import saveIcon from "@/assets/icons/save.svg";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    MenuItem,
    TextField,
    useTheme
} from "@mui/material";
import { fUpper } from "../../useHelpers";

const courseStructureDefault: CourseStructure = {
    ielts: 0,
    grammar: 0,
    speaking: 0,
    one_to_one: 0,
    linguaskill: 0,
    writing_correction: 0,
};

const GeneralInfoBox = ({ formRef, product, data, courseMonths, setCourseMonths, handleAccordionChange, expanded, isAdmission, callCreateProduct, callUpdateProduct, userAdminTk, snackHandler, setProduct, invalidateProducts }) => {
    const theme = useTheme();
    const isLoading = callCreateProduct.isLoading || callUpdateProduct.isLoading;

    const submitForm = async (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        formData.set("course_info[months]", courseMonths.toString());
        formData.set(
            "course_info[course_name]",
            formData.get("product_tag") as string
        );
        formData.set("tutoring", isAdmission ? "1" : "0");

        // unset all null values
        [...formData.keys()].forEach((key) => {
            if (formData.get(key) === null || formData.get(key) == "") {
                formData.delete(key);
            }
        });

        const params = {
            token: userAdminTk,
            formData,
            callback: () => { },
        };

        if (product?.token) {
            callUpdateProduct.mutate({ ...params, prod_tk: product?.token },
                {
                    onSuccess: async (data: any) => {
                        if (data?.data) {
                            snackHandler("Product updated!", "success");
                            // setProduct(data?.data);
                            setProduct((p) => ({ ...p, ...data?.data }));
                            invalidateProducts();
                        }
                    },
                    onError: (error) => {
                        console.error(error);
                        snackHandler("Some issue occured", "error");
                    }
                }
            );
        } else {
            callCreateProduct.mutate(params,
                {
                    onSuccess: async (data: any) => {
                        if (data?.data) {
                            snackHandler("Product created!", "success");
                            setProduct((p) => ({ ...p, ...data?.data }));
                            invalidateProducts();
                        }
                    },
                    onError: (error) => {
                        console.error(error);
                        snackHandler("Some issue occured", "error");
                    }
                }
            );
        }
    };

    return (
        <Accordion
            id="main-form"
            ref={formRef}
            onSubmit={submitForm}
            component="form"
            expanded={expanded === "acc-gen-info"}
            onChange={handleAccordionChange("acc-gen-info")}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                General info
            </AccordionSummary>

            <AccordionDetails className="flex flex-col gap-4">
                <Box className="grid auto-cols-fr grid-flow-col gap-4">
                    <TextField
                        required
                        fullWidth
                        name="product_tag"
                        label="Tag"
                        defaultValue={product?.product_tag ?? ""}
                        className="col-span-2"
                    />

                    {
                        data?.token && (data?.price > 0) && (
                            <TextField
                                fullWidth
                                name="price"
                                label="Old Price"
                                disabled
                                className="col-span-1"
                                sx={{
                                    "& .Mui-disabled": {
                                        border: "none !important",
                                    },
                                }}
                                onChange={(e) =>
                                    (e.target.value = e.target.value.replace(/[^0-9.]/g, ""))
                                }
                                defaultValue={product?.price ? Number(product?.price) / 100 : ""}
                            />
                        )
                    }


                    <TextField
                        select
                        fullWidth
                        name="guaranteed_level"
                        label="Guaranteed Level"
                        defaultValue={product?.guaranteed_level ?? ""}
                        className="col-span-1"
                    >
                        {ENGLISH_LEVELS?.map((l, i: number) => (
                            <MenuItem
                                key={l + i}
                                value={l}
                            >
                                {l}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Box className="flex flex-nowrap items-stretch gap-4 col-span-2">
                        <Button
                            variant="outlined"
                            onClick={() => setCourseMonths((p) => p - 1)}
                            disabled={Number(product?.course_info?.months) < 2}
                            className="!text-2xl font-bold"
                        >
                            -
                        </Button>

                        <Box
                            sx={{ borderColor: theme.palette.primary.main }}
                            className="border rounded flex justify-center items-center grow select-none py-2"
                        >
                            {courseMonths} Month{courseMonths != 1 && "s"}
                        </Box>

                        <Button
                            variant="outlined"
                            onClick={() => setCourseMonths((p) => p + 1)}
                            className="!text-2xl font-bold"
                        >
                            +
                        </Button>
                    </Box>
                </Box>

                <Accordion className="!rounded-lg">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Structure {courseMonths} months
                    </AccordionSummary>

                    <AccordionDetails className="!pt-0">
                        <Box className="flex flex-col gap-2">
                            {Array.from({ length: courseMonths }).map((_, i: number) => (
                                <Box
                                    key={i}
                                    className="flex flex-col gap-3"
                                >
                                    <Box
                                        sx={{ color: theme.palette.primary.main }}
                                        className="text-sm"
                                    >
                                        Structure month {i + 1}
                                    </Box>

                                    <Box className="flex flex-row gap-4 flex-nowrap">
                                        {Object.keys({ ...courseStructureDefault }).map(
                                            (type: string) => (
                                                <TextField
                                                    fullWidth
                                                    key={type + i}
                                                    name={`course_info[course_structure][${i}][${type}]`}
                                                    label={fUpper(type.replaceAll("_", " "))}
                                                    defaultValue={
                                                        product?.course_info?.course_structure?.[i]?.[
                                                        type
                                                        ] ?? 0
                                                    }
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="!rounded-lg">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Structure week for all {courseMonths} months
                    </AccordionSummary>

                    <AccordionDetails className="!pt-0">
                        <Box className="flex flex-col gap-2">
                            {Array.from({ length: courseMonths }).map((_, i: number) => (
                                <Box
                                    key={i}
                                    className="flex flex-col gap-3"
                                >
                                    <Box
                                        sx={{ color: theme.palette.primary.main }}
                                        className="text-sm"
                                    >
                                        Structure one week for month {i + 1}
                                    </Box>

                                    <Box className="flex flex-row gap-4 flex-nowrap">
                                        {Object.keys({ ...courseStructureDefault }).map(
                                            (type: string) => (
                                                <TextField
                                                    fullWidth
                                                    key={type + i}
                                                    name={`course_info[course_structure_week][${i}][${type}]`}
                                                    label={fUpper(type.replaceAll("_", " "))}
                                                    defaultValue={
                                                        product?.course_info?.course_structure_week?.[
                                                        i
                                                        ]?.[type] ?? 0
                                                    }
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <LoadingButton
                    form="main-form"
                    type="submit"
                    color="primary"
                    variant="outlined"
                    loading={isLoading}
                    title="Save"
                    className="flex justify-center items-center !ml-auto"
                >
                    <img
                        src={saveIcon}
                        className={`icon !m-0 ${isLoading ? "opacity-0" : ""}`}
                    />
                </LoadingButton>
            </AccordionDetails>
        </Accordion>
    )
}

export default GeneralInfoBox