import { convert } from "./useHelpers";
import { UserAdmin } from "@/providers/AuthProvider";
import { IButtonCrmWhatsapp } from "@/components/assets/BUTTONS_CRM_WHATSAPP";
import SPECIAL_KEYS_WHATSAPP, { IRowCrm } from "@/components/assets/SPECIAL_KEYS_WHATSAPP";
import useAPI from "@/api/useAPI";
import ICONS_WHATSAPP from "@/components/assets/ICONS_WHATSAPP";

const BASE_LINK = (phone: string, template: string) =>
  `https://api.whatsapp.com/send?phone=${phone}&text=${template}`;

export type typeCersionConvertRowCrm =
  | "crm"
  | "crm-upselling"
  | "crm-admission";

interface IConvertRowCrm {
  first_name?: string;
  last_name?: string;
  lang: string;
  phone?: string;
  cell?: string;
  version?: typeCersionConvertRowCrm;
  meeting?: string;
  meetings?: string;
  owned_by?: string;
  tutoring?: number;
}

export const convertRowCrm = (row: IConvertRowCrm) => ({
  first_name: row?.first_name ?? "",
  lang: row?.lang ?? "en",
  last_name: row?.last_name ?? "",
  phone: row?.phone?.replaceAll("+", "") ?? "",
  version: row?.version ?? (row?.tutoring ? "crm-admission" : "crm"),
  lastMeet: convert(row?.meeting ?? row?.meetings ?? {})?.meet?.at(-1) ?? null,
  owned_by: row?.owned_by ?? "",
});

export default async (
  versionButton: IButtonCrmWhatsapp,
  rowCrm: IRowCrm,
  userAdmin: UserAdmin,
  snackHandler: (text: string, version: "error") => void,
  callback?: () => any,
  fallback?: () => any
) => {
  const params: any = {
    lang: rowCrm.lang,
    button: versionButton,
  };

  if (rowCrm?.version) {
    params.version = rowCrm?.version;
  }

  const res = await useAPI("/v1/crm/templates-whatsapp-web/show", params);

  if (res?.status == false) {
    snackHandler(
      res?.message ?? "Failed, template not found into DB!",
      "error"
    );
    return fallback?.();
  }

  let template: string = res;

  Object.values(SPECIAL_KEYS_WHATSAPP).forEach((value) => {
    template = template.replaceAll(value?.code, value.value(rowCrm, userAdmin));
  });

  Object.values(ICONS_WHATSAPP).forEach((icon) => {
    template = template.replaceAll(icon?.code, icon.value);
  });

  window.open(BASE_LINK(rowCrm.phone, template), "_blank");

  return callback?.();
};
