import { Button, Popover, styled } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

const FiltersBarMobileSize = (props) => {
  const [anchorElFilter, setAnchorElFilter] =
    useState<HTMLButtonElement | null>(null);

  const openFilter = Boolean(anchorElFilter);
  const idFilter = openFilter ? "filters-popover" : undefined;

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  return (
    <>
      <Button
        color="primary"
        aria-describedby={idFilter}
        variant="outlined"
        onClick={(e) => {
          handleClickFilter(e);
        }}
      >
        <MenuIcon />
      </Button>

      <FilterPopoverStyles
        id={idFilter}
        open={openFilter}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="container">{props.children}</div>
      </FilterPopoverStyles>
    </>
  );
};

const FilterPopoverStyles = styled(Popover)(({ theme }) => ({
  "& .container": {
    padding: "10px",
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    flexDirection: "column",
    "& button": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
}));

export default FiltersBarMobileSize;
