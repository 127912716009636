import { MouseEvent, useState } from "react";
import { EmojiEventsRoundedIcon } from "@/components";
import {
  Box,
  Button,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const VALUES = [50, 80, 120, 150, "new_checkout"];

interface INProps {
  onClick: (v: number | string) => void;
  displayButton?: boolean | undefined;
}

const ChooseIniPayment = ({ onClick, displayButton }: INProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  return (
    <>
      {!displayButton ? (
        <MenuItem onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ListItemIcon>
            <EmojiEventsRoundedIcon sx={{ fontSize: "1.15rem" }} />
          </ListItemIcon>
          <ListItemText>Chose Payment</ListItemText>
        </MenuItem>
      ) : (
        <Button
          onClick={(e: MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(e.currentTarget)
          }
        >
          <EmojiEventsRoundedIcon sx={{ fontSize: "1.15rem" }} />
        </Button>
      )}

      {Boolean(anchorEl) && (
        <Popover
          id={Boolean(anchorEl) ? "simple-popover-chooseIniPayment" : undefined}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Box className="p-1 flex gap-1 bg-edu-800">
            {VALUES.map((v, i) => (
              <Button
                key={
                  typeof v === "number"
                    ? `chooseIniPayment-${v}`
                    : `chooseIniPayment-${i}`
                }
                onClick={() => onClick(v)}
              >
                {v === "new_checkout" ? "Pagolight/Alma" : `€${v}`}
              </Button>
            ))}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default ChooseIniPayment;
