import { forwardRef, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import { STATUS_OFFER_REJECTED } from "./CrmTable";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import cn from "classnames";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import saveIcon from "@/assets/icons/save.svg";
import callIcon from "@/assets/icons/call.svg";
import ConfirmationDialog from "../ConfirmationDialog";

export interface IRef {
  open: (user: any) => void;
}

interface IProps {
  isUpselling?: boolean;
  invalidateQuery: () => void;
  onInterestedInTheFuture: (row: any) => void;
}

interface IOfferRejected {
  admin: {
    id: number; // 60,
    first_name: string; // "Oriana",
    last_name: string; // "Siragusa",
    email: string; // "oriana@edusogno.com"
  };
  created: string; // "12-06 at 19:28",
  created_at: string; // "2024-06-12 19:28",
  content: string; // "non interessata",
  status: string; // "No real interest in a course"
}

const d = (f: string) => dayjs().format(f);

const DialogNewStatusToBeConverted = forwardRef<IRef, IProps>(
  ({ isUpselling, invalidateQuery, onInterestedInTheFuture }, ref) => {
    const { userAdmin, snackHandler } = useAuth();

    const defaultValue = {
      admin: {
        id: userAdmin.user.id,
        first_name: userAdmin.user.first_name,
        last_name: userAdmin.user.last_name,
        email: userAdmin.user.email,
      },
      created: d("DD-MM") + " at " + d("HH:mm"),
      created_at: d("YYYY-MM-DD HH:mm"),
      content: "",
      status: "",
    };

    const [user, setUser] = useState<any>();
    const [value, setValue] = useState<IOfferRejected>(defaultValue);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openConfirmSave, setOpenConfirmSave] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      open: (user) => {
        setUser(user);
      },
    }));

    const handleClose = () => {
      setUser(undefined);
      setValue(defaultValue);
      setIsLoading(false);
      setOpenConfirmSave(false);
    };

    const prepareSubmit = (e: any) => {
      e.preventDefault();
      setOpenConfirmSave(true);
    };

    const handleSumbit = async () => {
      setIsLoading(true);
      setOpenConfirmSave(false);

      const val = { ...value };
      val.created = d("DD-MM") + " at " + d("HH:mm");
      val.created_at = d("YYYY-MM-DD HH:mm");

      const res = await useAPI(
        isUpselling
          ? "/v1/settings/set/crm-upselling/set-status-offer-rejected"
          : "/v1/settings/set/crm/set-status-offer-rejected",
        {
          value,
          token: user?.token,
        }
      );

      if (!res?.status) {
        snackHandler(
          res?.message || "Failed save value Offer rejected!",
          "error"
        );
        return;
      }

      snackHandler("Success save value Offer rejected!");
      invalidateQuery();
      handleClose();
    };

    return (
      <>
        <EduModal
          fullWidth
          open={Boolean(user)}
          onClose={handleClose}
          maxWidth="sm"
        >
          <EduModalTitle onClose={handleClose}>
            <img
              src={callIcon}
              className={cn(
                "icon no-margin",
                Boolean(user?.info?.offer_rejected)
                  ? "rotate-[165deg]"
                  : "rotate-[135deg]"
              )}
            />
            Set Interested in the future or add status for To be converted
          </EduModalTitle>

          <EduModalBody
            onSubmit={prepareSubmit}
            component="form"
            className="flex flex-col gap-5 p-5 pt-6"
          >
            <Button
              title="Open popup Interested in the future"
              variant="outlined"
              onClick={() => onInterestedInTheFuture(user)}
              className="!py-2"
            >
              <img
                src={callIcon}
                className="icon"
              />
              Interested in the future
            </Button>

            {Boolean(user?.info?.offer_rejected) ? (
              <Box>Offer rejected: {user?.info?.offer_rejected}</Box>
            ) : null}

            <TextField
              select
              fullWidth
              name="offer_rejected"
              label="Offer rejected"
              title="Select Offer rejected"
              value={value?.status || user?.info?.offer_rejected?.status || ""}
              onChange={({ target: { value } }) =>
                setValue((p) => ({ ...p, status: value }))
              }
            >
              {STATUS_OFFER_REJECTED.all.map((v) => (
                <MenuItem
                  key={v}
                  value={v}
                >
                  {v}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              multiline
              name="content"
              label="Note"
              value={value?.content || ""}
              minRows={4}
              maxRows={6}
              required={STATUS_OFFER_REJECTED.required.includes(value.status)}
              onChange={({ target: { value } }) =>
                setValue((p) => ({ ...p, content: value }))
              }
            />

            <Box className="w-full flex justify-end">
              <LoadingButton
                type="submit"
                variant="outlined"
                loading={isLoading}
                disabled={Boolean(
                  isLoading ||
                  !value.status ||
                  (STATUS_OFFER_REJECTED.required.includes(value.status) &&
                    !value.content)
                )}
              >
                <img
                  src={saveIcon}
                  className="icon"
                />
                SAVE
              </LoadingButton>
            </Box>
          </EduModalBody>
        </EduModal>

        {openConfirmSave && (
          <ConfirmationDialog
            open={openConfirmSave}
            onClose={() => setOpenConfirmSave(false)}
            onConfirm={handleSumbit}
          >
            Do you want save this values Offer rejected?
          </ConfirmationDialog>
        )}
      </>
    );
  }
);

export default DialogNewStatusToBeConverted;
