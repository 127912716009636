import { useRef, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { LoadingButton } from "@mui/lab";
import { updateMaterial } from "@/api";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Grid,
  Checkbox,
  MenuItem,
  TextField,
  IconButton,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import saveIcon from "@/assets/icons/save.svg";
import LANGUAGES from "@/components/assets/LANGUAGES";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MaterialFileSelect from "./components/MaterialFile";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const FOR = ["User", "Tutor", "Both"];
const MATERIAL_TYPE = ["Grammar", "Speaking", "Ielts", "Linguaskill"];

export const CATEGORY = ["Beginner", "Basic", "Intermediate", "Advanced"];

const CATEGORY_MAPPING = {
  Grammar: ["Advanced", "Intermediate", "Basic", "Beginner"],
  Speaking: [
    "Advanced",
    "Intermediate",
    "Basic",
    "Beginner",
    "One-to-One",
    "Zero",
    "Starter",
  ],
  Ielts: ["Reading", "Writing", "Speaking", "Listening"],
  Linguaskill: ["General", "Writing", "Listening", "Speaking"],
};

const CATEGORY_ENGLISH_LEVEL_MAPPING = {
  Zero: ["A0"],
  Starter: ["A1"],
  Beginner: ["A1"],
  Basic: ["A2"],
  B1: ["B1"],
  Intermediate: ["B1+", "B2-"],
  Advanced: ["B2", "B2+", "C1"],
};

const isValidURL = (url) => {
  const regex =
    /^https:\/\/((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|\d{1,3}(\.\d{1,3}){3})(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[a-z\d_]*)?$/i;
  return regex.test(url);
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditMaterial = ({
  onMutate,
  material,
  userAdmin,
  openUpdate,
  setOpenUpdate,
}) => {
  const { snackHandler } = useAuth();

  const fileRef = useRef<HTMLInputElement>();

  const [data, setData] = useState<any>({});
  const [urlError, setUrlError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLinkUsed, setIsLinkUsed] = useState(false);
  const [isFileUsed, setIsFileUsed] = useState(false);
  const [category, setCategory] = useState(
    material.category
      ? typeof material.category === "string"
        ? material.category.split(",")
        : material.category
      : []
  );
  const [openRemoveLink, setOpenRemoveLink] = useState<boolean>(false);

  const onClose = () => setOpenUpdate(false);

  const handleResourceChange = (event: any) => {
    const { value } = event.target;
    setIsLinkUsed(!!value);
    setIsFileUsed(false);
    if (!isValidURL(value)) {
      setUrlError("Invalid URL");
    } else {
      setUrlError("");
    }
    setData((p: any) => ({
      ...p,
      link: value,
      file: "",
      file_name: "",
    }));
  };

  const handleUpdate = async (e: any) => {
    e.preventDefault();

    if (isLinkUsed && !isValidURL(data.link)) {
      setUrlError("Invalid URL");
      snackHandler("invalid url", "error");
      return;
    }

    const formData = new FormData();
    const updatedData = {
      ...data,
      category: category.join(","),
    };

    for (const key in updatedData) {
      if (Object.prototype.hasOwnProperty.call(updatedData, key)) {
        if (key === "file" && !isFileUsed) {
          continue;
        }

        formData.append(key, updatedData[key]);
      }
    }

    setIsLoading(true);

    const res = await updateMaterial({
      id: material.id,
      tk: userAdmin.token,
      formData: formData,
    });
    setIsLoading(false);

    if (res?.status != "200") {
      snackHandler(res?.message ?? "Failed updating material", "error");
      return;
    }

    if (res?.status == "200") {
      onClose();
    }

    if (fileRef.current) {
      fileRef.current.value = "";
    }
    setData(res.data);
    snackHandler("Success updating material");

    queryClient.invalidateQueries(["materials-list"]);

    if (onMutate) {
      onMutate();
    }
  };

  const handleFileChange = (file: File | null) => {
    if (!file) {
      setIsFileUsed(false);
      setData((p: any) => ({ ...p, file: "", file_name: "", link: "" }));
    } else {
      setIsFileUsed(!!file);
      setIsLinkUsed(false);
      setData((p: any) => ({
        ...p,
        file,
        link: "",
        resource: file.name,
        file_name: file.name,
      }));
    }
  };

  const handleCategoryChange = (_, value) => {
    const newEnglishLevels = Array.from(
      new Set(
        value.flatMap(
          (category: string) => CATEGORY_ENGLISH_LEVEL_MAPPING[category] || []
        )
      )
    );
    setCategory(value);
    setData({ ...data, english_level: newEnglishLevels });
  };

  const handleRemoveLink = async () => {
    setData((p: any) => ({ ...p, link: "", file: "", file_name: "" }));
    setIsLinkUsed(false);

    try {
      const response = await useAPI(
        `/v1/material/${material.id}/remove-resource`,
        { link: true }
      );
      snackHandler(response.message);
    } catch (error) {
      snackHandler("An error occurred while removing the link", "error");
      console.error("Error removing link:", error);
    }
  };

  return (
    <>
      <EduModal
        fullWidth
        open={openUpdate}
        onClose={onClose}
        maxWidth="sm"
      >
        <EduModalTitle
          onClose={onClose}
          className="flex justify-between"
        >
          EDIT Material
        </EduModalTitle>

        <EduModalBody className="p-5 w-full">
          <Box
            onSubmit={handleUpdate}
            component="form"
            className={"p-3 flex flex-col gap-5"}
          >
            <FormControlLabel
              label="Precourse"
              control={
                <Checkbox
                  name="precourse"
                  color="primary"
                  checked={data.precourse ?? material?.precourse ?? false}
                  onChange={(e) =>
                    setData({ ...data, precourse: e.target.checked })
                  }
                />
              }
            />

            <TextField
              fullWidth
              name="name"
              label="Name material"
              value={data.name ?? material?.name ?? ""}
              variant="outlined"
              onChange={(event) =>
                setData({ ...data, name: event.target.value })
              }
              placeholder="Name material"
            />

            <Box
              display="flex"
              alignItems="center"
            >
              <TextField
                fullWidth
                name="link"
                error={!!urlError}
                label="Material Link"
                value={
                  typeof data.link === "string"
                    ? data.link
                    : material?.link !== "null"
                      ? material?.link
                      : ""
                }
                variant="outlined"
                onChange={handleResourceChange}
                helperText={urlError}
                placeholder="Material Link"
              />

              {data.link && (
                <IconButton onClick={() => setOpenRemoveLink(true)}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>

            <MaterialFileSelect
              disabled={isLinkUsed}
              onFileChange={handleFileChange}
              initialFileName={data?.file_name}
            />

            <TextField
              select
              fullWidth
              name="lesson"
              label="Lesson"
              value={data?.lesson ?? material?.lesson ?? ""}
              variant="outlined"
              onChange={(e) => setData({ ...data, lesson: e.target.value })}
              placeholder="Type"
            >
              {MATERIAL_TYPE.map((e, i) => (
                <MenuItem
                  key={e + i}
                  value={e}
                >
                  {e}
                </MenuItem>
              ))}
            </TextField>

            <Grid
              item
              md={12}
            >
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="checkboxes-tags-demo"
                color="primary"
                value={category}
                style={{ width: "100%" }}
                options={
                  CATEGORY_MAPPING[data.lesson ?? material?.lesson] || []
                }
                onChange={handleCategoryChange}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    key={option}
                  >
                    <Checkbox
                      icon={icon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      checkedIcon={checkedIcon}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    placeholder="Category"
                  />
                )}
              />
            </Grid>

            <Grid
              item
              md={12}
            >
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="checkboxes-tags-demo"
                color="primary"
                value={data?.language ?? material?.language}
                style={{ width: "100%" }}
                options={LANGUAGES.map((lang) => lang.iso)}
                onChange={(_, value) => setData({ ...data, language: value })}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    key={option}
                  >
                    <Checkbox
                      icon={icon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      checkedIcon={checkedIcon}
                    />
                    {LANGUAGES.find((lang) => lang.iso === option)?.name ??
                      option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Language"
                    placeholder="Language"
                  />
                )}
              />
            </Grid>

            <TextField
              select
              fullWidth
              name="for"
              label="For"
              value={data?.for ?? material?.for ?? ""}
              variant="outlined"
              onChange={(e) => setData({ ...data, for: e.target.value })}
            >
              {FOR.map((f, i) => (
                <MenuItem
                  key={f + i}
                  value={f}
                >
                  {f}
                </MenuItem>
              ))}
            </TextField>

            <Box className="flex justify-end">
              <LoadingButton
                type="submit"
                variant="outlined"
                loading={isLoading}
              >
                <img
                  src={saveIcon}
                  className="icon"
                />
                UPDATE
              </LoadingButton>
            </Box>
          </Box>
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={openRemoveLink}
        title="Remove link from material"
        onClose={() => setOpenRemoveLink(false)}
        onConfirm={handleRemoveLink}
      >
        Do you want to remove link from this material?
      </ConfirmationDialog>
    </>
  );
};

export default EditMaterial;
