import { ChangeEvent, useMemo } from "react";
import { MenuItem, TextField } from "@mui/material";
import { objectInvertKeyValue } from "../useHelpers";
import useCrmApi, { NEW_STATUS_FOR_CALL_CENTER } from "@/api/useCrmApi";

interface INProps {
  status: string;
  items?: Array<string>;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  useRemove?: boolean | undefined;
}

const SelectStatusFilter = ({
  items,
  status,
  onChange,
  useRemove,
}: INProps): JSX.Element => {
  const { arrayOptions, arrayLabelOptions } = useCrmApi();

  const menuItems = useMemo(
    () =>
      [
        ...new Set(
          items || [
            ...Object.values(arrayLabelOptions),
            ...NEW_STATUS_FOR_CALL_CENTER,
          ]
        ),
      ]
        .sort()
        .map((status: string, i: number) => {
          const invertKeyValue = objectInvertKeyValue(arrayLabelOptions);

          return (
            <MenuItem
              key={i}
              color="primary"
              value={
                items
                  ? status
                  : status in invertKeyValue
                  ? invertKeyValue[status]
                  : status
              }
            >
              {items
                ? status
                : status in arrayLabelOptions
                ? arrayLabelOptions[status]
                : status}
            </MenuItem>
          );
        }),
    [arrayOptions, arrayLabelOptions, items]
  );

  return (
    <TextField
      select
      fullWidth
      id="status-filter-select"
      name="status"
      label="Status"
      value={status || ""}
      color="primary"
      onChange={onChange}
    >
      {useRemove && <MenuItem value="">- Remove -</MenuItem>}

      {menuItems}
    </TextField>
  );
};

export default SelectStatusFilter;
