import React, { forwardRef, useState } from "react";
import { EduModal, EduModalBody, EduModalTitle } from "@/components/_Common/Modal/ModalStyles";
import { Button, Input } from "@mui/material";

const UploadModal = forwardRef((props: any, ref: any) => {
    const [localSelectedPDF, setLocalSelectedPDF] = useState<File | null>(null);

    return (
        <EduModal open={props.open} onClose={props.onClose} ref={ref}>
            <EduModalTitle onClose={props.onClose}>Carica il file PDF della fattura</EduModalTitle>
            <EduModalBody className="p-4">
                <Input 
                    sx={{ mr: 2}}
                    inputProps={{ type: 'file', accept: '.pdf' }} 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const file = event.target.files?.[0];
                        if (file) setLocalSelectedPDF(file);
                    }}              
                />
                <Button 
                    onClick={async () => {
                        try {
                            props.closeModal();
                            await props.updateInvoiceStatus(props.selectedRowId, localSelectedPDF);
                            props.snackHandler("User invoice status updated successfully!");
                        } catch (error) {
                            console.error("Error updating invoice status", error);
                            props.snackHandler("Error updating invoice status", "error");
                        }
                    }}
                    disabled={!localSelectedPDF}
                >
                    Conferma
                </Button>
            </EduModalBody>
        </EduModal>
    );
});

export default UploadModal;
