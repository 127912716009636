import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent,
} from "react";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  IconButton,
  Button,
  Chip,
} from "@mui/material";
import { Add, Delete, CopyAll } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import saveIcon from "@/assets/icons/save.svg";
import { useLinks, useSaveLinks } from "./useLinks"; // Custom hooks
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import { v4 as uuidv4 } from "uuid";
import URLPopover from "./URLPopover"; // Import the new component
import useAuth from '@/hooks/useAuth';

interface Link {
  uuid: string;
  url: string;
}

interface LinksData {
  admission: Link[];
  english: Link[];
}

const baseEnglish = "https://landing.edusogno.com/acceleratore-di-inglese-";
const baseAdmission = "https://landing.edusogno.com/admissions-";

const LinksModal = forwardRef(
  (_, ref: React.Ref<{ open: () => void; close: () => void }>) => {
    const [open, setOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const { copyToClipboard } = useAuth();
    const [linksData, setLinksData] = useState<LinksData>({
      admission: [],
      english: [],
    });
    const [newLink, setNewLink] = useState<string>("");

    // State for handling the popover
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(
      null
    );
    const [popoverURL, setPopoverURL] = useState<string>("");

    const { data: fetchedLinks, isLoading: isFetching } = useLinks();
    const { mutate: saveLinks, isLoading: isSaving } =
      useSaveLinks(setLinksData); // Pass setLinksData to useSaveLinks

    useImperativeHandle(ref, () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }));

    useEffect(() => {
      if (fetchedLinks && open) {
        setLinksData(fetchedLinks);
      }
    }, [fetchedLinks, open]);

    const handleAddLink = () => {
      if (!newLink) return;

      const newLinkData = {
        uuid: uuidv4(),
        url: newLink,
      };

      setLinksData((prev) => ({
        ...prev,
        [tabIndex === 0 ? "english" : "admission"]: [
          ...prev[tabIndex === 0 ? "english" : "admission"],
          newLinkData,
        ],
      }));

      setNewLink("");
    };

    const handleDeleteLink = (uuid: string, type: "admission" | "english") => {
      setLinksData((prev) => ({
        ...prev,
        [type]: prev[type].filter((link) => link.uuid !== uuid),
      }));
    };

    const handleCopyLink = (
      event: React.MouseEvent<HTMLElement>,
      url: string
    ) => {
      setPopoverAnchor(event.currentTarget);
      setPopoverURL(url);
      copyToClipboard(url);
    };

    const handlePopoverClose = () => {
      setPopoverAnchor(null);
      setPopoverURL("");
    };

    const handleSave = () => {
      saveLinks(linksData);
    };

    const handleTabChange = (
      _event: React.SyntheticEvent,
      newIndex: number
    ) => {
      setTabIndex(newIndex);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleAddLink();
      }
    };

    const handleBadgeClick = (baseLink: string) => {
      setNewLink(baseLink);
    };

    return (
      <EduModal
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        sx={{
          height: "600px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <EduModalTitle onClose={() => setOpen(false)}>
          Manage Links
        </EduModalTitle>

        <EduModalBody
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            overflow: "hidden",
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{ marginBottom: "16px" }}
          >
            <Tab label="English Links" />
            <Tab label="Admission Links" />
          </Tabs>

          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              marginBottom: "16px",
              padding: "10px",
              borderRadius: "4px",
              border: "primary",
            }}
          >
            {(tabIndex === 0 ? linksData.english : linksData.admission).map(
              (link) => (
                <Box
                  key={link.uuid}
                  display="flex"
                  alignItems="center"
                  mb={2}
                >
                  <TextField
                    value={link.url}
                    onChange={(e) => {
                      const updatedLinks =
                        tabIndex === 0
                          ? linksData.english.map((l) =>
                            l.uuid === link.uuid
                              ? { ...l, url: e.target.value }
                              : l
                          )
                          : linksData.admission.map((l) =>
                            l.uuid === link.uuid
                              ? { ...l, url: e.target.value }
                              : l
                          );

                      setLinksData((prev) => ({
                        ...prev,
                        [tabIndex === 0 ? "english" : "admission"]:
                          updatedLinks,
                      }));
                    }}
                    fullWidth
                  />
                  <IconButton onClick={(e) => handleCopyLink(e, link.url)}>
                    <CopyAll />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleDeleteLink(
                        link.uuid,
                        tabIndex === 0 ? "english" : "admission"
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </Box>
              )
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              label={`Add ${tabIndex === 0 ? "English" : "Admission"} link`}
              fullWidth
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
              onKeyDown={handleKeyDown}
              sx={{ marginRight: "8px" }}
            />
            <Button
              onClick={handleAddLink}
              startIcon={<Add />}
              variant="outlined"
            >
              Add
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "8px",
              marginTop: "16px",
              justifyContent: "flex-start",
            }}
          >
            <Chip
              label="Base English Link"
              onClick={() => handleBadgeClick(baseEnglish)}
              clickable
            />
            <Chip
              label="Base Admission Link"
              onClick={() => handleBadgeClick(baseAdmission)}
              clickable
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <LoadingButton
              variant="outlined"
              onClick={handleSave}
              disabled={isSaving}
              loading={isSaving}
              color="primary"
            >
              Save
              <img
                src={saveIcon}
                alt="save icon"
                style={{ width: "24px", height: "24px" }}
              />
            </LoadingButton>
          </Box>
        </EduModalBody>

        <URLPopover
          url={popoverURL}
          anchorEl={popoverAnchor}
          onClose={handlePopoverClose}
        />
      </EduModal>
    );
  }
);

export default LinksModal;
