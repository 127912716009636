import { PaymentOption } from "@/api/orders";
import almaIcon from "@/assets/icons/alma.svg";
import klarnaIcon from "@/assets/icons/klarna.svg";
import pagolightIcon from "@/assets/icons/pagolight.svg";
import stripeIcon from "@/assets/icons/stripe.svg";
import { fUpper } from "@/components/useHelpers";

type Props = {
  providers: PaymentOption[];
  className?: string | undefined;
  children?: any,
  showBadge?: boolean;
};

const EnabledPaymentsIcon = (props: Props) => {
  const { providers, ...rest } = props;

  const icons = {
    alma: almaIcon,
    stripe: stripeIcon,
    klarna: klarnaIcon,
    pagolight: pagolightIcon,
  };

  return (
    <div className="flex gap-2 items-center">
      {providers.map((provider, i) => (
        <div className=" relative">
          {props.showBadge && provider.installments && (
            <div className="absolute translate-y-1/2 translate-x-1/2 text-[9px] bottom-0 right-0 text-white font-extrabold">
              <span>{provider.installments}x</span>
            </div>
          )}
          <img
            alt={provider?.payment_type}
            src={icons[provider.payment_type]}
            key={i}
            title={fUpper(provider.display_name)}
            className={rest.className || "w-4 h-4"}
          />
        </div>
      ))}
      {
        props?.children && <span>{props.children}</span>
      }
    </div>
  );
};

export default EnabledPaymentsIcon;
