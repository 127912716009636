import { translations } from "./translations";

interface INParams {
  link: string;
  nameUser: string;
  lang: string;
  expires_at: string | number;
  code?: string;
}

export default ({ nameUser, link, lang, expires_at }: INParams): string => {
  const t = translations?.[lang] ?? translations?.["it"];

  return `<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"
    style="font-family: arial, 'helvetica neue', helvetica, sans-serif">
  
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title>Notification Template</title>
    <link href="https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i" rel="stylesheet" />
    <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>
    <style type="text/css">
      #outlook a { padding: 0; }
      * { font-family: 'Poppins', Arial, sans-serif; }
      .es-button { mso-style-priority: 100 !important; text-decoration: none !important; }
      a[x-apple-data-detectors] { color: inherit !important; text-decoration: none !important; font-size: inherit !important; font-family: inherit !important; font-weight: inherit !important; line-height: inherit !important; }
      p { color: initial; }
      .hero { background-image: url(${
        t.headerImg
      }); height: 100px; background-size: cover; background-position: center; }
      .chat-bubble { margin: 4vh auto; background-color: #8065c9; color: white; padding: 10px 20px; border-radius: 15px; display: inline-block; }
      .text-title { text-align: center; font-size: 13px; color: initial; }
      .text-desc, .color-desc { text-align: center; padding: 2.5vh 3vh; }
      .color-desc { background-color: #21fc9359; border-radius: 13px; margin: 0 3vw; }
      .color-desc p { font-size: 13px; }
      .color-desc a { display: inline-block; background-color: #21FC93; padding: 7px 36px; margin-top: 10px; border-radius: 13px; font-weight: 600; font-size: 13px; color: #fff; text-decoration: none; }
      .lower-desc { margin: 4vh 0; padding: 2.5vw 0; }
      .footer img { height: 40px; width: 40px; margin: 0 10px; }
      @media only screen and (max-width: 904px) {
        .color-desc, .text-desc { padding: 1.5vh 2vh; }
        .color-desc { margin: 0 7vw; }
      }
      @media only screen and (max-width: 620px) {
        .footer img { height: 35px; width: 35px; }
      }
      @media only screen and (max-width: 425px) {
        .footer img { height: 20px; width: 20px; }
      }
    </style>
  </head>
  
  <body style="width: 100%; padding: 0; margin: 0; background-color: #f8f8f8;">
    <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: #f8f8f8;">
      <tr>
        <td align="center">
          <table width="600" cellpadding="0" cellspacing="0" border="0" style="background-color: white; margin: auto;">
            <tr>
              <td class="hero"></td>
            </tr>
            <tr>
              <td align="center">
                <div class="chat-bubble">${
                  t.greeting
                }, <span>&#128075;</span></div>
              </td>
            </tr>
            <tr>
              <td class="text-title">${t.greetingUser(nameUser)}</td>
            </tr>
            <tr>
              <td class="text-desc">${t.messageContent}</td>
            </tr>
            <tr>
              <td>
                <div class="color-desc"> 
                  ${t.discoverDetails}
                  <a href="${link}" style="display: inline-block; background-color: #21FC93; padding: 7px 36px; margin-top: 10px; border-radius: 13px; font-weight: 600; font-size: 13px; color: #000000; text-decoration: none;">
                  ${t.callToAction}</a>
                </div>
              </td>
            </tr>
            <tr>
              <td class="lower-desc">${t.discountReminder(expires_at)}</td>
            </tr>
            <tr>
              <td align="center">
                <div style="padding:20px;display:flex;width: fit-content;" class="footer">
                  ${t.socialCallToAction}
                </div>
              </td>
            </tr>
            <tr>
              <td style="text-align: center; padding: 15px; font-size: 12px; color: #333;">${
                t.footer
              }</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
  </html>
  `;
};
