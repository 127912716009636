import client from "@/api/client";
import { API_ENDPOINTS } from "@/api/client/endpoints";
import { Order, OrdersData } from "@/interfaces";
import { queryClient } from "@/main";
import useAuth from "@/hooks/useAuth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export interface PaymentOption {
  display_name: string;
  payment_type: string;
  installments: number | null;
}

export const fetchOrder = (token: string) => {
  if (!token) {
    return {
      order: null,
      error: null,
      loading: false,
      keyQuery: [API_ENDPOINTS.V3.ORDER, token],
    };
  }

  const { data, error, isLoading } = useQuery<Order, Error>(
    [API_ENDPOINTS.V3.ORDER, token],
    () => client.orders.get(token),
    { keepPreviousData: true }
  );

  return {
    error,
    order: data,
    loading: isLoading,
    keyQuery: [API_ENDPOINTS.V3.ORDER, token],
  };
};

export const listOrders = (filters: any) =>
  useQuery({
    queryKey: ["listOrders", JSON.stringify(filters)],
    queryFn: () => client.orders.list(filters),
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  });

export function useMutateOrder() {
  const { snackHandler } = useAuth();

  return useMutation({
    mutationFn: ({ token, data }: { token: string; data: OrdersData }) =>
      client.orders.update(token, data),
    onError: () => {
      snackHandler("Error updating order!", "error");
    },
    onSuccess: () => {
      const queries = queryClient.getQueryCache().getAll();
      const activeQueries = queries.find(
        (q) => q.isActive() && q.queryKey[0] === "listOrders"
      );

      if (activeQueries) {
        // Use invalidateQueries instead of invalidate on the query instance
        queryClient.invalidateQueries(activeQueries.queryKey);
      }

      snackHandler("Order updated successfully!");
    },
  });
}

export const usePaymentOptions = () => {
  const queryClient = useQueryClient();
  const { snackHandler } = useAuth();

  // Fetch payment options
  const { data, isLoading, error } = useQuery(
    ["paymentOptions"],
    () => client.orders.getPaymentOptions(),
    {
      staleTime: 5 * 60 * 1000,
    }
  );

  const paymentOptions = data?.payment_options;
  const availablePaymentTypes =
    data?.available_payment_types as PaymentOption[];

  // Set payment options
  const mutation = useMutation(
    (newPaymentOptions: any) =>
      client.orders.setPaymentOptions(newPaymentOptions),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["paymentOptions"]);
        snackHandler("Payment options updated successfully!");
      },
    }
  );

  return {
    paymentOptions,
    availablePaymentTypes,
    isLoading,
    error,
    setPaymentOptions: mutation.mutate,
  };
};
