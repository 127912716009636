import { useMemo, useState } from "react";
import { STATUS } from "@/pages/Upselling/v1/Crm";
import { queryClient } from "@/main";
import { CALENDARS } from "@/providers/AuthProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  MenuItem,
  useTheme,
  Skeleton,
  TextField,
} from "@mui/material";
import {
  add,
  parse,
  format,
  endOfWeek,
  endOfMonth,
  startOfWeek,
  startOfToday,
  eachDayOfInterval,
} from "date-fns";
import client from "@/api/client";
import useAPI from "@/api/useAPI";
import Calendar from "./Calendar";
import useScreen from "@/hooks/useScreen";
import classNames from "classnames";
import ToBeCalled from "./ToBeCalled";
import DefaultCalendarButtons from "./DefaultCalendarButtons";
import useAuth from "@/hooks/useAuth";

interface ICreateCalendarEvent {
  start: string;
  summary: string;
  is_admin: boolean;
  calendar: string[];
  attendees: string[];
  newStatus: string | null;
  user_token: string;
  isUpselling: boolean | undefined;
  isAdmission: boolean | undefined;
  transparency?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  style: { zIndex: 350010 },
  PaperProps: {
    style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
  },
};

interface INSvgIcon {
  polyline: string;
}

const SvgIcon = ({ polyline }: INSvgIcon): JSX.Element => (
  <svg
    fill="none"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    stroke="currentColor"
    viewBox="0 0 24 24"
    className="icon no-margin"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      stroke="none"
      d="M0 0h24v24H0z"
      fill="none"
    />
    <polyline points={polyline} />
  </svg>
);

const MOUNTHS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

interface INCalendarEvents {
  user: any;
  status: string | null;
  isUpselling?: boolean;
  onUpdateRow?: (params: any) => void;
}

export default ({
  user,
  status,
  isUpselling,
  onUpdateRow,
}: INCalendarEvents) => {
  const theme = useTheme();
  const today = startOfToday();
  const isMobile = useScreen().width <= 1024;
  const { userAdmin, snackHandler } = useAuth();
  const isAdmission = user?.tutoring === 1 || user?.tutoring === true;

  const { data: CalendarsData } = useQuery(
    ["crm-get-img-calendars"],
    () => useAPI("/v1/crm/get-img-calendars"),
    { enabled: status != "Da richiamare" && status != STATUS.ToBeCalled }
  );

  const isToBeCalled = status == "Da richiamare" || status == STATUS.ToBeCalled;

  const name = userAdmin?.user?.first_name + " " + userAdmin?.user?.last_name;

  const names = [
    ...new Set([
      isToBeCalled ? name : "All",
      ...userAdmin?.calendars?.[
        isAdmission
          ? CALENDARS.ADMISSION
          : isUpselling
            ? CALENDARS.UPSELLING
            : CALENDARS.ENGLISH
      ]?.map((e: any) => e?.name),
    ]),
  ];

  const [calendars, setCalendars] = useState<string[]>([
    names?.includes(name)
      ? name
      : isUpselling
        ? names[0]
        : isToBeCalled
          ? name
          : "All",
  ]);

  const defaultQueryName = [
    "availableCalendars",
    user.lang,
    isAdmission,
    calendars,
  ];
  const [selectedDay, setSelectedDay] = useState(today);
  const [currentMonth, setCurrentMonth] = useState(format(today, "MMMM-yyyy"));

  const payload: any = {
    isAdmission,
    isUpselling,
    token: user.token,
    limit: 4 * 7,
    calendar: calendars?.[0] === "All" ? undefined : calendars[0],
    admin: 1,
  };

  const {
    data: calendarData,
    isLoading: isLoadingEvents,
    isFetching,
  } = useQuery(
    [defaultQueryName],
    ({ signal }) => client.calendar.list(payload, { signal }),
    { keepPreviousData: true }
  );

  const firstDateCurrentMonth = parse(currentMonth, "MMMM-yyyy", new Date());

  const days = eachDayOfInterval({
    start: startOfWeek(firstDateCurrentMonth, { weekStartsOn: 1 }),
    end: add(endOfWeek(endOfMonth(firstDateCurrentMonth)), { days: 1 }),
  });

  const prevMonth = () =>
    setCurrentMonth(
      format(add(firstDateCurrentMonth, { months: -1 }), "MMMM-yyyy")
    );

  const nextMonth = () =>
    setCurrentMonth(
      format(add(firstDateCurrentMonth, { months: 1 }), "MMMM-yyyy")
    );

  const setQueriesData = (params: any) => {
    queryClient.setQueriesData(defaultQueryName, (old: any) =>
      old?.filter((e: any) => e?.date !== params?.start)
    );
  };

  const newEvent = useMutation(
    (params: any) => client.calendar.createOrUpdate(params),
    {
      onSuccess: async (res: any, params) => {
        snackHandler(res?.message ?? "Event created!");

        setQueriesData(params?.start);

        onUpdateRow?.(res?.data);
      },
      onError: (res: any, params) => {
        snackHandler(
          res?.response?.data?.message ?? "This date has been taken!",
          "error"
        );

        setQueriesData(params?.start);
      },
    }
  );

  const createEvent = (calendarId: string, date: string) => {
    let thisEvent: ICreateCalendarEvent = {
      isUpselling,
      isAdmission,
      start: date,
      summary: user?.name ?? user?.first_name + " " + user?.last_name,
      calendar: [calendarId],
      newStatus: status,
      attendees: [user.email],
      user_token: user.token,
      is_admin: true,
    };

    if (status == "Da richiamare" || status == STATUS.ToBeCalled) {
      thisEvent = {
        ...thisEvent,
        summary:
          "Da richiamare " +
          (user?.name ?? user?.first_name + " " + user?.last_name),
        user_token: user.token,
        transparency: "transparent",
      };
    }

    newEvent.mutate(thisEvent);
  };

  const availableCalendarsFormat = useMemo(() => {
    if (calendarData && CalendarsData) {
      return calendarData.map((c: any) => ({
        ...c,
        image: CalendarsData[c.calendar]?.img || null,
      }));
    }
  }, [CalendarsData, calendarData]);

  const availableDates = useMemo(() => {
    if (!calendarData) return [];

    return [
      ...new Set(
        calendarData
          .map((c: any) => {
            if (calendars.length === 1 && calendars[0] === "All") {
              return format(new Date(c.date), "yyyy-M-dd");
            }

            if (calendars.indexOf(c.calendar) !== -1) {
              return format(new Date(c.date), "yyyy-M-dd");
            }
          })
          .filter(Boolean)
      ),
    ] as Array<string>;
  }, [calendarData, calendars]);

  return (
    <Box className="flex flex-col md:flex-row h-screen">
      <Box className="p-5 border-r !border-r-slate-500/30">
        <Box
          sx={{ color: theme.palette.primary.main }}
          className="flex items-center justify-between"
        >
          <Button
            onClick={prevMonth}
            aria-label="calendar backward"
          >
            <SvgIcon polyline="15 6 9 12 15 18" />
          </Button>

          <span
            tabIndex={0}
            className="text-base font-bold"
          >
            {format(firstDateCurrentMonth, "MMMM yyyy")}
          </span>

          <Button
            onClick={nextMonth}
            aria-label="calendar forward"
          >
            <SvgIcon polyline="9 6 15 12 9 18" />
          </Button>
        </Box>

        <Box
          sx={{ color: theme.palette.primary.main }}
          className={classNames(
            "overflow-x-auto pb-4",
            isMobile ? "pt-1" : "pt-8"
          )}
        >
          <Box className="grid grid-cols-7 mt-2 gap-3 w-full">
            {MOUNTHS.map((month: string, i: number) => (
              <p
                key={month + i}
                className="text-base font-medium text-center"
              >
                {month}
              </p>
            ))}
          </Box>

          <Calendar
            {...{
              days,
              status,
              isMobile,
              selectedDay,
              availableDates,
              setSelectedDay,
              firstDateCurrentMonth,
              isLoadingEvents: isFetching || isLoadingEvents,
            }}
          />
        </Box>

        <TextField
          select
          fullWidth
          label="Calendar"
          value={calendars ?? ""}
          onChange={(e) => setCalendars([e.target.value])}
          className="!mt-4"
          InputLabelProps={{ shrink: true }}
        >
          {names?.map((n: string) => (
            <MenuItem
              key={n}
              value={n}
            >
              {n}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {status == "Da richiamare" || status == STATUS.ToBeCalled ? (
        <ToBeCalled
          theme={theme}
          newEvent={newEvent}
          calendars={calendars}
          selectedDay={selectedDay}
          createEvent={createEvent}
        />
      ) : isFetching || isLoadingEvents ? (
        <DSkeleton />
      ) : (
        <DefaultCalendarButtons
          newEvent={newEvent}
          calendars={calendars.map((c) => c.toLowerCase())}
          selectedDay={selectedDay}
          createEvent={createEvent}
          availableCalendars={availableCalendarsFormat || []}
        />
      )}
    </Box>
  );
};

function DSkeleton() {
  return (
    <Box className="pt-1 pb-4 md:pt-6 pl-4 grow flex flex-col gap-4">
      <Box className="text-sm font-bold leading-20">Schedule for ...</Box>

      <Box className="flex flex-col gap-4 min-w-[250px] grow overflow-y-auto pr-4">
        {Array.from({ length: 7 })
          .fill(0)
          .map((_, i) => (
            <Skeleton
              sx={{ borderRadius: 2 }}
              key={i}
              width="100%"
              height="33.5px"
              variant="rectangular"
            />
          ))}
      </Box>
    </Box>
  );
}
