import { usersAll } from "@/api";
import useAPI from "@/api/useAPI";
import useProductApi from "@/api/useProductApi";
import useUserApi from "@/api/useUserApi";
import AvailabilitiesIcon from "@/assets/icons/availabilities.svg";
import chatIcon from "@/assets/icons/chat.svg";
import commentIcon from "@/assets/icons/comments.svg";
import surveyIcon from "@/assets/icons/download.svg";
import editIcon from "@/assets/icons/edit.svg";
import exercisesIcon from "@/assets/icons/exercises.svg";
import feedbackIcon from "@/assets/icons/feedback.svg";
import impersonateIcon from "@/assets/icons/impersonate.svg";
import invoiceIcon from "@/assets/icons/invoice.svg";
import mailIcon from "@/assets/icons/mail.svg";
import PaymentInIcon from "@/assets/icons/payment-incoming.svg";
import presenceIcon from "@/assets/icons/presence.svg";
import CustomPagination from "@/components/_Common/CustomPagination";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import FlagRender from "@/components/FlagRender";
import MyTooltip from "@/components/MyTooltip";
import BasePage from "@/components/Page/Base";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CreateInvoicePaymentInDialog, {
  IRef as IRefCreateInvoicePaymentInDialog,
} from "@/components/PaymentsIn/CreateInvoicePaymentInDialog";
import { arrayFlat, clone, convert, euro } from "@/components/useHelpers";
import {
  defaultAnchorElProduct,
  IAnchorElProduct,
  PopoverProduct,
  PopoverSetProductFromPayment,
  RenderCellProduct,
  valueGetterProduct,
} from "@/components/User/PopoverProduct";
import useAuth from "@/hooks/useAuth";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";
import useDGS from "@/hooks/useDataGridSort";
import usePagination from "@/hooks/usePagination";
import useScreen from "@/hooks/useScreen";
import { UserOrders } from "@/interfaces";
import { queryClient } from "@/main";
import UsersListNotes from "@/pages/UsersList/component/UsersListNotes";
import { getTableStyling } from "@/providers/Colors";
import {
  CurrencyExchange,
  Image,
  Link,
  ShoppingCart,
} from "@mui/icons-material";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReviewsIcon from "@mui/icons-material/Reviews";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Badge,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { createRef, useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EditOrder, {
  IRef as IRefEditOrder,
} from "../Orders/components/EditOrder";
import AddCheckBookingIelts, {
  IRef as AddCheckBookingIELTSRef,
} from "./components/AddCheckBookingIelts";
import CheckBookingIELTS, {
  IRef as CheckBookingIELTSRef,
} from "./components/CheckBookingIELTS";
import CreateUser, { Products } from "./components/CreateUser";
import EditMaxChangeEvent from "./components/EditMaxChangeEvent";
import ExercisesUserDialog, {
  IForwardRef as ExercisesUserDialogRef,
} from "./components/ExercisesUserDialog";
import FeedbackUserDialog, {
  IForwardRef as FeedbackUserDialogRef,
} from "./components/FeedbackUserDialog";
import SendMessageIntercom, {
  IForwardRef as SendMessageIntercomRef,
} from "./components/IntercomSendMessage";
import InvoiceUserDialog, {
  IForwardRef as InvoiceUserDialogRef,
} from "./components/InvoiceUserDialog";
import PresenceUserDialog, {
  IForwardRef as PresenceUserDialogRef,
} from "./components/PresenceUserDialog";
import StretchRangeInsertAvailabilities, {
  IForwardRef as IStretchRangeInsertAvailabilitiesRef,
} from "./components/StretchRangeInsertAvailabilities";
import ToogleActiveDiploma, {
  IRef as ToogleActiveDiplomaRef,
} from "./components/ToogleActiveDiploma";
import { exportSurveyDataToCsv } from "./components/UsersSurvey/surveyHelpers";
import UsersToolbar from "./components/UsersToolbar";
import { ENGLISH_LEVEL } from "./UserSingle";

dayjs.extend(isBetween);

const VITE_ROUTE_ADMIN = import.meta.env.VITE_ROUTE_ADMIN;
const VITE_ROUTE_ACADEMY_USER = import.meta.env.VITE_ROUTE_ACADEMY_USER;

const BASE_PAYMENT_LINK =
  (import.meta.env.VITE_CHECKOUT_BASE as string) + "existing-user/";

export const ONBOARDING_VALUES_NEW_USERS = ["YES", "NO", "Scheduled"];

const GestioneIscritti = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { data: products } = useProductApi().callGetProducts();
  const { data: startMonths } = useUserApi().callGetStartMonths();
  const { userAdmin, copyToClipboard, snackHandler } = useAuth();

  const { data: deadlineAPI } = useQuery(["next-deadline"], () =>
    useAPI("/v1/settings/get/next-deadline", {}, { type: "get" })
  );

  const [isMode, setIsMode] = useState<string>();
  const [filters, setFilters] = useSearchParams({});
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<any>();
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<{} | null>(null);
  const [selectedModes, setSelectedModes] = useState<Array<any>>([]);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );
  const [openConfirmDialogReviews, setOpenConfirmDialogReviews] =
    useState<boolean>(false);
  const [openConfirmSelectedUpselling, setOpenConfirmSelectedUpselling] =
    useState<boolean>(false);
  const [openConfirmAddToActiveCampaign, setOpenConfirmAddToActiveCampaign] =
    useState<boolean>(false);
  const [
    anchorElPopoverSetProductFromPayment,
    setAnchorElPopoverSetProductFromPayment,
  ] = useState<IAnchorElProduct>(defaultAnchorElProduct);

  const refEditOrder = createRef<IRefEditOrder>();
  const invoiceDialogRef = createRef<IRefCreateInvoicePaymentInDialog>();
  const intercomDialogRef = createRef<SendMessageIntercomRef>();
  const checkBookingIELTSRef = createRef<CheckBookingIELTSRef>();
  const InvoiceUserDialogRef = createRef<InvoiceUserDialogRef>();
  const FeedbackUserDialogRef = createRef<FeedbackUserDialogRef>();
  const PresenceUserDialogRef = createRef<PresenceUserDialogRef>();
  const ExercisesUserDialogRef = createRef<ExercisesUserDialogRef>();
  const ToogleActiveDiplomaRef = createRef<ToogleActiveDiplomaRef>();
  const addCheckBookingIELTSRef = createRef<AddCheckBookingIELTSRef>();
  const stretchRangeInsertAvailabilitiesRef =
    createRef<IStretchRangeInsertAvailabilitiesRef>();

  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ filters, setFilters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });
  const [isEditMaxChangeDialogOpen, setIsEditMaxChangeDialogOpen] =
    useState(false);

  delete filterData?.page;
  const cacheKey = ["usersGestioneIscritti", page, filterData, perPage];

  const { data: usersAPI, isLoading } = useQuery(
    cacheKey,
    () => usersAll(userAdmin.token, { page, per_page: perPage, ...filterData }),
    { keepPreviousData: true, refetchOnWindowFocus: true, enabled: perPage > 0 }
  );

  const pagination = {
    total: usersAPI?.total || 1,
    last_page: usersAPI?.last_page || 1,
    current_page: usersAPI?.current_page || 1,
  };

  const selectedUser = usersAPI?.data?.find(
    (user: { token: string }) => user.token === selectedUserId
  );

  const handleOpenDialog = (data = null) => {
    setOpenDialog(true);
    setUserSelected(data);
  };

  const handleCloseEditMaxChangeDialog = () => {
    setIsEditMaxChangeDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUserSelected(null);
  };

  const selectedMode = useMemo(() => arrayFlat(selectedModes), [selectedModes]);

  const productsData = useMemo(() => {
    const tempProducts =
      products?.products?.map((product: any) => ({
        id: product?.id,
        name: product?.product_tag,
      })) || [];

    return [
      ...new Map(tempProducts.map((item: any) => [item["id"], item])).values(),
    ] as Products[];
  }, [products]);

  const handleImpersonate = async (token: string) => {
    const res = await useAPI(
      "/v1/impersonate/user",
      { token },
      { useErrors: true }
    );

    if (res?.data?.status == false) {
      snackHandler(res?.data?.message ?? "Failed authorized!", "error");
      return;
    }

    window.open(
      `${VITE_ROUTE_ACADEMY_USER}/impersonate/${res?.data}`,
      "_blank"
    );
  };

  const handleGetReviews = useCallback(async () => {
    setOpenConfirmDialogReviews(false);
    await useAPI("/v1/get-reviews", { token: rowSelected.token });
    snackHandler("Send email for review");
  }, [rowSelected]);

  const handleRemoveModeSelected = useCallback(() => {
    setIsMode(undefined);
    setSelectedModes([]);
  }, []);

  const handleAddToUpsellingApi = useCallback(async () => {
    setOpenConfirmSelectedUpselling(false);

    const res = await useAPI("/v1/upselling/crm/add-users", {
      users: selectedMode,
      admin: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
    });

    if (res?.status) {
      snackHandler(`Success added user${selectedMode.length > 1 && "s"}`);

      handleRemoveModeSelected();

      queryClient.invalidateQueries(cacheKey);
    } else {
      snackHandler(
        `Error added user${selectedMode.length > 1 && "s"}`,
        "error"
      );
    }
  }, [selectedMode, userAdmin]);

  const handleAddToActiveCampaign = async () => {
    setOpenConfirmAddToActiveCampaign(false);
    const data: any = {};

    if (selectedModes?.length) {
      data.usersId = selectedModes[0];
    }

    if (rowSelected?.token) {
      data.usersId = [rowSelected?.id];
    }

    const res = await useAPI("/v1/users/add-to-active-campaign", data);

    if (!res?.status) {
      snackHandler("Failed add to Active Campaign!", "error");
      return;
    }

    snackHandler("Success add to Active Campaign!");
  };

  const handleChange = ({ target: { name, value } }) => {
    setFilters(
      (p) => {
        if (value) {
          p.set(name, value);
        } else {
          p.delete(name);
        }
        return p;
      },
      { replace: true }
    );
    setPage(1);
  };

  const englishMenu = useMemo(
    () =>
      ENGLISH_LEVEL.map((e) => (
        <MenuItem
          key={e}
          value={e}
        >
          {e}
        </MenuItem>
      )),
    []
  );

  const userCourseStatusMenu = useMemo(
    () =>
      ["yes", "no"].map((e) => (
        <MenuItem
          key={e}
          value={e}
        >
          {e.toUpperCase()}
        </MenuItem>
      )),
    []
  );

  const startMonthMenu = useMemo(
    () =>
      startMonths?.data?.map(({ month, month_en }) => (
        <MenuItem
          key={month}
          value={month}
        >
          {month_en}
        </MenuItem>
      )),
    [startMonths]
  );

  const productMenu = useMemo(
    () =>
      productsData
        ?.filter((p) => p.id)
        .map(({ id, name }) => (
          <MenuItem
            key={id}
            value={id}
          >
            {name}
          </MenuItem>
        )),
    [productsData]
  );

  const handleStretchRangeintoAvailabilities = () => {
    stretchRangeInsertAvailabilitiesRef?.current?.open(selectedModes, {});
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "lang",
        headerName: "",
        align: "center",
        maxWidth: 75,
        renderCell: ({ row }) => (
          <Box className="flex items-center">
            {Boolean(row?.settings?.activeCampaign?.lists?.length) && (
              <span>AC</span>
            )}
            {row?.settings?.isBeta && (
              <span style={{ fontSize: "20px" }}>&#946;</span>
            )}
            <FlagRender flag={row?.lang} />
          </Box>
        ),
      },
      {
        field: "fullName",
        headerName: "Name",
        minWidth: 180,
        flex: 1,
        renderCell: ({ row }) => <MyTooltip>{row?.fullName}</MyTooltip>,
      },
      {
        field: "start_month",
        headerName: "Start month",
        minWidth: 150,
        flex: 0.5,
        valueGetter: ({ row }) =>
          useConvertStartMonthEn(row?.start_month) || "-",
      },
      {
        field: "english_level",
        headerName: "English level",
        width: 120,
        valueGetter: ({ row }) => row?.english_level || "-",
      },
      {
        field: "product",
        headerName: "Product",
        minWidth: 150,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => valueGetterProduct(row),
        renderCell: ({ row }) => (
          <RenderCellProduct
            {...{
              row,
              setAnchorElProduct,
              setAnchorElPopoverSetProductFromPayment,
            }}
          />
        ),
      },
      {
        field: "ltv",
        headerName: "LTV",
        minWidth: 110,
        flex: 0.3,
        valueGetter: ({ row }) =>
          euro(
            row?.payments?.reduce(
              (acc: number, e: any) => acc + Number(e?.ltv),
              0
            )
          ),
      },
      {
        field: "feedbacks",
        headerName: "Rating",
        width: 75,
        valueGetter: ({ row }) => Number(row?.feedbacks) || "-",
      },
      {
        field: "count_feedbacks",
        headerName: "#feedbacks",
        width: 110,
        valueGetter: ({ row }) => Number(row?.count_feedbacks) || "-",
      },
      {
        field: "attendance",
        headerName: "#attendance",
        width: 130,
        // valueGetter: ({ row }) => row?.user_events?.[0]?.attendance || "-",
        valueGetter: ({ row }) => Number(row?.attendance) || "-",
      },
      {
        field: "nps_feedback",
        headerName: "NPS",
        width: 60,
        valueGetter: ({ row }) => row?.nps_feedback || "-",
      },
      {
        field: "period_availability",
        width: 70,
        sortable: false,
        hideable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        renderHeader: () => (
          <div className="MuiDataGrid-columnHeaderTitle ">
            <MyTooltip title="Period availability">P. avai.</MyTooltip>
          </div>
        ),
        valueGetter: ({ row }) =>
          Boolean(row?.availabilities?.length) ? "YES" : "NO",
      },
      {
        field: "get_diploma",
        width: 75,
        sortable: false,
        hideable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        renderHeader: () => (
          <div className="MuiDataGrid-columnHeaderTitle ">
            <MyTooltip title="Get diploma">Get dip.</MyTooltip>
          </div>
        ),
        valueGetter: ({ row }) => (Boolean(row?.get_diploma) ? "YES" : "NO"),
      },
      // GRID_CHECKBOX_SELECTION_COL_DEF
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 100,
      },
      {
        field: "actions",
        headerName: "",
        width: 70,
        sortable: false,
        hideable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) => {
          const notes = convert(row?.note ?? []);

          const isSuspended =
            row?.user_suspended && !row?.user_suspended?.active;

          return (
            <EduBadge
              color={
                isSuspended || row?.settings?.availabilities?.removeLimit
                  ? "error"
                  : "primary"
              }
              variant="dot"
              invisible={
                !notes.length &&
                !isSuspended &&
                !row?.settings?.availabilities?.removeLimit
              }
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Dropdown
                text={<MoreVertIcon />}
                content={
                  <MenuList>
                    <MenuItem
                      color="primary"
                      title="Copy email"
                      onClick={() => copyToClipboard(row?.email)}
                    >
                      <ListItemIcon>
                        <img
                          src={mailIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Copy email</ListItemText>
                    </MenuItem>

                    <MenuItem
                      title="Send WhatsApp message"
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send?phone=" +
                            row?.phone?.replace("+", "%2B") +
                            "&text="
                        );
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={chatIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>WhatsApp</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Add payment"
                      onClick={() =>
                        invoiceDialogRef?.current?.open({
                          user: row,
                          products: products.products,
                        })
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={PaymentInIcon}
                          width="16px"
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Add payment</ListItemText>
                    </MenuItem>

                    <MenuItem
                      title="Edit Max change event"
                      onClick={() => {
                        setRowSelected(row);
                        setIsEditMaxChangeDialogOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={editIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Edit Max change event</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Feedbacks"
                      onClick={() =>
                        FeedbackUserDialogRef.current?.open(row.token)
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={feedbackIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Feedbacks</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Presence"
                      onClick={() =>
                        PresenceUserDialogRef.current?.open(row.token)
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={presenceIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Presence</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Exercises"
                      onClick={() =>
                        ExercisesUserDialogRef.current?.open(row.token)
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={exercisesIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Exercises</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Surveys"
                      onClick={() =>
                        exportSurveyDataToCsv(
                          row.token,
                          userAdmin.token,
                          row.fullName,
                          row.start_month,
                          valueGetterProduct(row)
                        )
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={surveyIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Export Survey</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Invoices"
                      onClick={() =>
                        InvoiceUserDialogRef.current?.open(row.token)
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={invoiceIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Invoices</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Send a message with Intercom"
                      onClick={() =>
                        intercomDialogRef.current?.open({
                          user: row,
                          token: row.token,
                        })
                      }
                    >
                      <ListItemIcon>
                        <i
                          style={{ fontSize: "1.2rem" }}
                          className="fa-brands fa-intercom"
                        />
                      </ListItemIcon>
                      <ListItemText>Intercom</ListItemText>
                    </MenuItem>

                    <MenuItem
                      title="Ask for a trustpilot review"
                      onClick={() => {
                        setRowSelected(row);
                        setOpenConfirmDialogReviews(true);
                      }}
                    >
                      <ListItemIcon>
                        <ReviewsIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Review</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Comments"
                      onClick={() => {
                        setOpenComment(true);
                        setSelectedUserId(row.token);
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={commentIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        Comments
                        <Badge
                          sx={{ mx: 2 }}
                          color="warning"
                          badgeContent={notes.length}
                        />
                      </ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Stretch range insert availabilities"
                      onClick={() => {
                        stretchRangeInsertAvailabilitiesRef?.current?.open(
                          row?.id,
                          row?.settings
                        );
                      }}
                    >
                      <ListItemIcon>
                        {row?.settings?.availabilities?.removeLimit ? (
                          <WarningAmberIcon
                            color="error"
                            fontSize="small"
                            className="mr-2"
                          />
                        ) : (
                          <img
                            src={AvailabilitiesIcon}
                            className="icon"
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        Stretch range insert availabilities
                      </ListItemText>
                    </MenuItem>

                    {isSuspended && (
                      <MenuItem
                        title="Show into suspended"
                        onClick={() => {
                          window.open(
                            `${VITE_ROUTE_ADMIN}/user-suspended?search=${row?.fullName}`,
                            "_blank"
                          );
                        }}
                      >
                        <ListItemIcon>
                          <Badge
                            sx={{
                              ml: 1,
                              "& > span": { width: "1rem", height: "1rem" },
                            }}
                            color="error"
                            variant="dot"
                          />
                        </ListItemIcon>
                        <ListItemText>Is suspended</ListItemText>
                      </MenuItem>
                    )}

                    {Boolean(row?.orders?.length) && (
                      <MenuItem
                        title={`Order${row?.orders?.length > 1 ? "s" : ""}`}
                        onClick={() =>
                          refEditOrder.current?.open(
                            row?.orders,
                            row as UserOrders
                          )
                        }
                      >
                        <ListItemIcon>
                          <ShoppingCart fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          Order{row?.orders?.length > 1 ? "s" : ""}
                          <Badge
                            sx={{ mx: 2 }}
                            color="warning"
                            badgeContent={row?.orders?.length}
                          />
                        </ListItemText>
                      </MenuItem>
                    )}

                    <MenuItem
                      color="primary"
                      title="Comments"
                      onClick={() => {
                        setRowSelected(row);
                        setOpenConfirmAddToActiveCampaign(true);
                      }}
                    >
                      <ListItemIcon>AC</ListItemIcon>
                      <ListItemText>Add/sync to Active Campaign</ListItemText>
                    </MenuItem>

                    {Boolean(!row?.created_account) && (
                      <MenuItem
                        color="primary"
                        title="Copy link for registration"
                        onClick={() => {
                          copyToClipboard(
                            VITE_ROUTE_ACADEMY_USER +
                              "/registration/" +
                              row.token
                          );
                        }}
                      >
                        <ListItemIcon>
                          <Link />
                        </ListItemIcon>
                        <ListItemText>Copy link for registration</ListItemText>
                      </MenuItem>
                    )}

                    {Boolean(row?.products?.at(-1)?.token) && (
                      <MenuItem
                        color="primary"
                        title="Copy link for payment"
                        onClick={() => {
                          copyToClipboard(
                            BASE_PAYMENT_LINK +
                              row?.products?.at(-1)?.token +
                              "/" +
                              row.token
                          );
                        }}
                      >
                        <ListItemIcon>
                          <CurrencyExchange fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          Copy link payment for last product
                        </ListItemText>
                      </MenuItem>
                    )}

                    <MenuItem
                      color="primary"
                      title="Check booking IELTS"
                      onClick={() =>
                        Boolean(row?.check_user_booking_ielts?.length)
                          ? checkBookingIELTSRef?.current?.open(
                              row?.check_user_booking_ielts
                            )
                          : addCheckBookingIELTSRef?.current?.open(row?.id)
                      }
                    >
                      <ListItemIcon>
                        <Image />
                      </ListItemIcon>
                      <ListItemText>
                        {Boolean(row?.check_user_booking_ielts?.length)
                          ? "Check booking IELTS"
                          : "Add booking IELTS"}
                        <Badge
                          showZero
                          sx={{ mx: 2 }}
                          color="warning"
                          badgeContent={row?.check_user_booking_ielts?.length}
                        />
                      </ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Toogle Unlock Diploma"
                      onClick={() =>
                        ToogleActiveDiplomaRef?.current?.open(row, cacheKey)
                      }
                    >
                      <ListItemIcon>
                        <DocumentScannerIcon />
                      </ListItemIcon>
                      <ListItemText>Toogle Unlock Diploma</ListItemText>
                    </MenuItem>

                    <MenuItem
                      title="Impersonate Academy User"
                      onClick={() => handleImpersonate(row?.token)}
                    >
                      <ListItemIcon>
                        <img
                          src={impersonateIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Impersonate</ListItemText>
                    </MenuItem>

                    <MenuItem
                      color="primary"
                      title="Edit user"
                      onClick={() => handleOpenDialog(row)}
                    >
                      <ListItemIcon>
                        <img
                          src={editIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </MenuItem>
                  </MenuList>
                }
              />
            </EduBadge>
          );
        },
      },
    ],
    [
      refEditOrder,
      intercomDialogRef,
      InvoiceUserDialogRef,
      PresenceUserDialogRef,
      FeedbackUserDialogRef,
      ExercisesUserDialogRef,
      ToogleActiveDiplomaRef,
    ]
  );

  return (
    <BasePage title="Users">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        hideFooterSelectedRowCount
        sx={getTableStyling(theme)}
        rows={usersAPI?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        sortingMode="server"
        selectionModel={selectedModes[parseInt(page.toString()) - 1] || []}
        isRowSelectable={({ row }) => !Boolean(row?.crm_upselling)}
        checkboxSelection={Boolean(isMode)}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        columnVisibilityModel={{ id_utente: false, actions: !isMode }}
        initialState={{
          columns: {
            columnVisibilityModel: { phone: false, email: false },
          },
        }}
        onSelectionModelChange={(ids) => {
          setSelectedModes((p: Array<Array<string | number>>) => {
            const t = clone(p);
            t[parseInt(page.toString()) - 1] = ids;
            return t;
          });
        }}
        components={{
          Toolbar: UsersToolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              rowSelected={selectedMode?.length}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            isMode,
            filters,
            setPage,
            setIsMode,
            setFilters,
            englishMenu,
            productMenu,
            handleChange,
            selectedMode,
            startMonthMenu,
            handleOpenDialog,
            userCourseStatusMenu,
            setOpenConfirmSelectedUpselling,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            onRemoveModeSelected: handleRemoveModeSelected,
            onStretchRangeintoAvailabilities:
              handleStretchRangeintoAvailabilities,
            onAddSyncToActiveCampaign: () =>
              setOpenConfirmAddToActiveCampaign(true),
          },
          pagination: { page, setPage, pagination },
        }}
      />

      <CreateUser
        open={openDialog}
        user={userSelected}
        cacheKey={cacheKey}
        products={productsData}
        handleOpen={handleOpenDialog}
        handleClose={handleCloseDialog}
      />

      <EditMaxChangeEvent
        open={isEditMaxChangeDialogOpen}
        cacheKey={cacheKey}
        maxValue={rowSelected?.max_value}
        userToken={rowSelected?.token}
        handleClose={handleCloseEditMaxChangeDialog}
      />

      <EditOrder ref={refEditOrder} />
      <CheckBookingIELTS
        ref={checkBookingIELTSRef}
        cacheKey={cacheKey}
      />
      <AddCheckBookingIelts
        ref={addCheckBookingIELTSRef}
        snackHandler={snackHandler}
      />
      <InvoiceUserDialog ref={InvoiceUserDialogRef} />
      <FeedbackUserDialog ref={FeedbackUserDialogRef} />
      <PresenceUserDialog ref={PresenceUserDialogRef} />
      <ExercisesUserDialog ref={ExercisesUserDialogRef} />
      <ToogleActiveDiploma ref={ToogleActiveDiplomaRef} />

      <UsersListNotes
        open={openComment}
        setOpen={setOpenComment}
        cacheKey={cacheKey}
        userAdmin={userAdmin}
        selectedUser={selectedUser}
      />

      <SendMessageIntercom ref={intercomDialogRef} />

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}

      {Boolean(anchorElPopoverSetProductFromPayment?.element) && (
        <PopoverSetProductFromPayment
          keyQuery={cacheKey}
          anchorElProduct={anchorElPopoverSetProductFromPayment}
          setAnchorElProduct={setAnchorElPopoverSetProductFromPayment}
        />
      )}

      <ConfirmationDialog
        open={openConfirmSelectedUpselling}
        title={
          <Box className="flex gap-1 items-center">
            <GroupAddOutlinedIcon fontSize="small" />
            <span>
              ADD CONTACT{selectedMode.length > 1 && "S"} TO UPSELLING
            </span>
          </Box>
        }
        onClose={() => setOpenConfirmSelectedUpselling(false)}
        onConfirm={handleAddToUpsellingApi}
      >
        Are you sure to add{" "}
        {selectedMode.length > 1 ? selectedMode.length : "this"} user
        {selectedMode.length > 1 && "s"} to upselling?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openConfirmDialogReviews}
        onClose={() => setOpenConfirmDialogReviews(false)}
        onConfirm={handleGetReviews}
      >
        {`Do you want to ask a review to ${rowSelected?.first_name} ${rowSelected?.last_name}?`}
      </ConfirmationDialog>

      <ConfirmationDialog
        open={Boolean(selectedModes?.length && openConfirmAddToActiveCampaign)}
        onClose={() => {
          setSelectedModes([]);
          setOpenConfirmAddToActiveCampaign(false);
        }}
        onConfirm={handleAddToActiveCampaign}
      >
        Do you want to add/sync to Active Campaign?
      </ConfirmationDialog>

      <CreateInvoicePaymentInDialog ref={invoiceDialogRef} />

      <StretchRangeInsertAvailabilities
        ref={stretchRangeInsertAvailabilitiesRef}
        cacheKey={cacheKey}
        deadlineAPI={deadlineAPI}
        snackHandler={snackHandler}
      />
    </BasePage>
  );
};

export default GestioneIscritti;
