import { useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteExercise,
  updateUserExercise,
  getUsersWithWritingExercises,
} from "@/api";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import usePagination from "@/hooks/usePagination";
import WritingExercises from "@/pages/CrmExercises/WritingExercises";

const WritingDone = () => {
  const { perPage } = useScreen();
  const { userAdmin, snackHandler } = useAuth();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const sorts = filterData?.sorts;
  delete filterData?.page;
  delete filterData?.sorts;

  const keyCache = [
    "getUsersWithExercisesWriting",
    page,
    sorts,
    perPage,
    filterData,
  ];

  const { data, isLoading } = useQuery(
    keyCache,
    () =>
      getUsersWithWritingExercises({
        tk: userAdmin.token,
        params: { page, filter: filterData, sorts, per_page: perPage },
      }),
    { keepPreviousData: true, enabled: perPage > 0, refetchOnWindowFocus: true }
  );

  const [idUser, setIdUser] = useState("");
  const [tokenEx, setTokenEx] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const updateDeleteExercise = useMutation(deleteExercise, {
    onSuccess() {
      setTokenEx("");
      setOpenConfirmDialog(false);
      snackHandler("Delete successful!");
    },
    onError() {
      snackHandler("Delete failed!", "error");
    },
  });

  const updateExercise = useMutation(updateUserExercise, {
    onSuccess(_: any, params: any) {
      setTokenEx("");
      setOpenConfirmDialog(false);
      queryClient.invalidateQueries(keyCache);
      snackHandler("Edit successful!");
      params?.callback?.();
    },
    onError(_: any, params: any) {
      snackHandler("Edit failed!", "error");
      params?.callback?.(false);
    },
  });

  return (
    <BasePage title="Writings Done">
      <WritingExercises
        id={userAdmin.token}
        page={page}
        idUser={idUser}
        tokenEx={tokenEx}
        setPage={setPage}
        filters={filterData}
        exercises={data}
        setIdUser={setIdUser}
        isLoading={isLoading}
        sortModel={sortModel}
        setFilters={setFilters}
        setTokenEx={setTokenEx}
        updateExercise={updateExercise}
        openConfirmDialog={openConfirmDialog}
        onSortModelChange={onSortModelChange}
        updateDeleteExercise={updateDeleteExercise}
        setOpenConfirmDialog={setOpenConfirmDialog}
      />
    </BasePage>
  );
};

export default WritingDone;
