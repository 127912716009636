import { ChangeEvent, memo } from "react";
import { STATUS } from ".";
import { ExpandMore, MessageRounded } from "@mui/icons-material";
import {
  INAnchorsEl,
  INCodeValues,
  INConfirmDialog,
} from "./interfacesAndDefaults";
import {
  Box,
  Button,
  Popover,
  Tooltip,
  MenuItem,
  Accordion,
  TextField,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import dayjs from "dayjs";
import MONTHS from "@/components/assets/MONTHS";

const PopoverUpselling = ({
  theme,
  dataAPI,
  products,
  comments,
  anchorsEl,
  codeValues,
  setComments,
  rowSelected,
  onUpdateRow,
  popoverInfo,
  setAnchorsEl,
  notesMeetings,
  setCodeValues,
  onGenerateEmail,
  onSetEnglishLevel,
  newStartingMonths,
  DEFAULT_EXPIRE_CODE,
  setConfirmDialogData,
  onCloseConfirmDialog,
}) => {
  return (
    <Popover
      open={Boolean(anchorsEl.open)}
      onClose={() => setAnchorsEl((p: INAnchorsEl) => ({ ...p, open: false }))}
      anchorEl={anchorsEl.element}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {anchorsEl.template === "info" && (
        <Box className="bg-edu-800 flex flex-col p-3 text-sm gap-1">
          <Box>
            Course: <b>{popoverInfo?.path}</b>
          </Box>

          <Box>
            English level: <b>{popoverInfo?.english_lvl}</b>
          </Box>

          <Box>
            New starting month: <b>{popoverInfo?.newStartingMonth}</b>
          </Box>
          <hr />
          {popoverInfo.used === 0 ? (
            <>
              <Box className="!h-[1px] bg-edu-600 my-1" />
              <Box>
                Discount: <b>{popoverInfo?.code}</b>
              </Box>

              <Box>
                Discount value: <b>{popoverInfo?.amount}</b>
              </Box>

              <Box>
                Discount expires: <b>{popoverInfo?.expires_at}</b>
              </Box>
            </>
          ) : (
            "Discount Code Expires"
          )}
        </Box>
      )}

      {anchorsEl.template === "comments" && (
        <Box
          sx={{ bgcolor: theme.palette.background.paper }}
          className="flex flex-col gap-3 p-3"
        >
          {Boolean(notesMeetings?.length) && (
            <Accordion
              sx={{ borderRadius: "0.5rem !important" }}
              className="!bg-none !m-0"
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                {notesMeetings?.length < 2
                  ? "Note from meeting"
                  : "Notes from meetings"}
              </AccordionSummary>

              <AccordionDetails className="flex flex-col gap-3 !-m-2">
                {notesMeetings.map((note: any, i: number) => (
                  <Box
                    sx={{ borderColor: theme.palette.primary.main }}
                    key={i}
                    className="border rounded p-3"
                  >
                    <Box
                      sx={{ borderColor: theme.palette.primary.main }}
                      className="flex justify-between gap-4 border-b mb-2"
                    >
                      <Box>{note.interviewer}</Box>
                      <Box>{dayjs(note.start).format("DD MMM YYYY HH:mm")}</Box>
                    </Box>
                    <Box>{note.notes}</Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          <TextField
            multiline
            sx={{ minWidth: "28rem" }}
            rows={10}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder="Write a comment"
          />

          <Button
            variant="outlined"
            disabled={
              rowSelected?.crm_upselling?.comments === comments || !comments
            }
            onClick={() =>
              setConfirmDialogData(
                (p: INConfirmDialog): INConfirmDialog => ({
                  ...p,
                  open: true,
                  title: (
                    <Box className="flex items-center gap-1">
                      <MessageRounded fontSize="small" />
                      <Box>ADD NOTE</Box>
                    </Box>
                  ),
                  text: `Do you want to confirm to add this note at ${rowSelected?.first_name} ${rowSelected?.last_name}?`,
                  onAgree: () =>
                    onUpdateRow({
                      toast: "comments",
                      values: { comments },
                    }),
                  onDisagree: onCloseConfirmDialog,
                })
              )
            }
          >
            SAVE
          </Button>
        </Box>
      )}

      {anchorsEl.template === "otherStatus" && (
        <Box className="bg-edu-800 flex flex-col gap-2 p-3">
          {anchorsEl?.buttons?.map((e: any) => (
            <Button
              key={e}
              variant="outlined"
              onClick={() =>
                onUpdateRow({
                  toast: "change",
                  values: { [anchorsEl.value]: e },
                })
              }
              disabled={rowSelected?.crm_upselling?.[anchorsEl.value] === e}
            >
              {e}
            </Button>
          ))}
        </Box>
      )}

      {anchorsEl.template === "changeEnglishLevel" && (
        <Box className="bg-edu-800 flex flex-col gap-2 p-3">
          {anchorsEl?.buttons?.map((e: any, i: number) => (
            <Button
              key={e + i}
              variant="outlined"
              onClick={() => onSetEnglishLevel(e)}
              disabled={rowSelected?.english_level === e}
            >
              {e}
            </Button>
          ))}
        </Box>
      )}

      {anchorsEl.template === "email" && (
        <Box
          onSubmit={onGenerateEmail}
          component="form"
          className="bg-edu-800 flex flex-col gap-4 p-3 w-72"
        >
          <TextField
            select
            required
            fullWidth
            name="code"
            label="Discount code"
            value={codeValues?.code ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCodeValues((p: INCodeValues) => ({
                ...p,
                code: e.target.value,
              }))
            }
          >
            <MenuItem value="">- Remove -</MenuItem>

            {dataAPI?.codes?.map(
              (c: { id: number; code: string; value: string }, i: number) => (
                <MenuItem
                  key={c.id}
                  value={c.id}
                >
                  <Box className="w-full flex justify-between gap-2">
                    <Box>{c.code}</Box>
                    <Box>-{c.value}</Box>
                  </Box>
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            required
            fullWidth
            type="number"
            name="expires_at"
            label="Expires in day/s"
            value={codeValues?.expires_at ?? DEFAULT_EXPIRE_CODE}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCodeValues((p: INCodeValues) => ({
                ...p,
                expires_at: parseInt(
                  e.target.value.match(/\d+/g)?.join("") ||
                    String(DEFAULT_EXPIRE_CODE)
                ),
              }))
            }
          />

          {[STATUS.StandBy].includes(rowSelected?.crm_upselling?.status) && (
            <>
              <TextField
                select
                required
                fullWidth
                name="newStartingMonth"
                label="New starting month"
                value={codeValues?.newStartingMonth ?? ""}
                onChange={(e: ChangeEvent<any>) =>
                  setCodeValues((p: INCodeValues) => ({
                    ...p,
                    newStartingMonth: e.target.value,
                  }))
                }
              >
                {newStartingMonths?.map((month: string, i: number) => {
                  const v = month.split(" ");

                  return (
                    <MenuItem
                      key={i + month}
                      value={`${MONTHS[v[0]]} ${v[1]}`}
                    >
                      {month}
                    </MenuItem>
                  );
                })}
              </TextField>

              <TextField
                select
                fullWidth
                name="product"
                label="Product"
                value={
                  rowSelected?.crm_upselling?.meetings?.meet?.slice(-1)?.[0]
                    ?.pacchetto ??
                  codeValues?.product ??
                  ""
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCodeValues((p: INCodeValues) => ({
                    ...p,
                    product: e.target.value,
                  }))
                }
              >
                <MenuItem value="">- Remove -</MenuItem>

                {products?.products?.map((product: any) => (
                  <MenuItem
                    key={product.id}
                    value={product.token}
                  >
                    <Tooltip title={product.product_tag}>
                      <Box className="w-full flex justify-between">
                        <Box className="truncate mr-2">
                          {product.product_tag}
                        </Box>
                        <Box>€{(product?.price / 100).toFixed(2)}</Box>
                      </Box>
                    </Tooltip>
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}

          <Button
            fullWidth
            type="submit"
            variant="contained"
          >
            Generate email
          </Button>
        </Box>
      )}
    </Popover>
  );
};

export default memo(PopoverUpselling);
