import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { clone, jS } from "@/components/useHelpers";
import { queryClient } from "@/main";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Typography, Button, useTheme } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import WEEK from "@/components/assets/WEEK";
import useAPI from "@/api/useAPI";
import UndoIcon from "@mui/icons-material/Undo";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const HTTP_ROUTE_UPDATE = "/v1/availabilities/edit";

export interface IRef {
  open: (row: {
    name: string;
    token: string;
    availabilities: Array<any>;
  }) => void;
}

interface IProps {
  keyQuery?: Array<any>;
  hideButtons?: boolean;
}

export default forwardRef<IRef, IProps>(({ keyQuery, hideButtons }, ref) => {
  const theme = useTheme();
  const { snackHandler } = useAuth();

  const [row, setRow] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [start, setStart] = useState<any>();
  const [total, setTotal] = useState<any>(0);
  const [disabled, setDisabled] = useState({
    back: true,
    save: true,
  });

  useImperativeHandle(ref, () => ({
    open: (row) => {
      setRow(row);
    },
  }));

  const hours = Object.keys(row?.availabilities || {});

  const handleSetTotal = (value: any) => {
    const _value = clone(value);
    let total = 0;
    Object.values(_value || {}).forEach((v: any) => {
      delete v.id;
      delete v.hour;
      Object.values(v).forEach((n: any) => (total += Number(n)));
    });
    setTotal(total);
  };

  const handleSetAvailability = (id: string | number, field: string) => {
    setDisabled({ back: false, save: false });
    const dataAvailability = clone(rows);
    const indexRow = dataAvailability.findIndex(
      (r: any) => r.id.toString() === id.toString()
    );
    dataAvailability[indexRow][field] = Number(
      !Boolean(Number(dataAvailability[indexRow][field]))
    );
    setRows(dataAvailability);
    handleSetTotal(dataAvailability);
    if (jS(dataAvailability) === jS(start)) {
      setDisabled({ back: true, save: true });
    }
  };

  const handleResetViewAvailability = () => {
    setRows(start);
    setDisabled((p: any) => ({ ...p, back: true }));
    if (disabled.save) {
      setDisabled((p: any) => ({ ...p, save: false }));
    } else {
      setDisabled((p: any) => ({ ...p, save: true }));
    }
  };

  const handleSaveAvailabilities = (e: any) => {
    if (!row?.token) return;
    setDisabled((p: any) => ({ ...p, save: true }));
    const transformedData = clone(rows).map((item: any) => {
      const _week = {};
      WEEK.filter((w: any) => (_week[w.itaLong] = item[w.itaLong]));

      return Object.keys(_week).map((day: any) => {
        const indexWeek = WEEK.findIndex((e: any) => e.itaLong === day);
        return {
          status: _week[day],
          id: `disp ${item.hour.split("-")[0]}:00 ${WEEK[indexWeek].index}`,
        };
      });
    });

    let final: Array<any> = [];
    transformedData.forEach((t: any) =>
      Object.values(t).forEach((v: any) => final.push(v))
    );

    const array = `[{"Stringa":${jS(final)}}]`;
    useAPI(HTTP_ROUTE_UPDATE, { token: row?.token, array });

    const availabilities = {};
    rows.forEach((row: any) => {
      availabilities[row.id] = { ...row };
      delete availabilities[row.id].id;
      delete availabilities[row.id].hour;
    });
    snackHandler("Save!");

    if (keyQuery || row?.keyQuery) {
      queryClient.invalidateQueries(keyQuery ?? row?.keyQuery);
    }

    handleSetTotal(availabilities);
  };

  const handleClose = () => {
    setRow(undefined);
    setDisabled({ back: true, save: true });
  };

  const columns: GridColDef[] = [
    {
      field: "hour",
      flex: 1,
      hideable: false,
      headerName: "",
    },
    ...WEEK.map(
      (w): GridColDef => ({
        field: w.itaLong as string,
        flex: 1,
        hideable: false,
        sortable: false,
        editable: false,
        filterable: false,
        headerName: w.engShort.toUpperCase() as string,
        headerAlign: "center",
        renderCell: ({ row, id, field }) =>
          !hideButtons ? (
            row[w.itaLong] === 1 ? (
              <Button
                sx={{ width: "100%" }}
                onClick={() => handleSetAvailability(id, field)}
              >
                <CheckCircleIcon color="primary" />
              </Button>
            ) : (
              <Button
                sx={{ width: "100%" }}
                onClick={() => handleSetAvailability(id, field)}
              >
                <CancelIcon color="error" />
              </Button>
            )
          ) : row[w.itaLong] === 1 ? (
            <Box sx={{ width: "100%" }}>
              <CheckCircleIcon color="primary" />
            </Box>
          ) : (
            <Box sx={{ width: "100%" }}>
              <CancelIcon color="error" />
            </Box>
          ),
      })
    ),
  ];

  useEffect(() => {
    const newData = clone(
      hours.map((hour) => ({ id: hour, hour, ...row?.availabilities[hour] }))
    );
    setStart(newData);
    setRows(newData);
    handleSetTotal(newData);
  }, [row]);

  return (
    <EduModal
      fullWidth
      open={Boolean(row?.availabilities)}
      onClose={handleClose}
      maxWidth="md"
    >
      <EduModalTitle onClose={handleClose}>Availabilities</EduModalTitle>

      <Box className="w-full flex justify-between items-center px-8 py-2">
        <Typography>
          {row?.name + (total ? " = " + total : "") || "---"}
        </Typography>

        {!hideButtons && (
          <Box className="flex justify-center items-center gap-4">
            <Box title="edit back">
              <Button
                sx={{ height: "2.3rem", minWidth: "2rem" }}
                color="primary"
                variant="outlined"
                onClick={handleResetViewAvailability}
                disabled={disabled.back}
              >
                <UndoIcon />
              </Button>
            </Box>

            <Box title="save">
              <Button
                sx={{ height: "2.3rem", minWidth: "2rem" }}
                color="primary"
                variant="outlined"
                onClick={handleSaveAvailabilities}
                disabled={disabled.save}
              >
                <SaveIcon />
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <EduModalBody
        sx={{ overflowY: "auto" }}
        className="px-8 pb-4"
      >
        <DataGrid
          autoHeight
          hideFooter
          disableColumnMenu
          disableVirtualization
          disableSelectionOnClick
          sx={getTableStyling(theme)}
          rows={rows}
          columns={columns}
          loading={Boolean(Array.isArray(row?.availabilities))}
          rowHeight={40}
          experimentalFeatures={{ newEditingApi: false }}
        />
      </EduModalBody>
    </EduModal>
  );
});
