import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Rating,
} from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import StarIcon from "@mui/icons-material/Star";
import { Feedback, Payments } from "../crm-upselling.types"; // Assuming Payments now also includes feedback data

const FeedbacksTemplate: React.FC<{ feedbacks: Feedback[] }> = ({
  feedbacks,
}) => {
  if (feedbacks.length === 0) {
    return (
      <Box className="bg-edu-800 flex flex-col p-3 text-sm gap-1">
        <Typography
          variant="h6"
          color="textSecondary"
        >
          No feedbacks available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="bg-edu-800 flex flex-col p-3 text-sm gap-1">
      <Typography
        variant="h6"
        gutterBottom
      >
        User Feedbacks
      </Typography>
      <List>
        {feedbacks.map((feedback) => (
          <Box
            key={feedback.id}
            mb={2}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <FeedbackIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={feedback.first_feedback}
                secondary={new Date(feedback.created_at).toLocaleDateString()}
              />
              <Rating
                value={feedback.rating}
                precision={0.5}
                readOnly
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarIcon fontSize="inherit" />}
              />
            </ListItem>
            <Divider variant="middle" />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default FeedbacksTemplate;
