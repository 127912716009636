import { useState } from "react";
import { euro } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { useTheme } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import Dropdown from "@/components/_Common/Dropdown";
import MyTooltip from "@/components/MyTooltip";
import deleteIcon from "@/assets/icons/delete.svg";
import downloadIcon from "@/assets/icons/download.svg";
import useDownloadPdf from "@/components/useDownloadPdf";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

interface IProps {
  user: any;
  loading: boolean;
}

const InvoiceCard = ({ user, loading }: IProps) => {
  const theme = useTheme();
  const { isAdmin, snackHandler } = useAuth();

  const [invoice, setInvoice] = useState<any>();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  const handleDownloadPdfInvoice = async (row: any) => {
    const res = await useAPI("/v1/users/download/invoice", {
      token: user?.token,
      idInvoice: row?.id,
    });
    const nameFile = `${user?.first_name} ${user?.last_name} - ${row?.type
      } - ${dayjs(row?.data).format("DD-MM-YYYY")}`;
    useDownloadPdf(res, nameFile);
  };

  const handleDelete = async () => {
    setOpenConfirmDelete(false);
    const res = await useAPI(`/v1/users/invoices/${invoice.id}/delete`);
    if (!res?.status) {
      snackHandler(res?.message ?? "Failed delete!", "error");
      return;
    }
    snackHandler("Success delete!");
    setInvoice(undefined);
    user.payments = user.payments.filter(({ id }) => id != invoice.id);
  };

  /**
   * Updates or changes the viewing of invoice state by calling on an api with required
   * parameters
   * @param row the row object on which the params are taken from
   */
  const handleDisableEnableInvoiceViewing = async (row: any) => {
    const response = await useAPI("/v1/users/invoices/change/viewing", {
      id: row.id,
      tokenUser: row.token_user,
      productId: row.product_id,
      canView: row.can_view,
    });

    if (response?.message) {
      snackHandler(response.message);
    } else {
      snackHandler("Invoice viewing not changed");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      width: 80
    },
    {
      field: "data",
      headerName: "Date",
      minWidth: 170,
      flex: 0.3,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 90,
      renderCell: ({ row }) => euro(row.amount),
    },
    {
      field: "paid_for",
      headerName: "Product",
      minWidth: 220,
      flex: 1,
      renderCell: ({ row }) =>
        row?.paid_for ? <MyTooltip>{row?.paid_for}</MyTooltip> : "-",
    },
    {
      field: "type",
      width: 100,
    },
    {
      field: "quantity",
      width: 90,
    },
    ...(isAdmin
      ? [
        {
          field: "url_drive",
          headerName: "",
          width: 70,
          editable: false,
          hideable: false,
          sortable: false,
          filterable: false,
          disableExport: true,
          renderCell: ({ row }) => {
            const canShow = Number(row?.can_view)
              ? "Disable invoice viewing"
              : "Enable invoice viewing";

            return (
              <Dropdown text={<MoreVert />}>
                <MenuList>
                  <MenuItem
                    color="primary"
                    title={canShow}
                    onClick={() => handleDisableEnableInvoiceViewing(row)}
                  >
                    <ListItemIcon>
                      <img
                        src={downloadIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>{canShow}</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => handleDownloadPdfInvoice(row)}>
                    <ListItemIcon>
                      <img
                        src={downloadIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Download PDF</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setInvoice(row);
                      setOpenConfirmDelete(true);
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={deleteIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </MenuList>
              </Dropdown>
            );
          },
        },
      ]
      : []),
  ];

  return (
    <>
      <DataGrid
        autoHeight
        hideFooter
        disableColumnMenu
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={user?.payments || []}
        columns={columns}
        loading={loading}
        pageSize={20}
        rowsPerPageOptions={[20]}
        components={{ Pagination: CustomPaginationClient }}
        initialState={{
          sorting: { sortModel: [{ field: "date", sort: "desc" }] },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <ConfirmationDialog
        open={Boolean(invoice) && openConfirmDelete}
        onClose={() => {
          setInvoice(undefined);
          setOpenConfirmDelete(false);
        }}
        title={
          <>
            <img src={deleteIcon} /> DELETE INVOICE
          </>
        }
        onConfirm={handleDelete}
      >
        Do you want to delete invoice?
      </ConfirmationDialog>
    </>
  );
};

export default InvoiceCard;
