import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Collapse,
  useTheme,
  Box,
} from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import ReportSection from "./ReportSection";
import { EventReportDetails } from "../interfaces";
import { blue } from "@mui/material/colors";

const EventDetailsModal = forwardRef(({ event, onClose }: any, ref) => {
  const [open, setOpen] = useState(false);
  const [openSections, setOpenSections] = useState({
    grammar: false,
    vocabulary: false,
    pronunciation: false,
  });
  const theme = useTheme();

  useImperativeHandle(ref, () => ({
    openModal: () => setOpen(true),
    closeModal: () => setOpen(false),
  }));

  if (!event) return null;

  const handleSectionToggle = (section: string) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
    >
      <EduModalTitle onClose={() => setOpen(false)}>
        Event Details
      </EduModalTitle>
      <EduModalBody className="p-5">
        <Typography variant="h6">{event.title}</Typography>
        <Typography variant="subtitle1">
          Tutor: {`${event.tutors.first_name} ${event.tutors.last_name}`}
        </Typography>
        <Typography variant="subtitle1">Date: {event.date}</Typography>

        {["grammar", "vocabulary", "pronunciation"].map((section) => (
          <Box
            bgcolor={theme.palette.secondary.main}
            style={{ padding: "0.5rem", borderRadius: "4px" }}
            key={section}
            my={2}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                component="div"
                onClick={() => handleSectionToggle(section)}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Typography>
              <IconButton onClick={() => handleSectionToggle(section)}>
                {openSections[section] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
            <Collapse in={openSections[section]}>
              <ReportSection
                title={section.charAt(0).toUpperCase() + section.slice(1)}
                entries={
                  event.report
                    ? event.report[section as keyof EventReportDetails]
                    : []
                }
              />
            </Collapse>
          </Box>
        ))}
      </EduModalBody>
    </EduModal>
  );
});

export default EventDetailsModal;
