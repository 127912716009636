import { Info } from "@mui/icons-material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import TimelineGen from "./TimelineGen";

interface INProps {
  user: any;
  open: boolean;
  onClose: () => void;
}

const ViewScreeningInfoDialog = ({ open, onClose, user }: INProps) => {
  if (!user) return null;

  const screeingInfo = user?.screening_info;

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <EduModalTitle onClose={onClose}>
        <Info
          fontSize="small"
          className="mr-3"
        />
        Info meetings - {user.first_name} {user.last_name}
      </EduModalTitle>

      <EduModalBody className="px-6 pt-5 pb-6">
        {Boolean(user.screening_info) && (
          <TimelineGen
            isMeet
            data={screeingInfo}
            title="Meeting"
          />
        )}
      </EduModalBody>
    </EduModal>
  );
};

export default ViewScreeningInfoDialog;
