import { addWeeks, endOfISOWeek, endOfWeek, startOfISOWeek, startOfWeek, subWeeks } from "date-fns";
import { useState } from "react";

export default () => {
  const [weekStart, setWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [weekEnd, setWeekEnd] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }));

  const handleNextWeek = () => {
    setWeekStart(startOfISOWeek(addWeeks(weekStart, 1)));
    setWeekEnd(endOfISOWeek(addWeeks(weekEnd, 1)));
  };

  const handlePrevWeek = () => {
    setWeekStart(subWeeks(weekStart, 1));
    setWeekEnd(subWeeks(weekEnd, 1));
  };

  return { weekStart, weekEnd, handleNextWeek, handlePrevWeek };
};
