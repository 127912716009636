import { MenuItem, TextField } from "@mui/material";

const SelectParts = ({ value, setValue }) => {
  return (
    <TextField
      select
      label="Number part Writing"
      className="w-full"
      InputLabelProps={{
        shrink: true,
        style: { border: "none" },
      }}
      color="primary"
      variant="outlined"
      value={value || 0}
      onChange={({ target: { value: v } }) => setValue(v)}
    >
      <MenuItem value={0} selected>
        SELECT
      </MenuItem>
      <MenuItem value={1}>One part</MenuItem>
      <MenuItem value={2}>Both parts</MenuItem>
    </TextField>
  );
};

export default SelectParts;
