export default (props: { setFilters: Function; filters: URLSearchParams }) => {
  const { filters, setFilters } = props;
  const filterData = Object.fromEntries(filters.entries());
  let page = filterData?.page || 1;
  const setPage = (value) => {
    setFilters((prev) => {
      prev.set("page", value);
      return prev;
    });
  };

  return {
    page,
    setPage,
  };
};
