import { useState, useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const sleep = (delay = 0) => new Promise((res) => setTimeout(res, delay));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteInput = ({
  data,
  label,
  value,
  setValue,
  multiple,
  closeSelect,
  optionsLabel,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Array<any>>([]);

  const loading = open && !options?.length;

  const handleChange = (_, newValue) => setValue(newValue);

  useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      await sleep(1e3); // For demo purposes.
      if (active) setOptions([...data]);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  return (
    <Autocomplete
      id={label}
      open={open}
      value={value}
      color="primary"
      onOpen={() => setOpen(true)}
      options={options}
      loading={loading}
      onClose={() => setOpen(false)}
      multiple={multiple}
      onChange={handleChange}
      disableCloseOnSelect={closeSelect}
      isOptionEqualToValue={(option, value) =>
        option?.id === value?.id ||
        (option?.token && option?.token === value?.token)
      }
      getOptionLabel={(option) =>
        optionsLabel
          ? option.name + " - " + option.type + " - " + option?.english_level
          : option.type
      }
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={(props as any).key + props["data-option-index"]}
        >
          <Checkbox
            icon={icon}
            style={{ marginRight: 8 }}
            checked={selected}
            checkedIcon={checkedIcon}
          />
          {option.name +
            " - " +
            (option?.typeV2 ?? option.type) +
            " - " +
            (option.modality ?? "no modality") +
            " - " +
            (option?.english_level ?? "no level")}
        </li>
      )}
      ChipProps={{ color: "primary", variant: "outlined" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label + " *"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="primary"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteInput;
