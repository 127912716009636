import { Dispatch, SetStateAction } from "react";
import { Box, TextField, Button } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import saveIcon from "@/assets/icons/save.svg";

interface ICDProps {
  open: boolean;
  title: string;
  content: string;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  userScore: number | null;
  setUserScore: (userScore: number | null) => void;
  textButtonAgree: string;
  setCorrectionData: (userScore: string | null) => void;
  textButtonDisagree: string;
  loadingButtonSaveEdit: boolean;
  setLoadingButtonSaveEdit: Dispatch<SetStateAction<boolean>>;
}

const EditDialog = ({
  open,
  title,
  content,
  setOpen,
  userScore,
  onConfirm,
  setUserScore,
  setCorrectionData,
  loadingButtonSaveEdit,
  setLoadingButtonSaveEdit,
}: ICDProps) => {
  const handleClose = () => {
    setUserScore(null);
    setOpen(false);
  };

  const handleChange = (e: any) => {
    const currentScore = e.target.value;
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString();
    setUserScore(currentScore);
    setCorrectionData(currentDate + " " + currentTime);
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <EduModalTitle onClose={handleClose}>{title}</EduModalTitle>

      <EduModalBody className="flex flex-col gap-4 p-5">
        <Box>{content}</Box>

        <TextField
          required
          type="text"
          name="user_score"
          label="Score"
          value={userScore ?? ""}
          onChange={(e) => handleChange(e)}
          placeholder="3.5"
        />

        <Box className="flex justify-end">
          <Button
            type="submit"
            variant="outlined"
            onClick={() => {
              setLoadingButtonSaveEdit(true);
              onConfirm();
            }}
            disabled={loadingButtonSaveEdit}
          >
            <img
              src={saveIcon}
              className="icon"
            />
            Save
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

EditDialog.defaultProps = {
  title: "Student's Exercise",
  textButtonAgree: "Save",
  textButtonDisagree: "Close",
};

export default EditDialog;
