import {
  useState,
  useEffect,
  createRef,
  MouseEvent,
  ChangeEvent,
  useCallback,
} from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Popover, TextField, useTheme } from "@mui/material";
import CreateNewUserDialog, {
  IForwardRef as IRefNewUser,
} from "@/components/Crm/CreateNewUserDialog";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import addIcon from "@/assets/icons/add.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useCrmApi from "@/api/useCrmApi";
import useScreen from "@/hooks/useScreen";
import filterIcon from "@/assets/icons/filter.svg";
import searchIcon from "@/assets/icons/search.svg";
import FiltersBar from "../_Common/FiltersBar";
import useDebounce from "@/hooks/useDebounce";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import IndexCrmTable from "./IndexCrmTable";
import useProductApi from "@/api/useProductApi";
import SelectOriginFilter from "./SelectOriginFilter";
import SelectStatusFilter from "./SelectStatusFilter";
import SelectOwnedByFilter from "./SelectOwnedByFilter";
import CrmTableFilterDropdown from "./CrmTableFilterDropdown";

export const STATUS_OFFER_REJECTED = {
  all: [
    "Price too high",
    "Not enough time",
    "Felt pressured",
    "Looked for in person course",
    "No real interest in a course",
    "Chose alternative",
    "Other",
  ],
  required: ["Chose alternative", "Other"],
};

const FILTER = {
  NOW: dayjs().format("YYYY-MM-DD"),
  DATE: "DATE",
  RESET: "FILTER",
  SEARCH: "SEARCH",
  TOMORROW: dayjs().add(1, "D").format("YYYY-MM-DD"),
};

const CrmTable = ({
  sales,
  setUsers,
  calendars,
  handleOpenMail,
  copyToClipboard,
  invalidateQuery,
  handleUpdateRow,
  getReminderLink,
  handleOpenDialog,
  getReminderLink2,
  getReminderLink3,
  getReminderLink4,
  sendDiscountCode,
  handleOpenCrmCall,
  handleOpenDialogType,
  handleOpenCalendarsEvent,
  handleSendTemplatesWithWati,
  handleAlsoAddOnOtherVersion,
  handleSendEmailIncorectNumber,
  getReminderLinkAfterRescheduled,
}) => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { isMember, userAdmin } = useAuth();
  const { data: products } = useProductApi().callGetProducts();
  const { callUpdateCrm, callDownloadCrm, callGetCrm } = useCrmApi();

  const { data: origins } = useQuery(["origins-crm-filter"], () =>
    useAPI("/v1/crm/get-origins")
  );

  const refNewUser = createRef<IRefNewUser>();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  const page = filterData?.page || 1;

  const [userId, setUserId] = useState<string>("");
  const [popupInfo, setPopupInfo] = useState<string>("");
  const [pagination, setPagination] = useState<any>({});
  const [filterMeetings, setFilterMeetings] = useState<string>(FILTER.RESET);
  const [tempCommentState, setTempCommentState] = useState<any>("");

  const debouncedSearch = useDebounce(filterData?.search, 500);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  const [anchorElFilter, setAnchorElFilter] =
    useState<HTMLButtonElement | null>(null);
  const openFilter = Boolean(anchorElFilter);
  const idFilter = openFilter ? "simple-popover" : undefined;

  const [anchorElConvertStatus, setAnchorElConvertStatus] =
    useState<HTMLButtonElement | null>(null);
  const popoverConvertStatusOpen = Boolean(anchorElConvertStatus);
  const idConvertStatus = popoverConvertStatusOpen
    ? "convert-status-popover"
    : undefined;

  const [anchorElSelectMeeting, setAnchorElSelectMeet] =
    useState<HTMLButtonElement | null>(null);
  const popoverSelectMeetOpen = Boolean(anchorElSelectMeeting);
  const idSelectMeet = popoverSelectMeetOpen
    ? "select-meet-popover"
    : undefined;

  // remove search
  let filter = { ...filterData };
  delete filter?.search;
  const sorts = filter?.sorts;
  delete filter?.sorts;
  delete filter?.page;

  const { data, isLoading } = callGetCrm(
    { page, filter, search: debouncedSearch, per_page: perPage, sorts },
    (res: any) => setUsers(res.data)
  );

  const { data: utmSourceItems } = useQuery(["utm_source"], () =>
    useAPI("/v1/crm/utm_source")
  );

  const users = data?.data;

  const paging = {
    total: data ? data?.total : 1,
    last_page: data ? data.last_page : 1,
    current_page: data ? data.current_page : 1,
  };

  const handleCloseFilter = () => setAnchorElFilter(null);

  const setPage = (value: any) =>
    setFilters((p: any) => {
      p.set("page", value);
      return p;
    });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setFilters((prev) => {
      if (value) {
        prev.set("search", value);
      } else prev.delete("search");
      return prev;
    });

    setPage(1);

    if (filterMeetings == "SEARCH") return;

    setFilterMeetings((p: string) => {
      if (p != FILTER.SEARCH) {
        return FILTER.SEARCH;
      }

      return p;
    });
  };

  const handlePopover = useCallback(
    (
      event: MouseEvent<HTMLButtonElement>,
      userId: string,
      prevComments: any,
      info: string
    ) => {
      if (!userId) return;
      setPopupInfo(info);
      setUserId(userId);
      setAnchorEl(event.currentTarget as HTMLButtonElement);
      setTempCommentState(prevComments);
    },
    []
  );

  const handlePopoverSelectMeet = useCallback(
    (event: MouseEvent<HTMLButtonElement>, userId: string | null) => {
      if (userId == "" || userId == null || userId == undefined) return;
      setUserId(userId);
      setAnchorElSelectMeet(event.currentTarget);
    },
    []
  );

  const handlePopoverConvertStatus = useCallback(
    (event: MouseEvent<HTMLButtonElement>, userId: string) => {
      if (userId == "" || userId == null || userId == undefined) return;
      setUserId(userId);
      setAnchorElConvertStatus(event.currentTarget);
    },
    []
  );

  const handlePopoverClose = () => setAnchorEl(null);

  const handlePopoverSelectMeetClose = () => setAnchorElSelectMeet(null);

  const handlePopoverConvertStatusClose = () => setAnchorElConvertStatus(null);

  const handleChangeComment = (e: { target: { value: string } }) =>
    setTempCommentState(e.target.value);

  const handleFilterChange = ({ target: { name, value } }) => {
    setFilters((p) => {
      if (value) p.set(name, value);
      else p.delete(name);
      p.set("page", "1");
      return p;
    });
  };

  // meeting information
  const meeting = tempCommentState?.meeting
    ? JSON.parse(tempCommentState.meeting).meet
    : null;

  let pacchetto: any = "";
  let motivation: string = "";
  let english_lvl: string = "";

  if (meeting && meeting.length > 0) {
    meeting.forEach((m: any) => {
      if (!pacchetto) pacchetto = m.pacchetto;
      if (!motivation) motivation = m.motivation;
      if (!english_lvl) english_lvl = m.english_lvl;
    });

    pacchetto = products?.products?.find((p: any) => p.token === pacchetto);
    pacchetto = pacchetto?.product_tag;
  }

  useEffect(() => {
    if (
      (paging.last_page == 1 && isLoading) ||
      paging.last_page == pagination.last_page
    )
      return;
    if (paging?.total == 0) return;

    setPagination(paging);
  }, [paging]);

  return (
    <Box>
      <Box className="flex flex-wrap flex-col justify-between">
        <Box
          id="filter_bar"
          className="flex gap-4 flex-wrap !items-stretch"
        >
          <FiltersBar>
            <Button
              variant="outlined"
              onClick={() => setFilters({})}
            >
              <img
                src={refreshIcon}
                className="icon"
              />
              Refresh
            </Button>

            <Button
              variant="outlined"
              onClick={() => refNewUser.current?.open()}
            >
              <img
                src={addIcon}
                className="icon"
              />
              Add manually
            </Button>

            <Dropdown
              text="Filter"
              icon={filterIcon}
              active={Boolean(
                Object.keys(filterData).filter(
                  (key) => !["search", "sorts", "page"].includes(key)
                ).length
              )}
              onClick={() => setFilterMeetings("FILTER")}
            >
              <CrmTableFilterDropdown
                sales={sales}
                filterData={filterData}
                setFilters={setFilters}
                utmSourceItems={utmSourceItems ?? []}
                handleFilterChange={handleFilterChange}
              />
            </Dropdown>

            {(!isMember ||
              userAdmin?.user?.email == "admission@edusogno.com") && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => callDownloadCrm()}
                >
                  <img
                    src={downloadIcon}
                    className="icon"
                  />
                  Download
                </Button>
              )}
          </FiltersBar>

          <TextField
            sx={{ marginLeft: "auto", maxWidth: "200px" }}
            size="small"
            name="search_input"
            value={filterData?.search || ""}
            variant="outlined"
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <img
                  src={searchIcon}
                  style={{ marginRight: 10, filter: theme.palette.filter }}
                />
              ),
            }}
            placeholder="Search..."
            autoComplete="off" //disable suggestions
          />
        </Box>
      </Box>

      <IndexCrmTable
        {...{
          id,
          page,
          users,
          setPage,
          perPage,
          sortModel,
          isLoading,
          calendars,
          setFilters,
          pagination,
          handlePopover,
          handleOpenMail,
          invalidateQuery,
          handleUpdateRow,
          copyToClipboard,
          getReminderLink,
          getReminderLink2,
          getReminderLink3,
          getReminderLink4,
          handleOpenDialog,
          handleOpenCrmCall,
          sendDiscountCode,
          onSortModelChange,
          handleOpenDialogType,
          handlePopoverSelectMeet,
          handleOpenCalendarsEvent,
          handlePopoverConvertStatus,
          handleSendTemplatesWithWati,
          handleAlsoAddOnOtherVersion,
          handleSendEmailIncorectNumber,
          getReminderLinkAfterRescheduled,
          filters: filterData,
        }}
      />

      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className="bg-edu-800 flex flex-col gap-4 p-4">
          {popupInfo === "comment" && (
            <>
              <TextField
                multiline
                rows={5}
                value={tempCommentState}
                onChange={handleChangeComment}
                placeholder="Write a comment"
              />

              <Button
                type="submit"
                variant="outlined"
                onClick={() =>
                  callUpdateCrm.mutate({
                    id: parseInt(userId),
                    data: { comments: tempCommentState },
                  })
                }
              >
                Save
              </Button>
            </>
          )}

          {popupInfo === "info" && (
            <Box className="text-sm">
              <Box>Course: {pacchetto}</Box>
              <Box>Motivation: {motivation}</Box>
              <Box>English Level: {english_lvl}</Box>
            </Box>
          )}
        </Box>
      </Popover>

      <Popover
        id={idSelectMeet}
        open={popoverSelectMeetOpen}
        onClose={handlePopoverSelectMeetClose}
        anchorEl={anchorElSelectMeeting}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className="bg-edu-800 flex flex-col p-3 gap-2">
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              callUpdateCrm.mutate({
                id: parseInt(userId),
                data: { meeting_status: "ci sarà" },
              });
              handlePopoverSelectMeetClose();
            }}
          >
            Present
          </Button>

          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              callUpdateCrm.mutate({
                id: parseInt(userId),
                data: { meeting_status: "non risponde" },
              });
              handlePopoverSelectMeetClose();
            }}
          >
            No reply
          </Button>

          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              callUpdateCrm.mutate({
                id: parseInt(userId),
                data: { meeting_status: "other" },
              });
              handlePopoverSelectMeetClose();
            }}
          >
            Other
          </Button>
        </Box>
      </Popover>

      <Popover
        id={idConvertStatus}
        open={popoverConvertStatusOpen}
        onClose={handlePopoverConvertStatusClose}
        anchorEl={anchorElConvertStatus}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className="bg-edu-800 flex flex-col p-3 gap-2">
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              callUpdateCrm.mutate({
                id: parseInt(userId),
                data: { convert_status: "soon buying" },
              });
              handlePopoverConvertStatusClose();
            }}
          >
            SOON BUYING
          </Button>

          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              callUpdateCrm.mutate({
                id: parseInt(userId),
                data: { convert_status: "no reply" },
              });
              handlePopoverConvertStatusClose();
            }}
          >
            NO REPLY
          </Button>

          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              callUpdateCrm.mutate({
                id: parseInt(userId),
                data: { convert_status: "other" },
              });
              handlePopoverConvertStatusClose();
            }}
          >
            OTHER
          </Button>
        </Box>
      </Popover>

      <Popover
        id={idFilter}
        open={openFilter}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box className="bg-edu-800 flex flex-col w-[250px] gap-5 p-5">
          <SelectStatusFilter
            status={filterData?.status || ""}
            onChange={handleFilterChange}
          />

          <SelectOriginFilter
            origin={filterData?.origin || ""}
            items={origins || []}
            onChange={handleFilterChange}
          />

          <SelectOwnedByFilter
            ownedBy={filterData?.ownedBy || ""}
            items={sales || []}
            onChange={handleFilterChange}
          />
        </Box>
      </Popover>

      <CreateNewUserDialog ref={refNewUser} />
    </Box>
  );
};

export default CrmTable;
