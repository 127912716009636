import {
  Box,
  Dialog,
  styled,
  Button,
  IconButton,
  DialogTitle,
} from "@mui/material";
import { Close, Crop75, FilterNone } from "@mui/icons-material";
import classNames from "classnames";
import { Dispatch, SetStateAction, useState } from "react";

export const EduModal = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
}));

const EduModalTitleStyles = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "white",
  display: "flex",
  alignItems: "center",
  padding: 10,
  fontWeight: 600,
  fontSize: "1rem",
  "& img, .icon": {
    marginRight: 10,
    filter: "brightness(0) invert(1)",
  },
  textTransform: "uppercase",
}));

export const EduModalBody = styled(Box)(({ theme }) => ({
  background: theme.palette.leftSideBarSub.main,
  overflowY: "auto",
}));

export const EduButtonFooter = styled(Button)(({ theme }) => ({
  "& img": {
    filter: theme.palette.filter,
    marginRight: 5,
  },
}));

export interface DialogTitleProps {
  id?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  className?: string;
  isMaximized?: boolean;
  setIsMaximized?: Dispatch<SetStateAction<boolean>>;
}

export const EduModalTitle = ({
  children,
  onClose,
  isMaximized,
  setIsMaximized,
  ...other
}: DialogTitleProps) => {
  return (
    <EduModalTitleStyles {...other}>
      {children}

      <Box className="absolute top-2 right-2 flex gap-2">
        {Boolean(setIsMaximized) && (
          <IconButton
            aria-label="maximize"
            onClick={() => setIsMaximized && setIsMaximized((p) => !p)}
            sx={{
              color: (theme) => theme.palette.primary.main,
              background: "white !important",
              borderRadius: "8px",
              width: "25px",
              height: "25px",
            }}
          >
            {isMaximized ? (
              <FilterNone sx={{ fontSize: "1rem", scale: "-1" }} />
            ) : (
              <Crop75 />
            )}
          </IconButton>
        )}

        {Boolean(onClose) && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.primary.main,
              background: "white !important",
              borderRadius: "8px",
              width: "25px",
              height: "25px",
            }}
          >
            <Close />
          </IconButton>
        )}
      </Box>
    </EduModalTitleStyles>
  );
};

interface IEduModalIFrame {
  link?: string;
  title?: any;
  onClose: () => void;
  maxWidth?: "sm" | "md" | "lg" | "xs" | "xl";
  className?: string;
}

export const EduModalIFrame = ({
  title,
  link,
  onClose,
  maxWidth = "xl",
  className,
}: IEduModalIFrame) => (
  <EduModal
    fullWidth
    open={Boolean(link)}
    onClose={onClose}
    maxWidth={maxWidth}
  >
    {Boolean(title) && <EduModalTitle onClose={onClose}>{title}</EduModalTitle>}

    <EduModalBody className={classNames("h-[80vh]", className)}>
      <iframe
        title="iframe-preview"
        src={link}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          transformOrigin: "0 0",
        }}
      />
    </EduModalBody>
  </EduModal>
);
