import axios from "axios";
import { getCookie } from "../components/Cookie";
const BASE_URL = import.meta.env.VITE_BASE_URL;
const BASE_URL_USER = import.meta.env.VITE_USER_BASE_URL;

let token = getCookie("user");

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export const apiFile = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

export const apiUser = axios.create({
  baseURL: BASE_URL_USER,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

//ADMIN LOGGED USER

export const login = async ({ email, password }) => {
  try {
    const { data } = await api.post("/v1/login", { email, password });
    // data.user.routes = JSON.parse(data.user.routes);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const auth = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  // return api.defaults.headers;

  if (!Boolean(tk)) throw Error("Authorization required");
  try {
    const { data } = await api.post("/v1/auth");
    // data.user.routes = JSON.parse(data.user.routes);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const logout = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post("/v1/logout");
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const team = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post("/v1/team");
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateThisUser = async (id, tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/${id}/update`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateTeamUser = async (id, tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/${id}/auth-update`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// CRM

export const crmCreate = async ({ obj }) => {
  try {
    const { data } = await api.post(`/v1/crm`, { ...obj });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const crmAll = async (tk, params) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.get(`/v1/crm`, { params });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const crmUpdateData = async ({ id, tk, newUpdate }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.put(`/v1/crm/${id}`, { ...newUpdate });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const crmSendMail = async ({ tk, obj }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.post(`/v1/crmMail`, { ...obj });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// USERS

export const usersAll = async (tk, params = null) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.get(`/v1/users`, { params });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getUser = async (userToken, tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.get(`/v1/users/${userToken}`);
    return data[0];
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getUserWithDates = async (userToken, tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.get(`/v1/users/with_date/${userToken}`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const userUpdate = async ({ id, tk, userToUpdate }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  // return { id, tk, userToUpdate };

  try {
    const { data } = await api.post(`/v1/users/${id}/update`, {
      ...userToUpdate,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

//EVENTS

export const getAllDataEvents = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  const userRequest = apiUser.get("v1/users/all");
  const tutorRequest = api.get("v1/tutors/all");

  try {
    const response = await axios.all([userRequest, tutorRequest]);
    return { users: response[0].data, tutors: response[1].data };
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const createSchoolEvent = async ({ tk, data }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const response = await api.post("v1/events/create", data);
    return response;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const createMultipleSchoolEvent = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "v1/events/createBulk", data, {
      headers: {
        Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

//CALENDARS
export const infoCalendars = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post("v1/recruiter");
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const setIndisponibility = async ({ tk, info, token }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(
      `v1/recruiter/${token}/indisponibility`,
      info
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const deleteIndisponibility = async ({ tk, info, token }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(
      `v1/recruiter/${token}/indisponibility/delete`,
      info
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

//INVOICES

export const getInvoices = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/users/invoices`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getPaymentsOut = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.get(`/v1/users/payments-out`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// export const getCorrispettivi = async (tk) => {
//   api.defaults.headers["Authorization"] = `Bearer ${tk}`;

//   try {
//     const { data } = await api.post(`/v1/users/corrispettivi`);
//     const incomes = data[0];
//     const outcomes = data[1];
//     const stripe = data[2];
//     const klarna = data[3];
//     const bonifico = data[4];
//     const e_invoices = data[5];

//     const allDates = new Set([...incomes.map(i => i.dataI), ...outcomes.map(o => o.dataO)]);

//     allDates.forEach(date => {
//       const incomeItem = incomes.find(i => i.dataI === date) || {};
//       const outcomeItem = outcomes.find(o => o.dataO === date) || {};

//       if (!incomeItem.dataI) {
//         incomes.push({
//           id: outcomeItem.id || `temp-${date}`,
//           incomes: 0,
//           dataI: date
//         });
//       }

//       if (!outcomeItem.dataO) {
//         outcomes.push({
//           id: incomeItem.id || `temp-${date}`,
//           outcomes: 0,
//           dataO: date
//         });
//       }
//     });

//     for (let i = 0; i < incomes.length; i++) {
//       for (let j = 0; j < outcomes.length; j++) {
//         if (incomes[i].dataI === outcomes[j].dataO) {
//           incomes[i].outcomes = outcomes[j].outcomes;
//         }
//       }
//       for (let k = 0; k < stripe.length; k++) {
//         if (incomes[i].dataI === stripe[k].dataS) {
//           incomes[i].stripe = stripe[k].stripe;
//         }
//       }
//       for (let k = 0; k < klarna.length; k++) {
//         if (incomes[i].dataI === klarna[k].dataK) {
//           incomes[i].klarna = klarna[k].klarna;
//         }
//       }
//       for (let k = 0; k < bonifico.length; k++) {
//         if (incomes[i].dataI === bonifico[k].dataB) {
//           incomes[i].bonifico = bonifico[k].bonifico;
//         }
//       }
//       for (let k = 0; k < e_invoices.length; k++) {
//         if (incomes[i].dataI === e_invoices[k].dataE) {
//           incomes[i].e_invoices = e_invoices[k].e_invoices;
//         }
//       }

//       if (incomes[i].outcomes)
//       incomes[i].total = incomes[i].incomes - incomes[i].outcomes;
//     else incomes[i].total = incomes[i].incomes;

//     incomes[i].total =
//     Math.round((incomes[i].total * Math.pow(10, 2)).toFixed(1)) /
//     Math.pow(10, 2).toFixed(2);
//   }

//     return incomes;
//   } catch (error) {
//     throw Error(error.response.data.message);
//   }
// };

export const getCorrispettivi = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/users/corrispettivi`);
    const [
      incomes,
      outcomes,
      stripe,
      klarna,
      bonifico,
      e_invoices,
      pagolight,
      alma,
    ] = data;

    const allDates = new Set([
      ...incomes.map((i) => i.dataI),
      ...outcomes.map((o) => o.dataO),
    ]);

    allDates.forEach((date) => {
      const incomeItem = incomes.find((i) => i.dataI === date) || {};
      const outcomeItem = outcomes.find((o) => o.dataO === date) || {};

      if (!incomeItem.dataI) {
        incomes.push({
          id: outcomeItem.id || `temp-${date}`,
          incomes: 0,
          dataI: date,
        });
      }

      if (!outcomeItem.dataO) {
        outcomes.push({
          id: incomeItem.id || `temp-${date}`,
          outcomes: 0,
          dataO: date,
        });
      }
    });

    const integrateData = (
      mainData,
      integrationData,
      mainDateKey,
      integrationDateKey,
      integrationKey
    ) => {
      mainData.forEach((item) => {
        const foundItem = integrationData.find(
          (i) => i[integrationDateKey] === item[mainDateKey]
        );
        if (foundItem) {
          item[integrationKey] = foundItem[integrationKey];
        }
      });
    };

    integrateData(incomes, outcomes, "dataI", "dataO", "outcomes");
    integrateData(incomes, stripe, "dataI", "dataS", "stripe");
    integrateData(incomes, klarna, "dataI", "dataK", "klarna");
    integrateData(incomes, bonifico, "dataI", "dataB", "bonifico");
    integrateData(incomes, e_invoices, "dataI", "dataE", "e_invoices");
    integrateData(incomes, pagolight, "dataI", "dataP", "pagolight");
    integrateData(incomes, alma, "dataI", "dataA", "alma");

    incomes.forEach((item) => {
      item.total = item.outcomes ? item.incomes - item.outcomes : item.incomes;
      item.total = Math.round((item.total * 100).toFixed(1)) / 100;
    });

    return incomes;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// PRODUCTS

export const getProducts = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/products`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateProduct = async ({ tk, productData, id }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/products/${id}/update`, {
      ...productData,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getChangelogs = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  // return tk;

  try {
    const { data } = await api.post(`/v1/changelog`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const newChangelog = async ({ tk, dataLog }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/changelog/create`, dataLog);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// CALENDAR EVENTS

export const getAllEvents = async ({ calendarsEvents, signal }) => {
  // return calendarsEvents;
  try {
    const { data } = await api.post(`/v1/calendar-events`, calendarsEvents, {
      signal,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getAvailableCalendars = async ({
  signal,
  queryName,
  isAdmission,
  isUpselling,
  token,
  limit = 14,
}) => {
  const calendar = queryName.length > 3 ? queryName.at(-1) : null;

  try {
    const { data } = await api.post(
      `/v1/calendar-list`,
      { admin: 1, calendar, isAdmission, isUpselling, token, limit },
      { signal }
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const createNewEvent = async ({ event }) => {
  try {
    const { data } = await api.post(`/v1/calendar-events/create`, event);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const resceduleEventMeeting = async ({ event }) => {
  // return calendarsEvents;
  try {
    const { data } = await api.post(`/v1/calendar-events/create`, event);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// INFLUENCERS LINKS

export const getInfluencersLink = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.post(`/v1/influencers/all`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const createInfluencerLink = async ({ tk, newData }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.post(`/v1/influencers/create`, newData);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// USERS LIST

export const listWithSecondPayment = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.post(`/v1/listWithSecondPayment`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const userListAudio = async ({ id, tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.post(`/v1/users/userListAudio/${id}`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// OVERVIEW LIST

export const getAdsPerformanceIndex = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.post(`/v1/overview`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// STATISTICS LIST

export const getStatisticsIndex = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  try {
    const { data } = await api.post(`/v1/statistics`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// UTILS

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  setSnackBarMessage("Copied: " + text);
  setOpenSnack(true);
};

export const confirmPayment = async ({ tk, postData }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/users/confirm-payment`, {
      ...postData,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const registerNewAdmin = async ({ tk, postData }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/register`, {
      ...postData,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateAdmin = async ({ tk, postData }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  const id = postData.id;

  try {
    const { data } = await api.post(`/v1/${id}/auth-update`, {
      ...postData,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateAvatarAdmin = async ({ tk, id, formData }) => {
  apiFile.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await apiFile.post(
      `/v1/${id}/team/upload-avatar`,
      formData
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const uploadTeamContractAdmin = async ({ tk, id, formData }) => {
  apiFile.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await apiFile.post(
      `/v1/${id}/team/upload-contract`,
      formData
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const deleteAdmin = async ({ tk, postData }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  const id = postData.id;

  try {
    const { data } = await api.post(`/v1/${id}/auth-delete`, {
      ...postData,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getEventsUser = async ({ tk, id }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/events/presences`, { token: id });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const addMaterial = async ({ tk, postData }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;
  api.defaults.headers.common["Content-Type"] = "multipart/mixed";

  try {
    const { data } = await api.post(`/v1/material/create`, postData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/mixed",
      },
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const listEvents = async ({ tk, interval }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/events`, { interval });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// Feedback
export const getFeedbacksUser = async ({ tk, id }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/feedbacks-user`, { id });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// EXERCISES

export const getExercises = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/exercises`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const listAllMaterialsAndEvents = async ({ tk }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/events/list/materials-exercises`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const bulkLoadExercisesAndMaterials = async ({ tk, dataPost }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(
      `/v1/events/load/materials-exercises`,
      dataPost
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// Materials
export const createMaterial = async ({ tk, formData }) => {
  apiFile.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await apiFile.post(BASE_URL + "/v1/materials", formData);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateMaterial = async ({ id, tk, formData }) => {
  apiFile.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await apiFile.post(
      BASE_URL + `v1/materials/${id}`,
      formData
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getMaterials = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.get(`/v1/materials`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// bulkLoadExercisesAndMaterials

export const createExercise = async ({ tk, formData }) => {
  apiFile.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await apiFile.post(
      BASE_URL + "v1/create-exercise",
      formData
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateExercise = async ({ tk, formData }) => {
  apiFile.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await apiFile.post(
      BASE_URL + "v1/update-exercise",
      formData
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const destroyExercise = async (tk, token) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(BASE_URL + "v1/delete-exercise", { token });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// Delete Exercise User

export const deleteExercise = async ({ tk, token_user, token_ex }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(
      `/v1/users/${token_user}/destroy/${token_ex}`
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// CRM Exercises

export const getUsersWithExercises = async ({ tk, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/users/exercises`, params);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getUsersWithWritingExercises = async ({ tk, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/users/writing-exercises`, params);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateUserExercise = async ({
  tk,
  token_user,
  token_ex,
  params,
}) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(
      `/v1/users/exercises/${token_user}/update/${token_ex}`,
      params
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

// Kickoff Date

export const createKickoffDate = async ({ tk, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/kickoff-date/create`, params);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateStatusKickoffDate = async ({ tk, id, hide }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(
      `/v1/kickoff-date/update-status/${id}`,
      hide
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateKickoffDate = async ({ tk, id, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/kickoff-date/update/${id}`, params);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateBookedIeltsLink = async ({ tk, token_user, id, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(
      `/v1/booked-ielts/${token_user}/update/${id}`,
      params
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getUserAccessToken = async (tk, userToken) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/impersonate`, { token: userToken });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const toggleStatusBooksExercises = async ({ tk, userToken }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post(`/v1/unlock-exercises`, {
      token: userToken,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getAllUsers = async (tk) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await apiUser.get("v1/users/all");
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const assignmentExercises = async ({ tk, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post("v1/assignment-exercises", params);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const assignmentMaterials = async ({ tk, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post("v1/assignment-materials", params);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const createNewUser = async ({ tk, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    const { data } = await api.post("v1/user/create", { ...params });
    return { data };
  } catch (error) {
    return error.response.data;
  }
};

export const apiAlsoAddOnEnglishAndAdmission = async ({ tk, params }) => {
  api.defaults.headers["Authorization"] = `Bearer ${tk}`;

  try {
    return (
      await api.post("v1/crm/also_add_lead_on_english_and_admission", params)
    ).data;
  } catch (error) {
    return error.response.data;
  }
};
