import CustomPagination from "@/components/_Common/CustomPagination";
import BasePage from "@/components/Page/Base";
import { getColumns } from "@/pages/Upselling/v2/Crm/components/Columns";
import Toolbar from "@/pages/Upselling/v2/Crm/components/Toolbar";
import {
  CrmDialogsContextProvider,
  useCrmDialogsContext,
} from "@/pages/Upselling/v2/Crm/state/CrmDialogsContext";
import {
  CrmContextProvider,
  useCrmContext,
} from "@/pages/Upselling/v2/Crm/state/CrmV3Context";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid } from "@mui/x-data-grid";

import client from "@/api/client";
import meetingDoneIcon from "@/assets/icons/meeting-done.svg";
import noShowIcon from "@/assets/icons/no-show.svg";
import rescheduledIcon from "@/assets/icons/rescheduled.svg";
import toBeCallIcon from "@/assets/icons/to-be-call.svg";
import videoIcon from "@/assets/icons/video.svg";
import {
  defaultAnchorElProduct,
  IAnchorElProduct,
  PopoverProduct,
} from "@/components/User/PopoverProduct";
import { queryClient } from "@/main";
import CrmCall from "@/pages/Crm/CrmCall";
import {
  defaultButtonCup,
  IFormMeetingDone,
} from "@/pages/Upselling/v2/Crm/components/crm-upselling.types";
import TemplateMail from "@/pages/Upselling/v2/Crm/components/TemplateEmail";
import {
  defaultCodeValues,
  STATUS
} from "@/pages/Upselling/v2/Crm/helpers/constants";
import {
  getLastByKey,
  toastMessages,
  updateRowAPI,
  updateRowData,
} from "@/pages/Upselling/v2/Crm/helpers/crm-update-rows";
import {
  formatUserCrmCall,
  formatUserCrmMeeting,
} from "@/pages/Upselling/v2/Crm/helpers/format-user";
import { handleMeetingUpdate } from "@/pages/Upselling/v2/Crm/helpers/meeting-done";
import {
  sendEmail,
  updateMeeting
} from "@/pages/Upselling/v2/Crm/helpers/send-mail";
import {
  defaultAnchorEl,
  defaultConfirmDialog,
  defaultDialogsEl,
  defaultFormMeetingDone,
  INButtonCup,
  INCodeValues,
  INDialogsEl,
  INUpdateRow,
} from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { createRef, useMemo, useState } from "react";

import AddInterestedEventDialog from "@/components/Crm/AddInterestedEventDialog";
import ConfirmPaymentDialog from "@/components/Crm/ConfirmPaymentDialog";
import DialogNewStatusToBeConverted from "@/components/Crm/DialogNewStatusToBeConverted";
import DialpadCallModal from "@/components/Dialpad/DialpadSingleCallV2/DialpadSingleCall";
import { IDialpadCallModal } from "@/components/Dialpad/interface";
import { jP } from "@/components/useHelpers";
import PopoverUpselling from "@/pages/Upselling/v2/Crm/components/PopoverUpselling";
import {
  handleConfirmPayment,
  interestedInTheFuture
} from "@/pages/Upselling/v2/Crm/helpers/side-effects";
import CrmMeetingComponent from "./Meeting/CrmMeeting";

function CrmUpselling() {
  // CRM Context
  const {
    theme,
    rowState,
    paginationState,
    sortState,
    KEY_QUERY,
    queryResult,
    filtersState,
    formMeetingState,
    buttonCupState,
    productsQueryResult,
    crmUpsellingState,
    rowSelectedState,
  } = useCrmContext();

  // Dialog context
  const {
    confirmDialogState,
    dialogsElState,
    dialogNewStatusToBeConvertedRef,
    anchorsElState,
    eventRef,
  } = useCrmDialogsContext();
  const { rows, setRows } = rowState;
  const { page, setPage, perPage } = paginationState;
  const { sortModel, onSortModelChange } = sortState;
  const { setFilters, filterData } = filtersState;

  // State variables
  const { userAdmin, snackHandler } = useAuth();
  const { data: imgCalendars } = useQuery(["crm-get-img-calendars"], () =>
    client.crm.getCalendarImages()
  );
  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );
  const [codeValues, setCodeValues] = useState<INCodeValues>(defaultCodeValues);
  const [loadingConfirmPayment, setLoadingConfirmPayment] =
    useState<boolean>(false);
  const modalRef = createRef<IDialpadCallModal>();
  const [targetCaller, setTargetCaller] = useState({});
  const meetingUpdateDeps = [
    {
      userAdmin,
      snackHandler,
    },
    {
      rowSelectedState,
      formMeetingState,
    },
    {
      confirmDialogState,
      dialogsElState,
    },
  ] as [
      Partial<ReturnType<typeof useAuth>>,
      Partial<ReturnType<typeof useCrmContext>>,
      Partial<ReturnType<typeof useCrmDialogsContext>>
    ];

  const handleDialpadCall = (row: any) => {
    setTargetCaller(row);

    if (modalRef.current) {
      modalRef.current.openModal();
    }
  };
  const handleMeetingDone = async (
    selectedEvent: any,
    eventId: number | string,
    newStatus: string
  ) => {
    return handleMeetingUpdate(
      selectedEvent,
      eventId,
      newStatus,
      rows,
      setRows,
      true,
      false,
      meetingUpdateDeps
    );
  };

  const handleMeetingDoneOther = async (newStatus: string) => {
    return handleMeetingUpdate(
      null,
      null,
      newStatus,
      rows,
      setRows,
      true,
      true,
      meetingUpdateDeps
    );
  };

  const handleConfirmSendMail = async (): Promise<void> => {
    const { rowSelected } = rowSelectedState;

    const lastPackage: any = getLastByKey(rowSelected, "pacchetto");

    const product: string | undefined = await updateMeeting(
      handleMeetingDone,
      lastPackage,
      codeValues
    );

    if (!product) {
      const errorMessage = "Error, the product is missing!";
      snackHandler(errorMessage, "error");
      return;
    }

    buttonCupState.setButtonCup((prevState: INButtonCup) => ({
      ...prevState,
      isLoadingConfirm: true,
    }));

    const emailResponse = await sendEmail(
      codeValues,
      rowSelected,
      product,
      queryResult.data,
      snackHandler
    );

    if (!emailResponse) {
      resetButtonCupAndDialogs();
      return;
    }

    resetStateAfterEmail();

    const updatedRows = rows.map((row: any) =>
      row.token === rowSelected.token
        ? { ...row, discount_codes: [emailResponse?.discount] }
        : row
    );

    handleUpdateRow({
      values: { status: STATUS.ToBeConverted },
      otherRows: updatedRows,
    });
  };

  const resetButtonCupAndDialogs = () => {
    buttonCupState.setButtonCup(defaultButtonCup);
    dialogsElState.setDialogsEl(defaultDialogsEl);
  };

  const resetStateAfterEmail = () => {
    anchorsElState.setAnchorsEl(defaultAnchorEl);
    buttonCupState.setButtonCup(defaultButtonCup);
    dialogsElState.setDialogsEl(defaultDialogsEl);
    setCodeValues(defaultCodeValues);
  };

  const resetStateAfterRowUpdate = (newRows: any) => {
    confirmDialogState.setConfirmDialogData(defaultConfirmDialog);
    setRows(newRows);
    anchorsElState.setAnchorsEl(defaultAnchorEl);
    dialogsElState.setDialogsEl(defaultDialogsEl);
    queryClient.invalidateQueries(KEY_QUERY);
  };

  const handleUpdateRow = async (params: INUpdateRow): Promise<void> => {
    const { rowSelected } = rowSelectedState;

    // 2. Merged default parameters and provided params into one step for clarity.
    const {
      toast = "",
      values = {},
      tokenUser = "",
      activeApi = true,
      otherRows = rows,
    } = { ...params };

    const token = tokenUser || rowSelected?.token;

    if (activeApi) {
      await updateRowAPI(values, token, userAdmin);
    }

    const newRows = updateRowData(otherRows, token, values, tokenUser);

    resetStateAfterRowUpdate(newRows);

    if (toast && toastMessages[toast]) {
      const message =
        typeof toastMessages[toast] === "function"
          ? (toastMessages[toast] as Function)(values.status)
          : toastMessages[toast];
      snackHandler(message);
    }
  };

  /**
   * Computed values
   */
  const meetingsRowSelected = useMemo(
    () =>
      typeof rowSelectedState.rowSelected?.crm_upselling?.meetings === "string"
        ? jP(rowSelectedState.rowSelected?.crm_upselling?.meetings)
        : rowSelectedState.rowSelected?.crm_upselling?.meetings,
    [rowSelectedState.rowSelected]
  );

  const notesMeetings = useMemo(
    () =>
      meetingsRowSelected?.meet
        ?.filter((m: any) => Boolean(m?.notes))
        ?.map((n: any) => ({
          start: n.start,
          notes: n.notes,
          interviewer: n.interviewer,
        })) || [],
    [meetingsRowSelected]
  );

  const pacchetto: string = useMemo(
    () =>
      meetingsRowSelected?.meet
        ?.filter((m: any) => Boolean(m?.pacchetto))
        ?.at(-1)?.pacchetto || "",
    [meetingsRowSelected]
  );

  // End of computed values

  return (
    <BasePage title="Crm Upselling">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        page={parseInt(page.toString()) - 1}
        columns={getColumns({
          handleUpdateRow,
          imgCalendars,
          setAnchorElProduct,
          handleDialpadCall,
        })}
        loading={queryResult.isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_: any, newPage: number) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        onPageChange={(newPage) => setPage(newPage + 1)}
        componentsProps={{
          toolbar: {
            setPage,
            status: Object.values(STATUS),
            filters: filterData,
            owned_by: queryResult.data?.owned_by,
            endMonth: queryResult.data?.endMonth,
            setFilters: setFilters,
          },
          pagination: {
            page,
            setPage,
            pagination: crmUpsellingState.crmUpselling,
          },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
      />

      {dialogsElState.dialogsEl.firstMeeting.value && (
        <CrmCall
          isUpselling
          open={dialogsElState.dialogsEl.firstMeeting.value}
          data={{
            user: formatUserCrmCall(rowSelectedState.rowSelected),
            buttons: {
              toBeCalled: { status: STATUS.ToBeCalled },
              scheduleMeeting: { status: STATUS.UpsellingScheduled },
            },
          }}
          onClose={() =>
            dialogsElState.setDialogsEl((p: INDialogsEl) => ({
              ...p,
              firstMeeting: { value: false },
            }))
          }
          functions={{ onUpdateRow: handleUpdateRow }}
        />
      )}

      {dialogsElState.dialogsEl.updateMeeting.value && (
        <CrmMeetingComponent
          isUpselling
          open={dialogsElState.dialogsEl.updateMeeting.value}
          onClose={() => {
            dialogsElState.setDialogsEl((p: INDialogsEl) => ({
              ...p,
              updateMeeting: { value: false },
            }));
            formMeetingState.setFormMeetingDone(defaultFormMeetingDone);
          }}
          data={{
            user: formatUserCrmMeeting(rowSelectedState.rowSelected),
            userAdmin,
            buttons: {
              noShow: { icon: noShowIcon, status: STATUS.NoShow },
              reschedule: {
                icon: rescheduledIcon,
                name: "Reschedule meeting",
                status: STATUS.UpsellingScheduled,
                eventMeetingType: "reschedule",
              },

              toBeCall: { icon: toBeCallIcon, status: STATUS.StandBy },
              meetingDone: {
                icon: meetingDoneIcon,
                form: formMeetingState.formMeetingDone,
                status: STATUS.UpsellingDone,
                setForm: (key: string, value: string): void =>
                  formMeetingState.setFormMeetingDone(
                    (p: IFormMeetingDone) => ({ ...p, [key]: value })
                  ),
              },
            },
            versionStatus: STATUS,
          }}
          functions={{
            onUpdateRow: handleUpdateRow,
            onMeetingDone: (
              selectedEvent: any,
              eventId: number | string,
              newStatus: string
            ) =>
              confirmDialogState.setConfirmDialogData({
                open: true,
                title: (
                  <>
                    <img src={videoIcon} /> MEET
                  </>
                ),
                text: `Do you want to confirm set this meet as done for "${rowSelectedState.rowSelected?.first_name} ${rowSelectedState.rowSelected?.last_name}"?`,
                onAgree: () =>
                  handleMeetingDone(selectedEvent, eventId, newStatus),
                onDisagree: () =>
                  confirmDialogState.setConfirmDialogData(defaultConfirmDialog),
              }),
            onMeetingDoneOther: (newStatus: string) =>
              confirmDialogState.setConfirmDialogData({
                open: true,
                title: (
                  <>
                    <img src={videoIcon} /> MEET
                  </>
                ),
                text: `Do you want to confirm set this meet as done for "${rowSelectedState.rowSelected?.first_name} ${rowSelectedState.rowSelected?.last_name}"?`,
                onAgree: () => handleMeetingDoneOther(newStatus),
                onDisagree: () =>
                  confirmDialogState.setConfirmDialogData(defaultConfirmDialog),
              }),
          }}
        />
      )}

      <TemplateMail
        open={dialogsElState.dialogsEl.templateEmail.value}
        onClose={() =>
          dialogsElState.setDialogsEl((p: INDialogsEl) => ({
            ...p,
            templateEmail: { value: false },
          }))
        }
        onClick={() =>
          buttonCupState.setButtonCup((p: INButtonCup) => ({
            ...p,
            showConfirm: true,
          }))
        }
        template={buttonCupState.buttonCup.template}
        onConfirm={handleConfirmSendMail}
        isLoading={buttonCupState.buttonCup.isLoadingConfirm}
        showConfirm={buttonCupState.buttonCup.showConfirm}
      />

      {dialogsElState.dialogsEl.confirmPayment.value && (
        <ConfirmPaymentDialog
          isUpselling
          open={dialogsElState.dialogsEl.confirmPayment.value}
          data={{
            user: {
              ...rowSelectedState.rowSelected,
              last_name: rowSelectedState.rowSelected.last_name,
              first_name: rowSelectedState.rowSelected.first_name,
            },
            products: productsQueryResult.data?.products,
            isLoading: loadingConfirmPayment,
          }}
          onClose={() => {
            dialogsElState.setDialogsEl((p: INDialogsEl) => ({
              ...p,
              confirmPayment: { value: false },
            }));

            setLoadingConfirmPayment(false);
          }}
          onSubmit={(user, paymentData) =>
            handleConfirmPayment(
              user,
              paymentData,
              confirmDialogState.setConfirmDialogData,
              setLoadingConfirmPayment,
              rowSelectedState.rowSelected,
              snackHandler,
              handleUpdateRow,
              () =>
                confirmDialogState.setConfirmDialogData(defaultConfirmDialog)
            )
          }
          defaultValues={{
            pacchetto,
            amount: String(
              (productsQueryResult.data?.products?.find(
                (p: any) => p?.token === pacchetto
              )?.price as number) / 100
            ),
            paidFor: productsQueryResult.data?.products?.find(
              (p: any) => p?.token === pacchetto
            )?.product_tag,
            editStartMonthAfterAttach: true,
          }}
        />
      )}

      {Boolean(anchorsElState.anchorsEl.open) && (
        <PopoverUpselling
          {...{
            codeValues,
            notesMeetings,
            setCodeValues,
            onUpdateRow: handleUpdateRow,
          }}
        />
      )}

      <AddInterestedEventDialog
        isUpselling
        ref={eventRef}
        dialogNewStatusToBeConvertedRef={dialogNewStatusToBeConvertedRef}
      />

      <DialogNewStatusToBeConverted
        isUpselling
        ref={dialogNewStatusToBeConvertedRef}
        invalidateQuery={() => queryClient.invalidateQueries(KEY_QUERY)}
        onInterestedInTheFuture={(row: any) =>
          interestedInTheFuture(eventRef, row)
        }
      />
      <DialpadCallModal
        ref={modalRef}
        row={targetCaller}
      />

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}
    </BasePage>
  );
}

export default function CrmUpsellingTemplate() {
  return (
    <CrmContextProvider>
      <CrmDialogsContextProvider>
        <CrmUpselling />
      </CrmDialogsContextProvider>
    </CrmContextProvider>
  );
}
