import { useEffect, useState } from "react";
import { Avatar, Box, Card, Typography } from "@mui/material";

const AVATAR_PATH_BASE = "https://api.edusogno.com/public/storage/";

const TutorCard = ({ data }) => {
  const getImage = (url: string) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      )
      .catch((error) => console.error(error));

  const [profilePicUrl, setProfilePicUrl] = useState(null);

  useEffect(() => {
    const path = AVATAR_PATH_BASE + data.profile_pic;

    getImage(path).then((image) => {
      setProfilePicUrl(image as any);
    });
  }, [data.profile_pic]);

  return (
    <Card>
      <Box
        p={1}
        height={190}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Box>
          {profilePicUrl ? (
            <Avatar
              src={profilePicUrl ?? ""}
              sx={{ width: 123, height: 123 }}
            />
          ) : (
            <Avatar
              src={""}
              sx={{ width: 123, height: 123 }}
            />
          )}
        </Box>

        <Box>
          <Box>
            <Typography
              color="#2D224C"
              variant="h6"
              fontSize={12}
            >
              Name:{" "}
              <Typography
                color="#2D224C"
                fontSize={24}
                fontWeight={800}
              >
                {data.first_name}
              </Typography>
            </Typography>

            <Typography
              color="#2D224C"
              variant="h6"
              fontSize={12}
            >
              Lessons:{" "}
              <Typography
                color="#2D224C"
                fontSize={24}
                fontWeight={800}
              >
                {data.eventCount}
              </Typography>
            </Typography>

            <Typography
              color="#2D224C"
              variant="h6"
              fontSize={12}
            >
              Feedback:{" "}
              <Typography
                color="#2D224C"
                fontSize={24}
                fontWeight={800}
              >
                {data.feedbackCount}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default TutorCard;
