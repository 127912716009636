import { useRef, useEffect } from "react";
import { Box, Button } from "@mui/material";
import cn from "classnames";
import Todo from "./Todo";
import AddIcon from "@mui/icons-material/Add";

const TodoList = ({ type, exercise, setExercise, onAddTodo }) => {
  const boxRef = useRef<HTMLDivElement | null>(null);

  // AUTO SCROLLING BOTTOM
  useEffect(() => {
    const { current: box } = boxRef;
    if (box) box.scrollTop = box.scrollHeight;
  }, [Object.keys(exercise?.questions || {}).length]);

  return type !== "Writing" ? (
    <Box
      ref={boxRef}
      className="flex flex-col gap-4"
    >
      <Box
        className={cn("grow h-full flex flex-col gap-4 overflow-y-auto pr-1", {
          "pt-2": Boolean(Object.values(exercise?.questions || {}).length),
        })}
      >
        {Object.keys(exercise?.questions || {}).map((id) => (
          <Todo
            id={id}
            key={id}
            exercise={exercise}
            setExercise={setExercise}
          />
        ))}
      </Box>

      <Box className="flex justify-end pb-1">
        <Button
          variant="outlined"
          onClick={onAddTodo}
          className="flex items-center gap-1"
        >
          <AddIcon />
          <Box>NEW Question</Box>
        </Button>
      </Box>
    </Box>
  ) : (
    <Box className="flex justify-center py-4 text-2xl text-primary">
      NOT POSSIBLE TO CREATE QUESTION
    </Box>
  );
};

export default TodoList;
