import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  debounce,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import useUserApi, { IUser } from "@/api/useUserApi";

interface ISelectUser {
  value: string;
  label?: string;
  onChange: (token: string | undefined | IUser | null, user?: any) => void;
  required?: boolean;
}

const SelectUser = ({
  value,
  onChange,
  label = "User",
  required = false,
}: ISelectUser) => {
  const [keyword, setKeyword] = useState("");
  const [inputValue, setInputValue] = useState("");

  const { callSearchUsers } = useUserApi();
  const { data, isLoading } = callSearchUsers(keyword);

  const changeKeyword = useCallback(
    debounce((value) => {
      setKeyword(value);
    }, 400),
    []
  );

  useEffect(() => {
    changeKeyword(inputValue);
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      options={data ? data : []}
      loading={isLoading}
      filterOptions={(x) => x}
      noOptionsText="No Result Found"
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      isOptionEqualToValue={(option, value) => option.token === value.token}
      renderOption={(props, option) => (
        <li
          {...props}
          key={option.token}
        >
          <Grid
            container
            alignItems="center"
          >
            <Grid
              item
              sx={{ width: "100%", wordWrap: "break-word" }}
            >
              <Box component="span">
                {option.first_name} {option.last_name}
              </Box>

              <Typography
                color="primary"
                variant="body2"
              >
                {option.email}{" "}
                {Boolean(option.phone) && (
                  <>
                    <br />
                    {option.phone}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
      onChange={(_: any, newValue: IUser | null) =>
        onChange(newValue?.token, newValue)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          InputLabelProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default SelectUser;
