import { useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { Box, Button, TextField } from "@mui/material";
import BasePage from "@/components/Page/Base";
import saveIcon from "@/assets/icons/save.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import PhoneField from "@/components/_Common/Forms/PhoneField";
import useUserAdminApi from "@/api/useUserAdminApi";

function PersonalInfo() {
  const { userAdmin } = useAuth();
  const { callUpdateAdmin, callUpdateAvatarAdmin } = useUserAdminApi();

  const [form, setForm] = useState<any>({});
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    setForm((p: any) => {
      return {
        ...p,
        id: userAdmin.user.id,
        email: userAdmin.user.email,
        phone: userAdmin.user.phone,
        last_name: userAdmin.user.last_name,
        first_name: userAdmin.user.first_name,
      };
    });
  }, [userAdmin]);

  const submitForm = async (e: any) => {
    e.preventDefault();

    await callUpdateAdmin.mutateAsync({ tk: userAdmin.token, postData: form });

    // upload avatar
    if (file) {
      const formData = new FormData();
      formData.append("file", file[0]);

      await callUpdateAvatarAdmin.mutateAsync({
        tk: userAdmin.token,
        id: form.id,
        formData,
      });

      setFile(null);
    }
  };

  const handleChange = (e: any) => {
    setForm((p: any) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files;
    setFile(file);
  };

  return (
    <BasePage title="Personal Information">
      <Box
        onSubmit={submitForm}
        component="form"
        className="flex flex-col gap-5 md:max-w-[500px] pt-5"
      >
        <TextField
          required
          fullWidth
          id="name-required"
          name="first_name"
          label="First name"
          value={form?.first_name ?? ""}
          onChange={handleChange}
        />

        <TextField
          required
          fullWidth
          id="lname-required"
          name="last_name"
          label="Last name"
          value={form?.last_name ?? ""}
          onChange={handleChange}
        />

        <TextField
          required
          fullWidth
          id="email-required"
          name="email"
          label="Email"
          value={form?.email ?? ""}
          onChange={handleChange}
        />

        <PhoneField
          fullWidth
          id="phone-required"
          name="phone"
          label="Phone"
          value={form?.phone ?? ""}
          onChange={(value) => {
            setForm((p: any) => ({ ...p, phone: value }));
          }}
        />

        <input
          id="file"
          type="file"
          name="file"
          accept=".png,.jpg,jpeg"
          onChange={handleFileChange}
          className="hidden"
        />

        {userAdmin.user.avatar && (
          <img
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
            src={userAdmin.user.avatar}
          />
        )}

        <Box className="mt-2 flex gap-2 justify-between">
          <Box>
            <label htmlFor="file">
              <Button
                variant="outlined"
                component="div"
              >
                {file ? file[0].name : <>Upload Avatar</>}
              </Button>
            </label>

            {file && (
              <Button
                sx={{ marginLeft: "10px" }}
                type="button"
                onClick={() => setFile(null)}
                variant="outlined"
              >
                <img
                  src={deleteIcon}
                  className="icon"
                />
                Remove
              </Button>
            )}
          </Box>

          <Button
            type="submit"
            variant="outlined"
            className="mr-2"
          >
            <img
              src={saveIcon}
              className="icon"
            />
            Save
          </Button>
        </Box>
      </Box>
    </BasePage>
  );
}

export default PersonalInfo;
