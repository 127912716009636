import { Box, Button } from "@mui/material";
import { handleSetEnglishLevel } from "@/pages/Upselling/v2/Crm/helpers/side-effects";
import { KEY_QUERY } from "@/pages/Tutors";
import useAuth from '@/hooks/useAuth';
import { useCrmDialogsContext } from "../../state/CrmDialogsContext";
import { useCrmContext } from "../../state/CrmV3Context";
const ChangeEnglishLevelTemplate = ({ anchorsEl, rowSelected }) => {
  const { queryResult, buttonCupState, rowSelectedState, rowState } =
    useCrmContext();

  // Dialog context
  const { dialogsElState, anchorsElState } = useCrmDialogsContext();

  const { userAdmin, snackHandler } = useAuth();
  const onSetEnglishLevel = (value: string) =>
    handleSetEnglishLevel(
      value,
      anchorsElState.setAnchorsEl,
      rowSelectedState.rowSelected,
      snackHandler,
      rowState.setRows,
      rowSelectedState.setRowSelected,
      KEY_QUERY
    );
  return (
    <Box className="bg-edu-800 flex flex-col gap-2 p-3">
      {anchorsEl?.buttons?.map((e: any, i: number) => (
        <Button
          key={e + i}
          variant="outlined"
          onClick={() => onSetEnglishLevel(e)}
          disabled={rowSelected?.english_level === e}
        >
          {e}
        </Button>
      ))}
    </Box>
  );
};

export default ChangeEnglishLevelTemplate;
