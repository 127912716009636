import { useRef, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { MoreVert } from "@mui/icons-material";
import { queryClient } from "@/main";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  Avatar,
  MenuList,
  MenuItem,
  TextField,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import CloseIcon from "@mui/icons-material/Close";
import deleteIcon from "@/assets/icons/delete.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

interface INProps {
  open: boolean;
  rows: Array<any>;
  theme: any;
  onClose: () => void;
  keyQuery: Array<any>;
}

const TableEditImageOwnedBy = ({
  open,
  rows,
  theme,
  onClose,
  keyQuery,
}: INProps) => {
  const { perPage } = useScreen();
  const { snackHandler } = useAuth();

  const fileInputRef = useRef(null);

  const perPageLess = perPage < 6 ? perPage : perPage - 3;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>();
  const [dataPopup, setDataPopup] = useState<any>({
    url: ""
  });

  const handleSubmit = async () => {
    setDataPopup((p: any) => ({ ...p, isLoading: true }));

    const formData = new FormData();

    for (const key in dataPopup?.data) {
      formData.append(key, dataPopup?.data?.[key]);
    }

    const res = await useAPI("/v1/settings/set/calendars/images", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    setDataPopup((p: any) => ({ ...p, isLoading: false }));

    if (!res?.status) {
      snackHandler("Error create!", "error");
      return;
    }

    setDataPopup({});
    queryClient.invalidateQueries(keyQuery);
    snackHandler(`Success create ${dataPopup?.data?.nameCalendar}!`);
  };

  const handleDelete = async () => {
    const res = await useAPI("/v1/settings/set/calendars/images", {
      idDelete,
    });

    if (!res?.status) {
      snackHandler("Error delete!", "error");
      return;
    }

    snackHandler(`Success delete ${idDelete}!`);
    setIdDelete(undefined);
    queryClient.invalidateQueries(keyQuery);
  };

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "",
      width: 70,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Avatar
          sx={{ width: "3rem", height: "3rem" }}
          src={row?.img}
          alt={row?.name}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name calendar",
      flex: 1,
    },
    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              title="Edit"
              color="primary"
              onClick={() => {
                setIsEdit(true);
                setDataPopup({ open: true, data: row });
              }}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              title="Delete"
              color="primary"
              onClick={() => setIdDelete(row?.name)}
            >
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={onClose}
        maxWidth="lg"
      >
        <EduModalTitle onClose={onClose}>Images column owned by</EduModalTitle>

        <EduModalBody className="p-8">
          <DataGrid
            disableColumnMenu
            disableVirtualization
            disableSelectionOnClick
            sx={getTableStyling(theme)}
            rows={Object.values(rows || {})}
            columns={columns}
            pageSize={perPageLess}
            autoHeight={true}
            rowsPerPageOptions={[perPageLess]}
            experimentalFeatures={{ newEditingApi: false }}
            getRowId={(row) => row?.name}
            components={{
              Toolbar: () => (
                <Box className="flex gap-4 justify-between">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setDataPopup({ open: true, data: {} })}
                  >
                    Create new
                  </Button>

                  <SearchToolbar />
                </Box>
              ),
              Pagination: CustomPaginationClient,
            }}
          />
        </EduModalBody>
      </EduModal>

      <CreateNew
        data={dataPopup}
        setData={setDataPopup}
        onSubmit={handleSubmit}
        fileInputRef={fileInputRef}
        isEdit={isEdit}
        onClose={() => {
          setIsEdit(false);
          setDataPopup(undefined);
        }}
      />

      <ConfirmationDialog
        open={Boolean(idDelete)}
        onClose={() => setIdDelete(undefined)}
        onConfirm={handleDelete}
        title={
          <>
            <img src={deleteIcon} /> DELETE
          </>
        }
      >
        Are you sure to remove {idDelete}?
      </ConfirmationDialog>
    </>
  );
};

function CreateNew({ data, setData, onSubmit, fileInputRef, isEdit, onClose }) {
  return (
    <EduModal
      fullWidth
      open={Boolean(data?.open)}
      onClose={onClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={onClose}>
        {isEdit ? <img src={editIcon} /> : <img src={addIcon} />}
        {isEdit ? `Edit - ${data?.data?.name}` : "Create new"}
      </EduModalTitle>

      <EduModalBody
        component="form"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit(e);
        }}
        className="p-8 flex flex-col gap-4"
      >
        <TextField
          required
          disabled={isEdit}
          variant={isEdit ? "filled" : "outlined"}
          label="Name Calendar"
          value={data?.data?.name || ""}
          placeholder="Insert name calendar"
          onChange={(e: any) =>
            setData((p: any) => ({
              ...p,
              data: { ...p?.data, name: e.target.value },
            }))
          }
        />

        <TextField
          required
          label="Tag"
          value={data?.data?.tag || ""}
          variant="outlined"
          placeholder="Ex: Admissions Manager"
          defaultValue="Admissions Manager"
          onChange={(e: any) =>
            setData((p: any) => {
              const t = {
                ...p,
                data: { ...p?.data, tag: e.target.value },
              };
              return t;
            })
          }
        />

        <Box className="w-full flex items-end gap-1">
          <Box className="min-w-[60%] flex flex-col grow">
            <TextField
              required
              fullWidth
              variant="standard"
              label="Professional photo (visible to crm)"
              placeholder="No File Selected"
              value={data?.data?.img?.name || ""}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Button
            disableElevation
            variant="contained"
            component="label"
          >
            Choose file
            <input
              hidden
              type="file"
              ref={fileInputRef}
              name="profile_pic"
              accept="image/png,image/jpeg"
              onChange={(event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  const selectedFile = event.target.files[0];

                  const reader = new FileReader();
                  reader.onload = function (e: any) {
                    const base64String = e.target.result;

                    setData((prevData) => ({
                      ...prevData,
                      data: {
                        ...prevData.data,
                        imgPreview: base64String,
                        img: event.target.files[0],
                      },
                    }));
                  };
                  reader.readAsDataURL(selectedFile);
                }
              }}
            />
          </Button>

          {data?.data?.img && (
            <IconButton
              color="primary"
              onClick={() => {
                fileInputRef.current.value = "";
                setData((p: any) => {
                  const t = { ...p };
                  delete t.data.img;
                  return t;
                });
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        <Box className="flex justify-between items-end">
          <Box>
            <Avatar
              sx={{ width: "10rem", height: "10rem" }}
              src={data?.data?.imgPreview || data?.data?.img}
              alt={data?.data?.name}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            disabled={Boolean(data?.isLoading)}
          >
            <img
              src={saveIcon}
              className={`icon`}
            />
            SAVE
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
}

export default TableEditImageOwnedBy;
