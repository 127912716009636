import client from "@/api/client";
import processErrorMessages from "@/api/client/processErrorMessages";
import saveIcon from "@/assets/icons/save.svg";
import { uuid } from "@/utils";
import { LoadingButton } from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, TextField } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";

const LocaleDataBox = ({ localeData, setLocaleData, supportedLanguages, product_id, snackHandler, invalidateProducts, hidden, expanded, handleAccordionChange }) => {
    const [isLoading, setIsLoading] = useState(false);
    const formId = useMemo(() => uuid(), []);
    const [files, setFiles] = useState({});

    const autocompleteValues = useMemo(() => {
        return localeData
            ?.filter(dataItem => !dataItem?.deleted_at)
            ?.map(dataItem => supportedLanguages.find(lang => lang.locale === dataItem.locale)).filter(Boolean);
    }, [localeData, supportedLanguages]);

    const createOrUpdateLocalData = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData(e.currentTarget);

        for (const key of formData.keys()) {
            const idx = key?.match(/\d+/)?.[0]

            if (key.includes("details") && idx) {
                const details = formData.get(`price_locale_data[${idx}][details]`);

                if (details && typeof details === "string") {
                    const detailsArray = details.split(",").map(item => item.trim());
                    formData.delete(`price_locale_data[${idx}][details]`)
                    detailsArray.forEach((detail, i) => {
                        formData.append(`price_locale_data[${idx}][details][${i}]`, detail)
                    })
                }
            }
        }

        for (const key of formData.keys()) {
            const idx = key?.match(/\d+/)?.[0]

            if (key.includes("product_presentation") && idx) {
                const file = formData.get(`price_locale_data[${idx}][product_presentation]`)

                if (typeof file === "object" && file?.size === 0) {
                    const locale = formData.get(`price_locale_data[${idx}][locale]`)

                    const data = localeData.find(data => data.locale === locale)

                    if (data && Array.isArray(data?.product_presentation)) {
                        formData.delete(`price_locale_data[${idx}][product_presentation]`)
                        data.product_presentation.forEach((file, i) => {
                            formData.append(`price_locale_data[${idx}][product_presentation][${i}]`, file)
                        })
                    }
                }
            }

        }

        try {
            const { item, message } = await client.product.localeData.create_or_update(product_id, formData, { headers: { "Content-Type": "multipart/form-data" } });

            if (item) {
                setLocaleData(item);
                snackHandler(message, "success");
            }

        } catch (error) {
            processErrorMessages(error);
        } finally {
            setIsLoading(false);
            invalidateProducts();
        }
    }

    const deleteLocaleData = async (localeDataId) => {
        setIsLoading(true);

        try {
            const { message } = await client.product.localeData.delete(localeDataId);

            if (message) {
                snackHandler(message, "success");
            }

        } catch (error) {
            processErrorMessages(error);
        } finally {
            setIsLoading(false);
            invalidateProducts();
        }
    }

    const restoreLocaleData = async (localeDataId) => {
        setIsLoading(true);

        try {
            const { message } = await client.product.localeData.restore(localeDataId);

            if (message) {
                snackHandler(message, "success");
            }

        } catch (error) {
            processErrorMessages(error);
        } finally {
            setIsLoading(false);
            invalidateProducts();
        }
    }

    const handleLocaleDataChange = async (newValue) => {
        const data = localeData.filter(dataItem => !dataItem?.deleted_at);

        if (newValue.length < data.length) {
            const deletedData = data.filter(dataItem => !newValue.map(country => country.locale).includes(dataItem.locale));

            if (deletedData?.length > 0) {
                for (const dataItem of deletedData) {
                    if (dataItem?.id) {
                        await deleteLocaleData(dataItem.id);
                        setLocaleData(prev => prev.map(dataItem => dataItem.id === dataItem.id ? { ...dataItem, deleted_at: new Date().toISOString() } : dataItem));
                    } else {
                        setLocaleData(prev => prev.filter(item => item.locale !== dataItem.locale));
                    }
                }
            }

        } else if (newValue.length > data.length) {
            const newLocale = newValue.find(country => !data.map(dataItem => dataItem.locale).includes(country.locale));
            const deletedData = localeData.find(dataItem => dataItem.deleted_at && dataItem.locale === newLocale.locale);

            if (deletedData?.id) {
                await restoreLocaleData(deletedData.id);
                setLocaleData(localeData.map(dataItem => dataItem.id === deletedData.id ? { ...dataItem, deleted_at: null } : dataItem));
            } else {
                setLocaleData([...localeData, { locale: newLocale.locale, product_id }]);
            }
        }
    }

    const handleFileChange = (e) => {
        const fileName = e?.target?.files?.[0]?.name
        const idx = e?.target?.getAttribute("data-idx") as unknown as string

        if (fileName) {
            setFiles({
                ...files, [idx]: {
                    fullName: fileName,
                    shortName: fileName.slice(0, 20) + "..."
                }
            })
        } else {
            setFiles(files => {
                const newFiles = { ...files }
                delete newFiles[idx]
                return newFiles
            })
        }
    }

    useEffect(() => {
        if (localeData?.length === 0) return;

        const newFiles = {}

        localeData.forEach((data, i) => {
            if (Array.isArray(data?.product_presentation) && data?.product_presentation?.length > 0) {
                newFiles[i] = {
                    fullName: data.product_presentation?.[0],
                    shortName: data.product_presentation?.[0].slice(0, 20) + "..."
                }
            }
        })

        setFiles(newFiles)

    }, [localeData])

    if (hidden) return null;

    return (
        <Accordion
            component="form"
            key={formId}
            id={formId}
            onSubmit={createOrUpdateLocalData}
            expanded={expanded === "acc-locales"}
            onChange={handleAccordionChange("acc-locales")}
        >
            <AccordionSummary
                id={`panel1-header-${formId}`}
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1-content"
            >
                {
                    "Supported languages"
                }
            </AccordionSummary>

            <AccordionDetails className="flex flex-col gap-2">

                <Autocomplete
                    multiple
                    options={supportedLanguages ?? []}
                    getOptionLabel={(option) => option?.language}
                    value={autocompleteValues}
                    onChange={(_, newValue) => handleLocaleDataChange(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select language"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.locale === value.locale}
                />


            </AccordionDetails>
            {localeData?.map((data, i) => {
                return !data?.deleted_at && (
                    <AccordionDetails className="flex flex-col gap-2"
                        key={i + data.locale + data.product_id}
                    >
                        <span
                            className="mx-2 text-lg font-bold"
                        >
                            {supportedLanguages?.find((country) => country.locale === data.locale)?.language}
                        </span>
                        <Box className="grid grid-cols-3 grid-flow-row md:grid-flow-col md:auto-cols-auto gap-4 items-center !mt-2">
                            <input type="text"
                                hidden
                                value={data.product_id}
                                readOnly
                                name={`price_locale_data[${i}][product_id]`}
                            />
                            <input type="text"
                                hidden
                                value={data.locale}
                                readOnly
                                name={`price_locale_data[${i}][locale]`}
                            />
                            <TextField
                                required
                                name={`price_locale_data[${i}][terms]`}
                                label="Terms"
                                placeholder="https://"
                                defaultValue={data.terms ?? ""}
                                InputLabelProps={{ shrink: true }}
                            />

                            <TextField
                                fullWidth
                                name={`price_locale_data[${i}][onboarding_link]`}
                                label="Link onboarding"
                                defaultValue={data.onboarding_link ?? ""}
                                placeholder="https://"
                                InputLabelProps={{ shrink: true }}
                            />

                            <label
                                title={files?.[i]?.fullName ?? "Upload pdf"}
                                htmlFor={`files-${data.id ?? i}-${data.product_id}`}
                                className="h-full w-full col-span-2"
                            >
                                <Button
                                    variant="outlined"
                                    component="span"
                                    className="!h-full w-full truncate"
                                >
                                    {files?.[i]?.shortName ?? "Upload pdf"}
                                </Button>
                            </label>

                            <input
                                id={`files-${data.id ?? i}-${data.product_id}`}
                                type="file"
                                name={`price_locale_data[${i}][product_presentation]`}
                                accept=".pdf"
                                className="hidden"
                                data-idx={i}
                                onChange={handleFileChange}
                            />
                        </Box>

                        <TextField
                            required
                            fullWidth
                            multiline
                            rows={2}
                            name={`price_locale_data[${i}][details]`}
                            label="Details"
                            className="!mt-2"
                            placeholder="Enter the details separated by comma ,"
                            defaultValue={data?.details?.join(",") ?? ""}
                            InputLabelProps={{ shrink: true }}
                        />
                    </AccordionDetails>
                )
            })}
            <AccordionDetails className="flex flex-col gap-2">
                <Box className="flex gap-2 justify-end !py-2">
                    <LoadingButton
                        form={formId}
                        type="submit"
                        color="primary"
                        variant="outlined"
                        loading={isLoading}
                        className="flex justify-center"
                        title="Save"
                    >
                        <img
                            src={saveIcon}
                            className={`icon !m-0 ${isLoading ? "opacity-0" : ""}`}
                        />
                    </LoadingButton>
                </Box>

            </AccordionDetails>


        </Accordion>

    )
}

export default LocaleDataBox