import { useEffect, useMemo, useState } from "react";
import { splitCount } from "./ListEvents";
import { queryClient } from "@/main";
import { API_ENDPOINTS } from "@/api/client/endpoints";
import { getTableStyling } from "@/providers/Colors";
import { Box, Button, useTheme, CircularProgress } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import useAuth from "@/hooks/useAuth";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import useCopyToClipboard from "@/components/useCopyToClipboard";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import ToolbarSearchEvents from "./ToolbarSearchEvents";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const LIMIT_SELECTABLE = 6;

const LINK_BASE = import.meta.env.VITE_ROUTE_ACADEMY_USER + "/change-event/";

interface IProps {
  open: boolean;
  event: any;
  onClose: () => void;
  keyQuery?: Array<any> | null;
  isMoreSelectable?: boolean;
}

export default ({
  open,
  event,
  onClose,
  keyQuery = null,
  isMoreSelectable = true,
}: IProps) => {
  const theme = useTheme();
  const { snackHandler } = useAuth();
  const { isMobile, screen } = useScreen();

  const [data, setData] = useState<any>([]);
  const [link, setLink] = useState<string>("");
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [users, setUsers] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectUser, setSelectUser] = useState<any>({});
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [selectedEvents, setSelectedEvents] = useState<Array<any>>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleSelect = (ids: Iterable<unknown> | null | undefined) => {
    if (!rows?.length) return;

    const selectedIDs = new Set(ids);
    const selectedRowData = rows?.filter((row: GridValidRowModel) =>
      selectedIDs.has(row?.id)
    );

    const limitSelectable = isMoreSelectable ? LIMIT_SELECTABLE : 1;

    if (selectedRowData.length <= limitSelectable)
      setSelectedEvents(selectedRowData || []);

    setRows((p) =>
      p.map((row) => {
        if (
          selectedRowData.length >= limitSelectable &&
          ![...selectedIDs].includes(row.id)
        ) {
          return { ...row, notSelected: true };
        } else {
          return { ...row, notSelected: false };
        }
      })
    );
  };

  const handleSearchEvents = async () => {
    setRows([]);

    setActiveSearch(true);

    const res = await useAPI(
      API_ENDPOINTS.V2.SEARCH_RELATED_EVENTS,
      { event: event?.id, user: selectUser?.token },
      { useErrors: true, pathLiberty: true }
    );

    if ("errors" in res) {
      snackHandler("Not find events!", "error");
      setActiveSearch(false);
      return;
    }

    setRows(res);
    setActiveSearch(false);
  };

  const handleCreateLink = async (e: any) => {
    setLoadingCreate(true);
    const expire = await useAPI("/v1/events/search/getDateDB");

    const paramsLink: any = btoa(
      JSON.stringify({
        user: selectUser.token,
        event: event.id,
        events: selectedEvents?.map((event: any) => event.id),
        expire: expire,
      })
    );

    let letters = "";

    while (letters.length < 4) {
      letters += String.fromCharCode(97 + Math.floor(Math.random() * 26));
    }

    const link =
      LINK_BASE +
      letters.substring(0, 2) +
      paramsLink +
      letters.substring(2, 4);

    setLink(link);

    setLoadingCreate(false);
    useCopyToClipboard(link);
    snackHandler("Link copied");
  };

  const handleChangeStudent = async (updateCounter: boolean) => {
    setLoadingCreate(true);
    const res = await useAPI(
      "/user/v2/change-event/set",
      {
        userToken: selectUser.token,
        event: event.id,
        newEvent: selectedEvents[0].id,
        update_counter: updateCounter,
      },
      { pathLiberty: true, useErrors: true }
    );

    setLoadingCreate(false);

    if (res?.status === false) {
      snackHandler(res?.message ?? "Error change event!", "error");
      return;
    }

    snackHandler("Success change event!");
    onClose();

    if (keyQuery) {
      queryClient.invalidateQueries(keyQuery);
    }
  };

  const columns: GridColDef[] = useMemo(
    () =>
      [
        { field: "id", headerName: "ID", width: 70 },
        { field: "lang", headerName: "Lang", width: 60 },
        {
          field: "titolo",
          headerName: "Title",
          minWidth: 250,
          flex: 2,
          renderCell: ({ row }) => <MyTooltip>{row?.titolo}</MyTooltip>,
        },
        {
          field: "tutors",
          headerName: "Tutor",
          minWidth: 150,
          flex: 1,
          valueGetter: ({ row }) => row?.tutors?.fullName,
          renderCell: ({ row }) => (
            <MyTooltip>{row?.tutors?.fullName}</MyTooltip>
          ),
        },
        {
          field: "data",
          headerName: "Date",
          width: 150,
          valueGetter: ({ row }) => row.data + " " + row.ora,
        },
        {
          field: "partecipants",
          headerName: "Partecipants",
          minWidth: 120,
          valueGetter: ({ row }) => {
            let partecipants = splitCount(row?.id_utente);
            if (row?.id_utente?.includes(row?.tutors?.email)) partecipants -= 1;
            return partecipants;
          },
        },
      ] as GridColDef[],
    [screen]
  );

  useEffect(() => {
    if (isLoading) return;
    setUsers(data);
  }, [isLoading, data]);

  useEffect(() => {
    if (!event) return;
    setIsLoading(true);
    useAPI(
      API_ENDPOINTS.V2.EVENT_USERS,
      { event_id: event.id },
      { pathLiberty: true }
    )
      .then((res) => {
        setIsLoading(false);
        setData(res);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, [event]);

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <EduModalTitle onClose={onClose}>
          {isMoreSelectable ? "Change event with link" : "Change event"} -{" "}
          {event.titolo}
        </EduModalTitle>

        <EduModalBody>
          <Box>
            <Box sx={{ p: "2rem" }}>
              {[
                {
                  label: "Date time",
                  value: `${event.data} ${event.ora}`,
                },
                { label: "English level", key: "english_lvl" },
                { label: "Type", key: "type" },
                { label: "Description", key: "descrizione" },
                ...(["A0", "A0-"].includes(event.english_lvl)
                  ? [{ label: "Lang", key: "lang" }]
                  : []),
              ].map((e) => (
                <Box key={e.label}>
                  <span>{e.label}</span>:{" "}
                  <span className="font-bold">
                    {e?.key ? event[e.key] : e?.value}
                  </span>
                </Box>
              ))}

              {Boolean(selectUser?.start_month) && (
                <Box>
                  User mese inizio:{" "}
                  <span className="font-bold">{selectUser?.start_month}</span>
                </Box>
              )}
            </Box>

            <DataGrid
              disableColumnMenu
              disableVirtualization
              disableSelectionOnClick
              sx={{
                ...getTableStyling(theme),
                px: "2rem",
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                  { display: "none" },
                height: isMobile ? "" : "calc(100vh - 26rem)",
              }}
              rows={rows}
              columns={columns}
              loading={activeSearch}
              pageSize={20}
              autoHeight={isMobile}
              components={{
                Toolbar: ToolbarSearchEvents,
                Pagination: CustomPaginationClient,
              }}
              isRowSelectable={({ row }) => !Boolean(row?.notSelected)}
              componentsProps={{
                toolbar: {
                  users,
                  selectUser,
                  activeSearch,
                  setSelectUser,
                  handleSearchEvents,
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              checkboxSelection={Boolean(rows.length)}
              rowsPerPageOptions={[20]}
              experimentalFeatures={{ newEditingApi: true }}
              onSelectionModelChange={handleSelect}
            />

            <Box className="h-14 flex items-center justify-between gap-2 mx-8 mt-4 mb-6 p-2 pl-4 border rounded-full border-gray-600">
              {isMoreSelectable ? (
                <>
                  <Box
                    className={
                      "truncate " +
                      (link ? "" : "text-gray-600") +
                      (isMobile ? " basic-8/12" : " basic-10/12")
                    }
                  >
                    {link ? link : LINK_BASE + "..."}
                  </Box>

                  <Button
                    className={
                      "!rounded-full flex gap-1 justify-center items-center" +
                      (isMobile ? " basic-4/12" : " basic-2/12")
                    }
                    onClick={handleCreateLink}
                    variant="contained"
                    disabled={
                      !selectUser || !selectedEvents?.length || loadingCreate
                    }
                  >
                    {loadingCreate && !isMobile ? (
                      <CircularProgress size="1rem" />
                    ) : null}
                    <span>Create</span>
                  </Button>
                </>
              ) : (
                <Box className="w-full flex justify-end">
                  <Button
                    variant="outlined"
                    disabled={
                      !selectUser || !selectedEvents?.length || loadingCreate
                    }
                    onClick={() => setOpenConfirmDialog(true)}
                    className="!rounded-full flex gap-1 justify-center items-center"
                  >
                    {loadingCreate && !isMobile ? (
                      <CircularProgress size="1rem" />
                    ) : null}
                    <span>Change event</span>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => {
          handleChangeStudent(false);
          setOpenConfirmDialog(false);
        }}
        onConfirm={() => {
          handleChangeStudent(true);
          setOpenConfirmDialog(false);
        }}
      >
        Do you want to add a plus to the counter ?
      </ConfirmationDialog>
    </>
  );
};
