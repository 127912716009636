import RequestUser from "./RequestUser";

export const getProducts = async (token, formData) => {
  const { data } = await RequestUser.call(
    {
      url: "/v2/product/all",
      method: "POST",
      data: formData,
    },
    token
  );

  const sortedProducts = data?.products?.sort((a, b) => {
    return b.price - a.price;
  });

  return {
    ...data,
    products: sortedProducts,
  };
};

export const createProduct = async (token, formData, callback) => {
  const { data, error } = await RequestUser.call(
    {
      url: "/v2/product/create",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    token
  );

  if (error) {
    throw error?.response?.data || error;
  }

  if (callback) callback();

  return { data };
};

export const updateProduct = async (token, formData, callback, prod_tk) => {
  const { data: result } = await RequestUser.call(
    {
      url: `/v2/product/update/${prod_tk}`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    token
  );

  if (callback) callback();

  return result;
};

// export const getPercorsi = async () => {
//   const { data } = await RequestUser.call({
//     url: "v1/percorsi/all",
//     method: "POST",
//   });

//   return data;
// };

export const deleteProduct = async ({ token, prod_tk, callback }) => {
  const { data: result } = await RequestUser.call(
    {
      url: `/v2/product/delete/${prod_tk}`,
      method: "POST",
    },
    token
  );

  if (callback) callback();

  return result;
};
