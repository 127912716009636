import { clone } from "@/components/useHelpers";
import { Delete } from "@mui/icons-material";
import { Box, MenuItem, IconButton } from "@mui/material";
import InputTextSelect from "./InputTextSelect";

const valuesDefaultSelect = [
  "testo",
  "A,B,C",
  "A,B,C,D",
  "A,B,C,D,E",
  "A,B,C,D,E,F",
  "A,B,C,D,E,F,G",
  "A,B,C,D,E,F,G,H",
  "I,II,III,IV,V,VI,VII,VIII",
  "YES,NO,NOT GIVEN",
  "TRUE,FALSE,NOT GIVEN",
];

const TodoExercise = ({ id, exercise, setExercise }) => {
  const correctAnswerFromValue =
    typeof exercise.questions[id].question_type === "string"
      ? exercise.questions[id].question_type.split(",").filter((e) => e.length)
      : [];

  const valueText = exercise.questions[id].question_type === "text";

  return (
    <Box className="flex gap-2 justify-between items-center">
      <Box className="w-[1.8rem] text-xl flex items-center">{id}</Box>

      <InputTextSelect
        className={
          exercise.questions[id].question_type
            ? valueText
              ? "basis-2/12"
              : "basis-4/12"
            : "grow"
        }
        label="Options"
        value={exercise.questions[id]?.question_type || ""}
        setValue={(v) =>
          setExercise((ex: any) => {
            const e = clone(ex);
            e.questions[id].question_type = v;
            return e;
          })
        }
        placeholder={valuesDefaultSelect[1]}
      >
        {valuesDefaultSelect.map((v, i) => (
          <MenuItem
            key={v + i}
            value={v}
          >
            {v}
          </MenuItem>
        ))}
      </InputTextSelect>

      {Boolean(exercise.questions[id].question_type) && (
        <InputTextSelect
          type={valueText ? "text" : ""}
          label="Answer/s"
          value={exercise.questions?.[id]?.correct_answer || ""}
          setValue={(v) =>
            setExercise((ex: any) => {
              const e = clone(ex);
              e.questions[id].correct_answer = v;
              return e;
            })
          }
          className="grow"
          placeholder={
            valueText
              ? "text..."
              : exercise.questions[id].question_type || "---"
          }
        >
          {correctAnswerFromValue.map((v, i) => (
            <MenuItem
              key={v + i}
              value={v}
            >
              {v.trim()}
            </MenuItem>
          ))}
        </InputTextSelect>
      )}

      <IconButton
        title="Delete exercise"
        onClick={() =>
          setExercise((exercise: any) => {
            const ex = clone(exercise);
            delete ex.questions[id];
            const newQuestions = {};
            Object.values(ex.questions).forEach(
              (v, i) => (newQuestions[i + 1] = v)
            );
            ex.questions = newQuestions;
            return ex;
          })
        }
      >
        <Delete />
      </IconButton>
    </Box>
  );
};

export default TodoExercise;
