import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { ReportEntry } from '../interfaces';

interface ReportSectionProps {
  title: string;
  entries?: ReportEntry[]; // Marked as optional
}

const ReportSection: React.FC<ReportSectionProps> = ({ title, entries = [] }) => { // Default to empty array
  const [openUsers, setOpenUsers] = useState<{ [key: string]: boolean }>({});

  const handleUserToggle = (userId: string) => {
    setOpenUsers((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  // Group entries by user
  const groupedEntries = entries.reduce((acc, entry) => {
    (acc[entry.user_id] = acc[entry.user_id] || []).push(entry);
    return acc;
  }, {} as { [key: string]: ReportEntry[] });

  // Return null if there are no entries for this section
  if (Object.keys(groupedEntries).length === 0) {
    return null;
  }

  return (
    <Box my={2} style={{padding: '1rem', borderRadius: '4px' }}>
      {Object.keys(groupedEntries).map((userId) => (
        <div key={userId} style={{ paddingLeft: '1rem' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="subtitle1" component="div" onClick={() => handleUserToggle(userId)}>
              {groupedEntries[userId][0].student_name}
            </Typography>
            <IconButton onClick={() => handleUserToggle(userId)}>
              {openUsers[userId] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={openUsers[userId]}>
            <Box
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
                padding: '0 1rem',
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#ffffff',
              }}
            >
              {groupedEntries[userId].map((entry, index) => (
                <Paper key={index} elevation={2} className="p-2 my-1">
                  <Typography variant="body2"><strong>Mistake:</strong> {entry.mistake}</Typography>
                  <Typography variant="body2"><strong>Correction:</strong> {entry.feedback}</Typography>
                  <Typography variant="body2"><strong>Explanation:</strong> {entry.explanation}</Typography>
                </Paper>
              ))}
            </Box>
          </Collapse>
        </div>
      ))}
    </Box>
  );
};

export default ReportSection;
