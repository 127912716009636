import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createCrm,
  getCrmData,
  sendMailCrm,
  updateCrmData,
  confirmPayment,
  downloadCrmData,
  moveCrmToUpselling,
  addEventInterested,
} from "./crmApi";
import dayjs from "dayjs";
import useSessionStorage from "@/hooks/useSessionStorage";

export const NEW_STATUS_FOR_CALL_CENTER = [
  "no-show WA1",
  "no-show WA2",
  "no-show WA3",
];

export const ARRAY_OPTIONS = [
  "Nuovo",
  "Messaggio WhatsApp",
  "Whatsapp text 1",
  "Whatsapp text 2",
  "Colloquio fissato",
  "Colloquio fatto",
  "Offerta rifiutata",
  "Rischedulare",
  "Assente al colloquio",
  "Convertito",
  "Da convertire",
  "Da richiamare",
  "Disinteressato",
  "Non risposto",
  "Numero non valido",
  "Rejected",
  "Interested in the future",
  "No show not interested",
  "Duplicate",
  "Blocked",
  "Waiting for feedback",
  "Waiting for Feedback No show",
  "Bad lead",
];

export const ARRAY_LABEL_OPTIONS = {
  Nuovo: "New",
  "Messaggio WhatsApp": "Whatsapp text",
  "Whatsapp text 1": "Whatsapp text 1",
  "Whatsapp text 2": "Whatsapp text 2",
  "Colloquio fissato": "Meeting scheduled",
  "Colloquio fatto": "Meeting done",
  "Offerta rifiutata": "Offer rejected",
  "No show not interested": "No show not interested",
  Rischedulare: "Reschedule",
  "Assente al colloquio": "No show",
  Convertito: "Sale",
  "Da convertire": "To be converted",
  "Da richiamare": "To be called",
  Disinteressato: "Not interested",
  "Non risposto": "No reply",
  "Numero non valido": "Incorrect number",
  Rejected: "Rejected",
  "Interested in the future": "Interested in the future",
  Duplicate: "Duplicate",
  Blocked: "Blocked",
  "Waiting for feedback": "Waiting for feedback",
  "Waiting for Feedback No show": "Waiting for Feedback No show",
  "Bad lead": "Bad lead",
};

export const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  timeZone: "Europe/Rome",
  weekday: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  month: "2-digit",
  year: "numeric",
};

export const MEET_OPTIONS = ["ci sarà", "non risponde"];

const useCrmApi = () => {
  const { snackHandler, userAdmin, isAdmission } = useAuth();

  const keyCache = `${isAdmission ? "admission-" : ""}crm`;
  const [getParams, setParams] = useSessionStorage<object>(keyCache);

  const callDownloadCrm = async () => {
    let filename = `CRM ${dayjs().format("DD-MM-YYYY")}.csv`;
    const params = getParams();
    const rs = await downloadCrmData(
      userAdmin.token,
      { ...params, export: true },
      filename
    );
    return rs;
  };

  const callGetCrm = (params: any, callback?: (data: any) => void) => {
    return useQuery(
      [keyCache, params],
      async () => {
        const rs = await getCrmData(userAdmin.token, {
          ...params,
          isAdmission,
        });
        setParams(params);
        if (callback) callback(rs);
        return rs;
      },
      {
        refetchOnWindowFocus: true,
        enabled: params?.per_page > 0,
        keepPreviousData: true,
      }
    );
  };

  const callCreateCrm = useMutation(
    (params: { data: any; callback?: (resp: any) => void }) =>
      createCrm({ ...params, token: userAdmin.token }),
    {
      onSuccess: async (res: any) => {
        if (res?.status == false) {
          snackHandler(res?.message, "error");
          return;
        }

        const queryParams = getParams();
        queryClient.invalidateQueries([keyCache, queryParams]);
        snackHandler("CRM created!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callUpdateCrm = useMutation(
    (params: { id: number; data: any; callback?: (resp: any) => void }) =>
      updateCrmData({ ...params, token: userAdmin.token }),
    {
      onSuccess: async () => {
        const queryParams = getParams();
        await queryClient.invalidateQueries([keyCache, queryParams]);
        snackHandler("CRM updated!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callMoveCrmToUpSelling = useMutation(
    (params: { id: number; callback?: (resp: any) => void }) =>
      moveCrmToUpselling({ ...params, token: userAdmin.token }),
    {
      onSuccess: async () => {
        const queryParams = getParams();
        await queryClient.invalidateQueries([keyCache, queryParams]);
        snackHandler("CRM Moved!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callConfirmPayment = useMutation(
    (params: { data: any; callback?: (resp: any) => void }) =>
      confirmPayment({ ...params, token: userAdmin.token }),
    {
      onSuccess: async () => {
        const queryParams = getParams();
        queryClient.invalidateQueries([keyCache, queryParams]);
        snackHandler("Payment confirmed!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callSendMailCrm = useMutation(
    (params: { data: any; callback?: (resp: any) => void }) =>
      sendMailCrm({ ...params, token: userAdmin.token }),
    {
      onSuccess: async () => {
        const queryParams = getParams();
        queryClient.invalidateQueries([keyCache, queryParams]);
        snackHandler("Mail sent!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callAddEventInterested = useMutation(
    (params: { data: any }) =>
      addEventInterested({ ...params, token: userAdmin.token }),
    {
      onSuccess: async (data: any) => {
        const queryParams = getParams();
        queryClient.invalidateQueries([keyCache, queryParams]);
        snackHandler("Created event!");
      },
      onError: async (error: any) => {
        if (error?.message) {
          snackHandler(error.message, "error");
        }
      },
    }
  );

  const invalidateQuery = () => {
    const queryParams = getParams();
    queryClient.invalidateQueries([keyCache, queryParams]);
  };

  return {
    keyCache,
    callGetCrm,
    callUpdateCrm,
    callCreateCrm,
    callSendMailCrm,
    callDownloadCrm,
    invalidateQuery,
    callConfirmPayment,
    callMoveCrmToUpSelling,
    callAddEventInterested,
    options: DATE_OPTIONS,
    meetOptions: MEET_OPTIONS,
    arrayOptions: ARRAY_OPTIONS,
    arrayLabelOptions: ARRAY_LABEL_OPTIONS,
  };
};

export default useCrmApi;
