import { useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { COLORS_STARS } from "..";
import { INDataFeedback } from "@/pages/Tutors/interfaces";
import { getTableStyling } from "@/providers/Colors";
import { fUpper, isObject } from "@/components/useHelpers";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import { Addchart, ContentCopy, Star, Circle } from "@mui/icons-material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import MyTooltip from "@/components/MyTooltip";
import videoIcon from "@/assets/icons/video.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import PerformanceReport from "./PerformanceReport";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

export const DEFAULT_FEEDBACKS = {
  name: "",
  data: [],
  open: false,
};

interface IProps {
  name: string;
  open: boolean;
  data: Array<INDataFeedback>;
  onClose: () => void;
}

const DialogTutors = ({ open, name, data, onClose }: IProps) => {
  const theme = useTheme();
  const { copyToClipboard } = useAuth();

  const [range, setRange] = useState<Array<Date> | null>(null);
  const [reportOpen, setReportOpen] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<INDataFeedback[]>([]);

  const handleClose = () => {
    onClose();
    setRange(null);
    setReportOpen(false);
    setFilteredData([]);
  };

  useEffect(() => {
    setFilteredData(
      data?.filter((p: INDataFeedback) => {
        if (!range) return true;
        if (!p.event?.data) return false;
        const value = dayjs(p.event?.data);
        return dayjs(range[0]) <= value && value <= dayjs(range[1]);
      })
    );
  }, [data, range]);

  const columns: GridColDef[] = [
    {
      field: "created_at",
      hide: true,
      hideable: false,
    },
    {
      field: "titolo",
      headerName: "Lesson",
      minWidth: 200,
      flex: 0.5,
      valueGetter: ({ row }) => row?.event?.titolo || "-",
      renderCell: ({ row }) => <MyTooltip>{row?.event?.titolo}</MyTooltip>,
    },
    {
      field: "data",
      headerName: "Date",
      width: 150,
      valueGetter: ({ row }) => row?.event?.data + " " + row?.event?.ora || "-",
    },
    {
      field: "student",
      headerName: "Student",
      width: 180,
      valueGetter: ({ row }) =>
        row?.user?.first_name && row?.user?.last_name
          ? `${row?.user?.first_name} ${row?.user?.last_name}`
          : "-",
      renderCell: ({ row }) => (
        <MyTooltip>
          {row?.user?.first_name && row?.user?.last_name
            ? `${row?.user?.first_name} ${row?.user?.last_name}`
            : "-"}
        </MyTooltip>
      ),
    },
    {
      field: "rating",
      headerName: "Rating tutor",
      width: 135,
      valueGetter: ({ row }) =>
        "rating =r" +
        (row?.rating_tutor ? row?.rating_tutor : row?.rating || 0),
      renderCell: ({ row }) => {
        const rat = Number(
          row?.rating_tutor ? row?.rating_tutor : row?.rating || 0
        );
        return rat
          ? Array.from({ length: rat }, (_, i) => (
            <Star
              key={i}
              style={{ color: COLORS_STARS.at(rat - 1) }}
            />
          ))
          : "-";
      },
    },
    {
      field: "first_feedback",
      headerName: "Comment",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        if (typeof JSON.parse(row?.improvements) !== "string")
          return <MyTooltip>{fUpper(row?.first_feedback || "")}</MyTooltip>;

        if (JSON.parse(row?.improvements).search("Tutor") < 0) return;
        const improvements = JSON.parse(JSON.parse(row?.improvements));

        return (
          <MyTooltip>
            {fUpper(
              row?.first_feedback
                ? row?.first_feedback
                : improvements
                  ? improvements?.Tutor?.join(", ")
                  : "-"
            )}
          </MyTooltip>
        );
      },
    },
    {
      field: "improvements",
      headerName: "Improvements",
      width: 150,
      valueGetter: ({ row }) => {
        let improv = JSON.parse(row?.improvements || "[]");
        if (typeof improv === "string") improv = JSON.parse(improv);
        const improvType = Array.isArray(improv)
          ? improv
          : isObject(improv)
            ? Object.keys(improv)
            : [];
        return (
          "improvements =i" +
          (improvType?.length && row?.last_feedback
            ? improvType?.length + 1
            : improvType?.length
              ? improvType?.length
              : row?.last_feedback
                ? 1
                : "-")
        );
      },
      renderCell: ({ row }) => {
        let improv = JSON.parse(row?.improvements || "[]");
        if (typeof improv === "string") improv = JSON.parse(improv);
        const improvType = Array.isArray(improv)
          ? improv
          : isObject(improv)
            ? Object.keys(improv)
            : [];

        return improvType.length ? (
          <Tooltip
            placement="bottom-start"
            arrow
            title={
              <Box>
                {improvType.map((e: string, i: number) => {
                  const v = Array.isArray(improv)
                    ? e
                    : isObject(improv)
                      ? improv
                      : "";

                  const isString = typeof v === "string";

                  const style =
                    i < improvType.length - 1
                      ? {
                        borderBottom: "1px solid",
                        paddingTop: i !== 0 ? "0.4rem" : "",
                      }
                      : i !== 0
                        ? { paddingTop: "0.4rem" }
                        : {};

                  return (
                    <Box key={isString ? v : e}>
                      <Box
                        className="text-base"
                        style={style}
                      >
                        <Circle
                          style={{ fontSize: "0.5rem", marginRight: "0.2rem" }}
                        />{" "}
                        {isString ? fUpper(v) : fUpper(e)}
                        {!isString && (
                          <Box className="pl-4 w-full">
                            {v[e]?.map((r: string) => (
                              <Box
                                key={r}
                                sx={{ width: "100%", wordWrap: "break-word" }}
                              >
                                - {fUpper(r)}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>

                      {row?.last_feedback && (
                        <Box
                          className="text-base border-t"
                          style={style}
                        >
                          <Circle
                            style={{
                              fontSize: "0.5rem",
                              marginRight: "0.2rem",
                            }}
                          />{" "}
                          Old version
                          <Box className="pl-4 w-full">
                            <Box sx={{ width: "100%", wordWrap: "break-word" }}>
                              - {fUpper(row?.last_feedback || "")}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            }
          >
            <Box
              className="truncate"
              style={{ width: "100%" }}
            >
              {improvType?.length && row?.last_feedback
                ? improvType?.length + 1
                : improvType?.length
                  ? improvType?.length
                  : row?.last_feedback
                    ? 1
                    : "-"}
            </Box>
          </Tooltip>
        ) : (
          "-"
        );
      },
    },
    {
      field: "actions",
      width: 120,
      headerName: "",
      sortable: false,
      editable: false,
      hideable: false,
      disableExport: true,
      valueGetter: ({ row }) =>
        row?.event?.link_video ? "video link meet" : "",
      renderCell: ({ row }) => {
        if (!row?.event?.link_video) return <></>;

        return (
          <Box
            className="border rounded"
            sx={{ borderColor: theme.palette.primary.main }}
          >
            <Button
              title="Copy the recorded video link from the drive"
              onClick={() => copyToClipboard(row.event.link_video)}
            >
              <ContentCopy fontSize="small" />
            </Button>

            <Button
              title="Watch the video recorded on the drive"
              onClick={() => {
                window.open(row.event.link_video, "_blank");
              }}
            >
              <img
                src={videoIcon}
                className="icon no-margin"
              />
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="xl"
    >
      <EduModalTitle onClose={handleClose}>Feedbacks - {name}</EduModalTitle>

      <EduModalBody className="p-5">
        <DataGrid
          autoHeight
          disableColumnMenu
          disableVirtualization
          disableSelectionOnClick
          sx={getTableStyling(theme)}
          rows={filteredData || []}
          columns={columns}
          pageSize={25}
          components={{
            Toolbar: () => (
              <Box className="flex gap-4">
                <Button
                  variant="outlined"
                  onClick={() => setReportOpen(true)}
                >
                  <Addchart color={"primary"} />
                </Button>

                <CustomDateRangePicker
                  value={range}
                  onChange={(date) =>
                    setRange(date ? [date.startDate, date.endDate] : null)
                  }
                />

                <SearchToolbar />
              </Box>
            ),
            Pagination: CustomPaginationClient,
          }}
          initialState={{
            sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowsPerPageOptions={[25]}
          experimentalFeatures={{ newEditingApi: false }}
          columnVisibilityModel={{ created_at: false }}
        />
      </EduModalBody>

      <PerformanceReport
        open={reportOpen}
        onClose={() => setReportOpen(false)}
        tutorToken={data?.[0]?.event?.tutor_token}
      />
    </EduModal>
  );
};

export default DialogTutors;
