import useAuth from '@/hooks/useAuth';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getUserVat,
  downloadUserVat,
  softDeleteUserVat,
  updateUserVatStatus,
} from "@/api/userVatApi";

const useUserVatApi = () => {
  const { userAdmin } = useAuth();

  const queryClient = useQueryClient();

  const keyCache = "users-vat";

  const callGetUsersVat = (params: any = {}) => {
    queryClient.setQueryData([keyCache, "last-params"], () => params);

    return useQuery(
      [keyCache, params],
      async () => await getUserVat(userAdmin.token, params),
      {
        enabled: Boolean(params?.per_page),
        keepPreviousData: true,
        refetchOnWindowFocus: true,
      }
    );
  };

  const updateInvoiceStatus = async (id: any, file?: any) => {
    try {
      const response = await updateUserVatStatus(userAdmin.token, id, file);
      const params = queryClient.getQueryData([keyCache, "last-params"]);
      queryClient.invalidateQueries([keyCache, params]);
      return response;
    } catch (error) {
      console.error("Error updating user VAT status", error);
      throw error;
    }
  };

  const softDelete = async (id: any) => {
    try {
      const data = await softDeleteUserVat(userAdmin.token, id);
      const params = queryClient.getQueryData([keyCache, "last-params"]);
      queryClient.invalidateQueries([keyCache, params]);
      return data;
    } catch (error) {
      console.error("Error soft delete user VAT status", error);
      throw error;
    }
  };

  const callDownloadUsersVat = () => {
    const params = queryClient.getQueryData([keyCache, "last-params"]);
    downloadUserVat(userAdmin.token, params);
  };

  return {
    softDelete,
    callGetUsersVat,
    updateInvoiceStatus,
    callDownloadUsersVat,
  };
};

export default useUserVatApi;
