import { useEffect, useState } from "react";
import { format } from "date-fns";
import { isObject } from "@/components/useHelpers";
import { TableStyling } from "@/dummy";
import { COLORS_STARS_MEDIA } from "..";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, Rating, Link, Badge } from "@mui/material";
import useScreen from "@/hooks/useScreen";
// import DialogTutors from "./DialogTutors";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ToolbarTutors from "./ToolbarTutors";
import MessageRounded from "@mui/icons-material/MessageRounded";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const StudentsFeedbacks = ({ dataAPI, getNotes, isLoading }) => {
  const [rows, setRows] = useState<Array<object>>([]);
  const [rowsCSV, setRowsCSV] = useState<object>({});
  const [rangeDate, setRangeDate] = useState([]);

  const [open, setOpen] = useState<boolean>(false);
  const [dataDialog, setDataDialog] = useState<object>({});

  const filterData = (data: Array<any>): Array<any> => {
    let newData: Array<any> = [...data];

    if (rangeDate?.length) {
      const start = new Date(rangeDate[0]);
      const end = new Date(rangeDate[1]);

      newData = newData.filter(({ event }) => {
        const created = new Date(event?.data);
        return start <= created && created <= end;
      });
    }

    return newData;
  };

  // COLUMNS 
  const columns: GridColDef[] = [
    {
      field: "created_at",
      hide: true,
      hideable: false,
    },
    {
      field: "name_tutor",
      headerName: "Tutor",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => (
        <Box className="truncate">{`${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`}</Box>
      ),
      valueGetter: ({ row }) =>
        `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`,
    },
    {
      field: "rating",
      headerName: "Rating tutor",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => "rating =r" + (row?.rating || 0),
      renderCell: ({ row }) => {
        const rat = Number(row?.rating || 0);
        return (
          <Box className="flex items-center gap-2">
            <Box>{rat.toFixed(2)}</Box>
            <Rating
              style={{ color: COLORS_STARS_MEDIA[Math.floor(rat * 2)] }}
              defaultValue={rat}
              value={rat}
              precision={0.1}
              readOnly
            />
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 210,
      flex: 1,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <Button
            onClick={() => {
              setDataDialog({
                data: dataAPI.filter(({ event }) => {
                  const token =
                    event?.tutors?.token === row?.event?.tutors?.token;

                  if (rangeDate.length) {
                    return (
                      new Date(rangeDate[0]) <= new Date(event?.data) &&
                      new Date(event?.data) <= new Date(rangeDate[1]) &&
                      token
                    );
                  }

                  return token;
                }),
                fullNome: `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`,
              });
              setOpen(true);
            }}
          >
            <RemoveRedEyeIcon />
          </Button>

          <Button>
            <Link
              color="primary"
              target={"_blank"}
              href={`https://api.whatsapp.com/send?phone=${row?.event?.tutors?.phone}&text=Ciao ${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`}
            >
              <WhatsAppIcon />
            </Link>
          </Button>

          <Button
            onClick={() =>
              getNotes({
                token: row?.event?.tutor_token,
                fullNome: `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`,
                numFeedback: row?.event?.tutors?.notes_feedback?.[0]?.num_notes,
                person: "tutors",
              })
            }
          >
            <Badge
              color="warning"
              badgeContent={row?.event?.tutors?.notes_feedback?.[0]?.num_notes}
            >
              <MessageRounded />
            </Badge>
          </Button>
        </>
      ),
    },
  ];

  // ROWS 
  const generateRows = (data: Array<Object>) => {
    const people: Array<string> = [];

    data.forEach(
      (e: any) =>
        e?.event?.tutors?.token &&
        !people.includes(e.event.tutors.token) &&
        people.push(e.event.tutors.token)
    );

    const groupedFeedbacks = {};
    data.forEach((element: any) => {
      const tutor =
        element?.event?.tutors?.first_name?.slice(0, 1) +
        "." +
        element?.event?.tutors?.last_name?.slice(0, 1) +
        ". " +
        element?.event?.tutors?.token;

      if (!groupedFeedbacks[tutor]) {
        groupedFeedbacks[tutor] = [];
      }

      let improvements = JSON.parse(element?.improvements || "[]");
      if (typeof improvements === "string")
        improvements = JSON.parse(improvements);

      const improv = Array.isArray(improvements) ? improvements.join(", ") : [];
      const improvObject = isObject(improvements)
        ? Object.keys(improvements)
        : [];

      const improvementsParsed = new Array();
      if (improvObject?.length) {
        improvObject.forEach((el) => {
          improvementsParsed.push(improvements[el]);
        });
      }

      const feedback = [
        element?.event?.data
          ? format(new Date(element?.event?.data), "dd/MM/yyyy")
          : "-",
        element?.user?.first_name + " " + element?.user?.last_name,
        element?.rating_tutor
          ? Number(element?.rating_tutor)
          : Number(element?.punto / 2),
        element?.first_feedback
          ? element?.first_feedback
          : improvementsParsed?.length && element?.first_feedback
          ? ", " + improvementsParsed?.join(", ")
          : improvementsParsed?.length
          ? improvementsParsed?.join(", ")
          : improv
          ? ", " + improv
          : element?.last_feedback
          ? ", " + element?.last_feedback
          : "",
      ];

      groupedFeedbacks[tutor].push(feedback);
    });

    setRowsCSV(groupedFeedbacks);

    return people.map((token) => {
      let ratingMedio = 0;
      let count = 0;
      let dataIndex = {};
      data.forEach((d: any) => {
        if (token !== d?.event?.tutors?.token) return;

        ratingMedio += Number(d?.rating_tutor)
          ? Number(d?.rating_tutor)
          : Number(d?.punto / 2);
        count++;
        dataIndex = d;
      });
      return { ...dataIndex, rating: ratingMedio / count };
    });
  };

  const { isMobile } = useScreen();

  useEffect(() => {
    dataAPI && setRows(generateRows(filterData(dataAPI)));
  }, [dataAPI, rangeDate]);

  return (
    <>
      <DataGrid
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={TableStyling}
        rows={rows}
        style={!isMobile ? { height: "calc(100vh - 14rem)" } : {}}
        columns={columns}
        loading={isLoading}
        pageSize={20}
        autoHeight={isMobile}
        components={{
          Toolbar: () => (
            <ToolbarTutors
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
              data={rowsCSV}
            />
          ),
        }}
        initialState={{
          sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        rowsPerPageOptions={[20]}
        experimentalFeatures={{ newEditingApi: false }}
        columnVisibilityModel={{ created_at: false }}
      />

      {/* <DialogTutors
      name={feed}
        {...{
          open,
          data: feedbacks?.data ?? [],
          name: feedbacks?.fullName ?? "",
        }}
        onClose={() => }
      /> */}
    </>
  );
};

export default StudentsFeedbacks;
