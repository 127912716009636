import { Box, Button } from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

export default ({ filters, setFilters, recruitingFormRef }) => {
  return (
    <Box
      id="filter_bar"
      className="flex gap-4 flex-wrap !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Button
          variant="outlined"
          onClick={() => recruitingFormRef?.current.open()}
        >
          <img
            src={addIcon}
            className="icon"
          />
          Add manually
        </Button>
      </FiltersBar>

      <SearchToolbar
        client={true}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};
