import { useMemo, useState, useEffect, useRef } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, useTheme } from "@mui/material";
import CreateLinkDialog, {
  INForm,
} from "@/components/AdsPage/CreateLinkDialog";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "@/components/AdsPage/Toolbar";
import BasePage from "@/components/Page/Base";
import copyIcon from "@/assets/icons/copy.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import deleteIcon from "@/assets/icons/delete.svg";
import LandingPreview from "@/components/LandingPreview";
import CustomPagination from "@/components/_Common/CustomPagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import LinksModal from "@/components/AdsPage/ManageLinks";

const MAX_LENGTH_TEXT = 16;

const KEY_QUERY = ["ads-influencers"];

const AdsPage = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler, isAdmission } = useAuth();
  const linksModalRef = useRef<{ open: () => void; close: () => void }>(null);

  const [rowSelected, setRowSelected] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openDialogCreateLink, setOpenDialogCreateLink] =
    useState<boolean>(false);
  const [landings, setLandings] = useState<string[]>([]);

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const page = filterData?.page || 1;

  const setPage = (value: any) => {
    setFilters((p: any) => {
      p.set("page", value);
      return p;
    });
  };

  const sorts = filterData?.sorts;
  delete filterData?.sorts;
  delete filterData?.page;

  const keyData = [KEY_QUERY, page, filterData, sorts, perPage, isAdmission];

  const { data: dataAPI, isLoading } = useQuery(
    keyData,
    () =>
      useAPI("/v1/influencers", {
        page,
        sorts,
        filter: filterData,
        per_page: perPage,
        isAdmission,
      }),
    { keepPreviousData: true }
  );

  const data = dataAPI?.data;
  const adsData = data?.data || [];
  const pagination = {
    total: data?.total || 1,
    last_page: data?.last_page || 1,
    current_page: data?.current_page || 1,
  };

  const handleCreateLink = async (formData: INForm) => {
    await useAPI("/v1/influencers/create", { ...formData, isAdmission });
    queryClient.invalidateQueries(keyData);
    setOpenDialogCreateLink(false);
    snackHandler("Success create ADS!");
  };

  const handleDelete = async () => {
    setOpenConfirmDialog(false);
    await useAPI(`/v1/influencers/${rowSelected.id}/delete`);
    queryClient.invalidateQueries(keyData);
    snackHandler("Success deleted!");
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 70 },
      {
        field: "type",
        headerName: "type",
        minWidth: 100,
        flex: 0.5,
        renderCell: ({ row }) => (row.ads ? "Landing" : "Form"),
      },
      {
        field: "tracking_name",
        headerName: "tracking name",
        minWidth: 180,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.tracking_name?.length > MAX_LENGTH_TEXT ? (
            <MyTooltip>{row.tracking_name}</MyTooltip>
          ) : (
            row.tracking_name
          );
        },
      },
      {
        field: "utm_source",
        headerName: "utm source",
        minWidth: 110,
        flex: 0.7,
        sortable: false,
        renderCell: ({ row }) => {
          return row?.utm_source?.length > MAX_LENGTH_TEXT ? (
            <MyTooltip>{row.utm_source}</MyTooltip>
          ) : (
            row.utm_source
          );
        },
      },
      {
        field: "utm_medium",
        headerName: "utm medium",
        minWidth: 120,
        sortable: false,
        renderCell: ({ row }) => {
          return row?.utm_medium?.length > MAX_LENGTH_TEXT ? (
            <MyTooltip>{row.utm_medium}</MyTooltip>
          ) : (
            row.utm_medium
          );
        },
      },
      {
        field: "utm_campaign",
        headerName: "utm campaign",
        minWidth: 130,
        sortable: false,
        renderCell: ({ row }) => {
          return row?.utm_campaign?.length > MAX_LENGTH_TEXT ? (
            <MyTooltip>{row.utm_campaign}</MyTooltip>
          ) : (
            row.utm_campaign
          );
        },
      },
      {
        sortable: false,
        field: "utm_content",
        headerName: "utm content",
        minWidth: 140,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.utm_content?.length > MAX_LENGTH_TEXT ? (
            <MyTooltip>{row.utm_content}</MyTooltip>
          ) : (
            row.utm_content
          );
        },
      },
      {
        field: "utm_ads",
        headerName: "utm ads",
        minWidth: 150,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => {
          return row?.utm_ads?.length > MAX_LENGTH_TEXT ? (
            <MyTooltip>{row.utm_ads}</MyTooltip>
          ) : (
            row.utm_ads
          );
        },
      },
      {
        field: "actions",
        headerName: "actions",
        sortable: false,
        minWidth: 120,
        flex: 0.5,
        renderCell: ({ row }) => {
          return (
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                background: theme.palette.background.paper,
                borderRadius: "8px",
              }}
            >
              <Dropdown
                text=""
                icon={copyIcon}
                content={
                  <LandingPreview
                    urls={landings}
                    basicUrl={row.associated_link}
                    token={row?.token}
                  />
                }
                buttonProps={{ variant: "standard" }}
              />

              <Button
                className="p-1"
                onClick={() => {
                  setRowSelected(row);
                  setOpenConfirmDialog(true);
                }}
              >
                <img
                  src={deleteIcon}
                  className="icon no-margin"
                />
              </Button>
            </Box>
          );
        },
      },
    ],
    [dataAPI, landings]
  );

  const refetchAvailableLandings = async () => {
    const data = await useAPI("/v1/lead/landing/refresh", { isAdmission });

    if (data) {
      setLandings(data);
      snackHandler("Success refetch available landings!");
    } else {
      snackHandler("Failed refetch available landings!", "error");
    }
  };

  useEffect(() => {
    if (!dataAPI?.landings) return;
    setLandings(dataAPI?.landings);
  }, [dataAPI]);

  return (
    <BasePage title="ADS">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={adsData}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        componentsProps={{
          toolbar: {
            setPage,
            setFilters,
            refetchAvailableLandings,
            filters: filterData,
            onClickAdd: () => setOpenDialogCreateLink(true),
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            linksModalRef,
          },
          pagination: { page, setPage, pagination },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <CreateLinkDialog
        open={openDialogCreateLink}
        params={dataAPI?.params}
        onClose={() => setOpenDialogCreateLink(false)}
        onCreateLink={handleCreateLink}
      />

      <LinksModal ref={linksModalRef} />
      <ConfirmationDialog
        open={openConfirmDialog}
        title={
          <>
            <img src={deleteIcon} /> DELETE LINK
          </>
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleDelete}
      >
        Are you sure to remove it?
      </ConfirmationDialog>
    </BasePage>
  );
};

export default AdsPage;
