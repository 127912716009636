import { forwardRef, useImperativeHandle, useState } from "react";
import { queryClient } from "@/main";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Box, Button, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import saveIcon from "@/assets/icons/save.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AvailabilitiesIcon from "@/assets/icons/availabilities.svg";

export interface IForwardRef {
  open: (tokens: Array<number> | number, settings: any) => void;
}

interface IProps {
  cacheKey: Array<any>;
  deadlineAPI: null | string;
  snackHandler: any;
}

const StretchRangeInsertAvailabilities = forwardRef<IForwardRef, IProps>(
  ({ deadlineAPI, snackHandler, cacheKey }, ref) => {
    const [date, setDate] = useState<string | null>(null);
    const [idUsers, setIdUsers] = useState<Array<number>>();
    const [settings, setSettings] = useState<any>({});

    useImperativeHandle(ref, () => ({
      open: (idUsers, settings) => {
        setDate(deadlineAPI);

        const idUsersAr = idUsers?.[0] || idUsers;

        setIdUsers(Array.isArray(idUsersAr) ? idUsersAr : [idUsersAr]);

        if (settings?.availabilities) {
          setSettings(settings?.availabilities);
        }
      },
    }));

    const handleAddValue = async (value: number, isDay: boolean = false) => {
      const res = await useAPI("/v1/settings/set/users/availabilities", {
        value,
        isDay,
        idUsers,
        isFastSet: true,
      });

      if (!res?.status) {
        snackHandler("Error add other date", "error");
        return;
      }

      snackHandler("Success add other date");

      if (res?.settings?.availabilities) {
        queryClient.invalidateQueries(cacheKey);
        setSettings(res?.settings?.availabilities);
      }
    };

    const handleAddDate = async () => {
      const res = await useAPI("/v1/settings/set/users/availabilities", {
        date,
        idUsers,
        isFastSet: false,
      });

      if (!res?.status) {
        snackHandler("Error add other date", "error");
        return;
      }

      snackHandler("Success add other date");

      if (res?.settings?.availabilities) {
        queryClient.invalidateQueries(cacheKey);
        setSettings(res?.settings?.availabilities);
      }
    };

    const handleSetRemoveLimit = async (removeLimit: boolean) => {
      const res = await useAPI("/v1/settings/set/users/availabilities", {
        idUsers,
        removeLimit,
      });

      if (!res?.status) {
        snackHandler("Error remove limit", "error");
        return;
      }

      snackHandler("Success remove limit");

      if (res?.settings?.availabilities) {
        queryClient.invalidateQueries(cacheKey);
        setSettings(res?.settings?.availabilities);
      }
    };

    const handleClose = () => {
      setDate(null);
      setIdUsers(undefined);
      setSettings({});
    };

    return (
      <EduModal
        fullWidth
        open={Boolean(idUsers?.length)}
        onClose={handleClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <img
            src={AvailabilitiesIcon}
            className="icon"
          />
          Stretch range insert availabilities
        </EduModalTitle>

        <EduModalBody className="flex flex-col gap-10 p-5">
          {Boolean(settings?.removeLimit) && (
            <Box className="text-[#f66] text-2xl text-center">
              <WarningAmberIcon
                color="error"
                fontSize="large"
                className="mr-2"
              />
              Remove limit ON
            </Box>
          )}

          {Boolean(settings?.currentMonth) &&
            !Boolean(settings?.removeLimit) && (
              <Box className="flex flex-col gap-2">
                {[
                  {
                    label: "Current month:",
                    value: settings?.currentMonth,
                  },
                  {
                    label: "Current deadline:",
                    value: settings?.deadlineDate,
                  },
                  {
                    label: "Stretch date:",
                    value: settings?.stretchDateAfterInsertRange,
                  },
                ].map(({ label, value }, i) =>
                  Boolean(value) ? (
                    <Box
                      key={label + i}
                      className="flex justify-center"
                    >
                      <span className="pr-2">{label}</span>
                      <b
                        className={
                          settings?.stretchDateAfterInsertRange == value &&
                          settings?.deadlineDate !=
                            settings?.stretchDateAfterInsertRange
                            ? "underline"
                            : ""
                        }
                      >
                        {value}
                      </b>
                    </Box>
                  ) : null
                )}
              </Box>
            )}

          <Box className="flex justify-center items-center gap-4">
            <Button
              variant="outlined"
              onClick={() => handleSetRemoveLimit(false)}
            >
              NO
            </Button>
            <Box>Remove limit</Box>
            <Button
              color="error"
              variant="outlined"
              onClick={() => handleSetRemoveLimit(true)}
            >
              YES
            </Button>
          </Box>

          <Box className="flex flex-col items-center gap-4">
            <Box className="flex justify-center gap-4">
              {[1, 3, 7, 12].map((h) => (
                <Button
                  key={h + "hour"}
                  variant="outlined"
                  onClick={() => handleAddValue(h)}
                  className={!h ? "grow" : ""}
                >
                  +{h} hour{h > 1 && "s"}
                </Button>
              ))}
            </Box>

            <Box className="flex justify-center gap-4">
              {[1, 2, 3].map((d) => (
                <Button
                  key={d + "day"}
                  variant="outlined"
                  onClick={() => handleAddValue(d, true)}
                >
                  +{d} day{d > 1 && "s"}
                </Button>
              ))}
            </Box>
          </Box>

          <Box className="flex flex-col gap-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={date}
                views={["day", "hours", "minutes"]}
                minDate={dayjs(deadlineAPI).format("YYYY-MM-DD HH:mm")}
                maxDate={dayjs(deadlineAPI)
                  .add(5, "days")
                  .format("YYYY-MM-DD HH:mm")}
                onChange={(d) => setDate(dayjs(d).format("YYYY-MM-DD HH:mm"))}
                inputFormat="yyyy-MM-dd HH:mm"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Insert other range after primary range"
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>

            <Box className="flex justify-between">
              <Button
                variant="outlined"
                onClick={() => handleAddValue(0)}
              >
                <img
                  src={refreshIcon}
                  className="icon"
                />
                RESET
              </Button>

              <LoadingButton
                type="submit"
                color="primary"
                variant="outlined"
                onClick={handleAddDate}
                disabled={!Boolean(date && date != settings?.deadlineDate)}
              >
                <img
                  src={saveIcon}
                  className="icon"
                />
                Save
              </LoadingButton>
            </Box>
          </Box>
        </EduModalBody>
      </EduModal>
    );
  }
);

export default StretchRangeInsertAvailabilities;
