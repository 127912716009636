import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import useAPI from '@/api/useAPI';

interface ActionMenuProps {
  adminId: number;
}

const cacheKey = ['callCenter']
const ActionMenu: React.FC<ActionMenuProps> = ({ adminId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const queryClient = useQueryClient();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCallCenter = useMutation(
    async () => {
      await useAPI(`v1/call-center/delete/${adminId}`, {}, { type: 'delete' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );

  const handleDelete = () => {
    deleteCallCenter.mutate();
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionMenu;
