import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Button,
  Switch,
  useTheme,
  Typography,
  FormControlLabel,
} from "@mui/material";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./Toolbar";
import useScreen from "@/hooks/useScreen";
import usePagination from "@/hooks/usePagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const Table = ({
  hide,
  setHide,
  idMonth,
  pastMonth,
  setIdMonth,
  setOpenEdit,
  updateStatus,
  filteredData,
  setOpenCreate,
  openConfirmDialog,
  setOpenConfirmDialog,
  togglePastMonthFilter,
}) => {
  const theme = useTheme();
  const { perPage } = useScreen();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const columns: GridColDef[] = [
    {
      field: "start_month",
      headerName: "Start month",
      minWidth: 140,
      flex: 1,
      valueGetter: (params) =>
        useConvertStartMonthEn(params.row.start_month) ||
        params.row.start_month,
      renderCell: (params) => params.value || "-",
      sortComparator: (v1, v2) => {
        const date1 = new Date(v1.split("/").reverse().join("-"));
        const date2 = new Date(v2.split("/").reverse().join("-"));
        return date1.getTime() - date2.getTime();
      },
    },
    {
      field: "kickoff_date",
      headerName: "Kick Off Date",
      minWidth: 110,
      flex: 1,
      valueGetter: ({ row }) =>
        String(row.kickoff_date.split("/").reverse().join("-")),
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      minWidth: 110,
      flex: 1,
      valueGetter: ({ row }) =>
        String(row.payment_date) ? String(row.payment_date) : "-",
    },
    {
      field: "actions",
      headerName: "Visible",
      width: 100,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      valueGetter: ({ row }) => (row.hide ? "off" : "on"),
      renderCell: ({ row }) => (
        <FormControlLabel
          sx={{ width: "6rem" }}
          name={row?.uuid}
          control={
            <Switch
              color="primary"
              checked={!row.hide}
              disabled={pastMonth}
              onChange={() => {
                setIdMonth(row?.id);
                setHide(!row.hide);
                setOpenConfirmDialog((prev) => !prev);
              }}
            />
          }
          label={
            <Typography color={!row.hide ? "primary" : ""}>
              {!row.hide ? "on" : "off"}
            </Typography>
          }
          className="uppercase"
          labelPlacement="end"
        />
      ),
    },
    {
      field: "actions-edit",
      headerName: "",
      width: 70,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <Button
          variant="outlined"
          onClick={() => {
            setIdMonth(row.id);
            setOpenEdit(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={filteredData || []}
        page={parseInt(page.toString()) - 1}
        columns={columns}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar: Toolbar,
          Pagination: CustomPaginationClient,
        }}
        onPageChange={(newPage) => setPage(newPage + 1)}
        componentsProps={{
          toolbar: {
            pastMonth,
            setFilters,
            setOpenCreate,
            togglePastMonthFilter,
            filters: filterData,
          },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={() => updateStatus.mutate({ id: idMonth, hide: hide })}
      >
        Do you want to set this month {!hide ? "visible" : "hidden"}?
      </ConfirmationDialog>
    </>
  );
};

export default Table;
