import { FC, useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { AddNewExercise } from "./new-exercise(pot)/AddNewExercise";
import { AddOldExercise } from "./old-exercise(ielts)/AddOldExercise";
import {
  DialogType,
  PotExercise,
  IeltsExercise,
  AddNewExerciseContentPropsType,
} from "../exercisesV3.types";

const AddExerciseContent: FC<AddNewExerciseContentPropsType> = ({
  type,
  isEditing,
  playerRef,
  watchImgRef,
  exercisePayload,
}) => {
  const [typeToggle, setTypeToggle] = useState<DialogType>(type ?? "ielts-old");

  return (
    <Box className="h-full flex flex-col gap-1 p-4">
      {!isEditing && (
        <Tabs
          centered
          value={typeToggle}
          onChange={(_, v) => setTypeToggle(v)}
        >
          <Tab
            value="ielts-old"
            label="Old Exercise"
          />

          <Tab
            value="pot-new"
            label="New Exercise"
          />
        </Tabs>
      )}

      <Box className="grow">
        {typeToggle === "pot-new" ? (
          <AddNewExercise
            isEditing={!!isEditing}
            playerRef={playerRef}
            watchImgRef={watchImgRef}
            exercisePayload={exercisePayload as PotExercise}
          />
        ) : (
          <AddOldExercise
            // @ts-ignore
            exercisePayload={exercisePayload as IeltsExercise}
          />
        )}
      </Box>
    </Box>
  );
};

export default AddExerciseContent;
