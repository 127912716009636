import { useRef, useEffect, useState, ChangeEvent } from "react";
import useAuth from '@/hooks/useAuth';
import { Box, IconButton, TextField } from "@mui/material";
import useAPI from "@/api/useAPI";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";

interface MaterialFileSelectProps {
  disabled?: boolean;
  materialId?: Int16Array;
  onFileChange: (file: File | null) => void;
  initialFileName?: string;
  shouldMakeAPICall?: boolean;
}

const MaterialFileSelect: React.FC<MaterialFileSelectProps> = ({
  disabled,
  materialId,
  onFileChange,
  initialFileName,
  shouldMakeAPICall,
}) => {
  const { snackHandler } = useAuth();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState<string>(initialFileName || "");

  useEffect(() => {
    if (initialFileName) {
      setFileName(initialFileName);
    }
  }, [initialFileName]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (file.size > 220000000) {
        snackHandler("File size is bigger than 220mb", "error");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }
      setFileName(file.name);
      onFileChange(file);
    }
  };

  const handleRemoveFile = async () => {
    if (shouldMakeAPICall) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFileName("");
      onFileChange(null);

      try {
        const response = await useAPI(
          `/v1/material/${materialId}/remove-resource`,
          { file: true },
          { type: "post" }
        );
        snackHandler(response.message, "success");
      } catch (error) {
        snackHandler("An error occurred while removing the file", "error");
        console.error("Error removing file:", error);
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFileName("");
      onFileChange(null);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        accept="application/pdf, video/mp4"
        disabled={disabled}
        onChange={handleFileChange}
      />

      <TextField
        fullWidth
        sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
        label="Choose a File"
        value={fileName}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              {fileName && (
                <IconButton onClick={handleRemoveFile}>
                  <CloseIcon />
                </IconButton>
              )}

              <IconButton
                onClick={() => !disabled && fileInputRef.current?.click()}
                disabled={disabled}
              >
                <UploadIcon />
              </IconButton>
            </>
          ),
        }}
        variant="outlined"
        disabled={disabled}
      />
    </Box>
  );
};

export default MaterialFileSelect;
