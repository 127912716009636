import MONTHS from "@/components/assets/MONTHS";

const useConvertStartMonthEn = (text: string) => {
  let temp = text;

  for (let monthEn in MONTHS) {
    const monthIt = MONTHS[monthEn];

    if (temp?.indexOf(monthIt) > -1) {
      temp = temp?.replace(monthIt, monthEn);
      break;
    }
  }

  return temp;
};

export default useConvertStartMonthEn;
