import { useState, useEffect } from "react";
import { format } from "date-fns";
import { queryClient } from "@/main";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import commentIcon from "@/assets/icons/comments.svg";
import ConfirmationDialog from "@/components/ConfirmationDialog";

const Comments = ({ open, setOpen, cacheKey, userAdmin, selectedUser }) => {
  const theme = useTheme();

  const [data, setData] = useState<Array<object>>(selectedUser?.note ?? []);
  const [addNote, setNewNote] = useState(false);
  const [content, setContent] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [commentIndex, setCommentIndex] = useState<number | null>(null);

  useEffect(() => {
    setData(
      selectedUser?.note && typeof selectedUser?.note === "string"
        ? JSON.parse(selectedUser?.note)
        : selectedUser?.note || []
    );
  }, [selectedUser?.note]);

  const agree = async () => {
    const { id, first_name, last_name, email } = userAdmin.user;

    const newNote = {
      admin: { id, first_name, last_name, email },
      created:
        format(new Date(), "dd-MM ") + " at " + format(new Date(), "HH:mm"),
      created_at: new Date(),
      content: content,
    };

    try {
      await useAPI(`/v1/recruitings/notes/${selectedUser.id}`, newNote);
      setData((prevData) => [...prevData, newNote]);
      queryClient.invalidateQueries(cacheKey);
      setContent("");
      setNewNote(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteComment = async () => {
    const rs = await useAPI(
      `/v1/recruitings/notes/${selectedUser.id}/${commentIndex}/delete`
    );
    queryClient.invalidateQueries(cacheKey);
    setCommentIndex(null);
    setOpenConfirm(false);
  };

  const onClose = () => {
    setOpen(false);
    setContent("");
    setNewNote(false);
  };

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={onClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={onClose}>
          <img
            src={commentIcon}
            className="icon"
          />
          Notes
        </EduModalTitle>

        <EduModalBody className="p-5">
          <Box className="flex flex-col gap-4">
            {Array.isArray(data) && data.length > 0 ? (
              data?.map((e: any, i) => (
                <div key={i}>
                  <div className="mb-2 flex justify-between items-center">
                    <Typography
                      color={"primary"}
                      fontSize="10px"
                    >
                      {e?.admin.name} {e?.admin.lname} wrote on {e?.created}
                    </Typography>
                    <Button
                      variant="outlined"
                      title="Delete Note"
                      onClick={() => {
                        setCommentIndex(i);
                        setOpenConfirm(true);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        className="icon no-margin"
                      />
                    </Button>
                  </div>

                  <Box
                    className="flex flex-col border rounded mt-1"
                    sx={{
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                    }}
                  >
                    <Typography className="p-2">{e?.content}</Typography>
                  </Box>
                </div>
              ))
            ) : (
              <Typography
                sx={{ textAlign: "center", color: "#B4B4B4" }}
                fontSize={16}
              >
                No comments here, add the first!
              </Typography>
            )}
          </Box>

          <Box className="flex gap-2 flex-col mt-5">
            {addNote ? (
              <>
                <Box className="flex gap-4 items-center justify-between grow">
                  <Box className="text-lg">New note</Box>
                </Box>

                <TextareaAutosize
                  value={content}
                  minRows={5}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Write here..."
                />
                <div className="flex justify-end">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => agree()}
                    disabled={!content}
                  >
                    <img
                      src={saveIcon}
                      className="icon"
                    />
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex justify-end">
                <Button
                  variant="outlined"
                  onClick={() => setNewNote(true)}
                >
                  <img
                    src={addIcon}
                    className="icon"
                  />
                  Add note
                </Button>
              </div>
            )}
          </Box>
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleDeleteComment}
      >
        Do you want to delete comment?
      </ConfirmationDialog>
    </>
  );
};

export default Comments;
