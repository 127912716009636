import { forwardRef, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import saveIcon from "@/assets/icons/save.svg";
import editIcon from "@/assets/icons/edit.svg";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";

export interface IRefEdit {
  open: (user: any) => void;
}

interface IProps {
  keyQuery: Array<any>;
}

const Edit = forwardRef<IRefEdit, IProps>(({ keyQuery }, ref) => {
  const { snackHandler } = useAuth();

  const [user, setUser] = useState<any>();
  const [newData, setNewData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: (user) => {
      setUser(user);
    },
  }));

  const handleChangeEdit = ({ target: { name, value } }) => {
    setNewData((p: any) => {
      const t = { ...p };
      t[name] = value;
      return t;
    });
  };

  const handleSave = async () => {
    setIsLoading(true);

    const res = await useAPI("/v1/users/level-change/change-english-level", {
      ...newData,
      userToken: user?.token,
    });

    setIsLoading(false);

    if (!res?.status) {
      snackHandler(res?.message || "Failed edit!", "error");
      return;
    }

    snackHandler("Success edit!");
    queryClient.invalidateQueries(keyQuery);
  };

  const handleClose = () => {
    setUser(undefined);
    setNewData({});
    setIsLoading(false);
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(user)}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={editIcon}
          className="icon no-margin"
        />
        Edit - {user?.fullName || ""}
      </EduModalTitle>

      <EduModalBody className="p-5 pt-7 flex flex-col gap-4">
        <TextField
          select
          fullWidth
          name="english_level"
          label="English level"
          value={newData?.english_level || user?.english_level || ""}
          onChange={handleChangeEdit}
        >
          {ENGLISH_LEVELS?.map((l, i: number) => (
            <MenuItem
              key={l + i}
              value={l}
            >
              {l}
            </MenuItem>
          ))}
        </TextField>

        <Box className="flex flex-row-reverse">
          <LoadingButton
            type="submit"
            color="primary"
            variant="outlined"
            loading={isLoading}
            disabled={!newData}
            onClick={() => {
              if (newData != undefined) handleSave();
            }}
          >
            <img
              src={saveIcon}
              className={`icon ${isLoading ? "opacity-0" : ""}`}
            />
            Save
          </LoadingButton>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});

export default Edit;
