import editIcon from "@/assets/icons/edit.svg";
import addIcon from "@/assets/icons/save.svg";
import LANGUAGES from "@/components/assets/LANGUAGES";
import useAuth from "@/hooks/useAuth";
import { CALENDARS } from "@/providers/AuthProvider";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import PhoneField from "../_Common/Forms/PhoneField";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import SelectOwnedByFilter from "./SelectOwnedByFilter";

type IUserInfo = {
  lang?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  owned_by?: string;
  last_interviewer_or_calendar?: string;
};

interface IProps {
  open: boolean;
  user: any;
  onClose: () => void;
  onSubmit: (user: any, newDataUser: any) => void;
}

const UserInfoDialog = ({ open, user, onClose, onSubmit }: IProps) => {
  const { isMember, userAdmin } = useAuth();

  const sales = userAdmin?.calendars?.[CALENDARS.SALES];

  const meet =
    typeof user.meeting == "string" ? JSON.parse(user.meeting) : user.meeting;

  const interviewerOrCalendar =
    meet?.meet?.filter((f: any) => f?.interviewer)?.at(-1) ??
    meet?.meet?.filter((f: any) => f?.calendar)?.at(-1);

  const [userData, setUserData] = useState<IUserInfo>({
    lang: user.lang,
    first_name: user.first_name,
    last_name: user.last_name,
    phone: user.phone,
    email: user.email,
    owned_by: user.owned_by,
    ...(interviewerOrCalendar
      ? {
        last_interviewer_or_calendar:
          interviewerOrCalendar?.interviewer ??
          interviewerOrCalendar?.calendar ??
          "",
      }
      : {}),
  });

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    onSubmit?.(user, userData);
  };

  const onChangeData = ({ target: { name, value } }) => {
    if (name == "phone") {
      value = value.match(/^\+|\d+/g)?.join("")!;
    }
    setUserData((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={onClose}>
        <img src={editIcon} />
        Edit User information
      </EduModalTitle>

      <EduModalBody className="px-5 py-7">
        <Box
          component="form"
          className="flex flex-col gap-5"
          onSubmitCapture={handleSubmit}
        >
          <TextField
            required
            fullWidth
            name="first_name"
            type="text"
            label="First name"
            value={userData?.first_name ?? ""}
            onChange={onChangeData}
          />

          <TextField
            required
            fullWidth
            name="last_name"
            type="text"
            label="Last name"
            value={userData?.last_name ?? ""}
            onChange={onChangeData}
          />

          <TextField
            required
            fullWidth
            name="email"
            type="email"
            label="Email"
            value={userData?.email ?? ""}
            onChange={onChangeData}
          />

          <PhoneField
            required
            fullWidth
            type="text"
            size="small"
            name="phone"
            label="Phone"
            value={userData?.phone ?? ""}
            onChange={(newPhone: string) =>
              onChangeData({ target: { name: "phone", value: newPhone } })
            }
          />

          <TextField
            select
            required
            fullWidth
            name="lang"
            type="text"
            label="Language"
            value={userData?.lang ?? ""}
            onChange={onChangeData}
          >
            {LANGUAGES.map((lang) => (
              <MenuItem
                key={lang.iso}
                value={lang.iso}
              >
                <Box className="flex">
                  <span className="w-8">{lang.iso.toUpperCase()}</span>
                  <span>{lang.name}</span>
                </Box>
              </MenuItem>
            ))}
          </TextField>

          {(["Call center", "Free"].includes(userAdmin?.user?.activity ?? "") ||
            !isMember) && (
              <SelectOwnedByFilter
                useRemove
                items={sales || []}
                ownedBy={userData?.owned_by ?? ""}
                onChange={(e) => {
                  onChangeData({
                    target: { name: "owned_by", value: e.target.value },
                  });
                }}
              />
            )}

          {Boolean(userData?.last_interviewer_or_calendar && !isMember) && (
            <SelectOwnedByFilter
              useRemove
              items={sales || []}
              title="Last interviewer/calendar"
              ownedBy={userData?.last_interviewer_or_calendar ?? ""}
              onChange={(e) => {
                onChangeData({
                  target: {
                    name: "last_interviewer_or_calendar",
                    value: e.target.value,
                  },
                });
              }}
            />
          )}

          <Box className="flex flex-row-reverse">
            <Button
              type="submit"
              variant="outlined"
            >
              <img
                src={addIcon}
                className="icon"
              />
              Save
            </Button>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default UserInfoDialog;
