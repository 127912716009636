import {
  useRef,
  useState,
  useEffect,
  createRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTheme } from "@mui/material/styles";
import { InfoBoxRef } from "../interface";
import { Button, IconButton } from "@mui/material";
import { CallOutlined, InfoOutlined } from "@mui/icons-material";
import "./DialpadCallModal.css";
import Call from "@mui/icons-material/Call";
import InfoBox from "./InfoBox";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";

interface IProps {
  row: any;
}

const DialpadCallModal = forwardRef(({ row }: IProps, ref) => {
  const theme = useTheme();

  const modalRef = useRef<HTMLDivElement>(null);
  const infoBoxRef = createRef<InfoBoxRef>();

  const [open, setOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setOpen(true);
      setIsMinimized(false);
    },
    closeModal: () => {
      setOpen(false);
    },
  }));

  const initiateCall = (phone_number: string) => {
    const dialpadFrame = document.querySelector("iframe");

    dialpadFrame?.contentWindow?.postMessage(
      {
        api: "opencti_dialpad",
        version: "1.0",
        method: "initiate_call",
        payload: { phone_number },
      },
      "https://dialpad.com"
    );
  };

  const endCall = () => {
    const dialpadFrame = document.querySelector("iframe");

    dialpadFrame?.contentWindow?.postMessage(
      {
        api: "opencti_dialpad",
        method: "end_all_calls",
        version: "1.0",
      },
      "https://dialpad.com"
    );
  };

  const handleIframeLoad = () => {
    setIsButtonDisabled(false);
  };

  const handleClose = () => {
    setIsButtonDisabled(true);
    setOpen(false);
    endCall();
  };

  const handleMinimizeToggle = () => setIsMinimized((p) => !p);

  const phoneNumber = row?.phone || row?.phone;
  const fullName = row?.first_name + " " + row?.last_name;

  useEffect(() => {
    const modal = modalRef.current;

    if (!modal) return;

    if (!isMinimized) {
      modal.style.left = "50%";
      modal.style.top = "50%";
      modal.style.transform = "translate(-50%, -50%)";
      modal.style.position = "fixed";
    } else {
      modal.style.left = "50%";
      modal.style.bottom = "10px";
      modal.style.top = "unset";
      modal.style.right = "unset";
      modal.style.transform = "translateX(-50%)";
      modal.style.position = "fixed";
    }
  }, [isMinimized, open]);

  return (
    <>
      {open && (
        <div className="modal-wrapper">
          <div
            ref={modalRef}
            style={{
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.leftSideBarSub.main,
            }}
            className={`modal-container ${isMinimized ? "minimized" : ""}`}
          >
            <div
              style={{ backgroundColor: theme.palette.primary.main }}
              className="modal-header"
            >
              <div className="font-bold text-[1rem] flex items-center gap-2">
                <CallOutlined sx={{ filter: "brightness(0) invert(1)" }} />

                <span
                  className={classNames("text-white", {
                    "truncate whitespace-nowrap inline-block w-[170px]":
                      isMinimized,
                  })}
                >
                  DIALPAD {isMinimized ? " - " + row?.first_name : "CALL"}
                </span>
              </div>

              <div className="modal-controls">
                <IconButton
                  sx={{ color: (theme) => theme.palette.primary.main }}
                  onClick={handleMinimizeToggle}
                  className="w-[25px] h-[25px] !rounded-lg !bg-white"
                  aria-label="minimize"
                >
                  {isMinimized ? "☐" : "—"}
                </IconButton>

                <IconButton
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                  onClick={handleClose}
                  className="w-[25px] h-[25px] !rounded-lg !bg-white"
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <div
              style={{
                color: theme.palette.text.primary,
                display: isMinimized ? "none" : "block",
              }}
              className="modal-body"
            >
              <div className="modal-content">
                <div
                  style={{ color: theme.palette.text.primary }}
                  className="callee-name"
                >
                  {fullName}
                </div>

                <div className="button-group">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => initiateCall(phoneNumber)}
                    disabled={isButtonDisabled}
                  >
                    <Call className="mr-2" /> CALL {phoneNumber}
                  </Button>

                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={(e) => infoBoxRef.current?.openPopover(e)}
                  >
                    <InfoOutlined className="mr-2" />
                    INFO
                  </Button>
                </div>

                <iframe
                  src={`https://dialpad.com/apps/${
                    import.meta.env.VITE_DIALPAD_CTI_CLIENT_ID
                  }`}
                  width="100%"
                  title="Dialpad"
                  allow="microphone; speaker-selection; autoplay; camera; display-capture; hid"
                  height="300px"
                  onLoad={handleIframeLoad}
                  sandbox="allow-popups allow-scripts allow-same-origin"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <InfoBox
        row={row}
        ref={infoBoxRef}
      />
    </>
  );
});

export default DialpadCallModal;
