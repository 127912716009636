import { Box, Dialog } from "@mui/material";
import ConfirmButton from "@/components/ConfirmationButton";
import UseShowTemplate from "@/components/UseShowTemplate";
import { EduModal, EduModalBody, EduModalTitle } from "@/components/_Common/Modal/ModalStyles";

const TemplateEmail = ({ open, onClose, template, onConfirm, isLoading, showConfirm, onClick }) => {
  return (
    <EduModal open={open} onClose={onClose} fullWidth maxWidth="sm">
      <EduModalTitle onClose={onClose}>Send email</EduModalTitle>
      
      <EduModalBody>
        <UseShowTemplate>{template}</UseShowTemplate>

        <Box className="p-4">
          <ConfirmButton text={"SEND"} show={showConfirm} onClick={onClick} disabled={isLoading} isLoading={isLoading} onConfirm={onConfirm} />
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default TemplateEmail;
