import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { styled } from '@mui/system';
import { editorConfig } from "./editorConfig";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import LoadSerializedContentPlugin from "./plugins/LoadSerializedContentPlugin";
import OnChangePlugin from "./plugins/OnChangePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import "./styles.css";

function Placeholder() {
    return <div className="editor-placeholder">Enter some rich text...</div>;
}


const EditorContainer = styled('div')(({ theme }) => ({
    margin: '20px auto',
    borderRadius: '2px',
    width: '100%',
    height: '500px',
    color: theme.palette.text.primary,
    position: 'relative',
    lineHeight: '20px',
    fontWeight: 400,
    textAlign: 'left',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    // backgroundColor: theme.palette.primary.default
}));

const EditorInner = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.leftSideBarSub.main,
    position: 'relative',
    overflowY: 'auto',
    height: '100%',
}));

export default ({ content, setContent }) => {
    const onChange = (editorState) => {
        const editorStateJSON = editorState.toJSON();
        setContent(JSON.stringify(editorStateJSON));
    };


    return (
        <LexicalComposer initialConfig={editorConfig}>
            <EditorContainer>
                <ToolbarPlugin />
                <EditorInner>
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" />}
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <CodeHighlightPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <AutoLinkPlugin />
                    <LoadSerializedContentPlugin content={content} />
                    <OnChangePlugin onChange={onChange} />
                </EditorInner>
            </EditorContainer>
        </LexicalComposer>
    );
};
