import PaymentIcon from "@mui/icons-material/Payment";
import PaymentsIcon from "@mui/icons-material/Payments";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EventRounded from "@mui/icons-material/EventRounded";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import ContactEmergencyRoundedIcon from "@mui/icons-material/ContactEmergencyRounded";
import ProductionQuantityLimitsRoundedIcon from "@mui/icons-material/ProductionQuantityLimitsRounded";
import CRMIcon from "@/assets/icons/crm.svg";
import ProductIcon from "@/assets/icons/product.svg";
import AdIcon from "@/assets/icons/ad.svg";
import AdPerformanceIcon from "@/assets/icons/ad-performance.svg";
import PerformanceIcon from "@/assets/icons/performance.svg";
import ProductPerformanceIcon from "@/assets/icons/product-performance.svg";
import UserListIcon from "@/assets/icons/user-list.svg";
import GestioneIcon from "@/assets/icons/gestione-iscritti.svg";
import KickOffDatesIcon from "@/assets/icons/kick-off-dates.svg";
import EventsIcon from "@/assets/icons/events.svg";
import FeedbackIcon from "@/assets/icons/feedback.svg";
import ExerciseIcon from "@/assets/icons/exercise.svg";
import CrmExerciseIcon from "@/assets/icons/crm-exercise.svg";
import AvailabilitiesIcon from "@/assets/icons/availabilities.svg";
import BookedIcon from "@/assets/icons/booked.svg";
import ReferralIcon from "@/assets/icons/referral.svg";
import ChangeLogIcon from "@/assets/icons/change-log.svg";
import StudentsIcon from "@/assets/icons/students.svg";

export const links = [
  {
    name: "CRM",
    path: "/crm",
    icon: <img src={CRMIcon} />,
    group: "English",
  },
  {
    name: "Upselling",
    path: "/upselling/crm",
    icon: <GroupAddIcon />,
    group: "English",
  },
  // {
  //   name: "Calendars",
  //   path: "/calendars",
  //   icon: <EventAvailableRounded />,
  //   group: "English",
  // },
  {
    name: "Products",
    path: "/products",
    icon: <img src={ProductIcon} />,
    group: "English",
  },
  {
    name: "Ads",
    path: "/influencers",
    icon: <img src={AdIcon} />,
    group: "English",
  },
  {
    name: "Ads performance",
    path: "/ads-performance",
    icon: <img src={AdPerformanceIcon} />,
    group: "English",
  },
  {
    name: "Performance",
    path: "/performance",
    icon: <img src={PerformanceIcon} />,
    group: "English",
  },
  {
    name: "Products performance",
    path: "/products-performance",
    icon: <img src={ProductPerformanceIcon} />,
    group: "English",
  },
  {
    name: "Users List",
    path: "/users-list",
    icon: <img src={UserListIcon} />,
    group: "English",
  },
  {
    name: "Gestione iscritti",
    path: "/users",
    icon: <img src={GestioneIcon} />,
    group: "English",
  },
  {
    name: "Kick Off Dates",
    path: "/kickoff-dates",
    icon: <img src={KickOffDatesIcon} />,
    group: "English",
  },
  {
    name: "Events",
    path: "/events",
    icon: <img src={EventsIcon} />,
    group: "English",
  },
  {
    name: "Feedbacks",
    path: "/feedback",
    icon: <img src={FeedbackIcon} />,
    group: "English",
  },
  {
    name: "Exercises",
    path: "/exercises",
    icon: <img src={ExerciseIcon} />,
    group: "English",
  },
  {
    name: "CRM Exercises",
    path: "/crm-exercises",
    icon: <img src={CrmExerciseIcon} />,
    group: "English",
  },
  {
    name: "Availabilities",
    path: "/availabilities",
    icon: <img src={AvailabilitiesIcon} />,
    group: "English",
  },
  {
    name: "Booked Ielts",
    path: "/booked-ielts",
    icon: <img src={BookedIcon} />,
    group: "English",
  },
  {
    name: "Referrals",
    path: "/referrals",
    icon: <img src={ReferralIcon} />,
    group: "English",
  },
  {
    name: "CRM",
    path: "/tutoring/crm",
    icon: <img src={CRMIcon} />,
    group: "Admisions",
  },
  {
    name: "Products",
    path: "/tutoring/products",
    icon: <img src={ProductIcon} />,
    group: "Admisions",
  },
  {
    name: "ChangeLog",
    path: "/changelog",
    icon: <img src={ChangeLogIcon} />,
    group: "English",
  },
  {
    name: "Students",
    path: "/tutoring/students",
    icon: <img src={StudentsIcon} />,
    group: "Admisions",
  },
  {
    name: "Incoming payments",
    path: "/payments-in",
    icon: <PaymentIcon />,
    group: "Accounting",
  },
  {
    name: "Outgoing payments",
    path: "/payments-out",
    icon: <PaymentsIcon />,
    group: "Accounting",
  },
  {
    name: "Balances",
    path: "/corrispettivi",
    icon: <AccountBalanceIcon />,
    group: "Accounting",
  },
  {
    name: "Team",
    path: "/team",
    icon: <GroupsRoundedIcon />,
    group: "General",
  },
  {
    name: "Calendars",
    path: "/calendars",
    icon: <CalendarMonthIcon />,
    group: "General",
  },
  {
    name: "Tutors",
    path: "/tutors",
    icon: <EscalatorWarningIcon />,
    group: "General",
  },
  {
    name: "V.A.T.",
    path: "/users-vat",
    icon: <ContactMailIcon />,
    group: "Accounting",
  },
];

export const TableStyling = {
  "&.MuiDataGrid-root, .MuiDataGrid-footerContainer": {
    border: "none",
    fontSize: "1rem",
  },
  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },
  ".MuiButtonBase-root, .MuiInputBase-root, p ": {
    color: "rgb(16, 185, 129)",
  },
  ".MuiDataGrid-cell": {
    color: "#fff",
    overflow: "unset !important",
  },
  ".css-1ptx2yq-MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "2px solid rgb(16, 185, 129)",
  },
  ".MuiDataGrid-columnHeaders, .MuiDataGrid-cell": {
    borderBottom: "none",
  },
  border: "none",
  "& .MuiDataGrid-cell:focus": {
    outline: " none",
  },
  "& .MuiDataGrid-cell:focus-visible": {
    outline: " none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: " none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    // color: 'yellow',
  },
  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
};
