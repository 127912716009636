import { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import { useQuery } from "@tanstack/react-query";
import { TableStyling } from "@/dummy";
import { Box, LinearProgress } from "@mui/material";
import useScreen from "@/hooks/useScreen";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const Availabilities = () => {
  const { data, isLoading } = useQuery(["tutoring-students"], () =>
    useAPI("/v1/tutoring/students")
  );

  const [rows, setRows] = useState<GridRowsProp>([]);

  const columns: GridColDef[] = [
    {
      field: "user",
      minWidth: 180,
      flex: 1,
      headerName: "User",
      renderCell: ({ row }) => (
        <Box className="truncate">{`${row?.first_name} ${row?.last_name}`}</Box>
      ),
      valueGetter: ({ row }) => `${row?.first_name} ${row?.last_name}`,
    },
    {
      field: "email",
      minWidth: 250,
      flex: 1,
      headerName: "Email",
      renderCell: ({ row }) => (
        <Box className="truncate">{row?.email || "-"}</Box>
      ),
    },
  ];

  const { isMobile } = useScreen();

  useEffect(() => data && setRows(data), [data]);

  if (isLoading) return <LinearProgress />;

  return (
    <Box
      className="mt-8 mx-5 md:mx-10"
      style={{ height: "calc(100% - 10rem)" }}
    >
      <Box
        component="h2"
        className="text-3xl font-extrabold text-white mb-3"
      >
        Students
      </Box>

      <DataGrid
        style={
          !isMobile ? { height: "calc(100vh - 10rem)", fontSize: "1rem" } : {}
        }
        autoHeight={isMobile}
        loading={isLoading}
        columns={columns}
        rows={rows}
        pageSize={20}
        rowsPerPageOptions={[20]}
        // density="comfortable"
        disableVirtualization
        disableSelectionOnClick
        sx={TableStyling}
        experimentalFeatures={{ newEditingApi: false }}
        components={{
          Toolbar: () => (
            <Box
              className="flex"
              id="filter_bar"
            >
              <SearchToolbar />
            </Box>
          ),
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
};

export default Availabilities;
