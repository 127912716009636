import { FC, useState } from "react";
import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface CallBackDateDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (date: Date | null) => void;
}

const CallBackDateDialog: FC<CallBackDateDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Select Call Back Date</DialogTitle>

        <DialogContent>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            shouldDisableDate={(date) => date < new Date()}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            onClick={() => {
              onConfirm(selectedDate);
              onClose();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CallBackDateDialog;
