import Request from "./RequestAdmin";

export const getPaymentsOut = async (token, params) => {
  const { data } = await Request.call(
    {
      url: "/v2/payments-out",
      method: "GET",
      params,
    },
    token
  );

  return data;
};

export const createPaymentOut = async (token, formData, callback) => {
  const { data } = await Request.call(
    {
      url: "/v1/users/payments-out",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    token
  );

  if (callback) callback();

  return data;
};

export const updatePaymentOut = async ({ token, id, formData, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/payments-out/${id}`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    token
  );

  if (callback) callback();

  return result;
};

export const deletePaymentOut = async ({ token, alsoFile, id, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/payments-out/${id}/${alsoFile}`,
      method: "DELETE",
    },
    token
  );

  if (callback) callback();

  return result;
};

export const bulkUploadPaymentOut = async (token, formData, callback) => {
  const { data } = await Request.call(
    {
      url: "/v1/users/payments-out/bulk-upload",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    token
  );

  if (callback) callback();

  return data;
};

export const downloadPaymentsOut = async (
  token,
  params,
  fileName = "export.csv"
) => {
  const { data } = await Request.download(
    {
      url: "/v2/payments-out",
      method: "GET",
      params: { ...params, export: true },
    },
    token,
    fileName
  );

  return data;
};
