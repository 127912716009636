import ThemeIcon from "@/assets/icons/theme.svg";
import useAuth from "@/hooks/useAuth";
import { TYPEOF } from "@/providers/AuthProvider";
import { PaletteMode, Tooltip } from "@mui/material";

const ChangeTheme = () => {
  const { currentTheme, setCurrentTheme, setCurrentColor } = useAuth();

  const handleClick = () => {
    let theme = localStorage.getItem("custom-theme") || currentTheme;
    let color =
      localStorage.getItem("typeOf") === TYPEOF.ADMISSION ? "cyan" : "purple";

    theme = theme === "light" ? "dark" : "light";

    localStorage.setItem("custom-theme", theme);
    localStorage.setItem("custom-color", color);
    setCurrentTheme(theme as PaletteMode);
    setCurrentColor(color);
  };

  return (
    <Tooltip
      title={"Change to " + (currentTheme === "dark" ? "light" : "dark")}
      placement="right"
    >
      <button
        type="button"
        onClick={handleClick}
      >
        <img src={ThemeIcon} />
      </button>
    </Tooltip>
  );
};

export default ChangeTheme;
