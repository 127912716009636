import { ChangeEvent } from "react";

export default (
  e: ChangeEvent<HTMLInputElement>,
  callback: (value: string | ArrayBuffer | null | undefined, file: File) => void
) => {
  if (e.target.files && e.target.files.length > 0) {
    const selectedFile = e.target.files[0];

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) =>
      callback(e.target?.result, selectedFile);

    reader.readAsDataURL(selectedFile);

    return;
  }

  console.error("Event not have value into useFileRenderPreview");
};
