import { Dispatch, SetStateAction, useState } from "react";
import { Box } from "@mui/material";
import useAuth from '@/hooks/useAuth';
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import ConfirmationDialog from "@/components/ConfirmationDialog";

interface IProps {
  setShow: Dispatch<SetStateAction<boolean>>;
  dataCreatedAt: string;
  openConfirmSend: boolean;
  setOpenConfirmSend: Dispatch<SetStateAction<boolean>>;
}

const ConfirmSendEmailAndNotifications = ({
  setShow,
  dataCreatedAt,
  openConfirmSend,
  setOpenConfirmSend,
}: IProps) => {
  const { snackHandler } = useAuth();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const handleSubmit = async () => {
    setShow(false);
    setOpenConfirm(false);
    setOpenConfirmSend(false);

    const res = await useAPI("/v2/events/add-events/send-emails-notifications");

    if (!res?.status) {
      snackHandler("Error send", "error");
      return;
    }

    snackHandler("Success send");
  };

  return (
    <>
      <ConfirmationDialog
        open={Boolean(openConfirmSend && dataCreatedAt)}
        title="Confirm send emails and notifications"
        onClose={() => {
          setOpenConfirmSend(false);
          setShow(false);
        }}
        maxWidth="xs"
        onConfirm={() => setOpenConfirm(true)}
      >
        <Box className="text-center">
          Want to send emails and notifications to students of events uploaded
          on this date "<b>{dayjs(dataCreatedAt).format("YYYY-MM-DD")}</b>",
          <br />
          Speaking, IELTS and Grammar?
        </Box>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openConfirm}
        title="Confirm"
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleSubmit}
        textButtonAgree="YES"
        textButtonDisagree="NO"
      >
        Are you sure?
      </ConfirmationDialog>
    </>
  );
};

export default ConfirmSendEmailAndNotifications;
