import { createRef } from "react";
import { euro } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  MenuList,
  useTheme,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Edit, { IRef as IRefEdit } from "@/components/PaymentsIn/Edit";
import InvoicePaymentInDialog, {
  IRef as IRefInvoicePaymentInDialog,
} from "@/components/PaymentsIn/InvoicePaymentInDialog";
import CreateInvoicePaymentInDialog, {
  IRef as IRefCreateInvoicePaymentInDialog,
} from "@/components/PaymentsIn/CreateInvoicePaymentInDialog";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import editIcon from "@/assets/icons/edit.svg";
import mailIcon from "@/assets/icons/mail.svg";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import CloseIcon from "@mui/icons-material/Close";
import refundIcon from "@/assets/icons/refund.svg";
import paymentIcon from "@/assets/icons/payment.svg";
import DownloadIcon from "@/assets/icons/download.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useProductApi from "@/api/useProductApi";
import usePagination from "@/hooks/usePagination";
import useDownloadPdf from "@/components/useDownloadPdf";
import usePaymentsInApi from "@/api/usePaymentsInApi";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CustomPagination from "@/components/_Common/CustomPagination";
import CustomToolbarPaymentsIn from "@/components/PaymentsIn/CustomToolbarPaymentsIn";
import FlagRender from "@/components/FlagRender";

const PaymentsIn = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { data: products } = useProductApi().callGetProducts();
  const { keyCache, callGetInvoices } = usePaymentsInApi();
  const { userAdmin, copyToClipboard } = useAuth();

  const refEdit = createRef<IRefEdit>();
  const refInvoicePaymentInDialog = createRef<IRefInvoicePaymentInDialog>();
  const refCreateInvoicePaymentInDialog =
    createRef<IRefCreateInvoicePaymentInDialog>();

  const [filters, setFilters] = useSearchParams();
  const { page, setPage } = usePagination({ setFilters, filters });
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });
  const filterData = Object.fromEntries(filters.entries());

  const sorts = filterData?.sorts;
  delete filterData?.sorts;
  delete filterData?.page;

  const { data: invoices, isLoading } = callGetInvoices(
    {
      page,
      sorts,
      filter: { ...filterData },
      per_page: perPage,
    },
    perPage > 0
  );

  const invoicesData = invoices?.data || [];

  const pagination = {
    total: invoices?.total ? invoices?.total : 1,
    last_page: invoices?.last_page ? invoices?.last_page : 1,
    current_page: invoices?.current_page ? invoices?.current_page : 1,
  };

  const handleDownloadPdfInvoice = async (row: any) => {
    const res = await useAPI("/v1/users/download/invoice", {
      token: row.token_user,
      idInvoice: row.id,
    });

    const nameFile = `${row?.intestatario} - ${row?.type} - ${dayjs(
      row?.data
    ).format("DD-MM-YYYY")}`;

    useDownloadPdf(res, nameFile);
  };

  const columns: GridColDef[] = [
    {
      field: "lang",
      headerName: "",
      align: "center",
      maxWidth: 75,
      renderCell: ({ row }) => (
        <Box className="flex items-center">
          <FlagRender
            flag={
              row?.country_code == "de"
                ? row?.phone?.includes("+41")
                  ? "ch"
                  : "de"
                : row?.country_code
            }
          />
        </Box>
      ),
    },
    {
      field: "data",
      headerName: "Date",
      width: 115,
      valueGetter: ({ row }) => dayjs(row?.data).format("YYYY-MM-DD"),
    },
    {
      field: "intestatario",
      headerName: "Holder",
      minWidth: 180,
      flex: 0.5,
      renderCell: ({ row }) => <MyTooltip>{row.intestatario}</MyTooltip>,
    },
    {
      field: "pagamenti.amount",
      headerName: "Amount",
      width: 120,
      editable: false,
      renderCell: ({ row }) =>
        row?.amount ? (
          <Typography color="primary">{euro(row?.amount)}</Typography>
        ) : (
          "-"
        ),
    },
    {
      field: "paid_for",
      headerName: "Paid for",
      minWidth: 400,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.paid_for}</MyTooltip>,
    },
    {
      field: "owned_by",
      headerName: "Owned by",
      minWidth: 180,
      flex: 0.5,
      renderCell: ({ row }) => <MyTooltip>{row?.owned_by}</MyTooltip>,
    },
    {
      field: "type",
      headerName: "Type",
      width: 120,
    },
    {
      field: "invoice_or_credit_note",
      headerName: "invoice/c. note",
      valueGetter: ({ row }) => row.amount > 0 ? "invoice" : "credit note",
    },
    {
      field: "link",
      headerName: "",
      width: 70,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <EduBadge
          color="primary"
          showZero={false}
          badgeContent={row.invoice_path ? "" : null}
        >
          <Dropdown text={<MoreVertIcon />}>
            <MenuList>
              <MenuItem onClick={() => copyToClipboard(row.email)}>
                <ListItemIcon>
                  <img
                    src={mailIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Copy email</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() =>
                  refInvoicePaymentInDialog.current?.open(row, "payment")
                }
              >
                <ListItemIcon>
                  <img
                    src={paymentIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Update invoice type</ListItemText>
              </MenuItem>

              {!Boolean(Number(row?.is_refunded)) && (
                <MenuItem
                  onClick={() =>
                    refInvoicePaymentInDialog.current?.open(row, "refund")
                  }
                >
                  <ListItemIcon>
                    <img
                      src={refundIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {/* He was asked by Marco for new Refund. */}
                    {!row.refunded_date ? "Refund" : "Again Refund"}
                  </ListItemText>
                </MenuItem>
              )}

              <MenuItem onClick={() => refEdit.current?.open(row)}>
                <ListItemIcon>
                  <img
                    src={editIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>

              {row.invoice_path ? (
                <MenuItem
                  title="View Invoice"
                  onClick={() => {
                    const iframe = document.getElementById("popFrame");
                    const frameContent =
                      document.getElementById("frameContent");

                    iframe?.classList.toggle("fixed");
                    iframe?.classList.toggle("hidden");
                    iframe?.classList.toggle("pointer-events-none");
                    frameContent?.setAttribute(
                      "src",
                      row.invoice_path.replace("download&confirm", "view")
                    );
                  }}
                >
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                  <ListItemText>View invoice</ListItemText>
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleDownloadPdfInvoice(row)}>
                  <ListItemIcon>
                    <img
                      src={DownloadIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Download</ListItemText>
                </MenuItem>
              )}
            </MenuList>
          </Dropdown>
        </EduBadge>
      ),
    },
  ];

  return (
    <BasePage
      title={
        userAdmin.user.admin
          ? "All incoming payments"
          : "All incoming payments in the last 2 weeks"
      }
    >
      <DataGrid
        autoHeight
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={invoicesData}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={(row) => row?.id + Math.random()}
        sortModel={sortModel}
        components={{
          Toolbar: CustomToolbarPaymentsIn,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        componentsProps={{
          toolbar: {
            filters: filterData,
            setPage: setPage,
            setFilters: setFilters,
            onClickAdd: () => {
              refCreateInvoicePaymentInDialog?.current?.open({
                products: products.products,
              });
            },
          },
          pagination: { page, setPage, pagination },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <Edit
        ref={refEdit}
        onInvalidateQuery={() => queryClient.invalidateQueries(keyCache)}
      />
      <InvoicePaymentInDialog ref={refInvoicePaymentInDialog} />
      <CreateInvoicePaymentInDialog ref={refCreateInvoicePaymentInDialog} />

      <Box
        id="popFrame"
        className="hidden inset-0 flex justify-center items-center pointer-events-none z-10"
      >
        <button
          className="absolute top-16 z-10 p-2 rounded-full"
          onClick={() => {
            const iframe = document.getElementById("popFrame");
            const frameContent = document.getElementById("frameContent");

            iframe?.classList.toggle("fixed");
            iframe?.classList.toggle("hidden");
            iframe?.classList.toggle("pointer-events-none");
            frameContent?.setAttribute("src", "");
          }}
        >
          <CloseIcon color="primary" />
        </button>

        <iframe
          id="frameContent"
          width="1024"
          height="700"
        />
      </Box>
    </BasePage>
  );
};

export default PaymentsIn;
