import { createRef, useEffect, useMemo, useRef, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { getUserAccessToken } from "@/api";
import { INDataAvailabilities } from "../Tutors/interfaces";
import { ContentCopy, MoreVert } from "@mui/icons-material";
import { ONBOARDING_VALUES_NEW_USERS } from "../UsersList/component/IndexUsersList";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  useTheme,
  TextField,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DataAvailabilities, {
  IRef as IRefDataAvailabilities,
} from "../Availabilities/dialog/DataAvailabilities";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import FlagRender from "@/components/FlagRender";
import useExportCSV from "@/components/useExportCSV";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import impersonateIcon from "@/assets/icons/impersonate.svg";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const HTTP_BASE = "/v1/users/onboarding";
const VITE_ROUTE_ACADEMY_USER = import.meta.env.VITE_ROUTE_ACADEMY_USER;
const HTTP_ROUTE_AVAILABILITIES_SHOW = "/v1/availabilities/show";

export default () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { copyToClipboard, snackHandler, userAdmin } = useAuth();

  const customPaginationRef = useRef();

  const refDataAvailabilities = createRef<IRefDataAvailabilities>();

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [productsData, setProductsData] = useState<Array<any>>([]);

  const [filters, setFilters] = useSearchParams({});
  const filterData = Object.fromEntries(filters.entries());
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  const KEY_QUERY = ["onboarding", filterData];

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () => useAPI(HTTP_BASE, { filters: filterData }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const handleUpdate = async (token: string, values: object) => {
    const res = await useAPI(
      `/v1/listWithSecondPayment/${token}/update`,
      values
    );

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed update!", "error");
      return false;
    }

    snackHandler("Success update!");
    return true;
  };

  const handleChangeStatusOnboarding = async (e: any, row: any) => {
    let s: any = row?.settings ?? {};

    if (!s?.onboarding) {
      s.onboarding = { status: e.target.value };
    } else {
      s.onboarding.status = e.target.value;
    }

    const res = await handleUpdate(row?.token, { settings: s });

    if (res) {
      setRows((p) => {
        const t = [...p];
        const index = t.findIndex((r) => r.token == row?.token);
        t[index].settings = s;
        return t;
      });
    }
  };

  const handleDownloadCSV = async () => {
    const res = await useAPI(HTTP_BASE, { export: true, filters: filterData });

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed get download!", "error");
      return;
    }

    useExportCSV(res, "Onboarding");
    snackHandler("Success download");
  };

  const handleGetAccessToken = async (token: string) => {
    const rs = await getUserAccessToken(userAdmin.token, token);
    window.open(
      `${VITE_ROUTE_ACADEMY_USER}/impersonate/${rs.access_token}`,
      "_blank"
    );
  };

  const handleGetAvailabilities = async (token: string, name: string) => {
    const keyQueryAvailability = [...KEY_QUERY, "availability", token];

    const dataAvai = {
      name,
      token,
      keyQuery: keyQueryAvailability,
      availabilities: [],
    };

    refDataAvailabilities?.current?.open(dataAvai);

    let res: any = queryClient.getQueryData(keyQueryAvailability);

    if (!res) {
      res = await useAPI(HTTP_ROUTE_AVAILABILITIES_SHOW, { token });
    }

    if (!Boolean(res)) {
      snackHandler(res?.message || "Failed get availabilities!", "error");
      return;
    }

    refDataAvailabilities?.current?.open({
      ...dataAvai,
      availabilities: res?.rows?.array?.secondaStringa,
    });
    queryClient.setQueryData(keyQueryAvailability, res);
  };

  const columns: GridColDef[] = [
    {
      field: "lang",
      headerName: "",
      align: "center",
      maxWidth: 75,
      renderCell: ({ row }) => (
        <Box className="flex items-center">
          {Boolean(row?.info?.activeCampaign?.lists?.length) && <Box>AC</Box>}
          <FlagRender flag={row?.lang} />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.full_name}</MyTooltip>,
    },
    {
      field: "start_month",
      headerName: "Start month",
      minWidth: 140,
      flex: 0.5,
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 220,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.product}</MyTooltip>,
    },
    {
      field: "english_level",
      headerName: "English level",
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: "ltv",
      headerName: "LTV",
      width: 100,
    },
    {
      field: "status_onboarding",
      headerName: "Status onboarding",
      minWidth: 180,
      flex: 0.5,
      editable: false,
      hideable: false,
      sortable: false,
      filterable: false,
      disableExport: true,
      valueGetter: ({ row }) => row?.settings?.onboarding?.status ?? "NO",
      renderCell: ({ row }) => (
        <TextField
          select
          fullWidth
          value={row?.settings?.onboarding?.status ?? "NO"}
          onChange={(e: any) => handleChangeStatusOnboarding(e, row)}
        >
          {ONBOARDING_VALUES_NEW_USERS?.map((o, i) => (
            <MenuItem
              key={o + i}
              value={o}
            >
              {o}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          {Boolean(row?.link_onboarding) && (
            <MenuItem onClick={() => copyToClipboard(row?.link_onboarding)}>
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy link onboarding</ListItemText>
            </MenuItem>
          )}

          {row?.created_account_value != "SI" && (
            <MenuItem
              onClick={() => copyToClipboard(row?.created_account_value)}
            >
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy link register</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            title="Impersonate Academy User"
            onClick={() => handleGetAccessToken(row?.token)}
          >
            <ListItemIcon>
              <img
                src={impersonateIcon}
                className="icon"
              />
            </ListItemIcon>
            <ListItemText>Impersonate</ListItemText>
          </MenuItem>

          <MenuItem
            color="primary"
            title="Availabilities"
            onClick={() => handleGetAvailabilities(row?.token, row?.full_name)}
          >
            <ListItemIcon>
              <SpellcheckIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Availability</ListItemText>
          </MenuItem>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (isLoading) return;
    setRows(dataAPI?.rows || []);
    setProductsData(dataAPI?.products || []);
  }, [dataAPI, isLoading]);

  return (
    <BasePage title="Onboarding">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        sortingMode="server"
        components={{
          Toolbar,
          Pagination: () => (
            <CustomPaginationClient customPaginationRef={customPaginationRef} />
          ),
        }}
        componentsProps={{
          toolbar: {
            setFilters,
            productsData,
            customPaginationRef,
            filters: filterData,
            keyQuery: KEY_QUERY,
            onDownloadSCV: handleDownloadCSV,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <DataAvailabilities
        hideButtons
        ref={refDataAvailabilities}
      />
    </BasePage>
  );
};
