import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { queryClient } from "@/main";
import { TYPE, SUBTYPE } from "../Events";
import { useGridApiContext } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Select,
  MenuItem,
  useTheme,
  Checkbox,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import useAuth from "@/hooks/useAuth";
import addIcon from "@/assets/icons/add.svg";
import nextIcon from "@/assets/icons/next.svg";
import backIcon from "@/assets/icons/back.svg";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import useDebounce from "@/hooks/useDebounce";
import refreshIcon from "@/assets/icons/refresh.svg";
import materialIcon from "@/assets/icons/material.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import DownloadEvents from "../component/DownloadEvents";

export default ({
  mode,
  weekEnd,
  filters,
  setMode,
  isMobile,
  refEvent,
  weekStart,
  setFilters,
  listTutors,
  EVENTS_KEY,
  onClearSelect,
  handleNextWeek,
  handlePrevWeek,
  selectedEvents,
  refMaterialDialog,
  refImportBulkEvent,
  customPaginationRef,
  refEditEventAssociations,
}) => {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const { isMember } = useAuth();

  const [search, setSearch] = useState<string | null>(filters?.search ?? null);

  const debouncedSearch = useDebounce(search, 500);
  const subtypesArray = filters.subtype ? filters.subtype.split(",") : [];

  const handleChange = ({ target: { name, value } }) => {
    const newFilters = { ...filters };

    if (name === "subtype" && Array.isArray(value)) {
      newFilters[name] = value.join(",");
    } else {
      if (value) {
        newFilters[name] = value;
      } else {
        delete newFilters[name];
      }
    }

    setFilters(newFilters);
  };

  const typesMenu = useMemo(
    () =>
      TYPE.map((e: string, i: number) => (
        <MenuItem
          key={i}
          value={e}
        >
          {e}
        </MenuItem>
      )),
    []
  );

  const subTypesMenu = useMemo(
    () =>
      SUBTYPE.map((e, i) => (
        <MenuItem
          key={i}
          value={e}
        >
          <Checkbox checked={subtypesArray.includes(e)} />
          {e}
        </MenuItem>
      )),
    [subtypesArray]
  );

  const tutorsMenu = useMemo(
    () =>
      listTutors.map((e: any, i: number) => (
        <MenuItem
          key={e.key + i}
          value={e.key}
        >
          {e.value}
        </MenuItem>
      )),
    [listTutors]
  );

  const technicalProblemMenu = useMemo(
    () =>
      [
        {
          key: 0,
          value: "No",
        },
        {
          key: 1,
          value: "Yes",
        },
      ].map((e: any, i: number) => (
        <MenuItem
          key={e.key + i}
          value={e.key}
        >
          {e.value}
        </MenuItem>
      )),
    []
  );

  const liveFilter = useMemo(
    () =>
      ["Happening now", "Happening next hour"].map((e: string, i: number) => (
        <MenuItem
          key={e + i}
          value={e}
        >
          {e}
        </MenuItem>
      )),
    []
  );

  useEffect(() => {
    if (setFilters)
      setFilters(
        (p: any) => {
          if (debouncedSearch) {
            p.set("search", debouncedSearch);
          } else {
            p.delete("search");
          }
          return p;
        },
        { replace: true }
      );
  }, [debouncedSearch]);

  let fileName = `events ${format(weekStart, "dd_MM")}-${format(
    weekEnd,
    "dd_MM"
  )}`;

  for (const property in filters) {
    const value = filters[property];

    if (property === "tutor") {
      const tutor: { key: string; value: string } = listTutors.find(
        (t: any) => t.key === value
      );

      if (tutor) {
        fileName += ` ${property}_${tutor?.value?.replaceAll(" ", "_")}`;
      }
    } else {
      fileName += ` ${property}_${value}`;
    }
  }

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => {
            setFilters({});
            apiRef.current.setQuickFilterValues([""]);
            queryClient.invalidateQueries(EVENTS_KEY);
            customPaginationRef?.current?.resetPage();
          }}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        {!mode && (
          <>
            <Dropdown
              text="Add"
              icon={addIcon}
            >
              <Box className="flex flex-col gap-5 p-5">
                <Button
                  variant="outlined"
                  onClick={() => refEvent?.current?.open(null)}
                  className="!justify-start"
                >
                  <img
                    src={addIcon}
                    className="icon"
                  />
                  Add manually
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => refImportBulkEvent?.current?.open()}
                  className="!justify-start"
                >
                  <img
                    src={addIcon}
                    className="icon"
                  />
                  Add in bulk
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => refMaterialDialog?.current?.open()}
                  className="!justify-start"
                >
                  <img
                    src={addIcon}
                    className="icon"
                  />
                  Load material
                </Button>
              </Box>
            </Dropdown>

            <Button
              variant="outlined"
              onClick={() => setMode("edit_exercise_material")}
            >
              <img
                src={materialIcon}
                className="icon"
              />
              Edit exercises/materials/students
            </Button>
          </>
        )}

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(
            Object.keys(filters).filter(
              (f) => !["sorts", "page", "search"].includes(f)
            ).length
          )}
        >
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            <Box
              sx={{ borderColor: theme.palette.secondary.main }}
              className="flex items-center justify-between rounded border p-2"
            >
              <Button
                sx={{ minWidth: 0 }}
                title="Prev dates"
                onClick={handlePrevWeek}
              >
                <img
                  src={backIcon}
                  className="icon no-margin"
                />
              </Button>

              <Typography
                color={"primary"}
                className="flex items-center justify-center gap-1 flex-wrap"
              >
                <span>{format(weekStart, "dd/MM")}</span>
                <span>{!isMobile ? " ~ " : " "}</span>
                <span>{format(weekEnd, "dd/MM")}</span>
              </Typography>

              <Button
                sx={{ minWidth: 0 }}
                title="Next dates"
                onClick={handleNextWeek}
              >
                <img
                  src={nextIcon}
                  className="icon no-margin"
                />
              </Button>
            </Box>

            <TextField
              select
              fullWidth
              name="lang"
              label="Lang"
              value={filters?.lang || ""}
              onChange={handleChange}
            >
              {LANGUAGES?.map(({ iso, name }) => (
                <MenuItem
                  key={iso}
                  value={iso}
                >
                  {name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              fullWidth
              name="type"
              label="Type"
              value={filters?.type || ""}
              onChange={handleChange}
            >
              {typesMenu}
            </TextField>

            <FormControl>
              <InputLabel id="demo-simple-select-label">Subtype</InputLabel>

              <Select
                multiple
                id="subtype"
                name="subtype"
                label="Subtype"
                value={subtypesArray}
                labelId="subtype-label"
                onChange={handleChange}
              >
                {subTypesMenu}
              </Select>
            </FormControl>

            <TextField
              select
              fullWidth
              name="tutor"
              label="Tutor"
              value={filters?.tutor || ""}
              onChange={handleChange}
            >
              {tutorsMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="technical_problem"
              label="Technical problem"
              value={filters?.technical_problem || ""}
              onChange={handleChange}
            >
              {technicalProblemMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="live_filter"
              label="Live filter"
              value={filters?.live_filter || ""}
              onChange={handleChange}
            >
              {liveFilter}
            </TextField>
          </Box>
        </Dropdown>

        {mode && (
          <div className="flex gap-4">
            <Button
              variant="outlined"
              onClick={() => {
                setMode("");
                onClearSelect();
              }}
            >
              <img
                src={backIcon}
                className="icon"
              />
              Back
            </Button>

            {mode &&
              [true, false].map((isRemove: boolean) => (
                <Button
                  variant="contained"
                  onClick={() =>
                    refEditEventAssociations?.current?.open({ isRemove })
                  }
                  disabled={selectedEvents.length === 0}
                >
                  {isRemove ? "- Remove" : "+ Add"}
                </Button>
              ))}
          </div>
        )}

        {!mode && !isMember && (
          <DownloadEvents
            removeDate
            fileName={fileName}
          />
        )}
      </FiltersBar>

      <SearchToolbar {...{ filters, setFilters }} />
    </Box>
  );
};
