import { createRef, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { MoreVert, Summarize } from "@mui/icons-material";
import {
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  PopoverProduct,
  IAnchorElProduct,
  RenderCellProduct,
  valueGetterProduct,
  defaultAnchorElProduct,
} from "@/components/User/PopoverProduct";
import Edit, { IRefEdit } from "./Edit";
import Note, { IRefNote } from "./Note";
import Classreport, { IRefClassreport } from "./Classreport";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import useDGSort from "@/hooks/useDataGridSort";
import MyTooltip from "@/components/MyTooltip";
import commentIcon from "@/assets/icons/comments.svg";
import useExportCSV from "@/components/useExportCSV";
import useProductApi from "@/api/useProductApi";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LevelChangeRequestPopOver from "./LevelChangeRequestPopOver";
import LevelChangeHistoryModal from "./LevelChangeHistory";
import user from "pusher-js/types/src/core/user";
import {
  LevelChangeHistoryModalRef,
  LevelRecommendationHistoryModalRef,
} from "./interfaces";
import LevelRecommendationHistoryModal from "./LevelRecommendationHistory";

const LevelChange = () => {
  const theme = useTheme();
  const { perPage } = useScreen();

  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );

  const refEdit = createRef<IRefEdit>();
  const noteRef = createRef<IRefNote>();
  const modalRef = useRef<LevelChangeHistoryModalRef>(null);
  const recommendationModalRef =
    useRef<LevelRecommendationHistoryModalRef>(null);
  const refClassreport = createRef<IRefClassreport>();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { sortModel, onSortModelChange } = useDGSort({ filters, setFilters });

  const { data: productsAPI } = useProductApi().callGetProducts();

  const KEY_QUERY = ["level-change", filterData];

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () => useAPI("/v1/users/level-change", { filters: filterData }),
    { keepPreviousData: true, refetchOnWindowFocus: true, enabled: perPage > 0 }
  );

  const startMonths = useMemo(() => {
    const months: Array<string> = [];
    const now = dayjs().subtract(6, "M");
    for (let i = 0; i < 18; i++) {
      months.push(dayjs(now).add(i, "M").format("MMMM YYYY"));
    }
    return months;
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setFilters(
      (p) => {
        if (value) p.set(name, value);
        else p.delete(name);
        return p;
      },
      { replace: true }
    );
  };

  const handleExport = async () => {
    const data = await useAPI("/v1/users/level-change", {
      export: true,
      filters: filterData,
    });

    const product: any = Boolean(filterData?.product && productsAPI?.products)
      ? productsAPI?.products?.find((p: any) => p?.token == filterData?.product)
      : null;

    useExportCSV(
      data,
      "Level change" +
        (filterData?.english_level ? " - " + filterData?.english_level : "") +
        (product
          ? " - " + (product?.course_info?.course_name || product?.product_tag)
          : "")
    );
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.fullName}</MyTooltip>,
    },
    {
      field: "start_month",
      headerName: "Start month",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => valueGetterProduct(row),
      renderCell: ({ row }) => (
        <RenderCellProduct
          {...{
            row,
            setAnchorElProduct,
          }}
        />
      ),
    },
    {
      field: "english_level",
      headerName: "English level",
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: "outstanding_level_change",
      headerName: "OUTSTANDINGLEVELCHANGE",
      minWidth: 220,
      flex: 0.5,
      renderCell: (params) => {
        const { value, row } = params;
        const reports = row.filtered_reports || [];

        const increaseCount = reports?.filter(
          (report: { level_change_direction: string }) =>
            report.level_change_direction === "increase"
        ).length;

        const decreaseCount = reports?.filter(
          (report: { level_change_direction: string }) =>
            report.level_change_direction === "decrease"
        ).length;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <LevelChangeRequestPopOver reports={reports} />
            {increaseCount > 0 && (
              <>
                <ArrowDropUpIcon style={{ color: "green", marginLeft: 4 }} />
                <Typography style={{ color: "green" }}>
                  {increaseCount}
                </Typography>
              </>
            )}
            {decreaseCount > 0 && (
              <>
                <ArrowDropDownIcon style={{ color: "red", marginLeft: 4 }} />
                <Typography style={{ color: "red" }}>
                  {decreaseCount}
                </Typography>
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              title="Info"
              color="primary"
              onClick={() => refEdit?.current?.open(row)}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>CHange User Level</ListItemText>
            </MenuItem>
            {/* change class report to level change history */}
            <MenuItem
              title="Info"
              color="primary"
              onClick={() => modalRef.current?.openModal(row)}
            >
              <ListItemIcon>
                <Summarize fontSize="small" />
              </ListItemIcon>
              <ListItemText>Level Change History</ListItemText>
            </MenuItem>
            <MenuItem
              title="Info"
              color="primary"
              onClick={() =>
                recommendationModalRef.current?.openModal(row, KEY_QUERY)
              }
            >
              <ListItemIcon>
                <Summarize fontSize="small" />
              </ListItemIcon>
              <ListItemText>Level Recommendations History</ListItemText>
            </MenuItem>

            <MenuItem
              title="Info"
              color="primary"
              onClick={() => noteRef?.current?.open(row)}
            >
              <ListItemIcon className="text-center">
                <img
                  src={commentIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>
                {Boolean(row?.note?.length) && row?.note?.length} Comment
                {!Boolean(row?.note?.length < 2) && "s"}
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <BasePage title="Level change">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={dataAPI?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        components={{
          Toolbar: Toolbar,
          Pagination: CustomPaginationClient,
        }}
        componentsProps={{
          toolbar: {
            filters,
            keyQuery: KEY_QUERY,
            onChange: handleChange,
            onExport: handleExport,
            setFilters,
            filterData,
            startMonths,
            productsAPI,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />

      <Edit
        ref={refEdit}
        keyQuery={KEY_QUERY}
      />

      <Classreport ref={refClassreport} />

      <Note
        ref={noteRef}
        keyQuery={KEY_QUERY}
      />

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}

      <LevelChangeHistoryModal ref={modalRef} />
      <LevelRecommendationHistoryModal ref={recommendationModalRef} />
    </BasePage>
  );
};

export default LevelChange;
