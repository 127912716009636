import { memo } from "react";
import { Box, Button, FormControl, InputLabel, Select } from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import FiltersBar from "@/components/_Common/FiltersBar";
import downloadIcon from "@/assets/icons/download.svg";
import useAuth from '@/hooks/useAuth';

const UsersToolbar = ({
  filters,
  handleChange,
  englishMenu,
  startMonthMenu,
  // productMenu,
  setPage,
  setFilters,
  downloadUpsolds,
}) => {
  const { isMember } = useAuth();

  return (
    <Box
      id="filter_bar"
      className="flex gap-2 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => {
            setFilters({});
          }}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>
        <Dropdown
          active={Boolean(
            Object.keys(filters).filter((f) =>
              ["english_level", "ultimo_mese_inizio", "path"].includes(f)
            ).length
          )}
          text="Filter"
          icon={filterIcon}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  English level
                </InputLabel>

                <Select
                  name="english_level"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="English level"
                  value={filters?.english_level ?? ""}
                  onChange={handleChange}
                >
                  {englishMenu}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="demo-simple-select-label2">
                  New start month
                </InputLabel>

                <Select
                  name="ultimo_mese_inizio"
                  labelId="demo-simple-select-label2"
                  id="demo-simple-select2"
                  label="New start month"
                  value={filters?.ultimo_mese_inizio ?? ""}
                  onChange={handleChange}
                >
                  {startMonthMenu}
                </Select>
              </FormControl>

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label3">
                  Current path
                </InputLabel>

                <Select
                  name="path"
                  labelId="demo-simple-select-label3"
                  id="demo-simple-select3"
                  label="Current path"
                  value={filters?.path ?? ""}
                  onChange={handleChange}
                >
                  {productMenu}
                </Select>
              </FormControl> */}
            </Box>
          }
        />

        {!isMember && (
          <Button
            variant={"outlined"}
            onClick={downloadUpsolds}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        setPage={setPage}
        setFilters={setFilters}
        filters={filters}
      />
    </Box>
  );
};

export default memo(UsersToolbar);
