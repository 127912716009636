import { useState } from "react";
import { IFormQuestion, IQuestion } from "./FormQuestion/QuestionInterface";

const useFormQuestion = () => {
  const [formData, setFormData] = useState<IFormQuestion | null>(null);
  const [content, setContent] = useState<IQuestion[]>([]);

  const firstQuestions: IQuestion[] = [
    {
      question: "What is your name and family name?",
      elements: [
        {
          type: "text",
          label: "First name",
          value: "",
        },
        {
          type: "text",
          label: "Last name",
          value: "",
        },
      ],
    },
    {
      question: "What is your email address?",
      elements: [
        {
          type: "email",
          label: "Email",
          value: "",
        },
      ],
    },
    {
      question: "What is your phone number?",
      elements: [
        {
          type: "tel",
          label: "Phone number",
          value: "",
        },
      ],
    },
    {
      question: "What is your date of birth?",
      elements: [
        {
          type: "date",
          label: "Birthday",
          value: "",
        },
      ],
    },
    {
      question: "Which language do you speak?",
      elements: [
        {
          type: "check-lang",
          label: "Italian",
          value: "",
        },
        {
          type: "check-lang",
          label: "German",
          value: "",
        },
        {
          type: "check-lang",
          label: "Portuguese",
          value: "",
        },
        {
          type: "check-lang",
          label: "Spanish",
          value: "",
        },
        {
          type: "check-lang",
          label: "French",
          value: "",
        },
        {
          type: "check-lang",
          label: "English",
          value: "",
        },
        {
          type: "check-lang",
          label: "Arabic",
          value: "",
        },
        {
          type: "check-lang",
          label: "Chinese",
          value: "",
        },
        {
          type: "check-lang",
          label: "Japanese",
          value: "",
        },
      ],
    },
  ];

  const addQuestion = () => {
    const newQuestion: IQuestion = {
      question: "",
      elements: [
        {
          type: "text",
          label: "",
          value: "",
          correctAnswer: "",
          trueOption: false,
        },
      ],
    };

    setContent([...content, newQuestion]);
  };

  const removeQuestionByIndex = (deleteIndex) => {
    const newContent = content.filter((_, index) => {
      return deleteIndex === index ? false : true;
    });

    setContent([...newContent]);
  };

  const changeQuestionByIndex = (index: number, value: IQuestion) => {
    if (!content[index]) return;

    setContent((prev) => {
      let newData = [...prev];
      newData[index] = value;
      return newData;
    });
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((p: any) => ({ ...p, [name]: value }));
  };

  const handleChangeByField = (field, newValue) => {
    setFormData((p: any) => ({ ...p, [field]: newValue }));
  };

  return {
    formData,
    setFormData,
    content,
    setContent,
    addQuestion,
    changeQuestionByIndex,
    removeQuestionByIndex,
    handleChange,
    firstQuestions,
    handleChangeByField,
  };
};

export default useFormQuestion;
