import { $generateHtmlFromNodes } from "@lexical/html";
import { createEditor } from "lexical";
import { sharedNodes } from "./editorConfig";

const lexicalToHTML = (content) => {
  try {
    const editor = createEditor({
      namespace: "PreviewEditor",
      nodes: sharedNodes,
    });
    const parsedEditorState = editor.parseEditorState(content);
    editor.setEditorState(parsedEditorState);
    let htmlContent = "";
    editor.update(() => {
      htmlContent = $generateHtmlFromNodes(editor);
    });

    return htmlContent;
  } catch (error) {
    console.error("Error converting Lexical JSON to HTML:", error);
    return "<p>Error rendering content</p>";
  }
};

const Preview = ({ body }) => {
  const htmlContent = lexicalToHTML(body);

  return (
    <div
      className="editor-preview mx-2"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default Preview;
