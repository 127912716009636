import BaseAxios from "axios";
const BASE_URL = import.meta.env.VITE_BASE_URL; // api/admin/

class Request {
  axios;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
  }

  async call(config, token = null) {
    let headers = {
      Accept: "application/json",
      ...config.headers,
    };
    if (!(config.data instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    if (token) headers["Authorization"] = `Bearer ${token}`;

    delete config.headers;

    const result = this.axios({
      baseURL: BASE_URL,
      headers,
      ...config,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          throw "Unauthorized";
        }
        throw error;
      });

    return result;
  }

  async api(config, token = null) {
    let headers = {
      Accept: "application/json",
      ...config.headers,
    };

    if (!(config.data instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    if (token) headers["Authorization"] = `Bearer ${token}`;

    delete config.headers;

    try {
      const response = await this.axios({
        baseURL: BASE_URL,
        headers,
        ...config,
      });

      return response.data;
    } catch (error) {
      if (error?.response?.status === 422) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async download(config, token = null, fileName) {
    let headers = {
      "Content-Type": "application/json",
      responseType: "blob",
      ...config.headers,
    };

    if (token) headers["Authorization"] = `Bearer ${token}`;

    delete config.headers;

    const response = await this.axios.request({
      baseURL: BASE_URL,
      headers,
      ...config,
    });

    let blob = new Blob([response.data], {
      type: response.headers["Content-type"],
    });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    return true;
  }
}

export default new Request();
