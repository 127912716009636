import { useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Toolbar from "../custom/EventStatisticsToolbar";
import useScreen from "@/hooks/useScreen";
import Title from "@/components/_Common/Page/Title";
import { getTableStyling } from "@/providers/Colors";
import { EventStatisticsItem, EventStatisticsQueryParam } from "@/interfaces";
import { useEventsStatistics } from "@/api/events";
import Pagination from "@/pages/Events/component/v2/Pagination";
import { uuid } from "@/utils";

const Info = () => {
  const {  screen, perPage } = useScreen();
  const theme = useTheme();

  const [eventQuery, setEventQuery] = useState<EventStatisticsQueryParam>({
    page: 1,
    per_page: perPage
  });

  const { events, paginatorInfo, loading: isLoading
  } = useEventsStatistics(eventQuery);


  const columns: GridColDef<EventStatisticsItem>[] = useMemo(
    () => [
      {
        field: "combination",
        headerName: "Combination",
        minWidth: 200,
        flex: 1,
        valueGetter: ({ row }) => row?.combination?.trim() || "-",
      },
      {
        field: "presences",
        headerName: "Presences",
        minWidth: 200,
        flex: 1,
        valueGetter: ({ row }) => row?.presences || "-",
      },
      {
        field: "participants",
        headerName: "Partecipants",
        minWidth: 120,
        flex: 1,
        valueGetter: ({ row }) => row?.participants || "-",
      },
      {
        field: "percentage",
        headerName: "Percentual",
        minWidth: 120,
        flex: 1,
        valueGetter: ({ row }) =>
          !isNaN(parseFloat(row?.percentage))
            ? parseFloat(row?.percentage)?.toFixed(2) + "%"
            : "0.00%",
      },
    ],
    [screen]
  );

  const handleSetEventQuery = (key: string, value: string | string[] | undefined | number) => {
    setEventQuery((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <Box className="mt-8 mx-5 md:mx-10">
      <Title>Events Statistics</Title>

      <DataGrid
        disableColumnMenu
        checkboxSelection
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={events.map((event) => ({ ...event, id: uuid() })) || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[perPage]}
        autoHeight={true}
        components={{ Toolbar, Pagination }}
        experimentalFeatures={{ newEditingApi: true }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            handleSetEventQuery,
            eventQuery,
            fileName: "Info events",
          },
          pagination: {
            paginatorInfo,
            setPage: (page: number) => handleSetEventQuery("page", page)
          },
        }}
      />
    </Box>
  );
};

export default Info;
