import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

type SelectFileProps = {
  id: string | "";
  files: File[];
  buttonName: string;
  onFilesChanged: (files: File[]) => void;
  title?: string;
};

const SelectInputFile: React.FC<SelectFileProps> = ({
  id,
  files,
  buttonName,
  onFilesChanged,
  title
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>(files);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedFiles(files);
  }, [files]);

  /**
   * Handles the chnage event of the input of type file
   */
  const handleFileChange = () => {
    if (fileInputRef.current?.files) {
      const newFiles = Array.from(fileInputRef.current.files);
      const updatedFiles = [...selectedFiles, ...newFiles];
      setSelectedFiles(updatedFiles);
      onFilesChanged(updatedFiles);
      fileInputRef.current.value = "";
    }
  };

  /**
   * Removes a file from a list or array of selelcted files
   * @param fileToRemove the file to remove
   */
  const handleRemoveFile = (fileToRemove: File) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    onFilesChanged(updatedFiles);
  };

  return (
    <div
      style={{
        border: "1px solid #B388FF",
        padding: "10px",
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        alignItems: "center",
        borderRadius: "10px",
      }}
      title={title}
    >
      <input
        multiple
        id={id}
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        accept=".pdf"
        onChange={handleFileChange}
      />

      {/* button to select file(s) */}
      <button
        onClick={() => fileInputRef.current?.click()}
        className="text-[#B388FF] truncate margin-right-10 padding-[5px] "
      >
        {buttonName}
      </button>


      {/* container for displaying selected files */}
      <div style={{ flexWrap: "wrap", gap: "5px", width: "100%" }}>
        {selectedFiles.map((file, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              border: "1px solid #2C203E",
              display: "flex",
              padding: "0px 5px",
              position: "relative",
              alignItems: "center",
              borderRadius: "5px",
              backgroundColor: "#B388FF",
            }}
          >
            <span
              style={{
                cursor: "default",
                display: "inline-block",
                fontSize: "14px",
                maxWidth: "150px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                marginRight: "10px",
                textOverflow: "ellipsis",
              }}
            >
              {`${index + 1}. ${file.name}`}
            </span>

            {/* button to remove a selected file */}
            <Button
              style={{
                color: "#2C203E",
                border: "none",
                cursor: "pointer",
                background: "none",
                marginLeft: "auto",
              }}
              onClick={() => handleRemoveFile(file)}
            >
              <span style={{ fontSize: "20px" }}>&times;</span>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectInputFile;
