import useAuth from "@/hooks/useAuth";
import { useMutation } from "@tanstack/react-query";
import {
  deleteAdmin,
  registerNewAdmin,
  updateAdmin,
  updateAvatarAdmin,
  uploadTeamContractAdmin
} from ".";
import { IFormUser, IParams } from "../components/Team/FormUserDialog";
import { queryClient } from "../main";

const useUserAdminApi = () => {
  const { snackHandler } = useAuth();

  const callDeleteAdmin = useMutation(
    (params: IParams) => deleteAdmin(params),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["team"]);
        snackHandler("Admin deleted!");
      },
      onError: (error) => {
        console.error(error);
        snackHandler("Failed delete admin!", "error");
      },
    }
  );

  const callUpdateAvatarAdmin = useMutation(
    ({ tk, id, formData }: { tk: string; id: string; formData: FormData }) =>
      updateAvatarAdmin({ tk, id, formData }),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(["userAdmin"]);
        queryClient.invalidateQueries(["team"]);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  /**
   * Updates the contract_file_path field with the new contract
   */
  const callUploadTeamContract = useMutation(
    ({ tk, id, formData }: { tk: string; id: string; formData: FormData }) =>
      uploadTeamContractAdmin({ tk, id, formData }),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(["userAdmin"]);
        queryClient.invalidateQueries(["team"]);
        snackHandler("Contract saved");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callUpdateAdmin = useMutation(
    (params: IParams) => updateAdmin(params),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(["team"]);
        snackHandler("Admin updated!");
      },
      onError: (error) => {
        console.error(error);
        snackHandler("Failed update admin!", "error");
      },
    }
  );

  const callRegisterNewAdmin = useMutation(
    (params: IParams) => registerNewAdmin(params),
    {
      onSuccess: async (data: { token: string; user: IFormUser }) => {
        queryClient.invalidateQueries(["team"]);
        snackHandler("Admin created!");
      },
      onError: (error) => {
        console.error(error);
        snackHandler("Failed create admin!", "error");
      },
    }
  );

  return {
    callDeleteAdmin,
    callUpdateAdmin,
    callRegisterNewAdmin,
    callUpdateAvatarAdmin,
    callUploadTeamContract,
  };
};

export default useUserAdminApi;
