import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import useAPI from "@/api/useAPI";
import CustomPagination from "@/components/_Common/CustomPagination";
import BasePage from "@/components/Page/Base";
import { getTableStyling } from "@/providers/Colors";
import gptIcon from "@/assets/chatgpt.svg";
import { Box, IconButton, CircularProgress, Button } from "@mui/material";
import Filter from "@/pages/EventsReport/components/Filter";
import useScreen from "@/hooks/useScreen";
import EventReportDetails from "./components/EventReportDetails";
import DescriptionIcon from "@mui/icons-material/Description";
import { Event } from "./interfaces";
import { useNavigate, useLocation } from "react-router-dom";

const EventsReport: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [searchTerm, setSearchTerm] = useState<string>(
    queryParams.get("search") || ""
  );
  const [sorts, setSorts] = useState<{ [key: string]: "asc" | "desc" }>(
    queryParams.get("sorts") ? JSON.parse(queryParams.get("sorts")!) : {}
  );
  const [page, setPage] = useState<number>(
    parseInt(queryParams.get("page") || "1", 10)
  );
  const [buttonLoading, setButtonLoading] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const theme = useTheme();
  const { perPage } = useScreen();
  const eventDetailsModalRef = useRef<{
    openModal: () => void;
    closeModal: () => void;
  }>(null);

  const updateUrl = useCallback(
    debounce(() => {
      const params = new URLSearchParams();
      if (searchTerm) params.set("search", searchTerm);
      if (page) params.set("page", page.toString());
      if (sorts && Object.keys(sorts).length > 0)
        params.set("sorts", JSON.stringify(sorts));

      navigate(`?${params.toString()}`);
    }, 2000),
    [navigate, searchTerm, page, sorts]
  );
  const fetchTableData = async () => {
    const params = {
      filter: searchTerm,
      sorts,
      page,
      per_page: perPage,
    };
    return useAPI("v1/reports", params, { type: "post" });
  };

  const { data, isLoading } = useQuery(
    ["events-report", { searchTerm, sorts, page, perPage }],
    fetchTableData
  );

  const handleFileButtonClick = async (event: Event) => {
    setButtonLoading((prev) => ({ ...prev, [event.class_id]: true }));
    try {
      const response = await useAPI(
        `/v1/reports/${event.class_id}`,
        {},
        { type: "post" }
      );
      const blob = new Blob([response], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `report_for${event.date}_by_${event.tutors.first_name}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error calling file endpoint:", error);
    } finally {
      setButtonLoading((prev) => ({ ...prev, [event.class_id]: false }));
    }
  };

  const handleAiButtonClick = async (event: Event) => {
    setButtonLoading((prev) => ({ ...prev, [event.class_id]: true }));
    try {
      const response = await useAPI(
        `/v1/reports/run-prompt/${event.class_id}`,
        {},
        { type: "post" }
      );
      const blob = new Blob([response], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `report_for${event.date}_by_${event.tutors.first_name}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error calling AI endpoint:", error);
    } finally {
      setButtonLoading((prev) => ({ ...prev, [event.class_id]: false }));
    }
  };

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    const newSorts: { [key: string]: "asc" | "desc" } = {};
    model.forEach((item) => {
      newSorts[item.field] = item.sort as "asc" | "desc";
    });
    setSorts(newSorts);
  };

  const handleShowDetails = (event: Event) => {
    setSelectedEvent(event);
    if (eventDetailsModalRef.current) {
      eventDetailsModalRef.current.openModal();
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchTerm(query);
        navigate(`?search=${query}`);
      }, 20),
    [navigate]
  );

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    updateUrl();
  }, [searchTerm, page, sorts, updateUrl]);

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", width: 200, sortable: true, flex: 1 },
    { field: "time", headerName: "Time", width: 200, sortable: true, flex: 1 },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      sortable: true,
      flex: 2,
    },
    {
      field: "tutor_fullname",
      headerName: "Tutor",
      width: 200,
      sortable: true,
      flex: 1,
      renderCell: (params) =>
        `${params.row?.tutors?.first_name} ${params.row?.tutors?.last_name}`,
    },
    {
      field: "feedback_count",
      headerName: "Number of Feedbacks",
      width: 200,
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          {params.row.corrections ? params.row.corrections.length : 0}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleFileButtonClick(params.row)}
            disabled={buttonLoading[params.row.class_id]}
          >
            {buttonLoading[params.row.class_id] ? (
              <CircularProgress size={24} />
            ) : (
              <FileCopyIcon />
            )}
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleShowDetails(params.row)}
          >
            <DescriptionIcon />
          </IconButton>
          {/* hide chatgpt button */}
          {/* <IconButton
            color="primary"
            onClick={() => handleAiButtonClick(params.row)}
            disabled={buttonLoading[params.row.class_id]}
          >
            {buttonLoading[params.row.class_id] ? <CircularProgress size={24} /> : <img
              src={gptIcon}
              style={{
                marginRight: 10,
                filter: theme.palette.filter,
              }}
              className="icon"
            />}
          </IconButton> */}
        </Box>
      ),
    },
  ];

  return (
    <BasePage title="Events Report">
      <Box className="flex gap-4 flex-wrap flex-row-reverse !items-stretch">
        <Filter
          searchTerm={searchTerm}
          onSearchTermChange={handleSearchTermChange}
        />
      </Box>

      <DataGrid
        autoHeight
        disableColumnMenu
        pagination
        sx={getTableStyling(theme)}
        rows={data?.data || []}
        columns={columns}
        getRowId={(row) => row.class_id}
        sortingOrder={["asc", "desc"]}
        onSortModelChange={handleSortModelChange}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: false }}
        rowsPerPageOptions={[perPage]}
        components={{
          Pagination: () => (
            <CustomPagination
              hideTotal
              page={page - 1}
              count={data?.total || 0}
              rowsPerPage={perPage}
              onPageChange={handlePageChange}
            />
          ),
        }}
      />

      <EventReportDetails
        ref={eventDetailsModalRef}
        event={selectedEvent}
        onClose={() => setSelectedEvent(null)}
      />
    </BasePage>
  );
};

export default EventsReport;
