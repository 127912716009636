import { memo } from "react";
import { Box, Typography, Popover } from "@mui/material";

interface IPopper {
  data: any;
  onClose: (...e: any) => void;
  anchorEl: any;
}

const Popper = ({ data, anchorEl, onClose }: IPopper) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Box className="p-4 flex flex-col gap-2">
        <Typography>Type: {data?.type}</Typography>
        <Typography>Tutor: {data?.tutor}</Typography>
        <Typography>Time: {data?.time}</Typography>
        <Typography>Event: {data?.nameEvent}</Typography>
      </Box>
    </Popover>
  );
};

export default memo(Popper);
