import useAuth from '@/hooks/useAuth';
import { Box, Button, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import FiltersBar from "../_Common/FiltersBar";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "../Toolbars/SearchToolbar";
import usePaymentsInApi from "@/api/usePaymentsInApi";
import DateRangePickerField from "../_Common/Forms/DateRangePickerField";

interface IProps {
  filters: any;
  setPage: Function;
  setFilters: Function;
  onClickAdd: () => void;
}

const CustomToolbarPaymentsIn = ({
  filters,
  setPage,
  setFilters,
  onClickAdd,
}: IProps) => {
  const { isMember } = useAuth();
  const { callDownloadInvoices } = usePaymentsInApi();

  const tempDates = filters?.dates?.split(",");
  const dates =
    tempDates?.length > 0
      ? [new Date(tempDates[0]), new Date(tempDates[1])]
      : null;

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Button
          variant="outlined"
          onClick={() => onClickAdd()}
        >
          <img
            src={addIcon}
            className="icon"
          />
          Add manually
        </Button>

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={filters?.dates ? true : false}
        >
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            <TextField
              select
              label="Language"
              value={filters?.lang ?? ""}
              onChange={({ target: { value } }) =>
                setFilters((p: any) => {
                  if (value) {
                    p.set("lang", value);
                  } else p.delete("lang");
                  return p;
                })
              }
            >
              <MenuItem
                key={0}
                value=""
              >
                - Remove -
              </MenuItem>

              {LANGUAGES.map((l) => (
                <MenuItem
                  key={l.iso}
                  value={l.iso}
                >
                  {l.name}
                </MenuItem>
              ))}
            </TextField>

            <DateRangePickerField
              title="Creation date"
              onChange={(date) => {
                if (date) {
                  setFilters((p: any) => {
                    p.set(
                      "dates",
                      dayjs(date.startDate).format("YYYY-MM-DD HH:mm:ss") +
                      "," +
                      dayjs(date.endDate).format("YYYY-MM-DD HH:mm:ss")
                    );
                    return p;
                  });
                } else {
                  setFilters((p: any) => {
                    p.delete("dates");
                    return p;
                  });
                }
              }}
              value={dates}
            />
          </Box>
        </Dropdown>

        {!isMember && (
          <Button
            variant="outlined"
            onClick={() => callDownloadInvoices()}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        filters={filters}
        setPage={setPage}
        setFilters={setFilters}
      />
    </Box>
  );
};

export default CustomToolbarPaymentsIn;
