export default (name: string, link: string): string => {
  return `
<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN"
  "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
  <meta charset="UTF-8" />
  <meta content="width=device-width, initial-scale=1" name="viewport" />
  <meta name="x-apple-disable-message-reformatting" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta content="telephone=no" name="format-detection" />
  <title>selezione</title>
</head>
<body style="width: 100%; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; padding: 0; margin: 0; background-color: #f8f8f8; display: flex; justify-content: center; color: #000;">
  <table class="main-wrapper" width="100%" cellspacing="0" cellpadding="0" bgcolor="#ffffff" style="width: 100%; max-width: 600px; margin: auto; background-color: white; color: #000;">
    <tr>
      <td>
        <table class="hero" width="100%" height="102px" style="width: 100%; height: 102px; background-size: cover; background-position: center; background-repeat: no-repeat; background-image: url('https://api.edusogno.com/public/storage/assets/easypeasy-header.png');">
          <tr>
            <td></td>
          </tr>
        </table>
        <table width="100%" cellspacing="0" cellpadding="0">
          <tr>
            <td align="center" style="padding: 20px;">
              <img style="padding: 20px; width:100px" src="https://api.edusogno.com/public/storage/assets/hello.png" />
            </td>
          </tr>
          <tr>
            <td align="center" style="font-size: 16px; font-weight: bold; padding: 10px;">
              Bonjour ${name}
            </td>
          </tr>
          <tr>
            <td align="center" style="font-size: 14px; padding: 10px 20px; text-align: center;">
              <p style="margin-bottom: 16px;">
                Comme promis, nous vous écrivons pour vous communiquer le résultat de l'entretien de sélection de notre accélérateur d'anglais.
              </p>
              <p style="margin-bottom: 16px;">
                Notre conversation nous a permis d'en savoir un peu plus sur vous et sur vos progrès dans l'apprentissage de l'anglais.
              </p>
              <p style="margin-bottom: 16px;">
                Comme nous n'avons pas un nombre infini de tuteurs et donc un nombre infini de places, nous avons sélectionné les profils qui nous ont semblé les plus motivés et en adéquation avec nos propositions d'apprentissage.
              </p>
            </td>
          </tr>
          <tr >
            <td align="center" >
                <div style="background-color:#21fc9359;border-radius:13px;margin:0 20px;display: grid;">
                  <p style="font-size: 14px;padding: 16px;margin: 0;">
                    Cliquez ici pour voir les résultats de l'entretien 👇
                  </p>
                  <a href="${link}" target="_blank" style="background-color:#21fc93;outline:none;border:none;padding:12px 27px;border-radius:13px;font-weight:600;font-size:14px;max-width:fit-content;text-decoration:none;color:black;margin: 16px auto;margin-top: 0;">
                    Voir les résultats
                  </a>
                </div>
            </td>
          </tr>
          <tr>
            <td align="center" style="padding: 20px;">
              <p style="margin: 0; text-align: center; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 21px; font-size: 14px;">
                Dans tous les cas, nous avons eu beaucoup de plaisir à vous rencontrer,<br /><br /><strong style="font-weight: bolder;">L'équipe de easypeasy</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td align="center">
              <div style="padding:20px;display:flex;width: fit-content;">
                <a target="_blank" href="https://facebook.com/easypeasyfluent" style="margin: 0 10px;">
                  <img src="https://api.edusogno.com/public/storage/assets/facebook.png" alt="Fb" title="Facebook" width="32" style="display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" />
                </a>
                <a target="_blank" href="https://www.instagram.com/easypeasyfluent/" style="margin: 0 10px;">
                  <img src="https://api.edusogno.com/public/storage/assets/instagram.png" alt="Ig" title="Instagram" width="32" style="display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" />
                </a>
                <a target="_blank" href="https://www.youtube.com/@edusognotutoring2258" style="margin: 0 10px;">
                  <img src="https://api.edusogno.com/public/storage/assets/youtube.png" alt="Yt" title="Youtube" width="32" style="display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" />
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/easypeasyfluent/" style="margin: 0 10px;">
                  <img src="https://api.edusogno.com/public/storage/assets/linkedIn.png" alt="In" title="Linkedin" width="32" style="display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" />
                </a>
                <a target="_blank" href="http://tiktok.com/@easypeasy_english" style="margin: 0 10px;">
                  <img src="https://api.edusogno.com/public/storage/assets/tik_tok.png" alt="Tt" title="TikTok" width="32" style="display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" />
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td align="center" style="padding: 20px;">
              <p style="margin: 1.5vh 0; padding-bottom: 3vh; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 18px; color: #333333; font-size: 12px; text-align: center;">
                www.easypeasyfluent.com - Réalisé avec le 🧠 en&nbsp;🇨🇭
              </p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
  `;
};
