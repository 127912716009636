import { createRef, useCallback, useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { Products } from "@/pages/Users/components/CreateUser";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { getUserAccessToken } from "@/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Badge,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  PopoverProduct,
  IAnchorElProduct,
  RenderCellProduct,
  valueGetterProduct,
  defaultAnchorElProduct,
} from "@/components/User/PopoverProduct";
import SendMessageIntercom, {
  IForwardRef as SendMessageIntercomRef,
} from "../../../Users/components/IntercomSendMessage";
import InvoiceUserDialog, {
  IForwardRef as InvoiceUserDialogRef,
} from "../../../Users/components/InvoiceUserDialog";
import FeedbackUserDialog, {
  IForwardRef as FeedbackUserDialogRef,
} from "../../../Users/components/FeedbackUserDialog";
import PresenceUserDialog, {
  IForwardRef as PresenceUserDialogRef,
} from "../../../Users/components/PresenceUserDialog";
import ExercisesUserDialog, {
  IForwardRef as ExercisesUserDialogRef,
} from "../../../Users/components/ExercisesUserDialog";
import useAPI from "@/api/useAPI";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import mailIcon from "@/assets/icons/mail.svg";
import chatIcon from "@/assets/icons/chat.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import useUserApi from "@/api/useUserApi";
import invoiceIcon from "@/assets/icons/invoice.svg";
import commentIcon from "@/assets/icons/comments.svg";
import ReviewsIcon from "@mui/icons-material/Reviews";
import feedbackIcon from "@/assets/icons/feedback.svg";
import presenceIcon from "@/assets/icons/presence.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import exercisesIcon from "@/assets/icons/exercises.svg";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";
import UsersListNotes from "@/pages/UsersList/component/UsersListNotes";
import useDataGridSort from "@/hooks/useDataGridSort";
import impersonateIcon from "@/assets/icons/impersonate.svg";
import CustomPagination from "@/components/_Common/CustomPagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";

const KEY_QUERY = ["upselling", "users", "upsolds"];
const HTTP_BASE = "/v1/upselling/users-upsolds";

const UsersUpsolds = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { userAdmin, copyToClipboard, snackHandler } = useAuth();

  const intercomDialogRef = createRef<SendMessageIntercomRef>();
  const InvoiceUserDialogRef = createRef<InvoiceUserDialogRef>();
  const FeedbackUserDialogRef = createRef<FeedbackUserDialogRef>();
  const PresenceUserDialogRef = createRef<PresenceUserDialogRef>();
  const ExercisesUserDialogRef = createRef<ExercisesUserDialogRef>();

  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useSearchParams();
  const { onSortModelChange, sortModel } = useDataGridSort({
    setFilters,
    filters,
  });

  const filterData = Object.fromEntries(filters.entries());

  const [rowSelected, setRowSelected] = useState<any>();
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );
  const [openConfirmDialogReviews, setOpenConfirmDialogReviews] =
    useState<boolean>(false);

  const sorts = filterData?.sorts;
  delete filterData?.sorts;

  const keyCache = [KEY_QUERY, page, perPage, filterData, sorts];
  const { data: dataAPI, isLoading } = useQuery(
    keyCache,
    () =>
      useAPI(HTTP_BASE, {
        page,
        per_page: perPage || 10,
        filter: filterData,
        sorts,
      }),
    {
      enabled: perPage > 0,
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const downloadUpsolds = async () => {
    const response = await useAPI(HTTP_BASE, {
      filter: filterData,
      sorts,
      export: true,
    });
    let blob = new Blob([response], {
      type: "text/csv",
    });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Upsolds";
    link.click();
  };

  const data = dataAPI?.data || [];
  const pagination = {
    total: dataAPI ? dataAPI?.total : 1,
    last_page: dataAPI ? dataAPI.last_page : 1,
    current_page: dataAPI ? dataAPI.current_page : 1,
  };

  const handleGetReviews = useCallback(async () => {
    setOpenConfirmDialogReviews(false);
    await useAPI("/v1/get-reviews", { token: rowSelected.token });
    snackHandler("Send email for review");
  }, [rowSelected]);

  // const productsData = useMemo(() => {
  //   const tempProducts =
  //     productsAPI?.products?.map((product: any) => {
  //       return {
  //         id: product?.percorsi?.id,
  //         name: product?.percorsi?.nome_percorso,
  //       };
  //     }) || [];

  //   return [
  //     ...new Map(tempProducts.map((item: any) => [item["id"], item])).values(),
  //   ] as Products[];
  // }, [productsAPI]);

  const handleGetAccessToken = useCallback(
    async (token: string) => {
      const res = await getUserAccessToken(userAdmin.token, token);
      window.open(
        `https://academy.edusogno.com/user/impersonate/${res.access_token}`,
        "_blank"
      );
    },
    [userAdmin]
  );

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFilters(
      (prev) => {
        prev.set(name, value);
        return prev;
      },
      { replace: true }
    );
  };

  const englishMenu = useMemo(
    () =>
      ENGLISH_LEVELS.map((e: string, i: number) => (
        <MenuItem
          key={i}
          value={e}
        >
          {e}
        </MenuItem>
      )),
    []
  );

  const { callGetStartMonths } = useUserApi();
  const { data: startMonths } = callGetStartMonths();

  const startMonthMenu = useMemo(() => {
    return startMonths?.data?.map((e) => (
      <MenuItem
        key={e.month}
        value={e.month}
      >
        <div>{e.month_en}</div>
      </MenuItem>
    ));
  }, [startMonths]);

  // const productMenu = useMemo(
  //   () =>
  //     productsData
  //       ?.filter((p) => p.id)
  //       .map((e: any) => (
  //         <MenuItem
  //           key={e.id}
  //           value={e.id}
  //         >
  //           {e.name}
  //         </MenuItem>
  //       )),
  //   [productsData]
  // );

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) =>
        `${row?.first_name} ${row?.last_name}`.trim() || "-",
      renderCell: ({ row }) =>
        `${row?.first_name} ${row?.last_name}`.trim() ? (
          <MyTooltip>{`${row?.first_name} ${row?.last_name}`.trim()}</MyTooltip>
        ) : (
          "-"
        ),
    },
    {
      field: "ultimo_mese_inizio",
      headerName: "New start month",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        useConvertStartMonthEn(row?.ultimo_mese_inizio) || "-",
    },
    {
      field: "english_level",
      headerName: "English level",
      minWidth: 120,
      flex: 0.5,
      valueGetter: ({ row }) => row?.english_level || "-",
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 220,
      flex: 0.5,
      valueGetter: ({ row }) => valueGetterProduct(row),
      renderCell: ({ row }) => (
        <RenderCellProduct
          {...{
            row,
            setAnchorElProduct,
          }}
        />
      ),
    },
    {
      field: "ltv",
      headerName: "LTV",
      minWidth: 100,
      flex: 0.5,
      valueGetter: ({ row }) =>
        String(
          new Intl.NumberFormat("it-IT", {
            style: "currency",
            currency: "EUR",
          }).format(row?.ltv || 0)
        ),
    },
    {
      field: "avg_rating",
      headerName: "Avg. rating",
      minWidth: 110,
      flex: 1,
      valueGetter: ({ row }) => {
        return row?.avg_rating ? Math.round(row?.avg_rating * 10) / 10 : "-";
      },
    },
    {
      field: "nps_feedback",
      headerName: "NPS",
      minWidth: 80,
      flex: 0.5,
      valueGetter: ({ row }) => row?.nps_feedback || "-",
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        const notes = row?.note
          ? typeof row.note === "string"
            ? JSON.parse(row.note)
            : row.note
          : [];

        return (
          <Dropdown text={<MoreVertIcon />}>
            <MenuList>
              <MenuItem
                title="Copy email"
                color="primary"
                onClick={() => copyToClipboard(row?.email)}
              >
                <ListItemIcon>
                  <img
                    src={mailIcon}
                    className="icon "
                  />
                </ListItemIcon>
                <ListItemText>Copy email</ListItemText>
              </MenuItem>

              <MenuItem
                title="Send WhatsApp message"
                onClick={() => {
                  const url =
                    "https://api.whatsapp.com/send?phone=" +
                    row?.phone +
                    "&text=";
                  window.open(url);
                }}
              >
                <ListItemIcon>
                  <img
                    src={chatIcon}
                    className="icon "
                  />
                </ListItemIcon>
                <ListItemText>WhatsApp</ListItemText>
              </MenuItem>

              <MenuItem
                title="Impersonate Academy User"
                onClick={() => handleGetAccessToken(row?.token)}
              >
                <ListItemIcon>
                  <img
                    src={impersonateIcon}
                    className="icon"
                  />
                </ListItemIcon>
                <ListItemText>Impersonate</ListItemText>
              </MenuItem>

              <MenuItem
                title="Feedbacks"
                color="primary"
                onClick={() => FeedbackUserDialogRef.current?.open(row.token)}
              >
                <ListItemIcon>
                  <img
                    src={feedbackIcon}
                    className="icon"
                  />
                </ListItemIcon>
                <ListItemText>Feedbacks</ListItemText>
              </MenuItem>

              <MenuItem
                title="Presence"
                color="primary"
                onClick={() => PresenceUserDialogRef.current?.open(row.token)}
              >
                <ListItemIcon>
                  <img
                    src={presenceIcon}
                    className="icon"
                  />
                </ListItemIcon>
                <ListItemText>Presence</ListItemText>
              </MenuItem>

              <MenuItem
                title="Exercises"
                color="primary"
                onClick={() => ExercisesUserDialogRef.current?.open(row.token)}
              >
                <ListItemIcon>
                  <img
                    src={exercisesIcon}
                    className="icon"
                  />
                </ListItemIcon>
                <ListItemText>Exercises</ListItemText>
              </MenuItem>

              <MenuItem
                title="Invoices"
                color="primary"
                onClick={() => InvoiceUserDialogRef.current?.open(row.token)}
              >
                <ListItemIcon>
                  <img
                    src={invoiceIcon}
                    className="icon"
                  />
                </ListItemIcon>
                <ListItemText>Invoices</ListItemText>
              </MenuItem>

              <MenuItem
                title="Send a message with Intercom"
                color="primary"
                onClick={() =>
                  intercomDialogRef.current?.open({
                    token: row.token,
                    user: row,
                  })
                }
              >
                <ListItemIcon>
                  <i
                    className="fa-brands fa-intercom"
                    style={{ fontSize: "1.2rem" }}
                  />
                </ListItemIcon>
                <ListItemText>Intercom</ListItemText>
              </MenuItem>

              <MenuItem
                title={"Ask for a trustpilot review"}
                onClick={() => {
                  setRowSelected(row);
                  setOpenConfirmDialogReviews(true);
                }}
              >
                <ListItemIcon>
                  <ReviewsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Review</ListItemText>
              </MenuItem>

              <MenuItem
                title="Comments"
                color="primary"
                onClick={() => {
                  setOpenComment(true);
                  setRowSelected(row);
                }}
              >
                <ListItemIcon>
                  <img
                    src={commentIcon}
                    className="icon "
                  />
                </ListItemIcon>
                <ListItemText>
                  Comments
                  <Badge
                    sx={{ mx: 2 }}
                    color="warning"
                    badgeContent={notes.length}
                  />
                </ListItemText>
              </MenuItem>
            </MenuList>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <BasePage title="Upsolds">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        disableColumnFilter
        sx={getTableStyling(theme)}
        rows={data}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        sortingMode="server"
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            filters: filterData,
            englishMenu,
            // productMenu,
            handleChange,
            startMonthMenu,
            setPage,
            setFilters,
            downloadUpsolds,
          },
          pagination: { page, setPage, pagination },
        }}
      />

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}

      <UsersListNotes
        open={openComment}
        setOpen={setOpenComment}
        cacheKey={{}}
        userAdmin={userAdmin}
        selectedUser={rowSelected}
      />

      <ConfirmationDialog
        open={openConfirmDialogReviews}
        onClose={() => setOpenConfirmDialogReviews(false)}
        onConfirm={handleGetReviews}
      >{`Do you want to send review to ${rowSelected?.first_name} ${rowSelected?.last_name}?`}</ConfirmationDialog>

      <InvoiceUserDialog ref={InvoiceUserDialogRef} />
      <FeedbackUserDialog ref={FeedbackUserDialogRef} />
      <PresenceUserDialog ref={PresenceUserDialogRef} />
      <SendMessageIntercom ref={intercomDialogRef} />
      <ExercisesUserDialog ref={ExercisesUserDialogRef} />
    </BasePage>
  );
};

export default UsersUpsolds;
