import { Box } from "@mui/material";
import Title from "../_Common/Page/Title";

interface IProps {
  sx?: any;
  title: string;
  children: any;
  showIcon?: boolean;
  className?: string;
}

const BasePage = ({
  sx,
  title,
  children,
  showIcon = true,
  className,
}: IProps) => {
  return (
    <Box className="mt-7 mx-4 md:mx-7">
      <Title showIcon={showIcon}>{title}</Title>

      <Box
        sx={sx}
        className={className}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BasePage;
