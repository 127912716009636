import { UserAdmin } from "@/providers/AuthProvider";
import { getTranslation, translations } from "@/utils/translations";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const getDateTimeWithZoneFromLang = (lang: string, dateTime: string) => {
  let tz: string = "Europe/Rome";
  let date: any = dayjs(dateTime).tz(tz);
  switch (lang) {
    case "es":
      tz = "Europe/Madrid";
      break;
    case "de":
      tz = "Europe/Berlin";
      break;
    case "pt":
      tz = "Europe/Lisbon";
      break;
    case "fr":
      tz = "Europe/Paris";
      break;
    case "en":
      tz = "Europe/London";
      break;
    default:
      return date;
  }
  return date.clone().tz(tz);
};

export interface IRowCrm {
  first_name: string;
  lang: string;
  last_name: string;
  phone: string;
  lastMeet: {
    end: string;
    start: string;
    calendar: string;
    link: string;
  };
  version?: string;
  owned_by: string;
}

export default {
  name_lead: {
    code: "[value:name_lead]",
    example: "Anna",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) => rowCrm.first_name,
  },
  last_name_lead: {
    code: "[value:last_name_lead]",
    example: "Carlos",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) => rowCrm.last_name,
  },
  full_name_this_admin: {
    code: "[value:full_name_this_admin]",
    example: "Filippo",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      userAdmin?.user?.first_name + " " + userAdmin?.user?.last_name,
  },
  name_this_admin: {
    code: "[value:name_this_admin]",
    example: "Filippo",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      userAdmin?.user?.first_name,
  },
  full_name_last_meet_calendar: {
    code: "[value:full_name_last_meet_calendar]",
    example: "Serena",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      rowCrm?.lastMeet?.calendar,
  },
  meeting_link: {
    code: "[value:meeting_link]",
    example: "Filippo",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) => rowCrm?.lastMeet?.link,
  },
  name_last_meet_calendar: {
    code: "[value:name_last_meet_calendar]",
    example: "Serena",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      rowCrm?.lastMeet?.calendar?.split(" ")?.[0],
  },
  time: {
    code: "[value:time]",
    example: "14:30",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      getDateTimeWithZoneFromLang(rowCrm.lang, rowCrm?.lastMeet?.start).format(
        "HH:mm"
      ),
  },
  one_number_day: {
    code: "[value:one_number_day]",
    example: "4",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      dayjs(rowCrm?.lastMeet?.start).format("D"),
  },
  number_day: {
    code: "[value:number_day]",
    example: "04",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      dayjs(rowCrm?.lastMeet?.start).format("DD"),
  },
  name_day: {
    code: "[value:name_day]",
    example: "Saturday",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) => {
      const day = Number(dayjs(rowCrm?.lastMeet?.start).format("d"));
      return translations[rowCrm.lang].weeks[day === 0 ? 6 : day - 1];
    },
  },
  name_day_today_tomorrow: {
    code: "[value:name_day_today_tomorrow]",
    example: "Saturday, Today, Tomorrow",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) => {
      const date = dayjs(rowCrm?.lastMeet?.start);
      if (dayjs().isSame(date, "day")) {
        return getTranslation(rowCrm.lang, "today");
      } else if (dayjs().add(1, "day").isSame(date, "day")) {
        return getTranslation(rowCrm.lang, "tomorrow");
      }
      const day = Number(dayjs(rowCrm?.lastMeet?.start).format("d"));
      return translations[rowCrm.lang].weeks[day === 0 ? 6 : day - 1];
    },
  },
  name_month: {
    code: "[value:name_month]",
    example: "August",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      translations[rowCrm.lang].months[
        Number(dayjs(rowCrm?.lastMeet?.start).format("M")) - 1
      ],
  },
  full_year: {
    code: "[value:full_year]",
    example: "2024",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      dayjs(rowCrm?.lastMeet?.start).format("YYYY"),
  },
  full_date: {
    code: "[value:full_date]",
    example: "2024-08-04",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      dayjs(rowCrm?.lastMeet?.start).format("YYYY-MM-DD"),
  },
  full_date_time: {
    code: "[value:full_date_time]",
    example: "2024-08-04 12:35:08",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      getDateTimeWithZoneFromLang(rowCrm.lang, rowCrm?.lastMeet?.start).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
  },
  owned_by: {
    code: "[value:owned_by_name]",
    example: "Filippo",
    value: (rowCrm: IRowCrm, userAdmin: UserAdmin) =>
      rowCrm?.owned_by.split(" ")[0],
  },
};
