import { forwardRef, useImperativeHandle, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
} from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import HistoryIcon from "@mui/icons-material/History";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TimelineIcon from "@mui/icons-material/Timeline";
import {
  LevelChangeHistoryModalRef,
  LevelRecommendationHistoryModalRef,
} from "./interfaces";
import { LoadingButton } from "@mui/lab";
import deleteIcon from "@/assets/icons/delete.svg";
import useAPI from "@/api/useAPI";
import { queryClient } from "@/main";

const LevelRecommendationHistoryModal =
  forwardRef<LevelRecommendationHistoryModalRef>((_, ref) => {
    const [open, setOpen] = useState(false);
    const [queryKey, setQueryKey] = useState<[]>([]);
    const [user, setUser] = useState<any>({});
    const [loadingIds, setLoadingIds] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
      openModal: (row, keyQuery) => {
        setOpen(true);
        setUser(row);
        setQueryKey(keyQuery);
      },
      closeModal: () => setOpen(false),
    }));

    const handleClose = () => setOpen(false);

    const recommendations = user?.tutors_reports
      ?.slice()
      .sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );

    const totalChanges = recommendations?.length || 0;
    const increases = recommendations?.filter(
      (recommendation) => recommendation?.level_change_direction == "increase"
    ).length;
    const decreases = recommendations?.filter(
      (recommendation) => recommendation?.level_change_direction == "decrease"
    ).length;

    const handleDelete = async (recommendationId: string) => {
      try {
        setLoadingIds((prev) => [...prev, recommendationId]);
        const response = await useAPI(
          "/v1/users/level-change/delete-recommended-level",
          {
            id: recommendationId,
          }
        );

        if (response.status) {
          setUser((prevUser: any) => ({
            ...prevUser,
            tutors_reports: prevUser.tutors_reports.filter(
              (rec: any) => rec.id !== recommendationId
            ),
          }));
          queryClient.invalidateQueries(queryKey);
        }
      } catch (error) {
        console.error("Error deleting recommendation", error);
      } finally {
        setLoadingIds((prev) => prev.filter((id) => id !== recommendationId));
      }
    };

    return (
      <EduModal
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <TimelineIcon />
          Tutor Recommendation History for - {user?.fullName || ""}
        </EduModalTitle>
        <EduModalBody
          className="p-5 pt-7 flex flex-col gap-4"
          maxHeight={"50vh"}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Summary</Typography>
            <Box>
              <Badge
                badgeContent={increases}
                color="success"
              >
                <ArrowUpwardIcon sx={{ color: "green", ml: 1 }} />
              </Badge>
              <Badge
                badgeContent={decreases}
                color="error"
              >
                <ArrowDownwardIcon sx={{ color: "red", ml: 1 }} />
              </Badge>
              <Typography variant="body2">
                Total Recommendations: {totalChanges}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />
          <List>
            {recommendations?.map((recommendation, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <HistoryIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={`Tutor: ${recommendation?.tutor?.fullName}`}
                  secondary={
                    <>
                      <Typography variant="body2">
                        Date:{" "}
                        {new Date(recommendation.updated_at).toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        Level Recommended: {recommendation.recommended_level}
                        {recommendation?.level_change_direction ===
                        "increase" ? (
                          <ArrowUpwardIcon sx={{ color: "green", ml: 1 }} />
                        ) : recommendation?.level_change_direction ===
                          "decrease" ? (
                          <ArrowDownwardIcon sx={{ color: "red", ml: 1 }} />
                        ) : (
                          ""
                        )}
                      </Typography>
                    </>
                  }
                />
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={loadingIds.includes(recommendation.id)}
                  onClick={() => handleDelete(recommendation.id)}
                >
                  <img
                    src={deleteIcon}
                    className="icon no-margin"
                  />
                  Delete
                </LoadingButton>
              </ListItem>
            ))}
          </List>
        </EduModalBody>
      </EduModal>
    );
  });

export default LevelRecommendationHistoryModal;
