import { logout } from "@/api";
import { Tooltip } from "@mui/material";
import useAuth from '@/hooks/useAuth';
import { rmCookie } from "@/components/Cookie";
import { useMutation } from "@tanstack/react-query";
import LogoutIcon from "@/assets/icons/logout.svg";

const ROUTES_BASE = import.meta.env.VITE_ROUTES_BASE;

const Logout = () => {
  const { userAdmin, snackHandler } = useAuth();

  const { mutate } = useMutation<
    { logout: boolean; message: string },
    any,
    string
  >(["userAdmin"], logout, {
    onSuccess: (data: { logout: boolean; message: string }) => {
      if (data.logout) {
        snackHandler(data.message);
        rmCookie("user");
        rmCookie("user-old");
        location.href = ROUTES_BASE;
      }
    },
  });

  const handleClick = () => {
    mutate(userAdmin.token);
  };

  return (
    <Tooltip
      title="Logout"
      placement="right"
      className="mt-6"
    >
      <button
        type="button"
        onClick={handleClick}
      >
        <img src={LogoutIcon} />
      </button>
    </Tooltip>
  );
};

export default Logout;
