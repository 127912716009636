import { forwardRef, useImperativeHandle, useState } from "react";
import { Box } from "@mui/material";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

export interface IDetailsMeetsRef {
  open: (rows: Array<any>, nameProduct: string) => void;
}

interface IProps {
  theme: any;
}

const DetailsMeets = forwardRef<IDetailsMeetsRef, IProps>(({ theme }, ref) => {
  const { perPage } = useScreen();

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [nameProduct, setNameProduct] = useState<string>("");

  useImperativeHandle(ref, () => ({
    open: (rows, nameProduct) => {
      setRows(rows);
      setNameProduct(nameProduct);
    },
  }));

  const handleClose = () => {
    setRows([]);
  };

  const columns: GridColDef[] = [
    {
      field: "start",
      headerName: "Meeting date",
      width: 170,
    },
    {
      field: "interviewer",
      headerName: "Sales",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.interviewer}</MyTooltip>,
    },
    {
      field: "motivation",
      headerName: "Status",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "notes",
      headerName: "Note",
      minWidth: 250,
      flex: 1.5,
      renderCell: ({ row }) => <MyTooltip>{row?.notes}</MyTooltip>,
    },
  ];

  return (
    <EduModal
      fullWidth
      open={Boolean(rows?.length)}
      onClose={handleClose}
      maxWidth="lg"
    >
      <EduModalTitle onClose={handleClose}>{nameProduct}</EduModalTitle>

      <EduModalBody className="p-5">
        <DataGrid
          autoHeight
          disableColumnMenu
          disableVirtualization
          disableSelectionOnClick
          sx={getTableStyling(theme)}
          rows={rows || []}
          columns={columns}
          pageSize={perPage}
          components={{
            Toolbar: () => (
              <Box className="w-full flex">
                <SearchToolbar />
              </Box>
            ),
            Pagination: CustomPaginationClient,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowsPerPageOptions={[perPage]}
          experimentalFeatures={{ newEditingApi: false }}
        />
      </EduModalBody>
    </EduModal>
  );
});

export default DetailsMeets;
