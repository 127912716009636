import { useEffect, useState } from "react";
import { TableStyling } from "@/dummy";
import { COLORS_STARS_MEDIA } from "..";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, Rating, Link, Badge, useTheme } from "@mui/material";
import useScreen from "@/hooks/useScreen";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DialogStudents from "./dialogStudents";
import MessageRounded from "@mui/icons-material/MessageRounded";
import ToolbarStudents from "./ToolbarStudents";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getTableStyling } from "@/providers/Colors";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const FIRST_CAMP_FILTER: any = {
  normal: "Start month",
  down: "Start month".toLowerCase().replace(" ", "_"),
};

const StudentsFeedbacks = ({ dataAPI, getNotes, isLoading }) => {
  const { isMobile } = useScreen();
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<Array<any>>([]);
  const [rangeDate, setRangeDate] = useState<Array<any>>([]);
  const [dataDialog, setDataDialog] = useState<any>({});
  const [filterMonth, setFilterMonth] = useState<any>({
    [FIRST_CAMP_FILTER.down]: "All",
  });

  const filterData = (data: Array<any>): Array<any> => {
    let newData: Array<any> = [...data];

    if (filterMonth[FIRST_CAMP_FILTER.down] !== "All") {
      newData = newData.filter(
        (f: any) => f?.user?.start_month === filterMonth[FIRST_CAMP_FILTER.down]
      );
    }

    if (rangeDate.length) {
      const start: Date = new Date(rangeDate[0]);
      const end: Date = new Date(rangeDate[1]);

      newData = newData.filter(({ event }) => {
        const created: Date = new Date(event?.data);
        return start <= created && created <= end;
      });
    }

    return newData;
  };

  // ROWS ==============================================================================
  const generateRows = (data: Array<any>) => {
    const rows: Array<any> = [];

    // Map per memorizzare le informazioni di ogni persona
    const people = new Map<
      number,
      { ratingMedio: number; count: number; dataIndex: any }
    >();

    // Scansione dei dati
    data.forEach((d: any) => {
      if (d?.user?.id) {
        const id = d.user.id;

        // Se l'ID non è presente nella mappa, lo aggiungiamo con le informazioni iniziali
        if (!people.has(id)) {
          people.set(id, { ratingMedio: 0, count: 0, dataIndex: {} });
        }

        // Aggiorniamo il rating medio e il conteggio per l'ID corrente
        const person: { ratingMedio: number; count: number; dataIndex: any } =
          people.get(id)!;
        person.ratingMedio += Number(d?.punto) / 2;
        person.count++;
        person.dataIndex = d;
      }
    });

    // Costruzione dell'array finale
    Array.from(people.values()).forEach((person: any) => {
      rows.push({
        ...person.dataIndex,
        rating: person.ratingMedio / person.count,
      });
    });

    return rows;
  };

  // COLUMNS ==============================================================================
  const columns: GridColDef[] = [
    {
      field: "created_at",
      hide: true,
      hideable: false,
    },
    {
      field: "student",
      headerName: "Student",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) =>
        `${row?.user?.first_name} ${row?.user?.last_name}`,
    },
    {
      field: "start_month",
      headerName: "Month initial",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row }) => row?.user?.start_month || "-",
    },
    {
      field: "rating",
      headerName: "Rating",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => "rating =r" + (row?.rating.toFixed(2) || 0),
      renderCell: ({ row }) => {
        const rat = row.rating;

        return (
          <Box className="flex items-center gap-2">
            <Box>{rat.toFixed(2)}</Box>
            <Rating
              style={{ color: "#faaf00" }}
              defaultValue={rat}
              value={rat}
              precision={0.1}
              readOnly
            />
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 210,
      flex: 1,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <Box
          className="flex flex-nowrap"
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "8px",
            background: theme.palette.background.paper,
          }}
        >
          <Button
            onClick={() => {
              setDataDialog({
                data: dataAPI.filter(({ id_user, event }) => {
                  if (row?.user?.id == undefined) return false;

                  const id = parseInt(id_user) == parseInt(row.user.id);

                  if (rangeDate.length) {
                    return (
                      new Date(rangeDate[0]) <= new Date(event?.data) &&
                      new Date(event?.data) <= new Date(rangeDate[1]) &&
                      id
                    );
                  }

                  return id;
                }),
                fullNome: `${row?.user?.first_name} ${row?.user?.last_name}`,
              });
              setOpen(true);
            }}
          >
            <RemoveRedEyeIcon />
          </Button>

          <Button>
            <Link
              color="primary"
              target={"_blank"}
              href={`https://api.whatsapp.com/send?phone=${row?.user?.phone}&text=Ciao ${row?.user?.first_name}`}
            >
              <WhatsAppIcon />
            </Link>
          </Button>

          <Button
            onClick={() =>
              getNotes({
                token: row?.user?.token,
                fullNome: `${row?.user?.first_name} ${row?.user?.last_name}`,
                numFeedback: row?.user?.notes_feedback?.[0]?.num_notes,
                person: "user",
              })
            }
          >
            <Badge
              color="warning"
              badgeContent={row?.user?.notes_feedback?.[0]?.num_notes}
            >
              <MessageRounded />
            </Badge>
          </Button>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (!dataAPI?.length) return;
    setRows(generateRows(filterData(dataAPI)));
  }, [dataAPI, filterMonth, rangeDate]);

  return (
    <>
      <DataGrid
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        // density="comfortable"
        pageSize={20}
        autoHeight={true}
        initialState={{
          sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        rowsPerPageOptions={[20]}
        experimentalFeatures={{ newEditingApi: false }}
        columnVisibilityModel={{ created_at: false }}
        components={{
          Toolbar: () => (
            <ToolbarStudents
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
              filterMonth={filterMonth}
              setFilterMonth={setFilterMonth}
              FIRST_CAMP_FILTER={FIRST_CAMP_FILTER}
            />
          ),
          Pagination: CustomPaginationClient,
        }}
      />

      {open ? <DialogStudents {...{ open, setOpen, dataDialog }} /> : null}
    </>
  );
};

export default StudentsFeedbacks;
