import { useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { getMaterials } from "@/api";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import TableMaterials from "./Table";
import CreateMaterial from "./Create";
import MaterialsAssignment from "./MaterialsAssignment";

const TYPE_SELECT = ["Listening", "Reading", "Writing", "Grammar"];
const NAME_SELECT = [
  "IELTS 0",
  "IELTS 0 - TEST 0",
  "IELTS 0 - TEST 0 - PART 0",
  "GRAMMAR TEST 0",
];

const Materials = () => {
  const { userAdmin, snackHandler } = useAuth();
  const [openCreate, setOpenCreate] = useState(false);
  const [openAssignment, setOpenAssignment] = useState(false);

  const keyQuery = ["materials-list"];

  const { data: dataAPI, isLoading } = useQuery(
    keyQuery,
    () =>
      getMaterials(userAdmin.token).then((materials) =>
        materials.map((material: any) => ({
          ...material,
          category: material.category
            ? typeof material.category === "string"
              ? material.category.split(",")
              : material.category
            : [],
          language: material.language || [],

          english_level: material.english_level || [],
        }))
      ),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const handleEditMutate = () => {
    queryClient.invalidateQueries(keyQuery);
    snackHandler("Success edit!");
  };

  const handleDelete = async (id: number) => {
    const res = await useAPI(`/v1/materials/${id}`, {}, { type: "delete" });
    if (!res?.status) {
      snackHandler(res?.message ?? "Failed delete material!", "error");
      return;
    }

    snackHandler("Success delete material");
    queryClient.invalidateQueries(keyQuery);
  };

  const handleCreateMutate = () => {
    queryClient.invalidateQueries(keyQuery);
    snackHandler("Success created!");
  };

  return (
    <BasePage title="All Materials">
      <TableMaterials
        {...{
          dataAPI,
          isLoading,
          userAdmin,
          NAME_SELECT,
          setOpenCreate,
          setOpenAssignment,
        }}
        onDelete={handleDelete}
        onMutate={handleEditMutate}
      />

      <CreateMaterial
        {...{ openCreate, setOpenCreate, userAdmin, TYPE_SELECT, NAME_SELECT }}
        onMutate={handleCreateMutate}
      />

      <MaterialsAssignment
        {...{
          userAdmin,
          openAssignment,
          setOpenAssignment,
          users: dataAPI,
          allMaterials: dataAPI,
        }}
      />
    </BasePage>
  );
};

export default Materials;
