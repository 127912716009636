import dayjs from "dayjs";

export default (
  data: string,
  nameFile: string = `pdf-${dayjs().format("DD-MM-YYYY")}.pdf`
) => {
  if (!data.includes("data:application/pdf;base64,")) {
    data = "data:application/pdf;base64," + data;
  }
  const link = document.createElement("a");
  link.href = data;
  link.download = nameFile;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
