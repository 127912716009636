import { EduModal, EduModalBody, EduModalTitle } from "@/components/_Common/Modal/ModalStyles";
import { Box } from "@mui/material";
import { forwardRef } from "react";
interface ViewPDFModalProps {
    open: boolean;
    onClose: () => void;
    pdfSrc: string | null;
}

const ViewPDFModal = forwardRef((props: ViewPDFModalProps, ref) => {
    const { open, onClose, pdfSrc } = props;

    const handleCloseIframe = () => {
        const frameContent = document.getElementById("frameContent");
        const iframe = document.getElementById("popFrame");

        iframe?.classList.toggle("fixed");
        iframe?.classList.toggle("hidden");
        frameContent?.setAttribute("src", "");
        onClose();
    };

    return (
        <EduModal open={open} onClose={handleCloseIframe}>
            <EduModalTitle onClose={handleCloseIframe}>Visualizza Fattura</EduModalTitle>
            <EduModalBody>
                <Box
                    id="popFrame"
                    className="inset-0 flex justify-center items-center z-10"
                >

                    <iframe
                        id="frameContent"
                        width="1024"
                        height="700"
                        src={pdfSrc || undefined}
                    />
                </Box>
            </EduModalBody>
        </EduModal>
    );
});

export default ViewPDFModal;
