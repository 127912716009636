import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../main";
import {
  updateInvoce,
  getInvoices,
  createInvoce,
  downloadInvoices,
} from "./paymentsInApi";
import useAuth from "@/hooks/useAuth";

const usePaymentsInApi = () => {
  const { snackHandler, userAdmin, isAdmission } = useAuth();

  const keyCache = ["payments-in", isAdmission];
  let lastKey;

  const callGetInvoices = (params, enabled = true) => {
    lastKey = [keyCache, params];
    queryClient.setQueryData([keyCache, "last-params"], () => params);

    return useQuery(
      [keyCache, params],
      async () => {
        const rs = await getInvoices(userAdmin.token, params);
        return rs;
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        enabled,
      }
    );
  };

  const callUpdatePaymentIn = useMutation(
    (params: {
      token: string;
      id: number;
      formData: any;
      callback: () => void;
    }) => updateInvoce(params),
    {
      onSuccess: async () => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        queryClient.invalidateQueries([keyCache, params]);
        snackHandler("Payment updated!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callCreatePaymentIn = useMutation(
    (params: { token: string; formData: any; callback: () => void }) =>
      createInvoce(params),
    {
      onSuccess: async () => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        queryClient.invalidateQueries([keyCache, params]);
        snackHandler("Payment created!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callDownloadInvoices = async () => {

    const params = queryClient.getQueryData([keyCache, "last-params"]);
    downloadInvoices(userAdmin.token, params);
  };

  return {
    keyCache: lastKey,
    callGetInvoices,
    callUpdatePaymentIn,
    callCreatePaymentIn,
    callDownloadInvoices,
  };
};

export default usePaymentsInApi;
