import { useImperativeHandle, forwardRef, useState, MouseEvent } from "react";
import { InfoBoxRef } from "../interface";
import { Popover, Box, IconButton } from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import FlagRender from "@/components/FlagRender";

interface InfoBoxProps {
  row: {
    email?: string;
    status?: string;
    language?: string;
    utm_source?: string;
    [key: string]: any;
  };
}

const InfoBox = forwardRef<InfoBoxRef, InfoBoxProps>(({ row }, ref) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useImperativeHandle(ref, () => ({
    openPopover: (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    closePopover: () => setAnchorEl(null),
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "info-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box className="p-4 relative w-[380px]">
        <IconButton
          onClick={handleClose}
          className="!absolute top-2 right-2"
        >
          <CloseIcon />
        </IconButton>

        <Box
          id="info-popover-description"
          className="flex flex-col gap-1"
        >
          {Boolean(row?.created_at) && (
            <p
              title={dayjs(row.created_at)
                .tz("Europe/Rome")
                .format("YYYY-MM-DD HH:mm")}
            >
              <b>DATE & TIME:</b>{" "}
              {dayjs(row.created_at)
                .tz("Europe/Rome")
                .format("ddd, DD-M HH:mm")}
            </p>
          )}

          {Boolean(row?.utm_source) && (
            <p>
              <b>UTM SOURCE:</b> {row.utm_source}
            </p>
          )}

          {Boolean(row?.email) && (
            <p>
              <b>EMAIL:</b> {row.email}
            </p>
          )}

          {Boolean(row?.lang) && (
            <p className="flex items-center gap-1">
              <b>Language: </b> {row?.lang?.toUpperCase()}
              <div className="flex items-center w-[15px] ml-1">
                <FlagRender
                  className="min-w-[20px]"
                  flag={
                    row?.lang === "de"
                      ? row?.phone?.includes("+41")
                        ? "ch"
                        : "de"
                      : row?.lang
                  }
                />
              </div>
            </p>
          )}

          {Boolean(row?.status) && (
            <p>
              <b>STATUS:</b> {row.status}
            </p>
          )}
        </Box>
      </Box>
    </Popover>
  );
});

export default InfoBox;
