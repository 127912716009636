import { useMemo } from "react";
import {
  Box,
  Select,
  Button,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";
import linkIcon from "@/assets/icons/link.svg";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import useRecruitingApi from "@/api/useRecruitingApi";
import useRecruitingFormApi from "@/api/useRecruitingFormApi";

export default ({ filters, setFilters, recruitingRef }) => {
  const { data: forms } = useRecruitingFormApi().callGetForms();
  const statuses = useRecruitingApi().statuses;
  const { callDownloadCandidates } = useRecruitingApi();

  const formsMenu = useMemo(
    () =>
      forms &&
      forms
        // .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
        .map((f: any) => (
          <MenuItem
            key={f.id}
            value={f.id}
          >
            <Typography
              noWrap
              className="w-fit"
            >
              {f.name}
            </Typography>
          </MenuItem>
        )),
    [forms]
  );

  const handleChange = ({ target: { name, value } }) => {
    setFilters((p: any) => {
      if (value) p.set(name, value);
      else p.delete(name);

      return p;
    });
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 flex-wrap !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(
            Object.keys(filters).filter(
              (key) => !["search", "sorts", "page"].includes(key)
            ).length
          )}
        >
          <Box className="p-3 flex flex-col gap-4">
            <TextField
              select
              className="w-48"
              name="form_id"
              label="Form link"
              value={filters?.form_id || ""}
              onChange={handleChange}
            >
              {formsMenu}
            </TextField>

            <TextField
              select
              fullWidth
              id="status-select"
              name="status"
              color="primary"
              label="Status"
              value={filters?.status || ""}
              onChange={handleChange}
            >
              {statuses.map((status, i) => (
                <MenuItem
                  key={status.label + "-status-" + i}
                  value={status.value}
                >
                  <Box className="flex flex-row gap-3">{status.value}</Box>
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {/* <TextField
            select
            className="w-48"
            name="form_id"
            label="Form link"
            value={filters?.form_id || ""}
            onChange={handleChange}
          >
            {formsMenu}
          </TextField>
          */}
        </Dropdown>

        <Button
          variant="outlined"
          onClick={() => callDownloadCandidates({ ...filters })}
        >
          <img
            src={downloadIcon}
            className="icon"
          />
          Download
        </Button>

        <Button
          variant="outlined"
          onClick={() => recruitingRef.current.open()}
        >
          <img
            src={linkIcon}
            className="icon"
          />
          Create a form link
        </Button>
      </FiltersBar>

      <SearchToolbar
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};
