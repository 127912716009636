export default (URLImg: string, nameFile: string) => {
  fetch(URLImg)
    .then((response) => response.blob())
    .then((blob) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = nameFile.includes(".")
        ? nameFile
        : nameFile + ".png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
};
