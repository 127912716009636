import { IFormMeetingDone } from "@/components/Crm/MeetingComponent";

export interface INStatus {
  Admin: string;
  NoBooked: string;
  MailSent: string;
  ToBeCalled: string;
  UpsellingScheduled: string;
  UpsellingDone: string;
  ToBeConverted: string;
  Reschedule: string;
  InterestedInTheFuture: string;
  StandBy: string,
  OfferRejected: string;
  NoShow: string;
  NotInterested: string;
  Upsold: string;
}

export interface INButtonCup {
  open: boolean;
  template: string;
  showConfirm: boolean;
  isLoadingConfirm: boolean;
}

export const defaultButtonCup: INButtonCup = {
  open: false,
  template: "",
  showConfirm: false,
  isLoadingConfirm: false,
};

export type TypeTemplate = "" | "info" | "comments" | "otherStatus" | "email" | "changeEnglishLevel";

export interface INAnchorsEl {
  open?: boolean;
  value?: any;
  element?: any;
  content?: any;
  buttons?: Array<string>;
  template?: TypeTemplate;
}

export const defaultAnchorEl: INAnchorsEl = {
  open: false,
  value: "",
  element: "",
  content: "",
  buttons: [],
  template: "",
};

export interface INDialogsEl {
  firstMeeting: { value: boolean };
  templateEmail: { value: boolean };
  updateMeeting: { value: boolean };
  confirmPayment: { value: boolean };
}

export const defaultDialogsEl: INDialogsEl = {
  firstMeeting: { value: false },
  templateEmail: { value: false },
  updateMeeting: { value: false },
  confirmPayment: { value: false },
};

export interface INUpdateRow {
  toast?: string;
  values?: any;
  otherRows?: any;
  tokenUser?: string;
  activeApi?: boolean;
}

export const defaultFormMeetingDone: IFormMeetingDone = {
  id: null,
  notes: null,
  pacchetto: null,
  english_lvl: null,
  interviewer: null,
  newStartingMonth: null,
};

export interface INConfirmDialog {
  text: string;
  open: boolean;
  title: any;
  onAgree: null | Function;
  onDisagree: null | Function;
}

export const defaultConfirmDialog: INConfirmDialog = {
  text: "",
  open: false,
  title: "",
  onAgree: null,
  onDisagree: null,
};

export interface INCodeValues {
  code: string;
  product?: string;
  expires_at: number;
  newStartingMonth?: string;
}
