import backIcon from "@/assets/icons/back.svg";
import nextIcon from "@/assets/icons/next.svg";
import saveIcon from "@/assets/icons/save.svg";
import videoIcon from "@/assets/icons/video.svg";
import MONTHS from "@/components/assets/MONTHS";
import useAuth from '@/hooks/useAuth';
import { STATUS } from "@/pages/Upselling/v1/Crm";
import DuoIcon from "@mui/icons-material/Duo";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { format, parseISO } from "date-fns";
import { ChangeEvent, useEffect, useState } from "react";
import FlagRender from "../FlagRender";
import client from '@/api/client'
import { useQuery } from "@tanstack/react-query";

const Checkbox = ({ checked, theme }) => (
  <Box
    style={{
      width: "21px",
      height: "21px",
      borderRadius: "50%",
      border: `1px solid ${theme.palette.primary.main} `,
      marginRight: 15,
    }}
    className="flex items-center justify-center"
  >
    {checked && (
      <Box
        style={{
          width: "13px",
          height: "13px",
          borderRadius: "50%",
          background: theme.palette.primary.main,
        }}
      />
    )}
  </Box>
);

const Step1 = ({
  meetingsSorted,
  setEventId,
  eventId,
  theme,
  setStep,
  setIsFictitious,
}) => {
  if (!Boolean(meetingsSorted?.length)) {
    return <Typography color="secondary">No Events</Typography>;
  }

  return (
    <Box>
      <ul className="listEvents flex flex-col gap-3">
        {meetingsSorted.map((event: any, i: number) => {
          const isEnd = new Date() > new Date(event.start);
          const isFictitiousEvent = Boolean(event.id.startsWith("fictitious"));

          return (
            <div
              key={event.id + i}
              className="flex w-full gap-2"
            >
              <li
                onClick={() => setEventId(event.id)}
                className="w-full"
              >
                <Checkbox
                  theme={theme}
                  checked={eventId === event.id}
                />

                <img
                  src={videoIcon}
                  className="icon"
                />
                {format(parseISO(event.start), "EEE, dd-M H:mm")}

                {isEnd && <span className="ended">Ended</span>}
                {isFictitiousEvent && <span className="boh">Boh</span>}
              </li>

              <Button
                component={"a"}
                href={event.link}
                target="_blank"
                variant="outlined"
                size="small"
              >
                <DuoIcon />
              </Button>
            </div>
          );
        })}
      </ul>

      <Box className="mt-5 text-center">
        {eventId && (
          <Button
            size="small"
            variant="outlined"
            onClick={() => setStep(2)}
          >
            <img
              src={nextIcon}
              className="icon"
            />
            Next
          </Button>
        )}

        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setStep(3);
            setIsFictitious(true);
          }}
          fullWidth
        >
          create new meet (boh)
        </Button>
      </Box>
    </Box>
  );
};

const Step2 = ({
  data,
  theme,
  status,
  setStep,
  eventId,
  setStatus,
  functions,
  isUpselling,
  setOpenCalendarEvents,
}) => {
  const handleSave = () => {
    if (
      [
        "Da richiamare",
        "Colloquio fissato",
        STATUS.UpsellingScheduled,
      ].includes(status)
    ) {
      setOpenCalendarEvents(true);
    } else if (
      [STATUS.UpsellingDone, STATUS.StandBy, "Colloquio fatto"].includes(status)
    ) {
      setStep(3);
    } else {
      functions?.onUpdateRow?.({
        toast: "status",
        tokenUser: data.user.token,
        values: { status: isUpselling ? status : "Assente al colloquio" },
      });
    }
  };

  return (
    <Box>
      <ul className="listEvents">
        {(Object.entries(data.buttons) as any).map(
          ([key, value]: any, i: number) => {
            return (
              <li
                key={key + i}
                onClick={() => setStatus(value.status)}
              >
                <Checkbox
                  checked={status === value.status}
                  theme={theme}
                />

                {value?.icon && (
                  <img
                    src={value.icon}
                    className="icon"
                  />
                )}
                {value?.name ? value?.name : value.status}
              </li>
            );
          }
        )}
      </ul>

      <Box className="mt-5 text-center">
        {eventId && (
          <>
            <Button
              sx={{ marginRight: "10px" }}
              variant="outlined"
              size="small"
              onClick={() => setStep((p: number) => p - 1)}
            >
              <img
                src={backIcon}
                className="icon"
              />
              Back
            </Button>

            {status && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleSave}
              >
                {[
                  STATUS.UpsellingDone,
                  STATUS.StandBy,
                  "Colloquio fatto",
                ].includes(status) ? (
                  <>
                    <img
                      src={nextIcon}
                      className="icon"
                    />
                    Next
                  </>
                ) : (
                  <>
                    <img
                      src={saveIcon}
                      className="icon"
                    />
                    Save
                  </>
                )}
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

const Step3 = ({
  data,
  status,
  eventId,
  setStep,
  functions,
  otherEvent,
  isAdmission,
  isFictitious,
  selectedEvent,
  dataGetToogle,
  BASE_PAYMENT_LINK,
  newStartingMonths,
  keysFormMeetingDone,
  handleSetFormMeetingDone,
}) => {
  const order_tk = data.buttons?.meetingDone.form?.order_tk ?? undefined;

  const { data: products } = useQuery({
    queryKey: ["products", data?.user?.lang, data?.user?.tutoring],
    queryFn: () => client.product.listByLanguage(data.user.lang, data?.user?.tutoring),
    enabled: Boolean(data?.user?.lang),
  })

  useEffect(() => {
    if (order_tk) {
      handleSetFormMeetingDone({
        target: { name: "order_tk", value: order_tk },
      });
    }
  }, [order_tk]);

  return (
    <Box
      onSubmit={(e) => {
        e.preventDefault();
        if (otherEvent || isFictitious) {
          const { payment_link, ...filteredParams } =
            data.buttons?.meetingDone?.form || {};

          functions?.onMeetingDoneOther?.({ ...filteredParams });
        } else {
          functions?.onMeetingDone?.(selectedEvent, eventId, status);
        }
      }}
      component="form"
      className="flex flex-col gap-5"
    >
      {keysFormMeetingDone.includes("pacchetto") && (
        <TextField
          select
          required
          fullWidth
          name="pacchetto"
          label={
            [STATUS.UpsellingDone].includes(status)
              ? "Product"
              : "Product of interest"
          }
          value={
            data.buttons?.meetingDone?.form?.pacchetto ??
            selectedEvent?.pacchetto ??
            ""
          }
          onChange={(e) => {
            handleSetFormMeetingDone(e);

            if (data.buttons?.meetingDone?.form.payment_link !== undefined) {
              data.buttons?.meetingDone?.setForm(
                "payment_link",
                BASE_PAYMENT_LINK + e.target.value
              );
            }
          }}
        >
          {
            products?.item?.map((product) => (
              <MenuItem
                key={product.id}
                value={product.token}
              >
                <Tooltip title={product.product_tag}>
                  <Box className="flex gap-2 justify-between items-center w-full">

                    <Box className="truncate mr-2">
                      <FlagRender
                        flag={product?.prices?.country}
                        className="w-[22px]"
                      />
                    </Box>

                    <Box className="truncate mr-2 w-[350px]">{product.product_tag}</Box>

                    <Box>{product?.prices?.currency} {product?.prices?.price}</Box>
                  </Box>
                </Tooltip>
              </MenuItem>
            ))
          }

        </TextField>
      )}

      {Boolean(keysFormMeetingDone.includes("english_lvl") && !isAdmission) && (
        <TextField
          select
          required
          fullWidth
          size="small"
          name="english_lvl"
          label="English level"
          value={
            data.buttons?.meetingDone.form.english_lvl ??
            selectedEvent?.english_lvl ??
            ""
          }
          onChange={handleSetFormMeetingDone}
        >
          {["A0", "A1", "A2", "B1", "B1+", "B2-", "B2", "B2+", "C1"].map(
            (level: string, index: any) => (
              <MenuItem
                key={level + index}
                value={level}
              >
                {level}
              </MenuItem>
            )
          )}
        </TextField>
      )}

      {Boolean(
        keysFormMeetingDone.includes("certification") && !isAdmission
      ) && (
          <TextField
            select
            required
            fullWidth
            name="certification"
            label="Interest for Certificate"
            value={
              data.buttons?.meetingDone.form.certification ??
              selectedEvent?.certification ??
              ""
            }
            onChange={(e) => handleSetFormMeetingDone(e)}
          >
            {["YES", "NO"].map((level, index: any) => (
              <MenuItem
                key={level + index}
                value={level}
              >
                {level}
              </MenuItem>
            ))}
          </TextField>
        )}

      {keysFormMeetingDone.includes("motivation") && (
        <TextField
          select
          required
          fullWidth
          name="motivation"
          label="Willingness to participate"
          value={
            data.buttons?.meetingDone.form.motivation ??
            selectedEvent?.motivation ??
            ""
          }
          onChange={handleSetFormMeetingDone}
        >
          {["Will buy", "Would buy", "Interested", "Rejected"].map(
            (level, index: any) => (
              <MenuItem
                key={level + index}
                value={level}
              >
                {level}
              </MenuItem>
            )
          )}
        </TextField>
      )}

      {keysFormMeetingDone.includes("newStartingMonth") &&
        ([STATUS.UpsellingDone].includes(status) || otherEvent) && (
          <TextField
            select
            required
            fullWidth
            name="newStartingMonth"
            label="New starting month"
            value={
              data.buttons?.meetingDone.form.newStartingMonth ??
              selectedEvent?.newStartingMonth ??
              ""
            }
            onChange={handleSetFormMeetingDone}
          >
            {newStartingMonths?.map((month: string, i: number) => {
              const v = month.split(" ");

              return (
                <MenuItem
                  key={i + month}
                  value={`${MONTHS[v[0]]} ${v[1]}`}
                >
                  {month}
                </MenuItem>
              );
            })}
          </TextField>
        )}

      {keysFormMeetingDone.includes("notes") && (
        <TextField
          multiline
          fullWidth
          id="outlined-required"
          rows={3}
          size="small"
          name="notes"
          label="Notes"
          value={
            data.buttons?.meetingDone.form.notes ?? selectedEvent?.notes ?? ""
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleSetFormMeetingDone(e)
          }
          placeholder="Write here a note..."
        />
      )}

      <Box className="text-center">
        {!otherEvent && (
          <Button
            sx={{ marginRight: "10px" }}
            size="small"
            variant="outlined"
            onClick={() => setStep((p: number) => p - 1)}
          >
            <img
              src={backIcon}
              className="icon"
            />
            Back
          </Button>
        )}

        <Button
          size="small"
          type="submit"
          variant="outlined"
        >
          <img
            src={saveIcon}
            className="icon"
          />
          Save
        </Button>
      </Box>
    </Box>
  );
};

const MeetingEditVideoCallInfo = ({
  data,
  status,
  eventId,
  products,
  setStatus,
  functions,
  setEventId,
  isUpselling,
  dataGetToogle,
  selectedEvent,
  meetingsSorted,
  newStartingMonths,
  BASE_PAYMENT_LINK,
  keysFormMeetingDone,
  setOpenCalendarEvents,
  handleSetFormMeetingDone,
}) => {
  const theme = useTheme();
  const { isAdmission, isAdmin } = useAuth();

  const event = meetingsSorted.find((m: any) => m.id === eventId);
  const otherEvent = !Boolean(meetingsSorted?.length) && isAdmin;

  const [step, setStep] = useState<number>(1);
  const [isFictitious, setIsFictitious] = useState<boolean>(false);

  return (
    <Box className="flex flex-col w-full">
      <Typography
        color="primary"
        fontWeight="bold"
      >
        {otherEvent
          ? "Other event"
          : eventId && step > 1
            ? format(parseISO(event.start), "EEE, dd-M H:mm")
            : "Select an event"}
      </Typography>

      <SelectEvent>
        {Boolean(meetingsSorted?.length) && step === 1 && (
          <Step1
            {...{
              meetingsSorted,
              setEventId,
              eventId,
              theme,
              setStep,
              setIsFictitious,
            }}
          />
        )}

        {Boolean(meetingsSorted?.length) && step === 2 && (
          <Step2
            {...{
              data,
              theme,
              status,
              setStep,
              eventId,
              functions,
              setStatus,
              isUpselling,
              setOpenCalendarEvents,
            }}
          />
        )}

        {(otherEvent ||
          isFictitious ||
          (Boolean(meetingsSorted?.length) && step === 3)) && (
            <Step3
              {...{
                data,
                status,
                setStep,
                eventId,
                products,
                functions,
                otherEvent,
                isAdmission,
                dataGetToogle,
                isFictitious,
                selectedEvent,
                BASE_PAYMENT_LINK,
                newStartingMonths,
                keysFormMeetingDone,
                handleSetFormMeetingDone,
              }}
            />
          )}
      </SelectEvent>
    </Box>
  );
};

const SelectEvent = styled("div")(({ theme }) => ({
  marginTop: 10,
  padding: 15,
  width: "100%",
  minHeight: "250px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.background.paper,
  "ul.listEvents": {
    minHeight: "200px",
    li: {
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      padding: 10,
      cursor: "pointer",
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.leftSideBarSub.main,
      ".icon": {
        filter: theme.palette.filter,
        marginRight: 15,
      },
      ".ended": {
        borderRadius: "3px",
        background: "#FF8C8C",
        color: "#8D0303",
        fontSize: "8px",
        height: "15px",
        lineHeight: "15px",
        padding: "0px 10px",
        marginLeft: "auto",
      },
      ".boh": {
        borderRadius: "3px",
        background: "#8c9aff",
        color: "#8D0303",
        fontSize: "8px",
        height: "15px",
        lineHeight: "15px",
        padding: "0px 10px",
        marginLeft: "2px",
      },
    },
    "li + li": {
      marginTop: 10,
    },
  },
}));

export default MeetingEditVideoCallInfo;
