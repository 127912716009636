import Request from "./RequestAdmin";

export const getCrmData = async (token, params) => {
  const { data } = await Request.call(
    {
      url: "/v2/crm",
      method: "GET",
      params,
    },
    token
  );

  return data;
};

export const downloadCrmData = async (
  token,
  params,
  fileName = "export.csv"
) => {
  const { data: res } = await Request.download(
    {
      url: "/v2/crm",
      method: "GET",
      params,
    },
    token,
    fileName
  );

  return res;
};

export const updateCrmData = async ({ token, id, data, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/crm/${id}`,
      method: "PUT",
      data,
    },
    token
  );

  if (callback) callback();

  return result;
};

export const moveCrmToUpselling = async ({ token, id, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v2/crm/move-to-upselling/${id}`,
      method: "POST",
    },
    token
  );

  if (callback) callback();

  return result;
};

export const confirmPayment = async ({ token, data, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/confirm-payment-v2`,
      method: "POST",
      data,
    },
    token
  );

  if (callback) callback(result);

  return result;
};

export const createCrm = async ({ token, data, callback }) => {
  const result = await Request.call(
    {
      url: `/v1/crm`,
      method: "POST",
      data,
    },
    token
  );

  if (callback) callback(result?.data ?? result?.response?.data);

  return result?.data ?? result?.response?.data;
};

export const sendMailCrm = async ({ token, data, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/crmMail`,
      method: "POST",
      data,
    },
    token
  );

  if (callback) callback(result);

  return result;
};

export const addEventInterested =  ({ token, data }) => {
  return Request.api(
    {
      url: `/v1/calendar-events/create-event-interested-future`,
      method: "POST",
      data,
    },
    token
  );
};
