import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./providers/AuthProvider";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import NotificationProvider from "./providers/notifications/NotificationProvider";
import App from "./App";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';

// Memoize query client options to prevent unnecessary recreations
const queryDefaultOptions = {
  defaultOptions: {
    queries: {
      staleTime: 10_000,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // Consider adding more global query options like:
      // suspense: true, // If using React Suspense
      // refetchInterval: false
    },
  },
};

export const queryClient = new QueryClient(queryDefaultOptions);

// Use a more robust method to ensure content is loaded only once
const renderApp = () => {
  const container = document.getElementById("root");

  if (container && !container.dataset.initialized) {
    const root = createRoot(container);

    root.render(
      <StrictMode>
        <AuthProvider>
          <>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools position="bottom-right" />
              </QueryClientProvider>
            </NotificationProvider>
            <ToastContainer />
          </>
        </AuthProvider>
      </StrictMode>
    );

    // Mark container as initialized to prevent multiple renders
    container.dataset.initialized = 'true';
  }
};

// Use more modern event handling
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', renderApp);
} else {
  renderApp();
}