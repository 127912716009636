import { useMemo } from "react";
import useAuth from '@/hooks/useAuth';
import { Box, Typography } from "@mui/material";
import { convert, fUpper } from "../useHelpers";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import ViewContactIcon from "@/assets/icons/view-contact.svg";
import TimelineGen from "../Crm/TimelineGen";

const PersonalDetails = ({ user }) => (
  <Box className="flex flex-col gap-2">
    <Typography
      color="primary"
      variant="h6"
      component="h2"
    >
      Personal details
    </Typography>

    {["first_name", "last_name", "email", "phone", "status", "languages"].map(
      (k, i) => (
        <Typography key={k}>
          {!i ? "First name" : i === 1 ? "Last name" : fUpper(k)}:{" "}
          {k == "languages" ? (
            <b>{user[k] ? user[k].join(", ") : "N/A"}</b>
          ) : (
            <b>{user[k] ? user[k] : "N/A"}</b>
          )}
        </Typography>
      )
    )}

    <Typography>
      Registration date:{" "}
      <b>
        {new Date(user.created_at)
          .toLocaleString("it-IT")
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
      </b>
    </Typography>
  </Box>
);

const LastActions = ({ user }) => {
  const lastMeetingScheduled = useMemo(
    () =>
      convert(user.updates)
        ?.reverse()
        ?.find((u: any) => u.status == "Screening scheduled")?.admin,
    [user]
  );

  return (
    <Box className="w-full mt-6 md:mt-0">
      <Typography
        color="primary"
        variant="h6"
        component="h2"
        className="w-full m-auto text-center"
      >
        Last actions
      </Typography>

      <TimelineGen data={convert(user.updates)?.slice(-9)} />

      {Boolean(lastMeetingScheduled) && (
        <Box className="w-full m-auto pt-4 text-center">
          Last Meeting scheduled:{" "}
          <Typography
            color="primary"
            className="!inline-block"
          >
            {lastMeetingScheduled}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const QuestionsForm = ({ user }) => {
  const { snackHandler } = useAuth();

  return (
    <Box className="flex flex-col gap-2 mt-5">
      <Typography
        color="primary"
        variant="h6"
        component="h2"
      >
        Questions form
      </Typography>

      <div className="max-h-80 h-full overflow-auto">
        {user.content?.map(
          (
            questionObj: {
              question: string;
              elements: {
                type: string;
                value: string;
                label: string;
              }[];
            },
            i: number
          ) =>
            i >= 5 && (
              <div
                key={i}
                className="mb-2"
              >
                <Typography>
                  <b>{questionObj.question}</b>
                </Typography>
                {questionObj.elements?.map((el, j) => (
                  <Typography
                    key={j}
                    title={
                      el?.value?.toString() == "true" ? el.label : el.value
                    }
                    // onClick={() => snackHandler(el.value)}
                    className="truncate"
                  >
                    {el.label ? `${el.label}: ` : ""}
                    <b>{el?.value?.toString() == "true" ? "Si" : el.value}</b>
                  </Typography>
                ))}
              </div>
            )
        )}
      </div>
    </Box>
  );
};

const ViewInfoDialog = ({ open, onClose, user }) => {
  if (!user) return null;

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <EduModalTitle onClose={onClose}>
        <img src={ViewContactIcon} />
        APPLICANT INFORMATION
      </EduModalTitle>

      <EduModalBody className="md:grid md:grid-cols-2 px-6 pt-5 pb-6">
        <Box>
          <PersonalDetails {...{ user }} />

          {Boolean(user.content) && <QuestionsForm {...{ user }} />}
        </Box>

        {Boolean(user.updates) && <LastActions {...{ user }} />}
      </EduModalBody>
    </EduModal>
  );
};

export default ViewInfoDialog;
