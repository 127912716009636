import { DatePicker } from "@mui/x-date-pickers";
import { FormHelperText, TextField } from "@mui/material";
import dayjs from "dayjs";

const DayOffModalFullDay = ({ formik }) => {
  return (
    <>
      <DatePicker
        disablePast
        views={["day"]}
        value={
          formik.values?.startTime ? dayjs(formik.values?.startTime) : null
        }
        openTo="day"
        onChange={(newValue) => {
          formik.setFieldValue(
            "startTime",
            newValue ? dayjs(newValue).format("YYYY-MM-DD 00:00:00") : null
          );
        }}
        inputFormat="DD/MM/YYYY"
        renderInput={(params) => (
          <TextField
            {...params}
            required
            fullWidth
            error={false}
            label="Start date"
          />
        )}
      />

      {Boolean(formik.touched.startTime && formik.errors.startTime) && (
        <FormHelperText
          error={formik.touched.startTime && Boolean(formik.errors.startTime)}
        >
          {formik.touched.startTime && formik.errors.startTime}
        </FormHelperText>
      )}

      <DatePicker
        disablePast
        views={["day"]}
        value={formik.values?.endTime ? dayjs(formik.values?.endTime) : null}
        openTo="day"
        onChange={(newValue) => {
          formik.setFieldValue(
            "endTime",
            newValue ? dayjs(newValue).format("YYYY-MM-DD 23:59:59") : null
          );
        }}
        inputFormat="DD/MM/YYYY"
        renderInput={(params) => (
          <TextField
            {...params}
            error={false}
            required
            fullWidth
            label="End date"
          />
        )}
      />

      {Boolean(formik.touched.endTime && formik.errors.endTime) && (
        <FormHelperText
          error={formik.touched.endTime && Boolean(formik.errors.endTime)}
        >
          {formik.touched.endTime && formik.errors.endTime}
        </FormHelperText>
      )}
    </>
  );
};

export default DayOffModalFullDay;
