import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { addMonths, format } from "date-fns";
import { Avatar, Box, LinearProgress, Tab, Tabs, Toolbar } from "@mui/material";
import FormUser from "./components/FormUser";
import useUserApi from "@/api/useUserApi";
import InvoiceCard from "./components/InvoiceCard";
// import FeedbackUser from "./components/FeedbackUser";
import ExercisesUser from "./components/ExercisesUser";
import UserAttendance from "./components/UserAttendance";

export const MONTH_LIST = {
  January: "Gennaio",
  February: "Febbraio",
  March: "Marzo",
  April: "Aprile",
  May: "Maggio",
  June: "Giugno",
  July: "Luglio",
  August: "Agosto",
  September: "Settembre",
  October: "Ottobre",
  November: "Novembre",
  December: "Dicembre",
};

export const ENGLISH_LEVEL = [
  "A0-",
  "A0",
  "A1",
  "A2-",
  "A2",
  "A2+",
  "B1-",
  "B1",
  "B1+",
  "B2-",
  "B2",
  "B2+",
  "C1",
  "Non classificato",
];

export interface User {
  id: number | string;
  first_name: string;
  last_name: string;
  email: string;
  token: number;
  birth_date: Date;
  phone: string;
  img: string;
  payments: Array<Object>;
  english_level: string;
  stato: string;
  start_month: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserSingle = () => {
  let { id } = useParams();

  if (!id) return;

  const [form, setForm] = useState({} as any);
  const [tabValue, setTabValue] = useState(0);
  const [user, setUser] = useState({} as User);
  const [allDate, setAllDate] = useState([] as string[]);

  const meseInizioRef = useRef(null);

  const { callGetUserWithDates, callUpdateUser } = useUserApi();
  const { data, isLoading } = callGetUserWithDates(id);

  const dates = (userMonth) => {
    let startDate = addMonths(new Date(), -2);

    let endDate = addMonths(new Date(), 12);

    let monthsYears: string[] = [];

    while (startDate <= endDate) {
      let formattedDate = format(startDate, "MMMM yyyy");
      const month = MONTH_LIST[formattedDate.split(" ")[0]];
      const year = formattedDate.split(" ")[1];

      monthsYears.push(`${month} ${year}`);

      startDate = addMonths(startDate, 1);
    }

    if (!monthsYears.includes(userMonth)) {
      monthsYears.unshift(userMonth);
    }

    monthsYears.push("Giugno 2028");

    setAllDate(monthsYears);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let userToUpdate = { ...form };
    delete userToUpdate.payments;

    if (id) callUpdateUser.mutate({ id, data: userToUpdate });
  };

  const handleUserState = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name !== "start_month") {
      setForm((prev) => ({ ...prev, [name]: value }));
      return;
    }

    const date = value.trim().split(" ");
    const meseInizio = `${date[0]} ${date[1]}`;
    setForm((prev) => ({
      ...prev,
      ["data_scelta"]: meseInizio,
      [name]: meseInizio,
    }));
  };

  useEffect(() => {
    if (!data?.user) return;
    setUser(data?.user[0]);
    dates(data?.user[0].start_month);

    return () => {
      setUser({} as User);
      setAllDate([] as string[]);
    };
  }, [data]);

  if (isLoading) return <LinearProgress />;

  return (
    <Box className="px-2 sm:px-2 md:px-20">
      <Toolbar />
      <Box className="flex gap-5 flex-row items-center">
        <Avatar
          src={
            Boolean(user?.img)
              ? "https://academy.edusogno.com/homepage/profile_pic/" + user.img
              : ""
          }
          sx={{ width: 80, height: 80 }}
        />
        <Box>
          <div className="font-bold">{user?.email}</div>
          <div className=" text text-sm">token: {user?.token}</div>
        </Box>
      </Box>

      <Box
        className="my-5"
        sx={{ borderBottom: "1px solid rgb(45, 55, 72)" }}
      >
        <Tabs
          component="div"
          sx={{
            "& .MuiTab-textColorPrimary": {
              // color: "#fff",
            },
            "& .MuiTabs-indicator": {
              // backgroundColor: "rgb(16, 185, 129)",
              height: 3,
            },
            "& .Mui-selected": {
              // color: "rgb(16, 185, 129)",
            },
          }}
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            component="div"
            label="Modify info"
            {...a11yProps(0)}
          />

          <Tab
            component="div"
            label="Payments"
            {...a11yProps(1)}
          />

          <Tab
            component="div"
            label="Presence"
            {...a11yProps(2)}
          />

          <Tab
            component="div"
            label="Feedback"
            {...a11yProps(3)}
          />

          <Tab
            component="div"
            label="Exercises"
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>

      <TabPanel
        index={tabValue}
        value={0}
      >
        <FormUser
          handleUpdate={handleUpdate}
          form={form}
          user={user}
          handleUserState={handleUserState}
          ENGLISH_LEVEL={ENGLISH_LEVEL}
          meseInizioRef={meseInizioRef}
          allDate={allDate}
          MONTH_LIST={MONTH_LIST}
          updateUser={callUpdateUser}
        />
      </TabPanel>

      <TabPanel
        index={tabValue}
        value={1}
      >
        <InvoiceCard
          user={user}
          loading={false}
        />
      </TabPanel>

      <TabPanel
        index={tabValue}
        value={2}
      >
        <UserAttendance
          user={user}
          isLoading={false}
        />
      </TabPanel>

      <TabPanel
        index={tabValue}
        value={3}
      >
        {/* <FeedbackUser user={user} /> */}
      </TabPanel>

      <TabPanel
        index={tabValue}
        value={4}
      >
        <ExercisesUser
          user={user}
          loading={false}
        />
      </TabPanel>
    </Box>
  );
};

export default UserSingle;
