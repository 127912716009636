import { useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { getCookie } from "../Cookie";
import { mergeByKey } from "../useHelpers";
import { Link, useLocation } from "react-router-dom";
import { Box, Tooltip, useTheme } from "@mui/material";
import { SideBarComponent, SubMenuComponent } from "./styles";
import cs from "classnames";
import Menu from "./Menu";
import Logout from "./Logout";
import ChangeTheme from "./ChangeTheme";
import BlackLogoIcon from "@/assets/logoIconBlack.svg";
import WhiteLogoIcon from "@/assets/logoIconWhite.svg";
import NotificationBell from "./NotificationBell";
// import AdminNotifications from "./AdminNotifications";
import PopoverChangeTypeOF from "./PopoverChangeTypeOf";

const Sidebar = () => {
  const theme = useTheme();
  const pathname = useLocation().pathname;
  const {
    links,
    typeOf,
    userAdmin,
    currentTheme,
    setUserAdmin,
    statusSideBar,
  } = useAuth();

  const [menu, setMenu] = useState<Array<any>>(Menu(theme));
  const [routes, setRoutes] = useState<Array<any>>([]);
  const [subMenu, setSubMenu] = useState<Array<any>>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isGeneral, setIsGeneral] = useState<boolean>(false);
  const [menuGeneral, setMenuGeneral] = useState<Array<any>>(Menu(theme));
  const [menuSelected, setMenuSelected] = useState<string>("English");
  const [subMenuSelected, setSubMenuSelected] = useState<string>("/crm");

  const showContentBaseOnSideBar = statusSideBar === "open";

  const impersonateOld = getCookie("user-old");

  useEffect(() => {
    const path = pathname === "/" ? "/crm" : pathname;
    setSubMenuSelected(path || "/crm");

    const activeLink: any = links.find((f: any) => f.path === path);
    setMenuSelected(activeLink?.group || "Sales");
  }, [pathname]);

  useEffect(() => {
    if (routes?.length) return;

    // Ottiene le route in base al ruolo dell'utente
    let routes2: Array<any> = Number(userAdmin?.user?.admin)
      ? links
      : userAdmin.user.routes;

    // Imposta l'attributo 'active' di ogni route in base al ruolo dell'utente
    routes2 = routes2.map((route: any) => {
      const data: any = {
        name: route.name,
        active: Boolean(Number(userAdmin.user.admin))
          ? true
          : [true, 1, "true"].includes(route?.active),
      };

      if (route?.limitByTypeOf) {
        data.limitByTypeOf = route.limitByTypeOf;
      }

      return data;
    });

    // Copia l'oggetto 'userAdmin' in una variabile temporanea
    const temp: any = { ...userAdmin };

    // Aggiunge le nuove route all'oggetto 'userAdmin'
    temp.user.routes = [
      ...(Number(userAdmin?.user?.admin) ? temp.user.routes : []),
      ...routes2,
    ];

    // Aggiorna lo stato 'userAdmin' con l'oggetto temporaneo
    setUserAdmin((prev: { state: { routes: any } }) => ({ ...prev, ...temp }));

    // Combina le route autorizzate dall'utente con le route di default
    const authorisedRoutes: Array<any> = mergeByKey(routes2, links, "name");

    setRoutes(authorisedRoutes);
  }, [userAdmin?.user?.admin]);

  useEffect(() => {
    if (!routes) return;

    const menuChildren = {
      normal: [] as Array<any>,
      general: [] as Array<any>,
    };

    routes.forEach((route) => {
      if (route.active === true) {
        if (route?.typeOf?.includes(typeOf) || route?.typeOf?.includes("all")) {
          menuChildren.normal.push(route);
        }

        if (route?.typeOf?.includes("general")) {
          menuChildren.general.push(route);
        }
      }
    });

    const menuFather = {
      normal: [] as Array<any>,
      general: [] as Array<any>,
    };

    Menu(theme).forEach((route: any) => {
      if (
        menuChildren.normal.some(
          (c) => !c?.typeOf?.includes("general") && c.group === route.group
        )
      ) {
        menuFather.normal.push(route);
      }

      if (
        menuChildren.general.some(
          (c) => c?.typeOf?.includes("general") && c.group === route.group
        )
      ) {
        menuFather.general.push(route);
      }
    });

    const updateMenu = (source: Array<any>, setTarget: any) => {
      setTarget(() =>
        source.map((f: any) => ({
          ...f,
          active: Boolean(
            routes.find(
              (r) =>
                r.group === f.title &&
                r.active === true &&
                (!r?.limitByTypeOf?.length ||
                  r?.limitByTypeOf?.includes(typeOf))
            )
          ),
        }))
      );
    };

    updateMenu(menuFather.normal, setMenu);

    updateMenu(menuFather.general, setMenuGeneral);

    const subMenu = routes.filter(
      (f) =>
        (f?.group === menuSelected &&
          f?.active === true &&
          (f?.typeOf?.includes(typeOf) ||
            f?.typeOf?.includes("all") ||
            f?.typeOf?.includes("general")) &&
          (!f?.limitByTypeOf?.length || f?.limitByTypeOf?.includes(typeOf))) ||
        (f?.role && f?.group === menuSelected)
    );

    setSubMenu(subMenu);
  }, [routes, menuSelected, typeOf, isGeneral, currentTheme]);

  const renderButton = (data: any, version: boolean = false) => (
    <button
      className="rounded w-full flex justify-center items-center"
      onClick={() => {
        setIsGeneral(version);
        setMenuSelected(data.group);
      }}
    >
      {data.icon}
    </button>
  );

  const renderButtonBelow = (data: any, version: boolean = false) => (
    <button
      onClick={() => {
        setIsGeneral(version);
        setMenuSelected(data.group);
      }}
      className="flex justify-center items-center"
    >
      {data.icon}
    </button>
  );

  const renderButtonSubMemnu = (data: {
    icon: any;
    name: string;
    path: string;
    isBeta?: boolean;
  }) => (
    <button
      className={cs("subMenuItem", {
        active: subMenuSelected === data.path,
        "bg-orange-300/20": data?.isBeta,
      })}
    >
      {showContentBaseOnSideBar ? (
        <>
          <div>{data.icon}</div>
          <span>{data.name}</span>
        </>
      ) : (
        data.icon
      )}
    </button>
  );

  const Logo = () => (
    <img src={theme.palette.mode === "light" ? BlackLogoIcon : WhiteLogoIcon} />
  );

  const renderSideBar = () => {
    const popupChangeTypeOf = Boolean(
      routes.find((r) => r.name === "English - Admission" && r.active === true)
    );

    return (
      <SideBarComponent>
        <Box className="MainMenu">
          <Box>
            <Box className="logo">
              {popupChangeTypeOf ? (
                <button
                  title="Change typeOf: English - Admission"
                  onClick={(e: any) => setAnchorEl(e.currentTarget)}
                >
                  <Logo />
                </button>
              ) : (
                <Logo />
              )}
            </Box>

            {menu?.map((m, i) =>
              Boolean(m.active) ? (
                <Box
                  key={i}
                  className={cs(
                    "MenuItem",
                    menuSelected === m.title && !isGeneral && "active"
                  )}
                >
                  <Tooltip
                    title={m.title}
                    placement="right"
                  >
                    {renderButton(m)}
                  </Tooltip>
                </Box>
              ) : null
            )}
          </Box>

          <Box className="UserSetting flex flex-col items-center gap-3">
            {menuGeneral?.map((m, i) =>
              Boolean(m.active) ? (
                <Tooltip
                  key={m.title + i}
                  title={m.title}
                  placement="right"
                >
                  {renderButtonBelow(m, true)}
                </Tooltip>
              ) : null
            )}

            <NotificationBell />

            <ChangeTheme />

            {/* <Settings /> */}

            {/* <UserProfile /> */}

            {!impersonateOld && <Logout />}
          </Box>
        </Box>

        <Box
          className={cs(
            "SubMenu",
            showContentBaseOnSideBar === false && "closed"
          )}
        >
          <SubMenuComponent>
            <Box
              style={{
                maxHeight: showContentBaseOnSideBar ? "100%" : "30px",
                visibility: showContentBaseOnSideBar ? "inherit" : "hidden",
              }}
              className="title"
            >
              {menuSelected}
            </Box>

            <Box className="menuContainer">
              {subMenu.map((s, i) =>
                s?.role == "divisor" && subMenu.length - 1 != i ? (
                  // if exist first divisor, remove next duplicate divisor after first, not after button
                  subMenu?.[i - 1]?.role &&
                    subMenu?.[i - 1]?.role == "divisor" ? null : (
                    <Box
                      key={i}
                      className="w-full border-t-2 border-gray-600/50 mt-1"
                    />
                  )
                ) : (
                  <Link
                    to={s.path}
                    key={i}
                  >
                    {showContentBaseOnSideBar ? (
                      renderButtonSubMemnu(s)
                    ) : (
                      <Tooltip
                        title={s.name}
                        placement="right"
                      >
                        {renderButtonSubMemnu(s)}
                      </Tooltip>
                    )}
                  </Link>
                )
              )}
            </Box>
          </SubMenuComponent>
        </Box>

        <PopoverChangeTypeOF
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
        />
      </SideBarComponent>
    );
  };

  return Number(routes?.length) ? renderSideBar() : <></>;
};

export default Sidebar;
