import { createRef, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { CustomToolbar } from "./Toolbar";
import { Box, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import AVGPriceDialog, {
  IRef as IRefAVGPriceDialog,
} from "./components/AVGPriceDialog";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import usePagination from "@/hooks/usePagination";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

interface IProps {
  version?: string;
}

const Interviews = ({ version = "normal" }: IProps) => {
  const theme = useTheme();
  const { screen, perPage } = useScreen();
  const { isAdmission, isMember, copyToClipboard, userAdmin } = useAuth();

  const isUpselling = version == "upselling";

  const refAVGPriceDialog = createRef<IRefAVGPriceDialog>();

  const KEY_QUERY = ["interview-performance", "kpis-meetings", isAdmission];

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [columnsHeaders, setColumnsHeaders] = useState<any>({});
  const [meetingCalendar, setMeetingCalendar] = useState<Array<string>>([]);

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });
  const { page, setPage } = usePagination({ filters, setFilters });

  delete filterData?.page;
  let KEY_QUERY_DATE: any = [...KEY_QUERY, "", "", filterData, version];
  let params: any = {};

  if (filterData?.dates) {
    const date = filterData?.dates.split(",");

    const startDate = format(new Date(date[0]), "yyyy-MM-dd HH:mm:ss");
    const endDate = format(new Date(date[1]), "yyyy-MM-dd HH:mm:ss");

    params = { ...params, startDate, endDate };

    KEY_QUERY_DATE = [...KEY_QUERY, startDate, endDate, filterData];
  }

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY_DATE,
    () =>
      useAPI("/v1/interview-performance/kpis-meetings", {
        ...params,
        ...(filterData ?? {}),
        ...(isMember
          ? {
            admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
          }
          : {}),
        isAdmission,
        isUpselling,
      }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const renderHeader = (nameColumn: string) => ({
    renderHeader: ({ field }) => (
      <Box className="MuiDataGrid-columnHeaderTitle ">
        {Boolean(columnsHeaders?.[field]) && (
          <span className="number">{columnsHeaders?.[field]}</span>
        )}
        {nameColumn}
      </Box>
    ),
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        minWidth: 180,
        flex: 1,
        headerName: "Admin",
      },
      {
        field: "meeting_scheduled",
        minWidth: 170,
        flex: 1,
        headerName: "Meeting scheduled",
        ...(isMember ? {} : renderHeader("Meeting scheduled")),
      },
      {
        field: "meeting_done",
        minWidth: 120,
        flex: 1,
        headerName: "Meeting done",
        ...(isMember ? {} : renderHeader("Meeting done")),
      },
      {
        field: "sale",
        minWidth: 60,
        flex: 1,
        headerName: "Sale",
        ...(isMember ? {} : renderHeader("Sale")),
      },
      {
        field: "interview_conversion",
        minWidth: 190,
        flex: 1,
        headerName: "Interview conversion",
        ...(isMember ? {} : renderHeader("Interview conversion")),
      },
      {
        field: "meeting_done_rescheduled",
        minWidth: 220,
        flex: 1,
        headerName: "Meeting done rescheduled",
        ...(isMember ? {} : renderHeader("Meeting done rescheduled")),
      },
      {
        field: "avg_price_proposed",
        minWidth: 170,
        flex: 1,
        headerName: "AVG price proposed",
        ...(isMember ? {} : renderHeader("AVG price proposed")),
        valueGetter: ({ row }) => row?.avg_price_proposed + "€",
        renderCell: ({ row }) =>
          row?.avg_price_proposed ? (
            <button
              onClick={() =>
                refAVGPriceDialog?.current?.open(
                  row?.avg_price_proposed_products,
                  "proposed - " + row?.name
                )
              }
            >
              {row?.avg_price_proposed}€
            </button>
          ) : (
            row?.avg_price_proposed + "€"
          ),
      },
      {
        field: "avg_price_sale",
        minWidth: 130,
        flex: 1,
        headerName: "AVG price sale",
        ...(isMember ? {} : renderHeader("AVG price sale")),
        valueGetter: ({ row }) => row?.avg_price_sale + "€",
        renderCell: ({ row }) =>
          row?.avg_price_sale ? (
            <button
              onClick={() =>
                refAVGPriceDialog?.current?.open(
                  row?.avg_price_sale_products,
                  "sale - " + row?.name
                )
              }
            >
              {row?.avg_price_sale}€
            </button>
          ) : (
            row?.avg_price_sale + "€"
          ),
      },
    ],
    [screen, columnsHeaders, filterData]
  );

  useEffect(() => {
    if (isLoading) return;
    setRows(dataAPI?.rows);
    setColumnsHeaders(dataAPI?.columns);
    setMeetingCalendar(dataAPI?.meeting_calendar);
  }, [dataAPI, isLoading]);

  return (
    <BasePage
      title={
        "KPIs Meetings" +
        (isAdmission ? " admission" : isUpselling ? " upselling" : "")
      }
    >
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        page={parseInt(page.toString()) - 1}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPaginationClient,
        }}
        onPageChange={(newPage) => {
          setPage(newPage + 1);
        }}
        componentsProps={{
          toolbar: {
            isMember,
            setFilters,
            meetingCalendar,
            title: "Meeting date",
            filters: filterData,
            fileName: "KPIs Meetings",
            KEY_QUERY: KEY_QUERY_DATE,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />

      <AVGPriceDialog
        ref={refAVGPriceDialog}
        theme={theme}
        copyToClipboard={copyToClipboard}
      />
    </BasePage>
  );
};

export default Interviews;
