import { Box } from "@mui/material";
import { CheckboxProps } from "../components/crm-upselling.types";
import { useCrmContext } from "../state/CrmV3Context";

const Checkbox: React.FC<CheckboxProps> = ({ checked }) => {
  const { theme } = useCrmContext();

  return (
    <Box
      style={{
        width: "21px",
        height: "21px",
        borderRadius: "50%",
        border: `1px solid ${theme.palette.primary.main}`,
        marginRight: 15,
      }}
      className="flex items-center justify-center"
    >
      {checked && (
        <Box
          style={{
            width: "13px",
            height: "13px",
            borderRadius: "50%",
            background: theme.palette.primary.main,
          }}
        />
      )}
    </Box>
  );
};

export default Checkbox;
