import { forwardRef, useImperativeHandle, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import addIcon from "@/assets/icons/add.svg";
import MaterialBox from "./MaterialBox";

export interface IRef {
  open: () => void;
}

interface IProps {}

const MaterialDialog = forwardRef<IRef, IProps>((_, ref) => {
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
    >
      <EduModalTitle onClose={() => setOpen(false)}>
        <img src={addIcon} /> LOAD MATERIAL
      </EduModalTitle>

      <EduModalBody className="p-5">
        <MaterialBox className={"p-3 flex flex-col gap-5"} />
      </EduModalBody>
    </EduModal>
  );
});

export default MaterialDialog;
