import { memo } from "react";
import { Box, Button } from "@mui/material";
import useAuth from '@/hooks/useAuth';
import dayjs from "dayjs";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";

const f = (v: any) => dayjs(v).format("YYYY-MM-DD HH:mm:ss");

function Toolbar({ filters, setFilters, onExport }: any) {
  const { isMember } = useAuth();

  return (
    <Box className="flex gap-4">
      <CustomDateRangePicker
        value={filters.range}
        onChange={(date) =>
          setFilters((p: any) => {
            if (date) {
              p.set("dates", f(date.startDate) + "," + f(date.endDate));
            } else {
              p.delete("dates");
            }
            return p;
          })
        }
      />

      {!isMember && (
        <Button
          variant="outlined"
          onClick={onExport}
        >
          <img
            src={downloadIcon}
            className="icon"
          />
          Download
        </Button>
      )}

      <SearchToolbar />
    </Box>
  );
}

export default memo(Toolbar);
