import { memo, useEffect, useState } from "react";
import { IPhone } from "./PhoneField";
import { countries } from "countries-list";
import { parsePhoneNumber } from "libphonenumber-js";
import {
  Menu,
  Button,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const FlagDropdown = ({ defaultCountry, value, onChange }) => {
  const [code, setCode] = useState(defaultCountry);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // get list countries
  let countriesData: IPhone[] = [];
  for (let code in countries) {
    const data = countries[code];
    countriesData.push({
      name: data.name,
      phone: data.phone,
      flag: `https://flagcdn.com/w20/${code.toLowerCase()}.png`,
      code: code,
    });
  }

  const countrySelected = countriesData.find((c: IPhone) => c.code === code);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "countries-popover" : undefined;

  useEffect(() => {
    if (!value) return;

    try {
      const phoneFormat = parsePhoneNumber(value);
      const country = phoneFormat?.country;

      if (country) {
        const temp = countriesData.find((c: IPhone) => c.code === country);
        setCode(temp?.code);
      }
    } catch (error) {
      if (String(error).indexOf("TOO_SHORT") !== -1) {
        const temp = countriesData.filter((c: IPhone) =>
          Boolean(c.phone.find((p) => p == value.replace("+", "")))
        );

        if (temp.length === 1) setCode(temp[0].code);
      }
    }
  }, [value]);

  const handleChangeCode = (newCode: string) => {
    const currentCountry = countriesData.find((c: IPhone) => c.code === code);
    const newCountry = countriesData.find((c: IPhone) => c.code === newCode);

    const currentValue = value || `+${currentCountry?.phone}`;

    let newValue = currentValue.replace(
      `+${currentCountry?.phone}`,
      `+${newCountry?.phone}`
    );

    onChange(newValue);
  };

  countriesData.sort((a, b) => (a.name < b.name ? -1 : 1));

  return (
    <>
      <Button
        sx={{ padding: "10px 5px", minWidth: "20px", marginRight: "10px" }}
        variant="text"
        onClick={handleClick}
        aria-describedby={id}
      >
        <img
          alt="flag"
          src={countrySelected?.flag}
        />
      </Button>

      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ style: { maxHeight: 300 } }}
      >
        {countriesData.map((c: IPhone) => (
          <MenuItem
            key={c.code}
            onClick={() => {
              handleChangeCode(c.code);
              setCode(c.code);
              handleClose();
            }}
          >
            <ListItemIcon>
              <img
                alt="flag"
                src={c?.flag}
              />
            </ListItemIcon>

            <ListItemText sx={{ mr: 2 }}>{c.name}</ListItemText>

            <Typography
              color="text.secondary"
              variant="body2"
            >
              {c.phone.map((p) => "+" + p).join(", ")}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default memo(FlagDropdown);
