import { useEffect, useState } from "react";
import { it } from "date-fns/locale";
import { fUpper } from "@/components/useHelpers";
import { queryClient } from "@/main";
import { add, format } from "date-fns";
import { LinearProgress } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createKickoffDate,
  updateKickoffDate,
  updateStatusKickoffDate,
} from "@/api";
import Edit from "./Edit";
import dayjs from "dayjs";
import Table from "./Table";
import Create from "./Create";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";

const KEY_QUERY = ["kickoff-date"];

const HTTP_BASE = "/v1/kickoff-date";

const KickoffDate = () => {
  const [hide, setHide] = useState(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const [idMonth, setIdMonth] = useState<number | null>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [pastMonth, setPastMonth] = useState(false);
  const [startMonth, setStartMonth] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [startsMonth, setStartsMonth] = useState(Array());
  const [paymentDate, setPaymentDate] = useState<Date | null>(new Date());
  const [selectedMonth, setSelectedMonth] = useState(Array());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { data, isLoading } = useQuery(KEY_QUERY, () => useAPI(HTTP_BASE));

  const currentDate = new Date();
  const dataFiltered = (data || [])
    .filter((d: any) => {
      // Filter future kick off date
      const kickOffDate = new Date(
        d.kickoff_date.split("/").reverse().join("-")
      );

      if (currentDate.getTime() > kickOffDate.getTime()) return false;

      return true;
    })
    .sort((a: any, b: any) => {
      // sort default kick of date ASC
      const from = new Date(
        a.kickoff_date.split("/").reverse().join("-")
      ).getTime();

      const to = new Date(
        b.kickoff_date.split("/").reverse().join("-")
      ).getTime();

      return from - to;
    });

  const togglePastMonthFilter = () => {
    setPastMonth((prev) => !prev);
  };

  const filterPreviousMonths = (data: Array<any>) => {
    const currentDate = new Date();

    return data.filter((d) => {
      const parts = d.kickoff_date.split("/");
      const kickOffDate = new Date(parts[2], parts[1] - 1, parts[0]);
      return kickOffDate < currentDate;
    });
  };

  const filteredData = pastMonth ? filterPreviousMonths(data) : dataFiltered;

  const createDate = useMutation(createKickoffDate, {
    onMutate: (update: any) => {
      const selectedMonths = Array();
      data.forEach((el: any) => selectedMonths.push(el.start_month));
      setSelectedMonth(selectedMonths);
    },
    onSuccess: () => {
      setDate(new Date());
      setStartMonth(null);
      setHide(null);
      queryClient.invalidateQueries(KEY_QUERY);
    },
  });

  const updateStatus = useMutation(updateStatusKickoffDate, {
    onMutate: (update: any) => {},
    onSuccess: () => {
      setIdMonth(null);
      setOpenConfirmDialog(false);
      queryClient.invalidateQueries(KEY_QUERY);
    },
  });

  const updatePaymentDate = useMutation(updateKickoffDate, {
    onMutate: (update: any) => {},
    onSuccess: () => {
      setIdMonth(null);
      setPaymentDate(new Date());
      setOpenConfirmDialog(false);
      setOpenEdit(false);
      queryClient.invalidateQueries(KEY_QUERY);
    },
  });

  useEffect(() => {
    const months = Array();

    for (let i = 1; i < 12 * 5 + 9; i++) {
      months.push(
        fUpper(
          format(add(new Date(), { months: i }), "MMMM yyyy", {
            locale: it,
          })
        )
      );
    }

    setStartsMonth(months);
  }, []);

  useEffect(() => {
    if (!data) return;
    const selectedMonths = Array();
    data.forEach((el: any) => selectedMonths.push(el.start_month));
    setSelectedMonth(selectedMonths);
  }, [data]);

  if (isLoading) return <LinearProgress />;

  return (
    <BasePage title="Kick Off Dates">
      <Table
        hide={hide}
        setHide={setHide}
        idMonth={idMonth}
        pastMonth={pastMonth}
        setIdMonth={setIdMonth}
        setOpenEdit={setOpenEdit}
        updateStatus={updateStatus}
        filteredData={filteredData}
        setOpenCreate={setOpenCreate}
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        togglePastMonthFilter={togglePastMonthFilter}
      />

      <Create
        date={date}
        hide={hide}
        open={openCreate}
        setHide={setHide}
        setDate={setDate}
        setOpen={setOpenCreate}
        startMonth={startMonth}
        startsMonth={startsMonth}
        paymentDate={paymentDate}
        selectedMonth={selectedMonth}
        setStartMonth={setStartMonth}
        setPaymentDate={setPaymentDate}
        onConfirm={() => {
          if (!startMonth && !date) return;
          createDate.mutate({
            params: {
              kickoff_date: date,
              start_month: startMonth,
              payment_date: dayjs(paymentDate).format("YYYY-MM-DD"),
              hide: hide,
            },
          });
        }}
      />

      <Edit
        data={data}
        date={date}
        idMonth={idMonth}
        setDate={setDate}
        openEdit={openEdit}
        paymentDate={paymentDate}
        setOpenEdit={setOpenEdit}
        setPaymentDate={setPaymentDate}
        onConfirm={() =>
          updatePaymentDate.mutate({
            id: idMonth,
            params: {
              kickoff_date: dayjs(date).format("DD/MM/YYYY"),
              payment_date: dayjs(paymentDate).format("YYYY-MM-DD"),
            },
          })
        }
      />
    </BasePage>
  );
};

export default KickoffDate;
