import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MONTHS from "@/components/assets/MONTHS";

const GENERATE_OTHER_MONTHS = 5;

const MONTHS_VALUES = Object.values(MONTHS);

const generateMonths = () => {
  const date = new Date();

  const objMonthGenerate = (month, year) => ({ month, year });
  const months = [
    objMonthGenerate(MONTHS_VALUES[date.getMonth()], date.getFullYear()),
  ];
  for (let i = 0; i < GENERATE_OTHER_MONTHS; i++) {
    date.setMonth(date.getMonth() - 1);
    months.push(objMonthGenerate(MONTHS_VALUES[date.getMonth()], date.getFullYear()));
  }
  return months;
};

const SelectMonthFilter = ({ status, name, label, setFilterMonth }: any) => (
  <FormControl className="w-full">
    <InputLabel id={"demo-simple-select-label-" + label}>{label}</InputLabel>

    <Select
      id={"demo-simple-select-" + label}
      name={name}
      label={label}
      color="primary"
      value={status || "All" || ""}
      labelId={"demo-simple-select-label-" + label}
      onChange={(e) => {
        setFilterMonth((prev: any) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
        // setAnchorElPopover(null);
      }}
    >
      <MenuItem
        color="primary"
        value="All"
      >
        All
      </MenuItem>

      {generateMonths().map(({ month, year }, i) => (
        <MenuItem
          style={{ display: "flex", justifyContent: "space-between" }}
          color="primary"
          key={i}
          value={`${month} ${year}`}
        >
          <Box style={{ display: "inline-block" }}>{month}</Box>{" "}
          <Box style={{ display: "inline-block" }}>{year}</Box>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectMonthFilter;
