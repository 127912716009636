import client from "@/api/client";
import useProductApi from "@/api/useProductApi";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import useAuth from '@/hooks/useAuth';
import {
  Box
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../../_Common/Modal/ModalStyles";
import { clone } from "../../useHelpers";
import GeneralInfoBox from "./GeneralInfoBox";
import LocaleDataBox from "./LocaleDataBox";
import PriceBox from "./PriceBox";

export default ({
  show,
  data,
  isCopy,
  setData,
  setIsCopy,
  hideDialog,
}: ProductDialogProps) => {
  const { userAdmin, isAdmission, snackHandler } = useAuth();
  const { callCreateProduct, callUpdateProduct, invalidateProducts } = useProductApi();
  const formRef = useRef<HTMLFormElement>(null);
  const [product, setProduct] = useState(data);
  const [productPrices, setProductPrices] = useState<Prices[]>(data?.prices ?? []);
  const [localeData, setLocaleData] = useState<LocaleData[]>(data?.locale_data ?? []);
  const [courseMonths, setCourseMonths] = useState(3);
  const [expanded, setExpanded] = useState("acc-gen-info");

  const payload = {
    key_value: {
      key: "code",
      value: "en",
    },
    columns: ["prefix", "currency", "locale"],
    get_supported_languages: true,
  }

  const { data: countriesData } = useQuery(["countries-list", JSON.stringify(payload)], () =>
    client.countries_list(payload)
  );

  const { countries, platform_supported_languages } = useMemo(() => {
    const countries = countriesData?.countries ?? [];
    const platform_supported_languages = countriesData?.platform_supported_languages ?? [];

    return { countries, platform_supported_languages };
  }, [countriesData]);

  const onClose = () => {
    hideDialog(false);
    setData(null);
    setIsCopy(false);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };


  useEffect(() => {
    if (data) {
      const newData = clone(data as any);

      if (isCopy) {
        delete newData?.token;
        setData(null);
      }

      setProduct(newData);
      setProductPrices(newData?.prices ?? []);
      setLocaleData(newData?.locale_data ?? []);

      if (newData?.course_info?.months) {
        setCourseMonths(Number(newData.course_info.months));
      }
    }
  }, [data, isCopy]);

  return (
    <EduModal
      fullWidth
      open={show}
      onClose={onClose}
      maxWidth="md"
    >
      <EduModalTitle onClose={onClose}>
        <img
          src={data?.token ? editIcon : addIcon}
          className="icon"
        />
        {isCopy && !data?.token
          ? "Add New Product from copy"
          : data?.token
            ? "Edit Product"
            : "Add New Product"}
        {isAdmission && " Tutoring"}
      </EduModalTitle>

      <EduModalBody>

        <Box className="flex flex-col gap-4 m-5">
          <GeneralInfoBox
            {...{ formRef, product, data, courseMonths, setCourseMonths, handleAccordionChange, expanded, isAdmission, callCreateProduct, callUpdateProduct, userAdminTk: userAdmin?.token, snackHandler, setProduct, invalidateProducts }}
          />

          <PriceBox
            countries={countries}
            productPrices={productPrices}
            product_id={product?.id}
            snackHandler={snackHandler}
            setProductPrices={setProductPrices}
            invalidateProducts={invalidateProducts}
            hidden={!product?.id}
            expanded={expanded}
            handleAccordionChange={handleAccordionChange}
          />

          <LocaleDataBox
            supportedLanguages={platform_supported_languages ?? []}
            localeData={localeData}
            product_id={product?.id}
            snackHandler={snackHandler}
            setLocaleData={setLocaleData}
            invalidateProducts={invalidateProducts}
            hidden={!product?.id}
            expanded={expanded}
            handleAccordionChange={handleAccordionChange}
          />
        </Box>

      </EduModalBody>
    </EduModal>
  );
}
