import { useState, createRef } from "react";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DataAvailabilities, {
  IRef as IRefDataAvailabilities,
} from "./dialog/DataAvailabilities";
import InfoFlowAvailabilities, {
  IRef as IRefInfoFlowAvailabilities,
} from "./dialog/InfoFlowAvailabilities";
import ShowStructureAutomation, {
  IRef as IRefShowStructureAutomation,
} from "./dialog/ShowStructureAutomation";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import mailIcon from "@/assets/icons/mail.svg";
import editIcon from "@/assets/icons/edit.svg";
import chatIcon from "@/assets/icons/chat.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import deleteIcon from "@/assets/icons/delete.svg";
import useExportCSV from "@/components/useExportCSV";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import usePagination from "@/hooks/usePagination";
import CustomPagination from "@/components/_Common/CustomPagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";

const KEY_QUERY = ["availabilities"];

const HTTP_ROUTE_BASE = "/v1/availabilities";
const HTTP_ROUTE_DELETE = HTTP_ROUTE_BASE + "/delete";
const HTTP_ROUTE_GET_BAD = HTTP_ROUTE_BASE + "/get_bad";
const HTTP_ROUTE_SET_DATES = HTTP_ROUTE_BASE + "/set_dates";

const formatDate = (v: Array<Date> | null, i: number) =>
  v?.length ? dayjs(v[i]).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");

const Availabilities = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler, copyToClipboard } = useAuth();

  const refDataAvailabilities = createRef<IRefDataAvailabilities>();
  const refInfoFlowAvailabilities = createRef<IRefInfoFlowAvailabilities>();
  const refShowStructureAutomation = createRef<IRefShowStructureAutomation>();

  const [idForDelete, setIdForDelete] = useState<string | number | null>();
  const [disableDownload, setDisableDownload] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [openConfirmSetDates, setOpenConfirmSetDates] =
    useState<boolean>(false);

  const [filters, setFilters] = useSearchParams();
  const { page, setPage } = usePagination({ filters, setFilters });
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  const filterData = Object.fromEntries(filters.entries());

  const sorts = filterData?.sorts;
  delete filterData?.sorts;
  delete filterData?.page;

  const keycache = [KEY_QUERY, page, perPage, filterData, sorts];

  const { data: dataAPI, isLoading } = useQuery(
    keycache,
    () =>
      useAPI(HTTP_ROUTE_BASE, {
        page,
        sorts,
        filter: filterData,
        per_page: perPage,
      }),
    { keepPreviousData: true, refetchOnWindowFocus: true, enabled: perPage > 0 }
  );

  const data = dataAPI?.data;
  const availabilitiesData = data?.data || [];
  const pagination = {
    total: data?.total ? data?.total : 1,
    last_page: data?.last_page ? data?.last_page : 1,
    current_page: data?.current_page ? data?.current_page : 1,
  };

  const range = dataAPI
    ? [new Date(dataAPI?.range_date[0]), new Date(dataAPI?.range_date[1])]
    : [];

  const handleConfirmSetRange = async () => {
    setOpenConfirmSetDates(false);
    await useAPI(HTTP_ROUTE_SET_DATES, {
      dates: [formatDate(range, 0), formatDate(range, 1)],
    });
    snackHandler("Success edit!");
  };

  const handleDestroy = async () => {
    setOpenConfirmDelete(false);
    await useAPI(HTTP_ROUTE_DELETE, { id: idForDelete });
    queryClient.invalidateQueries(keycache);
    setIdForDelete(null);
    snackHandler("Success delete!");
  };

  const handleExportBadAvailabilities = async () => {
    setDisableDownload(true);
    useExportCSV(
      await useAPI(HTTP_ROUTE_GET_BAD),
      `Bad Availabilities ${format(new Date(), "dd-MM-yyyy")}.csv`
    );
    setDisableDownload(false);
  };

  const handleExportAvailabilities = async () => {
    setDisableDownload(true);
    useExportCSV(
      await useAPI(HTTP_ROUTE_BASE, {
        export: true,
        filter: filterData,
      }),
      `Availabilities ${format(new Date(), "dd-MM-yyyy")}.csv`
    );
    setDisableDownload(false);
  };

  const handleOnOpenInfoFlow = () => {
    refInfoFlowAvailabilities?.current?.open();
  };

  const handleOnOpenShowStructureAutomation = () => {
    refShowStructureAutomation?.current?.open();
  };

  const columns: GridColDef[] = [
    {
      field: "period",
      sortable: false,
      width: 200,
      headerName: "Period",
      valueGetter: ({ row }) =>
        row?.availabilities?.[0]?.data_range
          ?.split("/")
          .map((d: any) => d.split("-").reverse().join("/"))
          .join(" - "),
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => {
        let i = 0;
        while (
          i < row?.availabilities?.length &&
          !row?.availabilities?.[i]?.has_problem
        ) {
          i++;
        }

        return row?.availabilities?.[i]?.has_problem ? (
          <>
            <WarningOutlinedIcon
              className="mr-2"
              sx={{ color: "red" }}
              fontSize="small"
            />
            {row.fullName}
          </>
        ) : (
          row.fullName
        );
      },
    },
    {
      field: "start_month",
      width: 150,
      headerName: "Start month",
      valueGetter: ({ row }) => useConvertStartMonthEn(row?.start_month) || "-",
    },
    {
      field: "english_level",
      width: 120,
      headerName: "English level",
    },
    {
      field: "name_product",
      minWidth: 180,
      flex: 1.3,
      headerName: "Name product",
      valueGetter: ({ row }) => row?.products?.at(-1)?.product_tag || "-",
      renderCell: ({ row }) => (
        <MyTooltip>{row?.products?.at(-1)?.product_tag || "-"}</MyTooltip>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVertIcon />}>
          <MenuList>
            <MenuItem
              title="Copy email"
              color="primary"
              onClick={() => copyToClipboard(row?.email)}
            >
              <ListItemIcon>
                <img
                  src={mailIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Copy email</ListItemText>
            </MenuItem>

            <MenuItem
              title="Send WhatsApp message"
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=${row?.phone}&text=Ciao ${row.first_name}`
                )
              }
            >
              <ListItemIcon>
                <img
                  src={chatIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>WhatsApp</ListItemText>
            </MenuItem>

            <MenuItem
              title="Edit"
              onClick={() => {
                refDataAvailabilities.current?.open({
                  name: `${row?.first_name} ${row?.last_name}`,
                  token: row?.token,
                  availabilities:
                    row?.availabilities?.[0]?.array?.secondaStringa,
                });
              }}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Edit availabilities</ListItemText>
            </MenuItem>

            <MenuItem
              title="Delete"
              onClick={() => {
                setIdForDelete(row?.token);
                setOpenConfirmDelete(true);
              }}
            >
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <BasePage title="Period availabilities">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={availabilitiesData}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar: Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        componentsProps={{
          toolbar: {
            theme,
            setFilters,
            disableDownload,
            handleOnOpenInfoFlow,
            handleExportAvailabilities,
            handleExportBadAvailabilities,
            handleOnOpenShowStructureAutomation,
            filters: filterData,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: { page, setPage, pagination },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <DataAvailabilities
        ref={refDataAvailabilities}
        keyQuery={keycache}
      />

      <InfoFlowAvailabilities ref={refInfoFlowAvailabilities} />

      <ShowStructureAutomation ref={refShowStructureAutomation} />

      <ConfirmationDialog
        open={openConfirmSetDates}
        onClose={() => setOpenConfirmSetDates(false)}
        onConfirm={handleConfirmSetRange}
      >
        Do you want to edit range dates?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={handleDestroy}
      >
        Do you want to delete this availability?
      </ConfirmationDialog>
    </BasePage>
  );
};

export default Availabilities;
