import Dropdown from '@/components/_Common/Dropdown';
import FiltersBar from '@/components/_Common/FiltersBar';
import { Box } from '@mui/material';
import filterIcon from "@/assets/icons/filter.svg";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";
import { useMemo } from 'react';
import { format } from 'date-fns';

const CustomToolbar = (props) => {

    const { filterData, setFilters } = props;

    const filtersApplied = useMemo(
        () =>
            Object.keys(filterData).filter(
                (key) => !["search", "sorts", "page"].includes(key)
            ).length,
        [filterData]
    );

    const tempDates = filterData?.dates?.split(",");
    const dates =
        tempDates?.length > 0
            ? [new Date(tempDates[0]), new Date(tempDates[1])]
            : null;


    return (
        <div
            id="filter_bar"
            className="flex gap-4"
        >
            <FiltersBar>

                <Dropdown
                    text="Filter"
                    icon={filterIcon}
                    active={filtersApplied ? true : false}
                    onClick={() => { }}
                    content={
                        <Box className="flex flex-col w-[250px] gap-5 p-5">
                            <>
                                <DateRangePickerField
                                    title="Creation date"
                                    onChange={(date) => {
                                        if (date) {
                                            setFilters((prev) => {
                                                return {
                                                    ...prev,
                                                    dates:
                                                        format(date.startDate, "yyyy-MM-dd HH:mm:ss") +
                                                        "," +
                                                        format(date.endDate, "yyyy-MM-dd HH:mm:ss"),
                                                };
                                            });
                                        } else {
                                            // props.setRange(null);
                                            setFilters((prev) => {
                                                prev.delete("dates");
                                                return prev;
                                            });
                                        }
                                    }}
                                    value={dates}
                                />
                            </>
                        </Box>
                    }
                />

            </FiltersBar>
        </div>
    )
}

export default CustomToolbar