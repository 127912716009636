import { useState, useEffect, Dispatch, SetStateAction } from "react";

interface HourGeneratorHook {
  hourEnd: string[];
  hourStart: string[];
  setUseHalf: Dispatch<SetStateAction<boolean>>;
  setStartHour: Dispatch<SetStateAction<string>>;
}

const zero = ":00";
const half = ":30";

const useHourGenerator = (timeDuration: Array<number>): HourGeneratorHook => {
  const [useHalf, setUseHalf] = useState<boolean>(false);
  const [hourEnd, setHourEnd] = useState<Array<string>>([]);
  const [startHour, setStartHour] = useState<string>("");
  const [hourStart, setHourStart] = useState<Array<string>>([]);

  const generateHourArray = (
    start: number,
    end: number,
    isEnd: boolean = false
  ) => {
    const hours: Array<string> = [];

    const has30 = startHour.includes(half);

    for (let i = start; i <= end; i++) {
      const hour = i < 10 ? "0" : "";

      if (useHalf) {
        if (isEnd) {
          if (has30) {
            hours.push(hour + i + zero);

            if (i < end) {
              hours.push(hour + i + half);
            }
          } else {
            const hour2 = i - 1 < 10 ? "0" : "";

            hours.push(hour2 + (i - 1) + half);
            hours.push(hour + i + zero);
          }
        } else {
          hours.push(hour + i + zero);
          hours.push(hour + i + half);
        }
      } else {
        hours.push(hour + i + zero);
      }
    }
    return hours;
  };

  useEffect(() => {
    setHourStart(generateHourArray(timeDuration[0], timeDuration[1]));
    setHourEnd(
      generateHourArray(timeDuration[0] + 1, timeDuration[1] + 1, true)
    );
  }, [timeDuration, useHalf]);

  useEffect(() => {
    if (startHour) {
      const startHourNumber = parseInt(startHour.slice(0, 2));
      setHourEnd(
        generateHourArray(startHourNumber + 1, timeDuration[1] + 1, true)
      );
    }
  }, [startHour, useHalf]);

  return { hourStart, hourEnd, setStartHour, setUseHalf };
};

export default useHourGenerator;
