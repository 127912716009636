import { useState } from "react";
import { Add, Audiotrack, Clear } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, useTheme } from "@mui/material";

function ExerciseChooseFile({
  name,
  label,
  accept,
  isEdit,
  exercise,
  onChange,
  playerRef,
  placeholder,
  watchImgRef,
}) {
  const theme = useTheme();

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const isImg = name === "img_file";
  const isAudio = name === "audio_file";

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const dataUrl = e.target?.result as string;
        setPreviewUrl(dataUrl);
        onChange(name, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClear = () => {
    setPreviewUrl(null);
    setFileName(null);
    onChange(name, null);
  };

  const handlePreview = () => {
    if (isImg && watchImgRef.current) {
      watchImgRef.current.open(previewUrl, exercise.name);
    } else if (isAudio && playerRef.current) {
      playerRef.current.open(previewUrl, exercise.name);
    }
  };

  return (
    <Box className="flex items-end">
      {isEdit && (
        <Button
          title={isImg ? "Watch img" : isAudio ? "Play audio" : ""}
          variant="outlined"
          onClick={handlePreview}
          className="!mr-2"
        >
          {isImg ? <Add /> : isAudio ? <Audiotrack /> : null}
        </Button>
      )}

      <Button
        disableElevation
        title="Select file"
        variant="contained"
        component="label"
        className={"flex justify-center items-center !min-w-fit !font-bold !text-base !p-0 " + (previewUrl && isImg ? "" : "!px-4 !py-2")}
      >
        {previewUrl && isImg ? (
          <img
            src={previewUrl}
            alt="Preview"
            style={{
              height: "150px",
              maxWidth: "150px",
              objectFit: "cover",
              borderColor: theme.palette.primary.main,
            }}
            className="rounded hover:border-2"
          />
        ) : (
          label
        )}
        <input
          hidden
          type="file"
          name={name}
          accept={accept}
          onChange={handleFileChange}
        />
      </Button>

      <TextField
        value={fileName || ""}
        variant="standard"
        className="w-full !ml-2 grow"
        placeholder={placeholder}
        InputProps={{
          readOnly: true,
        }}
      />

      {exercise[name] && (
        <IconButton
          title="Clear file"
          className="!p-[6px]"
          onClick={handleClear}
        >
          <Clear />
        </IconButton>
      )}
    </Box>
  );
}

export default ExerciseChooseFile;
