import { useEffect, useState } from "react";
import { convert } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { useContextCrm } from "../Context";
import { MessageRounded } from "@mui/icons-material";
import { sortDescNotesByCreatedAt } from "@/components/Crm/CommentDialog";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export interface IComments {
  admin: {
    name: string;
    lname: string;
    email: string;
  };
  created: string;
  content: string;
  created_at: string;
  isFromMeeting?: string;
}

const Comments = () => {
  const theme = useTheme();
  const context = useContextCrm();
  const { userAdmin, snackHandler } = useAuth();

  const [content, setContent] = useState<string>("");
  const [addNote, setAddNote] = useState<boolean>(false);
  const [allNotes, setAllNotes] = useState<Array<IComments>>([]);
  const [indexCommentDelete, setIndexCommentDelete] = useState<number>();

  const handleAddNewComment = async () => {
    const { id, first_name, last_name, email } = userAdmin.user;
    const row = context.dialog?.row ?? context.rowSelected;

    const notesWithoutFromMeetings = allNotes?.filter(
      (n: any) => !n?.isFromMeeting || n?.isFromMeeting != "from meeting"
    );

    const newNotes = {
      notes: [
        ...(notesWithoutFromMeetings || []),
        {
          admin: { id, name: first_name, lname: last_name, email },
          created: dayjs().format("DD-MM") + " at " + dayjs().format("HH:mm"),
          created_at: dayjs().format("YYYY-MM-DD HH:mm"),
          content,
        },
      ],
    };

    const res = await useAPI("/v1/crm/" + row?.id, newNotes, { type: "put" });

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed save comments!", "error");
      return;
    }

    snackHandler("Success save comments!");
    setAddNote(false);
    setContent("");
    row.notes = newNotes.notes;
    context.setDialog({ row, component: "show_comments" });
    context.getInvalidateQuery();
  };

  const handleDeleteComment = async () => {
    const row = context.dialog?.row ?? context.rowSelected;

    const newNotes = {
      notes:
        convert(row?.notes ?? [])?.filter(
          (n: any, i: number) =>
            i !== indexCommentDelete &&
            (!n?.isFromMeeting || n?.isFromMeeting != "from meeting")
        ) || [],
    };

    setIndexCommentDelete(undefined);

    const res = await useAPI("/v1/crm/" + row?.id, newNotes, { type: "put" });

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed delete comment!", "error");
      return;
    }

    snackHandler("Success delete comment!");
    row.notes = newNotes.notes;
    context.setDialog({ row, component: "show_comments" });
    context.getInvalidateQuery();
  };

  const handleClose = () => {
    setAddNote(false);
    setContent("");
    setIndexCommentDelete(undefined);
    setAllNotes([]);
    context.setDialog(undefined);
  };

  useEffect(() => {
    const row = context.dialog?.row ?? context.rowSelected;

    const notesUser: Array<any> = convert(row?.notes || []);

    const meetings = convert(row?.meeting || {});

    const meets =
      [
        ...(meetings?.meet?.filter((e: any) => e?.notes) ?? []),
        ...(meetings?.meetToBeCalled?.filter((e: any) => e?.notes) ?? []),
      ].map((e: any) => ({
        admin: {
          name: e?.interviewer,
          lname: "",
          email: "",
        },
        created: dayjs(e?.start).format("DD-MM % HH:mm").replace("%", "at"),
        content: e?.notes,
        created_at: e?.start,
        isFromMeeting: "from meeting",
      })) ?? [];

    setAllNotes(sortDescNotesByCreatedAt([...notesUser, ...meets]));
  }, [
    open,
    context.dialog?.row,
    context.dialog?.row?.notes,
    context.dialog?.row?.meeting,
  ]);

  return (
    <>
      <EduModal
        fullWidth
        open={Boolean(context.dialog?.row)}
        onClose={handleClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <MessageRounded sx={{ mr: 1 }} />
          Comments
        </EduModalTitle>

        <EduModalBody className="flex flex-col gap-4 p-5">
          {allNotes.length ? (
            allNotes?.map((e: any, i: number) => (
              <Box
                key={i}
                className="flex flex-col gap-1"
              >
                <Box className="mb-2 flex justify-between items-end">
                  <Typography
                    color="primary"
                    fontSize="10px"
                    className={e?.isFromMeeting ? "pb-2 pt-1" : ""}
                  >
                    <b className="text-lg w-6 inline-block">{i + 1}</b>{" "}
                    {e?.admin.name} {e?.admin.lname} wrote on {e?.created}{" "}
                    {e?.isFromMeeting}
                  </Typography>

                  {!e?.isFromMeeting && (
                    <Button
                      title="Delete Note"
                      variant="outlined"
                      onClick={() => setIndexCommentDelete(i)}
                    >
                      <img
                        src={deleteIcon}
                        className="icon no-margin"
                      />
                    </Button>
                  )}
                </Box>

                <Box
                  sx={{
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                  }}
                  className="flex flex-col border-b"
                >
                  <Typography className="p-2">{e?.content}</Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              sx={{ textAlign: "center", color: "#B4B4B4" }}
              fontSize={16}
            >
              No comments here, add the first!
            </Typography>
          )}

          {addNote ? (
            <Box className="flex flex-col gap-4">
              <Typography
                color="primary"
                className="text-lg"
              >
                New comment
              </Typography>

              <TextareaAutosize
                value={content}
                minRows={5}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Write here..."
              />

              <Box className="flex justify-end">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleAddNewComment}
                  disabled={!content}
                >
                  <img
                    src={saveIcon}
                    className="icon"
                  />
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className="flex justify-end">
              <Button
                variant="outlined"
                onClick={() => setAddNote(true)}
              >
                <img
                  src={addIcon}
                  className="icon"
                />
                Add
              </Button>
            </Box>
          )}
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={indexCommentDelete != undefined}
        onClose={() => setIndexCommentDelete(undefined)}
        onConfirm={handleDeleteComment}
      >
        Do you want to delete comment <b>{(indexCommentDelete ?? 0) + 1}</b>?
      </ConfirmationDialog>
    </>
  );
};

export default Comments;
