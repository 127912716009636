import { memo, useState } from "react";
import { Avatar, Box } from "@mui/material";
import dayjs from "dayjs";
import ConfirmButton from "@/components/ConfirmationButton";

const DefaultCalendarButtons = ({
  newEvent,
  calendars,
  createEvent,
  selectedDay,
  availableCalendars,
}) => {
  const [buttonIndex, setButtonIndex] = useState<string | null>(null);

  const events =
    availableCalendars
      .filter(
        (c: any) =>
          c.date.indexOf(dayjs(selectedDay).format("YYYY-MM-DD")) !== -1
      )
      .filter(
        (c: any) =>
          calendars.length === 0 ||
          (calendars.length === 1 && calendars[0] === "all") ||
          calendars.indexOf(c.calendar.toLowerCase()) !== -1
      ) || [];

  return (
    <Box className="pt-1 pb-4 md:pt-6 pl-4 grow flex flex-col gap-4">
      <Box className="text-sm font-bold leading-20">
        Schedule for {dayjs(selectedDay).format("MMMM DD, YYYY")}
      </Box>

      <Box className="flex flex-col gap-4 min-w-[250px] grow overflow-y-auto pr-4 pb-1">
        {events.map((e: any, i: number) => (
          <ConfirmButton
            key={i}
            show={e.calendar_id + "-" + i == buttonIndex}
            text={
              <>
                <div className="mr-2">{dayjs(e.date).format("HH:mm")}</div>

                <Avatar
                  src={e?.image}
                  alt={e?.calendar}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    fontSize: "10px",
                  }}
                >
                  {e?.calendar?.charAt(0).toUpperCase()}
                </Avatar>
              </>
            }
            title={e.calendar}
            onClick={() => setButtonIndex(e.calendar_id + "-" + i)}
            isLoading={newEvent.isLoading}
            onConfirm={() => createEvent(e.calendar, e.date)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default memo(DefaultCalendarButtons);
