import { useMemo, useState } from "react";
import { format } from "date-fns";
import { TableStyling } from "@/dummy";
import { fUpper, isObject } from "@/components/useHelpers";
import { COLORS_STARS_MEDIA } from "..";
import { Box, Rating, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import MyTooltip from "@/components/MyTooltip";
import useScreen from "@/hooks/useScreen";
import CircleIcon from "@mui/icons-material/Circle";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";

const AllFeedbacks = ({ dataAPI, isLoading }) => {
  const { isMobile, screen } = useScreen();

  const [rangeDate, setRangeDate] = useState<Array<any>>([]);

  const filterData = useMemo(() => {
    if (!rangeDate.length) return dataAPI;

    const start = new Date(rangeDate[0]);
    const end = new Date(rangeDate[1]);

    return dataAPI?.filter(
      ({ event }) =>
        start <= new Date(event?.data) && new Date(event?.data) <= end
    );
  }, [rangeDate, dataAPI]);

  // COLUMNS ===================================================================
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "created_at",
        hide: true,
        hideable: false,
      },
      {
        field: "student",
        headerName: "Student",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) =>
          `${row?.user?.first_name} ${row?.user?.last_name}` || "-",
        renderCell: ({ row }) =>
          row?.user?.first_name && row?.user?.last_name ? (
            `${row?.user?.first_name} ${row?.user?.last_name}`.length > 18 ? (
              <MyTooltip>{`${row?.user?.first_name} ${row?.user?.last_name}`}</MyTooltip>
            ) : (
              `${row?.user?.first_name} ${row?.user?.last_name}`
            )
          ) : (
            "-"
          ),
      },
      {
        field: "titolo",
        headerName: "Lesson",
        minWidth: 260,
        flex: 1,
        renderCell: ({ row }) =>
          row?.event?.titolo ? (
            <MyTooltip>{row?.event?.titolo}</MyTooltip>
          ) : (
            "-"
          ),
        valueGetter: ({ row }) => row?.event?.titolo,
      },
      {
        field: "date",
        headerName: "Date",
        width: 110,
        valueGetter: ({ row }) => (row?.event?.data ? row?.event?.data : "-"),
      },
      {
        field: "hour",
        headerName: "Hour",
        width: 70,
        valueGetter: ({ row }) => row?.event?.ora || "-",
      },
      {
        field: "name_tutor",
        headerName: "Tutor",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) =>
          `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`.trim() ||
          "-",
        renderCell: ({ row }) =>
          row?.event?.tutors?.first_name && row?.event?.tutors?.last_name ? (
            `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`
              .length > 18 ? (
              <MyTooltip>{`${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`}</MyTooltip>
            ) : (
              `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`
            )
          ) : (
            "-"
          ),
      },
      {
        field: "rating",
        headerName: "Rating",
        width: 180,
        valueGetter: ({ row }) => "rating =r" + (row?.punto / 2 || 0),
        renderCell: ({ row }) => {
          const rat = Number(row?.punto / 2 || 0);

          return (
            <Box className="flex items-center gap-2">
              <Box>{rat.toFixed(2)}</Box>
              <Rating
                style={{ color: COLORS_STARS_MEDIA[Math.floor(rat * 2)] }}
                defaultValue={rat}
                value={rat}
                precision={0.1}
                readOnly
              />
            </Box>
          );
        },
      },
      {
        field: "first_feedback",
        headerName: "Comment",
        minWidth: 200,
        flex: 1,
        renderCell: ({ row }) =>
          row?.first_feedback !== "-" ? (
            row?.first_feedback?.length > 18 ? (
              <MyTooltip>{fUpper(row?.first_feedback || "")}</MyTooltip>
            ) : (
              fUpper(row?.first_feedback || "")
            )
          ) : (
            "-"
          ),
      },
      {
        field: "improvements",
        headerName: "Improvements",
        width: 130,
        valueGetter: ({ row }) => {
          let improv = JSON.parse(row?.improvements || "[]");
          if (typeof improv === "string") improv = JSON.parse(improv);
          const improvType = Array.isArray(improv)
            ? improv
            : isObject(improv)
            ? Object.keys(improv)
            : [];
          return (
            "improvements =i" +
            (improvType.length && row?.last_feedback
              ? improvType.length + 1
              : improvType.length
              ? improvType.length
              : row?.last_feedback
              ? 1
              : "-")
          );
        },
        renderCell: ({ row }) => {
          let improv = JSON.parse(row?.improvements || "[]");
          if (typeof improv === "string") improv = JSON.parse(improv);
          const improvType = Array.isArray(improv)
            ? improv
            : isObject(improv)
            ? Object.keys(improv)
            : [];

          return improvType.length ? (
            <Tooltip
              placement="bottom-start"
              arrow
              title={
                <Box>
                  {improvType.map((e: string, i: number) => {
                    const v = Array.isArray(improv)
                      ? e
                      : isObject(improv)
                      ? improv
                      : "";

                    const isString = typeof v === "string";

                    const style =
                      i < improvType.length - 1
                        ? {
                            borderBottom: "1px solid",
                            paddingTop: i !== 0 ? "0.4rem" : "",
                          }
                        : i !== 0
                        ? { paddingTop: "0.4rem" }
                        : {};

                    return (
                      <Box key={isString ? v : e}>
                        <Box
                          className="text-base"
                          style={style}
                        >
                          <CircleIcon
                            style={{
                              fontSize: "0.5rem",
                              marginRight: "0.2rem",
                            }}
                          />{" "}
                          {isString ? fUpper(v) : fUpper(e)}
                          {!isString && (
                            <Box className="pl-4 w-full">
                              {v[e]?.map((r: string) => (
                                <Box
                                  key={r + "child"}
                                  sx={{ width: "100%", wordWrap: "break-word" }}
                                >
                                  - {fUpper(r)}
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>

                        {row?.last_feedback && (
                          <Box
                            className="text-base border-t"
                            style={style}
                          >
                            <CircleIcon
                              style={{
                                fontSize: "0.5rem",
                                marginRight: "0.2rem",
                              }}
                            />{" "}
                            Old version
                            <Box className="pl-4 w-full">
                              <Box
                                sx={{ width: "100%", wordWrap: "break-word" }}
                              >
                                - {fUpper(row?.last_feedback || "")}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              }
            >
              <Box
                className="truncate"
                style={{ width: "100%" }}
              >
                {improvType?.length && row?.last_feedback
                  ? improvType?.length + 1
                  : improvType?.length
                  ? improvType?.length
                  : row?.last_feedback
                  ? 1
                  : "-"}
              </Box>
            </Tooltip>
          ) : (
            "-"
          );
        },
      },
    ],
    [screen, filterData, dataAPI]
  );

  if (!dataAPI?.length) return <></>;

  return (
    <DataGrid
      disableColumnMenu
      disableVirtualization
      disableSelectionOnClick
      sx={TableStyling}
      rows={filterData}
      style={!isMobile ? { height: "calc(100vh - 14rem)" } : {}}
      columns={columns}
      loading={isLoading}
      pageSize={20}
      autoHeight={isMobile}
      initialState={{
        sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      rowsPerPageOptions={[20]}
      experimentalFeatures={{ newEditingApi: false }}
      columnVisibilityModel={{ created_at: false }}
      components={{
        Toolbar: () => (
          <Box
            className="flex"
            id="filter_bar"
          >
            <CustomDateRangePicker
              value={rangeDate}
              onChange={(date) =>
                date
                  ? setRangeDate([date?.startDate, date?.endDate])
                  : setRangeDate([])
              }
            />
            <SearchToolbar />
          </Box>
        ),
      }}
    />
  );
};

export default AllFeedbacks;
