import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useAuth from '@/hooks/useAuth';
import { formatPrice } from "../useHelpers";
import { LoadingButton } from "@mui/lab";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import {
  Box,
  Tooltip,
  Checkbox,
  MenuItem,
  useTheme,
  TextField,
  FormControl,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import SelectUser from "../SelectUser";
import usePaymentsInApi from "@/api/usePaymentsInApi";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client";

export interface IData {
  id?: number | string;
  type?: string;
  date?: string;
  amount?: number | string;
  paidFor?: string;
  quantity: string;
  tokenUser?: string;
  isInitial?: boolean;
  isUpselling: boolean;
  isPaidTotal?: boolean;
  isAdmission?: boolean;
  tokenProduct?: number | string;
  enable_quantity?: boolean;
  sendEmailInvoice?: boolean;
}

interface IProps { }

export interface IRef {
  open: (v: any) => void;
}

export type IAddressData = {
  city?: string;
  address?: string;
  zipcode?: string;
  country?: string;
};

export const types = [
  "Alma",
  "Klarna",
  "Paypal",
  "Stripe",
  "Revolut",
  "Bonifico",
  "Pagolight",
].sort();

const CreateInvoicePaymentInDialog = forwardRef<IRef, IProps>((_props, ref) => {
  const theme = useTheme();
  const { callCreatePaymentIn } = usePaymentsInApi();
  const { userAdmin, isAdmission } = useAuth();

  const defaultPayment: IData = {
    isAdmission,
    type: "Bonifico",
    date: dayjs().format("YYYY-MM-DD"),
    quantity: "",
    isInitial: false,
    tokenUser: String(userAdmin.user.id),
    isPaidTotal: false,
    isUpselling: false,
    sendEmailInvoice: true,
  };

  const [mode, setMode] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [payment, setPayment] = useState<IData>(defaultPayment);
  const [products, setProducts] = useState<Array<any>>([]);
  const [addressData, setAddressData] = useState<IAddressData | null>(null);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IData | null>(null);

  const { data: countries } = useQuery(["countries-list"], () =>
    client.countries_list({
      key_value: {
        key: "en",
        value: "it",
      },
      columns: ["prefix"],
    })
  );

  useImperativeHandle(ref, () => ({
    open: (data: any) => {
      setShow(true);

      setProducts(data.products);

      if (data?.user?.token) {
        setMode("add-payment");

        setPayment((p) => ({
          ...p,
          tokenUser: data?.user?.token,
        }));

        setAddressData({
          zipcode: data?.user?.zipcode || "",
          city: data?.user?.city || "",
          address: data?.user?.address || "",
        });
      }
    },
  }));

  const handleAutocompleteChange = (
    event: ChangeEvent<{}>,
    newValue: any | null
  ) => {
    if (newValue) {
      // set camp "tokenProduct"
      handlePaymentChange({
        target: { name: "tokenProduct", value: newValue.token },
      });

      // set camp "amount"
      handlePaymentChange({
        target: { name: "amount", value: String(newValue.price / 100) },
      });

      // set camp "paidFor"
      handlePaymentChange({
        target: { name: "paidFor", value: newValue.product_tag },
      });
    }
  };

  const handlePaymentChange = async (event: any) => {
    let value = event.target.value;
    const name = event.target.name;

    if (name === "amount") {
      value = formatPrice(value);
    }

    setPayment((p: IData) => ({ ...p, [name]: value }));

    if (name === "tokenProduct") {
      const selectedProduct = products.find((p) => p.token === value);
      setSelectedProduct(selectedProduct);
    }
  };

  const handleOnClose = () => {
    setShow(false);
    setIsLoadingData(false);
    setPayment(defaultPayment);
    setMode("");
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    const formData = {
      ...payment,
      ...addressData,
      quantity: selectedProduct?.enable_quantity ? payment.quantity : undefined,
    };

    setIsLoadingData(true);

    callCreatePaymentIn.mutate({
      token: userAdmin.token,
      formData: formData,
      callback: handleOnClose,
    });
  };

  return (
    <EduModal
      fullWidth
      open={show}
      onClose={handleOnClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleOnClose}>
        <img
          src={addIcon}
          className="icon"
        />
        {mode === "add-payment" ? "Add payment" : "Add manually"}
      </EduModalTitle>

      <EduModalBody>
        <Box
          onSubmit={submitForm}
          component="form"
          className=" flex flex-col m-5 gap-5"
        >
          <Box className="grid grid-cols-2 gap-5">
            <FormControlLabel
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
              }}
              label="Is initial payment"
              control={
                <Checkbox
                  name="isInitial"
                  checked={payment.isInitial}
                  onChange={(e) =>
                    setPayment((p: any) => ({
                      ...p,
                      isInitial: e.target.checked,
                    }))
                  }
                />
              }
              className="rounded w-full !ml-0 select-none"
            />

            <FormControlLabel
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
              }}
              label="Is paid total"
              control={
                <Checkbox
                  name="isPaidTotal"
                  checked={payment.isPaidTotal}
                  onChange={(e) =>
                    setPayment((p: any) => ({
                      ...p,
                      isPaidTotal: e.target.checked,
                    }))
                  }
                />
              }
              className="rounded w-full !ml-0 select-none"
            />
          </Box>

          <FormControlLabel
            sx={{
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.primary.main}`,
            }}
            label="Send email invoice"
            control={
              <Checkbox
                name="sendEmailInvoice"
                checked={payment.sendEmailInvoice}
                onChange={(e) =>
                  setPayment((p: any) => ({
                    ...p,
                    sendEmailInvoice: e.target.checked,
                  }))
                }
              />
            }
            className="rounded w-full !ml-0 select-none"
          />

          {mode !== "add-payment" && (
            <FormControl className="w-full">
              <SelectUser
                value={payment?.tokenUser ?? ""}
                onChange={(tokenUser, user) => {
                  setPayment((p: any) => ({ ...p, tokenUser }));
                  setAddressData((p: IAddressData | null) => ({
                    ...(p || {}),
                    zipcode: user?.zipcode || "",
                    city: user?.city || "",
                    address: user?.address || "",
                    country: user?.country || "",
                  }));
                }}
              />
            </FormControl>
          )}

          <TextField
            required
            fullWidth
            id="data"
            type="date"
            name="date"
            value={payment?.date ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handlePaymentChange(e)
            }
          />

          <Autocomplete
            options={products}
            getOptionLabel={(option) => option.product_tag}
            value={
              products.find((p) => p.token === payment?.tokenProduct) || null
            }
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product"
                required
                fullWidth
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={option.product_tag}>
                  <Box className="w-full flex justify-between">
                    <Box className="truncate mr-2">{option.product_tag}</Box>
                    <Box>€{(option.price / 100).toFixed(2)}</Box>
                  </Box>
                </Tooltip>
              </li>
            )}
          />

          <TextField
            required
            fullWidth
            type="number"
            name="amount"
            label="Amount"
            value={payment?.amount ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handlePaymentChange(e)
            }
          />

          {selectedProduct?.enable_quantity && (
            <TextField
              required
              fullWidth
              type="number"
              name="quantity"
              label="Quantity"
              value={payment?.quantity ?? ""}
              onChange={handlePaymentChange}
            />
          )}

          <TextField
            required
            fullWidth
            type="text"
            name="paidFor"
            label="Paid for"
            value={payment?.paidFor ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handlePaymentChange(e)
            }
          />

          <TextField
            select
            required
            fullWidth
            name="type"
            label="Type"
            value={payment?.type ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handlePaymentChange(e)
            }
          >
            {types.map((type) => (
              <MenuItem
                key={type + Math.random()}
                value={type}
              >
                {type}
              </MenuItem>
            ))}
          </TextField>

          <Box className="grid grid-cols-2 gap-5">
            <TextField
              required
              fullWidth
              select
              name="country"
              label="Country"
              value={addressData?.country || ""}
              onChange={(e) =>
                setAddressData((prev) => ({
                  ...prev,
                  country: e.target.value,
                }))
              }
            >
              {countries?.map((item, i) => (
                <MenuItem
                  key={item?.key + i}
                  value={item?.value}
                >
                  {item?.key}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              fullWidth
              name="address"
              label="Address"
              value={addressData?.address || ""}
              onChange={(e) =>
                setAddressData((p) => ({
                  ...p,
                  address: e.target.value,
                }))
              }
            />
          </Box>

          <Box className="grid grid-cols-2 gap-5">
            <TextField
              required
              name="city"
              label="City"
              value={addressData?.city || ""}
              onChange={(e) =>
                setAddressData((p) => ({
                  ...p,
                  city: e.target.value,
                }))
              }
            />

            <TextField
              required
              name="zipcode"
              label="Postal code"
              value={addressData?.zipcode || ""}
              onChange={(e) =>
                setAddressData((p) => ({
                  ...p,
                  zipcode: e.target.value,
                }))
              }
            />
          </Box>

          <Box className="flex flex-row-reverse">
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoadingData}
            >
              <img
                src={saveIcon}
                className={`icon ${isLoadingData ? "opacity-0" : ""}`}
              />
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});

export default CreateInvoicePaymentInDialog;
