import { styled } from "@mui/material";

export const UseShowTemplateStyles = styled("div")(({ theme }) => ({
  "& table tr": {
    background: "none !important",
  },
}));

export default ({ children }: { children: string }) => {
  return (
    <UseShowTemplateStyles
      style={{ display: "inline-block" }}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};
