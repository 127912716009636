import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Dialog,
  Box,
  TextareaAutosize,
  Typography,
  Button,
} from "@mui/material";
import HeaderDialog from "@/components/Dialog/HeaderDialog";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";

const DialogViewNotes = ({
  open,
  setOpen,
  dataDialog,
  setDataDialog,
  createNotes,
  userAdmin,
  setDataAPI,
}) => {
  const [data, setData] = useState<Array<object>>(dataDialog?.data);
  const [addNote, setNewNote] = useState(false);
  const [content, setContent] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const agree = () => {
    const template = {
      content,
      admin: `${userAdmin?.user?.first_name} ${userAdmin?.user?.last_name}`,
      created: format(new Date(), "dd-MM-yyyy"),
      token_user: dataDialog?.token,
    };

    setDataAPI((p: any) => {
      const t: Array<any> = [...p.feedbacks];

      t.forEach((e: any, i: number) => {
        if (
          dataDialog?.person === "user" &&
          Array.isArray(e?.user?.notes_feedback) &&
          e?.user?.token === dataDialog?.token
        )
          if (e?.user?.notes_feedback?.length) {
            t[i].user.notes_feedback[0].num_notes++;
          } else {
            t[i].user.notes_feedback.push({
              token_user: dataDialog?.token,
              num_notes: 1,
            });
          }

        if (
          dataDialog?.person === "tutors" &&
          Array.isArray(e?.event?.tutors?.notes_feedback) &&
          e?.event?.tutors?.token === dataDialog?.token
        )
          if (e?.event?.tutors?.notes_feedback?.length) {
            t[i].event.tutors.notes_feedback[0].num_notes++;
          } else {
            t[i].event.tutors.notes_feedback.push({
              token_user: dataDialog?.token,
              num_notes: 1,
            });
          }
      });

      return { ...p, feedbacks: t };
    });

    setOpenConfirm(false);
    setNewNote(false);
    setContent("");
    createNotes(template);

    setData((p) => {
      const t = [...p];
      t.push(template);
      return t;
    });
  };

  const close = () => {
    setOpenConfirm(false);
    setOpen(false);
    setNewNote(false);
    setContent("");
    setData([]);
    setDataDialog({});
  };

  useEffect(() => setData(dataDialog?.data), [dataDialog?.data]);

  return (
    <EduModal
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={close}
    >
      <EduModalTitle>{dataDialog?.fullNome}</EduModalTitle>

      <EduModalBody className={`p-5 flex flex-col ${data?.length && "gap-8"}`}>
        {!data?.length && dataDialog?.numFeedback && (
          <Box className="pb-4">loading...</Box>
        )}

        <Box className="flex flex-col gap-4">
          {data?.map((e: any, i) => (
            <Box
              key={i}
              className="flex flex-col border rounded border-gray-800"
            >
              <Box className="border-b border-gray-800 p-2 pb-1">
                {e?.admin} wrote on {e?.created}
              </Box>
              <Typography className="p-2 pt-4">{e?.content}</Typography>
            </Box>
          ))}
        </Box>

        <Box className="flex gap-2 flex-col">
          {addNote ? (
            <>
              <Box className="flex gap-4 items-center justify-between grow">
                <Box className="text-lg">New note</Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenConfirm(true)}
                  disabled={!content}
                >
                  Save
                </Button>
              </Box>

              <TextareaAutosize
                style={{ outline: "none" }}
                color="primary"
                className="border p-3 rounded"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="write here..."
                minRows={5}
              />
            </>
          ) : (
            <Button
              onClick={() => setNewNote(true)}
              variant="outlined"
            >
              add note
            </Button>
          )}
        </Box>
      </EduModalBody>

      <Dialog open={openConfirm}>
        <Box className="flex flex-col gap-4 p-4  bg-edu-800">
          <Box className="text-xl font-bold">Confirmation</Box>
          <Box className="text-center text-lg">
            Do you want to add these notes?
          </Box>
          <Box className="flex justify-end gap-4">
            <Button
              variant="outlined"
              onClick={() => setOpenConfirm(false)}
            >
              disagree
            </Button>
            <Button
              variant="outlined"
              onClick={agree}
            >
              agree
            </Button>
          </Box>
        </Box>
      </Dialog>
    </EduModal>
  );
};

export default DialogViewNotes;
