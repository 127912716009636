import { getCookie } from "@/components/Cookie";
import axios from "axios";

// Oggetto per tracciare le richieste pendenti
const pendingRequests: { [key: string]: AbortController } = {};

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

/**
 * Makes an HTTP request using the specified API with provided parameters and options.
 *
 * @param path The path of the resource to which the request is made.
 *             The starting `baseURL` for default use `import.meta.env.VITE_BASE_URL`.
 * @param params An object containing the parameters to be sent in the request (optional).
 * @param options An object containing the options for the request (optional).
 *                The supported options are:
 *                - `type`: A string representing the HTTP method to use (optional).
 *                - `headers`: An object containing custom headers for the request (optional).
 *                             The object must have the following properties:
 *                             - `Authorization`: A string containing the authorization header (optional).
 *                             - `Accept`: A string containing the accepted content type (optional).
 *                             - `Content-type`: A string containing the sent content type (optional).
 *                - `useErrors`: A boolean indicating whether to use errors from the response (optional).
 *                - `pathLiberty`: A boolean indicating the path api is base, without "/admin" (optional).
 * @returns A promise that resolves with the response data of the request.
 * @throws Error if the request was not successful and `useErrors` is false in the options.
 */
export default async (
  path: string,
  params?: object,
  options?: {
    type?: "get" | "post" | "put" | "patch" | "delete";
    headers?: {
      Authorization?: string;
      Accept?: string;
      "Content-Type"?: string;
    };
    useErrors?: boolean;
    pathLiberty?: boolean;
    // responseType?: "json" | "text" | "blob", //added to support these types
    // returnFullResponse?: boolean;
  }
): Promise<any> => {
  const method = options?.type?.toLowerCase() || "post";
  const requestKey = `${method}:${path}`;

  // Annulla la richiesta pendente precedente se esiste
  if (pendingRequests[requestKey]) {
    pendingRequests[requestKey].abort();
  }

  const controller = new AbortController();
  pendingRequests[requestKey] = controller;

  // Set the authorization header including the access token if provided, otherwise use the "user" cookie
  //@ts-ignore
  api.defaults.headers = {
    ...api.defaults.headers,
    Authorization: `Bearer ${getCookie("user")}`,
    ...options?.headers,
  };

  api.defaults.baseURL = options?.pathLiberty
    ? import.meta.env.VITE_BASE_URL.replace("admin", "")
    : import.meta.env.VITE_BASE_URL;

  try {
    // Make a request to the API using the method specified in the options (default: "post")
    // Return the response data
    const response = await api.request({
      url: path,
      data: params,
      method: method,
      // responseType: options?.responseType || "json", // added to support the type json
      signal: controller.signal,
    });

    // Rimuovi la richiesta pendente completata
    delete pendingRequests[requestKey];

    return response.data;
    // Return full response or just data
    // I added this option because I noticed I am unable to view pdf files when
    // the API endpoint is returning a blob
    // return options?.returnFullResponse ? response : response.data;
    // if (options?.returnFullResponse === true) {
    //   return response;
    // } else {
    //   return response.data;
    // }
  } catch (error: any) {
    // Rimuovi la richiesta pendente fallita
    delete pendingRequests[requestKey];

    if (options?.useErrors) {
      // If the `useErrors` option is true, return errors from the response
      return {
        data: error.response?.data,
        errors: error.response?.data?.errors,
      };
    } else {
      // Otherwise, raise an error with the message from the response
      throw Error(error.response?.data?.message);
    }
  }
};
