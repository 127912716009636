import { forwardRef, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Send,
  Close,
  Email,
  Comment,
  WbSunny,
  MoreVert,
  Brightness3,
  ContactMail,
  Notifications,
  SettingsSuggest,
  MotionPhotosAuto,
} from "@mui/icons-material";
import useAPI from "@/api/useAPI";
import InfoIcon from "@mui/icons-material/Info";
import Dropdown from "@/components/_Common/Dropdown";
import MyTooltip from "@/components/MyTooltip";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export interface IRef {
  open: () => void;
}

interface IProps { }

const getIcon = (version: string) => {
  switch (version) {
    case "set":
      return <SettingsSuggest />;
    case "active_campaign":
      return <ContactMail />;
    case "background":
      return <MotionPhotosAuto />;
    case "email":
      return <Email />;
    case "notification":
      return <Notifications />;
    case "wati":
      return <Comment />;
    case "close":
      return <Close />;
    default:
      return <Close />;
  }
};

const ShowStructureAutomation = forwardRef<IRef, IProps>((_, ref) => {
  const theme = useTheme();
  const { snackHandler } = useAuth();

  const [open, setOpen] = useState<boolean>(false);
  const [actionCallback, setActionCallback] = useState<{
    icon: any;
    actionCallback: string;
  }>();

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const { data, isLoading } = useQuery(
    ["show-structure-automation-availabilities"],
    () => useAPI("/v1/settings/flow/get/show-structure")
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleForceRun = async (
    actionCallbackParam: string | undefined = undefined
  ) => {
    const icon = actionCallback?.icon;

    setActionCallback(undefined);

    if (!actionCallbackParam) {
      snackHandler(<>Error into button! {icon}</>);
      return;
    }

    const res = await useAPI("/v1/availabilities/structure/force-action", {
      actionCallback: actionCallbackParam,
    });

    if (!res?.status) {
      snackHandler(res?.message ?? <>Failed run manually! {icon}</>, "error");
      return;
    }

    snackHandler(<>Success run manually! {icon}</>);
  };

  const columns: GridColDef[] = [
    {
      field: "intoDay",
      headerName: "",
      width: 50,
      renderCell: ({ row }) => (
        <MyTooltip title={row?.intoDay}>
          {row?.intoDay == "morning" ? <WbSunny /> : <Brightness3 />}
        </MyTooltip>
      ),
    },
    {
      field: "date",
      headerName: "Action date",
      width: 150,
    },
    {
      field: "days",
      headerName: "Nr. Days before deadline",
      width: 140,
    },
    {
      field: "icon",
      headerName: "",
      width: 50,
      renderCell: ({ row }) => getIcon(row?.forIcon),
    },
    {
      field: "action",
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.action}</MyTooltip>,
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      hideable: false,
      editable: false,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              title="Run manually"
              onClick={() =>
                setActionCallback({
                  icon: getIcon(row?.forIcon),
                  actionCallback: row?.actionCallback,
                })
              }
            >
              <ListItemIcon>
                <Send fontSize="small" />
              </ListItemIcon>
              <ListItemText>Run manually</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="xl"
      >
        <EduModalTitle
          onClose={handleClose}
          className="flex gap-3"
        >
          <InfoIcon />
          Show structure automation
        </EduModalTitle>

        <EduModalBody className="p-5">
          <Box>Deadline: {data?.data?.deadline}</Box>

          <DataGrid
            autoHeight
            disableColumnMenu
            disableColumnFilter
            disableVirtualization
            disableSelectionOnClick
            sx={getTableStyling(theme)}
            rows={data?.data?.actions ?? []}
            columns={columns}
            loading={isLoading}
            pageSize={10}
            hideFooter={data?.data?.actions?.length <= 10}
            rowsPerPageOptions={[10]}
            experimentalFeatures={{ newEditingApi: false }}
          />
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={Boolean(actionCallback?.actionCallback)}
        onClose={() => setActionCallback(undefined)}
        onConfirm={() => handleForceRun(actionCallback?.actionCallback)}
      >
        Do you want to run manually this{" "}
        <span className="px-1">{actionCallback?.icon}</span> command?
      </ConfirmationDialog>
    </>
  );
});

export default ShowStructureAutomation;
