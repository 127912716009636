import addIcon from "@/assets/icons/add.svg";
import filterIcon from "@/assets/icons/filter.svg";
import searchIcon from "@/assets/icons/search.svg";
import Dropdown from "@/components/_Common/Dropdown";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import AddExerciseContent from "@/pages/Exercises/ExercisesV3/components/AddExerciseContent";
import { useExerciseContext } from "@/pages/Exercises/ExercisesV3/state/ExerciseContext";
import { Box, Button, MenuItem, TextField, useTheme } from "@mui/material";
import AssignOldExerciseContent from "./AssignOldExerciseContent";
import { ExerciseModal } from "./ExerciseModal";

interface ToolBarProps {
  playerRef: React.RefObject<any>;
  watchImgRef: React.RefObject<any>;
}

export default ({ playerRef, watchImgRef }: ToolBarProps) => {
  const theme = useTheme();
  const { paramState, assignOldExerciseState, addNewExerciseOpenState } =
    useExerciseContext();

  const { queryParams, setQueryParams } = paramState;
  const { addNewExerciseOpen, setAddNewExerciseOpen } = addNewExerciseOpenState;
  const { assignOldExerciseOpen, setAssignOldExerciseOpen } =
    assignOldExerciseState;

  return (
    <Box className="flex gap-4 itels-stretch">
      <Button
        title="Add new exercises"
        variant="outlined"
        onClick={() => {
          setAddNewExerciseOpen(true);
        }}
      >
        <img
          src={addIcon}
          className="icon"
        />
        Add
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          setAssignOldExerciseOpen(true);
        }}
      >
        <img
          src={addIcon}
          className="icon"
        />
        Assign
      </Button>

      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={Boolean(queryParams?.filters?.version)}
      >
        <Box className="flex flex-col w-[250px] gap-5 p-5">
          <TextField
            select
            fullWidth
            label="Version"
            value={queryParams?.filters?.version ?? ""}
            onChange={(e) =>
              setQueryParams((prevQueryParams) => ({
                ...prevQueryParams,
                filters: { version: e.target.value },
              }))
            }
          >
            <MenuItem
              key="ALL"
              value=""
            >
              ALL
            </MenuItem>

            {["NEW", "OLD"].map((item) => (
              <MenuItem
                key={item}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Dropdown>

      <TextField
        sx={{ marginLeft: "auto", maxWidth: "200px" }}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              style={{ marginRight: 10, filter: theme.palette.filter }}
            />
          ),
        }}
        name="search"
        size="small"
        variant="outlined"
        value={queryParams.filters["search"] || ""}
        onChange={(e) =>
          setQueryParams((prevQueryParams) => ({
            ...prevQueryParams,
            filters: { search: e.target.value },
          }))
        }
        placeholder="Search..."
      />

      {addNewExerciseOpen && (
        <ExerciseModal
          open={addNewExerciseOpen}
          icon={
            <img
              src={addIcon}
              alt="Add icon"
            />
          }
          title="Add New Ielts Exercise"
          onClose={() => setAddNewExerciseOpen(false)}
        >
          <AddExerciseContent
            playerRef={playerRef}
            watchImgRef={watchImgRef}
          />
        </ExerciseModal>
      )}

      {Boolean(assignOldExerciseOpen) && (
        <EduModal
          fullWidth
          open={Boolean(assignOldExerciseOpen)}
          maxWidth="md"
        >
          <EduModalTitle onClose={() => setAssignOldExerciseOpen(false)}>
            Exercises Assignment
          </EduModalTitle>

          <EduModalBody className="p-5 pt-7">
            <AssignOldExerciseContent />
          </EduModalBody>
        </EduModal>
      )}
    </Box>
  );
};
