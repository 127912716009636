import { MenuItem, TextField } from "@mui/material";

interface INProps {
  sx?: object;
  value: string;
  items: Array<string>;
  label?: string;
  onChange: (v: any) => void;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  firstItem?: boolean;
  placeholder?: string;
}

const CustomSelect = ({
  sx = {},
  label,
  value,
  items,
  required = false,
  disabled = false,
  onChange,
  fullWidth = true,
  placeholder,
}: INProps) => (
  <TextField
    select
    sx={sx}
    label={label}
    value={value}
    color="primary"
    variant="outlined"
    required={required}
    disabled={disabled}
    onChange={(e) => onChange(e.target.value)}
    autoFocus={false}
    fullWidth={fullWidth}
    placeholder={placeholder}
    SelectProps={{ displayEmpty: true, defaultValue: "" }}
    InputLabelProps={{ shrink: true }}
  >
    {items.map((el: string, i: number) => (
      <MenuItem
        key={el + i}
        value={el}
      >
        {el}
      </MenuItem>
    ))}
  </TextField>
);

export default CustomSelect;
