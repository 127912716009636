import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { queryClient } from "@/main";
import { getTableStyling } from "@/providers/Colors";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import DiscountIcon from "@mui/icons-material/Discount";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import saveIcon from "@/assets/icons/save.svg";
import useAPI from "@/api/useAPI";

interface IDiscountCode {
  code: string;
  amount: number;
  active: boolean;
  expires_at: string | null;
  is_percentage: boolean;
}

const INI_DATA: IDiscountCode = {
  code: "",
  amount: 0,
  active: true,
  expires_at: null,
  is_percentage: false,
};

interface DiscountError {
  code?: string[];
  amount?: string[];
  active?: string[];
  expires_at?: string[];
  is_percentage?: string[];
}

const DiscountDialog = ({ show, hideDialog, data: discountData }) => {
  const theme = useTheme();

  const [errors, setErrors] = React.useState<DiscountError>();
  const [formData, setFormData] = React.useState<IDiscountCode>(INI_DATA);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof IDiscountCode;

    let value: string | boolean | null = null;

    // Boolean
    if (["active", "is_percentage"].includes(name)) {
      value = e.target.checked;
    } 
    // Upper case
    else if (name === "code") {
      value = e.target.value.toUpperCase();
    } 
    // Nullable
    else if (name === "expires_at" && !e.target.value) {
      value = null;
    } 
    // Only number
    else if (name === "amount") {
      value = e.target.value.replace(/[^0-9]/g, "");
    } 
    // other
    else {
      value = e.target.value;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const cols: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Button onClick={() => navigator.clipboard.writeText(row.code)}>
            {row.code}
          </Button>
        );
      },
    },
    {
      field: "amount",
      headerName: "Discount",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>{row.is_percentage ? row.amount + "%" : row.amount + "€"}</Box>
        );
      },
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      renderCell: ({ row }) => {
        return <Box>{row.active ? <DoneIcon /> : <ClearIcon />}</Box>;
      },
    },
  ];

  const createDiscountCode = async (params: IDiscountCode) => {
    try {
      return await useAPI("/v1/products/discount", params);
    } catch (error: any) {
      if (error?.response) {
        setErrors(error?.response?.data?.errors);
        setIsLoading(false);
        throw error?.response?.data?.errors;
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const { data } = await createDiscountCode(formData);

    setIsLoading(false);

    if (data) {
      await queryClient.setQueryData(["products"], (oldData: any) => {
        return {
          ...oldData,
          discount_codes: [...oldData?.discount_codes, data],
        };
      });
    }
  };

  return (
    <EduModal
      fullWidth
      open={show}
      onClose={() => hideDialog(false)}
    >
      <EduModalTitle onClose={() => hideDialog(false)}>
        <DiscountIcon className="icon" /> Discount
      </EduModalTitle>

      <EduModalBody className="p-5">
        <Box
          className="grid grid-cols-2 gap-4"
          component={"form"}
          onSubmit={handleSubmit}
        >
          <TextField
            required
            label="Code"
            placeholder="Code"
            variant="outlined"
            name="code"
            helperText={errors?.code ? errors?.code?.join("\n") : ""}
            value={formData?.code || ""}
            onChange={handleChange}
          />

          <TextField
            required
            label="Amount"
            variant="outlined"
            placeholder="Amount"
            name="amount"
            value={formData?.amount || ""}
            helperText={errors?.amount ? errors?.amount?.join("\n") : ""}
            onChange={handleChange}
          />

          <FormControlLabel
            control={
              <Switch
                name="active"
                checked={formData?.active}
                onChange={handleChange}
              />
            }
            label="Active"
          />

          <FormControlLabel
            control={
              <Switch
                name="is_percentage"
                checked={formData?.is_percentage}
                onChange={handleChange}
              />
            }
            label="Is percentage"
          />

          <TextField
            label="Expires At"
            variant="outlined"
            name="expires_at"
            value={formData?.expires_at || ""}
            onChange={handleChange}
            type="date"
            InputLabelProps={{
              shrink: true,
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />

          <div className="flex justify-end">
            <LoadingButton
              variant="outlined"
              type="submit"
              loading={isLoading}
            >
              <img
                src={saveIcon}
                className="icon"
              />{" "}
              Save
            </LoadingButton>
          </div>
        </Box>

        <DataGrid
          autoHeight
          rows={discountData}
          columns={cols}
          sx={getTableStyling(theme)}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: false }}
          components={{ Pagination: CustomPaginationClient }}
        />
      </EduModalBody>
    </EduModal>
  );
};

export default DiscountDialog;
