import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import nextIcon from "@/assets/icons/next.svg";
import backIcon from "@/assets/icons/back.svg";

interface IProps {
  page: number;
  count: number;
  hideTotal?: boolean;
  rowsPerPage: number;
  rowSelected?: number;
  onPageChange: (...params: any) => void;
}

const CustomPagination = ({
  page,
  count,
  hideTotal = false,
  rowsPerPage,
  rowSelected,
  onPageChange,
}: IProps) => {
  const theme = useTheme();

  const from = rowsPerPage * page + 1;
  let to = rowsPerPage * (page + 1);

  if (to > count) {
    to = count;
  }

  const totalPage = Math.ceil(count / rowsPerPage);
  if (!totalPage || totalPage <= 1) return <></>;

  return (
    <Box className="flex justify-between items-center w-full mt-5 gap-4">
      <Grid
        container
        alignItems="center"
      >
        <Grid item>
          <Button
            sx={{ marginRight: "10px", background: "none" }}
            variant="outlined"
            disabled={page === 0}
            onClick={(e) => onPageChange(e, page - 1)}
          >
            <img
              src={backIcon}
              style={{ padding: "6px", filter: theme.palette.filter }}
            />
          </Button>
        </Grid>

        <Grid item>
          <Typography
            color="primary"
            variant="caption"
            fontSize="0.875rem"
            style={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: "8px",
              padding: "7px 30px",
            }}
          >
            {from} - {to}
            {hideTotal ? "" : " of " + count}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant="outlined"
            sx={{ marginLeft: "10px", background: "none" }}
            disabled={to >= count}
            onClick={(e) => onPageChange(e, page + 1)}
          >
            <img
              src={nextIcon}
              style={{ padding: "6px", filter: theme.palette.filter }}
            />
          </Button>
        </Grid>
      </Grid>

      {Boolean(rowSelected) && (
        <Box className="flex flex-col">
          <span>
            {rowSelected} row{Number(rowSelected) > 1 && "s"}
          </span>
          <span>selected</span>
        </Box>
      )}
    </Box>
  );
};

export default CustomPagination;
