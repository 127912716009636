import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, LinearProgress, Link } from "@mui/material";
import logo from "@/assets/logoBlack.svg";
import jsPDF from "jspdf";
import provinces from "provinces";
import useUserApi from "@/api/useUserApi";
import html2canvas from "html2canvas";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";

import provincieItaliane from "../assets/provincie-italiane.json";

const PdfInvoice = () => {
  const { id, invoiceId } = useParams();
  const { callGetUser } = useUserApi();

  if (!id) return null;

  const { data: user, isLoading } = callGetUser(id);

  const [disabled, setDisabled] = useState<boolean>(false);

  const getNetPrice = (price: any): any => {
    price = parseFloat(price);
    price = price - (price / 100) * 18.0327868852459;
    return price.toFixed(2);
  };

  const pdfData = user?.payments?.find((payment: { id: number }) =>
    invoiceId ? payment.id === parseInt(invoiceId) : false
  );

  const exportPdf = async () => {
    setDisabled(true);

    const capture = document.querySelector("#capture") as HTMLElement;

    const canvas = await html2canvas(capture, { scale: 5 });

    const img = canvas.toDataURL("image/png");
    const wid = canvas.width;
    const hgt = canvas.height;
    const hratio = hgt / wid;
    const doc = new jsPDF("p", "px", "a4");
    const width = doc.internal.pageSize.width;
    const height = width * hratio;

    doc.addImage(img, "JPEG", 0, 0, width, height, "someAlias", "FAST");
    doc.save(user?.first_name + " " + user?.last_name + " invoice.pdf");

    setDisabled(false);

    if (capture)
      html2canvas(capture, { scale: 5 }).then((canvas) => {
        const img = canvas.toDataURL("image/png");
        const wid = canvas.width;
        const hgt = canvas.height;
        const hratio = hgt / wid;
        const doc = new jsPDF("p", "px", "a5");
        const width = doc.internal.pageSize.width;
        const height = width * hratio;
        doc.addImage(img, "JPEG", 0, 0, width, height, "someAlias", "FAST");
        doc.save(user?.first_name + " " + user?.last_name + " invoice.pdf");
      });
  };

  // const country = useMemo(
  //   () =>
  //     provincieItaliane
  //       .find((p: any) => p.name === user?.provincia)
  //       ?.country.trim() ||
  //     provinces.find((p: any) => p.name === user?.provincia)?.country.trim() ||
  //     user?.provincia,
  //   [user]
  // );

  if (isLoading && !pdfData) return <LinearProgress />;

  return (
    <Box className="mx-auto p-10 flex flex-col items-start gap-10">
      <Button
        disabled={disabled}
        sx={{ p: "1rem" }}
        variant="outlined"
        onClick={exportPdf}
      >
        {disabled ? <RefreshIcon /> : <DownloadIcon />}
      </Button>

      <Box
        id="capture"
        className="bg-[#fff] rounded-lg pt-12 px-16 pb-10 text-[#2D224C] flex flex-col justify-between gap-10 font-sans"
        style={{ width: "210mm", height: "297mm" }}
      >
        <Box className="flex justify-between items-center">
          <img
            src={logo}
            style={{ width: 170 }}
          />

          <Box className="flex flex-col conte">
            <Box
              className="text-2xl pb-1"
              style={{ fontWeight: "bold" }}
            >
              Edusogno Srl
            </Box>
            <Box className="text-lg">Via Ospedale di Venere 82/b</Box>
            <Box className="text-lg">Bari (BA), 70131</Box>
            <Box className="text-lg">IT08587960728</Box>
          </Box>
        </Box>

        <Box className="grid grid-auto-rows place-content-start text-xl">
          <Box
            className="pb-1 text-2xl"
            style={{ fontWeight: "bold", letterSpacing: "1px" }}
          >
            {user?.first_name + " " + user?.last_name}
          </Box>
          <span>{user?.address}</span>
          <span>
            {user?.city}
            {/* {country ? ` (${country})` : ""} */}
            {user?.zipcode ? `, ${user?.zipcode}` : ""}
          </span>
        </Box>

        <table className="w-full">
          <thead>
            <tr className="bg-[#2D224C] text-[#fff]">
              <th className="text-start font-medium text-lg pt-1 pl-3 pb-6">
                Product
              </th>
              <th className="text-start font-medium text-lg pt-1 pb-6 pl-[11px]">
                Price
              </th>
              <th className="text-end font-medium text-lg pt-1 pr-3 pb-6">
                Net price
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="font-semibold py-3 pl-3">{pdfData?.paid_for}</td>
              <td className="font-semibold py-3 pl-[11px]">
                {pdfData?.amount} €
              </td>
              <td className="text-end font-semibold py-3 pr-3">
                {getNetPrice(pdfData?.amount)} €
              </td>
            </tr>

            <tr>
              <td></td>
              <td className="font-semibold py-2 pl-[11px]">IVA (22%)</td>
              <td className="text-end font-semibold py-3 pr-3">
                {(
                  parseFloat(pdfData?.amount) - getNetPrice(pdfData?.amount)
                ).toFixed(2)}{" "}
                €
              </td>
            </tr>

            <tr>
              <td
                colSpan={3}
                className="font-black text-end text-3xl p-3"
              >
                {pdfData?.amount} €
              </td>
            </tr>
          </tbody>
        </table>

        <Box className="border border-[#2D224C] font-semibold flex flex-col gap-5 p-3 pb-7">
          <Box className="grid grid-cols-2">
            <span>Data di emissione:</span>
            <span>{pdfData?.data}</span>
          </Box>

          <Box className="grid grid-cols-2">
            <span>Metodo di pagamento:</span>
            <span>{pdfData?.type}</span>
          </Box>
        </Box>

        <Box className="w-full flex justify-between items-center gap-5 text-xs font-semibold">
          <Box color={"black"}>Edusogno Srl - P. IVA: IT08587960728 </Box>

          <Link
            style={{ textDecoration: "none" }}
            color={"#2D224C"}
            target={"_blank"}
            href="https://edusogno.com"
          >
            edusogno.com
          </Link>

          <Link
            style={{ textDecoration: "none" }}
            color={"#2D224C"}
            href="mailto:info@edusogno.com"
          >
            info@edusogno.com
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default PdfInvoice;
