//@ts-nocheck

import useAPI from "@/api/useAPI";
import { queryClient } from "@/main";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

const NewComment = (props) => {
  const { user, cacheKey, onClose } = props;
  const problematicUser = user?.problematic_users;

  const { userAdmin } = useAuth();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      updates: {
        issue: "",
        solution: "",
        admin: `${userAdmin?.user?.first_name} ${userAdmin?.user?.last_name}`,
        date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      },
      status: "",
    },
    onSubmit: () => { },
    validationSchema: Yup.object({
      updates: Yup.object().when("status", (status, schema) => {
        if (status?.[0] === "troublesome" || status?.[0] === "peaceful") {
          return schema.shape({
            issue: Yup.string()
              .required("Required")
              .max(350, "Max 350 characters")
              .min(5, "Min 5 characters"),
            solution: Yup.string()
              .required("Required")
              .max(350, "Max 350 characters")
              .min(5, "Min 5 characters"),
          });
        }
        return schema; // Or define another schema for other statuses if needed
      }),
      status: Yup.string()
        .required("Required")
        .oneOf(["troublesome", "peaceful", "no reply"]),
    }),
    validateOnMount: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(formik.errors).length != 0) {
      formik.handleSubmit();
      return;
    }

    setLoading(true);

    const values = formik.values;

    const updates = values?.updates;

    for (const key in updates) {
      if (updates[key] === "") {
        delete updates[key];
      }
    }

    const final = {
      ...values,
      updates: [
        ...problematicUser?.updates,
        {
          ...updates,
          status: values?.status,
        },
      ],
    };

    try {
      const data = await useAPI(
        `/v1/users/problematic/${user.id}/update`,
        final
      );

      if (data?.success) {
        // queryClient.setQueryData(cacheKey, (oldData) => {

        //     const newData = oldData?.data?.map((item) => {
        //         if (item.id === user.id) {
        //             return {
        //                 ...item,
        //                 problematic_users: {
        //                     ...item?.problematic_users,
        //                     updates: final.updates
        //                 }
        //             }
        //         }
        //         return item
        //     })

        //     if (oldData?.data) {
        //         oldData.data = newData
        //     }

        //     return oldData

        // })

        queryClient.invalidateQueries(cacheKey || ["problematicUsers"]);

        formik.resetForm();

        onClose();
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="grid gap-2"
      >
        <div className="grid grid-flow-row md:grid-flow-col gap-2">
          <TextField
            multiline
            rows={4}
            fullWidth
            id="updates.issue"
            name="updates.issue"
            label="Issue"
            value={formik.values.updates.issue}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik?.touched?.updates?.issue &&
              Boolean(formik?.errors?.updates?.issue)
            }
            helperText={
              formik?.touched?.updates?.issue && formik?.errors?.updates?.issue
            }
          />

          <TextField
            multiline
            rows={4}
            fullWidth
            id="updates.solution"
            name="updates.solution"
            label="Solution"
            value={formik.values.updates.solution}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik?.touched?.updates?.solution &&
              Boolean(formik?.errors?.updates?.solution)
            }
            helperText={
              formik?.touched?.updates?.solution &&
              formik?.errors?.updates?.solution
            }
          />
        </div>

        <div className="grid grid-flow-row gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Status</FormLabel>
            <Select
              id="status"
              name="status"
              label="Status"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched?.status && Boolean(formik?.errors?.status)}
            >
              <MenuItem value="troublesome">Troublesome</MenuItem>
              <MenuItem value="peaceful">Peaceful</MenuItem>
              <MenuItem value="no reply">No reply</MenuItem>
            </Select>

            {formik?.touched?.status && formik?.errors?.status && (
              <p className="text-red-500 text-xs">{formik?.errors?.status}</p>
            )}
          </FormControl>

          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
        </div>
      </form>
    </>
  );
};

export default NewComment;
