import React from "react";
import { TextField, Box, useTheme } from "@mui/material";
import searchIcon from "@/assets/icons/search.svg";

interface FilterComponentProps {
  searchTerm: string;
  onSearchTermChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Filter: React.FC<FilterComponentProps> = ({
  searchTerm,
  onSearchTermChange,
}) => {
  const theme = useTheme();

  return (
    <Box mb={2}>
      <TextField
        size="small"
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={onSearchTermChange}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              style={{ marginRight: 10, filter: theme.palette.filter }}
            />
          ),
        }}
      />
    </Box>
  );
};

export default Filter;
