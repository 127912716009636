import { memo } from "react";
import { Popover } from "@mui/material";
import InfoTemplate from "@/pages/Upselling/v2/Crm/components/popover-templates/InfoTemplate";
import CommentsTemplate from "@/pages/Upselling/v2/Crm/components/popover-templates/CommentsTemplate";
import OtherStatusTemplate from "@/pages/Upselling/v2/Crm/components/popover-templates/OtherStatusTemplate";
import ChangeEnglishLevelTemplate from "@/pages/Upselling/v2/Crm/components/popover-templates/ChangeEnglishLevelTemplate";
import EmailTemplate from "@/pages/Upselling/v2/Crm/components/popover-templates/EmailTemplate";
import { INAnchorsEl } from "@/pages/Upselling/v2/Crm/components/crm-upselling.types";
import { useCrmDialogsContext } from "../state/CrmDialogsContext";
import { useCrmContext } from "@/pages/Upselling/v2/Crm/state/CrmV3Context";
import { defaultConfirmDialog } from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import { DEFAULT_EXPIRE_CODE } from "@/pages/Upselling/v2/Crm/helpers/constants";
import { newStartingMonths } from "@/pages/Upselling/v2/Crm/helpers/side-effects";
import { INCodeValues } from "@/pages/Upselling/v2/Crm/components/crm-upselling.types";
import { popoverInfo } from "@/pages/Upselling/v2/Crm/helpers/side-effects";
import PurchaseHistoryTemplate from "./popover-templates/PurchaseHistoryTemplate";
import FeedbacksTemplate from "./popover-templates/FeedBacksTemplate";
export interface PopoverUpsellingProps {
  onUpdateRow: (params: any) => void;
  codeValues: any;
  notesMeetings: any;
  setCodeValues: React.Dispatch<React.SetStateAction<INCodeValues>>;
}

const PopoverUpselling: React.FC<PopoverUpsellingProps> = ({
  onUpdateRow,
  codeValues,
  notesMeetings,
  setCodeValues,
}) => {
  const {
    theme,
    commentsState,
    queryResult,
    productsQueryResult,
    rowSelectedState,
  } = useCrmContext();
  const {
    anchorsElState: { anchorsEl, setAnchorsEl },
    confirmDialogState,
  } = useCrmDialogsContext();

  const handleClose = () =>
    setAnchorsEl((prev: INAnchorsEl) => ({ ...prev, open: false }));

  const templates = {
    info: (
      <InfoTemplate
        popoverInfo={popoverInfo(
          rowSelectedState.rowSelected,
          productsQueryResult.data
        )}
      />
    ),
    comments: (
      <CommentsTemplate
        notesMeetings={notesMeetings}
        comments={commentsState.comments}
        setComments={commentsState.setComments}
        rowSelected={rowSelectedState.rowSelected}
        setConfirmDialogData={confirmDialogState.setConfirmDialogData}
        onCloseConfirmDialog={() =>
          confirmDialogState.setConfirmDialogData(defaultConfirmDialog)
        }
        theme={theme}
        onUpdateRow={onUpdateRow}
      />
    ),
    otherStatus: (
      <OtherStatusTemplate
        anchorsEl={anchorsEl}
        onUpdateRow={onUpdateRow}
        rowSelected={rowSelectedState.rowSelected}
      />
    ),
    changeEnglishLevel: (
      <ChangeEnglishLevelTemplate
        anchorsEl={anchorsEl}
        rowSelected={rowSelectedState.rowSelected}
      />
    ),
    email: (
      <EmailTemplate
        dataAPI={queryResult.data}
        products={productsQueryResult.data}
        codeValues={codeValues}
        setCodeValues={setCodeValues}
        rowSelected={rowSelectedState.rowSelected}
        newStartingMonths={newStartingMonths()}
        DEFAULT_EXPIRE_CODE={DEFAULT_EXPIRE_CODE}
      />
    ),
    purchaseHistory: (
      <PurchaseHistoryTemplate
        payments={rowSelectedState.rowSelected?.payments}
      />
    ),
    feedbacks: (
      <FeedbacksTemplate feedbacks={rowSelectedState.rowSelected?.feedbacks} />
    ),
  };

  return (
    <Popover
      open={Boolean(anchorsEl.open)}
      onClose={handleClose}
      anchorEl={anchorsEl.element}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {templates[anchorsEl.template as keyof typeof templates]}
    </Popover>
  );
};

export default memo(PopoverUpselling);
