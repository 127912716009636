import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { fUpper, fUpperWords } from "@/components/useHelpers";
import { queryClient } from "@/main";
import { typeCersionConvertRowCrm } from "@/components/useGenerateTemplateCrm";
import { AdsClick, SubdirectoryArrowLeft } from "@mui/icons-material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  useTheme,
  TextField,
  FormControlLabel,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import LANGUAGES from "@/components/assets/LANGUAGES";
import ICONS_WHATSAPP from "@/components/assets/ICONS_WHATSAPP";
import BUTTONS_CRM_WHATSAPP from "@/components/assets/BUTTONS_CRM_WHATSAPP";
import SPECIAL_KEYS_WHATSAPP from "@/components/assets/SPECIAL_KEYS_WHATSAPP";

interface ITemplate {
  id?: number;
  lang: string;
  button: string;
  hidden: boolean;
  version: typeCersionConvertRowCrm;
  template: string;
}

export interface IRef {
  edit: (template: ITemplate) => void;
  create: () => void;
}

interface IProps {
  QUERY_KEY: Array<any>;
  snackHandler: (text: string, variant?: "error") => void;
}

const defaultTemplate: ITemplate = {
  lang: "",
  button: "",
  hidden: true,
  version: "crm",
  template: "",
};

interface ICursorSelect {
  end: number;
  start: number;
}

const defaultCursorSelect: ICursorSelect = {
  end: 0,
  start: 0,
};

const DialogTemplate = forwardRef<IRef, IProps>(
  ({ QUERY_KEY, snackHandler }, ref) => {
    const theme = useTheme();

    const inputTemplateRef = useRef<HTMLInputElement>(null);

    const [open, setOpen] = useState<boolean>(false);
    const [template, setTemplate] = useState<ITemplate>(defaultTemplate);
    const [useCursor, setUseCursor] = useState<boolean>(false);
    const [cursorSelect, setCursorSelect] =
      useState<ICursorSelect>(defaultCursorSelect);

    useImperativeHandle(ref, () => ({
      edit: (template) => {
        setOpen(true);
        setTemplate(template);
      },
      create: () => {
        setOpen(true);
        setTemplate(defaultTemplate);
      },
    }));

    const handleClose = () => {
      setOpen(false);
      setTemplate(defaultTemplate);
      setUseCursor(false);
      setCursorSelect(defaultCursorSelect);
    };

    const handleChange = ({ target: { name, value } }) => {
      setTemplate((p: ITemplate) => ({ ...p, [name]: value }));
    };

    const handleChangeHidden = ({ target: { checked } }) => {
      setTemplate((p: ITemplate) => ({ ...p, hidden: !checked }));
    };

    const handleAddSpecialKey = (k: string) => {
      let newTemplate: string = template?.template ?? "";

      if (typeof cursorSelect?.start == "number" && useCursor) {
        newTemplate =
          newTemplate.slice(0, cursorSelect.start) +
          k +
          newTemplate.slice(cursorSelect.end);
      } else {
        newTemplate = newTemplate + k;
      }

      setTemplate((p: ITemplate) => ({ ...p, template: newTemplate }));

      inputTemplateRef.current?.focus();
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();

      const res = await useAPI(
        `/v1/crm/templates-whatsapp-web/${
          !template?.id && template?.id == undefined ? "create" : "edit"
        }`,
        template
      );

      if (!res?.status) {
        snackHandler(res?.message ?? "Failed save!", "error");
        return;
      }

      handleClose();
      snackHandler("Success save!");
      queryClient.invalidateQueries(QUERY_KEY);
    };

    return (
      <EduModal
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="xl"
      >
        <EduModalTitle onClose={handleClose}>
          <img
            src={template?.id == undefined ? addIcon : editIcon}
            className="icon"
          />
          {template?.id == undefined ? "Add template" : "Edit template"}
        </EduModalTitle>

        <EduModalBody
          onSubmit={handleSubmit}
          component="form"
          className="grid grid-cols-5 gap-5 p-5"
        >
          <Box className="flex flex-col gap-5 col-span-3">
            <Box className="flex flex-col lg:flex-row gap-5">
              <TextField
                select
                required
                fullWidth
                name="lang"
                label="Lang"
                value={template?.lang ?? ""}
                onChange={handleChange}
              >
                {LANGUAGES?.map(({ iso, name }) => (
                  <MenuItem
                    key={iso}
                    value={iso}
                  >
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                required
                fullWidth
                name="button"
                label="Attach to button"
                value={template?.button ?? ""}
                onChange={handleChange}
              >
                {Object.keys(BUTTONS_CRM_WHATSAPP).map((b) => (
                  <MenuItem
                    key={b}
                    value={b}
                    title={BUTTONS_CRM_WHATSAPP[b].label}
                  >
                    <Box className="w-full flex gap-4 items-center">
                      <span>{BUTTONS_CRM_WHATSAPP[b].icon}</span>
                      <span>{BUTTONS_CRM_WHATSAPP[b].label}</span>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                required
                fullWidth
                name="version"
                label="Version crm"
                value={template?.version ?? ""}
                onChange={handleChange}
              >
                {(
                  [
                    "crm",
                    "crm-admission",
                    "crm-upselling",
                  ] as Array<typeCersionConvertRowCrm>
                ).map((v) => (
                  <MenuItem
                    key={v}
                    value={v}
                  >
                    {fUpperWords(v.replace("-", " "))}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box className="flex !items-stretch gap-3">
              <TextField
                required
                fullWidth
                multiline
                name="template"
                label="Template"
                value={template?.template ?? ""}
                minRows={12}
                maxRows={20}
                onClick={(e: any) =>
                  setCursorSelect({
                    end: e.target?.selectionEnd,
                    start: e.target?.selectionStart,
                  })
                }
                onChange={handleChange}
                inputRef={inputTemplateRef}
                placeholder="Write here, use %special_keys% for insert dinamic values"
                InputLabelProps={{ shrink: true }}
              />

              <Box className="flex flex-col items-center gap-3">
                <Button
                  title={useCursor ? "ON" : "OFF"}
                  variant="outlined"
                  onClick={() => setUseCursor((p) => !p)}
                >
                  <AdsClick sx={{ opacity: useCursor ? 1 : 0.3 }} />
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    setTemplate((p: ITemplate) => {
                      let template: string = p.template;

                      if (typeof cursorSelect?.start == "number" && useCursor) {
                        if (cursorSelect.start == cursorSelect.end) {
                          template =
                            template.slice(0, cursorSelect.start) +
                            "%0a" +
                            template.slice(cursorSelect.end);
                        }
                      } else {
                        template = template + "%0a";
                      }

                      return {
                        ...p,
                        template,
                      };
                    });

                    inputTemplateRef.current?.focus();
                  }}
                  className="!p-0 !h-full"
                >
                  <SubdirectoryArrowLeft sx={{ mb: 1 }} />
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className="flex flex-col gap-4 col-span-2">
            <Box className="flex flex-col gap-2">
              <Box
                sx={{ color: theme.palette.primary.main }}
                className="text-sm"
              >
                Add icons into template
              </Box>

              <Box className="flex flex-wrap gap-2">
                {Object.keys(ICONS_WHATSAPP).map((k) => (
                  <Button
                    key={k}
                    title={fUpper(k)}
                    variant="outlined"
                    onClick={() => handleAddSpecialKey(ICONS_WHATSAPP[k].code)}
                  >
                    {ICONS_WHATSAPP[k]?.show ?? ICONS_WHATSAPP[k].value}
                  </Button>
                ))}
              </Box>
            </Box>

            <Box className="flex flex-col gap-2">
              <Box
                sx={{ color: theme.palette.primary.main }}
                className="text-sm"
              >
                Add special keys into template
              </Box>

              <Box className="grid grid-cols-3 gap-3">
                {Object.keys(SPECIAL_KEYS_WHATSAPP).map((k) => (
                  <Button
                    key={k}
                    title={"ex: " + SPECIAL_KEYS_WHATSAPP[k].example}
                    variant="outlined"
                    onClick={() =>
                      handleAddSpecialKey(SPECIAL_KEYS_WHATSAPP[k].code)
                    }
                    className="!leading-4"
                  >
                    {fUpper(k.replaceAll("_", " "))}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>

          <Box className="w-full flex justify-between items-center col-span-5">
            <FormControlLabel
              label={"Use this template: " + (template?.hidden ? "NO" : "YES")}
              control={
                <Checkbox
                  checked={!template?.hidden}
                  onChange={(e) =>
                    handleChangeHidden({
                      target: { checked: e.target.checked },
                    })
                  }
                />
              }
            />

            <Button
              type="submit"
              variant="outlined"
            >
              Save
            </Button>
          </Box>
        </EduModalBody>
      </EduModal>
    );
  }
);

export default DialogTemplate;
