import { ChangeEvent, useEffect, useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { API_GET_TOOGLE, QUERY_GET_TOOGLE } from "@/pages/Prodotti";
import { Box, Button, Drawer, TextField, Typography } from "@mui/material";
import {
  INStatus,
  INUpdateRow,
} from "@/pages/Upselling/v1/Crm/interfacesAndDefaults";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import saveIcon from "@/assets/icons/save.svg";
import videoIcon from "@/assets/icons/video.svg";
import PhoneField from "@/components/_Common/Forms/PhoneField";
import useProductApi from "@/api/useProductApi";
import CalendarEvents from "@/components/CalendarEvents/";
import MeetingEditVideoCallInfo from "./MeetingEditVideoCallInfo";
import { convert } from "../useHelpers";
import cn from "classnames";

const BASE_PAYMENT_LINK =
  "https://academy.edusogno.com/basic_checkout/checkout?price=";

const GENERATE_NUMBER_MONTHS = 12;

export interface INUserMeeting {
  id: number;
  first_name: string;
  email: string;
  phone: string;
  token: string;
  status: string;
  last_name: string;
  updates: undefined | Array<any>;
  tutoring: boolean;
  meeting?: { meet: Array<any>; summary: string } | undefined;
  meetings?: { meet: Array<any>; summary: string } | undefined;
  lang?: string;
}

export interface IFormMeetingDone {
  id?: string | null;
  notes?: string | null;
  pacchetto?: string | null;
  motivation?: string | null;
  english_lvl?: string | null;
  interviewer?: string | null;
  payment_link?: string | null;
  certification?: string | null;
  newStartingMonth?: string | null;
}

export interface INButtons {
  NoShow?: string;
  Reschedule?: string;
  ToBeCalled?: string;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  data: {
    user: INUserMeeting;
    userAdmin: { user: { first_name: string; last_name: string } };
    buttons?: {
      noShow?: { icon: any; name?: string; status: string };
      toBeCall?: { icon: any; name?: string; status: string };
      reschedule?: {
        icon: any;
        name?: string;
        status: string;
        eventMeetingType?: string;
      };
      meetingDone?: {
        icon: any;
        name?: string;
        status: string;
        form: IFormMeetingDone | undefined;
        setForm: (key: string, value: string) => void;
      };
    };
    versionStatus?: INStatus;
  };
  functions?: {
    onUpdateRow?: (param: INUpdateRow) => void;
    onMeetingDone?: (...params: any) => void;
    onSendFormUser?: (params: { id: number; formUser: any }) => void;
    onMeetingDoneOther?: (...params: any) => void;
  };
  isUpselling?: boolean;
  useDataGetToogle?: boolean;
}

const MeetingComponent = ({
  open,
  data,
  onClose,
  functions,
  isUpselling,
  useDataGetToogle,
}: IProps) => {
  const { callGetProducts } = useProductApi();
  const { data: products } = callGetProducts();
  const { userAdmin } = useAuth();

  const useFormUser = Boolean(functions?.onSendFormUser);

  let dataGetToogle: any = null;

  if (useDataGetToogle) {
    dataGetToogle = useQuery(QUERY_GET_TOOGLE, () =>
      useAPI(API_GET_TOOGLE)
    ).data;
  }

  const [status, setStatus] = useState<string>("");
  const [eventId, setEventId] = useState<string>("");
  const [formUser, setFormUser] = useState<any>({});
  const [openCalendarEvents, setOpenCalendarEvents] = useState<boolean>(false);

  const meetingsSorted = convert(data.user?.meetings ?? data.user?.meeting)
    ?.meet?.length
    ? convert(data.user?.meetings ?? data.user?.meeting)?.meet?.sort(
      (a: any, b: any) => a.start.localeCompare(b.start)
    )
    : [];

  const selectedEvent = meetingsSorted?.length
    ? meetingsSorted?.find((e: any) => e.id === eventId)
    : {};

  const handleSetFormMeetingDone = (event: {
    target: { name: string; value: string };
  }) => {
    const name = event.target.name;
    const value = event.target.value;

    if (
      !Object.hasOwnProperty.call(
        data.buttons?.meetingDone?.form,
        "interviewer"
      ) ||
      data.buttons?.meetingDone?.form?.interviewer === "" ||
      !data.buttons?.meetingDone?.form?.interviewer ||
      data.buttons?.meetingDone?.form?.interviewer !==
      userAdmin?.user?.first_name + " " + userAdmin?.user?.last_name
    ) {
      data.buttons?.meetingDone?.setForm?.(
        "interviewer",
        userAdmin?.user?.first_name + " " + userAdmin?.user?.last_name
      );
    }

    data.buttons?.meetingDone?.setForm?.(name, value);
  };

  const resetFormMeetingDone = () => {
    const map = new Set(Object.keys(data?.buttons?.meetingDone?.form || {}));

    map.forEach((key: string) => {
      data.buttons?.meetingDone?.setForm?.(key, "");
    });
  };

  useEffect(() => {
    const meetings = data.user.meetings?.meet;
    if (Array.isArray(meetings) && meetings.length) {
      const first = meetings.find(
        (meet: any) => new Date() < new Date(meet.start)
      );

      if (!first) {
        setEventId(meetings[meetings.length - 1].id);
        data.buttons?.meetingDone?.setForm(
          "id",
          meetings[meetings.length - 1].id
        );
        return;
      }

      setEventId(first.id);
      data.buttons?.meetingDone?.setForm("id", first.id);
    }
  }, []);

  const newStartingMonths =
    data?.buttons?.meetingDone?.form?.newStartingMonth !== undefined
      ? useMemo(() => {
        const futureMonths: Array<string> = [];
        for (let i = 0; i <= GENERATE_NUMBER_MONTHS; i++) {
          const futureMonth = dayjs().add(i, "M");
          const formattedMonth = dayjs(futureMonth).format("MMMM YYYY");
          futureMonths.push(
            formattedMonth.charAt(0).toUpperCase() +
            formattedMonth.slice(1).toLowerCase()
          );
        }
        return futureMonths;
      }, [])
      : null;

  const keysFormMeetingDone = useMemo(
    () => Object.keys(data?.buttons?.meetingDone?.form || {}),
    [data]
  );

  useEffect(() => {
    const selectedMeet = meetingsSorted?.find((e: any) => e.id === eventId);

    if (selectedMeet) {
      for (const key in selectedMeet) {
        data.buttons?.meetingDone?.setForm?.(key, selectedMeet[key]);
      }
    }
  }, [eventId]);

  useEffect(() => {
    return () => {
      resetFormMeetingDone();
    };
  }, [open]);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={useFormUser ? "md" : "sm"}
    >
      <EduModalTitle onClose={onClose}>
        <img src={videoIcon} />
        MODIFY VIDEO CALL INFORMATION - {data?.user?.first_name}{" "}
        {data?.user?.last_name}
      </EduModalTitle>

      <EduModalBody>
        <Box
          className={cn(
            "p-5",
            useFormUser && "md:p-10 grid md:grid-cols-2 gap-10"
          )}
        >
          {useFormUser && (
            <Box
              onSubmit={(e) => {
                e.preventDefault();
                functions?.onSendFormUser?.({ id: data.user.id, formUser });
              }}
              component="form"
              className="flex flex-col w-full gap-5"
            >
              <Typography
                color="primary"
                fontWeight="bold"
              >
                Modify contact information
              </Typography>

              <TextField
                required
                size="small"
                name="first_name"
                label="Name"
                value={formUser?.first_name ?? data.user?.first_name ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFormUser((p: any) => ({
                    ...p,
                    first_name: e.target.value,
                  }))
                }
                inputProps={{ style: { textTransform: "capitalize" } }}
              />

              <TextField
                required
                size="small"
                name="last_name"
                label="Last name"
                value={formUser?.last_name ?? data.user?.last_name ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFormUser((p: any) => ({ ...p, last_name: e.target.value }))
                }
                inputProps={{ style: { textTransform: "capitalize" } }}
              />

              <TextField
                required
                size="small"
                name="email"
                label="Email"
                value={formUser?.email ?? data.user?.email ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFormUser((p: any) => ({ ...p, email: e.target.value }))
                }
              />

              <PhoneField
                required
                size="small"
                name="phone"
                label="Phone"
                value={formUser?.phone ?? data.user?.phone ?? ""}
                onChange={(newPhone: string) =>
                  setFormUser((p: any) => ({ ...p, phone: newPhone }))
                }
              />

              <Box className="flex flex-row-reverse">
                <Button
                  type="submit"
                  variant="outlined"
                >
                  <img
                    src={saveIcon}
                    className="icon"
                  />
                  Save
                </Button>
              </Box>
            </Box>
          )}

          <MeetingEditVideoCallInfo
            {...{
              data,
              status,
              eventId,
              products,
              functions,
              setStatus,
              setEventId,
              isUpselling,
              dataGetToogle,
              selectedEvent,
              meetingsSorted,
              newStartingMonths,
              BASE_PAYMENT_LINK,
              keysFormMeetingDone,
              setOpenCalendarEvents,
              handleSetFormMeetingDone,
            }}
          />
        </Box>

        <Drawer
          sx={{ zIndex: 1300 }}
          open={openCalendarEvents}
          anchor="right"
          onClose={() => setOpenCalendarEvents(false)}
        >
          {data.user ? (
            <CalendarEvents
              user={data.user}
              status={status}
              isUpselling={Boolean(isUpselling)}
              onUpdateRow={(meet: any) => {
                const values: any = { status };

                const isScheduleMeeting =
                  status === data.buttons?.reschedule?.status;

                if (isScheduleMeeting) {
                  let meetings: any = data.user.meetings;

                  const formatMeet = {
                    id: meet?.id,
                    start: meet?.start,
                    end: meet?.end,
                    calendar: meet?.calendar,
                    link: meet?.meet,
                  };

                  if (isScheduleMeeting && meetings) {
                    meetings.meet.push(formatMeet);
                    values.meetings = meetings;
                  } else if (isScheduleMeeting && !meetings) {
                    values.meetings = {
                      meet: [formatMeet],
                      summary: meet?.summary,
                    };
                  }
                }

                functions?.onUpdateRow?.({
                  values,
                  toast: "status",
                  tokenUser: data.user.token,
                  activeApi: !isScheduleMeeting,
                });
                setOpenCalendarEvents(false);
              }}
            />
          ) : (
            "No user selected"
          )}
        </Drawer>
      </EduModalBody>
    </EduModal>
  );
};

export default MeetingComponent;
