import { createRef } from "react";
import useAuth from '@/hooks/useAuth';
import { MoreVert } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import InfoFlowAvailabilities, {
  IRef as IRefInfoFlowAvailabilities,
} from "../dialog/InfoFlowAvailabilities";
import ShowStructureAutomation, {
  IRef as IRefShowStructureAutomation,
} from "../dialog/ShowStructureAutomation";
import useAPI from "@/api/useAPI";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import copyIcon from "@/assets/icons/copy.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const KEY_QUERY = ["availabilities-to-insert"];

const AvailabilitiesToInsert = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { copyToClipboard } = useAuth();

  const refInfoFlowAvailabilities = createRef<IRefInfoFlowAvailabilities>();
  const refShowStructureAutomation = createRef<IRefShowStructureAutomation>();

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () => useAPI("/v1/availabilities/to-insert"),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const handleOnOpenInfoFlow = () => {
    refInfoFlowAvailabilities?.current?.open();
  };

  const handleOnOpenShowStructureAutomation = () => {
    refShowStructureAutomation?.current?.open();
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Full name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) =>
        row?.fullName ? <MyTooltip>{row?.fullName}</MyTooltip> : "",
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 140,
      valueGetter: () => "",
      renderCell: ({ row }) =>
        row?.phone ? <MyTooltip>{row?.phone}</MyTooltip> : "",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 180,
      flex: 0.7,
      renderCell: ({ row }) =>
        row?.email ? <MyTooltip>{row?.email}</MyTooltip> : "",
    },
    {
      field: "product_name",
      headerName: "Product",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row?.products?.at(-1)?.product_name || "",
      renderCell: ({ row }) =>
        row?.products?.at(-1)?.product_name ? (
          <MyTooltip>{row?.products?.at(-1)?.product_name}</MyTooltip>
        ) : (
          ""
        ),
    },
    {
      field: "ltv",
      headerName: "LTV",
      width: 100,
      valueGetter: ({ row }) =>
        row?.payments
          ?.filter((p: any) => p?.product_id == row?.products?.at(-1)?.id)
          .reduce((acc: number, p: any) => acc + Number(p.amount), 0)
          ?.toFixed(2) + "€",
    },
    {
      field: "possible_send_notification",
      width: 60,
      valueGetter: ({ row }) =>
        Boolean(Number(row?.possible_send_notification)) ? "YES" : "",
      renderHeader: () => (
        <Box className="MuiDataGrid-columnHeaderTitle ">
          <MyTooltip title="Possible send notification">P.S.N.</MyTooltip>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem onClick={() => copyToClipboard(row?.email)}>
              <ListItemIcon>
                <img src={copyIcon} />
              </ListItemIcon>
              <ListItemText>Copy email</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <BasePage title="Availabilities to insert">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={dataAPI?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        components={{
          Toolbar: Toolbar,
          Pagination: CustomPaginationClient,
        }}
        sortingMode="server"
        componentsProps={{
          toolbar: {
            KEY_QUERY,
            handleOnOpenInfoFlow,
            handleOnOpenShowStructureAutomation,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <InfoFlowAvailabilities ref={refInfoFlowAvailabilities} />

      <ShowStructureAutomation ref={refShowStructureAutomation} />
    </BasePage>
  );
};

export default AvailabilitiesToInsert;
