import { Dispatch, SetStateAction } from "react";
import { INEmail } from ".";
import { Box, Button, Dialog, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import UseShowTemplate from "@/components/UseShowTemplate";
import { EduModal, EduModalTitle } from "@/components/_Common/Modal/ModalStyles";

interface INProps {
  isMobile: boolean;
  templateEmail: INEmail;
  setTemplateEmail: Dispatch<SetStateAction<INEmail>>;
}

export default ({ isMobile, templateEmail, setTemplateEmail }: INProps) => {
  const onClose = () => setTemplateEmail((p: INEmail) => ({ ...p, open: false }));

  return (
    <EduModal open={Boolean(templateEmail?.open)} onClose={onClose} fullWidth maxWidth="md">
      <EduModalTitle>
        Template email
      </EduModalTitle>

      <Box className={"flex p-8 gap-4 " + (isMobile ? "flex-col" : "")}>
        <TextField
          required
          sx={{ width: "100%" }}
          value={templateEmail?.email ?? ""}
          error={Boolean(templateEmail?.errors?.email)}
          label={templateEmail?.errors?.email || "Send to email"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTemplateEmail((p: INEmail) => ({ ...p, email: e.target.value }))}
          placeholder="example@gmail.com"
          
        />

        <TextField
          required
          sx={{ width: "100%" }}
          value={templateEmail?.subject ?? ""}
          label={templateEmail?.errors?.subject || "Sent to subject"}
          error={Boolean(templateEmail?.errors?.subject)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTemplateEmail((p: INEmail) => ({ ...p, subject: e.target.value }))}
          placeholder="RE: IELTS richiesta link"
          
        />
      </Box>

      <Box className="p-8 overflow-auto w-full">
        <UseShowTemplate>{templateEmail?.template}</UseShowTemplate>
      </Box>

      <Box className="px-8 py-6 bg-edu-800 flex justify-center">
        <Button
          sx={{ px: 4 }}
          variant="outlined"
          disabled={Boolean(templateEmail?.email?.length < 10) || Boolean(templateEmail?.subject?.length < 5) || templateEmail?.loadingSend}
          onClick={() => setTemplateEmail((p: INEmail) => ({ ...p, openConfirm: true }))}
        >
          {templateEmail?.loadingSend ? <RefreshIcon /> : "Send"}
        </Button>
      </Box>
    </EduModal>
  );
};
