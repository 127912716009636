import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ADS from "./ADS";
import Summary from "./Summary";

const TollbarTabs = [
  { value: "1", label: "ADS" },
  { value: "2", label: "Summary" },
];

const AdsPerformance = () => {
  const [tab, setTab] = useState<string>(localStorage.getItem("ads_performance_tab") || "1");

  const handleChangeTab = (_: any, newTab: string) => {
    localStorage.setItem("ads_performance_tab", newTab);
    setTab(newTab);
  };

  return (
    <TabContext value={tab}>
      <Box className="mt-8 mx-5 md:mx-10" style={{ height: "calc(100% - 10rem)" }}>
        <Box component="h2" className="text-3xl font-extrabold text-white mb-3">
          Ads performance
        </Box>

        <Box className="border-b border-gray-800">
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            {TollbarTabs.map(({ value: v, label: l }) => (
              <Tab key={v + l} sx={{ color: "#fff" }} value={v} label={l} />
            ))}
          </TabList>
        </Box>

        <TabPanel style={{ height: "calc(100% - 10rem)" }} sx={{ px: 0 }} value="1">
          <ADS />
        </TabPanel>

        <TabPanel style={{ height: "calc(100% - 10rem)" }} sx={{ px: 0 }} value="2">
          <Summary />
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default AdsPerformance;
