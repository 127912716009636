import { parseISO, format } from "date-fns";
import { Box, IconButton, Menu, MenuItem, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DialpadIcon from "@mui/icons-material/Dialpad";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useAuth from '@/hooks/useAuth';

const infoMap = {
  "Never booked": {
    info1: "status",
    info2: "creationDate",
    info3: "utm_source",
  },
  "No show": {
    info1: "status",
    info2: "creationDate",
    info3: "previousMeeting",
  },
  Upselling: {
    info1: "finishDate",
    info2: "course",
    info3: "attendanceRating",
  },
  Absent: {
    info1: "startMonth",
    info2: "course",
    info3: "missedCondition", //bool
  },
  "Low rating": {
    info1: "lessonDate",
    info2: "tutor",
    info3: "reasons",
  },
  Review: {
    info1: "startMonth",
    info2: "course",
    info3: "attendanceRating",
  },
  "Still to pay": {
    info1: "startMonth",
    info2: "courseCost",
    info3: "whatToPay",
  },
  Suspended: {
    info1: "status",
    info2: "restartMonth",
    info3: "-",
  },
  Recruiting: {
    info1: "dateOfSubmission",
    info2: "creationDate",
    info3: "-",
  },
};

const renderInfoCell = (row: any, field: string) => {
  const fieldMapping = infoMap[row.type] || {};
  const property = fieldMapping[field];
  let displayValue = row[property];

  // Format date if property is a date string
  if (property && typeof row[property] === "string") {
    if (!isNaN(Date.parse(row[property]))) {
      try {
        displayValue = format(parseISO(row[property]), "EEE, dd-MM  HH:mm");
      } catch (error) {
        console.error(error);
      }
    }
  }

  return <Box title={property}>{displayValue}</Box>;
};

const handleNotInterested = (row: any, callUpdateCrm: any) => {
  const { snackHandler } = useAuth();
  callUpdateCrm.mutate({
    id: parseInt(row.id),
    data: { status: "Disinteressato" },
  });

  snackHandler("Updated", "success");
};

const handleNotActive = (row: any, callUpdateCrm: any) => {
  const { snackHandler } = useAuth();
  callUpdateCrm.mutate({
    id: parseInt(row.id),
    data: { status: "Numero non valido" },
  });
  snackHandler("Updated", "success");
};

const handleSeeInCrm = (row: any) => {
  const url = `/admin/crm?search=${encodeURIComponent(row.name)}&page=1`;
  window.open(url, "_blank");
};

const handleScheduleMeeting = (row: any) => {
  const url = `/admin/crm?search=${encodeURIComponent(row.name)}&page=1`;
  window.open(url, "_blank");
};

const renderMenuItems = (
  row: any,
  handleMenuClose: any,
  callUpdateCrm: any,
  handleCommentClick: any,
  handleStandBy: any,
  handleResolve: any
) => {
  const menuItems =
    row.type === "Never booked" || row.type === "No show"
      ? [
        {
          label: "Schedule meeting",
          action: () => handleScheduleMeeting(row),
        },
        { label: "See in CRM", action: () => handleSeeInCrm(row) },
        {
          label: "Numero inesistente / inattivo",
          action: () => handleNotActive(row, callUpdateCrm),
        },
        {
          label: "Not interested",
          action: () => handleNotInterested(row, callUpdateCrm),
        },
      ]
      : row.type === "Upselling"
        ? [
          {
            label: "Schedule meeting",
            action: () => handleScheduleMeeting(row),
          },
          {
            label: "Not interested",
            action: () => handleNotInterested(row, callUpdateCrm),
          },
        ]
        : row.type === "Absent" || row.type === "Low rating"
          ? [
            { label: "Resolved", action: () => handleResolve(row) },
            { label: "Stand By", action: () => handleStandBy(row) },
            { label: "Comment", action: () => handleCommentClick(row) },
          ]
          : row.type === "Review"
            ? [
              { label: "Not interested", action: handleMenuClose },
              { label: "Booked call", action: handleMenuClose },
            ]
            : row.type === "Still to pay"
              ? [{ label: "Paid", action: handleMenuClose }]
              : [{ label: "Close Menu", action: handleMenuClose }];

  return menuItems.map((item, index) => (
    <MenuItem
      key={index}
      onClick={() => {
        item.action();
        handleMenuClose();
      }}
    >
      {item.label}
    </MenuItem>
  ));
};

export const getColumns = (
  handleMenuClick: any,
  handleMenuClose: any,
  menuRow: any,
  anchorEl: any,
  handleDialpadCall: any,
  callUpdateCrm: any,
  handleCommentClick: any,
  handleStandBy: any,
  handleResolve: any
) => [
    {
      field: "name",
      headerName: "Name",
      minWidth: 140,
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => <Box title={row.name}>{row.name}</Box>,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) => <Box title={row.type}>{row.type}</Box>,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 140,
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => <Box title={row.phone}>{row.phone}</Box>,
    },
    {
      field: "calledToday",
      headerName: "Called Today",
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) => (
        <Box title={row.calledToday ? "Called" : "Not Called"}>
          {row.calledToday ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="disabled" />
          )}
        </Box>
      ),
    },
    {
      field: "info1",
      headerName: "Info 1",
      minWidth: 170,
      flex: 1,
      renderCell: ({ row }) => renderInfoCell(row, "info1"),
    },
    {
      field: "info2",
      headerName: "Info 2",
      minWidth: 170,
      flex: 1,
      renderCell: ({ row }) => renderInfoCell(row, "info2"),
    },
    {
      field: "info3",
      headerName: "Info 3",
      minWidth: 170,
      flex: 1,
      renderCell: ({ row }) => renderInfoCell(row, "info3"),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      flex: 1,
      renderCell: ({ row }) => (
        <Box className="flex gap-2">
          <Button onClick={() => handleDialpadCall(row)}>
            <DialpadIcon fontSize="small" />
          </Button>

          <Button onClick={() => handleSeeInCrm(row)}>See in Crm</Button>

          <IconButton
            onClick={(event) => handleMenuClick(event, row.id)}
            aria-haspopup="true"
            aria-controls={menuRow === row.id ? "actions-menu" : undefined}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="actions-menu"
            open={menuRow === row.id}
            onClose={handleMenuClose}
            anchorEl={anchorEl}
          >
            {renderMenuItems(
              row,
              handleMenuClose,
              callUpdateCrm,
              handleCommentClick,
              handleStandBy,
              handleResolve
            )}
          </Menu>
        </Box>
      ),
    },
  ];
