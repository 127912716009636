import { Button, MenuItem, MenuList, Popover } from '@mui/material';
import { useState } from 'react';
import copyIcon from "@/assets/icons/copy.svg";
import useAuth from '@/hooks/useAuth';
const FORM_BASE = import.meta.env.VITE_FORM_BASE;

const LandingPreview = ({ urls, token, basicUrl }) => {
    const { copyToClipboard } = useAuth();
    const [hoveredLink, setHoveredLink] = useState();
    const [link, setLink] = useState();
    const FORM_URL = FORM_BASE + 'ads/' + token;

    const handleLinkHover = (url) => (e) => {
        setHoveredLink(e.currentTarget);
        setLink(url);
    };

    const handlePopoverClose = () => {
        setHoveredLink(undefined);
        setLink(undefined);
    };

    return (
        <>
            <MenuList
            >
                <MenuItem
                    className='p-3'
                    onClick={handleLinkHover(basicUrl)}
                >
                    <div>
                        BASIC LANDING URL
                    </div>

                </MenuItem>
                <MenuItem
                    className='p-3'
                    onClick={handleLinkHover(FORM_URL)}
                >
                    <div>
                        BASIC FORM URL
                    </div>

                </MenuItem>
                {urls?.map((url, index) => (
                    <MenuItem
                        className='p-3'
                        key={index}
                        onClick={handleLinkHover(url?.url + token)}
                    >
                        <div>
                            {
                                url?.url?.split('/')?.[3].split('?')?.[0] || "landing"
                            }

                        </div>

                    </MenuItem>
                ))}
            </MenuList>
            <Popover
                open={Boolean(hoveredLink)}
                anchorEl={hoveredLink}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {Boolean(hoveredLink) && (
                    <div style={{
                        width: '375px',
                        height: '250px',
                        position: 'relative',
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                    }}>

                        <div
                            className='flex items-center p-1 absolute top-0 right-3'
                        >
                            <Button
                                onClick={(e) => link ? copyToClipboard(link) : null}
                                title='copy'
                            >
                                <img
                                    src={copyIcon}
                                    className="icon no-margin"
                                />
                            </Button>

                            <Button
                                onClick={handlePopoverClose}
                                title='close'

                            >
                                <span className='text-[16px]'>
                                    &times;
                                </span>
                            </Button>

                        </div>
                        <iframe
                            title="iframe-preview"
                            src={link + token}
                            style={
                                {
                                    width: '100%',
                                    height: '100%',
                                    border: 'none',
                                    transformOrigin: '0 0',
                                }
                            }
                        />
                    </div>
                )}
            </Popover>
        </>
    );
};

export default LandingPreview;