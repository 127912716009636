import React, { useRef, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartConfiguration
} from 'chart.js';
import {
  BoxPlotController,
  BoxAndWiskers,
} from '@sgratzl/chartjs-chart-boxplot';
import { Box, Card } from '@mui/material';
import boxplot from '@/assets/boxplot.png';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BoxPlotController,
  BoxAndWiskers,
  Title,
  Tooltip,
  Legend
);

const median = (values) => {
  if (values.length === 0) return 0;
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
}

const calculateBoxPlotStats = (values) => {
  const sortedValues = [...values].sort((a, b) => a - b);
  const min = sortedValues[0];
  const q1 = median(sortedValues.slice(0, Math.floor(sortedValues.length / 2)));
  const medianVal = median(sortedValues);
  const q3 = median(sortedValues.slice(Math.ceil(sortedValues.length / 2)));
  const max = sortedValues[sortedValues.length - 1];
  return { min, q1, median: medianVal, q3, max };
}

const BoxPlotComparison = ( props ) => {
  function prepareData(ratings: Array<{averageRating: number | null}>): number[] {
    const validRatings = ratings.filter(rating => rating?.averageRating !== null);
    let ratingValues = validRatings.map(rating => rating?.averageRating);
    ratingValues.sort((a, b) => (a || 0) - (b || 0));
    return ratingValues as number[];
  }  

  let ratingValues = prepareData(props.filteredData.averageAllTutorRating);
  const boxPlotStats = calculateBoxPlotStats(ratingValues);
  
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [chartInstance, setChartInstance] = useState<ChartJS | null>(null);
  const tutorRating = props.averageRating;

  const data: ChartConfiguration<'boxplot'>['data'] = {
    labels: [''],
    datasets: [
      {
        label: 'All tutor',
        data: [
          boxPlotStats
        ],
        backgroundColor: '#D9DAF3',
          borderColor: '#8065C9',
          outlierBackgroundColor: '#B9BCFF',
          borderWidth: 2,
          meanBackgroundColor: '#8065C9',
          lowerBackgroundColor: '#B9BCFF',
      },
    ],
  };

  const config: ChartConfiguration<'boxplot'> = {
    type: 'boxplot',
    data,
    options: {
      indexAxis: 'y',
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: true
        },
      },      
      scales: {
        x: {
            min: Math.min(...ratingValues),
            max: Math.max(...ratingValues),
            // min: 4.5,
            // max: 5,
          }
      }
    }
  }; 

  useEffect(() => {
    let chart: ChartJS | null = null;
  
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        chartInstance?.destroy();
        
        chart = new ChartJS(ctx, config);
        setChartInstance(chart);
      }
    }
    
    return () => {
      chart?.destroy();
    };
  }, [props.filteredData]);
  
  

  return (
    <Card>
      <Box height={420} p={2}>
        <div className="text-eduV2-1000 text-sm font-semibold leading-[13.93px]">
          Performance of tutor cohort
        </div>
        <div className='flex justify-center' style={{height: '240px'}} >
          <canvas ref={canvasRef} aria-label="Grafico di confronto boxplot" />
        </div>
        <div className="text-eduV2-1000 text-xs italic font-semibold leading-[13.93px] pt-3">How to read</div>
        <div className='flex justify-around items-center'>
         <img src={boxplot} 
            width="200px"
            /> 
          <p style={{ width: '420px', fontSize: '9px' }}> 
            <b>Min</b>: It is the lowest tutor average <br />
            <b>Quartile 1</b>: It marks the 25th percentile, meaning 25% of the tutor averages are below Q1. <br />
            <b>Median</b>: It represents the middle value of the data when arranged in ascending order. Half of the tutor averages are below the median, and the other half are above it. <br />
            <b>Quartile 3</b>:  It denotes the 75th percentile, indicating that 75% of the data values are below Q3. <br />
            <b>Max</b>: It is the highest tutor average</p>
        </div>
      </Box>
    </Card>
  );
};

export default BoxPlotComparison;
