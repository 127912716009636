//@ts-nocheck
import { EduModal, EduModalBody, EduModalTitle } from "@/components/_Common/Modal/ModalStyles";
import { Box, Button } from "@mui/material";

const ConfirmDialog = ({ children, open, onClose, clickYes, color = "error" }) => {
  return (
    <EduModal open={open} onClose={() => onClose(false)}>
      <EduModalTitle>Confirmation</EduModalTitle>
      <EduModalBody className="flex flex-col gap-4 p-4 ">
        <Box className="text-center">{children}</Box>
        <Box className="flex justify-end gap-4">
          <Button variant="outlined" onClick={() => onClose(false)} color={color}>
            Disagree
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose(false);
              clickYes();
            }}
          >
            Agree
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default ConfirmDialog;
