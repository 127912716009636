import useAuth from '@/hooks/useAuth';
import { Box, Button } from "@mui/material";
import { PlaylistAddIcon } from "@/components";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import Dropdown from "@/components/_Common/Dropdown";
import FiltersBar from "../_Common/FiltersBar";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "../Toolbars/SearchToolbar";
import usePaymentsOutApi from "@/api/usePaymentsOutApi";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

interface IProps {
  filters: any;
  setPage: Function;
  setFilters: Function;
  onClickAdd: () => void;
  onClickImport: () => void;
}

const CustomToolbarPaymentsOut = ({
  filters,
  setPage,
  setFilters,
  onClickAdd,
  onClickImport,
}: IProps) => {
  const { isMember } = useAuth();

  const { callDownloadPaymentOut } = usePaymentsOutApi();

  const handleExport = () => callDownloadPaymentOut({ filter: filters });

  const tempDates = filters?.dates?.split(",");

  const dates =
    tempDates?.length > 0
      ? [new Date(tempDates[0]), new Date(tempDates[1])]
      : null;

  return (
    <Box
      id="filter_bar"
      className="flex gap-5 flex-wrap !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => {
            setPage(1);
            setFilters({});
          }}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Button
          variant="outlined"
          onClick={onClickAdd}
        >
          <img
            src={addIcon}
            className="icon"
          />
          Add manually
        </Button>

        <Button
          variant="outlined"
          onClick={onClickImport}
        >
          <PlaylistAddIcon className="icon" /> Add in bulk
        </Button>

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(filters?.dates)}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <DateRangePickerField
                title="Creation date"
                onChange={(date) => {
                  if (date) {
                    setFilters((p: any) => {
                      p.set(
                        "dates",
                        dayjs(date.startDate).format("YYYY-MM-DD HH:mm:ss") +
                        "," +
                        dayjs(date.endDate).format("YYYY-MM-DD HH:mm:ss")
                      );
                      return p;
                    });
                  } else {
                    setFilters((p: any) => {
                      p.delete("dates");
                      return p;
                    });
                  }
                }}
                value={dates}
              />
            </Box>
          }
        />

        {!isMember && (
          <Button
            variant="outlined"
            onClick={handleExport}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        setPage={setPage}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};

export default CustomToolbarPaymentsOut;
