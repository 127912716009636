import { fUpper } from "../useHelpers";
import useAuth from '@/hooks/useAuth';
import { Typography, useTheme } from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  VideocamRounded,
  Groups2RoundedIcon,
  DoneAllRoundedIcon,
  PersonAddRoundedIcon,
  // ChatBubbleRoundedIcon,
  VideocamOffRoundedIcon,
  // PhoneInTalkRoundedIcon,
  DoDisturbAltRoundedIcon,
  QuestionMarkRoundedIcon,
  // PhonelinkEraseRoundedIcon,
  AutorenewIcon,
  EventBusyIcon,
  EventAvailableIcon,
  // SendIcon,
  // PersonOutlineIcon,
} from "@/components";
import cn from "classnames";
import dayjs from "dayjs";
import useCrmApi from "@/api/useCrmApi";

const genIcon = (key: string) => {
  switch (key) {
    case "Partially filled":
      return <PersonAddRoundedIcon color="primary" />;
    // case "Admin":
    //   return <PersonOutlineIcon color="primary" />;

    // case "Messaggio WhatsApp":
    // case "Messaggio Whatsapp":
    // case "Whatsapp text 1":
    // case "Whatsapp text 2":
    //   return <ChatBubbleRoundedIcon color="primary" />;

    case "Screened out":
    case "Rejected screening":
    case "Rejected interview":
    case "Offer rejected":
    case "Not interested":
      return <DoDisturbAltRoundedIcon color="primary" />;

    case "Screening scheduled":
    case "Interview with manager scheduled":
      return <Groups2RoundedIcon color="primary" />;

    case "No show":
      return <VideocamOffRoundedIcon color="primary" />;

    // case "Mail Sent":
    //   return <SendIcon color="primary" />;

    case "Interested in future":
      return <EventAvailableIcon color="primary" />;

    case "Schedule screening":
      return <EventBusyIcon color="primary" />;

    case "On-hold":
      return <AutorenewIcon color="primary" />;

    case "Employed":
      return <DoneAllRoundedIcon color="primary" />;

    // case "Da richiamare":
    //   return <PhonelinkEraseRoundedIcon color="primary" />;

    // case "No show":
    //   return <PhoneInTalkRoundedIcon color="primary" />;

    default:
      return <QuestionMarkRoundedIcon color="primary" />;
  }
};

interface IProps {
  data: any;
  title?: string;
  isMeet?: boolean;
}

const TimelineGen = ({ data, isMeet = false, title = "" }: IProps) => {
  const theme = useTheme();
  const { snackHandler, isAdmin } = useAuth();
  const { arrayLabelOptions, options } = useCrmApi();

  const meeting = data;

  return (
    <>
      {Boolean(title) && (
        <Typography
          color="primary"
          className="text-center"
        >
          {title}
        </Typography>
      )}

      <Timeline position="alternate">
        <TimelineItem sx={{ ".MuiTimelineDot-filled": { m: 0, p: 1 } }}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="left"
            title={
              "Date ITA: " + dayjs(meeting?.start).format("YYYY-MM-DD HH:mm:ss")
            }
            variant="body2"
            className="w-[160px] truncate m-auto"
          >
            {isMeet && "Start: "}
            {new Date(meeting?.start)
              .toLocaleString("en-EN", options)
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              )}
            <br />
            {Boolean(meeting?.end) && (
              <>
                End:{" "}
                {new Date(meeting?.end)
                  .toLocaleString("en-EN", options)
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}
              </>
            )}
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineConnector />

            <TimelineDot
              sx={{
                border: 2,
                borderColor: theme.palette.primary.main,
                backgroundColor: "transparent",
              }}
            >
              {isMeet ? (
                <VideocamRounded color="primary" />
              ) : (
                genIcon(meeting?.status)
              )}
            </TimelineDot>

            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent className="w-[160px] !m-auto">
            {isMeet ? (
              ['interviewer', 'status', "personality", "availability", "salary", "languages",].map(
                (k) => {
                  const fK = fUpper(k);
                  const isLink = ["link"].includes(k);
                  const isPerson = ["calendar", "interviewer"].includes(k);

                  return Boolean(meeting?.[k]) ? (
                    <Typography
                      key={k}
                      title={fK + ": " + meeting?.[k]}
                      color={isPerson ? "primary" : ""}
                      className="w-full truncate !text-left"
                    >
                      {fK}:{" "}
                      <span
                        className={cn({
                          "cursor-pointer hover:underline": isLink,
                        })}
                        onClick={() =>
                          isLink ? snackHandler(meeting?.[k]) : null
                        }
                      >
                        {meeting?.[k]}
                      </span>
                    </Typography>
                  ) : null;
                }
              )
            ) : (
              <>
                {/* {Boolean(meeting?.status) && (
                      <Typography
                        title={"Status: " + item.status}
                        className="w-full truncate"
                      >
                        {arrayLabelOptions[item.status] || item.status}
                      </Typography>
                    )} */}

                {Boolean(meeting?.interviewer) && (
                  <Typography
                    color="primary"
                    title={"Interviewer: " + meeting?.interviewer}
                    className="w-full truncate"
                  >
                    {meeting?.interviewer}
                  </Typography>
                )}

                {/* {Boolean(meeting?.action) && (
                      <Typography
                        title={"Action: " + item.action}
                        className="w-full truncate"
                      >
                        {item.action}
                      </Typography>
                    )} */}
              </>
            )}
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </>
  );
};

export default TimelineGen;
