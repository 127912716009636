import { Typography, styled } from "@mui/material";
import CollapseSideBarIcon from "@/assets/icons/collapse-sidebar.svg";
import useAuth from '@/hooks/useAuth';

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  display: "flex",
  marginBottom: 20,
  fontSize: "20px",
  textTransform: "uppercase",
  "& img": {
    filter: theme.palette.filter,
    marginRight: 15,
    cursor: "pointer",
  },
}));

const Title = ({ children, showIcon = true, ...rest }) => {
  const { setStatusSideBar, statusSideBar } = useAuth();

  const toggleSideBar = () => {
    const value = statusSideBar === "open" ? "close" : "open";
    setStatusSideBar(value);
    localStorage.setItem("status-sidebar", value);
  };

  return (
    <PageTitle id="page-title" variant="h4" {...rest}>
      {showIcon && (
        <img src={CollapseSideBarIcon} onClick={() => toggleSideBar()} />
      )}

      {children}
    </PageTitle>
  );
};

export default Title;
