export default [
  { 
    index: 0,
    engShort: "Mon",
    engLong: "Monday",
    itaShort: "Lun",
    itaLong: "Lunedì",
  },
  { 
    index: 1,
    engShort: "Tue",
    engLong: "Tuesday",
    itaShort: "Mar",
    itaLong: "Martedì",
  },
  { 
    index: 2,
    engShort: "Wed",
    engLong: "Wednesday",
    itaShort: "Mer",
    itaLong: "Mercoledì",
  },
  { 
    index: 3,
    engShort: "Thu",
    engLong: "Thustday",
    itaShort: "Gio",
    itaLong: "Giovedì",
  },
  { 
    index: 4,
    engShort: "Fri",
    engLong: "Friday",
    itaShort: "Ven",
    itaLong: "Venerdì",
  },
  { 
    index: 5,
    engShort: "Sat",
    engLong: "Saturday",
    itaShort: "Sab",
    itaLong: "Sabato",
  },
  { 
    index: 6,
    engShort: "Sun",
    engLong: "Sunday",
    itaShort: "Dom",
    itaLong: "Domenica",
  },
];