import * as XLSX from "xlsx";
import { getSurveyData, getAllSurveyData } from "@/api/usersApi";
import dayjs from "dayjs";

type SurveyData = {
  answers: any;
  ID: number;
  user_id: number;
  answer: string;
  created_at: string;
  updated_at: string;
};

type SurveyAnswer = {
  comment: unknown;
  label: string;
  reaction?: number;
  option?: number;
};

// const parseSurveyAnswer = (jsonAnswer: string): SurveyAnswer[] => {
//     try {
//         if (!jsonAnswer || jsonAnswer.trim() === "") {
//             console.error('JSON answer is empty');
//             return [];
//           }

//       return JSON.parse(jsonAnswer);
//     } catch (error) {
//       console.error('Error parsing survey answer:', error);
//       return [];
//     }
//   };

const extractUniqueLabels = (surveyData) => {
  const labels = new Set();
  if (Array.isArray(surveyData)) {
    surveyData.forEach((survey) => {
      const answers = survey.answers;
      if (Array.isArray(answers)) {
        answers.forEach((answer) => labels.add(answer.label));
      }
    });
  }
  return labels;
};

const reactionLabels = ["edusogno", "grammatica", "ielts", "speaking"];

const normalizeLabels = (answer) => {
  if (reactionLabels.includes(answer.label)) {
    if (!("reaction" in answer) && "option" in answer) {
      answer.reaction = answer.option;
      delete answer.option;
    }
  } else {
    if ("reaction" in answer && !("option" in answer)) {
      answer.option = answer.reaction;
      delete answer.reaction;
    }
  }
  return answer;
};

const mapReactionValue = (value) => {
  switch (value) {
    case 0:
      return "Negativa";
    case 1:
      return "Buona";
    case 2:
      return "Molto buona!";
    default:
      return "N/A";
  }
};

const mapOptionValue = (value) => {
  switch (value) {
    case 0:
      return "No";
    case 1:
      return "Sì";
    default:
      return "N/A";
  }
};

const transformSurveyDataForXLSX = (
  surveyData,
  fullName,
  startMonth,
  productName
) => {
  if (!surveyData || surveyData.length === 0) {
    console.error("No survey data found");
    return [];
  }

  const uniqueLabels = extractUniqueLabels(surveyData);
  const uniqueLabelsArray = Array.from(uniqueLabels);
  const headers = [
    "Nome Utente",
    "Mese di Inizio",
    "Corso",
    ...uniqueLabelsArray.flatMap((label) => [label, label + " Commento"]),
  ];

  const transformedData = surveyData.map((survey) => {
    const row = new Array(headers.length).fill("N/A");
    row[0] = fullName;
    row[1] = startMonth;
    row[2] = productName;

    survey.answers.forEach((answer) => {
      const normalizedAnswer = normalizeLabels(answer);
      const labelIndex = uniqueLabelsArray.indexOf(normalizedAnswer.label);
      if (labelIndex !== -1) {
        const valueIndex = labelIndex * 2 + 3;

        row[valueIndex] = reactionLabels.includes(normalizedAnswer.label)
          ? mapReactionValue(normalizedAnswer.reaction)
          : mapOptionValue(normalizedAnswer.option);

        if ("comment" in normalizedAnswer) {
          row[valueIndex + 1] = normalizedAnswer.comment;
        }
      }
    });

    return row;
  });

  return [headers, ...transformedData];
};

// Funzione per esportare i dati del sondaggio
export const exportSurveyDataToCsv = async (
  userToken,
  adminToken,
  fullName,
  startMonth,
  productName
) => {
  try {
    const surveyData = await getSurveyData(adminToken, userToken);

    if (!surveyData || surveyData.length === 0) {
      throw new Error("No survey data found");
    }

    const surveyDataArray = surveyData.user_surveys;
    if (!surveyDataArray || surveyDataArray.length === 0) {
      console.error("No survey data found");
      return [];
    }

    const worksheetData = transformSurveyDataForXLSX(
      surveyDataArray,
      fullName,
      startMonth,
      productName
    );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Survey Data");
    const fileName = `SurveyData-${fullName.replace(
      /\s+/g,
      "_"
    )}-${startMonth}-${productName}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  } catch (error) {
    console.error("Error fetching and exporting survey data:", error);
  }
};

const labelsSurvey = [
  "edusogno",
  "grammatica",
  "ielts",
  "speaking",
  "material",
  "utilization",
  "service",
];

const labelsSurveyView = [
  "esperienza con Edusogno",
  "esperienza con grammatica",
  "esperienza con speaking",
  "esperienza con IELTS",
  "utilità materiali e risorse",
  "facilità piattaforma",
  "esperienza con assistenza",
];

export const exportAllSurveyToCsv = async (adminToken) => {
  try {
    const surveyData = await getAllSurveyData(adminToken);

    if (!surveyData || surveyData.length === 0) {
      throw new Error("No survey data found");
    }

    const worksheetData: any = [];

    const headers = [
      "Data Survey",
      "Nome Utente",
      "Mese di Inizio",
      "Nome percorso",
      ...labelsSurveyView.flatMap((label) => [
        "Domanda " + label,
        "Commento " + label,
      ]),
    ];

    worksheetData.push(headers);

    surveyData?.user_surveys?.forEach((user: any) => {
      user?.user_surveys?.forEach((survey: any) => {
        const row = new Array(headers.length).fill("N/A");
        row[0] = dayjs(survey.start_date).format("DD-MM-YYYY") || "N/A";
        row[1] = user.fullName || "N/A";
        row[2] = user.start_month || "N/A";
        row[3] =
          user.products?.length > 0
            ? user.products[0]?.course_info?.course_name
            : "N/A";

        survey?.answers?.forEach((answer) => {
          const normalizedAnswer = normalizeLabels(answer);
          const labelIndex = labelsSurvey.indexOf(normalizedAnswer.label);
          if (labelIndex !== -1) {
            const valueIndex = labelIndex * 2 + 4;
            if ("reaction" in normalizedAnswer) {
              row[valueIndex] = mapReactionValue(normalizedAnswer.reaction);
            } else {
              row[valueIndex] = mapOptionValue(normalizedAnswer.option);
            }
            if ("comment" in normalizedAnswer) {
              row[valueIndex + 1] = normalizedAnswer.comment || "N/A";
            }
          }
        });

        worksheetData.push(row);
      });
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Survey Data");
    const fileName = `All_Surveys_${new Date().toISOString()}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  } catch (error) {
    console.error("Error fetching and exporting survey data:", error);
  }
};
