import { useMemo } from "react";
import { DATE_OPTIONS } from "@/api/useCrmApi";
import { Box, Typography } from "@mui/material";
import { convert, fUpper, toArray } from "@/components/useHelpers";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import TimelineGen from "./TimelineGen";
import ViewContactIcon from "@/assets/icons/view-contact.svg";

interface INProps {
  user: any;
  open: boolean;
  onClose: () => void;
}

const PersonalDetails = ({ user }) => (
  <Box className="flex flex-col gap-2">
    <Typography
      color="primary"
      variant="h6"
      component="h2"
    >
      Personal details
    </Typography>

    {["first_name", "last_name", "email", "phone", "status"].map((k, i) => (
      <Typography key={k}>
        {!i ? "First name" : i === 1 ? "Last name" : fUpper(k)}:{" "}
        <b>{user[k]}</b>
      </Typography>
    ))}

    <Typography>
      Registration date:{" "}
      <b>
        {new Date(user.created_at)
          .toLocaleString("en-EN", DATE_OPTIONS)
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
      </b>
    </Typography>
  </Box>
);

const LastActions = ({ user }) => {
  const lastMeetingScheduled = useMemo(
    () =>
      toArray(convert(user?.crm_upselling?.updates))
        ?.reverse()
        ?.find((u: any) => u.status == "Colloquio fissato")?.admin,
    [user]
  );

  return (
    <Box className="w-full mt-6 md:mt-0">
      <Typography
        color="primary"
        variant="h6"
        component="h2"
        className="w-full m-auto text-center"
      >
        Last actions
      </Typography>

      <TimelineGen
        data={toArray(convert(user?.crm_upselling?.updates))?.slice(-9)}
      />

      {Boolean(lastMeetingScheduled) && (
        <Box className="w-full m-auto pt-4 text-center">
          Last Meeting scheduled:{" "}
          <Typography
            color="primary"
            className="!inline-block"
          >
            {lastMeetingScheduled}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const ViewCrmDialog = ({ open, onClose, user }: INProps) => {
  if (!user) return null;

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <EduModalTitle onClose={onClose}>
        <img src={ViewContactIcon} />
        USER INFORMATION
      </EduModalTitle>

      <EduModalBody className="md:grid md:grid-cols-2 px-6 pt-5 pb-6">
        <Box>
          <PersonalDetails {...{ user }} />
        </Box>

        {Boolean(user?.crm_upselling?.updates) && <LastActions {...{ user }} />}
      </EduModalBody>
    </EduModal>
  );
};

export default ViewCrmDialog;
