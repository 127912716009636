import { createRef, useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { getUserAccessToken } from "@/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Badge,
  Button,
  Popover,
  useTheme,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  PopoverProduct,
  IAnchorElProduct,
  RenderCellProduct,
  valueGetterProduct,
  defaultAnchorElProduct,
  PopoverSetProductFromPayment,
} from "@/components/User/PopoverProduct";
import PresenceUserDialog, {
  IForwardRef as PresenceUserDialogRef,
} from "@/pages/Users/components/PresenceUserDialog";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import useScreen from "@/hooks/useScreen";
import PhoneIcon from "@mui/icons-material/Phone";
import commentIcon from "@/assets/icons/comments.svg";
import presenceIcon from "@/assets/icons/presence.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomToolbar from "./components/CustomToolbar";
import QrCodeGenerator from "@/components/QrCodeGenerator";
import impersonateIcon from "@/assets/icons/impersonate.svg";
import CommentsUserDialog from "./components/CommentsUserDialog";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const VITE_ROUTE_ACADEMY_USER = import.meta.env.VITE_ROUTE_ACADEMY_USER;

const ProblematicUsers = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { userAdmin } = useAuth();
  const PresenceUserDialogRef = createRef<PresenceUserDialogRef>();
  const CommentsUserDialogRef = createRef<PresenceUserDialogRef>();
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [anchorElPhone, setAnchorElPhone] = useState<any>(null);
  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );
  const [
    anchorElPopoverSetProductFromPayment,
    setAnchorElPopoverSetProductFromPayment,
  ] = useState<IAnchorElProduct>(defaultAnchorElProduct);

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const open = Boolean(anchorElPhone);

  const id = open ? "simple-popover" : undefined;

  const cacheKey = ["problematicUsers"];

  const { data, isLoading } = useQuery(
    cacheKey,
    () => useAPI("/v1/users/problematic"),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const handleGetAccessToken = async (token: string) => {
    const rs = await getUserAccessToken(userAdmin.token, token);
    window.open(
      `${VITE_ROUTE_ACADEMY_USER}/impersonate/${rs.access_token}`,
      "_blank"
    );
  };

  const filteredData = useMemo(() => {
    const searchLower = filterData?.search?.toLowerCase();

    return (data?.data || []).filter((e: any) => {
      if (!searchLower || searchLower === "") return true;
      return (
        e?.full_name?.toLowerCase().includes(searchLower) ||
        e?.start_month?.toLowerCase().includes(searchLower) ||
        e?.problematic_users?.owned_by?.toLowerCase().includes(searchLower) ||
        e?.problematic_users?.status?.toLowerCase().includes(searchLower)
      );
    });
  }, [data, filterData?.search]);

  const handleQrClose = () => {
    setAnchorElPhone(null);
    setSelectedUser(null);

    queryClient.invalidateQueries(cacheKey);
  };

  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectRows] = useState([]);
  const handleRowSelectionChange = (newSelection) => {
    setSelectionModel(newSelection);
    setSelectRows(
      newSelection.map((id: number) =>
        data?.data.find((row: any) => row.id === id)
      )
    );
  };

  const handleUserSelection = (_e, user: any) => {
    setAnchorElPhone(_e.currentTarget);
    setSelectedUser(user);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "full_name",
        headerName: "Full name",
        flex: 1,
        valueGetter: ({ row }) => row?.full_name || "-",
      },
      {
        field: "start_month",
        flex: 1,
        headerName: "Start month",
      },
      {
        field: "phone",
        headerName: "Phone number",
        flex: 1,
        renderCell: ({ row }) =>
          row?.phone ? (
            <Button
              onClick={(e) => handleUserSelection(e, row)}
              className="cursor-pointer"
            >
              <PhoneIcon className="cursor-pointer" />
            </Button>
          ) : (
            "-"
          ),
      },
      {
        field: "product",
        headerName: "Product",
        minWidth: 150,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => valueGetterProduct(row),
        renderCell: ({ row }) => (
          <RenderCellProduct
            {...{
              row,
              setAnchorElProduct,
              setAnchorElPopoverSetProductFromPayment,
            }}
          />
        ),
      },
      {
        field: "attendance",
        flex: 1,
        headerName: "Attendance speaking #",
        valueGetter: ({ row }) => row?.attendance || "-",
      },
      {
        field: "invitees",
        flex: 1,
        headerName: "Invited speaking (#)",
        valueGetter: ({ row }) => row?.invitees || "-",
      },
      {
        field: "owned_by",
        flex: 1,
        headerName: "Owned by",
        valueGetter: ({ row }) => row?.problematic_users?.owned_by || "-",
      },
      {
        field: "status",
        flex: 1,
        headerName: "Status",
        valueGetter: ({ row }) => row?.problematic_users?.status || "-",
      },
      {
        field: "actions",
        headerName: "",
        minWidth: 100,
        sortable: false,
        hideable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) => {
          const comments =
            row?.problematic_users?.updates?.filter(
              (e: any) => e?.status != "no reply"
            ) || [];
          const invisible = !comments || comments?.length === 0;
          const commentsCount = comments?.length || 0;

          return (
            <EduBadge
              color="primary"
              variant="dot"
              invisible={invisible}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Dropdown
                text={<MoreVertIcon />}
                content={
                  <MenuList>
                    <MenuItem
                      color="primary"
                      title="Presence"
                      onClick={() =>
                        PresenceUserDialogRef.current?.open(row.token)
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={presenceIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Presence</ListItemText>
                    </MenuItem>

                    {Boolean(row?.problematic_users?.owned_by) && (
                      <MenuItem
                        color="primary"
                        title="Presence"
                        onClick={() => CommentsUserDialogRef.current?.open(row)}
                      >
                        <ListItemIcon>
                          <img
                            src={commentIcon}
                            className="icon"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          Comments
                          <Badge
                            sx={{ mx: 2 }}
                            color="warning"
                            badgeContent={commentsCount}
                          />
                        </ListItemText>
                      </MenuItem>
                    )}

                    <MenuItem
                      title="Impersonate Academy User"
                      onClick={() => handleGetAccessToken(row?.token)}
                    >
                      <ListItemIcon>
                        <img
                          src={impersonateIcon}
                          className="icon"
                        />
                      </ListItemIcon>
                      <ListItemText>Impersonate</ListItemText>
                    </MenuItem>
                  </MenuList>
                }
              />
            </EduBadge>
          );
        },
      },
    ],
    [filteredData, PresenceUserDialogRef, CommentsUserDialogRef]
  );

  return (
    <BasePage title="Problematic Users">
      <DataGrid
        autoHeight
        disableColumnMenu
        checkboxSelection
        disableVirtualization
        disableSelectionOnClick
        hideFooterSelectedRowCount
        sx={getTableStyling(theme)}
        rows={filteredData || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPaginationClient,
        }}
        selectionModel={selectionModel}
        componentsProps={{
          toolbar: {
            filters,
            setFilters,
            selectedRows,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        onSelectionModelChange={(newSelection) =>
          handleRowSelectionChange(newSelection)
        }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorElPhone}
        onClose={() => handleQrClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {selectedUser?.token && (
          <QrCodeGenerator
            dataText={
              data?.qr_base +
              `/${selectedUser?.token}?auth=${data?.qr_auth_header}`
            }
            disableHover
          />
        )}
      </Popover>

      <PresenceUserDialog ref={PresenceUserDialogRef} />

      <CommentsUserDialog
        ref={CommentsUserDialogRef}
        cacheKey={
          cacheKey as
          | (string & (string | number)[])
          | (number & (string | number)[])
        }
      />

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}

      {Boolean(anchorElPopoverSetProductFromPayment?.element) && (
        <PopoverSetProductFromPayment
          anchorElProduct={anchorElPopoverSetProductFromPayment}
          setAnchorElProduct={setAnchorElPopoverSetProductFromPayment}
        />
      )}
    </BasePage>
  );
};

export default ProblematicUsers;
