import { Box, TextField, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const addValue = 5;

const InputNumber = ({ value, setValue, required = false, error }) => {
  const handleMoreDuration = () => (value ? (typeof value === "number" ? setValue(value + addValue) : setValue(addValue)) : setValue(addValue));
  const handleLoseDuration = () => (typeof value === "number" ? (value < addValue + 1 ? setValue("") : setValue(value - addValue)) : "");

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", right: 10, top: "50%", transform: "translateY(-50%)", zIndex: 10 }}>
        <Button sx={{ p: "0.5rem", minWidth: 0 }} onClick={handleLoseDuration}>
          <RemoveIcon />
        </Button>

        <Button sx={{ p: "0.5rem", minWidth: 0 }} onClick={handleMoreDuration}>
          <AddIcon />
        </Button>
      </Box>

      <TextField
        error={error}
        required={required || false}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          type: "number",
          min: 1,
          onChange: ({ target: { value: v } }: any) => setValue(parseInt(v)),
        }}
        value={!value ? "" : value}
        sx={{ width: "100%" }}
        placeholder="56"
        variant="outlined"
        label="Duration (min)"
      />
    </Box>
  );
};

export default InputNumber;
