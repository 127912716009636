import {
  memo,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { queryClient } from "@/main";
import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import saveIcon from "@/assets/icons/save.svg";
import useUserApi from "@/api/useUserApi";
import SelectUser from "@/components/SelectUser";
import useUserSuspendedApi from "@/api/useUserSuspendedApi";

export interface IForwardRef {
  open: (data?: any) => void;
}

interface IProps {}

const UserSuspenedDialog = forwardRef<IForwardRef, IProps>((_, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(null);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const { callCreateUserSuspend, callUpdateUserSuspend } =
    useUserSuspendedApi();

  const { callGetStartMonths } = useUserApi();
  const { keyCache, callGetUser } = useUserApi();

  const { data: studentInfo } = callGetUser(formData?.student);
  const { data: startMonthsData } = callGetStartMonths();

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);

      if (data) {
        setFormData({
          ...data,
          student: data?.user?.token,
        });
      }
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setFormData(null);
  };

  const handleChange = ({ target: { name, value } }: any) => {
    setFormData((p: any) => ({ ...p, [name]: value }));
  };

  const restartMonthMenu = useMemo(
    () =>
      Array.from({ length: 10 })
        .map((_, i) => dayjs().add(i + 1, "M"))
        .reverse()
        .map((d, i) => (
          <MenuItem
            key={i}
            value={d.format("MMMM YYYY")}
          >
            <Box className="w-full flex justify-between">
              <span>{d.format("MMMM")}</span>
              <span>{d.format("YYYY")}</span>
            </Box>
          </MenuItem>
        )),
    [startMonthsData]
  );

  const updatedStartMonthMenu = useMemo(
    () => [
      <MenuItem
        key="NEVER"
        value="NEVER"
      >
        NEVER
      </MenuItem>,
      Array.from({ length: 10 })
        .map((_, i) => dayjs().add(i + 1, "M"))
        .reverse()
        .map((d, i) => (
          <MenuItem
            key={i}
            value={d.format("MMMM YYYY")}
          >
            <Box className="w-full flex justify-between">
              <span>{d.format("MMMM")}</span>
              <span>{d.format("YYYY")}</span>
            </Box>
          </MenuItem>
        )),
      ...Array.from({ length: 8 }).map((_, i) => {
        const d = dayjs().subtract(i, "M");

        return (
          <MenuItem
            key={i}
            value={d.format("MMMM YYYY")}
          >
            <Box className="w-full flex justify-between">
              <span>{d.format("MMMM")}</span>
              <span>{d.format("YYYY")}</span>
            </Box>
          </MenuItem>
        );
      }),
    ],
    [startMonthsData]
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!formData?.student) return;

    setIsLoadingSubmit(true);

    const data = {
      token_user: formData.student,
      restart_month: formData.restart_month,
      months_to_finish: formData.months_to_finish,
      update_start_month: formData.update_start_month,
    };

    if (!formData?.id) {
      await callCreateUserSuspend.mutateAsync({ data });
    } else {
      await callUpdateUserSuspend.mutateAsync({ id: formData?.id, data });
    }

    setIsLoadingSubmit(false);

    queryClient.invalidateQueries([keyCache, "last-params"]);

    handleClose();
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        {formData?.id != undefined ? (
          <>
            <img
              src={editIcon}
              className="icon"
            />
            Edit Suspened
          </>
        ) : (
          <>
            <img
              src={addIcon}
              className="icon"
            />
            Add Manually
          </>
        )}
      </EduModalTitle>

      <EduModalBody className="p-5 flex flex-col gap-5">
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="flex flex-col gap-5 mt-2"
        >
          {formData?.id == undefined && (
            <>
              <SelectUser
                required
                label="Student"
                value={formData?.student}
                onChange={(value) =>
                  setFormData((p: any) => ({ ...p, student: value }))
                }
              />

              <TextField
                fullWidth
                name="current_path"
                label="Current path"
                value={studentInfo?.products?.[0]?.product_tag || ""}
              />
            </>
          )}

          <Box className="grid grid-cols-2 gap-5">
            {formData?.id == undefined && (
              <TextField
                name="start_month"
                label="Start month"
                value={studentInfo?.start_month || ""}
              />
            )}

            <TextField
              required
              type="number"
              name="months_to_finish"
              label="Months to finish"
              value={formData?.months_to_finish || "1"}
              onChange={(e: any) =>
                setFormData((p: any) => ({
                  ...p,
                  months_to_finish: e.target.value,
                }))
              }
            />

            <TextField
              select
              required
              name="restart_month"
              label="Restart month"
              value={formData?.restart_month || ""}
              onChange={handleChange}
            >
              {restartMonthMenu}
            </TextField>

            <TextField
              select
              required
              name="update_start_month"
              label="Updated start month"
              value={formData?.update_start_month || ""}
              onChange={handleChange}
            >
              {updatedStartMonthMenu}
            </TextField>
          </Box>

          <Box className="flex justify-end">
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoadingSubmit}
              startIcon={
                <img
                  src={saveIcon}
                  className="icon no-margin"
                />
              }
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});

export default memo(UserSuspenedDialog);
