import { useEffect, useState } from "react";

const useMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  let screen = "";

  if (width >= 1440) screen = "large";
  else if (width >= 1024) screen = "normal";
  else screen = "small";

  return { isMobile: width < 1024, screen };
};

export default useMobileScreen;
