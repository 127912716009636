import commentIcon from "@/assets/icons/comments.svg";
import { EduModal, EduModalBody, EduModalTitle } from '@/components/_Common/Modal/ModalStyles';
import { forwardRef, useImperativeHandle, useState } from 'react';
import NewComment from "./NewComment";
import Comments from "./Comments";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface IForwardRef {
    open: (user) => void;
}

interface IProps {
    ref: HTMLButtonElement;
    cacheKey: (string | number)[]
}

const CommentsUserDialog = forwardRef<IForwardRef, IProps>(({ cacheKey }, ref) => {
    const [user, setUser] = useState<any>({});
    const [open, setOpen] = useState<boolean>(false);
    // const { key } = props

    useImperativeHandle(ref, () => ({
        open: (value) => {
            setOpen(true);
            setUser(value);
        },
    }));

    const handleClose = () => {
        setOpen(false);
        setUser({});
    }

    return (
        <EduModal fullWidth maxWidth="sm" open={open} onClose={() => handleClose()}>
            <EduModalTitle onClose={() => handleClose()}>
                <img src={commentIcon} />
                Comments History
            </EduModalTitle>

            <EduModalBody className="flex flex-col p-5">
                <div className="grid gap-2">
                    {
                        !user?.problematic_users || user?.problematic_users?.updates?.length === 0 ? (
                            "No comments for now"
                        ) : (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>View comments history </Typography>
                                </AccordionSummary>
                                <Comments user={user} />
                            </Accordion>
                        )
                    }

                    <span>
                        Leave a comment
                    </span>
                    <NewComment user={user} cacheKey={cacheKey} onClose={handleClose} />
                </div>
            </EduModalBody>
        </EduModal>
    );
});

export default CommentsUserDialog;