import notification from '../../assets/notification.wav'
import { getCookie } from '@/components/Cookie';
import { actions } from './actions_array';

const audio = new Audio(notification);

const showNotification = (title, options) => {
    if ('Notification' in window && Notification.permission === 'granted') {
        const notification = new Notification(title, options);

        notification.onclick = options.onclick;
    }
};


export const fireAction = async (type: string, event: any, message?: string | null | undefined) => {

     actions.forEach(async (action) => {
        if(action.type === type){
            const result = await action.action(event);

            if(result && typeof result === 'object'){
                showNotification(event?.title || 'New Notification from Admin', {
                    body: message || 'You have a new notification.',
                    ...result || { onclick: () => {} }
                });

                if (getCookie('notification_service_sound')) {
                    audio.play();
                }
            }
        }
    })
}