/**
 * @author Bismark Atta FRIMPONG
 * @email battafrimpong@gmail.com
 * @create date 2024-07-30 21:37:08
 * @modify date 2024-07-30 21:37:08
 * @desc this component helps to preview pdf files returned from an api endpoint
 */

import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { Button, Slider, Box } from '@mui/material';
import { EduModal, EduModalBody, EduModalTitle } from './_Common/Modal/ModalStyles';
import PaymentIcon from "@/assets/icons/payment.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
  open: boolean;
  onClose: () => void;
  pdfUrl: string;
  dialogTitle: string;
}

const EdusognoPdfViewer: React.FC<PdfViewerProps> = ({ open, onClose, pdfUrl, dialogTitle }) => {

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);

  const handleLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
  };

  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };

  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    setScale(Array.isArray(newValue) ? newValue[0] : newValue);
  };

  return (
    <EduModal
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <EduModalTitle onClose={onClose}>
        <img
          src={PaymentIcon}
          className="icon no-margin"
        />{" "}
        PDF Viewer - {dialogTitle}
      </EduModalTitle>
      <EduModalBody>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Document
            file={pdfUrl}
            onLoadSuccess={handleLoadSuccess}
            //@ts-ignore
            options={{ workerSrc: pdfjs.GlobalWorkerOptions.workerSrc }}
          >
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </div>

        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
          {numPages && (
            <>
              <Button
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber <= 1}
                variant="outlined"
              >
                Previous
              </Button>
              <span style={{ margin: '0 16px', color: '#b388ff', paddingTop: '7px' }}>
                Page {pageNumber} of {numPages}
              </span>
              <Button
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pageNumber >= numPages}
                variant="outlined"
              >
                Next
              </Button>
            </>
          )}
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
          <Button
            href={pdfUrl}
            download
            variant="contained"
            color="primary"
            style={{ marginRight: 16, height: '40px', fontWeight: '600' }}
          >
            Download
          </Button>
          <div style={{ width: 200 }}>
            <Slider
              value={scale}
              min={0.5}
              max={3}
              step={0.1}
              onChange={handleZoomChange}
              aria-labelledby="zoom-slider"
            />
          </div>
        </Box>
      </EduModalBody>
    </EduModal>
  )
}

export default EdusognoPdfViewer;