import { Box, TextField, Button } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import saveIcon from "@/assets/icons/save.svg";

interface ICDProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  linkExam: any;
  onConfirm: () => void;
  setLinkExam: any;
}

const EditDialog = ({
  open,
  setOpen,
  linkExam,
  setLinkExam,
  onConfirm,
}: ICDProps) => {
  const handleClose = () => {
    setLinkExam("");
    setOpen(false);
  };

  const handleChange = (e: any) => setLinkExam(e);

  return (
    <EduModal fullWidth={true} maxWidth="sm" open={open} onClose={handleClose}>
      <EduModalTitle><img src={saveIcon} className="icon" /> Link exam</EduModalTitle>
      <EduModalBody>
        <Box className="flex flex-col gap-4 p-10">
          <TextField
            color="primary"
            variant="outlined"
            fullWidth
            required
            type="text"
            name="link_exam"
            label="Insert link exam"
            value={linkExam ?? ""}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="https://link_exam..."
          />
          <div className=" flex flex-row-reverse">
            <Button
              variant="outlined"
              // className="w-full"
              type="submit"
              onClick={onConfirm}
            >
              <img src={saveIcon} className="icon" /> Save
            </Button>
          </div>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default EditDialog;
