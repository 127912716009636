import { useRef, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Grid,
  Checkbox,
  MenuItem,
  TextField,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import saveIcon from "@/assets/icons/save.svg";
import LANGUAGES from "@/components/assets/LANGUAGES";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MaterialFileSelect from "./components/MaterialFile";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const FOR = ["User", "Tutor", "Both"];

const lesson = ["Grammar", "Speaking", "Ielts", "Linguaskill"];

export const CATEGORY = [
  "Beginner",
  "Basic",
  "Intermediate",
  "Advanced",
  "Starter",
  "Zero",
  "One-to-One",
  "Writing",
  "General",
  "Reading",
  "Speaking",
  "Listening",
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const isValidURL = (url: string) => {
  const regex =
    /^https:\/\/((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|\d{1,3}(\.\d{1,3}){3})(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[a-z\d_]*)?$/i;
  return regex.test(url);
};

const CATEGORY_MAPPING = {
  Grammar: ["Advanced", "Intermediate", "Basic", "Beginner"],
  Speaking: [
    "Advanced",
    "Intermediate",
    "Basic",
    "Beginner",
    "One-to-One",
    "Zero",
    "Starter",
  ],
  Ielts: ["Reading", "Writing", "Speaking", "Listening"],
  Linguaskill: ["General", "Writing", "Listening", "Speaking"],
};

const CATEGORY_TO_ENGLISH_LEVEL = {
  Zero: ["A0"],
  Starter: ["A0+"],
  Beginner: ["A1"],
  Basic: ["A2", "B1"],
  Intermediate: ["B1+", "B2-"],
  Advanced: ["B2", "B2+", "C1"],
};

interface Data {
  for: string;
  name: string;
  link: string;
  file: string | Blob;
  lesson: string;
  category: string[];
  language: string[];
  precourse: boolean;
  file_name: string;
  english_level: string[];
}

export const DEFAULT_DATA: Data = {
  for: FOR[0],
  name: "",
  file: "",
  link: "",
  lesson: lesson[0],
  category: [],
  language: [],
  file_name: "",
  precourse: false,
  english_level: [],
};

const CreateMaterial = ({ onMutate, openCreate, setOpenCreate }) => {
  const { snackHandler } = useAuth();

  const fileRef = useRef<HTMLInputElement>();

  const [data, setData] = useState<Data>(DEFAULT_DATA);
  const [error, setError] = useState<any>({});
  const [urlError, setUrlError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLinkUsed, setIsLinkUsed] = useState(false);
  const [isFileUsed, setIsFileUsed] = useState(false);

  const loadMaterial = async (e: any) => {
    e.preventDefault();

    if (isLinkUsed && !isValidURL(data.link)) {
      setUrlError("Invalid URL");
      snackHandler("invalid url", "error");
      return;
    }

    const formData = new FormData();

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }

    try {
      setIsLoading(true);

      const res = await useAPI("/v1/material/create", formData, {
        headers: { "Content-Type": "multipart/mixed" },
      });

      setIsLoading(false);

      if (!res?.status) {
        if (res?.errors) {
          setError(res.errors);
        } else {
          setError({ general: res?.message ?? "Failed creating material" });
        }
        return;
      }

      snackHandler("Success created material");
      setData(DEFAULT_DATA);

      if (res?.status) {
        onClose();
      }

      if (fileRef.current) {
        fileRef.current.value = "";
      }

      if (onMutate) {
        onMutate();
      }
    } catch (error) {
      setIsLoading(false);
      setError({ general: "An error occurred while creating material" });
      console.error("Error creating material:", error);
    }
  };

  const handleResourceChange = (event) => {
    const { value } = event.target;
    setIsLinkUsed(!!value);
    setIsFileUsed(false);
    if (!isValidURL(value)) {
      setUrlError("Invalid URL");
    } else {
      setUrlError("");
    }
    setData({ ...data, link: value });
  };

  const handleFileChange = (file: File | null) => {
    if (!file) {
      setIsFileUsed(false);
      setData((p: any) => ({ ...p, file: "", file_name: "", link: "" }));
    } else {
      setIsFileUsed(!!file);
      setIsLinkUsed(false);
      setData((p: any) => ({
        ...p,
        file,
        link: "",
        resource: file.name,
        file_name: file.name,
      }));
    }
  };

  const onClose = () => {
    setOpenCreate(false);
    setError({});
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<{}>,
    value: string[]
  ) => {
    const newEnglishLevels = Array.from(
      new Set(
        value.flatMap((category) => CATEGORY_TO_ENGLISH_LEVEL[category] || [])
      )
    );
    setData({ ...data, category: value, english_level: newEnglishLevels });
  };

  return (
    <EduModal
      fullWidth
      open={openCreate}
      onClose={onClose}
      maxWidth="sm"
    >
      <EduModalTitle
        onClose={onClose}
        className="flex justify-between"
      >
        CREATE new Material
      </EduModalTitle>

      <EduModalBody className="p-5 w-full">
        {error.general && (
          <Box
            sx={{ color: "red", marginBottom: 2 }}
            className="error-message"
          >
            {error.general}
          </Box>
        )}

        <Box
          onSubmit={loadMaterial}
          component="form"
          className="p-3 flex flex-col gap-5"
        >
          <FormControlLabel
            label="Precourse"
            control={
              <Checkbox
                name="precourse"
                color="primary"
                checked={data.precourse}
                onChange={(e) =>
                  setData({ ...data, precourse: e.target.checked })
                }
              />
            }
          />

          <TextField
            required
            fullWidth
            name="name"
            label="Name material"
            value={data.name}
            error={!!error.name}
            variant="outlined"
            onChange={(event) => setData({ ...data, name: event.target.value })}
            placeholder="Name material"
          />

          <TextField
            fullWidth
            name="link"
            value={data?.link || ""}
            error={!!urlError}
            label="Material Link"
            variant="outlined"
            disabled={isFileUsed}
            onChange={handleResourceChange}
            helperText={urlError}
            placeholder="Material Link"
          />

          <MaterialFileSelect
            disabled={isLinkUsed}
            onFileChange={handleFileChange}
            initialFileName={data?.file_name}
          />

          <TextField
            select
            required
            fullWidth
            name="lesson"
            label="Lesson"
            value={data?.lesson || ""}
            variant="outlined"
            onChange={(e) => setData({ ...data, lesson: e.target.value })}
            placeholder="Lesson"
          >
            {lesson.map((e, i) => (
              <MenuItem
                key={e + i}
                value={e}
              >
                {e}
              </MenuItem>
            ))}
          </TextField>

          <Grid
            item
            md={12}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="checkboxes-tags-demo"
              color="primary"
              value={data.category}
              style={{ width: "100%" }}
              options={CATEGORY_MAPPING[data.lesson] || []}
              onChange={handleCategoryChange}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option}
                >
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    checkedIcon={checkedIcon}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  placeholder="Category"
                />
              )}
            />
          </Grid>

          <Grid
            item
            md={12}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="checkboxes-tags-demo"
              color="primary"
              value={data.language}
              style={{ width: "100%" }}
              options={LANGUAGES.map((lang) => lang.iso)}
              onChange={(_, value) => setData({ ...data, language: value })}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option}
                >
                  <Checkbox
                    required
                    icon={icon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    checkedIcon={checkedIcon}
                  />
                  {LANGUAGES.find((lang) => lang.iso === option)?.name ??
                    option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Language"
                  placeholder="Language"
                />
              )}
            />
          </Grid>

          <TextField
            select
            required
            fullWidth
            name="for"
            label="For"
            value={data?.for ?? ""}
            variant="outlined"
            onChange={(e) => setData({ ...data, for: e.target.value })}
          >
            {FOR.map((f, i) => (
              <MenuItem
                key={f + i}
                value={f}
              >
                {f}
              </MenuItem>
            ))}
          </TextField>

          <Box className="flex justify-end">
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={isLoading}
            >
              <img
                src={saveIcon}
                className="icon"
              />
              CREATE
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default CreateMaterial;
