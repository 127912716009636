import { useState } from "react";
import { WarningAmber } from "@mui/icons-material";
import {
  Box,
  Menu,
  Table,
  Paper,
  Button,
  Select,
  Popover,
  MenuItem,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  TableContainer,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DialpadIcon from "@mui/icons-material/Dialpad";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const handleSeeInCrm = (row: any) => {
  const url = `/admin/users?search=${encodeURIComponent(row.name)}&page=1`;
  window.open(url, "_blank");
};

const renderMenuItems = (
  row: { type: string },
  handleMenuClose: () => void,
  navigate: any,
  callUpdateCrm: any,
  handleCommentClick: (arg0: any) => any,
  handleOpenUserUpdates: (arg0: any) => any
) => {
  const menuItems =
    //#region no longer used here(dev-center users) but now used in dev-center crm
    // row.type === "Never booked" || row.type === "No show"
    //   ? [
    //       {
    //         label: "Schedule meeting",
    //         action: () => handleScheduleMeeting(row),
    //       },
    //       { label: "See in CRM", action: () => handleSeeInCrm(row) },
    //       {
    //         label: "Numero inesistente / inattivo",
    //         action: () => handleNotActive(row, callUpdateCrm),
    //       },
    //       {
    //         label: "Not interested",
    //         action: () => handleNotInterested(row, callUpdateCrm),
    //       },
    //     ]
    //   :
    // row.type === "Upselling"
    //   ? [
    //       {
    //         label: "Schedule meeting",
    //         action: () => handleScheduleMeeting(row),
    //       },
    //       {
    //         label: "Not interested",
    //         action: () => handleNotInterested(row, callUpdateCrm),
    //       },
    //     ]
    //#endregion
    row.type === "Absent" || row.type === "Low rating"
      ? [
          //{ label: 'Resolved', action: () => handleResolve(row) },
          //{ label: 'Stand By', action: () => handleStandBy(row) },
          { label: "Comment", action: () => handleCommentClick(row) },
          { label: "View", action: () => handleOpenUserUpdates(row) },
        ]
      : row.type === "Review"
      ? [
          { label: "Not interested", action: handleMenuClose },
          { label: "Booked call", action: handleMenuClose },
        ]
      : row.type === "Still to pay"
      ? [{ label: "Paid", action: handleMenuClose }]
      : [{ label: "Close Menu", action: handleMenuClose }];

  return menuItems.map((item, index) => (
    <MenuItem
      key={index}
      onClick={() => {
        item.action();
        handleMenuClose();
      }}
    >
      {item.label}
    </MenuItem>
  ));
};

export const getColumns = (
  handleMenuClick: any,
  handleMenuClose: any,
  menuRow: any,
  anchorEl: any,
  navigate: any,
  handleDialpadCall: any,
  callUpdateCrm: any,
  handleCommentClick: any,
  handleStandBy: any,
  handleResolve: any,
  handleStatusChange: any,
  handleOpenUserUpdates: any
) => [
  {
    field: "name",
    headerName: "Name",
    minWidth: 140,
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => {
      return <Box title={row.name}>{row.name}</Box>;
    },
  },
  {
    field: "rating",
    headerName: "Low rating",
    minWidth: 80,
    flex: 1,
    renderCell: ({ row }) => {
      const [anchorEl, setAnchorEl] = useState(null);
      const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
      const handlePopoverClose = () => {
        setAnchorEl(null);
      };
      const open = Boolean(anchorEl);

      return (
        <Box>
          {row?.is_low_rating && (
            <>
              <Button onClick={handlePopoverOpen}>
                <WarningAmber
                  color="warning"
                  className="!-mr-[0.08rem]"
                />
              </Button>
              <Popover
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Box p={2}>
                  <TableContainer
                    sx={{ mb: 2 }}
                    component={Paper}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Lesson Date
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Reason for low rating
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Tutor
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">
                              {row.lessonDate}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">
                              {row.reasons}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">{row.tutor}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Button
                    variant="contained"
                    onClick={() => handleResolve(row, "Low rating")}
                  >
                    Resolve
                  </Button>
                </Box>
              </Popover>
            </>
          )}
        </Box>
      );
    },
  },
  {
    field: "absent",
    headerName: "Absent",
    minWidth: 80,
    flex: 1,
    renderCell: ({ row }) => {
      const [anchorEl, setAnchorEl] = useState(null);
      const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
      const handlePopoverClose = () => {
        setAnchorEl(null);
      };
      const open = Boolean(anchorEl);

      return (
        <Box>
          {row?.is_absent && (
            <>
              <Button onClick={handlePopoverOpen}>
                <WarningAmber
                  color="warning"
                  className="!-mr-[0.08rem]"
                />
              </Button>

              <Popover
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Box p={2}>
                  <TableContainer
                    component={Paper}
                    sx={{ mb: 2 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Missed Condition
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Course
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Start Month
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">
                              {row.missedCondition}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">
                              {row.course}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">
                              {row.startMonth}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Button
                    variant="contained"
                    onClick={() => handleResolve(row, "Absent")}
                  >
                    Resolve
                  </Button>
                </Box>
              </Popover>
            </>
          )}
        </Box>
      );
    },
  },
  {
    field: "review",
    headerName: "Review",
    minWidth: 80,
    flex: 1,
    renderCell: ({ row }) => {
      const [anchorEl, setAnchorEl] = useState(null);
      const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
      const handlePopoverClose = () => {
        setAnchorEl(null);
      };
      const open = Boolean(anchorEl);

      return (
        <Box>
          {row?.is_review && (
            <>
              <Button onClick={handlePopoverOpen}>
                <WarningAmber
                  color="success"
                  className="!-mr-[0.08rem]"
                />
              </Button>

              <Popover
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Box p={2}>
                  <TableContainer
                    component={Paper}
                    sx={{ mb: 2 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Rating
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Attendance
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Course
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body2"
                              color="primary"
                            >
                              Start Month
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">
                              {row.rating}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">
                              {row.attending}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">
                              {row.course}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">
                              {row.startMonth}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Popover>
            </>
          )}
        </Box>
      );
    },
  },
  {
    field: "startMonth",
    headerName: "Start Month",
    minWidth: 140,
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Box title={row?.startMonth}>{row?.startMonth}</Box>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 140,
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => {
      const statusOptions = [
        "To be called",
        "To be recalled",
        "No reply",
        "Stand by",
      ];

      return (
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel>Status</InputLabel>
          <Select
            id="status-select"
            name="status"
            size="small"
            color="primary"
            label="Status"
            value={row.status}
            labelId="status-select-label"
            onChange={(e) => handleStatusChange(row, e.target.value)}
          >
            {statusOptions.map((option) => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    minWidth: 140,
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => <Box title={row.phone}>{row.phone}</Box>,
  },
  {
    field: "calledToday",
    headerName: "Called Today",
    minWidth: 140,
    flex: 1,
    renderCell: ({ row }) => (
      <Box title={row.calledToday ? "Called" : "Not Called"}>
        {row.calledToday ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="disabled" />
        )}
      </Box>
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    minWidth: 300,
    flex: 1,
    renderCell: ({ row }) => (
      <Box className="flex gap-2">
        <Button onClick={() => handleDialpadCall(row)}>
          <DialpadIcon fontSize="small" />
        </Button>

        <Button onClick={() => handleSeeInCrm(row)}>See in Users</Button>

        <IconButton
          aria-controls={menuRow === row.uuid ? "actions-menu" : undefined}
          aria-haspopup="true"
          onClick={(event) => handleMenuClick(event, row.uuid)}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="actions-menu"
          open={menuRow === row.uuid}
          onClose={handleMenuClose}
          anchorEl={anchorEl}
        >
          {renderMenuItems(
            row,
            handleMenuClose,
            navigate,
            callUpdateCrm,
            handleCommentClick,
            handleOpenUserUpdates
          )}
        </Menu>
      </Box>
    ),
  },
];
