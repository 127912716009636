import LANGUAGES from "@/components/assets/LANGUAGES";
import InputTextSelect from "@/pages/Exercises/ExercisesV1/components/InputTextSelect";
import { ExerciseFormPropsType } from "@/pages/Exercises/ExercisesV3/exercisesV3.types";
import { levelMappingDummy } from "@/pages/Exercises/ExercisesV3/exerciseV3.utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import ExerciseChooseFile from "../ExerciseChooseFile";

const AddNewExerciseForm: React.FC<ExerciseFormPropsType> = ({
  exercise,
  playerRef,
  watchImgRef,
  handleSetExercise,
}) => {
  const theme = useTheme();

  return (
    <Box className="flex flex-col w-full gap-5 overflow-y-auto pr-1 pt-2">
      <InputTextSelect
        required
        multiline
        type="text"
        label="Name"
        value={exercise?.name ?? ""}
        maxRows={2}
        setValue={(value) => handleSetExercise("name", value)}
        placeholder="Exercise name"
      />

      <Box className="grid grid-cols-2 gap-4">
        <InputTextSelect
          required
          name="type"
          label="Type"
          value={exercise?.type ?? ""}
          setValue={(value) => {
            handleSetExercise("type", value);
          }}
          className="w-full"
          placeholder="Exercise type"
        >
          {["GRAMMAR", "COMPREHENSION"].map((type) => (
            <MenuItem
              key={type}
              value={type}
            >
              {type}
            </MenuItem>
          ))}
        </InputTextSelect>

        <FormControl
          required
          className="w-full"
        >
          <InputLabel id="lang-select-label">Language</InputLabel>
          <Select
            multiple
            required
            name="lang"
            label="Language"
            value={exercise?.lang || []}
            labelId="lang-select-label"
            onChange={(e) => handleSetExercise("lang", e.target.value)}
            renderValue={(selected) =>
              Array.isArray(selected) ? selected.join(", ") : ""
            }
          >
            {LANGUAGES.map((lang) => (
              <MenuItem
                key={lang.iso}
                value={lang.iso}
              >
                <Checkbox
                  checked={(exercise?.lang || []).indexOf(lang.iso) > -1}
                />
                <ListItemText primary={lang.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <InputTextSelect
          required
          name="english_level"
          label="Level"
          value={exercise?.english_level ?? ""}
          setValue={(value) => handleSetExercise("english_level", value)}
          className="w-full"
          placeholder="Exercise Level"
        >
          {levelMappingDummy.map((level, index) => (
            <MenuItem
              key={index}
              value={level}
            >
              {level}
            </MenuItem>
          ))}
        </InputTextSelect>

        <InputTextSelect
          required
          name="category"
          label="Category"
          value={exercise?.category ?? ""}
          setValue={(value) => handleSetExercise("category", value)}
          placeholder="Category"
        >
          {["Listening", "Reading"].map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
          ))}
        </InputTextSelect>

        <TextField
          required
          fullWidth
          type="number"
          name="duration"
          label="Duration"
          value={exercise?.duration ?? ""}
          onChange={(e) => handleSetExercise("duration", e.target.value)}
          placeholder="Duration in minutes"
        />

        <FormControlLabel
          label="Is Precourse"
          sx={{
            color: theme.palette.primary.main,
            bgcolor: theme.palette.mode == "dark" ? "transparent" : "white",
            borderColor: theme.palette.primary.main,
          }}
          className="rounded-[0.6rem] border h-[2.7rem] !mx-0"
          control={
            <Checkbox
              checked={Boolean(Number(exercise?.is_precourse)) || false}
              onChange={(e) =>
                handleSetExercise("is_precourse", e.target.checked)
              }
            />
          }
        />
      </Box>

      <Box
        sx={{
          "& .ck.ck-toolbar": {
            backgroundColor: theme.palette.background.default,
          },
          "& .ck.ck-button:not(.ck-disabled):hover, & .ck.ck-button.ck-on": {
            backgroundColor:
              theme.palette.mode == "dark"
                ? theme.palette.primary.main
                : theme.palette.background.paper,
          },
          "& .ck.ck-editor__editable_inline, & .ck.ck-editor__editable_inline, & .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content, & .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable), & .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred":
            {
              borderColor: theme.palette.primary.main,
            },
          "& .ck.ck-editor__main>.ck-editor__editable, & .ck.ck-list__item, & .ck-dropdown__panel-visible":
            {
              backgroundColor: theme.palette.background.default,
            },
          "& .ck-reset_all :not(.ck-reset_all-excluded *), & .ck.ck-reset_all":
            {
              color:
                theme.palette.mode == "dark"
                  ? "white"
                  : theme.palette.primary.main,
            },
        }}
        className="w-full"
      >
        <CKEditor
          data={exercise?.text ?? ""}
          editor={ClassicEditor}
          onChange={(_, editor) => handleSetExercise("text", editor.getData())}
        />
      </Box>

      {exercise.category?.toLowerCase() === "writing" && (
        <>
          <TextField
            fullWidth
            type="text"
            name="essay_id"
            label="Essay ID"
            value={exercise?.essay_id ?? ""}
            onChange={(e) => handleSetExercise("essay_id", e.target.value)}
            placeholder="Essay checker id"
          />

          <TextField
            fullWidth
            type="text"
            name="topic"
            label="Essay Topic"
            value={exercise?.topic ?? ""}
            onChange={(e) => handleSetExercise("topic", e.target.value)}
            placeholder="Essay checker topic"
          />
        </>
      )}

      {exercise.category?.toLowerCase() === "listening" && (
        <ExerciseChooseFile
          name="audio_file"
          label="Choose audio file (MP3)"
          accept=".mp3"
          isEdit={false}
          exercise={exercise}
          onChange={handleSetExercise}
          playerRef={playerRef}
          watchImgRef={watchImgRef}
          placeholder="No audio file selected"
        />
      )}

      <ExerciseChooseFile
        name="img_file"
        label="Choose image file"
        accept=".png,.jpg,.jpeg"
        isEdit={false}
        exercise={exercise}
        onChange={handleSetExercise}
        playerRef={playerRef}
        watchImgRef={watchImgRef}
        placeholder="No image file selected"
      />
    </Box>
  );
};

export default AddNewExerciseForm;
