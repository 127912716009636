import { memo, useState, forwardRef, useImperativeHandle } from "react";
import useAuth from '@/hooks/useAuth';
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  // Stack,
  // Switch,
  // styled,
  TextField,
  // Typography,
} from "@mui/material";
import * as Yup from "yup";
import saveIcon from "@/assets/icons/save.svg";
import dayOffIcon from "@/assets/icons/day-off.svg";
import useDayOffApi from "@/api/useDayOffApi";
import DayOffModalFullDay from "./DayOffModalFullDay";
// import dayjs from "dayjs";
// import DayOffModalLessOneDay from "./DayOffModalLessOneDay";

export interface IForwardRef {
  open: (data?: any) => void;
}

interface IProps {
  ref: HTMLButtonElement;
}

const DayOffModal = forwardRef<IForwardRef, IProps>((props, ref) => {
  const { userAdmin } = useAuth();
  const { callCreateDayOff } = useDayOffApi();

  const [open, setOpen] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const formik = useFormik({
    initialValues: {
      id: null,
      type: true,
      reason: null,
      endTime: null,
      startTime: null,
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Required"),
      startTime: Yup.date().required("Required"),
      endTime: Yup.date()
        .default(null)
        .when(
          "startTime",
          (startTime, yup) =>
            startTime &&
            yup.min(startTime, "End time can not be before start time")
        )
        .required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoadingSubmit(true);
      await callCreateDayOff({
        start_time: values.startTime,
        end_time: values.endTime,
        admin_id: userAdmin.user.id,
        reason: values.reason,
      });
      setIsLoadingSubmit(false);
      handleClose();
    },
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={dayOffIcon}
          className="icon"
        />
        {formik.values?.id ? <>Edit TIME OFF</> : <>ADD TIME OFF</>}
      </EduModalTitle>

      <EduModalBody
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        component="form"
        className="p-5 flex flex-col gap-4"
      >
        {/* <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography>Less a day</Typography>
            <AntSwitch
              checked={formik.values?.type}
              onClick={() => {
                const newType = !formik.values?.type;
                formik.setFieldValue("type", newType);

                const newValue = formik.values?.startTime;

                if (newValue) {
                  formik.setFieldValue(
                    "startTime",
                    newValue
                      ? dayjs(newValue).format("YYYY-MM-DD 00:00:00")
                      : null
                  );

                  const time = newType === true ? "23:59:59" : "00:00:00";
                  formik.setFieldValue(
                    "endTime",
                    newValue
                      ? dayjs(newValue).format(`YYYY-MM-DD ${time}`)
                      : null
                  );
                }
              }}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>Full day</Typography>
          </Stack> */}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* {formik.values?.type ? ( */}
          <DayOffModalFullDay formik={formik} />
          {/* ) : (
             <DayOffModalLessOneDay formik={formik} />
           )} */}
        </LocalizationProvider>

        <TextField
          fullWidth
          required
          multiline
          minRows={5}
          name="reason"
          label="Reason"
          value={formik.values?.reason || ""}
          onChange={formik.handleChange}
        />

        <Box className="flex justify-end">
          <LoadingButton
            type="submit"
            color="primary"
            variant="outlined"
            loading={isLoadingSubmit}
          >
            <img
              src={saveIcon}
              className={`icon ${isLoadingSubmit ? "opacity-0" : ""}`}
            />
            Save
          </LoadingButton>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     "&.Mui-checked": {
//       transform: "translateX(12px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.primary.main,
//     boxSizing: "border-box",
//   },
// }));

export default memo(DayOffModal);
