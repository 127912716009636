import { Box } from "@mui/material";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import UseDownloadTable from "@/components/UseDownloadTable";

export const Toolbar = ({ filters, setFilters }) => {
  return (
    <Box
      id="filter_bar"
      className="flex justify-between !items-stretch"
    >
      <UseDownloadTable fileName="Referrals" />

      <SearchToolbar
        filters={filters}
        setFilters={setFilters}
        client={true}
      />
    </Box>
  );
};
