import { ChangeEvent, useState } from "react";
import { useContextCrm } from "../Context";
import { Box, Button, MenuItem, Popover, TextField } from "@mui/material";
import EditPhone from "./EditPhone";
import functions from "../functions";
import EditFullName from "./EditFullName";

export default () => {
  const context = useContextCrm();
  const getFunctions = functions(context);

  const Children = () => {
    const [comment, setComment] = useState<string>(
      context.popover?.row?.comments ?? ""
    );

    switch (context.popover?.component) {
      case "meeting_status":
        return (
          <Box className="bg-edu-800 flex flex-col p-3 gap-3">
            {[
              { label: "Present", meeting_status: "ci sarà" },
              { label: "No reply", meeting_status: "non risponde" },
              { label: "Other", meeting_status: "other" },
            ].map((b, i) => (
              <Button
                key={b.label + i}
                type="button"
                variant="outlined"
                onClick={async () => {
                  await getFunctions.handleUpdateLead(
                    context.popover?.row?.id ?? context.rowSelected?.id,
                    { meeting_status: b.meeting_status }
                  );
                  context.setPopover(undefined);
                  context.getInvalidateQuery();
                }}
              >
                {b.label}
              </Button>
            ))}
          </Box>
        );
      case "convert_status":
        return (
          <Box className="bg-edu-800 flex flex-col p-3 gap-3">
            {[
              { label: "Soon buying", meeting_status: "soon buying" },
              { label: "No reply", meeting_status: "no reply" },
              { label: "OTHER", meeting_status: "other" },
            ].map((b, i) => (
              <Button
                key={b.label + i}
                type="button"
                variant="outlined"
                onClick={async () => {
                  await getFunctions.handleUpdateLead(
                    context.popover?.row?.id ?? context.rowSelected?.id,
                    { convert_status: b.meeting_status }
                  );
                  context.setPopover(undefined);
                  context.getInvalidateQuery();
                }}
              >
                {b.label}
              </Button>
            ))}
          </Box>
        );
      case "show_comment":
        return (
          <Box className="bg-edu-800 flex flex-col p-3 gap-3">
            <TextField
              multiline
              rows={5}
              value={comment ?? ""}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Write a comment"
            />

            <Button
              type="submit"
              variant="outlined"
              onClick={async () => {
                await getFunctions.handleUpdateLead(
                  context.popover?.row?.id ?? context.rowSelected?.id,
                  { comments: comment }
                );
                context.setPopover(undefined);
                context.getInvalidateQuery();
              }}
            >
              Save
            </Button>
          </Box>
        );
      case "send_discount_code":
        return (
          <Box className="bg-edu-800 flex flex-col gap-4 p-3 w-[200px]">
            <TextField
              select
              fullWidth
              label="Discount code"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                getFunctions.handleSendTemplateWhatsAppDiscountCode(
                  context.popover?.row,
                  e.target.value
                )
              }
            >
              <MenuItem value="">- Remove -</MenuItem>

              {context.products?.discount_codes?.map((c: any, i: number) => (
                <MenuItem
                  key={c.id + i}
                  value={c?.code}
                >
                  <Box className="w-full flex justify-between gap-2">
                    <Box>{c?.code}</Box>
                    <Box>
                      {c?.amount}
                      {c?.is_percentage ? "%" : "€"}
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </Box>
        );
      case "edit_phone":
        return <EditPhone />;
      case "edit_full_name":
        return <EditFullName />;
      default:
        return null;
    }
  };

  return (
    <Popover
      id={context.popover?.component}
      open={Boolean(context.popover?.anchor)}
      onClose={() => {
        context.setPopover(undefined);
        context.setGlobalAnchorEl(undefined);
      }}
      anchorEl={context.popover?.anchor}
      anchorOrigin={{
        vertical: context.popover?.anchorOrigin?.vertical ?? "top",
        horizontal: context.popover?.anchorOrigin?.horizontal ?? "left",
      }}
      transformOrigin={{
        vertical: context.popover?.transformOrigin?.vertical ?? "top",
        horizontal: context.popover?.transformOrigin?.horizontal ?? "right",
      }}
    >
      <Children />
    </Popover>
  );
};
