import useAuth from '@/hooks/useAuth';
import { Visibility } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import useDownloadFile from "@/components/useDownloadFile";

function Toolbar({ setOpenCreate, togglePastMonthFilter, pastMonth, ...rest }) {
  const { isAdmin, userAdmin } = useAuth();

  const handleDownloadFileKickOffDates = async () => {
    const res = await useAPI(
      "/v1/settings/get/file-kickoff_dates",
      {},
      { type: "get" }
    );

    const fileName = "kickoff_dates.json";

    if (typeof res == "string") {
      useDownloadFile(res, fileName);
    } else {
      useDownloadFile(JSON.stringify(res, null, "\t"), fileName);
    }
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <Button
        size="medium"
        title="Add kickoff date"
        variant="outlined"
        onClick={() => setOpenCreate(true)}
      >
        <img
          src={addIcon}
          className="icon"
        />
        Add
      </Button>

      <Button
        size="medium"
        title={pastMonth ? "Show future kickoffs" : "Show past kickoffs"}
        variant="outlined"
        onClick={togglePastMonthFilter}
      >
        <Visibility
          fontSize="small"
          className="icon"
        />{" "}
        {pastMonth ? "Show Future kickoffs" : "Show Past Kickoffs"}
      </Button>

      {isAdmin &&
        ["irinel"].includes(userAdmin.user.first_name.toLowerCase()) && (
          <Button
            variant="outlined"
            onClick={handleDownloadFileKickOffDates}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download file kickoff_dates.json
          </Button>
        )}

      <SearchToolbar
        {...rest}
        client={true}
      />
    </Box>
  );
}

export default Toolbar;
