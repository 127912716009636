import { useEffect, useState } from "react";
import { Star } from "@mui/icons-material";
import { clone } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { INFeedback } from "../interfaces";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { Avatar, Box, Button, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { HTTP_ROUTE_FEEDBACK, PUBLIC_STORAGE } from "..";
import DialogTutors, {
  DEFAULT_FEEDBACKS,
} from "@/pages/Feedback/TutorsFeedbacks/TutorsDialog";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import useExportCSV from "@/components/useExportCSV";
import PerformanceReport from "@/pages/Feedback/TutorsFeedbacks/PerformanceReport";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const HTTP_BASE = "/v1/general/tutors/analytics";

const TutorAnalytics = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler } = useAuth();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [feedbacks, setFeedbacks] = useState<INFeedback>(DEFAULT_FEEDBACKS);
  const [reportTutor, setReportTutor] = useState<string>();

  const KEY_QUERY = ["tutors-analytics", filterData];

  const { data: dataAPI, isLoading } = useQuery(KEY_QUERY, () =>
    useAPI(HTTP_BASE, { perPage, filters: filterData })
  );

  const handleExport = async () => {
    const res = await useAPI(HTTP_BASE, {
      perPage,
      filters: filterData,
      export: true,
    });

    useExportCSV(
      res,
      "English tutor - analytics" +
      (filterData?.dates ? " " + filterData?.dates : "")
    );
  };

  // GET FEEDBACK
  const handleGetFeedback = async (
    token: string,
    name: string
  ): Promise<any> => {
    setFeedbacks((p) => ({ ...p, name, open: true }));

    const oldTutors = queryClient.getQueryData(KEY_QUERY);

    const newTutors: any = clone(oldTutors ?? rows);
    const index: number = newTutors.findIndex((e: any) => e.token === token);
    let rowFeedbacks: Array<any> = clone(newTutors[index]);

    if (!Boolean(rowFeedbacks?.length)) {
      rowFeedbacks = await useAPI(HTTP_ROUTE_FEEDBACK, { token });
    }

    if (!rowFeedbacks?.length) {
      setFeedbacks({ name: "", open: false, data: [] });
      snackHandler("No feedback recorded!", "error");
      return;
    }

    newTutors[index].feedbacks = rowFeedbacks;

    queryClient.invalidateQueries(KEY_QUERY);
    setRows(newTutors);
    setFeedbacks((p) => ({ ...p, data: rowFeedbacks }));
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      minWidth: 180,
      flex: 1,
      headerName: "Tutor",
    },
    {
      field: "profile_pic",
      headerName: "",
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Avatar
          className="mr-2"
          src={PUBLIC_STORAGE + "/public/storage/" + (row?.profile_pic || "")}
        >
          {row?.first_name?.charAt(0)}
        </Avatar>
      ),
    },
    {
      field: "type",
      minWidth: 200,
      flex: 0.7,
      renderHeader: () => (
        <Box
          color={theme.palette.primary.main}
          className="w-full text-center truncate"
        >
          Type
        </Box>
      ),
      valueGetter: ({ row }) => row?.role?.replaceAll(",", ", "),
      renderCell: ({ row }) => (
        <Box className="w-full text-center">
          {row?.role?.replaceAll(",", ", ")}
        </Box>
      ),
    },
    {
      field: "classes",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "sub_request",
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: "delay",
      minWidth: 70,
      flex: 0.5,
    },
    {
      field: "avg_rating",
      minWidth: 110,
      flex: 0.5,
    },
    {
      field: "actions",
      width: 70,
      headerName: "",
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        return (
          <Button
            title="Wath feedback"
            variant="outlined"
            onClick={() => handleGetFeedback(row.token, row.fullName)}
          >
            <Star color="secondary" />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (!dataAPI?.data?.length) return;
    setRows(dataAPI.data);
  }, [isLoading]);

  return (
    <>
      <BasePage title="English Tutors - Analytics">
        <DataGrid
          autoHeight
          disableColumnMenu
          disableVirtualization
          disableSelectionOnClick
          sx={getTableStyling(theme)}
          rows={rows}
          columns={columns}
          loading={isLoading}
          pageSize={perPage}
          sortModel={sortModel}
          components={{
            Toolbar: Toolbar,
            Pagination: CustomPaginationClient,
          }}
          componentsProps={{
            toolbar: {
              setFilters,
              filters: filterData,
              onExport: handleExport,
            },
          }}
          onSortModelChange={onSortModelChange}
          rowsPerPageOptions={[perPage]}
          experimentalFeatures={{ newEditingApi: false }}
        />
      </BasePage>

      {Boolean(feedbacks?.open) && (
        <DialogTutors
          open={Boolean(feedbacks?.open)}
          name={feedbacks?.name ?? ""}
          data={feedbacks?.data ?? []}
          onClose={() => setFeedbacks(DEFAULT_FEEDBACKS)}
        />
      )}

      {Boolean(reportTutor) && (
        <PerformanceReport
          open={Boolean(reportTutor)}
          onClose={() => setReportTutor(undefined)}
          tutorToken={reportTutor}
        />
      )}
    </>
  );
};

export default TutorAnalytics;
