import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Chip,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Payments } from "../crm-upselling.types";

const PurchaseHistoryTemplate: React.FC<Payments> = ({ payments }) => {
  if (payments.length === 0) {
    return (
      <Box className="bg-edu-800 flex flex-col p-3 text-sm gap-1">
        <Typography
          variant="h6"
          color="textSecondary"
        >
          No purchase history available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="bg-edu-800 flex flex-col p-3 text-sm gap-1">
      <Typography
        variant="h6"
        gutterBottom
      >
        Purchase History
      </Typography>
      <List>
        {payments.map((payment) => (
          <Box
            key={payment.id}
            mb={2}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ShoppingCartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={payment.paid_for}
                secondary={`Price: ${payment.amount}`}
              />
              {/* <Chip
                label={`$${payment.amount}`}
                color="success"
                avatar={<PaymentIcon />}
                variant="outlined"
              /> */}
            </ListItem>
            <Divider variant="middle" />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default PurchaseHistoryTemplate;
