import { getDay } from "date-fns";

export const translations = {
  it: {
    // reminder1:
    //   "Ciao [1], sono [2] di Edusogno! %0aVolevo ricordarti che hai fissato per [3] alle [4] il colloquio per il nostro percorso di inglese. Dovresti aver ricevuto l'invito per partecipare e lo dovresti vedere nel tuo calendario o direttamente nella casella della tua mail. Ci vediamo nella chiamata. \nNon vediamo l'ora di conoscerti e sapere le tue motivazioni per migliorare il tuo inglese!!",
    // reminder1:
    //   "Ciao [1], ti scrivo solo per ricordarti l'appuntamento di [2] e per chiederti cortesemente conferma ☺️ %0aDovresti aver ricevuto la mail d'invito con il link per partecipare alla videocall, a prestissimo!",
    reminder1:
      "Ciao, [1] ☺ ti scrivo per ricordarti il meeting di domani e per chiederti cortesemente di confermare la tua presenza.%0aDovresti aver ricevuto l'invito via mail con il link per partecipare alla videocall.%0aA presto!",
    reminder2_1:
      "Ciao [1] come stai? Sono [2] di Edusogno :) Siamo davvero contenti tu abbia deciso di partecipare al nostro progetto. Volevo dirti che per i ragazzi che si sono iscritti questa settimana c'è un offerta: un tuo amico/a a cui interessa migliorare il livello di Inglese potrà partecipare al nostro corso. In questo caso avrai diritto ad uno sconto di 50 euro sul costo dell'esame IELTS, per ogni amico che si iscrive ed inizia il corso. Hai tempo fino a domani alle 20.00 per far fissare ad uno o più amici un incontro con me in cui gli spiegherò come funziona il percorso. Ti basta girargli questo link: [3] A presto!",
    reminder2_2:
      "Ciao [1] come stai? Sono [2] di Edusogno :)  Siamo davvero contenti tu abbia deciso di partecipare al nostro progetto. Volevo dirti che per i ragazzi che si sono iscritti questa settimana c'è un offerta: un tuo amico/a a cui interessa migliorare il livello di Inglese potrà partecipare al nostro corso. In questo caso avrai diritto ad rimborso di 50 euro sul costo complessivo, per ogni amico che si iscrive ed inizia il corso. Hai tempo fino a domani alle 20.00 per far fissare ad uno o più amici un incontro con me in cui gli spiegherò come funziona il percorso. Ti basta girargli questo link: [3] A presto!",
    reminder3:
      "Ciao [1], come stai? Ti ho inviato la mail di ammissione al nostro percorso.%0a%0a ☺ Volevo chiederti se avevi intenzione di partecipare e se ti serviva qualche info prima delle 21:00? Fammi sapere, grazie in anticipo!",
    // reminder3:
    //   "Ciao [1], come stai? Ti ho inviato la mail di ammissione al nostro percorso.%0a%0a ☺ Volevo chiederti se avevi intenzione di partecipare e se ti serviva qualche info prima delle [2]? Fammi sapere, grazie in anticipo!",
    // reminder3:
    //   "Ciao [1], come stai? Sono [2] di Edusogno :) Ti abbiamo inviato la mail di ammissione al nostro percorso. Volevo chiederti se hai intenzione di partecipare oppure dobbiamo assegnare il tuo posto ad un altro candidato. Fammi sapere, grazie in anticipo!",
    // reminder4:
    //   "Ciao [1], sono [2] di Edusogno ☺️  ho visto che hai fissato un incontro con me per capire come migliorare il tuo Inglese. Ci vediamo [3] alle [4]! Nel frattempo, ti lascio il mio numero per qualsiasi domanda o necessità. Non vedo l'ora di conoscerti e di scoprire insieme come possiamo aiutarti. A presto!",
    reminder4:
      "Ciao, [1]! Sono [2] di Edusogno! ☺%0aHo visto che hai prenotato un meeting con me [3] alle [4] per scoprire quale tra i nostri 45 percorsi di Inglese è il più adatto alle tue esigenze. Pensiamo che il modo migliore per scoprirlo sia una chiacchierata informale insieme di 15 minuti che includerà:%0a%0a🔍 Identificazione dei tuoi obiettivi e motivazioni per migliorare la lingua%0a🇬🇧 Test di livello gratuito (non preoccuparti, sarà divertente!)%0a✅ Infine, ti consiglieremo il percorso più adatto e ti daremo tutte le informazioni necessarie (tempistiche, costi, etc...)%0a%0aNon vedo l'ora di incontrarti e di scoprire insieme come aiutarti a raggiungere i tuoi obiettivi, a presto!",
    reminder_after_rescheduled:
      "Ciao [1]! Ho visto che hai provveduto a schedulare un nuovo orario per il nostro appuntamento di inglese domani alle [2] ☺ Ti chiedo cortesemente di confermare la presenza al nuovo appuntamento. A presto!",
    today: "oggi",
    tomorrow: "domani",
    discount: [
      "Ciao! Ti scrivo per informarti che abbiamo attivato lo sconto nel tuo checkout. Usa il codice: [1]. Non fartelo scappare, ti aspettiamo!",
      "Ciao! Ti scrivo per informarti che abbiamo attivato lo sconto nel tuo checkout. Al momento del pagamento completo, inserisci il codice: [1]. Non fartelo scappare, ti aspettiamo!",
    ],
    hours: "ore",
    day: "il giorno",
    weeks: [
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
      "Domenica",
    ],
    months: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
  },
  es: {
    // "reminder1": "Hola [1], te escribo para recordarte lo de [2] y pedirte amablemente que confirmes %0aDeberías haber recibido el correo electrónico de invitación con el enlace para participar en la reunión, ¡hasta pronto!",
    // reminder1:
    //   "Hola [1] ☺️ Te escribo para recordarte nuestro encuentro de [2] y pedirte gentilmente que confirmes tu presencia. Deberías haber recibido el correo electrónico de invitación con el enlace para participar al meet. ¡Hasta pronto!",
    reminder1:
      "Hola [1] ☺️ Te escribo para recordarte nuestro encuentro de mañana a las [2] y pedirte gentilmente que confirmes tu presencia. Deberías haber recibido el correo electrónico de invitación con el enlace para participar al meet.%0a¡Hasta pronto! 👋",
    reminder2_1:
      "Hola [1], ¿cómo estás? Soy [2] de Edusogno :) Estamos muy contentos de que hayas decidido participar en nuestro proyecto. Quería decirte que para los chicos que se han inscrito esta semana hay una oferta: un amigo/a tuyo interesado en mejorar su nivel de inglés podrá participar en nuestro curso. En este caso, tendrás derecho a un descuento de 50 euros en el costo del examen IELTS, por cada amigo que se inscriba y comience el curso. Tienes tiempo hasta mañana a las 20.00 para que uno o más amigos fijen una reunión conmigo, donde les explicaré cómo funciona el curso. Simplemente pasa este enlace: [3] ¡Hasta pronto!",
    reminder2_2:
      "Hola [1], ¿cómo estás? Soy [2] de Edusogno :) Estamos muy contentos de que hayas decidido participar en nuestro proyecto. Quería decirte que para los chicos que se han inscrito esta semana hay una oferta: un amigo/a tuyo interesado en mejorar su nivel de inglés podrá participar en nuestro curso. En este caso, tendrás derecho a un reembolso de 50 euros en el costo total, por cada amigo que se inscriba y comience el curso. Tienes tiempo hasta mañana a las 20.00 para que uno o más amigos fijen una reunión conmigo, donde les explicaré cómo funciona el curso. Simplemente pasa este enlace: [3] ¡Hasta pronto!",
    // reminder3:
    //   "Hola [1], ¿cómo estás? Soy [2] de Edusogno :) Te hemos enviado el correo de admisión a nuestro curso. Quería preguntarte si tienes la intención de participar o si debemos asignar tu lugar a otro candidato. Por favor, házmelo saber, gracias de antemano!",
    reminder3:
      "Hola [1]! Cómo estás? %0aTe hemos enviado el email de invitación al curso. Quisiera saber si tienes la intención de asistir y si necesitas más información ☺ Por favor, avísanos. Gracias de antemano!",
    // reminder4:
    //   "Hola [1], soy [2] de Edusogno ☺️ %0aHe visto que has agendado una reunión conmigo para explicarte todo sobre nuestro método acelerador de inglés. ¡Nos vemos el [3] a las [4]! Para cualquier pregunta o necesidad me puedes escribir por aquí. No veo la hora conocerte y descubrir juntos cómo podemos ayudarte. ¡Hasta entonces!",
    reminder4:
      "¡Hola, [1]! Te escribe [2] de Edusogno! ☺%0aVi que agendaste una reunión conmigo el [3] a las [4] para explorar el mejor curso para ti dentro de los 45 cursos de inglés online que tenemos. Y creemos que la mejor forma de encontrarlo es mediante la reunión de 15 minutos que incluirá:%0a%0a🔍 Conocer tus objetivos y motivaciones para aprender inglés%0a🇬🇧 Una evaluación de nivel gratuita (no te preocupes, será súper ameno!)%0a✅ Finalmente, te propondré el curso más adecuado, con toda la información necesaria%0a%0a¡Ya quiero conocerte y descubrir cómo podemos ayudarte a lograr tus objetivos, hasta pronto!%0aSaludos",
    reminder_after_rescheduled:
      "Hola [1]! 👋 Espero que te encuentres fantástico! ☺️%0aVi que pudiste agendar un nuevo horario para tener una videollamada con nosotros y poder contarte todo sobre nuestros cursos de inglés online mañana a las [2]. Te pido amablemente que confirmes tu asistencia.%0a%0a¡Estoy deseando conocerte!%0aSaludos,%0a[3] de Edusogno",
    today: "hoy",
    tomorrow: "mañana",
    discount: [
      "Hola! Te escribo para informarte que hemos activado el descuento en tu checkout. Usa el código: [1]. No te lo pierdas, ¡te esperamos!",
      "Hola! Te escribo para informarte que hemos activado el descuento en tu checkout. Al momento del pago completo, ingresa el código: [1]. No te lo pierdas, ¡te esperamos!",
    ],
    hours: "horas",
    day: "el día",
    weeks: [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  },
  en: {
    reminder1:
      "Hi [1], I'm [2] from Edusogno! %0aI just wanted to remind you that you have an appointment for [3] at [4] for our English course. You should have received the invitation to participate and you should see it in your calendar or directly in your email box. See you in the call. \nWe can't wait to meet you and hear your motivations for improving your English!!",
    reminder2_1:
      "Hi [1] how are you? I'm [2] from Edusogno :) We are really happy you decided to join our project. I wanted to tell you that for the guys who signed up this week there is an offer: a friend of yours interested in improving their English level will be able to participate in our course. In this case you will be entitled to a discount of 50 euros on the cost of the IELTS exam, for each friend who signs up and starts the course. You have until tomorrow at 8.00 pm to have one or more friends set up a meeting with me where I will explain how the course works. Just pass them this link: [3] See you soon!",
    reminder2_2:
      "Hi [1] how are you? I'm [2] from Edusogno :) We are really happy you decided to join our project. I wanted to tell you that for the guys who signed up this week there is an offer: a friend of yours interested in improving their English level will be able to participate in our course. In this case you will be entitled to a refund of 50 euros on the total cost, for each friend who signs up and starts the course. You have until tomorrow at 8.00 pm to have one or more friends set up a meeting with me where I will explain how the course works. Just pass them this link: [3] See you soon!",
    reminder3:
      "Hi [1], how are you? I'm [2] from Edusogno :) We have sent you the admission email to our course. I wanted to ask you if you intend to participate or if we should assign your place to another candidate. Please let me know, thank you in advance!",
    reminder4:
      "Hi [1], I'm [2] from Edusogno ☺️  I saw that you have set up a meeting with me to understand how to improve your English. See you [3] at [4]! In the meantime, I leave you my number for any questions or needs. I can't wait to meet you and discover together how we can help you. See you soon!",
    reminder_after_rescheduled:
      "Hello [1]! 👋 I hope you're feeling fantastic! ☺%a0I saw that you were able to schedule a new time to have a video call with us and we could tell you everything about our online English courses tomorrow at [2]. I kindly ask you to confirm your attendance.%0a%0aI'm looking forward to meet you!%0aGreetings,%0a[3] by Edusogno",
    today: "today",
    tomorrow: "tomorrow",
    discount: [
      "Hi! I'm writing to inform you that we have activated the discount in your checkout. Use the code: [1]. Don't miss it, we're waiting for you!",
      "Hi! I'm writing to inform you that we have activated the discount in your checkout. At the time of full payment, enter the code: [1]. Don't miss it, we're waiting for you!",
    ],
    hours: "hours",
    day: "the day",
    weeks: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
  fr: {
    reminder1:
      "Salut [1], je suis [2] d'Edusogno! %0aJe voulais juste te rappeler que tu as rendez-vous pour [3] à [4] pour notre cours d'anglais. Tu aurais dû recevoir l'invitation pour participer et tu devrais le voir dans ton calendrier ou directement dans ta boîte mail. À bientôt dans l'appel. \nNous avons hâte de te rencontrer et d'entendre tes motivations pour améliorer ton anglais !!",
    reminder2_1:
      "Salut [1] comment vas-tu? Je suis [2] d'Edusogno :) Nous sommes vraiment heureux que tu aies décidé de rejoindre notre projet. Je voulais te dire que pour les gars qui se sont inscrits cette semaine, il y a une offre: un de tes amis intéressé à améliorer son niveau d'anglais pourra participer à notre cours. Dans ce cas, tu auras droit à une réduction de 50 euros sur le coût de l'examen IELTS, pour chaque ami qui s'inscrit et commence le cours. Tu as jusqu'à demain à 20h00 pour que un ou plusieurs amis fixent un rendez-vous avec moi où je leur expliquerai comment fonctionne le cours. Il suffit de leur passer ce lien: [3] À bientôt!",
    reminder2_2:
      "Salut [1] comment vas-tu? Je suis [2] d'Edusogno :) Nous sommes vraiment heureux que tu aies décidé de rejoindre notre projet. Je voulais te dire que pour les gars qui se sont inscrits cette semaine, il y a une offre: un de tes amis intéressé à améliorer son niveau d'anglais pourra participer à notre cours. Dans ce cas, tu auras droit à un remboursement de 50 euros sur le coût total, pour chaque ami qui s'inscrit et commence le cours. Tu as jusqu'à demain à 20h00 pour que un ou plusieurs amis fixent un rendez-vous avec moi où je leur expliquerai comment fonctionne le cours. Il suffit de leur passer ce lien: [3] À bientôt!",
    reminder3:
      "Salut [1], comment vas-tu? Je suis [2] d'Edusogno :) Nous t'avons envoyé le mail d'admission à notre cours. Je voulais te demander si tu as l'intention de participer ou si nous devons attribuer ta place à un autre candidat. S'il te plaît, fais-le moi savoir, merci d'avance!",
    reminder4:
      "Salut [1], je suis [2] d'Edusogno ☺️  j'ai vu que tu as fixé un rendez-vous avec moi pour comprendre comment améliorer ton anglais. On se voit [3] à [4]! En attendant, je te laisse mon numéro pour toute question ou besoin. J'ai hâte de te rencontrer et de découvrir ensemble comment nous pouvons t'aider. À bientôt!",
    reminder_after_rescheduled:
      "Bonjour [1]! 👋 J'espère que vous vous sentez bien! ☺%0aJ'ai vu que vous pouviez planifier une nouvelle heure pour passer un appel vidéo avec nous et nous pourrions tout vous dire sur nos cours d'anglais en ligne demain à (heure du rendez-vous). Je vous demande de bien vouloir confirmer votre présence.%0a%0aJ'ai hâte de te rencontrer!%0aSalutations,%0a[3] par Edusogno",
    today: "aujourd'hui",
    tomorrow: "demain",
    discount: [
      "Salut! Je t'écris pour t'informer que nous avons activé la réduction dans ton panier. Utilise le code: [1]. Ne le manquez pas, nous vous attendons!",
      "Salut! Je t'écris pour t'informer que nous avons activé la réduction dans ton panier. Au moment du paiement complet, entrez le code: [1]. Ne le manquez pas, nous vous attendons!",
    ],
    hours: "heures",
    day: "le jour",
    weeks: [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ],
    months: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
  },
  de: {
    reminder1:
      "Hallo [1] :relaxed: Ich wollte dich nur an unser Meeting von [2] erinnern. Du solltest die Einladung mit dem Link zur Teilnahme per Email erhalten haben. Bitte bestätige mir doch kurz deine Anwesenheit, danke und bis bald!",
    reminder2_1:
      "Hallo [1] wie geht es dir? Ich bin [2] von Edusogno :) Wir freuen uns sehr, dass du dich entschieden hast, an unserem Projekt teilzunehmen. Ich wollte dir sagen, dass es für die Jungs, die sich diese Woche angemeldet haben, ein Angebot gibt: ein Freund von dir, der daran interessiert ist, sein Englischniveau zu verbessern, wird an unserem Kurs teilnehmen können. In diesem Fall hast du Anspruch auf einen Rabatt von 50 Euro auf die Kosten des IELTS-Tests, für jeden Freund, der sich anmeldet und den Kurs beginnt. Du hast bis morgen um 20.00 Uhr Zeit, dass ein oder mehrere Freunde ein Treffen mit mir vereinbaren, bei dem ich ihnen erkläre, wie der Kurs funktioniert. Gib ihnen einfach diesen Link: [3] Bis bald!",
    reminder2_2:
      "Hallo [1] wie geht es dir? Ich bin [2] von Edusogno :) Wir freuen uns sehr, dass du dich entschieden hast, an unserem Projekt teilzunehmen. Ich wollte dir sagen, dass es für die Jungs, die sich diese Woche angemeldet haben, ein Angebot gibt: ein Freund von dir, der daran interessiert ist, sein Englischniveau zu verbessern, wird an unserem Kurs teilnehmen können. In diesem Fall hast du Anspruch auf eine Rückerstattung von 50 Euro auf die Gesamtkosten, für jeden Freund, der sich anmeldet und den Kurs beginnt. Du hast bis morgen um 20.00 Uhr Zeit, dass ein oder mehrere Freunde ein Treffen mit mir vereinbaren, bei dem ich ihnen erkläre, wie der Kurs funktioniert. Gib ihnen einfach diesen Link: [3] Bis bald!",
    // reminder3:
    //   "Hallo [1], wie gehts? Wir haben dir die Zulassungs-E-Mail für unseren Kurs geschickt. Hast du die Absicht, am Kurs teilzunehmen? Bitte lass es mich wissen, danke im Voraus!",
    reminder3:
      "Hallo [1]! Wie geht's? ☺ %0aWir haben dir die Zulassungsmail für unseren Kurs geschickt. Ich wollte nachfragen, ob du noch Fragen hast und ob du planst, teilzunehmen. Wäre super, wenn du mir Bescheid geben könntest. Danke dir im Voraus!",
    // reminder4:
    //   "Hallo [1], ich bin [2] von Edusogno :relaxed: Ich habe gesehen, dass du ein Meeting mit mir vereinbart hast, um dir alles über unsere Online-Akademie zu erzählen. Wir sehen uns am [3] um [4] Uhr! Wenn du Fragen hast oder was ist, kannst du mir gerne hier schreiben. Ich freue mich darauf, dich kennenzulernen und gemeinsam herauszufinden, wie wir dir mit deinem Englisch helfen können. Bis dahin!",
    reminder4:
      "Hallo [1], ich bin [2] von Edusogno ☺ Ich habe gesehen, dass du ein Meeting mit mir vereinbart hast, um mehr über unsere Englisch-Akademie zu erfahren. Wir sehen uns am [3]. um [4] Uhr! Wenn du Fragen hast oder was ist, kannst du mir gerne hier schreiben. Ich freue mich darauf, dich kennenzulernen und gemeinsam herauszufinden, wie wir dir helfen können. Bis dahin 👋🏼",
    reminder_after_rescheduled:
      "Hallo1]! 👋 Ich hoffe, du fühlst dich fantastisch! ☺%0aIch habe gesehen, dass Sie einen neuen Termin für einen Videoanruf mit uns vereinbaren konnten und wir Ihnen morgen unter [2] alles über unsere Online-Englischkurse erzählen konnten. Ich bitte Sie, Ihre Teilnahme zu bestätigen.%0a%0aIch kann es kaum erwarten, dich kennenzulernen!%0aGrüße,%0a[3] von Edusogno",
    today: "heute",
    tomorrow: "morgen",
    discount: [
      "Hallo! Ich schreibe dir, um dich darüber zu informieren, dass wir den Rabatt in deinem Warenkorb aktiviert haben. Verwende den Code: [1]. Verpassen Sie es nicht, wir warten auf Sie!",
      "Hallo! Ich schreibe dir, um dich darüber zu informieren, dass wir den Rabatt in deinem Warenkorb aktiviert haben. Geben Sie bei vollständiger Zahlung den Code ein: [1]. Verpassen Sie es nicht, wir warten auf Sie!",
    ],
    hours: "Stunden",
    day: "der Tag",
    weeks: [
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
      "Sonntag",
    ],
    months: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
  },
  pt: {
    reminder1:
      "Olá [1], sou [2] da Edusogno! %0aSó queria lembrar-te que tens uma marcação para [3] às [4] para o nosso curso de inglês. Deverias ter recebido o convite para participar e deverias vê-lo no teu calendário ou diretamente na tua caixa de correio. Até já na chamada. \nEstamos ansiosos por te conhecer e ouvir as tuas motivações para melhorar o teu inglês!!",
    reminder2_1:
      "Olá [1] como estás? Sou [2] da Edusogno :) Estamos muito felizes por teres decidido participar no nosso projeto. Queria dizer-te que para os rapazes que se inscreveram esta semana há uma oferta: um amigo teu interessado em melhorar o nível de inglês poderá participar no nosso curso. Neste caso terás direito a um desconto de 50 euros no custo do exame IELTS, por cada amigo que se inscreva e comece o curso. Tens até amanhã às 20.00 para que um ou mais amigos marquem uma reunião comigo onde lhes explicarei como funciona o curso. Basta passar-lhes este link: [3] Até já!",
    reminder2_2:
      "Olá [1] como estás? Sou [2] da Edusogno :) Estamos muito felizes por teres decidido participar no nosso projeto. Queria dizer-te que para os rapazes que se inscreveram esta semana há uma oferta: um amigo teu interessado em melhorar o nível de inglês poderá participar no nosso curso. Neste caso terás direito a um reembolso de 50 euros no custo total, por cada amigo que se inscreva e comece o curso. Tens até amanhã às 20.00 para que um ou mais amigos marquem uma reunião comigo onde lhes explicarei como funciona o curso. Basta passar-lhes este link: [3] Até já!",
    reminder3:
      "Olá [1], como estás? Sou [2] da Edusogno :) Enviamos-te o e-mail de admissão ao nosso curso. Queria perguntar-te se tens intenção de participar ou se devemos atribuir o teu lugar a outro candidato. Por favor, avisa-me, obrigado desde já!",
    reminder4:
      "Olá [1], sou [2] da Edusogno ☺️  vi que marcaste uma reunião comigo para perceber como melhorar o teu inglês. Até [3] às [4]! Entretanto, deixo-te o meu número para qualquer questão ou necessidade. Mal posso esperar para te conhecer e descobrir juntos como podemos ajudar-te. Até já!",
    reminder_after_rescheduled:
      "Olá1]! 👋 Espero que você se sinta fantástico! ☺%0aVi que você conseguiu agendar um novo horário para fazer uma videochamada conosco e poderemos contar tudo sobre nossos cursos de inglês online amanhã às [2]. Peço a gentileza de confirmar sua presença.%0a%0aMal posso esperar para conhecer você!%0aAtenciosamente,%0a[3] por Edusogno",
    today: "hoje",
    tomorrow: "amanhã",
    discount: [
      "Olá! Escrevo-te para te informar que ativámos o desconto no teu checkout. Usa o código: [1]. Não percas, estamos à tua espera!",
      "Olá! Escrevo-te para te informar que ativámos o desconto no teu checkout. No momento do pagamento completo, insere o código: [1]. Não percas, estamos à tua espera!",
    ],
    hours: "horas",
    day: "o dia",
    weeks: [
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
      "Domingo",
    ],
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
  },
};

export const getTranslation = (
  lang: string,
  key: string,
  data?: string[] | undefined
) => {
  // if key has a dot
  let text: any;

  if (key.includes(".")) {
    const keys = key.split(".");

    text = keys.reduce((acc, curr) => acc?.[curr] || false, translations[lang]);
  } else {
    text = translations[lang]?.[key] || false;
  }

  if (!text) {
    text = translations["it"][key];
  }

  if (!text) {
    throw new Error(`Translation not found for key ${key}`);
  }

  if (data?.length) {
    const camps = text.match(/\[\d\]/g)?.length;

    for (let i = 0; i < camps; i++) {
      if (i < data.length) {
        text = text.replace(`[${i + 1}]`, data[i]);
      }
    }
  }

  return text;
};

export const getDayOfWeekStartingMonday = (date: any) => {
  const day = getDay(date);
  return day === 0 ? 6 : day - 1; // Convert Sunday from 0 to 7, keep other days as is
};
