import { useMemo, useState } from "react";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import {
  Box,
  Button,
  Select,
  useTheme,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

type Props = {
  tutors: Array<any>;
  products?: any;
  exportPdf: Function;
  exportCSV: () => string[][];
  filterData: any;
  setFilters: any;
  startMonths: any;
  hasFilterProducts?: boolean | undefined;
};

const Toolbar = (props: Props) => {
  const {
    tutors,
    products,
    exportPdf,
    exportCSV,
    filterData,
    setFilters,
    startMonths,
    hasFilterProducts,
  } = props;
  const theme = useTheme();

  const [age, setAge] = useState<{ age_1?: number; age_2?: number }>();

  const startMonthMenu = useMemo(
    () =>
      startMonths?.data?.map((e: any) => (
        <MenuItem
          key={e.month}
          value={e.month}
        >
          {e.month_en}
        </MenuItem>
      )),
    [startMonths]
  );

  const filtersApplied = useMemo(
    () =>
      Object.keys(filterData).filter(
        (key) => !["search", "sorts", "page"].includes(key)
      ).length,
    [filterData]
  );

  const tempDates = filterData?.dates?.split(",");
  const dates =
    tempDates?.length > 0
      ? [new Date(tempDates[0]), new Date(tempDates[1])]
      : null;

  const handleAgeChange = ({ target: { name, value } }) => {
    const temp = { ...age, [name]: value };

    setAge(temp);

    if (name === "age_2") {
      setFilters((p: any) => ({
        ...p,
        age_range: [...Object.values(temp)],
      }));
    }
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-4"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => {
            setFilters({});
          }}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(filtersApplied)}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <>
                <DateRangePickerField
                  title="Creation date"
                  onChange={(date) => {
                    if (date) {
                      setFilters((p: any) => {
                        return {
                          ...p,
                          dates:
                            format(date.startDate, "yyyy-MM-dd HH:mm:ss") +
                            "," +
                            format(date.endDate, "yyyy-MM-dd HH:mm:ss"),
                        };
                      });
                    } else {
                      setFilters((p: any) => {
                        p.delete("dates");
                        return p;
                      });
                    }
                  }}
                  value={dates}
                />

                <TextField
                  select
                  fullWidth
                  name="tutor"
                  label="Tutor"
                  value={filterData?.tutor ?? ""}
                  onChange={(e) => {
                    setFilters((p: any) => ({
                      ...p,
                      tutor: e.target.value,
                    }));
                  }}
                >
                  {tutors?.map(({ name, token }) => (
                    <MenuItem
                      key={token}
                      value={token}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  fullWidth
                  name="start_month"
                  label="Start month"
                  value={filterData?.start_month ?? ""}
                  onChange={(e) =>
                    setFilters((p: any) => ({
                      ...p,
                      start_month: e.target.value,
                    }))
                  }
                >
                  {startMonthMenu}
                </TextField>

                {hasFilterProducts && (
                  <FormControl>
                    <InputLabel id="demo-multiple-name-label-products">
                      Products
                    </InputLabel>
                    <Select
                      multiple
                      id="demo-multiple-name-label-products"
                      label="Products"
                      input={<OutlinedInput label="Name" />}
                      value={
                        filterData?.products
                          ? JSON.parse(filterData?.products)
                          : []
                      }
                      labelId="demo-multiple-name-label-products"
                      onChange={(e) => {
                        setFilters((p: any) => ({
                          ...p,
                          products: JSON.stringify(e.target.value),
                        }));
                      }}
                    >
                      {products?.map((p: string, i: number) => (
                        <MenuItem
                          key={i}
                          value={p}
                        >
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <TextField
                  fullWidth
                  type="number"
                  label="Number months course"
                  value={filterData?.number_months_course ?? ""}
                  onChange={(e) =>
                    setFilters((p: any) => ({
                      ...p,
                      number_months_course: e.target.value,
                    }))
                  }
                />

                {/* // age picker */}
                <FormControl>
                  <Box
                    style={{ borderColor: theme.palette.primary.main }}
                    className="flex gap-2 items-center place-items-center"
                  >
                    <span
                      style={{ color: theme.palette.primary.main }}
                      className="text-sm"
                    >
                      Age
                    </span>
                    <TextField
                      type="number"
                      name="age_1"
                      label="from"
                      value={age?.age_1 ?? ""}
                      variant="outlined"
                      onChange={handleAgeChange}
                    />
                    <span style={{ color: theme.palette.primary.main }}>-</span>
                    <TextField
                      type="number"
                      name="age_2"
                      label="to"
                      value={age?.age_2 ?? ""}
                      variant="outlined"
                      onChange={handleAgeChange}
                    />
                  </Box>
                </FormControl>
                {/* // end age picker */}
              </>
            </Box>
          }
        />

        <Dropdown
          text="Download"
          icon={filterIcon}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <Button
                variant="outlined"
                onClick={() => exportPdf()}
              >
                Download PDF
              </Button>

              <Button
                variant="outlined"
                onClick={() => {
                  // exportPdf();
                }}
              >
                <CSVLink
                  data={exportCSV()}
                  filename="statistics.csv"
                  separator=";"
                >
                  Download CSV
                </CSVLink>
              </Button>
            </Box>
          }
        />
      </FiltersBar>
    </Box>
  );
};

export default Toolbar;
