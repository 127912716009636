import { useState, useEffect } from "react";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { updateBookedIeltsLink } from "@/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DataGrid,
  GridColDef,
  GridComparatorFn,
  GridRowsProp,
} from "@mui/x-data-grid";
import {
  Box,
  LinearProgress,
  Button,
  capitalize,
  useTheme,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import AddIcon from "@mui/icons-material/Add";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import CheckIcon from "@mui/icons-material/Check";
import EditDialog from "./EditDialog";
import ShowDataMail from "./ShowDataMail";
import ShowTemplateMail from "./ShowTemplateMail";
import Title from "@/components/_Common/Page/Title";
import { getTableStyling } from "@/providers/Colors";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import Toolbar from "./Toolbar";
import { useSearchParams } from "react-router-dom";
import usePagination from "@/hooks/usePagination";
import useDataGridSort from "@/hooks/useDataGridSort";

const KEY_QUERY = ["booked-ielts"];

const HTTP_BASE = "/v1/booked-ielts";
const HTTP_GET = HTTP_BASE + "/get";
const HTTP_SEND_MAIL = HTTP_BASE + "/send-mail";
const HTTP_GET_TEMPLATE_MAIL = HTTP_BASE + "/get-template-mail";
export const HTTP_GET_ALL_LINK_EXAM = HTTP_BASE + "/get_all_link_exam";
export const HTTP_SET_ALL_LINK_EXAM = HTTP_BASE + "/set_all_link_exam";

export interface INEmail {
  open: boolean;
  email: string;
  users: Array<any>;
  errors: any;
  subject: string;
  template: string;
  loadingGet: boolean;
  openConfirm: boolean;
  loadingSend: boolean;
}

const emailDefault: INEmail = {
  open: false,
  email: "",
  users: [],
  errors: null,
  subject: "",
  template: "",
  loadingGet: false,
  openConfirm: false,
  loadingSend: false,
};

const BookedIelts = () => {
  const theme = useTheme();
  const { isMobile, perPage } = useScreen();
  const { userAdmin, snackHandler } = useAuth();

  const { data, isLoading } = useQuery(KEY_QUERY, () => useAPI(HTTP_GET));

  const [rows, setRows] = useState<GridRowsProp[]>([]);
  const [idBook, setIdBook] = useState<number | string | null>(null);
  const [linkExam, setLinkExam] = useState<number | string | null>(null);
  const [tokenUser, setTokenUser] = useState<number | string | null>(null);
  const [dataEmail, setDataEmail] = useState<INEmail>({ ...emailDefault });
  const [editDialog, setEditDialog] = useState<boolean>(false);
  const [templateEmail, setTemplateEmail] = useState<INEmail>({
    ...emailDefault,
  });
  const [selectedUsersID, setSelectedUsersID] = useState<Array<any>>([]);

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ filters, setFilters });

  const { sortModel, onSortModelChange } = useDataGridSort({
    setFilters,
    filters,
  });

  const updateBookedIelts = useMutation(updateBookedIeltsLink, {
    onMutate: (update: any) => {
      const oldData: any = queryClient.getQueryData(["booked-ielts"]);

      const updated = oldData?.map((el: any) => {
        if (el.id == update.id && el.token_user == update.token_user) {
          el.link_exam = update.params.link_exam;
        }
        return el;
      });

      queryClient.setQueryData(["booked-ielts"], updated);
    },
    onSuccess: () => {
      setEditDialog(false);
      setLinkExam(null);
    },
  });

  const handleSelectRows = (ids: Iterable<unknown> | null | undefined) => {
    if (!rows) return;
    const selectedIDs = new Set(ids);
    const selectedRowIds = rows
      ?.filter((user: any) => selectedIDs.has(user.id))
      .map((user: any) => user.id);
    setSelectedUsersID([...selectedRowIds]);
  };

  const handleGetTemplate = async () => {
    setTemplateEmail((p: any) => ({ ...p, loadingGet: true }));
    const template = await useAPI(HTTP_GET_TEMPLATE_MAIL, {
      users: selectedUsersID,
    });
    setTemplateEmail((p: INEmail) => ({
      ...p,
      open: true,
      template,
      loadingGet: false,
    }));
  };

  const handleSendEmail = async () => {
    setTemplateEmail((p: any) => ({
      ...p,
      loadingSend: true,
      openConfirm: false,
    }));
    const res = await useAPI(
      HTTP_SEND_MAIL,
      {
        users: selectedUsersID,
        email: templateEmail?.email,
        subject: templateEmail?.subject,
      },
      { useErrors: true }
    );

    if (res?.errors) {
      setTemplateEmail((p: INEmail) => ({
        ...p,
        errors: res.errors,
        loadingSend: false,
      }));
      return;
    }

    snackHandler("Success send mail!");
    setTemplateEmail({ ...emailDefault });
    setSelectedUsersID([]);
  };

  const handleSearchEmail = async () => {
    setDataEmail((p: any) => ({ ...p, loadingSend: true }));
    const res = await useAPI(
      HTTP_GET_ALL_LINK_EXAM,
      {
        users: selectedUsersID,
        email: dataEmail?.email,
        subject: dataEmail?.subject,
      },
      { useErrors: true }
    );

    if (res?.errors) {
      setDataEmail((p: INEmail) => ({
        ...p,
        errors: res.errors,
        loadingSend: false,
      }));
      return;
    }

    setDataEmail((p: INEmail) => ({ ...p, users: res, loadingSend: false }));
  };

  const handleSetAllLinkExam = async () => {
    setDataEmail((p: INEmail) => ({
      ...p,
      openConfirm: false,
      loadingSend: true,
    }));

    const res = await useAPI(
      HTTP_SET_ALL_LINK_EXAM,
      { users: dataEmail.users },
      { useErrors: true }
    );

    if (Boolean(res?.errors)) {
      snackHandler("Error set all link exam!", "error");
      return;
    }

    snackHandler("Success set all link exam!");
    setDataEmail({ ...emailDefault });
    setSelectedUsersID([]);
    if (res?.users) {
      setRows(res.users);
      queryClient.setQueryData(KEY_QUERY, res.users);
    }
  };

  const dayInMonthComparator: GridComparatorFn<string> = (v1, v2) => {
    const day1 = v1.split("-");
    const day2 = v2.split("-");

    return (
      new Date(day1[2] + "-" + day1[1] + "-" + day1[0]).getTime() -
      new Date(day2[2] + "-" + day2[1] + "-" + day2[0]).getTime()
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Student",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => `${row?.user?.first_name} ${row?.user?.last_name}`,
      renderCell: ({ row }) =>
        row?.user?.first_name && row?.user?.last_name ? (
          <MyTooltip>{`${row?.user?.first_name} ${row?.user?.last_name}`}</MyTooltip>
        ) : (
          "-"
        ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 220,
      flex: 1,
      valueGetter: ({ row }) => row?.user?.email || "-",
      renderCell: ({ row }) =>
        row?.user?.email ? <MyTooltip>{row?.user?.email}</MyTooltip> : "-",
    },
    {
      field: "date_exam",
      headerName: "Date exam",
      minWidth: 110,
      flex: 0.5,
      sortComparator: dayInMonthComparator,
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 90,
      flex: 0.5,
      valueGetter: ({ row }) => capitalize(row?.location),
    },
    {
      field: "method",
      headerName: "Method",
      minWidth: 100,
      flex: 0.5,
      valueGetter: ({ row }) => capitalize(row?.method),
    },
    {
      field: "Link exam",
      headerName: "Actions",
      minWidth: 90,
      flex: 0.5,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      valueGetter: ({ row }) => row?.link_exam || "",
      renderCell: ({ row }) => (
        <Button
          onClick={() => {
            if (row?.link_exam != null) return;

            setEditDialog(true);
            setTokenUser(row?.user?.token);
            setIdBook(row?.id);
          }}
        >
          {row?.link_exam === null ? <AddIcon /> : <CheckIcon />}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (isLoading) return;
    setRows(data);
  }, [isLoading]);

  if (isLoading) return <LinearProgress />;

  return (
    <Box className="mt-8 mx-5 md:mx-10">
      <Title>Exam booking</Title>

      <DataGrid
        checkboxSelection
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={false}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        autoHeight={true}
        selectionModel={selectedUsersID}
        isRowSelectable={({ row }) => !Boolean(row.link_exam)}
        componentsProps={{
          toolbar: {
            filters: filterData,
            setFilters: setFilters,
            selectedUsersID,
            setDataEmail,
            templateEmail,
            handleGetTemplate,
          },
        }}
        experimentalFeatures={{ newEditingApi: false }}
        onSelectionModelChange={(ids) => handleSelectRows(ids)}
        components={{
          Toolbar: Toolbar,
          Pagination: CustomPaginationClient,
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        page={parseInt(page.toString()) - 1}
        onPageChange={(newPage) => {
          setPage(newPage + 1);
        }}
      />

      <EditDialog
        open={editDialog}
        setOpen={setEditDialog}
        linkExam={linkExam}
        onConfirm={() =>
          updateBookedIelts.mutate({
            tk: userAdmin.token,
            token_user: tokenUser,
            id: idBook,
            params: { link_exam: linkExam },
          })
        }
        setLinkExam={setLinkExam}
      />

      <ShowTemplateMail
        isMobile={isMobile}
        templateEmail={templateEmail}
        setTemplateEmail={setTemplateEmail}
      />

      <ShowDataMail
        isMobile={isMobile}
        dataEmail={dataEmail}
        setDataEmail={setDataEmail}
        onSearchEmail={handleSearchEmail}
      />

      <ConfirmationDialog
        open={Boolean(templateEmail?.openConfirm)}
        onClose={() =>
          setTemplateEmail((p: INEmail) => ({ ...p, openConfirm: false }))
        }
        onConfirm={handleSendEmail}
      >
        Do you want to send mail?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={Boolean(dataEmail?.openConfirm)}
        onClose={() =>
          setDataEmail((p: INEmail) => ({ ...p, openConfirm: false }))
        }
        onConfirm={handleSetAllLinkExam}
      >
        Do you want to set all link exam where is null?
      </ConfirmationDialog>
    </Box>
  );
};

export default BookedIelts;
