import { createRef, useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ViewPdfExerciseDialog, {
  IViewExerciseForwardRef,
} from "@/pages/Exercises/ExercisesV1/ViewPdfExerciseDialog";
import useDGS from "@/hooks/useDataGridSort";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import deleteIcon from "@/assets/icons/delete.svg";
import MiniPlayer from "@/pages/Exercises/ExercisesV1/components/MiniPlayer";
import EditMaterial from "./Edit";
import usePagination from "@/hooks/usePagination";
import ToolbarMaterials from "./ToolbarMaterials";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const TableMaterials = ({
  dataAPI,
  onDelete,
  onMutate,
  isLoading,
  userAdmin,
  setOpenCreate,
  setOpenAssignment,
}) => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const [filters, setFilters] = useSearchParams();

  const viewPdfExerciseDialog = createRef<IViewExerciseForwardRef>();

  const [material, setMaterial] = useState<any>();
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { page, setPage } = usePagination({ filters, setFilters });
  const filterData = Object.fromEntries(filters.entries());
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  /**
   * Gets the array of languages from the row and checks if there are null or empty items and returns
   * the language or a hyphen
   * @param row the row object
   * @returns the languages or a hyphen
   */
  const getMaterialLanguage = (row: any) => {
    const isNullOrEmpty = row?.language.some(
      (lang: any) => lang === null || lang === ""
    );

    if (isNullOrEmpty) {
      return "-";
    }

    return row?.language.join(", ");
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      width: 60,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.name}</MyTooltip>,
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "center",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <>
          <Button
            onClick={() => {
              viewPdfExerciseDialog.current?.open({
                name: row?.name,
                url: row?.resource,
              });
            }}
          >
            <PictureAsPdfIcon />
          </Button>

          {Boolean(row?.files?.audio) && (
            <MiniPlayer audioURL={row?.files?.audio} />
          )}
        </>
      ),
    },
    {
      field: "lesson",
      headerName: "Lesson",
      width: 120,
      renderCell: ({ row }) =>
        row?.lesson ? <MyTooltip>{row?.lesson}</MyTooltip> : "-",
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 120,
      flex: 0.5,
      renderCell: ({ row }) => (
        <MyTooltip
          title={row?.category?.map((c: string) => (
            <Box>{c}</Box>
          ))}
        >
          {row?.category?.join(", ")}
        </MyTooltip>
      ),
    },

    {
      field: "language",
      headerName: "Language",
      minWidth: 120,
      flex: 0.5,
      renderCell: ({ row }) => {
        if (!row?.language?.length) return "-";

        const langs = getMaterialLanguage(row);

        return (
          <MyTooltip
            title={langs?.split(", ")?.map((c: string) => (
              <Box>{c}</Box>
            ))}
          >
            {langs}
          </MyTooltip>
        );
      },
    },
    {
      field: "for",
      headerName: "For",
      width: 80,
    },
    {
      field: "precourse",
      headerName: "Precourse",
      width: 100,
      valueFormatter: ({ value }) => (value === 1 ? "true" : "false"),
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              title="Edit"
              color="primary"
              onClick={() => {
                setMaterial(row);
                setOpenEditDialog(true);
              }}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              title="Delete"
              color="primary"
              onClick={() => {
                setMaterial(row);
                setOpenDeleteDialog(true);
              }}
            >
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={dataAPI || []}
        page={parseInt(page.toString()) - 1}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={(row) => row.id}
        sortModel={sortModel}
        components={{
          Toolbar: ToolbarMaterials,
          Pagination: CustomPaginationClient,
        }}
        initialState={{
          sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
        }}
        componentsProps={{
          toolbar: {
            setFilters,
            setOpenCreate,
            setOpenAssignment,
            filters: filterData,
          },
        }}
        onPageChange={(newPage) => setPage(newPage + 1)}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      {Boolean(openEditDialog && material) && (
        <EditMaterial
          onMutate={onMutate}
          material={material}
          userAdmin={userAdmin}
          openUpdate={openEditDialog}
          setOpenUpdate={setOpenEditDialog}
        />
      )}

      <ConfirmationDialog
        open={Boolean(openDeleteDialog && material)}
        title="Delete material"
        onClose={() => {
          setMaterial(undefined);
          setOpenDeleteDialog(false);
        }}
        onConfirm={async () => {
          onDelete(material?.id);
          setMaterial(undefined);
          setOpenDeleteDialog(false);
        }}
      >
        Do you want to delete this material "{material?.name}"?
      </ConfirmationDialog>

      <ViewPdfExerciseDialog ref={viewPdfExerciseDialog} />
    </>
  );
};

export default TableMaterials;
