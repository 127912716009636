import {
  Box,
  IconButton,
  TextField
} from "@mui/material";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import UseDownloadTable from "@/components/UseDownloadTable";
import filterIcon from "@/assets/icons/filter.svg";
import Dropdown from "@/components/_Common/Dropdown";
import { AreSomeKeysEmpty } from "@/utils";
import { EventStatisticsQueryParam } from "@/interfaces";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker as DatePicker } from "@mui/x-date-pickers/MobileDatePicker/MobileDatePicker";
import clearIcon from "@/assets/icons/cancel.svg";


export default ({
                  handleSetEventQuery,
                  eventQuery,


  fileName,
}) => {

  return (
    <Box className="flex gap-4 flex-wrap">

      <Dropdown
        active={AreSomeKeysEmpty<EventStatisticsQueryParam>(eventQuery, "start_date", "end_date")}
        text="Filter"
        icon={filterIcon}
        onClick={() => { }}
        content={
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start date"
                value={eventQuery?.start_date || null}
                onChange={(date) => handleSetEventQuery("start_date", date)}
                closeOnSelect
                inputFormat={"YYYY-MM-DD"}
                openTo="day"
                renderInput={(params) => (
                  // @ts-ignore
                  <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      readOnly: true,
                      endAdornment: (
                        <IconButton
                          onClick={() => handleSetEventQuery("start_date", undefined)}
                          sx={{
                            visibility: eventQuery?.start_date ? "visible" : "hidden",
                          }}
                        >
                          <img
                            src={clearIcon}
                            className="icon"
                          />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End date"
                value={eventQuery?.end_date || null}
                inputFormat={"YYYY-MM-DD"}
                shouldDisableDate={(date) => {
                  if (eventQuery?.start_date) {
                    return date.isBefore(eventQuery.start_date);
                  }
                  return false;
                }}
                onChange={(date) => handleSetEventQuery("end_date", date)}
                closeOnSelect
                renderInput={(params) => (
                  // @ts-ignore
                  <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      readOnly: true,
                      endAdornment: (
                        <IconButton
                          onClick={() => handleSetEventQuery("end_date", undefined)}
                          sx={{
                            visibility: eventQuery?.end_date ? "visible" : "hidden",
                          }}
                        >
                          <img
                            src={clearIcon}
                            className="icon"
                          />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        }
      />

      <UseDownloadTable fileName={fileName} />

      <SearchToolbar />
    </Box>
  );
};
