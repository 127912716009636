import { clone } from "@/components/useHelpers";
import { ExpandMore, Delete, Add } from "@mui/icons-material";
import { IExercise, defDefinition } from "./interfacesAndDefaults";
import {
  Box,
  Button,
  Accordion,
  IconButton,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import InputTextSelect from "@/pages/Exercises/ExercisesV1/components/InputTextSelect";

export default function Definitions({ exercise, setExercise }) {
  return (
    <Accordion className="!rounded !m-0">
      <AccordionSummary expandIcon={<ExpandMore />}>
        Definitions
      </AccordionSummary>

      <AccordionDetails className="flex flex-col">
        {exercise?.definitions.map((definition: any, indexDefinion: number) => {
          return (
            <Box
              className={
                "w-full flex gap-2 items-center p-1 py-3 hover:bg-slate-600/5 rounded " +
                (indexDefinion ? "mt-4" : "")
              }
            >
              <Box className="w-[2rem] text-2xl text-center">
                {indexDefinion + 1}
              </Box>

              <Box className="grow flex flex-col gap-4">
                <InputTextSelect
                  required
                  multiline
                  type="text"
                  label="Value"
                  value={definition?.value ?? ""}
                  maxRows={2}
                  setValue={(value) =>
                    setExercise((e: IExercise) => {
                      const t = { ...e };
                      t.definitions[indexDefinion].value = value;
                      return t;
                    })
                  }
                  placeholder="name exercise"
                />

                <InputTextSelect
                  required
                  multiline
                  type="text"
                  label="Definition"
                  value={definition?.definition ?? ""}
                  maxRows={4}
                  setValue={(value) =>
                    setExercise((e: IExercise) => {
                      const t = { ...e };
                      t.definitions[indexDefinion].definition = value;
                      return t;
                    })
                  }
                  placeholder="name exercise"
                />
              </Box>

              <IconButton
                title="Delete exercise"
                onClick={() => {
                  setExercise((e: IExercise) => {
                    const t = { ...e };
                    t.definitions = t.definitions.filter(
                      (_: any, i: number) => i != indexDefinion
                    );
                    return t;
                  });
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          );
        })}

        <Button
          title="Create new question"
          variant="outlined"
          onClick={() => {
            setExercise((e: IExercise) => {
              const t = { ...e };
              t.definitions.push(clone(defDefinition));
              return t;
            });
          }}
          className="w-full flex gap-2 !py-1"
        >
          <Add /> <span>NEW Definition</span>
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}
