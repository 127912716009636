import { Dispatch, SetStateAction } from "react";
import { Box, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import UseDownloadTable from "@/components/UseDownloadTable";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";

interface INProps {
  range: Array<Date>;
  getAPI: Function;
  disabled: boolean;
  fileName: string;
  setRange: Dispatch<SetStateAction<Array<Date>>>;
  onResetdata: () => void;
  resetDataDisabled: boolean;
}

export default ({ range, setRange, getAPI, disabled, fileName, onResetdata, resetDataDisabled }: INProps) => (
  <Box className="flex gap-4 items-stretch flex-wrap" id="filter_bar">
    <Button variant="outlined" onClick={onResetdata} disabled={resetDataDisabled} title="Reset/Refresh all">
      {resetDataDisabled ? <RefreshIcon /> : <RestartAltIcon />}
    </Button>

    <UseDownloadTable fileName={fileName} />

    <CustomDateRangePicker
      icon={disabled ? <RefreshIcon /> : undefined}
      value={range}
      disabled={disabled}
      onChange={(date) => {
        if (date) setRange([date.startDate, date.endDate]);
        else setRange([]);

        getAPI(date);
      }}
    />
    <SearchToolbar />
  </Box>
);
