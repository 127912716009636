
export const translationsOld = {
    en: {
      titleWithCode: "DISCOUNT CODE",
      titleWithoutCode: "NEW PATH WITH EASYPEASY",
      greeting: "Hello",
      messageWithCode: (nameUser: string) =>
        `Hello ${nameUser},<br />As promised, we are sending you the link and discount code to continue your journey with Edusogno. You will find the details of what we discussed on the link's page.`,
      messageWithoutCode: (nameUser: string) =>
        `Hello ${nameUser},<br />As promised, we are sending you the instructions to continue your English course. At this link, you'll find all the details of what we discussed.`,
      discountInfo: (code: string, expires: number) =>
        `The discount code you can use to continue at a reduced price is "<strong>${code}</strong>". You can copy and paste it at checkout.<br />It is valid for <strong>${expires} days</strong> from now.`,
      prompt: "Make sure to secure your spot within the next 48 hours to continue immediately.",
      closing: "See you soon,<br />the Edusogno Team",
      buttonText: "Open link",
    },
    it: {
      titleWithCode: "CODICE SCONTO",
      titleWithoutCode: "NUOVO PERCORSO CON EDUSOGNO",
      greeting: "Ciao",
      messageWithCode: (nameUser: string) =>
        `Ciao ${nameUser},<br />Come promesso ti inviamo il link ed il codice sconto per continuare il percorso con Edusogno. Troverai nella pagina del link i dettagli di quanto discusso insieme.`,
      messageWithoutCode: (nameUser: string) =>
        `Ciao ${nameUser},<br />Come promesso ti inviamo le indicazioni per continuare il tuo percorso di Inglese. A questo link troverai tutti i dettagli di quanto discusso insieme.`,
      discountInfo: (code: string, expires: number) =>
        `Il codice sconto che puoi inserire per continuare a pagare ad un prezzo agevolato è "<strong>${code}</strong>". Puoi copiarlo ed inserirlo nel checkout.<br />Ti ricordiamo che è valido per <strong>${expires} giorni</strong> da adesso.`,
      prompt: "Per poter proseguire da subito assicurati di bloccare il tuo posto entro le prossime 48h.",
      closing: "A presto,<br />the Edusogno Team",
      buttonText: "Apri link",
    },
    es: {
      titleWithCode: "CÓDIGO DE DESCUENTO",
      titleWithoutCode: "NUEVO CAMINO CON EASYPEASY",
      greeting: "Hola",
      messageWithCode: (nameUser: string) =>
        `Hola ${nameUser},<br />Como prometimos, te enviamos el enlace y el código de descuento para continuar con Edusogno. En la página del enlace encontrarás los detalles de lo que discutimos.`,
      messageWithoutCode: (nameUser: string) =>
        `Hola ${nameUser},<br />Como prometimos, te enviamos las indicaciones para continuar con tu curso de inglés. En este enlace encontrarás todos los detalles de lo que discutimos.`,
      discountInfo: (code: string, expires: number) =>
        `El código de descuento que puedes usar para continuar a un precio reducido es "<strong>${code}</strong>". Puedes copiarlo e introducirlo en el checkout.<br />Es válido por <strong>${expires} días</strong> desde ahora.`,
      prompt: "Asegúrate de asegurar tu lugar dentro de las próximas 48 horas para continuar de inmediato.",
      closing: "Hasta pronto,<br />el equipo de Edusogno",
      buttonText: "Abrir link",
    },
    pt: {
      titleWithCode: "CÓDIGO DE DESCONTO",
      titleWithoutCode: "NOVO CAMINHO COM A EASYPEASY",
      greeting: "Olá",
      messageWithCode: (nameUser: string) =>
        `Olá ${nameUser},<br />Como prometido, estamos enviando o link e o código de desconto para continuar sua jornada com a Edusogno. Você encontrará na página do link os detalhes do que discutimos.`,
      messageWithoutCode: (nameUser: string) =>
        `Olá ${nameUser},<br />Como prometido, estamos enviando as instruções para continuar seu curso de inglês. Neste link, você encontrará todos os detalhes do que discutimos.`,
      discountInfo: (code: string, expires: number) =>
        `O código de desconto que você pode usar para continuar com um preço reduzido é "<strong>${code}</strong>". Você pode copiá-lo e inseri-lo no checkout.<br />Ele é válido por <strong>${expires} dias</strong> a partir de agora.`,
      prompt: "Certifique-se de garantir sua vaga nas próximas 48 horas para continuar imediatamente.",
      closing: "Até breve,<br />a equipe da Edusogno",
      buttonText: "Abrir link",
    },
    fr: {
      titleWithCode: "CODE DE RÉDUCTION",
      titleWithoutCode: "NOUVEAU CHEMIN AVEC EASYPEASY",
      greeting: "Bonjour",
      messageWithCode: (nameUser: string) =>
        `Bonjour ${nameUser},<br />Comme promis, nous vous envoyons le lien et le code de réduction pour continuer votre parcours avec Edusogno. Vous trouverez les détails de ce que nous avons discuté sur la page du lien.`,
      messageWithoutCode: (nameUser: string) =>
        `Bonjour ${nameUser},<br />Comme promis, nous vous envoyons les instructions pour continuer votre cours d'anglais. Sur ce lien, vous trouverez tous les détails de ce que nous avons discuté.`,
      discountInfo: (code: string, expires: number) =>
        `Le code de réduction que vous pouvez utiliser pour continuer à un prix réduit est "<strong>${code}</strong>". Vous pouvez le copier et le coller lors du paiement.<br />Il est valable pendant <strong>${expires} jours</strong> à partir de maintenant.`,
      prompt: "Assurez-vous de réserver votre place dans les 48 heures pour continuer immédiatement.",
      closing: "À bientôt,<br />l'équipe Edusogno",
      buttonText: "Ouvrir le lien",
    },
    de: {
      titleWithCode: "RABATTCODE",
      titleWithoutCode: "NEUER WEG MIT EASYPEASY",
      greeting: "Hallo",
      messageWithCode: (nameUser: string) =>
        `Hallo ${nameUser},<br />Wie versprochen senden wir dir den Link und den Rabattcode, um deinen Weg mit Edusogno fortzusetzen. Auf der Linkseite findest du die Details zu dem, was wir besprochen haben.`,
      messageWithoutCode: (nameUser: string) =>
        `Hallo ${nameUser},<br />Wie versprochen senden wir dir die Anweisungen, um deinen Englischkurs fortzusetzen. Unter diesem Link findest du alle Details zu dem, was wir besprochen haben.`,
      discountInfo: (code: string, expires: number) =>
        `Der Rabattcode, den du verwenden kannst, um zu einem reduzierten Preis weiterzumachen, lautet "<strong>${code}</strong>". Du kannst ihn kopieren und beim Checkout eingeben.<br />Er ist <strong>${expires} Tage</strong> ab jetzt gültig.`,
      prompt: "Stelle sicher, dass du deinen Platz innerhalb der nächsten 48 Stunden sicherst, um sofort weiterzumachen.",
      closing: "Bis bald,<br />das Edusogno-Team",
      buttonText: "Link öffnen",
    },
  };

  interface LanguageTranslations {
    headerImg: string;
    greeting: string;
    greetingUser: (nameUser: string) => string;
    messageContent: string;
    discoverDetails: string;
    callToAction: string;
    discountReminder: (days: string | number) => string;
    farewell: string;
    from: string;
    socialCallToAction: string;
    footer: string;
  }

  interface TranslationsI {
    [key: string]: LanguageTranslations;
  }

  export const translations: TranslationsI = {
    it:{
      headerImg: "https://api.edusogno.com/public/storage/assets/header.png",
      greeting: "Ciao",
      greetingUser : (nameUser: string) => `Ciao <span style="font-weight: bold">${nameUser}</span><br />`,
      messageContent: "<p>Come promesso ti scriviamo tutte le info per poter proseguire il tuo percorso di apprendimento con Edusogno. Cliccando sul link troverai tutti i dettagli di quanto discusso insieme al colloquio.</p>",
      discoverDetails: "<p><span>&#128071;</span> Scopri i dettagli premendo questo bottone <span>&#128071;</span></p>",
      callToAction: "Apri link",
      discountReminder: (days) => `<p
          style=" text-align: center;margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 21px; color: #000; font-size: 13px; ">
          Ti ricordiamo che il tuo posto sarà riservato al prezzo agevolato <br> per <strong>${days} giorni</strong> da adesso.<br /><br />A presto <br><strong style="font-weight: bolder;">IL team di Edusogno</strong> </p>`,
      farewell: "A presto",
      from: "IL team di Edusogno",
      socialCallToAction: `
        <a target="_blank" href="https://www.facebook.com/edusogno"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/facebook.png"
            alt="Fb" title="Facebook" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" /></a>
  
        <a target="_blank" href="https://www.instagram.com/edu.sogno/"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/instagram.png"
            alt="Ig" title="Instagram" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" /></a>
        <a target="_blank" href="https://www.youtube.com/@edusognotutoring2258"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;"><img style="height: 35px; width: 35px; margin: 0 10px;"
            src="https://api.edusogno.com/public/storage/assets/youtube.png"
            alt="Yt" title="Youtube" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " /></a>
  
        <a target="_blank" href="https://www.linkedin.com/company/28861904/admin/"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/linkedIn.png"
            alt="In" title="Linkedin" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" />
        </a>
        <a target="_blank" href="https://www.tiktok.com/@edu.sogno"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/tik_tok.png"
            alt="Tt" title="TikTok" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " />
        </a>`,
      footer: "www.edusogno.com - Realizzato con il 🧠 in&nbsp;🇮🇹"
    },
    en: {
      headerImg: "https://api.edusogno.com/public/storage/assets/easypeasy-header.png",
      greeting: "Hello",
      greetingUser: (nameUser: string) => `Hello <span style="font-weight: bold">${nameUser}</span><br />`,
      messageContent: "<p>As promised, we are writing to you with all the information to continue your learning journey with Edusogno. By clicking the link, you will find all the details discussed during the interview.</p>",
      discoverDetails: "<p><span>&#128071;</span> Discover the details by clicking this button <span>&#128071;</span></p>",
      callToAction: "Open link",
      discountReminder: (days) => `<p style="text-align: center; margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 21px; color: #000; font-size: 13px;">We remind you that your spot will be reserved at the discounted price <br> for <strong>${days} days</strong> from now.<br /><br />See you soon <br><strong style="font-weight: bolder;">The easypeasy team</strong></p>`,
      farewell: "See you soon",
      from: "The easypeasy team",
      socialCallToAction: `
        <a target="_blank" href="https://facebook.com/easypeasyfluent"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/facebook.png"
            alt="Fb" title="Facebook" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" /></a>
  
        <a target="_blank" href="https://www.instagram.com/easypeasyfluent/"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/instagram.png"
            alt="Ig" title="Instagram" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" /></a>
        <a target="_blank" href="https://www.youtube.com/@edusognotutoring2258"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;"><img style="height: 35px; width: 35px; margin: 0 10px;"
            src="https://api.edusogno.com/public/storage/assets/youtube.png"
            alt="Yt" title="Youtube" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " /></a>
  
        <a target="_blank" href="https://www.linkedin.com/company/easypeasyfluent/"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/linkedIn.png"
            alt="In" title="Linkedin" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" />
        </a>
        <a target="_blank" href="http://tiktok.com/@easypeasy_english"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/tik_tok.png"
            alt="Tt" title="TikTok" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " />
        </a>`,
      footer: "www.easypeasyfluent.com - Made with 🧠 in&nbsp;🇨🇭"
    },
    es: {
      headerImg: "https://api.edusogno.com/public/storage/assets/easypeasy-header.png",
      greeting: "Hola",
      greetingUser: (nameUser: string) => `Hola <span style="font-weight: bold">${nameUser}</span><br />`,
      messageContent: "<p>Como prometido, te escribimos con toda la información para continuar tu trayectoria de aprendizaje con Edusogno. Al hacer clic en el enlace, encontrarás todos los detalles discutidos durante la entrevista.</p>",
      discoverDetails: "<p><span>&#128071;</span> Descubre los detalles presionando este botón <span>&#128071;</span></p>",
      callToAction: `Abrir link`,
      discountReminder: (days) => `<p style="text-align: center; margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 21px; color: #000; font-size: 13px;">Te recordamos que tu lugar estará reservado al precio reducido <br> por <strong>${days} días</strong> a partir de ahora.<br /><br />Hasta pronto <br><strong style="font-weight: bolder;">El equipo de easypeasy</strong></p>`,
      farewell: "Hasta pronto",
      from: "El equipo de easypeasy",
      socialCallToAction: `
        <a target="_blank" href="https://facebook.com/easypeasyfluent"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/facebook.png"
            alt="Fb" title="Facebook" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" /></a>
  
        <a target="_blank" href="https://www.instagram.com/easypeasyfluent/"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/instagram.png"
            alt="Ig" title="Instagram" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" /></a>
        <a target="_blank" href="https://www.youtube.com/@edusognotutoring2258"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;"><img style="height: 35px; width: 35px; margin: 0 10px;"
            src="https://api.edusogno.com/public/storage/assets/youtube.png"
            alt="Yt" title="Youtube" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " /></a>
  
        <a target="_blank" href="https://www.linkedin.com/company/easypeasyfluent/"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/linkedIn.png"
            alt="In" title="Linkedin" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" />
        </a>
        <a target="_blank" href="http://tiktok.com/@easypeasy_english"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/tik_tok.png"
            alt="Tt" title="TikTok" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " />
        </a>`,
      footer: "www.easypeasyfluent.com - Hecho con 🧠 en&nbsp;🇨🇭"
    },
    pt: {
      headerImg: "https://api.edusogno.com/public/storage/assets/easypeasy-header.png",
      greeting: "Olá",
      greetingUser: (nameUser: string) => `Olá <span style="font-weight: bold">${nameUser}</span><br />`,
      messageContent: "<p>Como prometido, estamos escrevendo com todas as informações para continuar sua jornada de aprendizado com a Edusogno. Ao clicar no link, você encontrará todos os detalhes discutidos durante a entrevista.</p>",
      discoverDetails: "<p><span>&#128071;</span> Descubra os detalhes clicando neste botão <span>&#128071;</span></p>",
      callToAction: "Abrir link",
      discountReminder: (days) => `<p style="text-align: center; margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 21px; color: #000; font-size: 13px;">Lembramos que seu lugar será reservado pelo preço reduzido <br> por <strong>${days} dias</strong> a partir de agora.<br /><br />Até logo <br><strong style="font-weight: bolder;">A equipe easypeasy</strong></p>`,
      farewell: "Até logo",
      from: "A equipe easypeasy",
      socialCallToAction: `
        <a target="_blank" href="https://facebook.com/easypeasyfluent"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/facebook.png"
            alt="Fb" title="Facebook" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" /></a>
  
        <a target="_blank" href="https://www.instagram.com/easypeasyfluent/"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/instagram.png"
            alt="Ig" title="Instagram" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" /></a>
        <a target="_blank" href="https://www.youtube.com/@edusognotutoring2258"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;"><img style="height: 35px; width: 35px; margin: 0 10px;"
            src="https://api.edusogno.com/public/storage/assets/youtube.png"
            alt="Yt" title="Youtube" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " /></a>
  
        <a target="_blank" href="https://www.linkedin.com/company/easypeasyfluent/"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/linkedIn.png"
            alt="In" title="Linkedin" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" />
        </a>
        <a target="_blank" href="http://tiktok.com/@easypeasy_english"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/tik_tok.png"
            alt="Tt" title="TikTok" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " />
        </a>`,
      footer: "www.easypeasyfluent.com - Feito com 🧠 em&nbsp;🇨🇭"
    },
    de: {
      headerImg: "https://api.edusogno.com/public/storage/assets/easypeasy-header.png",
      greeting: "Hallo",
      greetingUser: (nameUser: string) => `Hallo <span style="font-weight: bold">${nameUser}</span><br />`,
      messageContent: "<p>Wie versprochen schreiben wir dir alle Informationen, um deine Lernreise mit Edusogno fortzusetzen. Wenn du auf den Link klickst, findest du alle Details, die während des Interviews besprochen wurden.</p>",
      discoverDetails: "<p><span>&#128071;</span> Entdecke die Details, indem du diesen Button drückst <span>&#128071;</span></p>",
      callToAction: "Link öffnen",
      discountReminder: (days) => `<p style="text-align: center; margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 21px; color: #000; font-size: 13px;">Wir erinnern dich daran, dass dein Platz zum reduzierten Preis <br> für <strong>${days} Tage</strong> ab jetzt reserviert wird.<br /><br />Bis bald <br><strong style="font-weight: bolder;">Das easypeasy-Team</strong></p>`,
      farewell: "Bis bald",
      from: "Das easypeasy-Team",
      socialCallToAction: `
        <a target="_blank" href="https://facebook.com/easypeasyfluent"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/facebook.png"
            alt="Fb" title="Facebook" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" /></a>
  
        <a target="_blank" href="https://www.instagram.com/easypeasyfluent/"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/instagram.png"
            alt="Ig" title="Instagram" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" /></a>
        <a target="_blank" href="https://www.youtube.com/@edusognotutoring2258"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;"><img style="height: 35px; width: 35px; margin: 0 10px;"
            src="https://api.edusogno.com/public/storage/assets/youtube.png"
            alt="Yt" title="Youtube" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " /></a>
  
        <a target="_blank" href="https://www.linkedin.com/company/easypeasyfluent/"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/linkedIn.png"
            alt="In" title="Linkedin" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" />
        </a>
        <a target="_blank" href="http://tiktok.com/@easypeasy_english"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/tik_tok.png"
            alt="Tt" title="TikTok" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " />
        </a>`,
      footer: "www.easypeasyfluent.com - Hergestellt mit 🧠 in&nbsp;🇨🇭"
    },
    fr: {
      headerImg: "https://api.edusogno.com/public/storage/assets/easypeasy-header.png",
      greeting: "Bonjour",
      greetingUser: (nameUser: string) => `Bonjour <span style="font-weight: bold">${nameUser}</span><br />`,
      messageContent: "<p>Comme promis, nous vous écrivons avec toutes les informations pour continuer votre parcours d'apprentissage avec Edusogno. En cliquant sur le lien, vous trouverez tous les détails discutés lors de l'entretien.</p>",
      discoverDetails: "<p><span>&#128071;</span> Découvrez les détails en appuyant sur ce bouton <span>&#128071;</span></p>",
      callToAction: "Ouvrir le lien",
      discountReminder: (days) => `<p style="text-align: center; margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif; line-height: 21px; color: #000; font-size: 13px;">Nous vous rappelons que votre place sera réservée au prix réduit <br> pour <strong>${days} jours</strong> à partir de maintenant.<br /><br />À bientôt <br><strong style="font-weight: bolder;">L'équipe easypeasy</strong></p>`,
      farewell: "À bientôt",
      from: "L'équipe easypeasy",
      socialCallToAction: `
        <a target="_blank" href="https://facebook.com/easypeasyfluent"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/facebook.png"
            alt="Fb" title="Facebook" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" /></a>
  
        <a target="_blank" href="https://www.instagram.com/easypeasyfluent/"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/instagram.png"
            alt="Ig" title="Instagram" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;" /></a>
        <a target="_blank" href="https://www.youtube.com/@edusognotutoring2258"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;"><img style="height: 35px; width: 35px; margin: 0 10px;"
            src="https://api.edusogno.com/public/storage/assets/youtube.png"
            alt="Yt" title="Youtube" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " /></a>
  
        <a target="_blank" href="https://www.linkedin.com/company/easypeasyfluent/"
          style="-webkit-text-size-adjust: none;-ms-text-size-adjust: none;mso-line-height-rule: exactly;text-decoration: underline;color: #2cb543;font-size: 14px;">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/linkedIn.png"
            alt="In" title="Linkedin" width="32"
            style="display: block;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" />
        </a>
        <a target="_blank" href="http://tiktok.com/@easypeasy_english"
          style=" -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: underline; color: #2cb543; font-size: 14px; ">
          <img style="height: 35px; width: 35px; margin: 0 10px;" src="https://api.edusogno.com/public/storage/assets/tik_tok.png"
            alt="Tt" title="TikTok" width="32"
            style=" display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; " />
        </a>`,
      footer: "www.easypeasyfluent.com - Réalisé avec 🧠 en&nbsp;🇨🇭"
    }
  }