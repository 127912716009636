export default [
  "A0",
  "A1",
  "A2",
  "B1",
  "B1+",
  "B2-",
  "B2",
  "B2+",
  "C1",
];
