import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from '@/hooks/useAuth';
import Request from "./RequestAdmin";
import { uuid } from "@/utils";

const useRecruitingFormApi = () => {
  const { userAdmin, snackHandler } = useAuth();
  const queryClient = useQueryClient();

  const keyCache = "recruiting-forms";

  // Get All Forms ===============

  const getForms = async (params: unknown) => {
    const { data } = await Request.call(
      {
        url: "/v1/recruiting-forms",
        method: "GET",
        params,
      },
      userAdmin.token
    );

    return data;
  };

  const callGetForms = (params = {}) => {
    queryClient.setQueryData([keyCache, "last-params"], () => params);

    return useQuery([keyCache, params], () => getForms(params), {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    });
  };

  // Add Form ================

  const postAddForm = async (data: unknown) => {
    const result = await Request.call(
      {
        url: `/v1/recruiting-forms`,
        method: "POST",
        data,
      },
      userAdmin.token
    );

    return result?.data;
  };

  const callPostAddForm = useMutation(
    (params: { data: unknown }) => postAddForm(params.data),
    {
      onSuccess: async () => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        await queryClient.invalidateQueries([keyCache, params]);

        snackHandler("Form added!");
      },
    }
  );

  // Update Form ==============

  const putUpdateForm = async (id: number, data: unknown) => {
    const result = await Request.call(
      {
        url: `/v1/recruiting-forms/${id}`,
        method: "PUT",
        data,
      },
      userAdmin.token
    );

    return result?.data;
  };

  const callPutUpdateForm = useMutation(
    (params: { id: number; data: unknown }) =>
      putUpdateForm(params.id, params.data),
    {
      onSuccess: async () => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        await queryClient.invalidateQueries([keyCache, params]);

        snackHandler("Form updated!");
      },
    }
  );

  // Delete Form ====================================
  const deleteForm = async (id: number) => {
    const result = await Request.call(
      {
        url: `/v1/recruiting-forms/${id}`,
        method: "DELETE",
      },
      userAdmin.token
    );

    return result?.data;
  };

  const callDeleteForm = useMutation(
    (params: { id: number }) => deleteForm(params.id),
    {
      onSuccess: async () => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        await queryClient.invalidateQueries([keyCache, params]);

        snackHandler("Form deleted!");
      },
    }
  );

  return {
    callGetForms,
    callPostAddForm,
    callPutUpdateForm,
    callDeleteForm,
  };
};

export default useRecruitingFormApi;
