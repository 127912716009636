import saveIcon from "@/assets/icons/save.svg";
import PhoneField from "@/components/_Common/Forms/PhoneField";
import { Box, Button, TextField, Typography } from "@mui/material";
import { ChangeEvent } from "react";

interface ContactMeetingFormProps {
  formUser: any;
  setFormUser: (user: any) => void;
  data: any;
  functions: any;
}

const ContactMeetingForm = ({
  formUser,
  setFormUser,
  data,
  functions,
}: ContactMeetingFormProps) => {
  return (
    <Box
      onSubmit={(e) => {
        e.preventDefault();
        functions?.onSendFormUser?.({ id: data.user.id, formUser });
      }}
      component="form"
      className="flex flex-col w-full gap-5"
    >
      <Typography
        color="primary"
        fontWeight="bold"
      >
        Modify contact information
      </Typography>

      <TextField
        required
        size="small"
        name="first_name"
        label="Name"
        value={formUser?.first_name ?? data.user?.first_name ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFormUser((p: any) => ({ ...p, first_name: e.target.value }))
        }
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <TextField
        required
        size="small"
        name="last_name"
        label="Last name"
        value={formUser?.last_name ?? data.user?.last_name ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFormUser((p: any) => ({ ...p, last_name: e.target.value }))
        }
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <TextField
        required
        size="small"
        name="email"
        label="Email"
        value={formUser?.email ?? data.user?.email ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFormUser((p: any) => ({ ...p, email: e.target.value }))
        }
      />

      <PhoneField
        required
        size="small"
        name="phone"
        label="Phone"
        value={formUser?.phone ?? data.user?.phone ?? ""}
        onChange={(newPhone: string) =>
          setFormUser((p: any) => ({ ...p, phone: newPhone }))
        }
      />

      <Box className="flex flex-row-reverse">
        <Button
          type="submit"
          variant="outlined"
        >
          <img
            src={saveIcon}
            className="icon"
          />
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ContactMeetingForm;
