import { useEffect, useState } from "react";
import { Tooltip, useTheme } from "@mui/material";
import { getCookie, rmCookie, setCookie } from "../Cookie";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const coockieName = "notification_service_sound";

const NotificationBell = () => {
  const theme = useTheme();

  const [isMuted, setIsMuted] = useState<boolean>(false);

  const handleNotificationClick = () => {
    if (isMuted) {
      setIsMuted(false);
      setCookie(coockieName, "true");
    } else {
      setIsMuted(true);
      rmCookie(coockieName);
    }
  };

  useEffect(() => {
    const notification_cookie = getCookie(coockieName);
    if (notification_cookie) {
      setIsMuted(false);
    } else {
      setIsMuted(true);
    }
  }, []);

  return (
    <Tooltip
      title={(isMuted ? "Enable" : "Disable") + " notifications"}
      className="mt-5"
      placement="right"
    >
      <button onClick={() => handleNotificationClick()}>
        {isMuted ? (
          <NotificationsOffIcon sx={{ color: theme.palette.text.primary }} />
        ) : (
          <NotificationsActiveIcon sx={{ color: theme.palette.primary.main }} />
        )}
      </button>
    </Tooltip>
  );
};

export default NotificationBell;
