import { queryClient } from "@/main";
import { Box, Button } from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const Toolbar = ({ QUERY_KEY, dialogTemplateRef }) => (
  <Box
    id="filter_bar"
    className="flex !items-stretch gap-4"
  >
    <Button
      variant="outlined"
      onClick={() => queryClient.invalidateQueries(QUERY_KEY)}
    >
      <img
        src={refreshIcon}
        className="icon"
      />
      Refresh
    </Button>

    <Button
      color="primary"
      variant="outlined"
      onClick={() => dialogTemplateRef?.current?.create()}
    >
      <img
        src={addIcon}
        className="icon"
      />
      Create new
    </Button>

    <SearchToolbar />
  </Box>
);

export default Toolbar;
