import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Button,
  Popover,
  Switch,
  Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useAPI from '@/api/useAPI';

const typeMap = {
  'Never booked': 'Never booked',
  'No show': 'No show',
  'Upselling': 'Upselling',
  'Absent': 'Absent',
  'Low rating': 'Low rating',
  'Review': 'Review',
  'Still to pay': 'Still to pay',
  'Suspended': 'Suspended',
  'Recruiting': 'Recruiting'
};

interface TypesCellProps {
  types: string[];
  adminId: number;
}

const cacheKey = ['callCenter']
const TypesCell: React.FC<TypesCellProps> = ({ types, adminId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentTypes, setCurrentTypes] = useState(types);
  const queryClient = useQueryClient();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const allTogglesOff = currentTypes.length === 0;

  const updateTypes = useMutation(
    async (updatedTypes: string[]) => {
      const response = await useAPI(`v1/call-center/update/${adminId}`, { type: updatedTypes }, { type: 'put' });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );

  const handleToggleChange = (label: string) => {
    const newTypes = currentTypes.includes(label)
      ? currentTypes.filter((type) => type !== label)
      : [...currentTypes, label];
    setCurrentTypes(newTypes);
    updateTypes.mutate(newTypes);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        variant="contained"
        className="icon"
        sx={{ boxShadow: 'none', background: "white" }}
      >
        {allTogglesOff ? <VisibilityOffIcon color='secondary' /> : <VisibilityIcon color='primary' />}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 10 }}>
          {Object.entries(typeMap).map(([key, label]) => (
            <Box key={key} display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{label}</Typography>
              <Switch
                checked={currentTypes.includes(label)}
                onChange={() => handleToggleChange(label)}
              />
            </Box>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default TypesCell;
