import {
  Dispatch,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from "react";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Box, SelectChangeEvent, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import saveIcon from "@/assets/icons/save.svg";
import refundIcon from "@/assets/icons/refund.svg";
import usePaymentsOutApi from "../../api/usePaymentsOutApi";

export interface IData {
  id?: number;
  refunded_date?: string;
  importo?: number;
}

interface Props {
  show: boolean;
  data: IData | null;
  setData: (data: IData | null) => void;
  hideDialog: Dispatch<SetStateAction<boolean>>;
}

const InvoicePaymentOutDialog = ({
  show,
  data,
  setData,
  hideDialog,
}: Props) => {
  const { userAdmin } = useAuth();

  const { callUpdatePaymentOut } = usePaymentsOutApi();

  const [payment, setPayment] = useState<IData | null>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const handlePaymentChange = async (event: SelectChangeEvent) => {
    let name = event.target.name;
    let value = event.target.value;

    setPayment((prev) => {
      let newState = { ...prev, [name]: value };
      return newState;
    });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in payment) {
      formData.append(key, payment[key]);
    }

    if (payment?.id) {
      callUpdatePaymentOut.mutate({
        token: userAdmin.token,
        id: payment.id,
        formData,
        callback: () => {
          setIsLoadingData(false);
          hideDialog(false);
          setPayment(null);
        },
      });
    }
  };

  useEffect(() => {
    setPayment(data);
  }, [data]);

  return (
    <EduModal
      fullWidth
      open={show}
      onClose={() => {
        hideDialog(false);
        setData(null);
        setPayment(null);
      }}
    >
      <EduModalTitle
        onClose={() => {
          hideDialog(false);
          setData(null);
          setPayment(null);
        }}
      >
        <img src={refundIcon} /> Refund
      </EduModalTitle>

      <EduModalBody>
        <Box
          onSubmit={submitForm}
          component="form"
          className=" flex flex-col m-5 gap-5"
        >
          <TextField
            required
            fullWidth
            id="importo"
            type="number"
            name="importo"
            label="Amount"
            value={payment?.importo ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handlePaymentChange(event)
            }
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              openTo="day"
              inputFormat="dd/MM/yyyy"
              views={["day"]}
              value={payment?.refunded_date ?? ""}
              onChange={(newValue) => {
                setPayment((prev) => {
                  let newState = {
                    ...prev,
                    refunded_date: newValue
                      ? dayjs(newValue).format("YYYY-MM-DD")
                      : "",
                  };
                  return newState;
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={false}
                  required
                  fullWidth
                  label="Date"
                />
              )}
            />
          </LocalizationProvider>

          <Box className="py-2 flex flex-1 flex-row-reverse">
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoadingData}
            >
              <img
                src={saveIcon}
                className="icon"
              />
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default InvoicePaymentOutDialog;
