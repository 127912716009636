import { useState } from "react";
import { Box } from "@mui/material";
import jsPDF from "jspdf";
import Toolbar from "./components/Toolbar";
import BasePage from "@/components/Page/Base";
import useUserApi from "@/api/useUserApi";
import html2canvas from "html2canvas";
import useStatisticsApi from "@/api/useStatisticsApi";
import EventsPresencesChart from "./components/EventsPresencesChart";

const UserEventsStats = () => {
  const [filters, setFilters] = useState([]);

  const { callGetStartMonths } = useUserApi();

  const { data: startMonths } = callGetStartMonths();

  const { callGetEventsPresencesStatistics } = useStatisticsApi();

  const {
    data: AllEvents,
    isLoading,
    isFetching,
  } = callGetEventsPresencesStatistics({
    filter: {
      ...filters,
    },
  });

  const {
    data: EventsByProduct,
    isLoading: fIsLoading,
    isFetching: fIsFetching,
  } = callGetEventsPresencesStatistics(
    { filter: { ...filters } },
    "events-by-product"
  );

  const products = AllEvents?.data?.products_filter;

  const exportPdf = async () => {
    const capture = document.querySelector("#capture") as HTMLElement;

    const canvas = await html2canvas(capture, { scale: 5 });

    const img = canvas.toDataURL("image/png");
    const wid = canvas.width;
    const hgt = canvas.height;
    const hratio = hgt / wid;
    const doc = new jsPDF("l", "px", "a4");
    const width = doc.internal.pageSize.width;
    const height = width * hratio;

    doc.addImage(img, "JPEG", 0, 0, width, height, "someAlias", "FAST");
    doc.save("statistics.pdf");
  };

  const exportCSV = () => {
    const sources = [
      {
        name: "Events presences",
        data: AllEvents,
      },
      {
        name: "Events presences",
        data: EventsByProduct,
      },
    ];
    const types = ["daily", "weekly"];

    let content: string[][] = [];

    sources.forEach((source) => {
      types.forEach((type) => {
        content.push([source.name + " " + type]);
        const data = source.data?.data[type];

        const datasets = Object.keys(data?.datasets || {});
        const labels = [""].concat(datasets || []);
        content.push(labels);

        (data?.labels || []).map((day, index) => {
          let row = [day];
          datasets.map((dataset) => {
            row.push(data?.datasets[dataset][index]);
          });
          content.push(row);
        });

        // add padding
        content.push([""]);
      });
    });

    return content;
  };

  return (
    <BasePage title="User events stats">
      <Toolbar
        hasFilterProducts
        tutors={AllEvents?.data?.tutors || []}
        products={products}
        exportPdf={exportPdf}
        exportCSV={exportCSV}
        filterData={filters}
        setFilters={setFilters}
        startMonths={startMonths}
      />

      <Box
        id="capture"
        className="grid auto-rows-auto gap-5"
      >
        <Box className="grid grid-flow-row xl:grid-cols-2 gap-5 pt-5">
          <EventsPresencesChart
            type={"weekly"}
            data={AllEvents}
            isLoading={isLoading}
            isFetching={isFetching}
          />

          <EventsPresencesChart
            type={"daily"}
            data={AllEvents}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        </Box>

        <Box className="grid grid-flow-row xl:grid-cols-2 gap-5 pt-5">
          <EventsPresencesChart
            type={"weekly"}
            data={EventsByProduct}
            isLoading={fIsLoading}
            isFetching={fIsFetching}
          />

          <EventsPresencesChart
            type={"daily"}
            data={EventsByProduct}
            isLoading={fIsLoading}
            isFetching={fIsFetching}
          />
        </Box>
      </Box>
    </BasePage>
  );
};

export default UserEventsStats;
