export const API_ENDPOINTS = {
  V2: {
    EVENTS: "/admin/v2/events/all",
    EVENTMEETING: "/admin/v2/events/meeting-link",
    EVENT_STATISTICS: "/admin/v2/events/info",
    EVENT_TUTORS: "/admin/v2/events/tutors",
    EVENT_USERS: "/admin/v2/events/users",
    EVENT_CREATE: "/admin/v2/events/create",
    EVENT_EDIT_CLASS_STUDENTS: "/admin/v2/events/edit-class-students",
    EVENT_CREATE_BULK: "/admin/v2/events/create-bulk",
    SEARCH_RELATED_EVENTS: "/admin/v2/events/search/related-events",
    BULK_EDIT_MATERIALS_EXERCISES_STUDENTS:
      "/admin/v2/events/materials-exercises",
    EVENT_UPDATE: (event_id: number) => `/admin/v2/events/${event_id}/update`,
    DESTROY_EVENT: (event_id: number) => `/admin/v2/events/${event_id}/destroy`,
    CALENDAR_LIST: "/v2/calendar/list",
    CALENDAR_CREATE_OR_UPDATE: "/v2/calendar/create-or-update",
    PAYMENT_OPTIONS: "/user/v2/checkout/payment-options",
  },
  V1: {
    LIST_MATERIAL_EXERCISES: "/admin/v1/events/list/materials-exercises",
    LIST_MATERIALS: "/admin/v1/materials",
    TECHNICAL_PROBLEM: (event_id: number) =>
      `/admin/v1/events/technical-problem/${event_id}`,
    CRM: {
      GET_IMG_CALENDARS: "/admin/v1/crm/get-img-calendars",
    },
    USERS: {
      CONFIRM_PAYMENT: "/admin/v1/users/confirm-payment-v2",
      UPDATE: (token: string) => `/admin/v1/users/${token}/update`,
      RESET_DISCOUNT_CODE: "/admin/v1/users/reset-discount-code",
      GET_REVIEWS: "/admin/v1/get-reviews",
    },
    UPSELLING: {
      LIST: "/admin/v1/upselling/crm",
      UPDATE: "/admin/v1/upselling/crm/update",
      TEMPLATE_EMAIL_SEND: "/admin/v1/upselling/crm/template/email/send",
      UPDATE_MEETING: "/admin/v1/upselling/crm/update-meeting",
      UPDATE_MEETING_OTHER: "/admin/v1/upselling/crm/update-meeting-other",
      UPSOLDS_LIST: "/admin/v1/upselling/users-upsolds",
    },
    EXERCISE: {
      ADD_EXERCISE_OLD: "/admin/v1/create-exercise",
      ADD_EXERCISE_NEW: "/admin/v2/exercises/create",
      EDIT_EXERCISE_NEW: "/admin/v2/exercises/edit",
      EDIT_EXERCISE_OLD: "/admin/v1/update-exercise",
      GET_EXERCISES: "/admin/v2/exercises/get",
      DELETE_EXERCISE_NEW: (id) => `/admin/v2/exercises/delete/${id}`,
      DELETE_EXERCISE_OLD: "/admin/v1/delete-exercise",
    },
    COUNTRY_LIST: "/v1/countries-list",
    GET_CRM_USER: (token) => `/v1/crm/user/${token}`,
    BLOG: {
      LIST: "/v1/blog/posts",
      CREATE: "/v1/blog/post/create",
      UPDATE: (id) => `/v1/blog/post/${id}/update`,
      DELETE: (id) => `/v1/blog/post/${id}/delete`,
    },
    PRODUCT: {
      PRICES: {
        CREATE_OR_UPDATE: (productId) => `/v1/products/prices/${productId}`,
        DELETE: (priceId) => `/v1/products/prices/${priceId}`,
        RESTORE: (priceId) => `/v1/products/prices/${priceId}/restore`,
      },

      LOCALE_DATA: {
        CREATE_OR_UPDATE: (productId) =>
          `/v1/products/locale-data/${productId}`,
        DELETE: (localeDataId) => `/v1/products/locale-data/${localeDataId}`,
        RESTORE: (localeDataId) =>
          `/v1/products/locale-data/${localeDataId}/restore`,
      },
      LIST_BY_LANGUAGE: (lang) => `/v1/products/${lang}/list`,
    },
  },
  V3: {
    ORDER: (order_tk: string) => `/v3/orders/get/${order_tk}`,
    ORDER_CREATE: "/v3/orders/create",
    ORDER_LIST: "/v3/orders/list",
    ORDER_UPDATE: (order_tk: string) => "/v3/orders/update/" + order_tk,
  },
};
