import useAuth from '@/hooks/useAuth';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getEventsPrsencesStats } from "@/api/statisticsApi";

interface IStats {
    labels: string[];
    datasets: number[];
}

export interface IEventsPresencesStatistics {
    data: {
        daily: IStats;
        weekly: IStats;
        total_events_by_dataset: {
            label: string;
            value: number;
        }[],
        products_filter: string[];
        tutors: Array<any>;
    }
}

const useStatisticsApi = () => {
    const { userAdmin } = useAuth();
    const queryClient = useQueryClient();

    const keyCache = "events-presences-statistics";

    const callGetEventsPresencesStatistics = (params = {}, key: string | null = null) => {

        let newKey = key || keyCache;

        queryClient.setQueryData([newKey, "last-params"], () => params);
        return useQuery(
            [newKey, params],
            async ({ signal }) => {
                const rs: IEventsPresencesStatistics = await getEventsPrsencesStats(userAdmin.token, params, signal);
                return rs;
            },
            {
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            }
        );
    };


    return {
        callGetEventsPresencesStatistics,
    };

}

export default useStatisticsApi;