import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useUserApi from "@/api/useUserApi";
import InvoiceCard from "./InvoiceCard";
import feedbackIcon from "@/assets/icons/invoice.svg";

export interface IForwardRef {
  open: (token: string) => void;
}

interface IProps {
  ref: HTMLButtonElement;
}

const InvoiceUserDialog = forwardRef<IForwardRef, IProps>((_, ref) => {
  const [user, setUser] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  const { data, isLoading } = useUserApi().callGetUserWithDates(token);

  useImperativeHandle(ref, () => ({
    open: (value) => {
      setOpen(true);
      setToken(value);
      setUser({});
    },
  }));

  useEffect(() => {
    if (!data?.user) return;
    setUser(data?.user);
    return () => {
      setUser({});
    };
  }, [data?.user, open, isLoading]);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
    >
      <EduModalTitle onClose={() => setOpen(false)}>
        <img src={feedbackIcon} />
        INVOICES - {user?.first_name} {user?.last_name}
      </EduModalTitle>

      <EduModalBody className="flex flex-col p-5 max-h-[50vh] overflow-y-auto">
        <InvoiceCard
          user={user}
          loading={isLoading}
        />
      </EduModalBody>
    </EduModal>
  );
});

export default InvoiceUserDialog;
