import { Dispatch, SetStateAction } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import UseDownloadTable from "@/components/UseDownloadTable";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";

interface INProps {
  range: Array<Date>;
  getAPI: Function;
  splitBy: string;
  fileName: string;
  disabled: boolean;
  setRange: Dispatch<SetStateAction<Array<Date>>>;
  onOpenFilter: () => void;
  onSetSplitBy: (e: any) => void;
}

export default ({ range, setRange, getAPI, disabled, splitBy, onSetSplitBy, fileName, onOpenFilter }: INProps) => (
  <Box className="flex gap-4 items-center" id="filter_bar">
    <UseDownloadTable fileName={fileName} />

    <CustomDateRangePicker
      icon={disabled ? <RefreshIcon /> : undefined}
      value={range}
      disabled={disabled}
      onChange={(date) => {
        if (date) setRange([date.startDate, date.endDate]);
        else setRange([]);

        getAPI(date);
      }}
    />

    <TextField
      select
      label="View"
      color="primary"
      variant="outlined"
      sx={{ "& .MuiInputLabel-root": { color: "#fff" }, minWidth: "6rem" }}
      value={splitBy}
      disabled={disabled}
      onChange={onSetSplitBy}
      InputLabelProps={{ shrink: true }}
    >
      <MenuItem value="day">Day</MenuItem>
      <MenuItem value="week">Week</MenuItem>
      <MenuItem value="month">Month</MenuItem>
    </TextField>

    <Button variant="outlined" sx={{ alignSelf: "stretch" }} onClick={onOpenFilter}>
      <FilterAltIcon />
    </Button>

    <SearchToolbar />
  </Box>
);
