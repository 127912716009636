import { Dispatch, SetStateAction, useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useTheme } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { WarningAmber } from "@mui/icons-material";
import {
  euro,
  clone,
  fUpper,
  splitDateRangeIntoMonths,
} from "@/components/useHelpers";
import {
  Box,
  Badge,
  Button,
  Popover,
  MenuItem,
  TextField,
} from "@mui/material";
import cn from "classnames";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import isBetween from "dayjs/plugin/isBetween";
import MyTooltip from "@/components/MyTooltip";
import ConfirmationDialog from "../ConfirmationDialog";

dayjs.extend(isBetween);

export interface IAnchorElProduct {
  data?: any;
  element?: HTMLElement | null;
}

export const defaultAnchorElProduct: IAnchorElProduct = {
  data: null,
  element: null,
};

export const valueGetterProduct = (row: any): string => {
  if (!row?.products?.length) return "-";

  const currentRangeProduct =
    row?.products?.filter(
      (el: any) =>
        (dayjs().isSame(el.pivot.start_date) ||
          dayjs().isAfter(el.pivot.start_date)) &&
        (dayjs().isSame(el.pivot.end_date) ||
          dayjs().isBefore(el.pivot.end_date))
    )?.[0] ?? row?.products?.at(-1);

  // const lastPayment = row?.payments[row?.payments?.length - 1];
  // return row?.lastPayment?.product?.product_tag;
  return currentRangeProduct?.product_tag;
};

interface IPropsCell {
  row: any;
  setAnchorElProduct: Dispatch<SetStateAction<IAnchorElProduct | {}>>;
  setAnchorElPopoverSetProductFromPayment?: Dispatch<
    SetStateAction<IAnchorElProduct | {}>
  >;
}

interface IPropsShowPopoverSetProductFromPayment {
  theme: any;
  payments: Array<any>;
  tokenUser: string;
  setConfirmSubmit: Dispatch<SetStateAction<IConfirmSubmit | {}>>;
}

const ShowPopoverSetProductFromPayment = ({
  theme,
  payments,
  tokenUser,
  setConfirmSubmit,
}: IPropsShowPopoverSetProductFromPayment) => {
  const { data: kicks } = useQuery(["kickoff-date"], () =>
    useAPI("/v1/kickoff-date")
  );

  return (
    <>
      {payments?.map((payment: any, i: number) => {
        const [value, setValue] = useState<IConfirmSubmit>({
          tokenUser,
          productId: payment?.product?.id,
        });

        const handleSet = ({ target: { name, value } }) =>
          setValue((p) => ({ ...p, [name]: value }));

        const handleSubSave = (e: any) => {
          e.preventDefault();
          setConfirmSubmit({ ...value, open: true });
        };

        const startKick = useMemo(() => {
          if (value?.endDate && !i) {
            const idx = kicks?.findIndex(
              (k: any) =>
                k.kickoff_date?.split("/").reverse().join("-") == value?.endDate
            );
            return kicks?.slice(0, idx) || [];
          }
          return kicks ?? [];
        }, [kicks, value?.endDate]);

        const endKick = useMemo(() => {
          if (value?.startDate && !i) {
            const idx = kicks?.findIndex(
              (k: any) =>
                k.kickoff_date?.split("/").reverse().join("-") ==
                value?.startDate
            );
            return kicks?.slice(idx + 1) || [];
          }
          return kicks ?? [];
        }, [kicks, value?.startDate]);

        return payment?.product ? (
          <Box
            sx={{ borderColor: theme.palette.primary.main }}
            key={i}
            onSubmit={handleSubSave}
            component="form"
            className={cn(
              "border-t p-4 flex flex-col gap-4 border mt-3 mx-3 rounded",
              payments?.length - 1 == i ? "mb-3" : ""
            )}
          >
            <Box className="max-w-[300px] truncate">
              <MyTooltip>{payment?.product?.product_tag}</MyTooltip>
            </Box>

            <Box className="truncate">
              <Box>LTV: {euro(payment?.ltv)}</Box>
            </Box>

            {!i && (
              <>
                {[
                  {
                    value: "startDate",
                    label: "Start month",
                  },
                  {
                    value: "endDate",
                    label: "End month",
                  },
                ].map((k, i) => (
                  <TextField
                    select
                    required
                    fullWidth
                    key={k.value + i}
                    name={k.value}
                    label={k.label}
                    value={value?.[k.value] ?? ""}
                    onChange={handleSet}
                  >
                    {(i ? endKick : startKick)?.map((kick: any, i: number) => (
                      <MenuItem
                        key={kick?.id + i}
                        value={(kick?.kickoff_date as string)
                          ?.split("/")
                          .reverse()
                          .join("-")}
                      >
                        {kick?.start_month}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}

                <Box className="w-full flex justify-end">
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={dayjs(value?.startDate) >= dayjs(value?.endDate)}
                  >
                    save
                  </Button>
                </Box>
              </>
            )}
          </Box>
        ) : null;
      })}
    </>
  );
};

interface IConfirmSubmit {
  open?: boolean;
  endDate?: string;
  startDate?: string;
  tokenUser?: string;
  productId?: number;
}

export const PopoverSetProductFromPayment = ({
  keyQuery,
  anchorElProduct,
  setAnchorElProduct,
}: IPropsPopover) => {
  const theme = useTheme();
  const { snackHandler } = useAuth();

  const [confirmSubmit, setConfirmSubmit] = useState<IConfirmSubmit>({});

  const handleSubmit = async () => {
    setConfirmSubmit((p) => {
      const t = clone(p);
      delete t.open;
      return t;
    });

    const res = await useAPI(
      "/v1/users/create-user-product-from-payment",
      confirmSubmit
    );

    if (!res?.status) {
      snackHandler(res?.message || "Failed create association!", "error");
      return;
    }

    snackHandler("Success created association!");
    setConfirmSubmit({});
    setAnchorElProduct({});

    if (keyQuery) {
      queryClient.invalidateQueries(keyQuery);
    }
  };

  const uniquePayments = useMemo(
    () =>
      [
        ...new Map(
          anchorElProduct.data?.payments?.map((p: any) => [p?.product_id, p])
        )?.values(),
      ] || [],
    [anchorElProduct.data?.payments]
  );

  const productsId = useMemo(
    () =>
      anchorElProduct.data?.products
        ?.map((p: any) => p?.id)
        ?.filter((p: any) => typeof p == "number") || [],
    [anchorElProduct.data?.products]
  );

  return (
    <Popover
      open={Boolean(anchorElProduct.element)}
      onClose={() => setAnchorElProduct(defaultAnchorElProduct)}
      anchorEl={anchorElProduct.element}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Box className="flex flex-col items-stretch gap-2">
        <ShowPopoverSetProductFromPayment
          theme={theme}
          payments={
            uniquePayments?.filter(
              (pay: any) => !productsId.includes(pay?.product_id)
            ) || []
          }
          tokenUser={anchorElProduct.data?.token ?? ""}
          setConfirmSubmit={setConfirmSubmit}
        />
      </Box>

      <ConfirmationDialog
        open={Boolean(confirmSubmit?.open)}
        onClose={() =>
          setConfirmSubmit((p: IConfirmSubmit) => ({ ...p, open: false }))
        }
        onConfirm={handleSubmit}
      >
        Do you want to create this association: user - product?
      </ConfirmationDialog>
    </Popover>
  );
};

interface IPropsWarningUserPorduct {
  row: any;
  color?: "error" | "warning";
  setAnchorElPopoverSetProductFromPayment: Dispatch<
    SetStateAction<IAnchorElProduct | {}>
  >;
}

const WarningUserPorduct = ({
  row,
  color = "error",
  setAnchorElPopoverSetProductFromPayment,
}: IPropsWarningUserPorduct) => (
  <MyTooltip
    title="There is a lack of association between users and products, you can tell from the invoices"
    className="!w-[3rem]"
  >
    <Button
      size="small"
      variant="text"
      onClick={(e) =>
        setAnchorElPopoverSetProductFromPayment((p: IAnchorElProduct) => ({
          ...p,
          data: row,
          element: e.currentTarget,
        }))
      }
      className="!py-0 !min-w-[2.5rem] !max-w-[2.5rem] !aspect-square !rounded-full"
    >
      <WarningAmber
        color={color}
        className="!-mr-[0.08rem]"
      />
    </Button>
  </MyTooltip>
);

export const RenderCellProduct = ({
  row,
  setAnchorElProduct,
  setAnchorElPopoverSetProductFromPayment,
}: IPropsCell) => {
  const countProduct = row?.products?.length;

  const lastPayment =
    row?.payments?.length > 0
      ? row?.payments?.[row?.payments?.length - 1]
      : null;

  const showWarning =
    row?.payments?.length &&
    typeof lastPayment?.product?.product_id == "number" &&
    row?.notHasProduct &&
    setAnchorElPopoverSetProductFromPayment;

  if (showWarning && !countProduct) {
    return (
      <WarningUserPorduct
        {...{ row, setAnchorElPopoverSetProductFromPayment }}
      />
    );
  }

  // const activeProduct = lastPayment?.product || null;

  // const count = row?.payments?.length;

  // const indexActiveProduct = row?.payments?.findIndex(
  //   (payment: any) => payment?.product_id == activeProduct?.id
  // );

  // Select the current product in user_product
  const currentRangeProduct =
    row?.products?.filter(
      (el: any) =>
        (dayjs().isSame(el.pivot.start_date) ||
          dayjs().isAfter(el.pivot.start_date)) &&
        (dayjs().isSame(el.pivot.end_date) ||
          dayjs().isBefore(el.pivot.end_date))
    )?.[0] ?? row?.products?.at(-1);

  return (
    <Box className="flex flex-nowrap justify-between items-center w-full gap-2">
      {/* {showWarning ? (
        <WarningUserPorduct
          color={indexActiveProduct < count - 1 ? "error" : "warning"}
          {...{ row, setAnchorElPopoverSetProductFromPayment }}
        />
      ) : null} */}

      {currentRangeProduct ? (
        <MyTooltip arrow>{currentRangeProduct?.product_tag}</MyTooltip>
      ) : (
        "-"
      )}

      {/* {activeProduct?.product_tag ? (
        <MyTooltip arrow>{activeProduct?.product_tag}</MyTooltip>
      ) : (
        "-"
      )} */}

      {Boolean(countProduct) && (
        <Button
          size="small"
          title={`Info product${Boolean(countProduct) && "s"}`}
          variant="text"
          className="!py-0 !min-w-[2rem] !max-w-[2rem] !aspect-square !rounded-full flex justify-center items-center"
          onClick={(e) => {
            setAnchorElProduct((p: IAnchorElProduct) => ({
              ...p,
              data: row,
              element: e.currentTarget,
            }));
          }}
        >
          !
        </Button>
      )}
    </Box>
  );
};

interface IStructureMonth {
  ielts: number;
  grammar: number;
  speaking: number;
  one_to_one: number;
  writing_correction: number;
}

interface IPropsPopoverBody {
  theme: any;
  product: any;
  isCurrentMonth?: boolean;
}

const PopoverProductBody = ({
  theme,
  product,
  isCurrentMonth = false,
}: IPropsPopoverBody) => {
  const formatDateIta = (v: string) =>
    dayjs(product?.pivot?.[v]).format("YYYY-MM-DD");

  let structure: IStructureMonth | undefined =
    product?.course_info?.course_structure?.at(-1);

  if (product?.pivot) {
    const indexMonth = splitDateRangeIntoMonths(
      product?.pivot?.start_date,
      product?.pivot?.end_date,
      true
    )?.[0]?.index;

    structure = product?.course_info?.course_structure?.[indexMonth];
  }

  if (!isCurrentMonth) {
    structure = product?.course_info?.course_structure?.reduce(
      (acc: IStructureMonth, month: IStructureMonth) => {
        Object.entries(month).map(([key, value]) => {
          if (!(key in (acc || {}))) {
            acc[key] = 0;
          }
          acc[key] += value;
        });
        return acc;
      },
      {} as IStructureMonth
    );
  }

  let isTextMonth = false;

  if (!structure) {
    isTextMonth = true;
    structure = product?.course_info?.course_structure?.at(-1);
  }

  return (
    <Box className="flex flex-col gap-2">
      {!isCurrentMonth && product?.pivot && (
        <Box>
          <span style={{ color: theme.palette.primary.main }}>Status: </span>
          {dayjs().isAfter(product?.pivot?.end_date) && "Old product"}
          {dayjs().isBefore(product?.pivot?.start_date) && "Next product"}
        </Box>
      )}

      <Box>
        <span style={{ color: theme.palette.primary.main }}>Name: </span>
        {product?.product_tag}
      </Box>

      {product?.pivot && (
        <Box className="flex justify-between gap-4">
          <Box>
            <span style={{ color: theme.palette.primary.main }}>Start: </span>
            {formatDateIta("start_date")}
          </Box>

          <Box>
            <span style={{ color: theme.palette.primary.main }}>End: </span>
            {formatDateIta("end_date")}
          </Box>
        </Box>
      )}

      {structure && (
        <Box>
          <Box sx={{ color: theme.palette.primary.main }}>
            {isCurrentMonth
              ? isTextMonth
                ? ""
                : "Hours in current month - " + dayjs().format("MMMM")
              : "Hours total product"}
          </Box>

          <Box
            sx={{ borderColor: theme.palette.primary.main }}
            className="flex flex-nowrap w-full border rounded"
          >
            {Object.entries(structure || []).map(([key, value], ix: number) => (
              <Box
                sx={{ borderColor: theme.palette.primary.main }}
                key={key + value + ix}
                className={`flex flex-col grow ${ix && "border-l"}`}
              >
                <Box
                  sx={{
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                  }}
                  className="pt-2 px-2 pb-1 border-b"
                >
                  {fUpper(key.replaceAll("_", " "))}
                </Box>

                <Box className="p-2">{String(value)}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface IShowProductData {
  theme: any;
  products: Array<any>;
}

const ShowProductData = ({ theme, products }: IShowProductData) => {
  return (
    <>
      {products?.map((product: any, i: number) => {
        const isCurrentMonth = dayjs().isBetween(
          product?.pivot?.start_date,
          product?.pivot?.end_date
        );

        return (
          <Box
            sx={{ borderColor: theme.palette.primary.main }}
            key={i}
            className="border-t p-4"
          >
            {isCurrentMonth ? (
              <Badge
                color="primary"
                variant="dot"
                className="w-full flex flex-col pt-3 gap-2"
                componentsProps={{
                  badge: { style: { backgroundColor: "chartreuse" } },
                }}
              >
                <PopoverProductBody
                  isCurrentMonth
                  theme={theme}
                  product={product}
                />
              </Badge>
            ) : (
              <PopoverProductBody
                theme={theme}
                product={product}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};

interface IPropsPopover {
  keyQuery?: Array<any>;
  anchorElProduct: IAnchorElProduct;
  setAnchorElProduct: Dispatch<SetStateAction<IAnchorElProduct | {}>>;
}

export const PopoverProduct = ({
  anchorElProduct,
  setAnchorElProduct,
}: IPropsPopover) => {
  const theme = useTheme();

  return (
    <Popover
      open={Boolean(anchorElProduct.element)}
      onClose={() => setAnchorElProduct(defaultAnchorElProduct)}
      anchorEl={anchorElProduct.element}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box className="flex flex-col items-stretch gap-2">
        <ShowProductData
          theme={theme}
          products={anchorElProduct.data?.products || []}
        />
      </Box>
    </Popover>
  );
};
