import { useState } from "react";
import { Box } from "@mui/system";
import useAuth from '@/hooks/useAuth';
import { useContextCrm } from "../Context";
import { Button, TextField } from "@mui/material";
import apis from "../apis";

const EditFullName = () => {
  const context = useContextCrm();
  const { update } = apis(context);
  const { snackHandler } = useAuth();

  const [firstName, setFirstName] = useState<string>(
    context.popover?.row.first_name ?? ""
  );
  const [lastName, setLastName] = useState<string>(
    context.popover?.row.last_name ?? ""
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    update({ last_name: lastName, first_name: firstName }, (res) => {
      if (res?.status == false) {
        snackHandler(res?.message ?? "Failed edit full name!", "error");
        return;
      }

      snackHandler("Success edit full name!");
      context.getInvalidateQuery();
    });

    context.setPopover(undefined);
    context.setRowSelected(undefined);
  };

  return (
    <Box
      onSubmit={handleSubmit}
      component="form"
      className="flex flex-col gap-2 p-2 pt-3 rounded min-w-11"
    >
      <Box className="flex flex-col gap-3">
        <TextField
          required
          fullWidth
          label="First name"
          value={firstName ?? ""}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          required
          fullWidth
          label="Last name"
          value={lastName ?? ""}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>

      <Button
        fullWidth
        type="submit"
        variant="contained"
      >
        SAVE
      </Button>
    </Box>
  );
};

export default EditFullName;
