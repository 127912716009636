import { forwardRef, useImperativeHandle, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import materialIcon from "@/assets/icons/material.svg";
import ExercisesMaterialsBox from "./ExercisesMaterialsBox";

export interface IForwardRef {
  open: (params: any) => void;
}

interface IProps {
  ref: HTMLButtonElement;
  query: object;
  onClearSelect: Function;
  selectedEvents: any[];
}

const EditExerciseMaterialDialog = forwardRef<IForwardRef, IProps>(
  (props, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const [paramsFromOpen, setParamsFromOpen] = useState<any>({});

    useImperativeHandle(ref, () => ({
      open: (params: any) => {
        setOpen(true);
        setParamsFromOpen(params);
      },
    }));

    return (
      <EduModal
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
      >
        <EduModalTitle onClose={() => setOpen(false)}>
          <img src={materialIcon} />
          {paramsFromOpen?.isRemove ? "Remove" : "Add"}: EXERCISES / MATERIALS / STUDENTS
        </EduModalTitle>

        <EduModalBody className="p-5">
          <ExercisesMaterialsBox
            query={props.query}
            events={props.selectedEvents}
            paramsFromOpen={paramsFromOpen}
          />
        </EduModalBody>
      </EduModal>
    );
  }
);

export default EditExerciseMaterialDialog;
