// new-exercise(pot) type categories
export const TYPE_TO_CATEGORIES = {
  IELTS: ["Reading", "Writing", "Listening", "Speaking"],
  GRAMMAR: [],
  // COMPREHENSION: ["Listening", "Reading"],
  LINGUASKILL: ["Reading", "Writing", "Listening", "Speaking"],
};

// old-exercise(ielts) type categories
export const OLD_TYPE_TO_CATEGORIES = {
  GRAMMAR: ["Advanced", "Intermediate", "Basic", "Beginner"],
  LISTENING: ["Reading", "Writing", "Listening", "Speaking"],
  READING: ["Listening", "Reading"],
  SPEAKING: ["Reading", "Writing", "Listening", "Speaking"],
  WRITING: ["Reading", "Writing", "Listening", "Speaking"],
};

export const CATEGORIES = [
  "Advanced",
  "Intermediate",
  "Basic",
  "Beginner",
  "One-to-One",
  "Zero",
  "Starter",
];

export const CATEGORY_TO_ENGLISH_LEVEL = {
  Zero: ["A0"],
  Starter: ["A0+"],
  Beginner: ["A1"],
  Basic: ["A2", "B1"],
  Intermediate: ["B1+", "B2-"],
  Advanced: ["B2", "B2+", "C1"],
};