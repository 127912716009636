import {
  INCodeValues,
  INStatus,
} from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";

export const GENERATE_NUMBER_MONTHS = 12;

export const ROUTE_API_INDEX = "/v1/upselling/crm";
export const ROUTE_API_UPDATE = "/v1/upselling/crm/update";
export const ROUTE_API_TEMPLATE_EMAIL_SEND =
  "/v1/upselling/crm/template/email/send";

export const DEFAULT_EXPIRE_CODE = 2;

export const CHECKOUT_BASE: string = import.meta.env.VITE_CHECKOUT_BASE;
export const BASE_PAYMENT_LINK = CHECKOUT_BASE + "existing-user";

export const STATUS: INStatus = {
  Admin: "Admin",
  NoBooked: "No Booked",
  MailSent: "Mail sent",
  ToBeCalled: "To be called",
  UpsellingScheduled: "Upselling scheduled",
  UpsellingDone: "Upselling done",
  ToBeConverted: "To be converted",
  Reschedule: "Reschedule",
  InterestedInTheFuture: "Interested in the future",
  StandBy: "Stand by",
  OfferRejected: "Offer rejected",
  NoShow: "No show",
  NotInterested: "Not interested",
  Upsold: "Upsold",
};

export const MEETING_STATUS_OPTIONS = [
  { status: "present", color: "#58ff2f" },
  { status: "no reply", color: "#FFD700" },
];

export const CONVERT_STATUS_OPTIONS = [
  { status: "soon buying", color: "#58ff2f" },
  { status: "no reply", color: "#FFD700" },
];

export const defaultCodeValues: INCodeValues = {
  code: "",
  expires_at: DEFAULT_EXPIRE_CODE,
};

export const BASE_PAYMENT_LINK_MEETING =
  "https://academy.edusogno.com/basic_checkout/checkout?price=";
