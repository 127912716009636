import { FC, useState } from "react";
import { Button, styled } from "@mui/material";
import { ModalPropsType } from "../exercisesV3.types";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";

export const EduButtonFooter = styled(Button)(({ theme }) => ({
  "& img": {
    filter: theme.palette.filter,
    marginRight: 5,
  },
}));

export const ExerciseModal: FC<ModalPropsType> = ({
  open,
  icon,
  title,
  onClose,
  children,
}) => {
  const [isMaximized, setIsMaximized] = useState<boolean>(false);

  return (
    <EduModal
      fullWidth
      open={open}
      maxWidth={isMaximized ? false : "xl"}
      fullScreen={isMaximized}
    >
      <EduModalTitle
        onClose={onClose}
        isMaximized={isMaximized}
        setIsMaximized={setIsMaximized}
      >
        {Boolean(icon) && icon}
        {title}
      </EduModalTitle>

      <EduModalBody className="h-full">{children}</EduModalBody>
    </EduModal>
  );
};
