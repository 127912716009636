import { Box, Button, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import DuoIcon from "@mui/icons-material/Duo";
import videoIcon from "@/assets/icons/video.svg";
import nextIcon from "@/assets/icons/next.svg";
import Checkbox from "./CrmCheckbox";
import { Step1Props } from "../components/crm-upselling.types";
import { useCrmContext } from "../state/CrmV3Context";

const Step1: React.FC<Step1Props> = ({ meetingsSorted, setStep }) => {
  const { meetingComponentState } = useCrmContext();

  const { eventId, setEventId } = meetingComponentState;

  if (!Boolean(meetingsSorted?.length)) {
    return <Typography color="secondary">No Events</Typography>;
  }

  return (
    <Box>
      <ul className="listEvents flex flex-col gap-3">
        {meetingsSorted.map((event, i) => {
          const isEnd = new Date() > new Date(event.start);

          return (
            <div
              key={event.id + i}
              className="flex w-full gap-2"
            >
              <li
                onClick={() => setEventId(event.id)}
                className="w-full"
              >
                <Checkbox checked={eventId === event.id} />

                <img
                  src={videoIcon}
                  className="icon"
                />
                {format(parseISO(event.start), "EEE, dd-M H:mm")}

                {isEnd && <span className="ended">Ended</span>}
              </li>

              <Button
                component={"a"}
                href={event.link}
                target="_blank"
                variant="outlined"
                size="small"
              >
                <DuoIcon />
              </Button>
            </div>
          );
        })}
      </ul>

      <Box className="mt-5 text-center">
        {eventId && (
          <Button
            size="small"
            variant="outlined"
            onClick={() => setStep(2)}
          >
            <img
              src={nextIcon}
              className="icon"
            />
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Step1;
