import { useState } from "react";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./components/Toolbar";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadModal from "./components/UploadModal";
import ViewPDFModal from "./components/ViewPDFModal";
import useUserVatApi from "@/api/useUserVatApi";
import usePagination from "@/hooks/usePagination";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CustomPagination from "@/components/_Common/CustomPagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const UsersVat = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler } = useAuth();

  const [modalId, setModalId] = useState<string | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [currentPDF, setCurrentPDF] = useState<string | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const closeModal = () => setModalId(null);
  const openViewModal = () => setModalId("view");
  const openUploadModal = () => setModalId("upload");

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  const viewPDF = (pdfURL: string) => {
    setCurrentPDF(pdfURL.replace("download&confirm", "view"));
    openViewModal();
  };

  const { callGetUsersVat, updateInvoiceStatus, softDelete } = useUserVatApi();

  const sorts = filterData?.sorts;
  delete filterData?.page;
  delete filterData?.sorts;
  const { data, isLoading } = callGetUsersVat({
    page,
    sorts,
    filter: filterData,
    per_page: perPage,
  });

  const userData = data?.data || [];
  const pagination = {
    total: data?.total ? data?.total : 1,
    last_page: data?.last_page ? data?.last_page : 1,
    current_page: data?.current_page ? data?.current_page : 1,
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row.fullName,
      renderCell: ({ row }) =>
        row?.fullName ? <MyTooltip>{row.fullName}</MyTooltip> : "-",
    },
    {
      field: "name",
      headerName: "Company name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row.name,
      renderCell: ({ row }) =>
        row?.name ? <MyTooltip>{row.name}</MyTooltip> : "-",
    },
    {
      field: "paid_for",
      headerName: "Product",
      minWidth: 210,
      flex: 1,
      valueGetter: ({ row }) => row.paid_for,
      renderCell: ({ row }) => <MyTooltip>{row.paid_for}</MyTooltip>,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 0.5,
      valueGetter: ({ row }) => row.amount,
    },
    {
      field: "vat_number",
      headerName: "VAT number",
      minWidth: 110,
    },
    {
      field: "recipient_code",
      headerName: "Recipient code",
      minWidth: 150,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 240,
      flex: 1,
    },
    {
      field: "recipient_pec",
      headerName: "Recipient PEC",
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Created at",
      minWidth: 120,
      flex: 0.5,
      valueGetter: ({ row }) => format(new Date(row?.created_at), "dd-MM-yyyy"),
    },
    {
      field: "updated",
      headerName: "Updated at",
      minWidth: 120,
      flex: 0.5,
      valueGetter: ({ row }) =>
        row?.updated ? format(new Date(row?.updated), "dd-MM-yyyy") : "-",
    },
    {
      field: "invoice_status",
      headerName: "Action",
      // flex: 0.5,
      width: 150,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const isDeleted = params.row.deleted_at;
        const invoiceStatus = String(parseInt(params.row.invoice_status, 10));
        const pdfURL = `${params.row.invoice_path}`;

        if (isDeleted) {
          return "";
        }

        return (
          <Box
            className="flex flex-nowrap"
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: "8px",
              background: theme.palette.background.paper,
            }}
          >
            {pdfURL === "null" ? (
              <Button
                style={{
                  color: "gray",
                }}
                title="Update invoice status"
                onClick={() => {
                  openUploadModal();
                  setSelectedRowId(params.row.id);
                }}
              >
                <CheckCircleOutlineIcon />
              </Button>
            ) : (
              <Button onClick={() => viewPDF(pdfURL)}>
                <PictureAsPdfIcon />
              </Button>
            )}
            {!pdfURL || pdfURL === "null" || pdfURL === "" ? (
              <Button
                onClick={() => {
                  setDeleteId(params.row.id);
                  setShowConfirmModal(true);
                }}
              >
                <DeleteIcon />
              </Button>
            ) : null}
          </Box>
        );
      },
    },
  ];

  return (
    <BasePage title="Value Added Tax">
      <DataGrid
        sx={getTableStyling(theme)}
        rows={userData}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        autoHeight={true}
        getRowClassName={(params) => {
          if (params.row.deleted_at) {
            return "!bg-red-400/30";
          } else if (params.row.payment?.is_refunded === 1) {
            return "!bg-yellow-400/30";
          }
          return "";
        }}
        components={{
          Toolbar: Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            setFilters: setFilters,
            filters: filterData,
          },
          pagination: { page, setPage, pagination },
        }}
        experimentalFeatures={{ newEditingApi: false }}
        disableColumnMenu
        disableSelectionOnClick
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        sortingMode="server"
      />

      <UploadModal
        open={modalId === "upload"}
        onClose={closeModal}
        updateInvoiceStatus={updateInvoiceStatus}
        snackHandler={snackHandler}
        selectedRowId={selectedRowId}
        closeModal={closeModal}
      />

      <ViewPDFModal
        open={modalId === "view"}
        onClose={closeModal}
        pdfSrc={currentPDF}
      />

      <ConfirmationDialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={async () => {
          try {
            await softDelete(deleteId);
            snackHandler("User request successfully removed!");
            setShowConfirmModal(false);
            setDeleteId(null);
          } catch (error) {
            console.error("Error", error);
            snackHandler("Error", "error");
          }
        }}
        title="Conferma eliminazione"
        textButtonAgree="Conferma"
        textButtonDisagree="Annulla"
      >
        Sei sicuro di voler eliminare questa richiesta?
      </ConfirmationDialog>
    </BasePage>
  );
};

export default UsersVat;
