import { ChangeEvent, FC, useCallback } from "react";
import { STATUS } from "@/pages/Upselling/v2/Crm/helpers/constants";
import useAuth from "@/hooks/useAuth";
import { useCrmContext } from "../../state/CrmV3Context";
import { handleGenerateEmail } from "@/pages/Upselling/v2/Crm/helpers/send-mail";
import { useCrmDialogsContext } from "../../state/CrmDialogsContext";
import { EmailTemplateProps, INCodeValues } from "../crm-upselling.types";
import { Box, Button, MenuItem, TextField, Tooltip } from "@mui/material";
import MONTHS from "@/components/assets/MONTHS";
import { red } from "@mui/material/colors";

const EmailTemplate: FC<EmailTemplateProps> = ({
  dataAPI,
  products,
  codeValues,
  setCodeValues,
  rowSelected,
  newStartingMonths,
  DEFAULT_EXPIRE_CODE,
}) => {
  const { queryResult, buttonCupState, rowSelectedState } = useCrmContext();

  // Dialog context
  const { dialogsElState } = useCrmDialogsContext();

  const { userAdmin, snackHandler } = useAuth();

  const handleCodeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCodeValues((prev: INCodeValues) => ({
        ...prev,
        code: e.target.value,
      }));
    },
    [setCodeValues]
  );

  const onGenerateEmail = (e) =>
    handleGenerateEmail(
      e,
      rowSelectedState.rowSelected,
      codeValues,
      buttonCupState.setButtonCup,
      snackHandler,
      queryResult.data,
      dialogsElState.setDialogsEl
    );

  const handleExpiresChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const days = parseInt(
        e.target.value.match(/\d+/g)?.join("") || String(DEFAULT_EXPIRE_CODE)
      );
      setCodeValues((prev: INCodeValues) => ({
        ...prev,
        expires_at: days,
      }));
    },
    [DEFAULT_EXPIRE_CODE, setCodeValues]
  );

  const handleMonthChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCodeValues((prev: INCodeValues) => ({
        ...prev,
        newStartingMonth: e.target.value,
      }));
    },
    [setCodeValues]
  );

  const handleProductChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCodeValues((prev: INCodeValues) => ({
        ...prev,
        product: e.target.value,
      }));
    },
    [setCodeValues]
  );

  const isStandBy = rowSelected?.crm_upselling?.status === STATUS.StandBy;
  const lastMeeting =
    rowSelected?.crm_upselling?.meetings?.meet?.slice(-1)?.[0];

  const calculateDiscountPercentage = (value: string): string => {
    if (value.endsWith("%")) {
      return value;
    }

    const lastProduct =
      rowSelected?.crm_upselling?.meetings?.meet.slice(-1)?.[0]?.pacchetto;

    const price =
      products?.products?.find((product) => product.token === lastProduct)
        ?.price / 100 || 0;

    const fixedAmount = parseFloat(value.replace(/[^\d.]/g, ""));
    if (!isNaN(fixedAmount) && price > 0) {
      const percentage = (fixedAmount / price) * 100;
      return `${percentage.toFixed(2)}%`;
    }

    return "0%";
  };

  return (
    <Box
      onSubmit={onGenerateEmail}
      component="form"
      className="bg-edu-800 flex flex-col gap-4 p-3 w-72"
    >
      <TextField
        select
        fullWidth
        name="code"
        label="Discount code"
        value={codeValues?.code ?? ""}
        onChange={handleCodeChange}
      >
        <MenuItem value="">- Remove -</MenuItem>
        {dataAPI?.codes?.map(
          (c: { id: number; code: string; value: string }) => (
            <MenuItem
              key={c.id}
              value={c.id}
            >
              <Box className="w-full flex justify-between gap-2">
                <Box>{c.code}</Box>
                <Box>-{c.value}</Box>
                <Box className="text-red-500 text-sm">
                  -{calculateDiscountPercentage(c.value)}
                </Box>
              </Box>
            </MenuItem>
          )
        )}
      </TextField>

      <TextField
        required
        fullWidth
        type="number"
        name="expires_at"
        label="Expires in day/s"
        value={codeValues?.expires_at ?? DEFAULT_EXPIRE_CODE}
        onChange={handleExpiresChange}
      />

      {isStandBy && (
        <>
          <TextField
            select
            required
            fullWidth
            name="newStartingMonth"
            label="New starting month"
            value={codeValues?.newStartingMonth ?? ""}
            onChange={handleMonthChange}
          >
            {newStartingMonths?.map((month: string) => {
              const [monthName, year] = month.split(" ");
              const monthValue = `${MONTHS[monthName]} ${year}`;
              return (
                <MenuItem
                  key={month}
                  value={monthValue}
                >
                  {month}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            select
            fullWidth
            name="product"
            label="Product"
            value={lastMeeting?.pacchetto ?? codeValues?.product ?? ""}
            onChange={handleProductChange}
          >
            <MenuItem value="">- Remove -</MenuItem>
            {products?.products?.map((product: any) => (
              <MenuItem
                key={product.id}
                value={product.token}
              >
                <Tooltip title={product.product_tag}>
                  <Box className="w-full flex justify-between">
                    <Box className="truncate mr-2">{product.product_tag}</Box>
                    <Box>€{(product?.price / 100).toFixed(2)}</Box>
                  </Box>
                </Tooltip>
              </MenuItem>
            ))}
          </TextField>
        </>
      )}

      <Button
        fullWidth
        type="submit"
        variant="contained"
      >
        Generate email
      </Button>
    </Box>
  );
};

export default EmailTemplate;
