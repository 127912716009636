import Request from "./RequestAdmin";

export const getListDayOffs = async (token, params) => {
  const { data } = await Request.call(
    {
      url: "/v1/user-days-off",
      method: "GET",
      params,
    },
    token
  );

  return data;
};

export const postUpdateStatusDayOffs = async (token, id, status) => {
  const { data } = await Request.call(
    {
      url: `/v1/user-days-off/${id}/update-status`,
      method: "POST",
      data: {
        status,
      },
    },
    token
  );

  return data;
};

export const postCreateDayOff = async (token, postData) => {
  const { data } = await Request.call(
    {
      url: "/v1/user-days-off",
      method: "POST",
      data: postData,
    },
    token
  );

  return data;
};

export const getListAdminHaveDayOffs = async (token) => {
  const { data } = await Request.call(
    {
      url: "/v1/user-days-off/list-admin",
      method: "GET",
    },
    token
  );

  return data;
};
