import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export const clone = <T extends Array<any> | object>(v: T): T =>
  JSON.parse(JSON.stringify(v));

export const isEmptyObject = (v: object): boolean =>
  Object.keys(v).length === 0;

export const filterObject = (v: object, exclude: Array<any>): any =>
  Object.keys(v).reduce((r, k) => {
    if (["string", "number", "boolean"].includes(typeof v[k])) {
      if (!exclude.includes(v[k])) {
        r[k] = v[k];
      }
    } else {
      if (!exclude.includes(jS(v[k]))) {
        r[k] = v[k];
      }
    }

    return r;
  }, {});

export const objectInvertKeyValue = (obj: any): any => {
  const invertedObj = {};
  for (const key in obj) {
    const value = obj[key];
    invertedObj[value] = key;
  }
  return invertedObj;
};

export const isObject = (v: any): boolean =>
  typeof v === "object" && !Array.isArray(v) && v !== null;

export const fUpper = (v: string): string =>
  v ? v.charAt(0).toUpperCase() + v.slice(1).toLowerCase() : v;

export const fUpperWords = (v: string): string => {
  return v
    .split(" ")
    .map((l: string) => l.charAt(0).toUpperCase() + l.slice(1))
    .join(" ");
};

export const hasStr = (v1: string, v2: string): boolean =>
  v1.toLowerCase().includes(v2.toLowerCase());

export const invertValuesObject = (obj: object): object => {
  const ob = {};
  for (const o in obj) {
    if (typeof obj[o] !== "string") {
      throw Error(`The value of ${o} is not string`);
    }
    ob[obj[o]] = o;
  }
  return ob;
};

export const jS = (v: Array<any> | object): string => JSON.stringify(v);

export const jP = (v: string): any => JSON.parse(v);

export const convert = (v: any) => (typeof v === "string" ? jP(v) : v);
export const convertToArray = (v: any) =>
  typeof v === "string" ? new Map([...jP(v)]) : v;

export const convertToQueryUrl = (params: any): string => {
  const toQueryString = (params: any, prefix = "") =>
    Object.keys(params)
      .map((key) => {
        const value = params[key];
        const prefixedKey = prefix ? `${prefix}[${key}]` : key;

        if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null
        ) {
          return toQueryString(value, prefixedKey);
        } else if (Array.isArray(value)) {
          return `${prefixedKey}=${value
            .map((val) => encodeURIComponent(val).replaceAll("%20", "+"))
            .join(",")}`;
        } else {
          return `${prefixedKey}=${encodeURIComponent(value).replaceAll(
            "%20",
            "+"
          )}`;
        }
      })
      .join("&");

  return "?" + toQueryString(params);
};

// objetc prototype to turn into array

declare global {
  interface Object {
    toArray(): any[];
  }
}

export const toArray = (object) => {
  return Object.keys(object).map((key) => object[key]);
};

export const onlyNumber = (v: string): number =>
  Number(v.replace(/[^0-9]/g, "") || 0);

export const formatPrice = (
  v: string,
  decimal: number = 2,
  separator: string = "."
): string => {
  let val: string | Array<string> = String(v).replace(
    new RegExp(`[^0-9${separator}]`, "g"),
    ""
  );

  if (separator && val.includes(separator)) {
    const s = val.split(separator);
    val = s[0] + separator + s[1].slice(0, decimal);
  }

  return val;
};

type AgainThisTipe = Array<AgainThisTipe | string | number | boolean>;

export const arrayFlat = (
  v: Array<AgainThisTipe | string | number | boolean>
): Array<string | number | boolean> => {
  let unique: Array<string | number | boolean> = [];

  v.forEach((item) => {
    if (Array.isArray(item)) {
      unique.push(...arrayFlat(item));
    } else if (
      typeof item === "string" ||
      typeof item === "number" ||
      typeof item === "boolean"
    ) {
      unique.push(item);
    }
  });

  unique = [...new Set(unique)];

  return unique;
};

interface IReturnValue {
  end: string;
  start: string;
  month: number;
  index: number;
}

export const splitDateRangeIntoMonths = (
  startDate: string,
  endDate: string,
  getInBetween: string | boolean = false
): Array<{
  end: string;
  start: string;
  month: number;
  index: number;
}> => {
  const monthsArray: Array<IReturnValue> = [];

  let index: number = 0;
  let currentDate: any = dayjs(startDate);

  const m = (v: any): number => (typeof v === "string" ? dayjs(v) : v).month();
  const f = (v: any): string =>
    (typeof v === "string" ? dayjs(v) : v).format("YYYY-MM-DD HH:mm:ss");

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
    const start =
      m(currentDate) === m(startDate)
        ? f(startDate)
        : f(currentDate.startOf("month"));
    const end =
      m(currentDate) === m(endDate)
        ? f(endDate)
        : f(currentDate.endOf("month"));

    const values: IReturnValue = {
      start,
      end,
      month: m(currentDate) + 1,
      index,
    };

    if (
      getInBetween &&
      (getInBetween === true ? dayjs() : dayjs(getInBetween)).isBetween(
        start,
        end
      )
    ) {
      return [values];
    }

    monthsArray.push(values);

    index++;
    currentDate = currentDate.add(1, "month");
  }

  return monthsArray;
};

export const euro = (v: string | number): string =>
  String(
    new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(Number(v || 0))
  );

export const include = (v1: string, v2: string) =>
  v1.toLowerCase().includes(v2.toLowerCase());

export const mergeByKey = (arr1: Array<any>, arr2: Array<any>, key: string) =>
  arr2.map((item2: any) => {
    const item1 = arr1.find((item1: any) => item1[key] === item2[key]);
    return { ...item1, ...item2 };
  });

export const excludedByKey = (
  arr1: Array<any>,
  arr2: Array<any>,
  key: string
) =>
  arr1.filter(
    (item1: any) =>
      arr2.map((item2: any) => item2[key]).indexOf(item1[key]) === -1
  );

export const groupBy = (list: Array<any>, keyGetter: any) => {
  const map = new Map();

  (list || []).forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const generateCasualCharacters = (length: number) => {
  const characters =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  let word = "";

  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * characters.length);
    word += characters.charAt(index);
  }

  return word;
};

export const getAndRemove = <T extends keyof D, D extends Record<string, any>>(
  dependence: D,
  keys: Array<T>
): Pick<D, T> => {
  const f = {} as Pick<D, T>;

  keys.forEach((key) => {
    f[key] = dependence?.[key];
    delete dependence?.[key];
  });

  return f;
};
