import useAuth from '@/hooks/useAuth';
import { Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { CrmMeetingEditVideoCallInfoProps } from "../components/crm-upselling.types";
import Step1 from "./MeetingVideoStepOne";
import Step2 from "./MeetingVideoStepTwo";
import Step3 from "./MeetingVideoStepThree";
import { useCrmContext } from "../state/CrmV3Context";

interface CheckboxProps {
  checked: boolean;
  theme: any;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, theme }) => (
  <Box
    style={{
      width: "21px",
      height: "21px",
      borderRadius: "50%",
      border: `1px solid ${theme.palette.primary.main} `,
      marginRight: 15,
    }}
    className="flex items-center justify-center"
  >
    {checked && (
      <Box
        style={{
          width: "13px",
          height: "13px",
          borderRadius: "50%",
          background: theme.palette.primary.main,
        }}
      />
    )}
  </Box>
);

const CrmMeetingEditVideoCallInfo: React.FC<
  CrmMeetingEditVideoCallInfoProps
> = ({
  data,
  products,
  functions,
  isUpselling,
  dataGetToogle,
  selectedEvent,
  meetingsSorted,
  newStartingMonths,
  keysFormMeetingDone,
  handleSetFormMeetingDone,
}) => {
    const { isAdmission, isAdmin } = useAuth();
    const { meetingComponentState } = useCrmContext();
    const { eventId, setStatus } = meetingComponentState;

    const event = meetingsSorted.find((m: any) => m.id === eventId);
    const otherEvent = !Boolean(meetingsSorted?.length) && isAdmin;

    const [step, setStep] = useState<number>(1);

    return (
      <Box className="flex flex-col w-full">
        <Typography
          color="primary"
          fontWeight="bold"
        >
          {otherEvent
            ? "Other event"
            : eventId && step > 1
              ? format(parseISO(event.start), "EEE, dd-M H:mm")
              : "Select an event"}
        </Typography>

        <SelectEvent>
          {Boolean(meetingsSorted?.length) && step === 1 && (
            <Step1 {...{ meetingsSorted, setStep }} />
          )}

          {Boolean(meetingsSorted?.length) && step === 2 && (
            <Step2
              {...{
                data,
                setStep,
                functions,
                setStatus,
                isUpselling,
              }}
            />
          )}

          {(otherEvent || (Boolean(meetingsSorted?.length) && step === 3)) && (
            <Step3
              {...{
                data,
                setStep,
                products,
                functions,
                otherEvent,
                isAdmission,
                dataGetToogle,
                selectedEvent,
                newStartingMonths,
                keysFormMeetingDone,
                handleSetFormMeetingDone,
              }}
            />
          )}
        </SelectEvent>
      </Box>
    );
  };

const SelectEvent = styled("div")(({ theme }) => ({
  marginTop: 10,
  padding: 15,
  width: "100%",
  minHeight: "250px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.background.paper,
  "ul.listEvents": {
    minHeight: "200px",
    li: {
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      padding: 10,
      cursor: "pointer",
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.leftSideBarSub.main,
      ".icon": {
        filter: theme.palette.filter,
        marginRight: 15,
      },
      ".ended": {
        borderRadius: "3px",
        background: "#FF8C8C",
        color: "#8D0303",
        fontSize: "8px",
        height: "15px",
        lineHeight: "15px",
        padding: "0px 10px",
        marginLeft: "auto",
      },
      ".boh": {
        borderRadius: "3px",
        background: "#8c9aff",
        color: "#8D0303",
        fontSize: "8px",
        height: "15px",
        lineHeight: "15px",
        padding: "0px 10px",
        marginLeft: "auto",
      },
    },
    "li + li": {
      marginTop: 10,
    },
  },
}));

export default CrmMeetingEditVideoCallInfo;
