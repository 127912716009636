import { useState, forwardRef, useImperativeHandle } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import previewIcon from "@/assets/icons/preview.svg";

import { IFormQuestion } from "../FormQuestion/QuestionInterface";

export interface IForwardRef {
  open: (data: IFormQuestion) => void;
}

interface IProps {
  ref: HTMLButtonElement;
}

const PreviewFormDialog = forwardRef<IForwardRef, IProps>((_, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormQuestion | null>(null);
  const [step, setStep] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setFormData(data);
      setStep(0);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setStep(0);
  };

  const currentForm = formData?.content?.[step];

  const type = currentForm?.elements?.[0]?.type || "textbox";

  const renderElements = () => {
    if (type === "check") {
      return currentForm?.elements.map((e) => {
        return (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label={e.label}
            />
          </FormGroup>
        );
      });
    }

    if (type === "radio") {
      return (
        <>
          <FormControl>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {currentForm?.elements.map((e) => {
                return (
                  <FormControlLabel
                    value={e.label}
                    control={<Radio />}
                    label={e.label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </>
      );
    }

    return currentForm?.elements.map((e) => {
      return <TextField placeholder={e.label || "Type here..."} />;
    });
  };

  return (
    <EduModal
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={previewIcon}
          className="icon"
        />
        Preview Form
      </EduModalTitle>

      <EduModalBody className="p-5 flex flex-col gap-5">
        <Container maxWidth="sm">
          <Typography
            sx={{ textAlign: "center" }}
            variant="h6"
            color="primary"
          >
            {formData?.first_name}
          </Typography>

          <Typography
            sx={{ textAlign: "center" }}
            variant="subtitle1"
            color="primary"
          >
            {currentForm?.question}
          </Typography>

          <Box className="flex flex-col gap-4 mt-4 mb-4">
            {renderElements()}
          </Box>

          <div style={{ textAlign: "center" }}>
            {step + 1 === formData?.content?.length ? (
              <Button
                variant="outlined"
                onClick={() => handleClose()}
              >
                Complete
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => setStep(step + 1)}
              >
                Next
              </Button>
            )}
          </div>
        </Container>
      </EduModalBody>
    </EduModal>
  );
});

export default PreviewFormDialog;
