import { fUpper } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { Typography, useTheme } from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  VideocamRounded,
  Groups2RoundedIcon,
  DoneAllRoundedIcon,
  PersonAddRoundedIcon,
  ChatBubbleRoundedIcon,
  VideocamOffRoundedIcon,
  PhoneInTalkRoundedIcon,
  DoDisturbAltRoundedIcon,
  QuestionMarkRoundedIcon,
  PhonelinkEraseRoundedIcon,
  AutorenewIcon,
  EventBusyIcon,
  EventAvailableIcon,
  SendIcon,
  PersonOutlineIcon,
} from "@/components";
import cn from "classnames";
import dayjs from "dayjs";
import useCrmApi from "@/api/useCrmApi";
import useProductApi from "@/api/useProductApi";

const genIcon = (key: string) => {
  switch (key) {
    case "Admin":
      return <PersonOutlineIcon color="primary" />;

    case "Offer rejected":
    case "Not interested":
      return <DoDisturbAltRoundedIcon color="primary" />;

    case "Upselling scheduled":
    case "Reschedule":
      return <Groups2RoundedIcon color="primary" />;

    case "Mail Sent":
      return <SendIcon color="primary" />;

    case "Interested in future":
      return <EventAvailableIcon color="primary" />;

    case "No booked":
      return <EventBusyIcon color="primary" />;

    case "To be converted":
      return <AutorenewIcon color="primary" />;

    case "Upselling done":
    case "Upsold":
      return <DoneAllRoundedIcon color="primary" />;

    case "No show":
      return <PhoneInTalkRoundedIcon color="primary" />;

    default:
      return <QuestionMarkRoundedIcon color="primary" />;
  }
};

interface IProps {
  data: any;
  title?: string;
  isMeet?: boolean;
}

const TimelineGen = ({ data, isMeet = false, title = "" }: IProps) => {
  const theme = useTheme();
  const { snackHandler, isAdmin } = useAuth();
  const { data: products } = useProductApi().callGetProducts();
  const { arrayLabelOptions, options } = useCrmApi();

  return (
    <>
      {Boolean(title) && (
        <Typography
          color="primary"
          className="text-center"
        >
          {title}
        </Typography>
      )}

      <Timeline position="alternate">
        {data.map((item: any, i: number) => {
          const date = isMeet ? item?.start : item?.date;

          return (
            <TimelineItem
              sx={{ ".MuiTimelineDot-filled": { m: 0, p: 1 } }}
              key={i}
            >
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="left"
                title={"Date ITA: " + dayjs(date).format("YYYY-MM-DD HH:mm:ss")}
                variant="body2"
                className="w-[160px] truncate m-auto"
              >
                {isMeet && "Start: "}
                {new Date(date)
                  .toLocaleString("en-EN", options)
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}
                <br />
                {Boolean(item?.end) && (
                  <>
                    End:{" "}
                    {new Date(item?.end)
                      .toLocaleString("en-EN", options)
                      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                      )}
                  </>
                )}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector />

                <TimelineDot
                  sx={{
                    border: 2,
                    borderColor: theme.palette.primary.main,
                    backgroundColor: "transparent",
                  }}
                >
                  {isMeet ? (
                    <VideocamRounded color="primary" />
                  ) : (
                    genIcon(item.status)
                  )}
                </TimelineDot>

                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent className="w-[160px] !m-auto">
                {isMeet ? (
                  [
                    "link",
                    "calendar",
                    "pacchetto",
                    "motivation",
                    "certification",
                    "english_lvl",
                    "payment_link",
                    "notes",
                    "interviewer",
                  ].map((k) => {
                    if (k == "pacchetto") {
                      const product = products?.products?.find(
                        (p: { token: string }) => p.token === item?.[k]
                      );

                      if (product) {
                        return (
                          <Typography
                            key={k}
                            title={"Product: " + product.product_tag}
                            className="w-full truncate !text-left"
                          >
                            Product:{" "}
                            <span
                              onClick={() =>
                                isAdmin ? snackHandler(product.token) : null
                              }
                              className={cn({ "cursor-pointer": isAdmin })}
                            >
                              {product.product_tag}
                            </span>
                          </Typography>
                        );
                      }

                      return null;
                    }

                    const fK = fUpper(k);
                    const isLink = ["link", "payment_link"].includes(k);
                    const isPerson = ["calendar", "interviewer"].includes(k);

                    return Boolean(item?.[k]) ? (
                      <Typography
                        key={k}
                        title={fK + ": " + item?.[k]}
                        color={isPerson ? "primary" : ""}
                        className="w-full truncate !text-left"
                      >
                        {fK}:{" "}
                        <span
                          className={cn({
                            "cursor-pointer hover:underline": isLink,
                          })}
                          onClick={() =>
                            isLink ? snackHandler(item?.[k]) : null
                          }
                        >
                          {item?.[k]}
                        </span>
                      </Typography>
                    ) : null;
                  })
                ) : (
                  <>
                    {Boolean(item?.status) && (
                      <Typography
                        title={"Status: " + item.status}
                        className="w-full truncate"
                      >
                        {arrayLabelOptions[item.status] || item.status}
                      </Typography>
                    )}

                    {Boolean(item?.admin) && (
                      <Typography
                        color="primary"
                        title={"Admin: " + item.admin}
                        className="w-full truncate"
                      >
                        {item?.admin}
                      </Typography>
                    )}

                    {Boolean(item?.action) && (
                      <Typography
                        title={"Action: " + item.action}
                        className="w-full truncate"
                      >
                        {item.action}
                      </Typography>
                    )}
                  </>
                )}
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </>
  );
};

export default TimelineGen;
