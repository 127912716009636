import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { format, parseISO } from "date-fns";
import { isObject, fUpper } from "@/components/useHelpers";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  LinearProgress,
  Rating,
  Tooltip,
  useTheme,
} from "@mui/material";
import Title from "@/components/_Common/Page/Title";
import useAPI from "@/api/useAPI";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import CircleIcon from "@mui/icons-material/Circle";
import ArchiveIcon from "@mui/icons-material/Archive";
import usePagination from "@/hooks/usePagination";
import useDataGridSort from "@/hooks/useDataGridSort";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import EventFeedbackToolbar from "@/pages/EventFeedback/EventFeedbackToolbar";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const ROUTE_HTTP_BASE = "/v1/feedbacks";
const ROUTE_HTTP_ARCHIVE = ROUTE_HTTP_BASE + "/archive";

interface INOpenConfirmArchive {
  open: boolean;
  id: number | null;
}

const ArchivedFeedbacks = () => {
  const { screen, perPage } = useScreen();
  const theme = useTheme();

  const KEY_QUERY = ["multi", "feedbacks", "archived"];
  const { data: dataAPI, isLoading } = useQuery(KEY_QUERY, () =>
    useAPI(ROUTE_HTTP_BASE, { type: "archive" })
  );

  const [openConfirmArchive, setOpenConfirmArchive] =
    useState<INOpenConfirmArchive>({ open: false, id: null });

  const [filters, setFilters] = useSearchParams();
  const { page, setPage } = usePagination({ setFilters, filters });
  const filterData = Object.fromEntries(filters.entries());
  const { onSortModelChange, sortModel } = useDataGridSort({
    setFilters,
    filters,
  });

  const handleOpenPopupArchive = (id: number) => {
    setOpenConfirmArchive({ open: true, id });
  };

  const handleArchive = async () => {
    const id = openConfirmArchive.id;

    await useAPI(ROUTE_HTTP_ARCHIVE, { id });
    queryClient.invalidateQueries(KEY_QUERY);
    setOpenConfirmArchive({ open: false, id: null });
  };

  const filteredData = useMemo(() => {
    let start: any, end: any;

    if (filterData?.dates) {
      const rangeDate = filterData.dates.split(",");
      start = new Date(rangeDate[0]);
      end = new Date(rangeDate[1]);
    }

    return dataAPI?.filter(({ event }) => {
      if (!start || !end) return true;
      return start <= new Date(event?.data) && new Date(event?.data) <= end;
    });
  }, [filterData, dataAPI]);

  // COLUMNS ===================================================================
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "created_at",
        hide: true,
        hideable: false,
      },
      {
        field: "student",
        headerName: "User",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) =>
          `${row?.user?.first_name} ${row?.user?.last_name}` || "-",
        renderCell: ({ row }) =>
          row?.user?.first_name && row?.user?.last_name ? (
            `${row?.user?.first_name} ${row?.user?.last_name}`.length > 18 ? (
              <MyTooltip>{`${row?.user?.first_name} ${row?.user?.last_name}`}</MyTooltip>
            ) : (
              `${row?.user?.first_name} ${row?.user?.last_name}`
            )
          ) : (
            "-"
          ),
      },
      {
        field: "name_tutor",
        headerName: "Tutor",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) =>
          `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`.trim() ||
          "-",
        renderCell: ({ row }) =>
          row?.event?.tutors?.first_name && row?.event?.tutors?.last_name ? (
            `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`
              .length > 18 ? (
              <MyTooltip>{`${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`}</MyTooltip>
            ) : (
              `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`
            )
          ) : (
            "-"
          ),
      },
      {
        field: "type",
        headerName: "Lesson type",
        width: 150,
        valueGetter: ({ row }) => row?.event?.type ?? "-",
      },
      {
        field: "titolo",
        headerName: "Topic",
        minWidth: 260,
        flex: 1,
        renderCell: ({ row }) =>
          row?.event?.descrizione_argomento ? (
            <MyTooltip>{row?.event?.descrizione_argomento}</MyTooltip>
          ) : (
            "-"
          ),
        valueGetter: ({ row }) => row?.event?.descrizione_argomento,
      },
      {
        field: "level",
        headerName: "Level",
        width: 150,
        valueGetter: ({ row }) => row?.event?.livello ?? "-",
      },
      {
        field: "date",
        headerName: "Date & Time",
        width: 150,
        valueGetter: ({ row }) =>
          row?.event?.data
            ? format(parseISO(row?.event?.data), "EEE, dd-M") +
              " " +
              row?.event?.ora
            : "-",
      },
      {
        field: "rating_tutor",
        headerName: "Rating TUTOR",
        width: 180,
        valueGetter: ({ row }) => row.rating_tutor,
        renderCell: ({ row }) => {
          const rat = Number(row?.rating_tutor);

          if (!rat) return "-";

          return (
            <Box className="flex items-center gap-2">
              <Box>{rat.toFixed(2)}</Box>
              <Rating
                style={{ color: "#faaf00" }}
                defaultValue={rat}
                value={rat}
                precision={0.1}
                readOnly
              />
            </Box>
          );
        },
      },
      {
        field: "rating_topic",
        headerName: "Rating Topic",
        width: 180,
        valueGetter: ({ row }) => row.rating_topic,
        renderCell: ({ row }) => {
          const rat = Number(row?.rating_topic);

          if (!rat) return "-";

          return (
            <Box className="flex items-center gap-2">
              <Box>{rat.toFixed(2)}</Box>
              <Rating
                style={{ color: "#faaf00" }}
                defaultValue={rat}
                value={rat}
                precision={0.1}
                readOnly
              />
            </Box>
          );
        },
      },
      {
        field: "first_feedback",
        headerName: "Comment",
        minWidth: 200,
        flex: 1,
        renderCell: ({ row }) =>
          row?.first_feedback ? (
            <MyTooltip>{fUpper(row?.first_feedback || "")}</MyTooltip>
          ) : (
            "-"
          ),
      },
      {
        field: "improvements",
        headerName: "Improvements",
        width: 130,
        valueGetter: ({ row }) => {
          let improv = JSON.parse(row?.improvements || "[]");
          if (typeof improv === "string") improv = JSON.parse(improv);
          const improvType = Array.isArray(improv)
            ? improv
            : isObject(improv)
            ? Object.keys(improv)
            : [];
          return (
            "improvements =i" +
            (improvType.length && row?.last_feedback
              ? improvType.length + 1
              : improvType.length
              ? improvType.length
              : row?.last_feedback
              ? 1
              : "-")
          );
        },
        renderCell: ({ row }) => {
          let improv = JSON.parse(row?.improvements || "[]");
          if (typeof improv === "string") improv = JSON.parse(improv);
          const improvType = Array.isArray(improv)
            ? improv
            : isObject(improv)
            ? Object.keys(improv)
            : [];

          return improvType.length ? (
            <Tooltip
              placement="bottom-start"
              arrow
              title={
                <Box>
                  {improvType.map((e: string, i: number) => {
                    const v = Array.isArray(improv)
                      ? e
                      : isObject(improv)
                      ? improv
                      : "";

                    const isString = typeof v === "string";

                    const style =
                      i < improvType.length - 1
                        ? {
                            borderBottom: "1px solid",
                            paddingTop: i !== 0 ? "0.4rem" : "",
                          }
                        : i !== 0
                        ? { paddingTop: "0.4rem" }
                        : {};

                    return (
                      <Box key={isString ? v : e}>
                        <Box
                          className="text-base"
                          style={style}
                        >
                          <CircleIcon
                            style={{
                              fontSize: "0.5rem",
                              marginRight: "0.2rem",
                            }}
                          />{" "}
                          {isString ? fUpper(v) : fUpper(e)}
                          {!isString && (
                            <Box className="pl-4 w-full">
                              {v[e]?.map((r: string) => (
                                <Box
                                  key={r + "child"}
                                  sx={{ width: "100%", wordWrap: "break-word" }}
                                >
                                  - {fUpper(r)}
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>

                        {row?.last_feedback && (
                          <Box
                            className="text-base border-t"
                            style={style}
                          >
                            <CircleIcon
                              style={{
                                fontSize: "0.5rem",
                                marginRight: "0.2rem",
                              }}
                            />{" "}
                            Old version
                            <Box className="pl-4 w-full">
                              <Box
                                sx={{ width: "100%", wordWrap: "break-word" }}
                              >
                                - {fUpper(row?.last_feedback || "")}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              }
            >
              <Box
                className="truncate"
                style={{ width: "100%" }}
              >
                {improvType?.length && row?.last_feedback
                  ? improvType?.length + 1
                  : improvType?.length
                  ? improvType?.length
                  : row?.last_feedback
                  ? 1
                  : "-"}
              </Box>
            </Tooltip>
          ) : (
            "-"
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 100,
        flex: 0.5,
        renderCell: ({ row }) => (
          <Button onClick={() => handleOpenPopupArchive(row.id)}>
            <ArchiveIcon />
          </Button>
        ),
      },
    ],
    [screen, dataAPI]
  );

  const tempDates = filterData?.dates?.split(",");
  const dates =
    tempDates?.length > 0
      ? [new Date(tempDates[0]), new Date(tempDates[1])]
      : null;

  if (isLoading) return <LinearProgress />;

  return (
    <Box className="p-8 pb-0">
      <Title>Feedbacks To archive</Title>
      <DataGrid
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={filteredData}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        autoHeight={true}
        initialState={{
          sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            setFilters: setFilters,
            filterData: filterData,
          },
        }}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        columnVisibilityModel={{ created_at: false }}
        components={{
          Toolbar: EventFeedbackToolbar,
          Pagination: CustomPaginationClient,
        }}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        page={parseInt(page.toString()) - 1}
        onPageChange={(newPage) => {
          setPage(newPage + 1);
        }}
      />

      {openConfirmArchive.open ? (
        <ConfirmationDialog
          open={openConfirmArchive.open}
          onClose={() => setOpenConfirmArchive({ open: false, id: null })}
          onConfirm={handleArchive}
        >
          Do you want to{" "}
          <span style={{ color: theme.palette.primary.main }}>archive</span>{" "}
          this feedback?
        </ConfirmationDialog>
      ) : null}
    </Box>
  );
};

export default ArchivedFeedbacks;
