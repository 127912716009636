import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import useAuth from '@/hooks/useAuth';
import { useContextCrm } from "../Context";
import { Button, TextField } from "@mui/material";
import apis from "../apis";

const EditPhone = () => {
  const context = useContextCrm();
  const { update } = apis(context);
  const { snackHandler } = useAuth();

  const refTextField = useRef<HTMLInputElement>();

  const [phone, setPhone] = useState<string>(context.popover?.row.phone ?? "");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    update({ phone }, (res) => {
      if (res?.status == false) {
        snackHandler(res?.message ?? "Failed edit phone number!", "error");
        return;
      }

      snackHandler("Success edit phone number!");
      context.getInvalidateQuery();
    });

    context.setPopover(undefined);
    context.setRowSelected(undefined);
  };

  useEffect(() => {
    refTextField.current?.focus();
  });

  return (
    <Box
      onSubmit={handleSubmit}
      component="form"
      className="flex flex-col gap-2 p-2 rounded min-w-11"
    >
      <TextField
        required
        fullWidth
        value={phone ?? ""}
        inputRef={refTextField}
        onChange={(e) => setPhone(e.target.value)}
      />

      <Button
        fullWidth
        type="submit"
        variant="contained"
      >
        SAVE
      </Button>
    </Box>
  );
};

export default EditPhone;
