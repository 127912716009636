import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useUserApi from "@/api/useUserApi";
import feedbackIcon from "@/assets/icons/presence.svg";
import UserAttendance from "./UserAttendance";

export interface IForwardRef {
  open: (token: string) => void;
}

interface IProps {}

const PresenceUserDialog = forwardRef<IForwardRef, IProps>((_, ref) => {
  const [user, setUser] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  const { data, isLoading } = useUserApi().callGetUserWithDates(token);

  useImperativeHandle(ref, () => ({
    open: (value) => {
      setOpen(true);
      setToken(value);
      setUser({});
    },
  }));

  useEffect(() => {
    if (!data?.user) return;
    setUser(data?.user);
    return () => {
      setUser({});
    };
  }, [data?.user, open, isLoading]);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
    >
      <EduModalTitle onClose={() => setOpen(false)}>
        <img src={feedbackIcon} />
        Reimbursement eligibility - presence
      </EduModalTitle>

      <EduModalBody className="flex flex-col p-5">
        <UserAttendance
          user={user}
          isLoading={isLoading}
        />
      </EduModalBody>
    </EduModal>
  );
});

export default PresenceUserDialog;
