import { forwardRef, useImperativeHandle, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import PaymentIcon from "@/assets/icons/payment.svg";

interface IProps {
  ref: HTMLButtonElement;
}

export interface IViewExerciseForwardRef {
  open: (data: { name: string; url: string }) => void;
}

const ViewPdfExerciseDialog = forwardRef<IViewExerciseForwardRef, IProps>(
  (_props, ref) => {
    const [show, setShow] = useState(false as boolean);
    const [exercise, setExercise] = useState<any>(null);

    useImperativeHandle(ref, () => ({
      open: (data) => {
        setExercise(data);
        setShow(true);
      },
    }));

    const onClose = () => {
      setShow(false);
      setExercise(null);
    };

    return (
      <EduModal
        fullWidth
        maxWidth="lg"
        open={show}
        onClose={onClose}
      >
        <EduModalTitle onClose={onClose}>
          <img
            src={PaymentIcon}
            className="icon"
          />
          {exercise?.name}
        </EduModalTitle>

        <EduModalBody>
          <iframe
            id="frameContent"
            src={exercise?.url}
            width="100%"
            height="700"
          />
        </EduModalBody>
      </EduModal>
    );
  }
);

export default ViewPdfExerciseDialog;
