import { Box } from "@mui/material";
import { InfoTemplateProps } from "../crm-upselling.types";

const InfoTemplate: React.FC<InfoTemplateProps> = ({ popoverInfo }) => {
  return (
    <Box className="bg-edu-800 flex flex-col p-3 text-sm gap-1">
      <Box>
        Course: <b>{popoverInfo?.path}</b>
      </Box>

      <Box>
        English level: <b>{popoverInfo?.english_lvl}</b>
      </Box>

      <Box>
        New starting month: <b>{popoverInfo?.newStartingMonth}</b>
      </Box>
      <hr />
      {popoverInfo?.used ?? 0 === 0 ? (
        <>
          <Box className="!h-[1px] bg-edu-600 my-1" />
          <Box>
            Discount: <b>{popoverInfo?.code}</b>
          </Box>

          <Box>
            Discount value: <b>{popoverInfo?.amount}</b>
          </Box>

          <Box>
            Discount expires: <b>{popoverInfo?.expires_at}</b>
          </Box>
        </>
      ) : (
        "Discount Code Expires"
      )}
    </Box>
  );
};

export default InfoTemplate;
