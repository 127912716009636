import Cookie from "js-cookie";

const setCookie = (cookieName: string, data: string, expires?: number | Date) => {
  Cookie.set(cookieName, data, {
    path: "/",
    secure: true,
    expires: expires || 1,
    sameSite: "strict",
  });
};

const getCookie = (cookieName: string) => {
  return Cookie.get(cookieName);
};

const rmCookie = (cookieName: string) => {
  return Cookie.remove(cookieName);
};

export { setCookie, getCookie, rmCookie };
