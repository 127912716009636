import { uuid } from "@/utils";
import { useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getTableStyling } from "@/providers/Colors";
import { Box, Tooltip, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const FeedbackUser = ({ user, isLoading }) => {
  const theme = useTheme();

  const columns = [
    {
      field: "name_lesson",
      headerName: "Lesson",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={<Typography>{row?.event?.titolo}</Typography>}>
            <p
              className="truncate"
              style={{ width: "100%" }}
            >
              {row?.event?.titolo}
            </p>
          </Tooltip>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      // flex: 1,
      width: 110,
      valueGetter: ({ row }) => (row?.event?.data ? row?.event?.data : "-"),
    },
    {
      field: "hour",
      headerName: "Hour",
      // flex: 1,
      width: 60,
      valueGetter: ({ row }) => {
        if (row?.event?.ora) return row?.event?.ora;
        return "-";
      },
    },
    {
      field: "name_tutor",
      headerName: "Tutor",
      width: 200,
      valueGetter: ({ row }) => {
        if (row?.event?.tutors)
          return `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`;
        return "-";
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      // flex: 1,
      width: 100,
      renderCell: ({ row }) => {
        return row?.event?.rating;
      },
    },
    {
      field: "first_feedback",
      headerName: "First feedback",
      width: 200,
      renderCell: ({ row }) => {
        if (row?.first_feedback)
          return (
            <Tooltip title={<Typography>{row?.first_feedback}</Typography>}>
              <p
                className="truncate"
                style={{ width: "100%" }}
              >
                {row?.first_feedback}
              </p>
            </Tooltip>
          );
        return "-";
      },
    },
    {
      field: "improvements",
      headerName: "Improvements",
      // flex: 1,
      width: 110,
      renderCell: ({ row }) => {
        // const improvements = row?.improvements
        //   ? JSON.parse(row?.improvements)
        //   : null;
        const jsonString = row?.improvements
          ?.replace(/\\\"/g, '"')
          .replace(/^"|"$/g, "");
        const improvements = jsonString ? JSON.parse(jsonString) : null;

        if (improvements && typeof improvements === "object") {
          return (
            <Tooltip
              placement="right"
              title={
                <Box>
                  {Object.entries(improvements).map(
                    ([category, values], categoryIndex) => {
                      if (Array.isArray(values)) {
                        return (
                          <Box key={category}>
                            {values?.length > 0 && (
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#b388ff",
                                  marginTop: categoryIndex > 0 ? "1rem" : "0",
                                }}
                              >
                                {category.charAt(0).toUpperCase() +
                                  category.slice(1)}
                              </Typography>
                            )}
                            {values?.map((value, index) => (
                              <Typography
                                key={`${category}-${index}`}
                                style={{
                                  paddingTop: index !== 0 ? "0.4rem" : "",
                                  borderBottom:
                                    index !== values?.length - 1
                                      ? "1px solid"
                                      : "",
                                }}
                              >
                                <CircleIcon
                                  style={{
                                    fontSize: "0.5rem",
                                    marginRight: "0.2rem",
                                  }}
                                />{" "}
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </Typography>
                            ))}
                          </Box>
                        );
                      }
                    }
                  )}
                </Box>
              }
            >
              <Box
                className="truncate"
                style={{ width: "100%" }}
              >
                {improvements &&
                  Object.values(improvements).reduce(
                    (acc, val: any) => acc + val.length,
                    0
                  )}
              </Box>
            </Tooltip>
          );
        }

        return "-";
      },
    },
    {
      field: "last_feedback",
      headerName: "Last feedback",
      width: 200,
      renderCell: ({ row }) => {
        if (row?.last_feedback)
          return (
            <Tooltip title={<Typography>{row?.last_feedback}</Typography>}>
              <p
                className="truncate"
                style={{ width: "100%" }}
              >
                {row?.last_feedback}
              </p>
            </Tooltip>
          );
        return "-";
      },
    },
  ];

  return (
    <DataGrid
      autoHeight
      disableSelectionOnClick
      sx={getTableStyling(theme)}
      rows={user?.feedbacks || []}
      columns={columns}
      density="compact"
      loading={isLoading}
      pageSize={10}
      rowsPerPageOptions={[10]}
      getRowId={(row: any) => uuid()}
      components={{
        Pagination: CustomPaginationClient,
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      initialState={{
        sorting: { sortModel: [{ field: "date", sort: "desc" }] },
      }}
      experimentalFeatures={{ newEditingApi: false }}
    />
  );
};

export default FeedbackUser;
