import templateEn from "@/components/assets/emails/chose-payment/template-en";
import templateIt from "@/components/assets/emails/chose-payment/template-it";
import templateEs from "@/components/assets/emails/chose-payment/template-es";
import templateDe from "@/components/assets/emails/chose-payment/template-de";
import templateFr from "@/components/assets/emails/chose-payment/template-fr";
import templatePt from "@/components/assets/emails/chose-payment/template-pt";

interface IProps {
  name: string;
  link: string;
  lang: string;
}

export default ({ name, link, lang }: IProps) => {
  switch (lang) {
    case "en":
      return templateEn(name, link);
    case "it":
      return templateIt(name, link);
    case "es":
      return templateEs(name, link);
    case "de":
      return templateDe(name, link);
    case "fr":
      return templateFr(name, link);
    case "pt":
      return templatePt(name, link);
    default:
      return templateEn(name, link);
  }
};
