import { Button } from '@mui/material';

export const SeeInCrmButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button fullWidth variant="contained" onClick={onClick}>
    See in CRM
  </Button>
);

export const ResolvedButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button fullWidth variant="contained" onClick={onClick}>
    Resolved
  </Button>
);

export const StandByButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button fullWidth variant="contained" onClick={onClick}>
    Stand By
  </Button>
);

export const CommentButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button fullWidth variant="contained" onClick={onClick}>
    Comment
  </Button>
);

export const BookedCallButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button fullWidth variant="contained" onClick={onClick}>
    Booked Call
  </Button>
);
