import { JSXElementConstructor } from "react";
import { useTheme } from "@mui/material";
import { getTableStyling } from "@/providers/Colors";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridFeatureMode,
  GridRowClassNameParams,
  GridSortModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import useScreen from "@/hooks/useScreen";
import CustomPagination from "./_Common/CustomPagination";

interface IProps {
  rows: GridRowsProp;
  page?: number;
  columns: Array<GridColDef>;
  loading?: boolean;
  Toolbar?: JSXElementConstructor<any> | null | undefined;
  sortModel?: GridSortModel | undefined;
  Pagination?: JSXElementConstructor<any> | null | undefined;
  sortingMode?: GridFeatureMode | undefined;
  onChangePage?: (newPage: number) => void;
  componentsProps?: any;
  totalPagination?: number;
  getRowClassName?:
    | ((params: GridRowClassNameParams<any>) => string)
    | undefined;
  onSortModelChange?:
    | ((model: GridSortModel, details: GridCallbackDetails) => void)
    | undefined;
  hideTotalPagination?: boolean;
}

export default ({
  rows,
  page,
  columns,
  loading,
  Toolbar,
  sortModel,
  Pagination,
  onChangePage,
  componentsProps,
  totalPagination,
  getRowClassName,
  onSortModelChange,
  hideTotalPagination,
  sortingMode = "client",
}: IProps) => {
  const theme = useTheme();
  const { perPage } = useScreen();

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      disableVirtualization
      disableSelectionOnClick
      sx={getTableStyling(theme)}
      rows={rows}
      columns={columns}
      loading={loading}
      pageSize={perPage}
      sortModel={sortModel}
      components={{
        Toolbar,
        Pagination: Boolean(Pagination)
          ? Pagination
          : ({ page, setPage, totalPagination }) => (
              <CustomPagination
                page={(page || 1) - 1}
                count={totalPagination || 0}
                hideTotal={hideTotalPagination}
                rowsPerPage={perPage}
                onPageChange={(_, newPage) => setPage(newPage + 1)}
              />
            ),
      }}
      sortingMode={sortingMode}
      componentsProps={
        componentsProps ?? {
          pagination: {
            page,
            totalPagination,
            setPage: onChangePage,
          },
        }
      }
      getRowClassName={getRowClassName}
      onSortModelChange={onSortModelChange}
      rowsPerPageOptions={[perPage]}
      experimentalFeatures={{ newEditingApi: false }}
    />
  );
};
