import { memo, useEffect, useState } from "react";
import { queryClient } from "@/main";
import { useGridApiContext } from "@mui/x-data-grid";
import { CALENDARS } from "@/providers/AuthProvider";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import UseDownloadTable from "@/components/UseDownloadTable";
import SelectOwnedByFilter from "@/components/Crm/SelectOwnedByFilter";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";
import useAuth from "@/hooks/useAuth";

interface IProps {
  filters: any;
  fileName: string;
  products: Array<any>;
  isMember?: boolean;
  KEY_QUERY: Array<any>;
  setFilters: Function;
}

const Toolbar = ({
  filters,
  fileName,
  products,
  isMember = false,
  KEY_QUERY,
  setFilters,
}: IProps) => {
  const [sales, setSales] = useState<Array<string>>([]);
  const apiRef = useGridApiContext();
  const { userAdmin } = useAuth();

  useEffect(() => {
    setSales(userAdmin?.calendars?.[CALENDARS.SALES]?.map((e: any) => e?.name));
  }, [userAdmin]);

  return (
    <Box
      id="filter_bar"
      className="flex !items-stretch gap-4"
    >
      <Button
        title="Refresh"
        variant="outlined"
        onClick={() => {
          setFilters({});
          apiRef.current.setQuickFilterValues([""]);
          queryClient.invalidateQueries(KEY_QUERY);
        }}
      >
        <img
          src={refreshIcon}
          className="icon"
        />
        Refresh
      </Button>

      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={filters?.dates || filters?.product}
      >
        <Box className="flex flex-col w-[250px] gap-5 p-5">
          <TextField
            select
            fullWidth
            name="lang"
            label="Language"
            value={filters?.lang || ""}
            onChange={(e) =>
              setFilters((p: any) => {
                p.set("lang", e.target.value);
                return p;
              })
            }
          >
            {LANGUAGES.map((lang) => (
              <MenuItem
                key={lang.iso}
                value={lang.iso}
              >
                {lang.name}
              </MenuItem>
            ))}
          </TextField>

          <SelectOwnedByFilter
            title="Sale"
            ownedBy={filters?.interviewer || ""}
            items={sales || []}
            onChange={(e: any) => {
              setFilters((p: any) => {
                p.set("interviewer", e.target.value);
                return p;
              });
            }}
          />

          <TextField
            select
            label="Product"
            value={filters.product || ""}
            variant="outlined"
            onChange={(e: any) =>
              setFilters((p: any) => {
                p.set("product", e.target.value);
                return p;
              })
            }
          >
            {products?.map((product) => (
              <MenuItem
                key={product?.id}
                value={product?.token}
              >
                {product?.course_info?.course_name || product?.product_tag}
              </MenuItem>
            ))}
          </TextField>

          <DateRangePickerField
            label="Meeting date"
            value={
              typeof filters.meeting_date == "string"
                ? filters.meeting_date.split(",")
                : filters.meeting_date
            }
            onChange={(date) => {
              if (date) {
                const range =
                  dayjs(date.startDate).format("YYYY-MM-DD HH:mm:ss") +
                  "," +
                  dayjs(date.endDate).format("YYYY-MM-DD HH:mm:ss");

                setFilters(
                  (p: any) => {
                    p.set("meeting_date", range);
                    return p;
                  },
                  { replace: true }
                );
              } else {
                setFilters(
                  (p: any) => {
                    p.delete("meeting_date");
                    return p;
                  },
                  { replace: true }
                );
              }
            }}
          />

          <DateRangePickerField
            label="Creation date"
            value={
              typeof filters.created_at == "string"
                ? filters.created_at.split(",")
                : filters.created_at
            }
            onChange={(date) => {
              if (date) {
                const range =
                  dayjs(date.startDate).format("YYYY-MM-DD HH:mm:ss") +
                  "," +
                  dayjs(date.endDate).format("YYYY-MM-DD HH:mm:ss");

                setFilters(
                  (p: any) => {
                    p.set("created_at", range);
                    return p;
                  },
                  { replace: true }
                );
              } else {
                setFilters(
                  (p: any) => {
                    p.delete("created_at");
                    return p;
                  },
                  { replace: true }
                );
              }
            }}
          />
        </Box>
      </Dropdown>

      {!isMember && <UseDownloadTable fileName={fileName} />}

      <SearchToolbar />
    </Box>
  );
};

export default memo(Toolbar);
