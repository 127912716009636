import Request from "./RequestAdmin";

export const getInvoices = async (token, params) => {
  const { data } = await Request.call(
    {
      url: "/v1/users/invoices",
      method: "GET",
      params,
    },
    token
  );

  return data;
};

export const createInvoce = async ({ token, formData, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/invoices-v2`,
      method: "POST",
      data: formData,
    },
    token
  );

  if (callback) callback();

  return result;
};

export const updateInvoce = async ({ token, id, formData, callback }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/invoices/${id}`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    token
  );

  if (callback) callback();

  return result;
};

export const downloadInvoices = async (token, params) => {
  const { data } = await Request.download(
    {
      url: "/v1/users/invoices",
      method: "GET",
      params: { ...params, export: true },
    },
    token,
    "incoming-payments.csv"
  );

  return data;
};
