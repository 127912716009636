import useAPI from "@/api/useAPI";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAuth from '@/hooks/useAuth';
import { Box, Button, TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface IOpenProps {
  token: string;
  user: {
    name: string;
    surname: string;
  };
}
export interface IForwardRef {
  open: ({ token, user }: IOpenProps) => void;
}
interface IProps {
  ref: HTMLButtonElement;
}

const SendMessageIntercom = forwardRef<IForwardRef, IProps>((props, ref) => {
  const [open, setOpen] = useState(false as boolean);
  const [token, setToken] = useState("" as string);
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState({} as any);
  const { snackHandler } = useAuth();

  useImperativeHandle(ref, () => ({
    open: ({ token, user }) => {
      setIsLoading(true);
      setOpen(true);
      setToken(token);
      setUser(user);
    },
  }));

  const handleClose = () => {
    setError(undefined);
    setIsLoading(false);
    setOpen(false);
  };

  const handleSendMessage = async () => {
    setIsLoading(true);
    const res = await useAPI(
      "/v1/intercom/send-message",
      { token, message },
      { useErrors: true }
    );
    setIsLoading(false);

    if (res?.status) {
      handleClose();
      snackHandler(
        `Sent message with Intercom to ${user?.first_name} ${user?.last_name}`
      );
    } else {
      setError(res?.errors);
    }
  };

  useEffect(() => {
    setMessage(`Ciao ${user?.first_name}`);
    setIsLoading(false);
  }, [user]);

  return (
    <EduModal
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleClose()}
    >
      <EduModalTitle onClose={() => handleClose()}>
        <i
          className="fa-brands fa-intercom"
          style={{ fontSize: "1.2rem", paddingRight: "10px" }}
        />
        Send Message with Intercom to {`${user?.first_name} ${user?.last_name}`}
      </EduModalTitle>

      <EduModalBody className="flex flex-col p-5">
        <Box className="px-8 pb-6 flex flex-col gap-4">
          <TextField
            helperText={error}
            fullWidth
            multiline
            rows={5}
            maxRows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={`Ciao ${user?.first_name}`}
          />
          <Button
            variant="contained"
            disabled={!message?.length || isLoading}
            onClick={handleSendMessage}
          >
            Send
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});

export default SendMessageIntercom;
