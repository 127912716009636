import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField, Button, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";

interface ICDProps {
  data: Array<any>;
  date: Date | null;
  idMonth: number | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
  openEdit: boolean;
  onConfirm: () => void;
  paymentDate: Date | null;
  setOpenEdit: Dispatch<SetStateAction<boolean>>;
  setPaymentDate: Dispatch<SetStateAction<Date | null>>;
}

const Edit = ({
  data,
  date,
  idMonth,
  setDate,
  openEdit,
  onConfirm,
  setOpenEdit,
  paymentDate,
  setPaymentDate,
}: ICDProps) => {
  const [month, setMonth] = useState<any>();

  const handleClose = () => {
    setOpenEdit(false);
    setPaymentDate(new Date());
  };

  useEffect(() => {
    if (!openEdit) return;
    const d: any = data.find((e) => e.id == idMonth);
    setMonth(d);
    setDate(dayjs(d.kickoff_date, "DD/MM/YYYY").toDate());
    setPaymentDate(new Date(d.payment_date));
  }, [openEdit, idMonth]);

  return (
    <EduModal
      fullWidth
      open={openEdit}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={addIcon}
          className="icon"
        />
        Edit payment date{month?.start_month ? " - " + useConvertStartMonthEn(month?.start_month) : ""}
      </EduModalTitle>

      <EduModalBody className="bg-edu-800 p-5 pt-6 flex flex-col gap-5">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["day"]}
            value={
              date ||
              (month?.kickoff_date
                ? dayjs(month?.kickoff_date, "DD/MM/YYYY")
                : new Date())
            }
            openTo="day"
            onChange={(e) => setDate(e as Date)}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Kick off date"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["day"]}
            value={
              paymentDate ||
              (month?.payment_date ? new Date(month?.payment_date) : new Date())
            }
            openTo="day"
            onChange={(e) => setPaymentDate(e as Date)}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Payment date"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>

        <Box className="flex justify-end">
          <Button
            variant="outlined"
            onClick={onConfirm}
            disabled={Boolean(!paymentDate)}
          >
            <img
              src={saveIcon}
              className="icon"
            />
            Save
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default Edit;
