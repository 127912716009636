import { useState } from "react";
import { convert } from "@/components/useHelpers";
import { INUpdateRow } from "../Upselling/v1/Crm/interfacesAndDefaults";
import { Box, Button, Drawer, Typography } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import callIcon from "@/assets/icons/call.svg";
import SimpleBar from "simplebar-react";
import TimelineGen from "@/components/Crm/TimelineGen";
import CalendarEvents from "@/components/CalendarEvents/";
import QrCodeGenerator from "@/components/QrCodeGenerator";
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";

export interface INUserCrmCall {
  first_name: string;
  lang: string;
  email: string;
  phone: string;
  token: string;
  status: string;
  last_name: string;
  answers: undefined | Array<object>;
  updates: undefined | Array<object>;
  details: undefined | Array<object>;
  meetings: { meet: Array<any>; summary: string };
  tutoring: boolean;
  created_at: string;
}

interface INProps {
  open: boolean;
  data: {
    user: INUserCrmCall;
    buttons?: {
      toBeCalled?: { status: string };
      scheduleMeeting?: { status: string };
      noReply?: { status: string };
      incorectNumber?: { status: string };
    };
  };
  onClose: () => void;
  functions?: { onUpdateRow?: (param: INUpdateRow) => void };
  isUpselling?: boolean;
}

const CrmCall = ({ open, data, onClose, functions, isUpselling }: INProps) => {
  const [status, setStatus] = useState<string>("");
  const [openCalendarEvents, setOpenCalendarEvents] = useState<boolean>(false);

  if (!data.user) return null;

  return (
    <EduModal
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <EduModalTitle onClose={onClose}>
        <img
          src={callIcon}
          className="icon no-margin"
        />{" "}
        CALL MEETING
      </EduModalTitle>

      <EduModalBody className="p-6">
        <SimpleBar>
          <Box className="grid sm:grid-cols-2 gap-10">
            <Box>
              <Box className="w-full flex flex-wrap">
                <Box className="flex flex-wrap flex-row">
                  {Boolean(data?.user?.phone) && (
                    <QrCodeGenerator dataText={"tel:" + data.user.phone} />
                  )}

                  {Boolean(data?.user?.updates) && (
                    <TimelineGen
                      data={
                        Array.isArray(convert(data.user.updates))
                          ? convert(data.user.updates).slice(-2)
                          : Object.values(data?.user?.updates ?? {})
                              .filter((update) => typeof update === "object")
                              .slice(-2)
                      }
                    />
                  )}
                </Box>
              </Box>

              <Box className="flex flex-wrap flex-col gap-10 mt-10">
                <Box>
                  <Typography
                    variant="h6"
                    component="h2"
                  >
                    <AccountCircleRoundedIcon /> Personal information
                  </Typography>

                  <Typography
                    className="text-bold text-sm"
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  >
                    Name & surname :{" "}
                    {data.user?.first_name + " " + data.user?.last_name}
                  </Typography>

                  {data.user.email ? (
                    <Typography sx={{ mt: 2 }}>
                      Email : {data.user.email}
                    </Typography>
                  ) : null}
                  {data.user.phone ? (
                    <Typography sx={{ mt: 2 }}>
                      Phone : {data.user.phone}
                    </Typography>
                  ) : null}
                  {data.user.status ? (
                    <Typography sx={{ mt: 2 }}>
                      Status : {data.user.status}
                    </Typography>
                  ) : null}
                  {data.user.created_at ? (
                    <Typography sx={{ mt: 2 }}>
                      Registration date :{" "}
                      {new Date(data.user.created_at).toLocaleString("it-IT")}
                    </Typography>
                  ) : null}
                </Box>

                {data.user?.answers && (
                  <Box>
                    <Typography
                      variant="h6"
                      component="h2"
                    >
                      <QuestionAnswerRoundedIcon /> Questions and answers
                    </Typography>

                    {convert(data.user.answers).map(
                      (answer: any, index: React.Key | null | undefined) => (
                        <Typography
                          key={index}
                          sx={{ mt: 2 }}
                        >
                          {answer.label} : {answer.value}
                        </Typography>
                      )
                    )}
                  </Box>
                )}

                {data.user.details && (
                  <Box>
                    <Typography
                      variant="h6"
                      component="h2"
                    >
                      <DataUsageRoundedIcon /> Marketing Data
                    </Typography>

                    {convert(data.user.details).map(
                      (detail: any, index: React.Key | null | undefined) => (
                        <Typography
                          key={index}
                          sx={{ mt: 2 }}
                        >
                          {detail.label} : {detail.value}
                        </Typography>
                      )
                    )}
                  </Box>
                )}
              </Box>
            </Box>

            <Box className="flex grow flex-wrap items-center flex-col gap-4">
              {data.buttons?.toBeCalled && (
                <Button
                  variant="outlined"
                  className="w-full mx-5 !p-[0.65rem]"
                  onClick={() => {
                    setStatus(data.buttons?.toBeCalled?.status || "");
                    setOpenCalendarEvents(true);
                  }}
                >
                  To be called
                </Button>
              )}

              {data.buttons?.scheduleMeeting && (
                <Button
                  variant="outlined"
                  className="w-full mx-5 !p-[0.65rem]"
                  onClick={() => {
                    setStatus(data.buttons?.scheduleMeeting?.status || "");
                    setOpenCalendarEvents(true);
                  }}
                >
                  Schedule meeting
                </Button>
              )}

              {data.buttons?.noReply && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    functions?.onUpdateRow?.({
                      toast: "status",
                      tokenUser: data.user.token,
                      values: { status: data.buttons?.noReply?.status },
                    })
                  }
                  className="w-full mx-5 !p-[0.65rem]"
                >
                  No reply
                </Button>
              )}

              {data.buttons?.incorectNumber && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    functions?.onUpdateRow?.({
                      toast: "status",
                      tokenUser: data.user.token,
                      values: {
                        status: data.buttons?.incorectNumber?.status,
                      },
                    })
                  }
                  className="w-full mx-5 !p-[0.65rem]"
                >
                  Incorrect number
                </Button>
              )}
            </Box>

            <Drawer
              sx={{ zIndex: 1300 }}
              open={openCalendarEvents}
              anchor="right"
              onClose={() => setOpenCalendarEvents(false)}
            >
              <CalendarEvents
                user={{
                  lang: data.user.lang,
                  name: `${data.user.first_name} ${data.user.last_name}`,
                  email: data.user.email,
                  token: data.user.token,
                  tutoring: data.user.tutoring,
                }}
                status={status}
                isUpselling={Boolean(isUpselling)}
                onUpdateRow={(meet: any) => {
                  const values: any = { status };

                  const isScheduleMeeting =
                    status === data.buttons?.scheduleMeeting?.status;

                  if (isScheduleMeeting) {
                    let meetings: any = convert(data.user.meetings);

                    const formatMeet = {
                      id: meet?.id,
                      start: meet?.start,
                      end: meet?.end,
                      calendar: meet?.calendar,
                      link: meet?.meet,
                    };

                    if (isScheduleMeeting && meetings) {
                      meetings.meet.push(formatMeet);
                      values.meetings = meetings;
                    } else if (isScheduleMeeting && !meetings) {
                      values.meetings = {
                        meet: [formatMeet],
                        summary: meet?.summary,
                      };
                    }
                  }

                  functions?.onUpdateRow?.({
                    toast: "status",
                    tokenUser: data.user.token,
                    values,
                    activeApi: !isScheduleMeeting,
                  });

                  setOpenCalendarEvents(false);
                }}
              />
            </Drawer>
          </Box>
        </SimpleBar>
      </EduModalBody>
    </EduModal>
  );
};

export default CrmCall;
