import { createRef, useEffect, useState } from "react";
import { useContextCrm } from "../Context";
import { IDialpadCallModal } from "@/components/Dialpad/interface";
import DialpadCallModal from "@/components/Dialpad/DialpadSingleCallV2/DialpadSingleCall";

export default () => {
  const context = useContextCrm();

  const dialpadCallRef = createRef<IDialpadCallModal>();

  const [rowForDialpad, setRowForDialpad] = useState<any>({});

  useEffect(() => {
    switch (context.dialog?.component) {
      case "dialpad_lead":
        if (context.dialog?.row?.phone || context.rowSelected?.phone) {
          setRowForDialpad(context.dialog?.row || context.rowSelected);
        }
        dialpadCallRef.current?.openModal();
        break;
      default:
        break;
    }
  }, [
    context.dialog,
    context.dialog?.row,
    context.dialog?.component,
    context.rowSelected,
  ]);

  return (
    <>
      <DialpadCallModal
        row={rowForDialpad}
        ref={dialpadCallRef}
      />
    </>
  );
};
