import { Box, Tooltip } from "@mui/material";
import classNames from "classnames";

interface INProps {
  title?: any;
  arrow?: boolean;
  children: any;
  className?: string;
  placement?:
    | "bottom-start"
    | "bottom-end"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
  classNameTooltip?: string;
}

const MyTooltip = ({
  children,
  title = "",
  arrow = true,
  className = "",
  placement = "bottom-start",
  classNameTooltip = "",
}: INProps) =>
  children && (
    <Tooltip
      arrow={arrow}
      title={
        <Box className={classNames("text-base", classNameTooltip)}>
          {title || children}
        </Box>
      }
      placement={placement}
    >
      <Box className={"truncate w-full " + className}>{children}</Box>
    </Tooltip>
  );

export default MyTooltip;
