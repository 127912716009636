import { TextField } from "@mui/material";
import FlagDropdown from "./FlagDropdown";

export interface IPhone {
  name: string;
  flag: string;
  code: string;
  phone: string[];
}

interface IProps {
  value: string;
  onChange: (newPhone: string, country?: string) => void;
  required?: boolean;
  [x: string]: any;
}

const PhoneField = ({ onChange, value, ...rest }: IProps) => {
  const handleChange = (data: string) => {
    const regex = /^[0-9+\b]+$/;

    if (data === "" || regex.test(data)) {
      onChange(data, "IT");
    }
  };

  return (
    <TextField
      sx={{
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "input[type=number]": {
          MozAppearance: "textfield",
        },
      }}
      {...rest}
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      InputProps={{
        onClick: () => {
          if (!value) handleChange("+39");
        },
        startAdornment: (
          <FlagDropdown
            value={value}
            onChange={(data: string) => handleChange(data)}
            defaultCountry="IT"
          />
        ),
      }}
    />
  );
};

export default PhoneField;
