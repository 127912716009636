import BasePage from "@/components/Page/Base";

const Test = () => {
  return (
    <BasePage title="TEST">
      TEST
    </BasePage>
  );
};

export default Test;
