import { Dispatch, SetStateAction } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

interface IProps {
  users: Array<any>;
  selectUser: any;
  activeSearch: boolean;
  setSelectUser: Dispatch<SetStateAction<any>>;
  handleSearchEvents: () => void;
}

export default ({
  users,
  selectUser,
  activeSearch,
  setSelectUser,
  handleSearchEvents,
}: IProps) => {
  return (
    <Box
      id="filter_bar"
      className="flex !items-stretch gap-4"
    >
      <TextField
        select
        sx={{ maxWidth: "20rem", minWidth: "7rem" }}
        label={users?.length ? "Select user from this event" : "Loading..."}
        color="primary"
        title="Select user for search"
        value={selectUser?.user || ""}
        variant="outlined"
        onChange={(e: any) =>
          setSelectUser(users.find((user: any) => user.user === e.target.value))
        }
        className="grow !text-[#b388ff]"
      >
        {!users?.length ? (
          <MenuItem
            key="0"
            color="primary"
          >
            Loading...
          </MenuItem>
        ) : null}

        {users?.map(({ user, count_change_event }) => (
          <MenuItem
            key={user}
            value={user}
            className=" !text-[#b388ff]"
          >
            {user}
            {count_change_event ? count_change_event : 0}
          </MenuItem>
        ))}
      </TextField>

      <Button
        color="primary"
        title="Search events by user"
        variant="outlined"
        onClick={handleSearchEvents}
        disabled={activeSearch || !selectUser?.user}
      >
        {activeSearch ? (
          <RefreshIcon color="primary" />
        ) : (
          <SearchIcon color="primary" />
        )}
      </Button>
    </Box>
  );
};
