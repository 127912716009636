import searchIcon from "@/assets/icons/search.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import useDebounce from "@/hooks/useDebounce";
import { Box, Button, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import callIcon from "@/assets/icons/call.svg";
import DialpadMassCall from "@/components/Dialpad/DialpadMassCall";

const CustomToolbar = (props) => {
  const { filters, setFilters, selectedRows } = props;
  const theme = useTheme();
  const [search, setSearch] = useState<string | null>(filters?.search ?? null);
  const debouncedSearch = useDebounce(search, 500);
  const [] = useState();
  const handleSearchChange = (e) => {
    let value = e.target.value;
    setSearch(value);
    // setTyped(true);
  };
  const [callStarted, setCallStarted] = useState(false);
  const [numbers, setNumbers] = useState<any>([]);

  const handleCallEnded = () => {
    setCallStarted(false);
  };
  const handleDialpadCall = (row: any) => {
    setCallStarted(true);
  };
  useEffect(() => {
    if (!filters?.search) {
      setSearch("");
    }
  }, [filters?.search]);

  useEffect(() => {
    if (setFilters)
      setFilters(
        (prev) => {
          if (debouncedSearch) {
            prev.set("search", debouncedSearch);
          } else {
            prev.delete("search");
          }

          return prev;
        },
        { replace: true }
      );
  }, [debouncedSearch]);

  useEffect(() => {
    const temp: any[] = [];
    selectedRows.forEach((element) => {
      temp.push(element.phone);
    });
    setNumbers(temp);
  }, [selectedRows]);
  return (
    <Box
      className="flex gap-4 flex-wrap"
      id="filter_bar"
    >
      <DialpadMassCall
        open={callStarted}
        onClose={handleCallEnded}
        rows={numbers}
      />
      <Button
        variant="outlined"
        onClick={handleDialpadCall}
      >
        <img
          src={callIcon}
          style={{
            marginRight: 10,
            filter: theme.palette.filter,
          }}
        />
        Call Selected Users
      </Button>
      <FiltersBar>
        <TextField
          sx={{ marginLeft: "auto", maxWidth: "200px" }}
          InputProps={{
            startAdornment: (
              <img
                src={searchIcon}
                style={{
                  marginRight: 10,
                  filter: theme.palette.filter,
                }}
              />
            ),
          }}
          name="search"
          size="small"
          variant="outlined"
          value={search || ""}
          onChange={handleSearchChange}
          placeholder="Search..."
        />
      </FiltersBar>
    </Box>
  );
};

export default CustomToolbar;
