import { memo, useMemo } from "react";
import { CALENDARS } from "@/providers/AuthProvider";
import { exportAllSurveyToCsv } from "../components/UsersSurvey/surveyHelpers";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import backIcon from "@/assets/icons/back.svg";
import nextIcon from "@/assets/icons/next.svg";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import useUserApi from "@/api/useUserApi";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import useExportCSV from "@/components/useExportCSV";
import downloadIcon from "@/assets/icons/download.svg";
import materialIcon from "@/assets/icons/material.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import AvailabilitiesIcon from "@/assets/icons/availabilities.svg";
import SelectOwnedByFilter from "@/components/Crm/SelectOwnedByFilter";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";
import useAuth from "@/hooks/useAuth";

const UsersToolbar = ({
  isMode,
  filters,
  setIsMode,
  setFilters,
  englishMenu,
  productMenu,
  handleChange,
  selectedMode,
  startMonthMenu,
  handleOpenDialog,
  userCourseStatusMenu,
  onRemoveModeSelected,
  onAddSyncToActiveCampaign,
  setOpenConfirmSelectedUpselling,
  onStretchRangeintoAvailabilities,
}) => {
  const { callDownloadUser } = useUserApi();
  const { isAdmission, isMember, userAdmin } = useAuth();

  const handleDownloadCSV = async () => {
    const res = await useAPI("/v1/tutoring", { ...filterData, export: true });
    useExportCSV(res, "admission-student.csv");
  };

  const filterData = Object.fromEntries(filters.entries());

  const handleDelete = (key: string) => {
    setFilters(
      (p: any) => {
        p.delete(key);
        return p;
      },
      { replace: true }
    );
  };

  const filtersApplied = useMemo(
    () =>
      Object.keys(filterData).filter(
        (key) => !["search", "sorts", "page"].includes(key)
      ).length,
    [filterData]
  );

  return (
    <Box
      id="filter_bar"
      className="flex gap-2 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({}, { replace: true })}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        {!isMode && !isAdmission && (
          <Button
            variant="outlined"
            title="Create user"
            onClick={() => handleOpenDialog()}
          >
            <img
              src={addIcon}
              className="icon"
            />
            Create user
          </Button>
        )}

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(filtersApplied)}
        >
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            {!isAdmission && (
              <>
                <TextField
                  select
                  fullWidth
                  name="active_students"
                  label="Active students"
                  value={filterData?.active_students ?? ""}
                  onChange={handleChange}
                >
                  {userCourseStatusMenu}
                </TextField>

                <TextField
                  select
                  fullWidth
                  name="english_level"
                  label="English level"
                  value={filterData?.english_level ?? ""}
                  onChange={handleChange}
                >
                  {englishMenu}
                </TextField>

                <TextField
                  select
                  fullWidth
                  name="start_month"
                  label="Start month"
                  value={filterData?.start_month ?? ""}
                  onChange={handleChange}
                >
                  {startMonthMenu}
                </TextField>

                <TextField
                  select
                  fullWidth
                  name="has_availabilities"
                  label="Has availabilities"
                  value={filterData?.has_availabilities ?? ""}
                  onChange={handleChange}
                >
                  <MenuItem
                    key="all"
                    value=""
                  >
                    All
                  </MenuItem>

                  {["Yes", "No"].map((e) => (
                    <MenuItem
                      key={e}
                      value={e}
                    >
                      {e}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}

            <TextField
              select
              fullWidth
              name="lang"
              color="primary"
              label="Language"
              value={filterData?.lang || ""}
              onChange={handleChange}
            >
              {[{ name: "- Remove -", iso: "" }, ...LANGUAGES].map(
                (item: { iso: string; name: string }, i: number) => (
                  <MenuItem
                    key={i}
                    value={item.iso}
                  >
                    {item.name}
                  </MenuItem>
                )
              )}
            </TextField>

            <TextField
              select
              fullWidth
              name="product"
              label="Product"
              value={filterData?.product ?? ""}
              onChange={(e: any) => {
                handleChange(e);
                handleDelete("path");
              }}
            >
              {productMenu}
            </TextField>

            <SelectOwnedByFilter
              useRemove
              items={userAdmin?.calendars?.[CALENDARS.SALES] || []}
              ownedBy={filterData?.owned_by}
              onChange={handleChange}
            />

            <DateRangePickerField
              title="Creation date"
              value={
                filterData?.created_at
                  ? filterData?.created_at
                    .split(",")
                    .map((c: string) => new Date(c))
                  : null
              }
              onChange={(value) => {
                setFilters((p: any) => {
                  if (value) {
                    p.set(
                      "created_at",
                      [
                        dayjs(value?.startDate).format("YYYY-MM-DD HH:mm:ss"),
                        dayjs(value?.endDate).format("YYYY-MM-DD HH:mm:ss"),
                      ].join(",")
                    );
                    p.set("page", "1");
                  } else p.delete("created_at");
                  return p;
                });
              }}
            />

            <DateRangePickerField
              title="Meeting date"
              value={
                filterData?.meeting_date
                  ? filterData?.meeting_date
                    .split(",")
                    .map((c: string) => new Date(c))
                  : null
              }
              onChange={(value) => {
                setFilters((p: any) => {
                  if (value) {
                    p.set(
                      "meeting_date",
                      [
                        dayjs(value?.startDate).format("YYYY-MM-DD HH:mm:ss"),
                        dayjs(value?.endDate).format("YYYY-MM-DD HH:mm:ss"),
                      ].join(",")
                    );
                    p.set("sorts", '{"meeting_date":"asc"}');
                    p.set("page", "1");
                  } else p.delete("meeting_date");
                  return p;
                });
              }}
            />
          </Box>
        </Dropdown>

        {!isMode && (
          <Dropdown
            text="Select users"
            icon={filterIcon}
            active={Boolean(selectedMode?.length)}
          >
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              {!isMode && !isAdmission && (
                <Button
                  variant="outlined"
                  onClick={() => setIsMode("upselling")}
                  className="!flex !gap-4 !justify-start"
                >
                  <img
                    src={materialIcon}
                    className="icon"
                  />
                  <Box className="grow text-center">Add to upselling</Box>
                </Button>
              )}

              {!isMode && (
                <Button
                  variant="outlined"
                  onClick={() => setIsMode("stretch_range_into_availabilities")}
                  className="!flex !gap-4 !justify-start"
                >
                  <img
                    src={AvailabilitiesIcon}
                    className="icon"
                  />
                  <Box className="grow text-center">
                    Stretch range into availabilities
                  </Box>
                </Button>
              )}

              {!isMode && (
                <Button
                  variant="outlined"
                  onClick={() => setIsMode("add_sync_to_active_campaign")}
                  className="!flex !gap-4 !justify-start"
                >
                  <span className="pr-2">AC</span>
                  <Box className="grow text-center">
                    Add/sync to Active Campaign
                  </Box>
                </Button>
              )}
            </Box>
          </Dropdown>
        )}

        {isMode && (
          <Box className="flex gap-4">
            <Button
              variant="outlined"
              onClick={onRemoveModeSelected}
            >
              <img
                src={backIcon}
                className="icon"
              />
              Back
            </Button>

            <Button
              disabled={!Boolean(selectedMode.length)}
              variant="contained"
              onClick={() => {
                switch (isMode) {
                  case "upselling":
                    setOpenConfirmSelectedUpselling(true);
                    break;
                  case "stretch_range_into_availabilities":
                    onStretchRangeintoAvailabilities();
                    break;
                  case "add_sync_to_active_campaign":
                    onAddSyncToActiveCampaign();
                    break;
                  default:
                    break;
                }
              }}
            >
              <img
                src={nextIcon}
                className="icon"
              />
              Next
            </Button>
          </Box>
        )}

        {!isMode && (
          <Dropdown
            text="Downloads"
            icon={filterIcon}
          >
            <Box className="flex flex-col w-[200px] gap-5 p-5">
              {!isMode && !isMember && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (isAdmission) {
                      handleDownloadCSV();
                    } else {
                      callDownloadUser(filterData, "All Users.csv");
                    }
                  }}
                  className="!flex !gap-4 !justify-start"
                >
                  <img
                    src={downloadIcon}
                    className="icon"
                  />
                  <Box className="grow text-center">Download</Box>
                </Button>
              )}

              {!isMode && (
                <Button
                  variant="outlined"
                  onClick={() => exportAllSurveyToCsv(userAdmin.token)}
                  className="!flex !gap-4 !justify-start"
                >
                  <img
                    src={downloadIcon}
                    className="icon"
                  />
                  <Box className="grow text-center">Export survey</Box>
                </Button>
              )}
            </Box>
          </Dropdown>
        )}
      </FiltersBar>

      <SearchToolbar
        filters={filterData}
        setFilters={setFilters}
      />
    </Box>
  );
};

export default memo(UsersToolbar);
