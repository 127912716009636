import { useMemo } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { Box, Button, MenuItem, TextField, Tooltip } from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";

const Toolbar = ({
  filters,
  keyQuery,
  onChange,
  onExport,
  setFilters,
  filterData,
  startMonths,
  productsAPI,
}) => {
  const { isMember } = useAuth();

  const filtersApplied = useMemo(
    () =>
      Boolean(
        Object.keys(Object.fromEntries(filters?.entries()) || {}).filter(
          (key) => !["search", "sorts", "page"].includes(key)
        )?.length
      ),
    [filters]
  );

  return (
    <Box className="flex gap-4">
      <Button
        variant="outlined"
        onClick={() => {
          setFilters({}, { replace: true });
          queryClient.invalidateQueries(keyQuery);
        }}
      >
        <img
          src={refreshIcon}
          className="icon"
        />
        Refresh
      </Button>

      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={filtersApplied}
      >
        <Box className="w-[200px] flex flex-col gap-4 p-4">
          <TextField
            select
            fullWidth
            name="english_level"
            label="English level"
            value={filterData?.english_level || ""}
            onChange={onChange}
          >
            {ENGLISH_LEVELS?.map((l, i: number) => (
              <MenuItem
                key={l + i}
                value={l}
              >
                {l}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            name="start_month"
            label="Start month"
            value={filterData?.start_month || ""}
            onChange={onChange}
          >
            {startMonths?.map((s: string, i: number) => (
              <MenuItem
                key={s + i}
                value={s}
              >
                {s}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            name="product"
            label="Product"
            value={filterData?.product || ""}
            onChange={onChange}
          >
            {productsAPI?.products?.map((product: any) => {
              const name =
                product?.course_info?.course_name || product?.product_tag;

              return (
                <MenuItem
                  key={product.id}
                  value={product.token}
                >
                  <Tooltip title={name}>
                    <Box className="w-full flex justify-between">
                      <Box className="truncate mr-2">{name}</Box>
                      <Box>{"€" + (product?.price / 100).toFixed(2)}</Box>
                    </Box>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      </Dropdown>

      {!isMember && (
        <Button
          variant="outlined"
          onClick={onExport}
        >
          <img
            src={downloadIcon}
            className="icon"
          />
          Download
        </Button>
      )}

      <SearchToolbar {...{ filters: filterData, setFilters }} />
    </Box>
  );
};

export default Toolbar;
