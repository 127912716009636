import { useMemo } from "react";
import { useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import MyTooltip from "@/components/MyTooltip";
import useScreen from "@/hooks/useScreen";
import ToolbarNps from "@/pages/Feedback/NpsFeedbacks/ToolbarNps";
import usePagination from "@/hooks/usePagination";
import useDataGridSort from "@/hooks/useDataGridSort";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const KEY_QUERY = ["multi", "nps"];
const ROUTE_HTTP_BASE = "/v1/feedbacks";

const Nps = () => {
  const theme = useTheme();
  const { perPage } = useScreen();

  const [filters, setFilters] = useSearchParams();
  const { page, setPage } = usePagination({ setFilters, filters });
  const filterData = Object.fromEntries(filters.entries());

  const { sortModel, onSortModelChange } = useDataGridSort({
    setFilters,
    filters,
  });

  const { data: dataAPI, isLoading } = useQuery(KEY_QUERY, () =>
    useAPI(ROUTE_HTTP_BASE, { type: "nps" })
  );

  const dataFilter = useMemo(
    () =>
      (dataAPI || [])
        .filter((u: any) => {
          if (!filterData?.start_month) return true;
          if (u.start_month === filterData.start_month) return true;
          return false;
        })
        .filter((u: any) => {
          if (!filterData?.english_level) return true;
          if (u.english_level === filterData.english_level) return true;
          return false;
        }),
    [dataAPI, filterData]
  );

  const startMonths = useMemo(
    () =>
      [
        ...new Set(
          (dataAPI || [])
            .filter((f: any) => Boolean(f.start_month))
            .map((f: any) => f.start_month)
        ),
      ].sort(),
    [dataAPI]
  );

  const avg = useMemo(() => {
    let promotersScore: number = 0;
    let detractorsScore: number = 0;

    dataFilter.forEach((el: any) => {
      if (el?.nps_feedback >= 9) {
        promotersScore++;
      }

      if (el?.nps_feedback >= 0 && el?.nps_feedback <= 6) {
        detractorsScore++;
      }
    });

    const percPromoters =
      (promotersScore / (promotersScore + detractorsScore)) * 100;

    const percDetractors =
      (detractorsScore / (promotersScore + detractorsScore)) * 100;

    return (percPromoters - percDetractors).toFixed(0);
  }, [dataFilter]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "first_name",
        minWidth: 180,
        flex: 1,
        headerName: "Name",
        valueGetter: ({ row }) => `${row?.first_name} ${row?.last_name}`,
      },
      {
        field: "start_month",
        headerName: "Start month",
        minWidth: 150,
        flex: 0.5,
        valueGetter: ({ row }) =>
          useConvertStartMonthEn(row?.start_month) || "-",
      },
      {
        field: "english_level",
        headerName: "English level",
        minWidth: 150,
        flex: 0.5,
        valueGetter: ({ row }) => row?.english_level || "-",
      },
      {
        field: "nps_feedback",
        headerName: `NPS = ${avg}%`,
        minWidth: 90,
        flex: 0.5,
        renderHeader: () => (
          <div className="MuiDataGrid-columnHeaderTitle ">
            <span className="number">{avg}%</span>
            NPS
          </div>
        ),
        valueGetter: ({ row }) => row?.nps_feedback || "-",
      },
      {
        field: "nps_comment",
        headerName: "Comment",
        minWidth: 300,
        flex: 2,
        renderCell: ({ row }) =>
          Boolean(row?.nps_comment) ? (
            <MyTooltip>{row?.nps_comment}</MyTooltip>
          ) : (
            "-"
          ),
      },
    ],
    [avg, dataFilter]
  );

  return (
    <BasePage title="NPS">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={dataFilter}
        page={parseInt(page.toString()) - 1}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar: ToolbarNps,
          Pagination: CustomPaginationClient,
        }}
        onPageChange={(newPage) => setPage(newPage + 1)}
        componentsProps={{
          toolbar: {
            setFilters,
            startMonths,
            filters: filterData,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />
    </BasePage>
  );
};

export default Nps;
