import { useState, forwardRef, ChangeEvent, useImperativeHandle } from "react";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import { Box, Input, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import saveIcon from "@/assets/icons/save.svg";
import refundIcon from "@/assets/icons/refund.svg";
import paymentIcon from "@/assets/icons/payment.svg";
import usePaymentsInApi from "../../api/usePaymentsInApi";
import { convert } from "../useHelpers";

export interface IData {
  id?: number;
  amount?: number;
  invoice_path?: string;
  invoice_type?: string;
  refunded_date?: string;
}

type TVersion = "payment" | "refund";

export interface IRef {
  open: (row: any, version?: TVersion) => void;
}

interface IProps { }

const INVOICE_TYPE = [
  {
    label: "Regular invoice",
    value: "regular",
  },
  {
    label: "Electronic invoice",
    value: "electronic",
  },
];

export default forwardRef<IRef, IProps>((_, ref) => {
  const { userAdmin } = useAuth();
  const { callUpdatePaymentIn } = usePaymentsInApi();

  const [row, setRow] = useState<any>();
  const [file, setFile] = useState<any>(null);
  const [version, setVersion] = useState<TVersion>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: (row, version) => {
      setRow(row);
      setVersion(version);
    },
  }));

  const handleClose = () => {
    setFile(null);
    setRow(undefined);
    setIsLoading(false);
  };

  const handlePaymentChange = ({ target: { name, value } }) => {
    setRow((p: any) => ({ ...(p ?? {}), [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const formData = new FormData();

    for (const key in row) {
      formData.append(key, row[key]);
    }

    if (file) {
      formData.append("file", file[0]);
    }

    if (row?.id) {
      callUpdatePaymentIn.mutate({
        formData,
        id: row.id,
        token: userAdmin.token,
        callback: handleClose,
      });
    }

    setIsLoading(false);
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(row)}
      onClose={handleClose}
      maxWidth="xs"
    >
      <EduModalTitle onClose={handleClose}>
        {version === "payment" ? (
          <img
            src={paymentIcon}
            className="icon no-margin"
          />
        ) : (
          version === "refund" && (
            <img
              src={refundIcon}
              className="icon no-margin"
            />
          )
        )}
        {version === "payment"
          ? "Update invoice type"
          : version === "refund" && "Refund"}
      </EduModalTitle>

      <EduModalBody>
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="flex flex-col gap-5 p-4 pt-6"
        >
          {version === "payment" ? (
            <TextField
              select
              required
              fullWidth
              id="type-select"
              name="invoice_type"
              label="Invoice Type"
              value={row?.invoice_type ?? ""}
              onChange={handlePaymentChange}
            >
              {INVOICE_TYPE.map((item, i) => (
                <MenuItem
                  key={item.value + i}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          ) : version === "refund" ? (
            <>
              {Boolean(row?.last_refunded && row?.last_refunded != "[]") && (
                <Box className="w-full">
                  First refund for this:
                  {convert(row?.last_refunded)?.map((r, i) => (
                    <Box
                      key={i}
                      className="w-full flex justify-between items-center"
                    >
                      <span>
                        <b>Date: </b>
                        {r.data}
                      </span>
                      <span>
                        <b>refund: </b>
                        {r.amount}
                      </span>
                    </Box>
                  ))}
                </Box>
              )}

              <TextField
                required
                fullWidth
                id="amount"
                type="number"
                name="amount"
                label="Amount"
                value={row?.amount ?? ""}
                onChange={handlePaymentChange}
              />

              <TextField
                required
                fullWidth
                id="date"
                type="date"
                name="refunded_date"
                label="Refunded date"
                value={row?.refunded_date ?? ""}
                onChange={handlePaymentChange}
                InputLabelProps={{ shrink: true }}
              />

              {row?.invoice_path && (
                <Input
                  id="refundFile"
                  type="file"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setFile(event.target.files)
                  }
                  inputProps={{ type: "file", accept: ".pdf" }}
                />
              )}
            </>
          ) : null}

          <Box className="flex flex-row-reverse">
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoading}
              disabled={isLoading}
              startIcon={<img src={saveIcon} />}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});
