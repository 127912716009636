export default function useSessionStorage<T>(
  key: string
): [() => T, (value: T) => void] {
  const getValue = () => {
    const item = sessionStorage.getItem(key);

    if (!item) return null;

    return JSON.parse(item);
  };

  const setValue = (data: T) => {
    sessionStorage.setItem(key, JSON.stringify(data));
  };

  return [getValue, setValue];
}
