import { useState, useEffect } from "react";
import useAuth from '@/hooks/useAuth';
import {
  Box,
  //   Switch,
  MenuItem,
  useTheme,
  TextField,
  //   FormControlLabel,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import searchIcon from "@/assets/icons/search.svg";

const allowedCallTypes = [
  { label: "None", value: "" },
  { label: "Never booked", value: "Never booked" },
  { label: "No show", value: "No show" },
];

const languageOptions = [
  { label: "None", value: "" },
  { label: "Italian", value: "it" },
  { label: "Spanish", value: "es" },
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "Portuguese", value: "pt" },
];

const SearchFilter = ({
  onSearch,
  // onToggle,
  //   isStandBy,
  //   isArchived,
  applyFilters,
  // onStandByToggle,
}) => {
  const theme = useTheme();
  const { userAdmin } = useAuth();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLang, setSelectedLang] = useState("");
  const [callTypeOptions, setCallTypeOptions] = useState(allowedCallTypes);
  const [selectedCallType, setSelectedCallType] = useState("");

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  //   const handleToggleChange = (event: any) => {
  //     onToggle(event.target.checked);
  //   };

  //   const handleStandByToggleChange = (event: any) => {
  //     onStandByToggle(event.target.checked);
  //   };

  const handleCallTypeChange = (event: any) => {
    const newCallType = event.target.value;
    setSelectedCallType(newCallType);
    applyFilters({ callType: newCallType, lang: selectedLang });
  };

  const handleLangChange = (event: any) => {
    const newLang = event.target.value;
    setSelectedLang(newLang);
    applyFilters({ callType: selectedCallType, lang: newLang });
  };

  useEffect(() => {
    const fetchCallTypes = async () => {
      try {
        const response = await useAPI(
          `v1/call-center/call-types/${userAdmin.user.uuid}`,
          {},
          { type: "get" }
        );
        const { types } = response;

        const formattedTypes = types
          .filter((type: string) =>
            allowedCallTypes.some(
              (allowedType) =>
                allowedType.value.toLowerCase() === type.toLowerCase()
            )
          )
          .map((type: string) => ({
            label: allowedCallTypes.find(
              (allowedType) =>
                allowedType.value.toLowerCase() === type.toLowerCase()
            )?.label,
            value: type,
          }));

        setCallTypeOptions([{ label: "None", value: "" }, ...formattedTypes]);
      } catch (error) {
        console.error("Error fetching call types:", error);
      }
    };

    fetchCallTypes();
  }, [userAdmin.user.uuid]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, onSearch]);

  return (
    <Box className="flex gap-4 flex-wrap !items-stretch">
      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={Boolean(selectedCallType || selectedLang)}
      >
        <Box className="flex flex-col gap-4 w-[250px] p-4">
          <TextField
            select
            fullWidth
            label="Call Type"
            value={selectedCallType}
            onChange={handleCallTypeChange}
          >
            {callTypeOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Language"
            value={selectedLang}
            onChange={handleLangChange}
          >
            {languageOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Dropdown>

      <TextField
        size="small"
        value={searchTerm}
        variant="outlined"
        onChange={handleInputChange}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              style={{ marginRight: 10, filter: theme.palette.filter }}
            />
          ),
        }}
        placeholder="Search..."
        autoComplete="off" //disable suggestions
      />

      {/* <Box className="flex items-stretch gap-2 pl-3">
        <FormControlLabel
          label="Show Archived"
          control={
            <Switch
              checked={isArchived}
              onChange={handleToggleChange}
            />
          }
        />

        <FormControlLabel
          label="Show StandBy"
          control={
            <Switch
              checked={isStandBy}
              onChange={handleStandByToggleChange}
            />
          }
          className="!mr-0"
        />
      </Box> */}
    </Box>
  );
};

export default SearchFilter;
