// @ts-nocheck

import { Avatar, Box, LinearProgress, Tooltip, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useQuery } from "@tanstack/react-query";
import { getInvoices } from '../api'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { TableStyling } from '../dummy';
import { NavLink } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';

const columns = [
    {
        field: "img",
        headerName: "",
        hideable: false,
        editable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params: {
            row: {
                token_user: string;
                intestatario: string;
                img: string;
            };
        }) =>
            <Tooltip title={params.row.intestatario}>
                <NavLink to={'/users/' + params.row.token_user}>
                    <Avatar sx={{ width: 36, height: 36 }} >
                        <img src={params.row.img} loading="lazy" alt="" />
                    </Avatar>
                </NavLink>
            </Tooltip>
    },
    {
        field: "data",
        headerName: "Data",
        flex: 1,
        editable: false,
    },
    {
        field: "intestatario",
        headerName: "Intestatario",
        flex: 1,
        editable: false,
        renderCell: (params: {
            row: {
                intestatario: string,
            }
        }) => {
            <span>
                {params.row.intestatario}
            </span>
        }
    },
    {
        field: "amount",
        headerName: "Importo",
        width: 120,
        editable: false,
        renderCell: (params: {
            row: {
                amount: string,
            }
        }) => {
            return (
                <Typography color='green'>
                    &euro;{params.row.amount.split('.').join(',')}
                </Typography>
            )
        }
    },
    {
        field: "paid_for",
        headerName: "Prodotto",
        flex: 1,
        editable: false,
    },
    {
        field: "type",
        headerName: "Metodo",
        flex: 1,
        editable: false,
    },
    {
        field: "link",
        headerName: "",
        flex: 1,
        editable: false,
        renderCell: (params: {
            row: {
                token: string,
                id: number
            }
        }) => {
            return (
                <Typography color='green'>
                    <NavLink to={`../users/${params.row.token_user}/invoice/${params.row.id}`}>
                        <ArrowForwardRoundedIcon />
                    </NavLink>
                </Typography>
            )
        }
    },
];

// users/m3F5Owoo5G/invoice/6289
const Invoices = () => {
    const { userAdmin } = useAuth()
    const { data: invoices, isLoading, isError } = useQuery(['invoices'], () => getInvoices(userAdmin.token), {
        onSuccess: (data) => { }
    })

    if (isLoading) {
        return <LinearProgress />
    }

    return (
        <div className='mt-10 md:mx-10' style={{ height: 'calc(100% - 150px)' }}>
            <Box>
                <h2 className='text-3xl font-extrabold text-white mb-3'>
                    {userAdmin.user.admin ? 'Payments' : 'Payments in the last 2 weeks'}
                </h2>
                <DataGrid
                    autoHeight
                    disableColumnMenu
                    // density="comfortable"
                    sx={{ ...TableStyling, '& .MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus': { outline: 0 } }}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 }
                        }
                    }}

                    rows={invoices}

                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: false }}
                />
            </Box>
        </div>
    )
}

export default Invoices