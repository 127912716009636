import { Dispatch, SetStateAction } from "react";
import { INEmail } from ".";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, TextField, useTheme } from "@mui/material";
import MyTooltip from "@/components/MyTooltip";
import RefreshIcon from "@mui/icons-material/Refresh";

import { EduModal, EduModalBody, EduModalTitle } from "@/components/_Common/Modal/ModalStyles";
import { getTableStyling } from "@/providers/Colors";

interface INProps {
  isMobile: boolean;
  dataEmail: INEmail;
  setDataEmail: Dispatch<SetStateAction<INEmail>>;
  onSearchEmail: () => void;
}

export default ({
  isMobile,
  dataEmail,
  setDataEmail,
  onSearchEmail,
}: INProps) => {
  const theme = useTheme();
  const onClose = () =>
    setDataEmail((p: INEmail) => ({ ...p, open: false, users: [] }));

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row.name,
      renderCell: ({ row }) => <MyTooltip>{row.name}</MyTooltip>,
    },
    {
      field: "date",
      headerName: "Date exam",
      minWidth: 110,
      flex: 0.5,
      valueGetter: ({ row }) => row.date,
    },
    {
      field: "link_exam",
      headerName: "Link exam",
      minWidth: 220,
      flex: 1,
      valueGetter: ({ row }) => row.link_exam,
      renderCell: ({ row }) => <MyTooltip>{row.link_exam}</MyTooltip>,
    },
  ];

  return (
    <EduModal
      open={Boolean(dataEmail?.open)}
      onClose={onClose}
      fullWidth
      maxWidth={Boolean(dataEmail?.users?.length) ? false : "md"}
    >
      <EduModalTitle>Search data email</EduModalTitle>
      <EduModalBody>
        <Box className={"flex p-8 gap-4 " + (isMobile ? "flex-col" : "")}>
          <TextField
            error={Boolean(dataEmail?.errors?.email)}
            required
            label={dataEmail?.errors?.email || "Search to email"}
            value={dataEmail?.email ?? ""}
            placeholder="example@gmail.com"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDataEmail((p: INEmail) => ({ ...p, email: e.target.value }))
            }
            fullWidth
          />

          <TextField
            error={Boolean(dataEmail?.errors?.subject)}
            required
            label={dataEmail?.errors?.subject || "Search to subject"}
            value={dataEmail?.subject ?? ""}
            placeholder="RE: IELTS richiesta link"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDataEmail((p: INEmail) => ({ ...p, subject: e.target.value }))
            }
            fullWidth
          />
        </Box>

        {dataEmail?.users?.length ? (
          <Box className="px-8 overflow-auto w-full h-full bg-edu-800">
            <DataGrid
              sx={getTableStyling(theme)}
              rows={dataEmail?.users || []}
              columns={columns}
              loading={false}
              density="comfortable"
              pageSize={20}
              autoHeight={isMobile}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              rowsPerPageOptions={[20]}
              experimentalFeatures={{ newEditingApi: false }}
              disableColumnMenu
              disableVirtualization
              disableSelectionOnClick
            />
          </Box>
        ) : null}

        <Box className="px-8 py-6 bg-edu-800 flex justify-center gap-4">
          <Button
            sx={{ px: 4 }}
            variant="outlined"
            color="primary"
            disabled={
              (dataEmail?.email?.length < 10 &&
                dataEmail?.subject?.length < 5) ||
              dataEmail?.loadingSend
            }
            onClick={() =>
              Boolean(dataEmail?.users?.length)
                ? setDataEmail((p: INEmail) => ({ ...p, openConfirm: true }))
                : onSearchEmail()
            }
          >
            {dataEmail?.loadingSend ? (
              <RefreshIcon />
            ) : Boolean(dataEmail?.users?.length) ? (
              "Save"
            ) : (
              "Search"
            )}
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};
