import { links } from "@/configs";
import { Alert, PaletteMode, Snackbar } from "@mui/material";
import { createContext, FC, useCallback, useMemo, useState } from "react";

interface Props {
  children: JSX.Element;
}

export interface UserAdmin {
  user: User;
  token: string;
  calendars: {
    admission: Array<any>;
    english: Array<any>;
    all: Array<object>;
    all_filtered: Array<string>;
  };
}

interface Routes {
  name: string;
  active: boolean;
  filter?;
}

type User = {
  id: number;
  first_name: string;
  last_name: string;
  uuid: string;
  email: string;
  admin: boolean | string;
  routes: Routes[];
  phone?: string;
  avatar?: string;
  activity?: string | null;
  role?: 'admin' | 'member' | 'manager';
};

interface ICalendars {
  ADMISSION: string;
  ENGLISH: string;
  UPSELLING: string;
  SALES: string;
}

export const CALENDARS: ICalendars = {
  ADMISSION: "admission",
  ENGLISH: "english",
  UPSELLING: "upselling",
  SALES: "all",
};

interface ITypeOf {
  ENGLISH: string;
  ADMISSION: string;
}

export const TYPEOF: ITypeOf = {
  ENGLISH: "english",
  ADMISSION: "admission",
};

const colors = {
  error: "#ef5350",
  success: "#4caf50",
};

export const AuthContext = createContext({
  userAdmin: {} as UserAdmin,
  setUserAdmin: (data: any) => { },
  snackHandler: (
    message: any,
    severity: "success" | "error" = "success"
  ) => { },
  defaultRoutes: [],
  currentTheme: "" as PaletteMode,
  setCurrentTheme: (data: PaletteMode) => { },
  currentColor: "" as string,
  setCurrentColor: (data: string) => { },
  statusSideBar: "" as string,
  setStatusSideBar: (data: string) => { },
  copyToClipboard: (data: string) => { },
  setTypeOf: (data: any) => { },
  typeOf: CALENDARS.ENGLISH as string,
  isAdmission: false,
  links: [],
  isAdmin: false,
  isMember: true,
  isManager: false,
  setRole: (data: any) => { },
  activity: null,
});

const AuthProvider: FC<Props> = ({ children }) => {
  const [userAdmin, setUserAdmin] = useState<UserAdmin>();
  const [snackType, setSnackType] = useState<string>("");
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const [currentTheme, setCurrentTheme] = useState<PaletteMode>(() => {
    const storedTheme = localStorage.getItem("custom-theme");
    return (storedTheme as PaletteMode) || "light";
  });

  const defaultRoutes = useMemo(() => links.map((link) => ({ name: link.name, active: true })), []);

  const [typeOf, setTypeOf] = useState<string>(
    localStorage.getItem("typeOf") || TYPEOF.ENGLISH
  );

  const role = useMemo(() => userAdmin?.user?.role?.toLowerCase(), [userAdmin]);
  const isAdmission = Boolean(typeOf == TYPEOF.ADMISSION);
  const isAdmin = useMemo(() => role === 'admin', [role]);
  const isMember = useMemo(() => role === 'member' || !role, [role]);
  const isManager = useMemo(() => role === 'manager', [role]);

  const activity = userAdmin?.user?.activity;

  const [currentColor, setCurrentColor] = useState<string>(
    localStorage.getItem("custom-color") || "purple"
  );

  const [statusSideBar, setStatusSideBar] = useState<string>(
    localStorage.getItem("status-sidebar") || "open"
  );

  const snackHandler = (
    message: any,
    severity: "success" | "error" = "success"
  ) => {
    setOpenSnack(true);
    setSnackBarMessage(message);
    setSnackType(severity);
  };

  const copyToClipboard = useCallback((text: string) => {
    try {
      navigator.clipboard.writeText(text)
        .then(() => snackHandler("Copied: " + text))
        .catch(err => {
          console.error('Failed to copy:', err);
          snackHandler("Copy failed", "error");
        });
    } catch (err) {
      console.error('Clipboard API error:', err);
      snackHandler("Clipboard error", "error");
    }
  }, [snackHandler]);

  const authContextValue: any = useMemo(() => ({
    userAdmin,
    setUserAdmin,
    snackHandler,
    defaultRoutes,
    setCurrentTheme,
    currentTheme,
    setCurrentColor,
    currentColor,
    statusSideBar,
    setStatusSideBar,
    copyToClipboard,
    typeOf,
    setTypeOf,
    isAdmission,
    links,
    isAdmin,
    isMember,
    isManager,
    activity,
  }), [
    userAdmin,
    setUserAdmin,
    defaultRoutes,
    currentTheme
  ]);

  const handleClose = () => {
    setOpenSnack(false);
    setSnackBarMessage("");
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      <>
        {children}

        {Boolean(snackType && ["success", "error"]?.includes(snackType)) && (
          <Snackbar
            open={openSnack}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={4000}
            onClose={handleClose}
            sx={{
              ".MuiAlert-root": {
                height: "40px",
                padding: "0px 16px",
                marginRight: "12px",
              },
              ".MuiSnackbar-root": {
                right: "40px",
              },
            }}
          >
            <Alert
              variant="outlined"
              severity={snackType as any}
              sx={{
                borderRadius: "8px",
                color: colors[snackType],
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Prefisso per WebKit (Chrome, Safari)
                MozBackdropFilter: "blur(6px)", // Prefisso per Mozilla Firefox
                OBackdropFilter: "blur(6px)",
              }}
            >
              {snackBarMessage}
            </Alert>
          </Snackbar>
        )}
      </>
    </AuthContext.Provider>
  );
};

export { AuthProvider };

