import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { CustomToolbar } from "./Toolbar";
import { Box, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import PopoverCallCenter from "./components/PopoverCallCenter";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const CallCenterKPI = () => {
  const theme = useTheme();
  const { screen, perPage } = useScreen();
  const { isAdmission, isMember, userAdmin } = useAuth();

  const KEY_QUERY: Array<any> = [
    "interview-performance",
    "kpis-call-center",
    isAdmission,
  ];

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [others, setOthers] = useState<any>({});
  const [ownedBy, setOwnedBy] = useState<Array<string>>([]);
  const [columnsHeaders, setColumnsHeaders] = useState({});

  delete filterData?.page;
  KEY_QUERY.push(filterData);
  let params: any = {};

  if (filterData?.dates) {
    const date = filterData?.dates.split(",");

    const startDate = format(new Date(date[0]), "yyyy-MM-dd HH:mm:ss");
    const endDate = format(new Date(date[1]), "yyyy-MM-dd HH:mm:ss");

    params = { ...params, startDate, endDate };

    KEY_QUERY.push(startDate);
    KEY_QUERY.push(endDate);
  }

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () =>
      useAPI("/v2/interview-performance/kpis-call-center", {
        ...params,
        ...(filterData ?? {}),
        ...(isMember
          ? {
            admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
          }
          : {}),
        isAdmission,
      }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const renderHeader = (nameColumn: string, titleTooltip: string = "") => ({
    renderHeader: ({ field }) => (
      <Box className="MuiDataGrid-columnHeaderTitle">
        {Boolean(titleTooltip) ? (
          <>
            {!isMember && (
              <span className="number">{columnsHeaders?.[field]}</span>
            )}
            <MyTooltip title={titleTooltip}>{nameColumn}</MyTooltip>
          </>
        ) : (
          <>
            {Boolean(columnsHeaders?.[field]) && !isMember && (
              <span className="number">{columnsHeaders?.[field]}</span>
            )}
            {nameColumn}
          </>
        )}
      </Box>
    ),
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        minWidth: 180,
        flex: 1,
        headerName: "Admin",
      },
      {
        field: "total_leads",
        minWidth: 110,
        flex: 1,
        ...renderHeader("Total leads"),
      },
      {
        field: "count_delete",
        minWidth: 75,
        flex: 1,
        ...renderHeader("Deleted"),
      },
      {
        field: "bad_lead",
        minWidth: 85,
        flex: 1,
        ...renderHeader("Bad lead"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "leads",
        minWidth: 70,
        flex: 1,
        ...renderHeader("Leads"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "booked",
        minWidth: 80,
        flex: 1,
        ...renderHeader("Booked"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "auto_booked",
        minWidth: 115,
        flex: 1,
        ...renderHeader("Autobooked"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "count_meeting_done",
        minWidth: 120,
        flex: 1,
        ...renderHeader("Meeting done"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "count_meeting_done_manually",
        minWidth: 130,
        flex: 1,
        ...renderHeader("M. D. Manually", "Meeting done manually"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "meeting_done_rate",
        minWidth: 156,
        flex: 1,
        ...renderHeader("Meeting done rate"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "booked_rate",
        minWidth: 120,
        flex: 1,
        ...renderHeader("Booked rate"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "auto_booked_rate",
        minWidth: 160,
        flex: 1,
        ...renderHeader("Auto booked rate"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
      {
        field: "time_medium",
        width: 110,
        ...renderHeader("Time medium"),
        renderCell: ({ row, field }) => (
          <MyTooltip title={row["logic_" + field]}>{row[field]}</MyTooltip>
        ),
      },
    ],
    [screen, columnsHeaders]
  );

  useEffect(() => {
    if (!dataAPI?.rows) return;
    setRows(dataAPI.rows);
    setOwnedBy(dataAPI.owned_by);
    setColumnsHeaders(dataAPI.columns);
  }, [dataAPI, isLoading]);

  return (
    <BasePage title={"KPIs Call Center" + (isAdmission ? " admission" : "")}>
      <DataGrid
        hideFooter
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={(row: any) => row?.name}
        sortModel={sortModel}
        autoHeight={true}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPaginationClient,
        }}
        initialState={{
          sorting: { sortModel: [{ field: "meeting_done", sort: "desc" }] },
        }}
        componentsProps={{
          toolbar: {
            ownedBy,
            isMember,
            setFilters,
            meetingCalendar: ownedBy,
            title: "Creation date",
            filters: filterData,
            fileName: "KPIs Call Center",
            KEY_QUERY: KEY_QUERY,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      {others?.element && (
        <PopoverCallCenter
          theme={theme}
          others={others}
          setOthers={setOthers}
        />
      )}
    </BasePage>
  );
};

export default CallCenterKPI;
