import { useState, useEffect, ChangeEvent } from "react";
import { useAssignment } from "@/pages/Exercises/ExercisesV3/hooks/useAssignment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useExerciseContext } from "@/pages/Exercises/ExercisesV3/state/ExerciseContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Box,
  Tab,
  Tabs,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import client from "@/api/client";
import ConfirmDialog from "@/pages/Exercises/ExercisesV1/components/ConfirmDialog";
import AutoCompleteExercisesInput from "@/pages/Exercises/ExercisesV1/components/AutoCompleteExercisesInput";

export default () => {
  const { queryResult } = useExerciseContext();
  const {
    date,
    setDate,
    students,
    setStudents,
    exercises,
    setExercises,
    openConfirm,
    setOpenConfirm,
    assignmentState,
    handleSubmit,
    typeToggle,
    setTypeToggle,
  } = useAssignment(queryResult);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [allExercises, setAllExercises] = useState([]);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: "old" | "new"
  ) => {
    setTypeToggle(newValue);
    // Reset exercises when tab changes
    setExercises([]);
  };

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        setLoading(true);
        const response = await client.exercises.getExercises({
          perPage: 99999,
        });

        // Filter exercises based on current tab
        const filteredExercises = response.data.filter((exercise) =>
          typeToggle === "old"
            ? exercise.exercise_type === "old"
            : exercise.exercise_type === "new"
        );

        setAllExercises(filteredExercises);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch exercises");
        setLoading(false);
      }
    };

    fetchExercises();
  }, [typeToggle]);

  return (
    <>
      <Box className="w-full flex flex-col gap-5">
        <Tabs
          value={typeToggle}
          onChange={handleTabChange}
          centered
        >
          <Tab
            label="Assign Old Exercise"
            value="old"
          />

          <Tab
            label="Assign New Exercise"
            value="new"
          />
        </Tabs>

        {Boolean(error) && (
          <Typography
            variant="h6"
            className="text-center w-full text-[#be3d3d]"
          >
            {error}
          </Typography>
        )}

        {/* // DATE */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={date}
            views={["day"]}
            openTo="day"
            onChange={(newValue) => setDate(newValue)}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Date"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>

        {/* // STUDENTS */}
        <TextField
          required
          fullWidth
          label="Students"
          variant="outlined"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setStudents(e.target.value)
          }
          value={students ?? ""}
          placeholder="example@gmail.com"
          InputLabelProps={{ shrink: true }}
        />

        {/* // EXERCISES */}
        {loading ? (
          <CircularProgress />
        ) : (
          <AutoCompleteExercisesInput
            multiple
            closeSelect
            optionsLabel
            data={allExercises}
            label={typeToggle === "old" ? "Old Exercises" : "New Exercises"}
            value={exercises ?? []}
            setValue={setExercises}
          />
        )}

        <Box className="flex justify-end">
          <Button
            variant="outlined"
            onClick={() => setOpenConfirm(true)}
            disabled={
              allExercises.length === 0 ||
              exercises?.length === 0 ||
              assignmentState.isLoading ||
              !students
            }
            className="block"
          >
            {assignmentState.isLoading ? (
              <CircularProgress
                size={26}
                color="primary"
              />
            ) : (
              "Assign"
            )}
          </Button>
        </Box>
      </Box>

      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        clickYes={handleSubmit}
      >
        Do you want to assign these exercises?
      </ConfirmDialog>
    </>
  );
};
