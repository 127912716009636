import { memo } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import cn from "classnames";
import Dropdown from "./_Common/Dropdown";

export interface IItemObject {
  label: string;
  value: string | number;
}

interface IProps {
  label: string;
  items: Array<string | number | IItemObject>;
  value: Array<string | number>;
  onChange: (event: any, newValue: Array<string | number>) => void;
  className?: string;
  itemsParams?: { className?: string };
}

const strOrNum = (v: string | number) => (Number.isNaN(v) ? Number(v) : v);

const SelectMultipleCheckbox = ({
  label,
  items,
  value,
  onChange,
  className,
  itemsParams = {},
}: IProps) => {
  const handleChange = (e: any) => {
    const valueE: string | number = strOrNum(e.target.value);
    const checked: boolean = e.target.checked;

    const newValue = [...value];

    if (checked && !newValue.includes(valueE)) {
      newValue.push(valueE);
    } else if (!checked && newValue.includes(valueE)) {
      const index = newValue.indexOf(valueE);
      if (index > -1) {
        newValue.splice(index, 1);
      }
    }

    onChange(e, newValue);
  };

  return (
    <Dropdown
      text={label}
      active={Boolean(value?.length)}
      className={cn(
        "flex flex-row-reverse !justify-between !pl-[0.9rem] !pr-2 !py-2 !text-base",
        className
      )}
      iconComponent={<ArrowDropDown />}
    >
      <FormGroup
        className={cn("px-4 py-2 -mr-[1.9rem]", itemsParams?.className)}
      >
        {items?.map((item: string | number | IItemObject, i: number) => {
          const [l, v] =
            typeof item != "string" && typeof item != "number"
              ? [item?.label, item?.value]
              : [item, item];

          return (
            <FormControlLabel
              key={String(item) + i}
              value={v}
              label={l}
              control={<Checkbox checked={value?.includes(strOrNum(v))} />}
              onChange={handleChange}
              className="hover:bg-gray-200/10 pr-4"
            />
          );
        })}
      </FormGroup>
    </Dropdown>
  );
};

export default memo(SelectMultipleCheckbox);
