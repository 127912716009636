//@ts-nocheck
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  PaperProps,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import SaveIcon from "@mui/icons-material/Save";
import { getChangelogs, newChangelog } from "../api";
import { queryClient } from "../main";
import { set } from "date-fns";
import useAuth from "@/hooks/useAuth";
const options = {
  timeZone: "Europe/Rome",
  weekday: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const ListItems = ({ data }) => {
  return data.map(
    (item: {
      created_at: any;
      id: Key | null | undefined;
      tag:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
      description:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    }) => {
      return (
        <li
          key={item.id}
          className="flex items-start space-x-3 text-edu-green my-5 p-6 bg-edu-800 rounded-xl"
        >
          {/* <a rel="noopener noreferrer" href="#" className="flex items-center h-8 text-sm hover:underline">v3.2.0</a> */}
          <div className="flex-1 space-y-2">
            <div className="flex items-center justify-between space-x-4 dark:text-gray-400">
              <div className="inline-flex items-center px-3 py-1 my-1 space-x-2 text-sm border rounded-full group dark:border-gray-700">
                <span
                  aria-hidden="true"
                  className="h-1.5 w-1.5 rounded-full bg-edu-green"
                ></span>
                <span className="group-hover:cursor-pointer dark:text-gray-100">
                  {item.tag}
                </span>
              </div>
              <span className="text-xs whitespace-nowrap">
                {new Date(item.created_at).toLocaleString("en-EN", options)}
              </span>
            </div>
            <div>
              <p>{item.description}</p>
            </div>
          </div>
        </li>
      );
    }
  );
};

const Changelog = () => {
  const [open, setOpen] = React.useState(false);
  const tag = React.createRef();
  const description = React.createRef();
  const { userAdmin } = useAuth();
  const { data: changelogs, isLoading } = useQuery(["changelogs"], () =>
    getChangelogs({ tk: userAdmin.token })
  );
  const addLog = useMutation(newChangelog, {
    onSuccess: (update) => {
      let old = queryClient.getQueryData(["changelogs"]);
      let newData = [...old, update];

      newData = newData.sort((a, b) =>
        b.created_at.localeCompare(a.created_at)
      );

      setOpen(false);

      queryClient.setQueryData(["changelogs"], newData);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    addLog.mutate({
      tk: userAdmin.token,
      dataLog: {
        tag: tag.current.value,
        description: description.current.value,
      },
    });
  };

  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      <div className="max-w-5xl p-8 mx-auto">
        <Toolbar />
        <Button
          variant="outlined"
          onClick={handleClickOpen}
        >
          add
        </Button>
        <ul className="space-y-12">
          <ListItems data={changelogs} />
        </ul>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent
          className="bg-edu-800"
          sx={{
            width: "600px",
          }}
        >
          <DialogContentText>
            <div className="flex flex-wrap -m-2 gap-5 p-5">
              <div className="w-full">
                <div className="relative">
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      className=" !text-edu-green"
                    >
                      Tag
                    </InputLabel>
                    <Select
                      className="w-full"
                      name="first_name"
                      inputRef={tag}
                      InputLabelProps={{
                        style: { color: "rgb(16, 185, 129)" },
                      }}
                      required
                      id="outlined-required"
                      label="Tag"
                      defaultValue="feature"
                    >
                      <MenuItem value="feature">feature</MenuItem>
                      <MenuItem value="fix">fix</MenuItem>
                      <MenuItem value="improvement">improvement</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-full">
                <div className="relative">
                  <TextField
                    className="w-full"
                    name="first_name"
                    inputRef={description}
                    InputLabelProps={{
                      style: { color: "rgb(16, 185, 129)" },
                    }}
                    required
                    id="outlined-required"
                    label="Description"
                    multiline
                    rows={4}
                  />
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="bg-edu-800">
          <Button
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={addLog.isLoading}
            // loadingPosition="start"
            startIcon={<SaveIcon />}
            // variant="outlined"
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
          {/* <Button>Save { ? 'OOOOK' : null}</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Changelog;
