import useAuth from "@/hooks/useAuth";
import { TYPEOF } from "@/providers/AuthProvider";
import { Box, Popover, Switch } from "@mui/material";

interface IProps {
  anchorEl: HTMLElement | null;
  onClose: (...params: any) => void;
}

const PopoverChangeTypeOF = ({ anchorEl, onClose }: IProps) => {
  const { typeOf, setTypeOf, setCurrentColor } = useAuth();

  const handleSwitch = ({ target: { checked } }) => {
    const value = checked ? TYPEOF.ADMISSION : TYPEOF.ENGLISH;
    setTypeOf(value);
    localStorage.setItem("typeOf", value);
    changeTheme(value);
    onClose();
  };

  const changeTheme = (typeOf: string) => {
    let color = typeOf === TYPEOF.ADMISSION ? "cyan" : "purple";
    localStorage.setItem("custom-color", color);
    setCurrentColor(color);
  };

  return anchorEl ? (
    <Popover
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      className="ml-2"
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <Box className="flex gap-2 items-center py-2 px-4">
        <Box>ENGLISH</Box>

        <Switch
          checked={typeOf === TYPEOF.ADMISSION}
          onChange={handleSwitch}
          inputProps={{ "aria-label": "ant design" }}
        />

        <Box>ADMISSION</Box>
      </Box>
    </Popover>
  ) : null;
};

export default PopoverChangeTypeOF;
