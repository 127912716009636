import { Box, Drawer } from "@mui/material";
import { IContextCrm } from "../interfaces";
import CalendarEvents from "@/components/CalendarEvents";

interface IProps {
  status: "Colloquio fissato" | "Da richiamare";
  context: IContextCrm;
  onUpdateRow: (status: "Colloquio fissato" | "Da richiamare") => void;
}

export default ({ context, onUpdateRow, status }: IProps) => {
  return (
    <Drawer
      sx={{ zIndex: 1300 }}
      open={Boolean(context.dialog?.component)}
      anchor="right"
      onClose={() => context.setDialog(undefined)}
    >
      {Boolean(context.dialog?.row ?? context.rowSelected) ? (
        <CalendarEvents
          user={context.dialog?.row ?? context.rowSelected}
          status={status}
          onUpdateRow={() => onUpdateRow(status)}
        />
      ) : (
        <Box className="p-4">No user selected!</Box>
      )}
    </Drawer>
  );
};
