import { Dispatch, SetStateAction, useState } from "react";
import { format } from "date-fns";
import { queryClient } from "@/main";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import SelectMultipleCheckbox, {
  IItemObject,
} from "@/components/SelectMultipleCheckbox";
import Refresh from "@/assets/icons/refresh.svg";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import MyTooltip from "@/components/MyTooltip";
import filterIcon from "@/assets/icons/filter.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

interface IProps {
  filters: any;
  keyQuery: Array<any>;
  setFilters: Dispatch<SetStateAction<any>>;
  productsAPI: any;
  startMonths: Array<string>;
  selectExport?: { rows: Array<string>; columns: Array<string> };
  onDownloadTable: () => void;
  onDownloadUsers: (selectRows: Array<any>, selectColumns: Array<any>) => void;
  monthActiveDefault: string;
  onDownloadUsersFilterCreatedAt: () => void;
}

const Toolbar = ({
  filters,
  keyQuery,
  setFilters,
  productsAPI,
  startMonths,
  selectExport,
  onDownloadTable,
  onDownloadUsers,
  monthActiveDefault,
  onDownloadUsersFilterCreatedAt,
}: IProps) => {
  const [selectRows, setSelectRows] = useState<Array<any>>([]);
  const [selectColumns, setSelectColumns] = useState<Array<any>>([]);

  return (
    <Box
      id="filter_bar"
      className="flex !items-stretch gap-4"
    >
      <Button
        variant="outlined"
        onClick={() => {
          setFilters({});
          queryClient.invalidateQueries(keyQuery);
        }}
      >
        <img
          src={Refresh}
          className="icon"
        />
        Refresh
      </Button>

      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={Boolean(
          filters?.lang ||
            filters?.productToken ||
            filters?.englishLevel ||
            filters?.startMonth
        )}
      >
        <Box className="flex flex-col w-[250px] gap-4 p-4">
          <TextField
            select
            fullWidth
            name="lang"
            label="Lang"
            value={filters?.lang || ""}
            onChange={(e) =>
              setFilters((p: any) => {
                p.set("lang", e.target.value);
                return p;
              })
            }
          >
            {LANGUAGES?.map((l) => (
              <MenuItem
                key={l.iso}
                value={l.iso}
              >
                {l.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Product"
            value={filters?.productToken || ""}
            onChange={(e) =>
              setFilters((p: any) => {
                p.set("productToken", e.target.value);
                return p;
              })
            }
          >
            {productsAPI?.products?.map((product: any) => (
              <MenuItem
                key={product.id}
                value={product.token}
              >
                <MyTooltip title={product.product_tag}>
                  <Box className="w-full flex justify-between">
                    <Box className="truncate mr-2">{product.product_tag}</Box>
                    <Box>{"€" + (product?.price / 100).toFixed(2)}</Box>
                  </Box>
                </MyTooltip>
              </MenuItem>
            ))}
          </TextField>

          <SelectMultipleCheckbox
            label="English level"
            value={filters?.englishLevel?.split(",") ?? []}
            items={
              [
                { label: "Blank", value: "null" },
                ...(ENGLISH_LEVELS as Array<string>),
              ] as Array<string | IItemObject>
            }
            onChange={(_, value) =>
              setFilters((p: any) => {
                if (value?.length) {
                  p.set("englishLevel", value.join(","));
                } else {
                  p.delete("englishLevel");
                }
                return p;
              })
            }
          />

          <TextField
            select
            fullWidth
            label="Start month"
            value={filters?.startMonth || ""}
            onChange={(e) =>
              setFilters((p: any) => {
                p.set("startMonth", e.target.value);
                return p;
              })
            }
          >
            {startMonths?.map((s: string) => (
              <MenuItem
                key={s}
                value={s}
                className={s == monthActiveDefault ? "opacity-60" : ""}
              >
                {s}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            type="date"
            name="availailities_after"
            label="Availabilites after"
            value={filters?.availailities_after ?? ""}
            onChange={({ target: { name, value } }) =>
              setFilters((p: any) => {
                if (value) {
                  p.set(name, value);
                } else {
                  p.delete(name);
                }
                return p;
              })
            }
            InputLabelProps={{ shrink: true }}
          />

          <DateRangePickerField
            title="Creation date"
            value={
              filters?.created_availabilities
                ? filters?.created_availabilities
                    .split(",")
                    .map((c: any) => new Date(c))
                : null
            }
            onChange={(value) => {
              setFilters((p: any) => {
                if (value) {
                  p.set(
                    "created_availabilities",
                    [
                      format(value?.startDate, "yyyy-MM-dd HH:mm:ss"),
                      format(value?.endDate, "yyyy-MM-dd HH:mm:ss"),
                    ].join(",")
                  );
                  p.set("page", "1");
                } else p.delete("created_availabilities");
                return p;
              });
            }}
          />
        </Box>
      </Dropdown>

      <Dropdown
        text="Download"
        iconComponent={
          <img
            src={downloadIcon}
            className="icon"
          />
        }
      >
        <Box className="flex flex-col gap-4 p-4">
          <Button
            variant="outlined"
            onClick={onDownloadTable}
          >
            Download table
          </Button>

          <Dropdown text="Download users">
            <Box className="!p-2 flex flex-col gap-2">
              <SelectMultipleCheckbox
                label="Rows"
                items={selectExport?.rows ?? []}
                value={selectRows}
                onChange={(_, value) => setSelectRows(value)}
              />

              <SelectMultipleCheckbox
                label="Columns"
                items={selectExport?.columns ?? []}
                value={selectColumns}
                onChange={(_, value) => setSelectColumns(value)}
              />

              <Button
                variant="outlined"
                onClick={() => onDownloadUsers(selectRows, selectColumns)}
              >
                Download
              </Button>
            </Box>
          </Dropdown>

          {Boolean(filters?.created_availabilities) && (
            <Button
              variant="outlined"
              onClick={onDownloadUsersFilterCreatedAt}
            >
              Download delay
            </Button>
          )}
        </Box>
      </Dropdown>

      <SearchToolbar {...{ filters, setFilters }} />
    </Box>
  );
};

export default Toolbar;
