import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTableStyling } from "@/providers/Colors";
import { useTheme, Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import TypesCell from "./components/TypesCell";
import ActionMenu from "./components/ActionMenu"; // Ensure correct import path
import LanguagesCell from "./components/LanguagesCell";
import FilterComponent from "./components/Filter";
import CustomPagination from "@/components/_Common/CustomPagination";
import AddCallCenterDialog from "./components/AddCallCenterDialog";

interface CallCenterData {
  id: number;
  name: string;
  email: string;
  languages: string[];
  type: string[];
}

const languageMap = {
  en: "English",
  it: "Italian",
  es: "Spanish",
  fr: "French",
  de: "German",
  pt: "Portuguese",
};

const CallCenterTable: React.FC = () => {
  const { perPage } = useScreen();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(perPage);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "created_at", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState({ search: "" });
  const [open, setOpen] = useState(false);

  const fetchCallCenters = async ({ queryKey }) => {
    const [_key, params] = queryKey;
    const sorts = sortModel.reduce(
      (acc, { field, sort }) => ({ ...acc, [field]: sort }),
      {}
    );
    const response = await useAPI(
      "v1/call-center/list",
      { ...params, sorts: JSON.stringify(sorts) },
      { type: "post" }
    );
    return response.data;
  };

  const { data, isLoading, isError, refetch } = useQuery(
    ["callCenter", { page: page + 1, per_page: pageSize, ...filterModel }],
    fetchCallCenters,
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (data && data.total > 0 && data.data.length === 0) {
      setPage((prev) => Math.max(prev - 1, 0));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [sortModel]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1, disableColumnMenu: true },
    { field: "email", headerName: "Email", flex: 1, disableColumnMenu: true },
    {
      field: "languages",
      headerName: "Languages",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <LanguagesCell
          languages={params.value}
          adminId={params.row.id}
        />
      ),
    },
    {
      field: "type",
      headerName: "Types",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <TypesCell
          types={params.value}
          adminId={params.row.id}
        />
      ), // Add this line
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      disableColumnMenu: true,
      renderCell: (params) => <ActionMenu adminId={params.row.id} />,
    },
  ];

  return (
    <BasePage title="Call Center">
      <Box className="flex gap-4 justify-between !items-stretch">
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          <img
            src={addIcon}
            className="icon"
          />
          Add Call Center
        </Button>

        <FilterComponent setFilterModel={setFilterModel} />
      </Box>

      <DataGrid
        autoHeight
        sx={getTableStyling(theme)}
        rows={data || []}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        rowCount={data?.total || 0}
        pageSize={pageSize}
        rowsPerPageOptions={[perPage]}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading}
        components={{
          Pagination: () => (
            <CustomPagination
              hideTotal
              page={page - 1}
              count={data?.total || 0}
              rowsPerPage={pageSize}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
      />

      <AddCallCenterDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </BasePage>
  );
};

export default CallCenterTable;
