import { Box, Button } from "@mui/material";
import { DownloadIcon } from "@/components";
import * as XLSX from "xlsx";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";

const handleExportCsv = (data: object) => {
  const headers = ["Date", "Student", "Rating", "Comments", "Average rating", "Average rating excluding worst two feedbacks"];

  const workbook = XLSX.utils.book_new();

  // Sort tutors alphabetically
  const sortedData = Object.entries(data).sort(([tutorA], [tutorB]) => tutorA.localeCompare(tutorB));

  // Create sheets for each tutor
  sortedData.forEach(([tutor, feedbacks]) => {
    // Flatten the feedbacks array
    const flattenedFeedbacks = feedbacks as unknown as Array<any>;

    flattenedFeedbacks.sort(([dateA, studentA, ratingA], [dateB, studentB, ratingB]) => {
      const sortableDateA = dateA.split("/").reverse().join("/");
      const sortableDateB = dateB.split("/").reverse().join("/");
      if (ratingA === ratingB) {
        return sortableDateB.localeCompare(sortableDateA); // Sort by date in descending order
      }
      return ratingB - ratingA; // Sort by rating in descending order if dates are the same
    });

    // Calculate average rating
    const avgRating = flattenedFeedbacks.reduce((sum, feedback) => sum + feedback[2], 0) / flattenedFeedbacks.length;
    // const avgRatingTop = flattenedFeedbacks
    //   .filter((feedback, index) => feedback[2] > 2 && index < flattenedFeedbacks.length - 2)
    //   .reduce((sum, feedback) => sum + feedback[2], 0) / flattenedFeedbacks.filter((feedback) => feedback[2] > 2).length;

    const feedbacksWithinRange = flattenedFeedbacks.filter((feedback) => feedback[2] <= 2);

    let excludedCount = Math.min(feedbacksWithinRange.length, 2);
    let excludedCountLength = Math.min(feedbacksWithinRange.length, 2);
    const avgRatingTop =
      flattenedFeedbacks
        .map((feedback) => feedback[2])
        .sort((a, b) => a - b)
        .filter((rating) => {
          if (rating > 2) {
            return true;
          } else if (rating <= 2 && excludedCount > 0) {
            excludedCount--;
            return false;
          }
          return true;
        })
        .reduce((sum, rating) => sum + rating, 0) /
      (flattenedFeedbacks.length - excludedCountLength);

    // Create a new worksheet
    const worksheetData = [headers, ...flattenedFeedbacks.map((feedback) => [...feedback])];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Add the average ratings to the first row after headers
    const avgRatingCell = XLSX.utils.encode_cell({ r: 1, c: 4 }); // Adjust column index (0-based)
    worksheet[avgRatingCell] = { t: "n", v: avgRating };
    const avgRatingTopCell = XLSX.utils.encode_cell({ r: 1, c: 5 }); // Adjust column index (0-based)
    worksheet[avgRatingTopCell] = { t: "n", v: avgRatingTop };

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, tutor);
  });

  // Save the workbook as an Excel file
  XLSX.writeFile(workbook, "tutors-feedback.xlsx");
};

const ToolbarTutors = ({ rangeDate, setRangeDate, data }) => (
  <Box className="flex gap-4" id="filter_bar">
    <CustomDateRangePicker value={rangeDate} onChange={(date) => (date ? setRangeDate([date?.startDate, date?.endDate]) : setRangeDate([]))} />

    <Button color={"primary"} sx={{ p: "1rem" }} variant="outlined" onClick={() => handleExportCsv(data)}>
      <DownloadIcon color={"primary"} />
    </Button>

    <SearchToolbar />
  </Box>
);

export default ToolbarTutors;
