import { memo } from "react";
import { format } from "date-fns";
import { Box, Button } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import ButtonCheck, { TColorBorder } from "./ButtonCheck";
import dayjs from "dayjs";
import MONTHS from "@/components/assets/MONTHS";
import useGetElapsedTime from "@/components/useGetElapsedTime";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface IDayWeek {
  day: number;
  date: Date;
  weekDay: number;
}

interface IColorsItem {
  color: string;
  bgColor: string;
}

interface IColors {
  GREEN: IColorsItem;
  ORANGE: IColorsItem;
  RED: IColorsItem;
}

const COLORS: IColors = {
  GREEN: {
    color: "#2D224C",
    bgColor: "#74DFAC",
  },
  ORANGE: {
    color: "#2D224C",
    bgColor: "#FFAA05",
  },
  RED: {
    color: "white",
    bgColor: "#660000",
  },
};

const getColor = (key: string): TColorBorder => {
  switch (key) {
    case "IELTS":
      return "yellow";
    case "Speaking":
      return "blue";
    case "Grammar":
      return "violet";
    case "Correction":
      return "orange";
    case "One to One":
      return "red";
    default:
      return "";
  }
};

export const days = [
  {
    id: 0,
    name: "MON",
  },
  {
    id: 1,
    name: "TUE",
  },
  {
    id: 2,
    name: "WED",
  },
  {
    id: 3,
    name: "THU",
  },
  {
    id: 4,
    name: "FRI",
  },
  {
    id: 5,
    name: "SAT",
  },
  {
    id: -1,
    name: "SUN",
  },
];

export const generateColumns = ({ days, onPopper }): GridColDef[] =>
  days.map(({ name }) => ({
    field: name,
    headerName: name.toUpperCase(),
    flex: 1,
    hideable: false,
    sortable: false,
    editable: false,
    filterable: false,
    disableExport: true,
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <Box className="w-full">
        {row[name]?.day && (
          <Box className="flex items-center gap-1 relative">
            <Box className="w-[42px] h-[42px] bg-white rounded text-black flex justify-center items-center font-bold">
              {row[name]?.day}
            </Box>

            <Box className="w-full h-full absolute top-[-10px] right-[10px] grid grid-flow-col grid-cols-2 grid-rows-3 gap-y-5 gap-x-6 opacity-80">
              {row[name]?.dataPopper?.map((e: any, i: number) => {
                let elapseTime: string = "";
                let time = 0;

                if (e.access) {
                  elapseTime = useGetElapsedTime(
                    new Date(e?.start),
                    new Date(e?.access),
                    {
                      h: { text: " " },
                      m: { text: "" },
                      s: { hide: true },
                    }
                  );

                  if (elapseTime && elapseTime.includes(" ")) {
                    time = parseInt(elapseTime.split(" ")[1]);
                  }

                  time = parseInt(elapseTime);
                }

                return (
                  <Box key={i}>
                    {e?.countPresence ? (
                      <ButtonCheck
                        color={
                          time > 5 ? COLORS.ORANGE.color : COLORS.GREEN.color
                        }
                        bgColor={
                          time > 5
                            ? COLORS.ORANGE.bgColor
                            : COLORS.GREEN.bgColor
                        }
                        borderColor={getColor(e.type)}
                        onClick={(ev) => onPopper(ev, e)}
                      >
                        {time > 5 ? `+${time}` : e?.access ? "OK" : ""}
                      </ButtonCheck>
                    ) : (
                      <ButtonCheck
                        color={COLORS.RED.color}
                        bgColor={COLORS.RED.bgColor}
                        onClick={(ev) => onPopper(ev, e)}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    ),
  }));

interface IGenerateRows {
  events: Array<any>;
  calendars: Array<Array<IDayWeek | null>>;
}

export const generateRows = ({
  events,
  calendars,
}: IGenerateRows): Array<any> =>
  calendars.map((week, index: number) => {
    const row: any = {
      id: index,
      typesCount: {},
    };

    week?.forEach((day) => {
      if (day) {
        const dayDate = dayjs(day?.date);

        let countPresences = 0;

        const dataPopper: Array<any> = [];

        events?.forEach((event: any) => {
          if (dayDate.isSame(event?.data, "day")) {
            const isPresent =
              Boolean(event?.pivot?.access_date) ||
              Boolean(Number(event?.pivot?.is_logged_in));

            countPresences += Number(isPresent);

            if (isPresent && event?.type) {
              if (!row.typesCount?.[event?.type]) {
                row.typesCount[event?.type] = 0;
              }

              row.typesCount[event?.type]++;
            }

            dataPopper.push({
              id: index,
              time: event?.ora,
              type: event?.type,
              tutor:
                (event?.tutors?.first_name || "") +
                " " +
                (event?.tutors?.last_name || ""),
              start: `${event?.data} ${event?.ora}`,
              access: event?.pivot?.access_date,
              id_event: event?.id,
              nameEvent: event?.titolo,
              countPresence: Number(isPresent),
            });
          }
        });

        const newDay = days.find(({ id }) => id === dayDate.day() - 1);

        if (newDay?.name) {
          row[newDay.name] = {
            day: day?.day,
            countPresences,
            dataPopper,
          };
        }
      }
    });

    return row;
  });

export const generateCalendar = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const lastDay = new Date(year, month + 1, 0);
  const firstDay = new Date(year, month, 1);
  const totalDays = lastDay.getDate();
  const firstWeek: Array<IDayWeek | null> = [];
  const calendarRows: Array<Array<IDayWeek | null>> = [];

  let first = 0;
  if (firstDay.getDay() === 0) first = -7;

  for (let i = firstDay.getDay() - 1; i > first; i--) {
    firstWeek.unshift(null);
  }

  firstWeek.push({
    date: firstDay,
    day: firstDay.getDate(),
    weekDay: firstDay.getDay(),
  });

  calendarRows.push(firstWeek);

  for (let i = 1; i < totalDays; i++) {
    const currentDate = new Date(year, month, i + 1);
    const weekDay = currentDate.getDay();

    if (calendarRows?.at(-1)?.length === 7) {
      calendarRows.push([]);
    }

    calendarRows?.at(-1)?.push({
      date: currentDate,
      day: currentDate.getDate(),
      weekDay,
    });
  }

  const lastWeek = calendarRows?.at(-1);

  for (let i = (lastWeek as Array<any>).length; i < 7; i++) {
    lastWeek?.push(null);
  }

  return calendarRows;
};

interface ICustomToolbar {
  date: string;
  typesCount: any;
  onLessIndex: () => void;
  onMoreIndex: () => void;
}

export const CustomToolbar = memo(
  ({ date, typesCount, onLessIndex, onMoreIndex }: ICustomToolbar) => {
    const DATE = new Date(date);
    const MMMM = Object.values(MONTHS)[DATE.getMonth()];
    const yyyy = DATE.getFullYear();

    return (
      <>
        <GridToolbarContainer
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={onMoreIndex}
            variant="text"
            sx={{ background: "none" }}
          >
            <KeyboardArrowLeftIcon />
          </Button>

          <Box
            style={{ width: "9rem", textAlign: "center", fontWeight: "bold" }}
          >
            {MMMM} {yyyy}
          </Box>

          <Button
            onClick={onLessIndex}
            sx={{ background: "none" }}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </GridToolbarContainer>

        <Box className="flex gap-4 py-1">
          {Object.entries(typesCount).map(([key, value]: Array<any>, i) => (
            <Box
              key={key + value + i}
              className="border-b-2"
              sx={{ borderColor: getColor(key) }}
            >
              {key}: {value}
            </Box>
          ))}
        </Box>
      </>
    );
  }
);

export const generateDates = (number: number, date?: string): Array<string> => {
  const formatDate = "yyyy-MM-dd";
  const startDate = date ? new Date(date) : new Date();

  if (startDate.getDate() >= 28) startDate.setDate(1);

  const newDates: Array<any> = [];

  newDates.push(format(startDate, formatDate));

  for (let i = 0; i < number; i++) {
    startDate.setMonth(startDate.getMonth() - 1);
    newDates.push(format(startDate, formatDate));
  }

  return newDates;
};
