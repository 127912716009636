import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

interface EventStatusProps {
  eventTime: string;
  eventStatus: string;
}

const EventDelayedStatus: React.FC<EventStatusProps> = ({ eventStatus, eventTime }) => {

  const [status, setStatus] = useState<string | null>('-');
  const [delayMinutes, setDelayMinutes] = useState<string | null>("");

  
  useEffect(() => {

    const fetchEventDelayedStatus = async () => {
        // try {
        //   const response = await useAPI(`v2/events/delay-status/${tutorToken}/${eventId}`);

        //   if (response?.eventDelayedStatus){
        //     setStatus(response?.eventDelayedStatus?.toString());
        //     setDelayMinutes(response?.eventDelayedTime);
        //   }

        // } catch (error) {
        //   console.error('Error fetching event status:', error);
        // }
        if (eventStatus !== "" && eventStatus !== null) {
          setStatus(eventStatus);
          setDelayMinutes(eventTime);
        }
    };

    fetchEventDelayedStatus();
  }, [eventStatus, eventTime]);

  const getDelayedStatus = () => {

    if (status !== null && status !== "") {
      if (delayMinutes) {
        return delayMinutes;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  return (
    <Box>
      {getDelayedStatus()}
    </Box>
  );
};

export default EventDelayedStatus;