import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import { Box } from "@mui/material";

const ExercisesToolbar = (props) => {
  return (
    <Box
      className="flex gap-4"
      id="filter_bar"
    >
      <SearchToolbar {...props} />
    </Box>
  );
};

export default ExercisesToolbar;
