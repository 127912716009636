import { useState, forwardRef, useImperativeHandle, createRef } from "react";
import useAuth from '@/hooks/useAuth';
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ContentCopy, Image, MoreVert } from "@mui/icons-material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ShowPhoto, { IRef as IRefShowPhoto } from "@/components/ShowPhoto";
import Dropdown from "@/components/_Common/Dropdown";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

export interface IRef {
  open: (tutor: any) => void;
}

interface IProps { }

const CheckTutorSetup = forwardRef<IRef, IProps>((_, ref) => {
  const theme = useTheme();
  const { copyToClipboard } = useAuth();

  const refShowPhoto = createRef<IRefShowPhoto>();

  const [tutor, setTutor] = useState<any>();

  useImperativeHandle(ref, () => ({
    open: (tutor) => {
      setTutor(tutor);
    },
  }));

  const columns: GridColDef[] = [
    {
      field: "titolo",
      headerName: "Lesson",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row?.event?.titolo || "",
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 180,
      flex: 0.5,
      valueGetter: ({ row }) => row?.event?.date || "",
    },
    {
      field: "photo_setup",
      headerName: "",
      width: 70,
      hideable: false,
      editable: false,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              color="primary"
              title="Copy photo setup"
              onClick={() => copyToClipboard(row.photo_setup)}
            >
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy photo setup</ListItemText>
            </MenuItem>

            <MenuItem
              color="primary"
              title="Show photo setup"
              onClick={() =>
                refShowPhoto?.current?.open(
                  row.photo_setup,
                  row?.event?.titolo + " - " + tutor?.fullName
                )
              }
            >
              <ListItemIcon>
                <Image fontSize="small" />
              </ListItemIcon>
              <ListItemText>Show photo setup</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <EduModal
        fullWidth
        open={Boolean(tutor)}
        onClose={() => setTutor(undefined)}
        maxWidth="md"
      >
        <EduModalTitle onClose={() => setTutor(undefined)}>
          Check tutor setup - {tutor?.fullName}
        </EduModalTitle>

        <EduModalBody className="p-4">
          <DataGrid
            autoHeight
            disableColumnMenu
            disableColumnSelector
            disableSelectionOnClick
            sx={getTableStyling(theme)}
            rows={tutor?.check_tutor_setup || []}
            columns={columns}
            pageSize={10}
            components={{ Pagination: CustomPaginationClient }}
            hideFooter={Boolean(tutor?.check_tutor_setup?.length < 10)}
            rowsPerPageOptions={[10]}
            experimentalFeatures={{ newEditingApi: false }}
          />
        </EduModalBody>
      </EduModal>

      <ShowPhoto ref={refShowPhoto} />
    </>
  );
});

export default CheckTutorSetup;
