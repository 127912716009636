import { useEffect } from "react";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import {
  useGridSelector,
  useGridApiContext,
  gridPaginationSelector,
} from "@mui/x-data-grid";
import nextIcon from "@/assets/icons/next.svg";
import backIcon from "@/assets/icons/back.svg";

interface IProps {
  customPaginationRef?: any;
}

const CustomPaginationClient = ({
  customPaginationRef = undefined,
}: IProps) => {
  const theme = useTheme();

  const apiRef = useGridApiContext();

  const pagination = useGridSelector(apiRef, gridPaginationSelector);
  const page = pagination.page;
  const count = pagination.rowCount;
  const rowsPerPage = pagination.pageSize;

  let from = rowsPerPage * page + 1;
  let to = rowsPerPage * (page + 1);

  if (to > count) {
    to = count;
  }

  const totalPage = Math.ceil(count / rowsPerPage);
  if (!totalPage || totalPage <= 1) return <></>;

  // Esporta la funzione resetPage attraverso il riferimento
  if (customPaginationRef) {
    useEffect(() => {
      if (!customPaginationRef) return;

      customPaginationRef.current = {
        resetPage: () => apiRef.current.setPage(0),
      };
    }, [customPaginationRef]);
  }

  return (
    <Grid
      container
      className="mt-5"
      alignItems="center"
    >
      <Grid item>
        <Button
          sx={{ marginRight: "10px", background: "none" }}
          variant="outlined"
          
          onClick={() => apiRef.current.setPage(page - 1)}
          disabled={!page}
        >
          <img
            src={backIcon}
            style={{ padding: "6px", filter: theme.palette.filter }}
          />
        </Button>
      </Grid>

      <Grid item>
        <Typography
          color="primary"
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            padding: "7px 30px",
            borderRadius: "8px",
          }}
          variant="caption"
          fontSize="0.875rem"
        >
          {from} - {to} of {count}
        </Typography>
      </Grid>

      <Grid item>
        <Button
          sx={{ marginLeft: "10px", background: "none" }}
          variant="outlined"
          onClick={() => apiRef.current.setPage(page + 1)}
          disabled={to >= count}
        >
          <img
            src={nextIcon}
            style={{ padding: "6px", filter: theme.palette.filter }}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomPaginationClient;