import client from "@/api/client";
import RemoveIcon from "@/assets/icons/delete.svg";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import BasePage from "@/components/Page/Base";
import PreviewArticle from "@/components/TextEditor/PreviewArticle";
import CustomPagination from "@/components/_Common/CustomPagination";
import Dropdown from "@/components/_Common/Dropdown";
import useDGS from "@/hooks/useDataGridSort";
import usePagination from "@/hooks/usePagination";
import useScreen from "@/hooks/useScreen";
import { queryClient } from "@/main";
import useAuth from '@/hooks/useAuth';
import { getTableStyling } from "@/providers/Colors";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  useTheme
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { createRef, useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';
import { MdOutlineEditNote, MdPreview, MdPublishedWithChanges } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import BlogPostDialog, { IForwardRef } from "./BlogDialog/BlogPostDialog";
import Toolbar from "./Toolbar";

const KEY_QUERY = ["general-blogs"];

export interface INEditDataCalendarID {
  token: string;
  value: string;
}

const BlogPost = () => {
  const theme = useTheme();
  const { snackHandler } = useAuth();
  const { perPage } = useScreen();
  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });
  const { page, setPage } = usePagination({ setFilters, filters });
  const { data, isLoading } = useQuery(
    [...KEY_QUERY, page, perPage, JSON.stringify(filterData)],
    () => client.blog.list({ page, per_page: perPage, ...filterData }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const blogsDialogRef = createRef<IForwardRef>()
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogTollbar, setOpenDialogTollbar] = useState<boolean>(false);
  const [deleteToken, setDeleteToken] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const triggerRemovePost = async (id) => {
    // await useAPI(`remove-post/${id}`, {}, { type: "delete", pathLiberty: true })
    await client.blog.delete(id)
    queryClient.invalidateQueries(KEY_QUERY);
    snackHandler("Post deleted");
  }

  const handleSnackMessages = (message, type) => {
    snackHandler(message, type)
  }

  const handleStatus = async (info, status) => {
    await client.blog.update(info.id, { status }).then(
      () => {
        const message = status === "published" ? "Blog Published" : "Blog Unpublished";
        snackHandler(message)
        queryClient.invalidateQueries(KEY_QUERY);
      },
      (err) => {
        console.error(err);
        snackHandler("Error")
        queryClient.invalidateQueries(KEY_QUERY);
      }
    )
  }

  const handleRecache = (value) => {
    if (value) {
      queryClient.invalidateQueries(KEY_QUERY);
    }
  }

  useEffect(() => {
    if (!openDialogTollbar) return;
    blogsDialogRef?.current?.open({ status: 'draft' })
    setOpenDialog(true);
  }, [openDialogTollbar]);

  const preview = (body, title) => {
    const newWindow = window.open('', '_blank');

    if (!newWindow) {
      handleSnackMessages("Please allow popups for this website", "error");
      return;
    }

    const newDocument = newWindow.document;
    newDocument.write('<div id="root"></div>');
    newDocument.close();
    newDocument.title = title;

    const rootElement = newDocument.getElementById('root');

    if (rootElement) {
      const root = ReactDOM.createRoot(rootElement);

      root.render(
        <React.StrictMode>
          <PreviewArticle body={body} />
        </React.StrictMode>
      );
    } else {
      handleSnackMessages("Failed to open new tab", "error");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      minWidth: 180,
      flex: 1,
      headerName: "Title",
      valueGetter: ({ row }) => row?.title,
    },
    {
      field: "slug",
      minWidth: 220,
      flex: 1,
      headerName: "slug",
      valueGetter: ({ row }) => row?.slug || "-",
    },
    {
      field: "status",
      minWidth: 220,
      flex: 1,
      headerName: "status",
      valueGetter: ({ row }) => row?.status || "-",
    },
    {
      field: "image",
      minWidth: 220,
      flex: 1,
      headerName: "Featured Image",
      renderCell: ({ row }) => (
        row?.featured_image ? (
          <img
            src={row.featured_image}
            alt="Featured"
            style={{ height: '48px', objectFit: "contain" }}
          />
        ) : (
          "-"
        )
      ),
    },
    {
      field: "date",
      minWidth: 220,
      flex: 1,
      headerName: "Created Date",
      valueGetter: ({ row }) => dayjs(row.created_at).tz("Europe/Rome").format("ddd, DD-M HH:mm")
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      width: 70,
      renderCell: ({ row }) => (
        <Dropdown
          text={<MoreVertIcon />}
          content={
            <MenuList>
              <MenuItem
                color="primary"
                title="Edit"
                onClick={() => {
                  blogsDialogRef?.current?.open(row)
                }}
              >
                <ListItemIcon>
                  <MdOutlineEditNote size={20} />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>

              <MenuItem
                color="primary"
                title="Publish"
                onClick={() => {
                  handleStatus(row, row.status === "published" ? "draft" : "published")
                }}
              >
                <ListItemIcon>
                  <MdPublishedWithChanges size={20} />
                </ListItemIcon>
                <ListItemText>{
                  row?.status === "published" ? "Unpublish" : "Publish"
                }</ListItemText>
              </MenuItem>


              <MenuItem
                color="primary"
                title="Preview"
                onClick={() => {
                  preview(row.body, row.title)
                }}
              >
                <ListItemIcon>
                  <MdPreview size={20} />
                </ListItemIcon>
                <ListItemText>Preview</ListItemText>
              </MenuItem>

              <MenuItem
                title="Delete Post"
                onClick={() => {
                  setDeleteToken(row?.id);
                  setOpenConfirmDialog(true);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </MenuList>
          }
        />
      ),
    },
  ];

  return (
    <BasePage title={"Blog"}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        // page={parseInt(page.toString()) - 1}
        columns={columns}
        loading={isLoading}
        rows={data?.data || []}
        pageSize={perPage}
        sortModel={sortModel}
        onPageChange={(newPage) => setPage(newPage + 1)}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        components={{
          Toolbar,
          Pagination: ({ page, setPage, totalPagination }) => (
            <CustomPagination
              page={(page || 1) - 1}
              count={totalPagination}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: { filters: filterData, setFilters, setOpenDialogTollbar },
          pagination: { page, setPage, totalPagination: data?.total || 0 },
        }}
      />

      <ConfirmationDialog
        open={openConfirmDialog}
        title={
          <>
            <img src={RemoveIcon} /> Delete Post
          </>
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          triggerRemovePost(deleteToken)
          setOpenConfirmDialog(false);
        }}
      >
        Are you sure to remove it?
      </ConfirmationDialog>

      <BlogPostDialog ref={blogsDialogRef} snackMessage={handleSnackMessages} reloadCache={handleRecache} setOpenDialog={setOpenDialog} setOpenDialog2={setOpenDialogTollbar} dialogStatus={openDialog} />

    </BasePage>
  );
};

export default BlogPost;
