import { Button } from "@mui/material";
import useAuth from '@/hooks/useAuth';
import { useGridApiContext } from "@mui/x-data-grid";
import dayjs from "dayjs";
import downloadIcon from "@/assets/icons/download.svg";

type Variant = "outlined" | "contained" | "text";
type Color =
  | "error"
  | "info"
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "warning";

interface INUseDownloadTable {
  sx?: any;
  Icon?: JSX.Element;
  color?: Color;
  title?: string;
  fileName: string;
  variant?: Variant;
  disabled?: boolean;
  delimiter?: string;
  className?: string;
  IconColor?: string;
  removeDate?: boolean;
}

export default ({
  sx,
  Icon,
  color,
  title,
  fileName,
  variant,
  disabled,
  delimiter,
  className,
  IconColor,
  removeDate,
}: INUseDownloadTable) => {
  const { isMember } = useAuth();
  const apiRef = useGridApiContext();

  const handleExport = () => {
    apiRef.current.exportDataAsCsv({
      allColumns: true,
      delimiter: delimiter || ";",
      fileName:
        fileName + (removeDate ? "" : " " + dayjs().format("DD-MM-YYYY")),
    });
  };

  return (
    <>
      {!isMember && (
        <Button
          title={title || "Download"}
          className={className}
          color={color}
          disabled={disabled}
          variant={variant || "outlined"}
          onClick={handleExport}
        >
          {Icon || (
            <img
              src={downloadIcon}
              className="icon"
            />
          )}
          Download
        </Button>
      )}
    </>
  );
};
