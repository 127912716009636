import { uuid } from "@/utils";
import { useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import CustomToolbar from "./components/CustomToolbar";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const CustomerCareKpi = () => {
  const { perPage } = useScreen();
  const theme = useTheme();
  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const cacheKey = ["problematicUsersKpi", filterData];

  const { data, isLoading } = useQuery(
    cacheKey,
    () =>
      useAPI("/v1/users/problematic/kpi", {
        filter: { ...filterData },
      }),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const columns: GridColDef[] = [
    {
      field: "owned_by",
      headerName: "Owned by",
      flex: 1,
      valueGetter: ({ row }) => row?.owned_by || "-",
    },
    {
      field: "no_reply",
      flex: 1,
      headerName: "No reply",
    },
    {
      field: "peaceful",
      flex: 1,
      headerName: "Peaceful",
    },
    {
      field: "troublesome",
      flex: 1,
      headerName: "Troublesome",
    },
    {
      field: "total",
      flex: 1,
      headerName: "Total",
    },
  ];

  return (
    <BasePage title="Problematic Users Kpi">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        hideFooterSelectedRowCount
        sx={getTableStyling(theme)}
        rows={data?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={() => uuid()}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        components={{
          Toolbar: CustomToolbar,
          Pagination: () => <CustomPaginationClient />,
        }}
        componentsProps={{
          toolbar: {
            filters,
            setFilters,
            filterData,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </BasePage>
  );
};

export default CustomerCareKpi;
