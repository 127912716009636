import { MouseEvent } from "react";
import { Button } from "@mui/material";

export type TColorBorder = "" | "yellow" | "blue" | "violet" | "orange" | "red";

interface INProps {
  color?: string;
  onClick: (event: MouseEvent) => void;
  bgColor?: string;
  children?: JSX.Element | string;
  borderColor?: TColorBorder;
}

const ButtonCheck = ({
  color,
  bgColor,
  onClick,
  children,
  borderColor = "",
}: INProps) => {
  return (
    <Button
      sx={{
        color,
        p: 0.5,
        width: "20px",
        height: "20px",
        border: borderColor && `2px solid ${borderColor}`,
        bgcolor: bgColor,
        minWidth: 0,
        fontSize: "7.5px",
        "&:hover": { backgroundColor: bgColor },
        borderRadius: "50%",
      }}
      onClick={onClick}
      className="flex justify-center items-center !font-bold"
    >
      {children}
    </Button>
  );
};

export default ButtonCheck;
