import { forwardRef, useImperativeHandle, useState } from "react";
import PaymentIcon from "@/assets/icons/payment.svg";
interface IProps {
  ref: HTMLButtonElement;
}

export interface IViewInvoiceForwardRef {
  open: (data: string) => void;
}

import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";

const ViewInvoicePaymentOutDialog = forwardRef<IViewInvoiceForwardRef, IProps>(
  (_props, ref) => {
    const [show, setShow] = useState(false as boolean);
    const [url, setUrl] = useState("");

    useImperativeHandle(ref, () => ({
      open: (data) => {
        setUrl(data);
        setShow(true);
      },
    }));

    return (
      <EduModal
        fullWidth
        maxWidth="lg"
        open={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <EduModalTitle
          onClose={() => {
            setShow(false);
          }}
        >
          <img src={PaymentIcon} className="icon no-margin" /> View Invoice
        </EduModalTitle>
        <EduModalBody>
          <iframe
            id="frameContent"
            width="100%"
            height="700"
            src={url}
          ></iframe>
        </EduModalBody>
      </EduModal>
    );
  }
);

export default ViewInvoicePaymentOutDialog;
