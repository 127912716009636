import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getUsersSuspended,
  createUserSuspended,
  updateUserSuspended,
  deleteUserSuspended,
  downloadUsersSuspended,
  reactivateUserSuspended,
} from "./usersApi";
import dayjs from "dayjs";

export interface IUser {
  id?: number;
  zipcode?: string;
  first_name?: string;
  phone?: string;
  email?: string;
  token?: string;
  city?: string;
  active?: number;
  last_name?: string;
  payments?: Array<any>;
  address?: string;
  restart_month?: string;
  availabilities_done?: string;
}

const useUserSuspendedApi = () => {
  const { snackHandler, userAdmin } = useAuth();

  const keyCache = "usersSuspened";
  const keyCacheUserList = "users-list";

  let QUERY_KEY = [keyCache, keyCacheUserList];

  const callGetUsersSuspended = (params) => {
    queryClient.setQueryData([keyCache, "last-params"], () => params);

    QUERY_KEY = [keyCache, keyCacheUserList, params];

    return useQuery(
      [keyCache, keyCacheUserList, params],
      async () => {
        const { data } = await getUsersSuspended(userAdmin.token, params);
        return data;
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        enabled: params.per_page > 0,
      }
    );
  };

  const callCreateUserSuspend = useMutation(
    (params: { data: any; callback?: (resp: any) => void }) =>
      createUserSuspended(userAdmin.token, params.data),
    {
      onSuccess: async (data, { callback }) => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        await queryClient.invalidateQueries([
          keyCache,
          keyCacheUserList,
          params,
        ]);
        snackHandler("Suspened Created!");
        if (callback) callback(data);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callUpdateUserSuspend = useMutation(
    (params: { id: string; data: any; callback?: (resp: any) => void }) =>
      updateUserSuspended(userAdmin.token, params.id, params.data),
    {
      onSuccess: async (data, { callback }) => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        await queryClient.invalidateQueries([
          keyCache,
          keyCacheUserList,
          params,
        ]);
        snackHandler("Suspened Updated!");
        if (callback) callback(data);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callDeleteUserSuspend = useMutation(
    (params: { id: string }) => deleteUserSuspended(userAdmin.token, params.id),
    {
      onSuccess: async () => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        await queryClient.invalidateQueries([
          keyCache,
          keyCacheUserList,
          params,
        ]);
        snackHandler("Suspened Deleted!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callReactiveUserSuspend = useMutation(
    (data: any) => reactivateUserSuspended(userAdmin.token, data),
    {
      onSuccess: async () => {
        const params = queryClient.getQueryData([keyCache, "last-params"]);
        await queryClient.invalidateQueries([
          keyCache,
          keyCacheUserList,
          params,
        ]);
        snackHandler("Reactivate Student!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callDownloadtUsersSuspended = async (params, filename?) => {
    if (!filename) filename = `Suspended ${dayjs().format("DD-MM-YYYY")}.csv`;

    const rs = await downloadUsersSuspended(
      userAdmin.token,
      { filters: { ...params }, export: true },
      filename
    );
    return rs;
  };

  return {
    QUERY_KEY,
    callGetUsersSuspended,
    callCreateUserSuspend,
    callUpdateUserSuspend,
    callDeleteUserSuspend,
    callReactiveUserSuspend,
    callDownloadtUsersSuspended,
  };
};

export default useUserSuspendedApi;
