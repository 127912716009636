import { useState } from "react";
import { useExerciseContext } from "@/pages/Exercises/ExercisesV3/state/ExerciseContext";
import useAuth from "@/hooks/useAuth";
import { assignmentExercises } from "@/api";
import { UseQueryResult } from "@tanstack/react-query";
import { IeltsExercise } from "@/pages/Exercises/ExercisesV3/exercisesV3.types";

export const useAssignment = <T extends UseQueryResult<any, unknown>>(
  queryResult: T | undefined
) => {
  const { assignOldExerciseState } = useExerciseContext();
  const { setAssignOldExerciseOpen } = assignOldExerciseState;
  const { userAdmin } = useAuth();
  const [date, setDate] = useState<Date | null>(new Date());
  const [exercises, setExercises] = useState<IeltsExercise[]>([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [students, setStudents] = useState("");
  const [typeToggle, setTypeToggle] = useState<"old" | "new">("new");
  const [assignmentState, setAssignmentState] = useState<{
    isLoading: boolean;
    error: string | null;
  }>({
    isLoading: false,
    error: null,
  });

  const handleSubmit = async () => {
    if (queryResult?.data?.data.length == 0 || exercises?.length == 0) {
      setAssignmentState({
        ...assignmentState,
        error: "You have to select student/s and exercise/s first",
      });
      return;
    }

    setAssignmentState({
      ...assignmentState,
      isLoading: true,
    });

    let tokenExercises = "";
    if (typeToggle === "old") {
      tokenExercises = exercises.map((el) => el?.token).join(",");
    } else {
      tokenExercises = exercises.map((el) => el?.id).join(",");
    }

    const params = {
      exercise_type: typeToggle,
      data: date,
      id_utente: students,
      exercise_token: tokenExercises,
    };

    const res = await assignmentExercises({ tk: userAdmin?.token, params });

    if (res?.status === "success") {
      setStudents("");
      setExercises([]);
      setAssignmentState({
        ...assignmentState,
        error: null,
        isLoading: false,
      });
      setAssignOldExerciseOpen(false);
    } else if (res?.error) {
      setAssignmentState({
        ...assignmentState,
        error: res?.error,
        isLoading: false,
      });
    }
  };

  return {
    date,
    setDate,
    exercises,
    setExercises,
    openConfirm,
    setOpenConfirm,
    students,
    setStudents,
    assignmentState,
    setAssignmentState,
    handleSubmit,
    typeToggle,
    setTypeToggle,
  };
};
