import { ReactElement, useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { MoreVert } from "@mui/icons-material";
import { mergeByKey } from "../useHelpers";
import { queryClient } from "@/main";
import { TeamMenuItem } from "./styles";
import { Box, Menu, Button, Switch, Popover, useTheme } from "@mui/material";
import useAPI from "@/api/useAPI";

interface Route {
  id?: number;
  name: string;
  icon: ReactElement;
  group: string;
  active: boolean;
  typeOf?: Array<string>;
  limitByTypeOf?: Array<string>;
  mixEnglishAdmission?: boolean;
}

interface IOtherSettings {
  anchorEl: any;
  routeData: Route;
}

interface MProps {
  userId: number;
  routes: Array<Route>;
}

const MenuToggleRoutes = ({ routes, userId }: MProps) => {
  const theme = useTheme();
  const { defaultRoutes, snackHandler, links } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dataRoutes, setDataRoutes] = useState<Array<Route>>(
    (routes
      ? mergeByKey(routes, links, "name")
      : mergeByKey(links, defaultRoutes, "name")
    )?.filter((r: any) => Boolean(r?.path))
  );
  const [otherSettings, setOtherSettings] = useState<IOtherSettings>();

  const handleToggle = (e: any) => {
    setDataRoutes((p) => {
      const i = p.findIndex((r) => r.name == e.target.name);
      p[i].active = e.target.checked;
      return p;
    });
  };

  const saveRoutes = async () => {
    const routes = dataRoutes.map((link) => {
      const data: any = {
        name: link.name,
        active: link.active,
      };

      if (link?.limitByTypeOf?.length) {
        data.limitByTypeOf = link.limitByTypeOf;
      }

      if (link?.mixEnglishAdmission) {
        data.mixEnglishAdmission = link.mixEnglishAdmission;
      }

      return data;
    });

    const res = await useAPI(`/v1/${userId}/auth-update`, { routes });

    if (!res?.status) {
      snackHandler(res?.message || "Failed", "error");
      return;
    }

    snackHandler("Saved");
    queryClient.invalidateQueries(["team"]);
  };

  const sections: Array<string> = [];

  dataRoutes.forEach((r) => {
    if (sections.includes(r.group) === false) {
      sections.push(r.group);
    }
  });

  const handleSetLimitByTypeOf = (e: any, typeOf: string) => {
    const checked = e.target.checked;

    const id = otherSettings?.routeData?.id as number;

    setDataRoutes((p) => {
      let limitByTypeOf = p[id]?.limitByTypeOf || [];

      const inArray = limitByTypeOf.includes(typeOf);

      if (inArray && !checked) {
        limitByTypeOf = limitByTypeOf.filter((t) => t != typeOf);
      } else if (!inArray && checked) {
        limitByTypeOf.push(typeOf);
      }

      if (limitByTypeOf?.length) {
        p[id].limitByTypeOf = limitByTypeOf;
      } else {
        delete p[id].limitByTypeOf;
      }

      return p;
    });
  };

  const handleSetMixEnglishAdmission = (e: any) => {
    const checked = e.target.checked;

    const id = otherSettings?.routeData?.id as number;

    setDataRoutes((p) => {
      if (checked) {
        p[id].mixEnglishAdmission = checked;
      } else {
        delete p[id].mixEnglishAdmission;
      }

      return p;
    });
  };

  const limitByTypeOf: Array<string> = useMemo(
    () => [
      ...new Set(
        dataRoutes.reduce(
          (acc: Array<string>, el: any) => [...acc, ...(el?.typeOf || [])],
          []
        )
      ),
    ],
    [dataRoutes]
  );

  const showMixEnglishAdmission =
    typeof otherSettings?.routeData?.id == "number" &&
    dataRoutes[otherSettings.routeData.id].typeOf?.includes("admission") &&
    (!dataRoutes[otherSettings.routeData.id]?.limitByTypeOf?.length ||
      (dataRoutes[otherSettings.routeData.id]?.limitByTypeOf?.length || 0) >=
      2);

  return (
    <>
      <Box
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        aria-controls={Boolean(anchorEl) ? "routes-menu" : undefined}
      >
        Setup routes
      </Box>

      <Menu
        id="routes-menu"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            bgcolor: theme.palette.leftSideBarSub.main,
            "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 22,
              width: 10,
              height: 10,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
          className: "overflow-hidden overflow-y-auto !max-h-[700px] scrollbar",
          elevation: 0,
        }}
        className="!overflow-hidden"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box className="w-full sticky top-3 pb-4 pr-4 z-10">
          <Button
            fullWidth
            variant="contained"
            onClick={saveRoutes}
            className="!mx-2 !text-lg !font-semibold !text-white"
          >
            Save
          </Button>
        </Box>

        {sections.map((section) => (
          <Box
            sx={{ px: 0 }}
            key={section}
            className="flex flex-col items-stretch !p-0 !m-0"
          >
            <Box
              style={{ background: theme.palette.primary.main }}
              className="w-full text-white text-lg px-[10px] py-[5px]"
            >
              {section}
            </Box>

            {dataRoutes.map((route, i) =>
              route.group !== section ? null : (
                <Box
                  key={i}
                  className="w-full flex gap-2 justify-between items-center p-2 hover:bg-gray-100/5"
                >
                  <Box className="grow flex items-center gap-1">
                    <Switch
                      name={route?.name}
                      onChange={handleToggle}
                      defaultChecked={Boolean(route?.active)}
                    />

                    <TeamMenuItem>{route.icon}</TeamMenuItem>

                    <Box>{route?.name}</Box>
                  </Box>

                  <Button
                    onClick={(e) =>
                      setOtherSettings({
                        anchorEl: e.currentTarget,
                        routeData: { ...route, id: i },
                      })
                    }
                    className="!min-w-0"
                  >
                    <MoreVert color="primary" />
                  </Button>
                </Box>
              )
            )}
          </Box>
        ))}
      </Menu>

      <Popover
        open={Boolean(otherSettings)}
        onClose={() => setOtherSettings(undefined)}
        anchorEl={otherSettings?.anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className="p-2">
          {showMixEnglishAdmission && (
            <Box
              key="mix"
              className="grow flex items-center gap-1 pr-4"
            >
              <Switch
                name="mix"
                onChange={handleSetMixEnglishAdmission}
                defaultChecked={Boolean(
                  otherSettings?.routeData?.mixEnglishAdmission
                )}
              />

              <Box>MIX ENGLISH ADMISSION</Box>
            </Box>
          )}

          {limitByTypeOf?.map((typeOf, i) =>
            otherSettings?.routeData?.typeOf?.includes(typeOf) ? (
              <Box
                key={typeOf + i}
                className="grow flex items-center gap-1 pr-4"
              >
                <Switch
                  name={typeOf}
                  onChange={(e) => handleSetLimitByTypeOf(e, typeOf)}
                  defaultChecked={Boolean(
                    otherSettings?.routeData?.limitByTypeOf?.includes(typeOf)
                  )}
                />

                <Box>{typeOf?.toUpperCase()}</Box>
              </Box>
            ) : null
          )}
        </Box>
      </Popover>
    </>
  );
};

export default MenuToggleRoutes;
