import classNames from 'classnames'
import flags from './flags'

type Props = {
    flag: string,
    className?: string,
}

const FlagRender = (props: Props) => {

    const { flag, className, ...rest } = props

    return (
        <img src={flags[flag] ?? flags["it"]} alt={flag} className={
            classNames(
                { "p-3": !className },
                className,
            )
        } {...rest} />
    )
}

export default FlagRender