import { ChangeEvent, forwardRef, useImperativeHandle, useState } from "react";
import { Image } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import useFileRenderPreview from "@/components/useFileRenderPreview";

interface IImagePreview {
  name: string;
  value: any;
}

export interface IRef {
  open: (userId: number) => void;
}

interface IProps {
  snackHandler: (text: string, version?: "error") => void;
}

const AddCheckBookingIelts = forwardRef<IRef, IProps>(
  ({ snackHandler }, ref) => {
    const [image, setImage] = useState<any>();
    const [userId, setUserId] = useState<number>();
    const [disable, setDisable] = useState<boolean>(false);
    const [imagePreview, setImagePreview] = useState<IImagePreview>();

    useImperativeHandle(ref, () => ({
      open: (userId) => {
        setUserId(userId);
      },
    }));

    const handleClose = () => {
      setImage(undefined);
      setUserId(undefined);
      setDisable(false);
      setImagePreview(undefined);
    };

    const handleSubmit = async () => {
      const formData = new FormData();
      formData.append("user_id", userId as any);
      formData.append("booking_pic", image);

      setDisable(true);

      const res = await useAPI(
        "/v1/users/add-check-user-booking-ielts",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      setDisable(false);

      if (res?.status != 200) {
        snackHandler(res?.message || "Failed upload!", "error");
        return;
      }

      snackHandler("Success upload!");
      handleClose();
    };

    return (
      <EduModal
        fullWidth
        open={Boolean(userId != undefined)}
        onClose={handleClose}
        maxWidth="sm"
      >
        <EduModalTitle>
          <Image sx={{ mr: 1 }} />
          Add check booking IELTS
        </EduModalTitle>

        <EduModalBody className="flex flex-col gap-5 p-5">
          {Boolean(imagePreview?.name) && (
            <Box>
              <img
                width="100%"
                alt={imagePreview?.name}
                src={imagePreview?.value}
              />
            </Box>
          )}

          <Box className="w-full flex justify-between">
            <Box>
              <input
                id="file"
                type="file"
                name="file"
                accept=".png,.jpg,jpeg"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  useFileRenderPreview(e, (value, file) => {
                    setImage(file);
                    setImagePreview({ name: file.name, value });
                  })
                }
                className="hidden"
              />

              {image ? (
                <Box>
                  <Button
                    variant="outlined"
                    component="div"
                    className="truncate"
                  >
                    {image.name}
                  </Button>

                  <Button
                    sx={{ marginLeft: "10px" }}
                    type="button"
                    color="error"
                    onClick={() => {
                      setImage(undefined);
                      setImagePreview(undefined);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              ) : (
                <label htmlFor="file">
                  <Button
                    variant="outlined"
                    component="span"
                  >
                    <img
                      src={addIcon}
                      className="icon"
                    />
                    Upload image
                  </Button>
                </label>
              )}
            </Box>

            <Button
              variant="outlined"
              onClick={handleSubmit}
              disabled={disable}
            >
              Save
            </Button>
          </Box>
        </EduModalBody>
      </EduModal>
    );
  }
);

export default AddCheckBookingIelts;
