import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import PhoneField from "@/components/_Common/Forms/PhoneField";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import LANGUAGES from "@/components/assets/LANGUAGES";
import useAuth from "@/hooks/useAuth";
import { CALENDARS } from "@/providers/AuthProvider";
import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, TextField } from "@mui/material";
import parsePhoneNumberFromString from "libphonenumber-js";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useContextCrm } from "../Context";
import { getDetailsWhatsApp } from "../functions";

export interface IRef {
  open: () => void;
}

interface IProps { }

interface INForm {
  first_name?: string;
  from?: string;
  lang?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  origin?: string;
  tutoring: number;
}

const CreateLead = forwardRef<IRef, IProps>((_, ref) => {
  const { isAdmission, userAdmin, snackHandler } = useAuth();
  const context = useContextCrm();

  const [form, setForm] = useState<INForm>({ tutoring: Number(isAdmission) });

  const admin = userAdmin.user.first_name + " " + userAdmin.user.last_name;

  const owned_by = (
    userAdmin.calendars?.[CALENDARS.SALES]?.map((item: any) => item.name) || []
  ).includes(admin)
    ? admin
    : null;

  useImperativeHandle(ref, () => ({
    open: () => {
      context.setDialog({ component: "create_lead" });
    },
  }));

  const handleClose = () => {
    context.setDialog(undefined);
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm((p: any) => ({ ...p, [name]: value }));
  };

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();

    context.setIsLoading(true);

    let phone = form.phone?.replace(/[\s\-\[\]_]/g, "");

    if (phone && !phone.startsWith("+")) {
      const phoneNumber = parsePhoneNumberFromString(phone, "IT");

      if (phoneNumber) {
        phone = phoneNumber.formatInternational();
      } else {
        phone = `+IT${phone}`;
      }
    }

    let data: any = {
      ...form,
      owned_by,
      phone: phone,
      status: "Nuovo",
      save_utm: true,
      utm_source: form.from,
      created_by_admin: userAdmin.user.id,
      updates: JSON.stringify([
        {
          status: "Nuovo",
          date: null,
          admin,
          owned_by,
        },
      ]),
    };

    if (form.from == "WhatsApp") {
      data = {
        ...data,
        ...getDetailsWhatsApp(),
      };
    }

    let res: any = null;

    try {
      res = await useAPI("/v1/crm", data);
    } catch (error) {
      context.setIsLoading(false);
      console.error(error);
      snackHandler(String(error), "error");
      return;
    }

    context.setIsLoading(false);

    if (res?.status == false) {
      snackHandler(res?.message || "Failed create lead!", "error");
      return;
    }

    setForm({ tutoring: Number(isAdmission) });
    context.setRowSelected(res);
    context.setDialog({ row: res, component: "add_meeting_calendar" });
    context.setOpenSelectCalendar(true);
    context.getInvalidateQuery();
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(context.dialog?.component == "create_lead")}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        <img src={addIcon} />
        ADD MANUALLY {isAdmission && " tutoring"}
      </EduModalTitle>

      <EduModalBody
        onSubmit={handleSubmitForm}
        component="form"
        className="flex flex-col gap-5 p-6 pt-8"
      >
        <Box className="flex gap-5">
          <TextField
            required
            fullWidth
            name="first_name"
            label="First name"
            value={form?.first_name ?? ""}
            onChange={handleChange}
          />

          <TextField
            required
            fullWidth
            name="last_name"
            label="Last name"
            value={form?.last_name ?? ""}
            onChange={handleChange}
          />
        </Box>

        <TextField
          required
          fullWidth
          name="email"
          label="Email"
          value={form?.email ?? ""}
          onChange={handleChange}
        />

        <PhoneField
          required
          fullWidth
          name="phone"
          label="Phone"
          value={form?.phone ?? ""}
          onChange={(phone: string) => setForm((p) => ({ ...p, phone }))}
        />

        <Box className="flex gap-5">
          <TextField
            select
            fullWidth
            name="from"
            label="Utm_source"
            value={form?.from ?? ""}
            onChange={handleChange}
          >
            {["WhatsApp", "Email", "IG DM", "Mail Marketing"].map((e, i) => (
              <MenuItem
                key={i}
                value={e}
              >
                {e}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            required
            fullWidth
            name="lang"
            label="Language"
            value={form?.lang || ""}
            onChange={handleChange}
          >
            {LANGUAGES.map((lang) => (
              <MenuItem
                key={lang.iso}
                value={lang.iso}
              >
                <Box className="flex">
                  <span className="w-8">{lang.iso.toUpperCase()}</span>
                  <span>{lang.name}</span>
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box className="flex flex-row-reverse">
          <LoadingButton
            type="submit"
            variant="outlined"
            loading={context.isLoading}
          >
            <img
              src={saveIcon}
              className="icon"
            />
            Save
          </LoadingButton>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});

export default CreateLead;
