import { useState, forwardRef, useImperativeHandle, createRef } from "react";
import { LoadingButton } from "@mui/lab";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  TextField,
  Button,
  styled,
  Stack,
  Typography,
  Switch,
} from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import previewIcon from "@/assets/icons/preview.svg";
import saveIcon from "@/assets/icons/save.svg";
import addQuestionIcon from "@/assets/icons/add-question.svg";
import Question from "./FormQuestion/Question";
import { IFormQuestion, IQuestion } from "./FormQuestion/QuestionInterface";
import useFormQuestion from "./useFormQuestion";
import useRecruitingFormApi from "@/api/useRecruitingFormApi";
import { uuid } from "@/utils";
import PreviewFormDialog, {
  IForwardRef as IPreview,
} from "./PreviewFormDialog/PreviewFormDialog";

export interface IForwardRef {
  open: (data?: IFormQuestion) => void;
}

interface IProps {
  ref: HTMLButtonElement;
}

const RecruitingFormDialog = forwardRef<IForwardRef, IProps>((props, ref) => {
  const refPreview = createRef<IPreview>();
  const [open, setOpen] = useState<boolean>(false);
  const {
    setFormData,
    formData,
    addQuestion,
    changeQuestionByIndex,
    removeQuestionByIndex,
    content,
    setContent,
    handleChange,
    handleChangeByField,
    firstQuestions,
  } = useFormQuestion();
  const { callPostAddForm, callPutUpdateForm } = useRecruitingFormApi();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      if (data) {
        setFormData({
          id: data?.id,
          first_name: data.first_name,
          status: data?.status,
        });

        setContent(data?.content || []);
      } else {
        // set content first questions
        let contentFirstQuestions: IQuestion[] = [];
        firstQuestions.map((q) => {
          contentFirstQuestions.push(q);
        });
        setContent(contentFirstQuestions);
      }
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setFormData(null);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!formData) return;

    if (formData?.id) {
      // update
      await callPutUpdateForm.mutateAsync({
        id: formData.id,
        data: {
          name: formData.first_name,
          status: formData.status,
          content: content,
        },
      });
    } else {
      // add
      await callPostAddForm.mutateAsync({
        data: {
          name: formData.first_name,
          status: formData.status,
          content: content,
          uuid: uuid(),
        },
      });
    }

    setIsLoadingSubmit(false);
    handleClose();
  };

  return (
    <>
      <EduModal
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <EduModalTitle onClose={handleClose}>
          <>
            {formData?.id ? (
              <>
                <img
                  src={editIcon}
                  className="icon"
                />
                EDit Form Link
              </>
            ) : (
              <>
                <img
                  src={addIcon}
                  className="icon"
                />
                CREATE A NEW FORM LINK
              </>
            )}
          </>
        </EduModalTitle>

        <EduModalBody className="p-5 flex flex-col gap-5">
          <Box
            className="flex flex-col gap-4 mt-2"
            component="form"
            onSubmit={handleSubmit}
          >
            <Box className="grid grid-cols-1 gap-4">
              <TextField
                required
                fullWidth
                type="text"
                name="name"
                label="Form name"
                value={formData?.first_name || ""}
                onChange={handleChange}
              />

              {firstQuestions.map((question, index) => {
                return (
                  <ButtonQuestion
                    key={index}
                    variant="outlined"
                    fullWidth
                  >
                    {index + 1} - {question.question}
                  </ButtonQuestion>
                );
              })}

              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "none",
                  textTransform: "uppercase",
                  mt: "40px",
                }}
                fullWidth
                onClick={() => addQuestion()}
              >
                Add Question
                <img src={addQuestionIcon} />
              </Button>

              {content.map((value: IQuestion, index) => {
                if (index <= 4) return;

                return (
                  <Question
                    key={index}
                    value={value}
                    onChange={(newValue: IQuestion) =>
                      changeQuestionByIndex(index, newValue)
                    }
                    onDelete={() => removeQuestionByIndex(index)}
                  />
                );
              })}
            </Box>

            <Box className="flex justify-end gap-4 mt-4">
              <Stack
                sx={{ flex: 1 }}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography>Disable</Typography>
                <AntSwitch
                  checked={formData?.status === 1}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    handleChangeByField("status", checked ? 1 : -1);
                  }}
                />
                <Typography>Enable</Typography>
              </Stack>
              <Button
                variant="outlined"
                onClick={() =>
                  refPreview.current?.open({
                    first_name: formData?.first_name || "",
                    content: content || [],
                  })
                }
              >
                <img
                  src={previewIcon}
                  className={`icon`}
                />
                Preview
              </Button>

              <LoadingButton
                type="submit"
                color="primary"
                variant="outlined"
                loading={isLoadingSubmit}
              >
                <img
                  src={saveIcon}
                  className={`icon ${isLoadingSubmit ? "opacity-0" : ""}`}
                />
                Save
              </LoadingButton>
            </Box>
          </Box>
        </EduModalBody>
      </EduModal>

      <PreviewFormDialog ref={refPreview} />
    </>
  );
});

const ButtonQuestion = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  background: "none",
  cursor: "not-allowed",
  pointerEvents: "none",
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default RecruitingFormDialog;
