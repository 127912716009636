import orderPaid from "@/assets/icons/order-successfull.svg";
import orderPending from "@/assets/icons/pending.svg";
import orderCancelled from "@/assets/icons/cancel.svg";
import orderInitialized from "@/assets/icons/order-initialized.svg";

type Props = {
  status: string;
  className?: string | undefined;
};

const StatusIcon = (props: Props) => {
  const { status, className } = props;

  const icons = {
    error: orderCancelled,
    pending: orderPending,
    completed: orderPaid,
    initialized: orderInitialized,
  };

  return (
    <div>
      <img
        src={icons[status]}
        alt={status}
        title={status}
        className={className || "w-6 h-6"}
      />
    </div>
  );
};

export default StatusIcon;
