import { createRef, useState } from "react";
import { Box } from "@mui/material";
import { groupBy } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { add, format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import useDayOffApi, { IDayOff } from "@/api/useDayOffApi";
import DayOffModal, {
  IForwardRef as IDayOffModal,
} from "./components/DayOffModal";
import RequestDayOffModal, {
  IRequestDayOffModal,
} from "./components/RequestDayOffModal";
import dayjs from "dayjs";
import TopBar from "./components/TopBar";
import BasePage from "@/components/Page/Base";
import MonthCalendar from "./components/MonthCalendar";

const getDate = (year: string | number, action: number) =>
  dayjs(`${year}-01-01`)
  [action ? "endOf" : "startOf"]("y")
    .format("YYYY-MM-DD HH:mm:ss");

const DayOffs = () => {
  const { userAdmin } = useAuth();
  const { keyQuery, callGetListDayOffs } = useDayOffApi();

  const dayOffModal = createRef<IDayOffModal>();
  const requestDayOffModal = createRef<IRequestDayOffModal>();

  const [year, setYear] = useState(new Date().getFullYear());

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const { data } = callGetListDayOffs({
    filter: {
      ...filterData,
      dates: getDate(year, 0) + "," + getDate(year, 1),
    },
  });

  const dayOffs: IDayOff[] = [];
  const admins = new Map();

  const dataFiltered =
    (data || []).filter((d) => {
      if (filterData?.viewMode === "admin") return true;
      if (userAdmin.user.id === d.admin.id) return true;
      return false;
    }) || [];

  // break range dates into array of dates
  dataFiltered.forEach((d) => {
    let startTime = dayjs(d.start_time);
    let endTime = dayjs(d.end_time);
    const getTimeEndDate = new Date(endTime.format("YYYY-MM-DD")).getTime();

    // add admin to array
    admins.set(d.admin_id, d.admin);

    // loop range dates
    let i = 1;
    while (
      new Date(startTime.format("YYYY-MM-DD")).getTime() <= getTimeEndDate
    ) {
      dayOffs.push({
        ...d,
        // use date and month to filter data
        date: startTime.format("YYYY-MM-DD"),
        month: startTime.format("YYYY-MM"),
      });
      startTime = startTime.add(1, "day");
      i++;
      if (i === 100) break;
    }
  });

  // convert map admin to array
  // const listAdmins = Array.from(admins, ([_, value]) => value);

  const months: JSX.Element[] = [];

  let firstTime = `${year}-01-01`;

  for (let index = 0; index < 12; index++) {
    const currentDate = new Date(firstTime);
    const currentMonth = format(currentDate, "yyyy-MM");
    const dayOffsFilter = groupBy(dayOffs, (day: IDayOff) => day?.month);

    months.push(
      <MonthCalendar
        key={index}
        dayOffs={dayOffsFilter.get(currentMonth)}
        viewMode={filterData?.viewMode}
        currentDate={currentDate}
      />
    );
    const nextMonth = add(new Date(firstTime), { months: 1 });
    firstTime = format(nextMonth, "yyyy-MM-dd");
  }

  return (
    <BasePage title="Day Offs">
      <TopBar
        year={year}
        data={data}
        setYear={setYear}
        filters={filterData}
        keyQuery={keyQuery}
        setFilters={setFilters}
        dayOffModal={dayOffModal}
        requestDayOffModal={requestDayOffModal}
      />

      <Box className="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 mt-6 mb-6 flex-wrap">
        {months.map((d) => d)}
      </Box>

      <DayOffModal ref={dayOffModal} />

      <RequestDayOffModal
        ref={requestDayOffModal}
        data={data}
      />
    </BasePage>
  );
};

export default DayOffs;
