import { getCookie, setCookie } from "@/components/Cookie";
import axios, { AxiosRequestConfig } from "axios";

const Axios = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL_API,
  timeout: 150000000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Axios.interceptors.request.use(
  (config) => {
    const token = getCookie("user");

    //@ts-ignore
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token ? token : ""}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403)
    ) {
      setCookie("user", "");
    }
    return Promise.reject(error);
  }
);

export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await Axios.get<T>(url, { params });
    return response.data;
  }

  static async post<T>(
    url: string,
    data: unknown,
    options?: AxiosRequestConfig
  ) {
    if (data instanceof FormData) {
      options = {
        ...options,
        transformRequest: [],
      };
    }

    const response = await Axios.post<T>(url, data, options);
    return response.data;
  }

  static async put<T>(
    url: string,
    data: unknown,
    options?: AxiosRequestConfig
  ) {
    const response = await Axios.put<T>(url, data, options);
    return response.data;
  }

  static async patch<T>(
    url: string,
    data: unknown,
    options?: AxiosRequestConfig
  ) {
    const response = await Axios.patch<T>(url, data, options);
    return response.data;
  }

  static async delete<T>(url: string, options?: AxiosRequestConfig) {
    const response = await Axios.delete<T>(url, options);
    return response.data;
  }
}
