import { useCallback, useEffect, useRef, useState } from 'react';
import "./index.css"

const WorkflowNode = ({ id, name, x, y, onDrag }) => {
    const handleMouseDown = (event) => {
        onDrag(event, id);
    };

    return (
        <div
            className="node"
            draggable
            style={{ left: `${x}px`, top: `${y}px`, position: 'absolute' }}
            onDrag={handleMouseDown}
        >
            {name}
        </div>
    );
};


function EndlessBoard({ triggers, setTriggers }) {
    const boardRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [origin, setOrigin] = useState({ x: 0, y: 0 });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isPanning, setIsPanning] = useState(false);
    const [startPan, setStartPan] = useState({ x: 0, y: 0 });
    const [draggingNodeId, setDraggingNodeId] = useState(null);

    const handleWheel = useCallback(
        (event) => {
            event.preventDefault();
            const { deltaY } = event;
            const direction = deltaY < 0 ? 1 : -1;
            const factor = 0.1;
            setScale((prevScale) => prevScale + direction * factor);
        },
        []
    );

    const handleMouseDown = useCallback((event) => {
        setIsPanning(true);
        setStartPan({ x: event.clientX - position.x, y: event.clientY - position.y });
    }, [position]);

    const handleDrag = useCallback((event, id) => {
        setDraggingNodeId(id);
        // Record the initial position for the drag
        setStartPan({
            x: event.clientX,
            y: event.clientY
        });
    }, []);

    const handleTriggerPositionChange = useCallback((event, id) => {
        if (draggingNodeId === id) {
            const deltaX = event.clientX - startPan.x;
            const deltaY = event.clientY - startPan.y;

            setTriggers((prevTriggers) =>
                prevTriggers.map((trigger) => {
                    if (trigger.id === id) {
                        return { ...trigger, x: trigger.x + deltaX, y: trigger.y + deltaY };
                    }
                    return trigger;
                })
            );

            // Reset the startPan position for the next mouse move
            setStartPan({ x: event.clientX, y: event.clientY });
        }
    }, [draggingNodeId, startPan, setTriggers]);

    const handleMouseMove = useCallback(
        (event) => {
            if (isPanning && !draggingNodeId) {
                // Handle panning
                setPosition({
                    x: event.clientX - startPan.x,
                    y: event.clientY - startPan.y,
                });
            } else if (draggingNodeId) {
                // Handle node dragging
                handleTriggerPositionChange(event, draggingNodeId);
            }
        },
        [isPanning, startPan, draggingNodeId, handleTriggerPositionChange]
    );

    const handleMouseUp = useCallback(() => {
        setIsPanning(false);
        setDraggingNodeId(null); // Stop dragging the node
    }, []);

    useEffect(() => {
        const board = boardRef.current ? boardRef.current as HTMLElement : null;

        if (board) {
            board.addEventListener('wheel', handleWheel);

            return () => {
                board.removeEventListener('wheel', handleWheel);
            };
        }

    }, []);

    return (
        <div
            ref={boardRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            // onWheel={handleWheel}
            onContextMenu={(e) => e.preventDefault()}
            style={{
                width: '100%',
                height: '100vh',
                overflow: 'hidden',
                position: 'relative',
                cursor: isPanning ? 'grabbing' : 'grab',
            }}
            className='grid-layout'
        >
            <div
                style={{
                    transform: `scale(${scale})`,
                    transformOrigin: `${origin.x}px ${origin.y}px`,
                    position: 'absolute',
                    top: `${position.y}px`,
                    left: `${position.x}px`,
                }}
            >
                {
                    triggers.map((trigger) => (
                        <WorkflowNode key={trigger.id} {...trigger} onDrag={handleDrag} />
                    ))
                }
            </div>
        </div>
    );
}

export default EndlessBoard;
