import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface DatePickerModalProps {
  open: boolean;
  selectedDate: Date | null;
  onClose: () => void;
  onDateChange: (date: Date | null) => void;
  onSubmit: () => void;
}

const DatePickerModal: React.FC<DatePickerModalProps> = ({ open, selectedDate, onClose, onDateChange, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Callback Date</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Callback Date"
            value={selectedDate}
            shouldDisableDate={(date) => date < new Date()}
            onChange={onDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} disabled={!selectedDate}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DatePickerModal;
