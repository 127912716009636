import { FC } from "react";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

const COLOR = "rgba(16, 185, 129, .2) !important";

interface INProps {
  text: any;
  show: boolean;
  title?: string;
  onClick: () => void;
  onClose?: () => void;
  disabled?: boolean;
  isLoading: boolean;
  onConfirm: () => void;
}

const ConfirmButton: FC<INProps> = ({
  text,
  show,
  title,
  onClick,
  onClose = () => {},
  disabled = false,
  isLoading,
  onConfirm,
}) => {
  return (
    <Box className="w-full flex gap-2">
      <Button
        sx={{
          "&.Mui-disabled": { color: COLOR, border: "1px solid " + COLOR },
        }}
        title={title}
        onClick={() => (show ? onClose() : onClick())}
        variant="outlined"
        disabled={disabled}
        className={
          show
            ? "selected w_30 width-out transition-none"
            : "w-full transition-none"
        }
      >
        <SendIcon className={"icon " + (show ? "rotate-180" : "")} /> {text}
      </Button>

      {show && (
        <LoadingButton
          sx={{
            "&.Mui-disabled": { color: COLOR, border: "1px solid " + COLOR },
          }}
          color="secondary"
          variant="contained"
          loading={isLoading}
          onClick={onConfirm}
          className="w_70 width-in transition-none"
        >
          CONFIRM
        </LoadingButton>
      )}
    </Box>
  );
};

export default ConfirmButton;
