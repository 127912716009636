import { createRef, useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { Box, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import DialogUsers, { IRef as IDialogUsersRef } from "./DialogUsers";
import useAPI from "@/api/useAPI";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import useExportCSV from "@/components/useExportCSV";
import useProductApi from "@/api/useProductApi";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import dayjs from "dayjs";

const Classi = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { data: productsAPI } = useProductApi().callGetProducts();
  const { isMember, copyToClipboard } = useAuth();

  const dialogUsersRef = createRef<IDialogUsersRef>();

  const [rows, setRows] = useState<GridRowsProp>([]);

  const [filters, setFilters] = useSearchParams({});
  const filterData = Object.fromEntries(filters.entries());

  const KEY_QUERY: Array<any> = ["classi", filterData];

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () => useAPI("/v1/users/classi", { filters: filterData }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const hadleExportCsv = (res: string, fileName: string) => {
    useExportCSV(
      res,
      fileName +
      (filterData?.startMonth ? " - " + filterData?.startMonth : "") +
      (filterData?.englishLevel ? " - " + filterData?.englishLevel : "")
    );
  };

  const handleDownloadTable = async () => {
    const res = await useAPI("/v1/users/classi", {
      filters: filterData,
      exportClassiTable: true,
    });

    hadleExportCsv(res, "Classi table");
  };

  const handleDownloadUsers = async (
    selectRows: Array<any>,
    selectColumns: Array<any>
  ) => {
    const res = await useAPI("/v1/users/classi", {
      filters: filterData,
      selectExport: { rows: selectRows, columns: selectColumns },
      exportClassiUsers: true,
    });

    hadleExportCsv(res, "Classi users");
  };

  const handleDownloadUsersFilterCreatedAt = async () => {
    const res = await useAPI("/v1/users/classi", {
      filters: filterData,
      exportClassiUsersFilterCreatedAt: true,
    });

    hadleExportCsv(res, "Classi table");
  };

  const renderHeader = (nameColumn: string) => ({
    renderHeader: ({ field }) => (
      <Box className="MuiDataGrid-columnHeaderTitle ">
        {Boolean(Number(dataAPI?.columns?.[field])) && (
          <span className="number">{dataAPI?.columns?.[field]}</span>
        )}
        {nameColumn}
        {dataAPI?.nameColumnsMonths?.[field]
          ? " " + dataAPI?.nameColumnsMonths?.[field]
          : ""}
      </Box>
    ),
  });

  const renderCell = () => ({
    renderCell: ({ row, field }) =>
      row?.[field] ? (
        <button
          onClick={() =>
            dialogUsersRef?.current?.open(
              row?.info?.["users"]?.[dataAPI?.nameColumnsMonths?.[field]],
              row?.name
            )
          }
        >
          {row[field]}
        </button>
      ) : (
        0
      ),
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row.name}</MyTooltip>,
    },
    {
      field: "tot_students_0",
      headerName: "Tot students",
      minWidth: 200,
      flex: 0.8,
      ...(isMember ? {} : renderHeader("Tot students")),
      renderCell: ({ row, field }) =>
        row?.[field] ? (
          <button
            onClick={() =>
              dialogUsersRef?.current?.open(
                row?.info?.["users"]?.[dataAPI?.nameColumnsMonths?.[field]],
                row?.name
              )
            }
          >
            {row[field]} ({(row[field] / 20).toFixed(2)})
          </button>
        ) : (
          0
        ),
    },
    {
      field: "with_availabilities",
      headerName: "With availabilities",
      minWidth: 160,
      flex: 0.8,
      renderCell: ({ row }) => {
        if (!row?.["tot_students_0"]) return 0;

        const users: Array<any> =
          row?.info?.["users"]?.[
            dataAPI?.nameColumnsMonths?.["tot_students_0"]
          ]?.filter((user: any) => user?.created_at_availabilities) ?? [];

        return users.length ? (
          <button
            onClick={() => dialogUsersRef?.current?.open(users, row?.name)}
          >
            {users.length}
          </button>
        ) : (
          0
        );
      },
    },
    {
      field: "without_availabilities",
      headerName: "Without availabilities",
      minWidth: 200,
      flex: 0.8,
      renderCell: ({ row }) => {
        if (!row?.["tot_students_0"]) return 0;

        const users: Array<any> =
          row?.info?.["users"]?.[
            dataAPI?.nameColumnsMonths?.["tot_students_0"]
          ]?.filter((user: any) => !user?.created_at_availabilities) ?? [];

        return users.length ? (
          <button
            onClick={() => dialogUsersRef?.current?.open(users, row?.name)}
          >
            {users.length}
          </button>
        ) : (
          0
        );
      },
    },
    ...(Boolean(filterData?.created_availabilities)
      ? [
        {
          field: "created_availabilities",
          headerName: "Delay",
          minWidth: 200,
          flex: 0.8,
          renderCell: ({ row }) => {
            if (!row?.["tot_students_0"]) return 0;

            const users: Array<any> =
              row?.info?.["users"]?.[
                dataAPI?.nameColumnsMonths?.["tot_students_0"]
              ]?.filter((user: any) => {
                const d = dayjs(user?.created_at_availabilities);
                const f = dayjs(
                  filterData?.created_availabilities.split(",")[0]
                );

                return d.isSame(f, "day") || d.isAfter(f);
              }) ?? [];

            return users.length ? (
              <button
                onClick={() =>
                  dialogUsersRef?.current?.open(users, row?.name)
                }
              >
                {users.length}
              </button>
            ) : (
              0
            );
          },
        },
      ]
      : []),
  ];

  useEffect(() => {
    if (!dataAPI?.rows) return;
    setRows(dataAPI.rows || []);
  }, [dataAPI, isLoading]);

  return (
    <BasePage title="Classi">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        hideFooterSelectedRowCount
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={(row) => row.name}
        components={{
          Toolbar,
          Pagination: CustomPaginationClient,
        }}
        componentsProps={{
          toolbar: {
            setFilters,
            productsAPI,
            filters: filterData,
            keyQuery: KEY_QUERY,
            onDownloadTable: handleDownloadTable,
            onDownloadUsers: handleDownloadUsers,
            startMonths: dataAPI?.startMonths || [],
            selectExport: dataAPI?.selectExport || {},
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            monthActiveDefault: dataAPI?.monthActiveDefault || "",
            onDownloadUsersFilterCreatedAt: handleDownloadUsersFilterCreatedAt,
          },
        }}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <DialogUsers
        ref={dialogUsersRef}
        theme={theme}
        copyToClipboard={copyToClipboard}
      />
    </BasePage>
  );
};

export default Classi;

// const columns: GridColDef[] = [
//   {
//     field: "name",
//     headerName: "name",
//     minWidth: 180,
//     flex: 1,
//     renderCell: ({ row }) => <MyTooltip>{row.name}</MyTooltip>,
//   },
//   {
//     field: "tot_students_0",
//     headerName: "Tot students",
//     minWidth: 200,
//     flex: 0.8,
//     ...renderCell(),
//     ...(isMember ? {} : renderHeader("Tot students")),
//   },
//   {
//     field: "ielts_and_grammar_0",
//     headerName: "IELTS and Grammar",
//     minWidth: 160,
//     flex: 0.8,
//     ...(isMember ? {} : renderHeader("IELTS and Grammar")),
//   },
//   {
//     field: "tot_students_1",
//     headerName: "Tot students +1",
//     minWidth: 200,
//     flex: 0.8,
//     ...renderCell(),
//     ...(isMember ? {} : renderHeader("Tot students +1")),
//   },
//   {
//     field: "ielts_and_grammar_1",
//     headerName: "IELTS and Grammar",
//     minWidth: 160,
//     flex: 0.8,
//     ...(isMember ? {} : renderHeader("IELTS and Grammar")),
//   },
//   {
//     field: "tot_students_2",
//     headerName: "Tot students +2",
//     minWidth: 200,
//     flex: 0.8,
//     ...renderCell(),
//     ...(isMember ? {} : renderHeader("Tot students +2")),
//   },
//   {
//     field: "ielts_and_grammar_2",
//     headerName: "IELTS and Grammar",
//     width: 160,
//     ...(isMember ? {} : renderHeader("IELTS and Grammar")),
//   },
// ];
