import useUserApi from "@/api/useUserApi";
import useAuth from "@/hooks/useAuth";
import { Box, createTheme, LinearProgress, ThemeOptions } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { memo, useMemo } from 'react';
import { BrowserRouter } from "react-router-dom";
import BarImpersonate from "./components/BarImpersonate";
import BasicRoutes from "./components/BasicRoutes";
import { getCookie, rmCookie } from "./components/Cookie";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { getColor } from "./providers/Colors";

const BASE_ROUTE = import.meta.env.VITE_ROUTES_BASE;

const App = () => {
  const {
    setUserAdmin,
    snackHandler,
    currentTheme,
    currentColor
  } = useAuth();

  // Memoize theme to prevent unnecessary recreations
  const theme: ThemeOptions = useMemo(() =>
    createTheme(getColor(currentTheme, currentColor)),
    [currentTheme, currentColor]
  );

  const authorization = getCookie("user");

  const { isError, isLoading, isSuccess } = useUserApi().callGetAuth({
    token: authorization,
    onSuccess: (userAdmin: any) => {
      const updatedUserAdmin = { ...userAdmin, token: authorization };
      setUserAdmin(updatedUserAdmin);
    },
    onError: () => {
      snackHandler("Session error", "error");
      rmCookie("user");
      rmCookie("user-old");
    },
  });

  if (!authorization) {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <BasicRoutes />
        </BrowserRouter>
      </ThemeProvider>
    );
  }

  // Loading state component
  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Box className="bg-edu-900 h-screen">
          <LinearProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <Box className="relative">
      <ThemeProvider theme={theme}>
        <BarImpersonate />

        <BrowserRouter basename={BASE_ROUTE}>
          {isError ? <BasicRoutes /> : <ProtectedRoutes />}
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  );
};

export default memo(App);