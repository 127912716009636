import useAuth from '@/hooks/useAuth';
import { Box, Button } from "@mui/material";
import { getCookie, rmCookie, setCookie } from "./Cookie";

export default () => {
  const { userAdmin } = useAuth();

  const authorization = getCookie("user");
  const authorizationOld = getCookie("user-old");

  const handleImpersonateOld = () => {
    if (!authorizationOld) return;
    setCookie("user", authorizationOld);
    rmCookie("user-old");
    window.location.reload();
  };

  return Boolean(
    authorization && authorizationOld && authorization != authorizationOld
  ) ? (
    <Box className="absolute top-0 right-0 w-full p-1 bg-orange-500/30 flex gap-2 z-50">
      <Button
        title="Back impersonate"
        variant="contained"
        onClick={handleImpersonateOld}
        className="!py-0 !font-bold"
      >
        {"<"} Quit
      </Button>

      <Box className="flex justify-center items-center grow">
        Impersonation:
        <b className="ml-1">
          {userAdmin?.user?.first_name} {userAdmin?.user?.last_name}
        </b>
      </Box>
    </Box>
  ) : null;
};
