import React from 'react';
import { TextField, Box, useTheme } from '@mui/material';
import searchIcon from "@/assets/icons/search.svg";

interface FilterComponentProps {
  setFilterModel: (model: { search: string }) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ setFilterModel }) => {
  const theme = useTheme();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterModel({ search: event.target.value });
  };

  return (
    <Box className="flex gap-4 flex-wrap !items-stretch" display="flex" alignItems="center" justifyContent="flex-end">
      <TextField
        variant="outlined"
        size="small"
        sx={{ width: '300px' }}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              style={{ marginRight: 10, filter: theme.palette.filter }}
              alt="search icon"
            />
          ),
        }}
        placeholder="Search..."
        autoComplete="off"
        onChange={handleSearchChange}
      />
    </Box>
  );
};

export default FilterComponent;
