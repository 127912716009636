import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import Title from "@/components/_Common/Page/Title";
import useAPI from "@/api/useAPI";
import editIcon from "@/assets/icons/edit.svg";
import LockIcon from "@mui/icons-material/Lock";
import useScreen from "@/hooks/useScreen";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";
import { useSearchParams } from "react-router-dom";
import usePagination from "@/hooks/usePagination";
import { Toolbar } from "./Toolbar";
import useDataGridSort from "@/hooks/useDataGridSort";

const KEY_QUERY = ["referrals"];

const HTTP_BASE = "/v1/referrals";
const HTTP_UPDATE_REDEEMED = HTTP_BASE + "/update_redeemed";

const Referrals = () => {
  const theme = useTheme();
  const { perPage } = useScreen();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });

  const { sortModel, onSortModelChange } = useDataGridSort({
    setFilters,
    filters,
  });

  const { data, isLoading } = useQuery(KEY_QUERY, () => useAPI(HTTP_BASE));

  const handleUpdateRedeemed = (token: string, value: number) => {
    useAPI(HTTP_UPDATE_REDEEMED, { token, value });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) =>
          `${row?.user?.first_name} ${row?.user?.last_name}`,
      },
      {
        field: "start_month",
        headerName: "Start month",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) =>
          useConvertStartMonthEn(row?.user?.start_month) || "-",
      },
      {
        field: "meeting_booked",
        headerName: "Meeting booked",
        minWidth: 180,
        flex: 0.5,
        valueGetter: ({ row }) => row?.meeting_booked || "-",
      },
      {
        field: "converted",
        headerName: "Converted",
        minWidth: 180,
        flex: 0.5,
        valueGetter: ({ row }) => row?.converted || "0",
      },
      {
        field: "started",
        headerName: "Started",
        minWidth: 180,
        valueGetter: ({ row }) => row?.started || "0",
      },
      {
        field: "redeemed",
        headerName: "Redeemed",
        minWidth: 180,
        flex: 0.5,
        valueGetter: ({ row }) => row?.redeemed || "-",
        renderCell: ({ row }) => {
          const [isLockRedeemed, setIsLockRedeemed] = useState(true);
          const [redeemed, setRedeemed] = useState(row?.redeemed);

          const converted = parseInt(row?.converted) - parseInt(redeemed);

          return isLockRedeemed ? (
            <Box className="w-full flex justify-between items-center">
              <Box>{redeemed}</Box>
              <Button
                variant="outlined"
                onClick={() => setIsLockRedeemed(false)}
                style={{ padding: "5px", minWidth: 0 }}
              >
                <img
                  src={editIcon}
                  className="icon no-margin"
                />
              </Button>
            </Box>
          ) : (
            <TextField
              select
              fullWidth
              sx={{ "& .MuiInputLabel-root": { color: "#fff" } }}
              color="primary"
              value={redeemed ?? 0}
              variant="outlined"
              InputProps={{ style: { height: "2.29rem" } }}
              SelectProps={{ displayEmpty: true, defaultValue: "" }}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem
                selected
                value={redeemed}
                className="flex justify-between"
                onClick={() => setIsLockRedeemed(true)}
              >
                <Box className="flex justify-between w-full">
                  <span>{redeemed}</span>
                  <LockIcon style={{ marginLeft: "1rem" }} />
                </Box>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setRedeemed(0);
                  setIsLockRedeemed(true);
                  handleUpdateRedeemed(row?.token_user, 0);
                }}
              >
                Reset
              </MenuItem>

              {Array.from({ length: converted }, (_: any, i: number) => {
                const v = i + 1;
                return (
                  <MenuItem
                    key={v}
                    value={v}
                    onClick={() => {
                      setRedeemed((p: number) => p + v);
                      setIsLockRedeemed(true);
                      handleUpdateRedeemed(row?.token_user, v);
                    }}
                  >
                    {v}
                  </MenuItem>
                );
              })}
            </TextField>
          );
        },
      },
    ],
    [data]
  );

  if (isLoading) return <LinearProgress />;

  return (
    <Box className="mt-8 mx-5 md:mx-10">
      <Title>Referrals</Title>

      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={data}
        columns={columns}
        loading={false}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            filters: filterData,
            setFilters,
          },
        }}
        components={{
          Toolbar: Toolbar,
          Pagination: CustomPaginationClient,
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        page={parseInt(page.toString()) - 1}
        onPageChange={(newPage) => {
          setPage(newPage + 1);
        }}
      />
    </Box>
  );
};

export default Referrals;
