import useAuth from '@/hooks/useAuth';
import { getTranslation } from "@/utils/translations";
import { IFormMeetingDone } from "@/components/Crm/MeetingComponent";
import { IButtonCrmWhatsapp } from "@/components/assets/BUTTONS_CRM_WHATSAPP";
import { convert, filterObject, jS } from "@/components/useHelpers";
import {
  IAddressData,
  IPaymentData,
} from "@/components/Crm/ConfirmPaymentDialog";
import {
  IFunctions,
  IContextCrm,
  IComponentSentTemplateWhatsApp,
} from "./interfaces";
import {
  LINK_WHATS_APP,
  PRODUCTS_REMINDER_LINK_3,
  NOT_FOUND_TEMPLATE_WHATSAPP,
} from "./constants";
import useGenerateTemplateCrm, {
  convertRowCrm,
} from "@/components/useGenerateTemplateCrm";
import apis from "./apis";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import parsePhoneNumberFromString from "libphonenumber-js";

export default (context: IContextCrm): IFunctions => {
  const getApis = apis(context);
  const { userAdmin, snackHandler } = useAuth();

  const handleChangeStatus = async (rowId: number, params: any) => {
    try {
      const res = await useAPI(
        "/v1/crm/" + rowId,
        {
          ...params,
          admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
        },
        { type: "put" }
      );

      if (res?.status == false) {
        snackHandler("Failed change status!", "error");
        return;
      }

      snackHandler("Success change status!");
      context.getInvalidateQuery();
    } catch (error) {
      console.error("Error api - functions.handleChangeStatus()", error);
      snackHandler("Failed change status!", "error");
      return;
    }
  };

  const updateMeetingDoneOther = async (dataApi: any) => {
    const payload = { mock_meeting: { ...dataApi } };
    const row = context.dialog?.row ?? context.rowSelected;
    try {
      const res = await useAPI("/v1/crm/" + row.id, payload, { type: "put" });

      if (res?.status == false) {
        snackHandler(res?.message || "Failed update event!", "error");
        return;
      }

      snackHandler("Success update event!");
      context.setDialog(undefined);
      context.getInvalidateQuery();
    } catch (error) {
      snackHandler("Failed update event, error api!", "error");
      console.error("Error api - functions.handleUpdateEventMeeting()", error);
    }
  };

  const handleOpenWhatsAppWeb = (row: any) => {
    window.open(
      LINK_WHATS_APP + row.phone + "&text=Ciao " + row.first_name,
      "_blank"
    );
  };

  const handleAddMeetingCalendar = (
    newStatus: "Colloquio fissato" | "Da richiamare"
  ) => {
    getApis.update(
      {
        status: newStatus,
        updates: jS([
          ...convert(context.rowSelected?.updates ?? []),
          {
            status: newStatus,
            date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
          },
        ]),
      },
      () => {
        context.setDialog(undefined);
        context.getInvalidateQuery();
      }
    );
  };

  const handleMoveToUpselling = async () => {
    const row = context.dialog?.row ?? context.rowSelected;
    context.setDialog(undefined);

    const res = await useAPI("/v2/crm/move-to-upselling/" + row?.id);

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed move to upselling!", "error");
      return;
    }

    snackHandler("Success move to upselling!");
  };

  const handleSendTemplatesWithWati = async (
    templatesWati: Array<any>,
    delayBetweenTemplates: number = 20
  ) => {
    const row = context.dialog?.row ?? context.rowSelected;
    context.setDialog(undefined);

    snackHandler(
      `Wait ${delayBetweenTemplates} seconds for send ${templatesWati.length} templates!`
    );

    const res = await useAPI("/v1/wati/send/templates/" + row?.token, {
      templates: templatesWati,
      delaySeconds: delayBetweenTemplates,
    });

    if (res?.status == false) {
      snackHandler(res?.message || "Failed send message!", "error");
      return;
    }

    snackHandler("Success send message/s!");
    handleSendMessagesWhatsApp(null, row);
  };

  const handleUpdateRowCrmCall = async (item: any) => {
    const row = context.dialog?.row ?? context.rowSelected;

    const updates = convert(row?.updates);

    const newUpdate = {
      status: item.values.status,
      updates: JSON.stringify([
        ...(Array.isArray(updates) ? updates : []),
        {
          status: item.values.status,
          date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
        },
      ]),
    };

    const res = await useAPI("/v1/crm/" + row?.id, newUpdate, { type: "put" });

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed update call crm!", "error");
      return;
    }

    snackHandler("Success update call crm!");
    context.setDialog(undefined);
    context.getInvalidateQuery();
  };

  const handleUpdateEventMeeting = async (
    eventSelected: IFormMeetingDone,
    eventId: number | string
  ) => {
    const row = context.dialog?.row ?? context.rowSelected;

    const meeting = convert(row?.meeting);

    if (!meeting?.meet) {
      snackHandler(
        "Failed search meetings into lead, lead not selected or not found!",
        "error"
      );
      return;
    }

    const newMeet = filterObject(
      {
        ...eventSelected,
        ...(context.eventSelected ?? {}),
      },
      [null]
    );

    const i = meeting.meet.findIndex((meet: any) => meet.id === eventId);

    meeting.meet[i] = newMeet;

    const status = ["Rejected"].includes(
      context.eventSelected?.motivation || ""
    )
      ? "Rejected"
      : "Colloquio fatto";

    const params: any = {
      status: row.status !== "Convertito" ? status : "Convertito",
      meeting: jS(meeting),
    };

    const fullNameAdmin =
      userAdmin.user.first_name + " " + userAdmin.user.last_name;

    if (fullNameAdmin !== newMeet.calendar) {
      params.meeting_calendar = fullNameAdmin;
      params.owned_by = fullNameAdmin;
    }

    try {
      const res = await useAPI("/v1/crm/" + row.id, params, { type: "put" });

      if (res?.status == false) {
        snackHandler(res?.message || "Failed update event!", "error");
        return;
      }

      snackHandler("Success update event!");
      context.setDialog(undefined);
      context.getInvalidateQuery();
    } catch (error) {
      snackHandler("Failed update event, error api!", "error");
      console.error("Error api - functions.handleUpdateEventMeeting()", error);
    }
  };

  const handleGenerateLinkWhatsApp = (phone: string, text: string) =>
    `${LINK_WHATS_APP}${phone}&text=${text}`;

  const handleGenerateTemplateCrm = (
    row: any,
    template: IButtonCrmWhatsapp,
    component?: IComponentSentTemplateWhatsApp,
    fallback?: (...params: any) => void
  ) => {
    useGenerateTemplateCrm(
      template,
      convertRowCrm(row),
      userAdmin,
      snackHandler,
      () =>
        component
          ? context.setDialog({
            row,
            template,
            component,
          })
          : context.setRowSelected(row),
      () =>
        fallback
          ? fallback?.(row)
          : snackHandler(NOT_FOUND_TEMPLATE_WHATSAPP, "error")
    );
  };

  const handleUpdateLead = async (rowId: number, data: any) => {
    const res = await useAPI("/v1/crm/" + rowId, data, { type: "put" });

    if (res?.status == false) {
      snackHandler(res?.message || "Failed update data lead!", "error");
      return;
    }

    snackHandler("Success update data lead!");
    context.getInvalidateQuery();
  };

  const handleSendEmailIncorectNumberSubmit = async () => {
    const row = context.dialog?.row ?? context.rowSelected;
    context.setDialog(undefined);

    const res = await useAPI("/v1/crm/send-email-incorect-number", {
      token: row?.token,
      sellerEmail: userAdmin?.user?.email,
    });

    if (!res?.status) {
      snackHandler(
        res?.message || "Error send email incorect number!",
        "error"
      );
      return;
    }

    snackHandler("Success send email incorect number!");
    context.getInvalidateQuery();
  };

  const handleGenerateReminderLink2 = (row: any) => {
    const payment = row?.payments?.find(
      (p: any) => p.paid_for && PRODUCTS_REMINDER_LINK_3[p.paid_for]
    );

    const linkText = encodeURIComponent(
      "https://edusogno.com/form/edusogno-inglese?utm_source=ig&utm_medium=organic&utm_campaign=edusogno&utm_content=Referral&Ads%2Bname=ReferralDicembre22"
    );

    const content = {
      1: getTranslation(row.lang, "reminder2_1", [
        row.first_name,
        userAdmin.user.first_name,
        linkText,
      ]),
      2: getTranslation(row.lang, "reminder2_2", [
        row.first_name,
        userAdmin.user.first_name,
        linkText,
      ]),
      3: "NO PRODUCT ASSOCIATED TO THE MESSAGE",
    };

    let textString = content[3];
    if (payment) {
      textString = content[PRODUCTS_REMINDER_LINK_3[payment.paid_for]];
    }

    window.open(handleGenerateLinkWhatsApp(row.phone, textString), "_blank");

    context.setDialog({ row, component: "reminder2", reminder: "reminder2" });
  };

  const handleSendMessagesWhatsApp = async (
    reminder: string | undefined | null,
    rowSelected?: any
  ) => {
    const row = context.dialog?.row ?? context.rowSelected ?? rowSelected;
    context.setDialog(undefined);

    const params: any = {
      updates: [...convert(row?.updates || "[]")],
    };

    const newUpdate: any = {
      status: row?.status,
      date: new Date().toLocaleString("en-EN"),
      admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
      action: "Send message/s!",
    };

    if (reminder) {
      newUpdate.action = `Send reminder: ${reminder}`;
      params.sent_reminders = {
        ...row?.sent_reminders,
        [reminder]: true,
      };
    } else {
      newUpdate.action = "Send welcome message";
      params.sent_welcome_message = true;
    }

    params.updates = jS([...params.updates, newUpdate]);

    const res = await useAPI("/v1/crm/" + row.id, params, {
      type: "put",
    });

    if (res?.status == false) {
      snackHandler(res?.message || "Error update reminder for lead!", "error");
      return;
    }

    snackHandler("Success update reminder for lead!");
    context.getInvalidateQuery();
  };

  const handleGetReminderLinkAfterRescheduledFallback = (rowSelected: any) => {
    const row = context.dialog?.row ?? context.rowSelected ?? rowSelected;

    const lastMeet = convert(row?.meeting ?? {})?.meet?.at(-1);

    if (!lastMeet) {
      snackHandler("No future event!", "error");
      return;
    }

    const link =
      LINK_WHATS_APP +
      row?.phone +
      "&text=" +
      getTranslation(row?.lang, "reminder_after_rescheduled", [
        row?.first_name,
        dayjs(lastMeet.start).format("H:mm"),
        userAdmin.user.first_name,
      ]);

    window.open(link, "_blank");
  };

  const handleSubmitConfirmPayment = async (
    row: any,
    paymentData: IPaymentData,
    addressData: IAddressData
  ) => {
    // get english_lvl from last item
    const english_lvl =
      convert(row?.meeting)
        ?.meet?.filter((m: any) => Boolean(m?.english_lvl))
        ?.at(-1)?.english_lvl || null;

    const res = await useAPI("/v1/users/confirm-payment-v2", {
      name: row.first_name,
      email: row.email,
      phone: row.phone,
      tokenUser: row.token,
      last_name: row.last_name,
      isUpselling: false,
      english_lvl,
      ...paymentData,
      ...addressData,
    });

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed confirm payment!", "error");
      return;
    }

    context.setDialog(undefined);
    context.getInvalidateQuery();
  };

  const handleSendTemplateWhatsAppDiscountCode = (
    row: any,
    discountCode: string
  ) => {
    if (!row) {
      snackHandler("User not found!", "error");
      return;
    }

    let key: string = "discount.1";

    if (row?.ini_type === "new_checkout") {
      key = "discount.0";
    }

    const link =
      LINK_WHATS_APP +
      row?.phone +
      "&text=" +
      getTranslation(row?.lang, key, [discountCode]);

    window.open(link, "_blank");

    context.setDialog({ row, component: "send_discount_code", discountCode });
  };

  const handleAssignDiscountCode = async () => {
    const row = context.popover?.row ?? context.rowSelected?.id;
    const discountCode = context.popover?.discountCode;

    context.setPopover(undefined);

    const data = await useAPI("/v2/crm/assign-discount-code/" + row?.id, {
      discountCode,
    });

    if (data?.status == false) {
      snackHandler(data?.message || "Error assign discount code!", "error");
      return;
    }

    snackHandler("Success assign discount code!");
    context.getInvalidateQuery();
  };

  const handleMoveToAdmission = async () => {
    const row = context.dialog?.row ?? context.rowSelected;
    context.setDialog(undefined);

    const res = await useAPI(
      "/v1/crm/change-for-admission/" + row?.token,
      {},
      { type: "put" }
    );

    if (res?.status == false) {
      snackHandler(res?.message || "Error change version", "error");
      return;
    }

    snackHandler(
      `Success change version for ${context.dialog?.row?.first_name} ${context.dialog?.row?.lname}`
    );
    context.getInvalidateQuery();
  };

  const handleAlsoAddOnOtherVersionApi = async () => {
    const row = context.dialog?.row ?? context.rowSelected;
    context.setDialog(undefined);

    const res = await useAPI("v1/crm/also_add_lead_on_english_and_admission", {
      token: row?.token,
    });

    if (res?.status == false) {
      snackHandler(res?.message || "Error switch to admission!", "error");
      return;
    }

    snackHandler("Success switch to admission!");
    context.getInvalidateQuery();
  };

  const handleEditLead = async (user: any, newDataUser: any) => {
    let phone = newDataUser.phone?.replace(/[\s\-\[\]_]/g, "");

    if (phone && !phone.startsWith("+")) {
      const phoneNumber = parsePhoneNumberFromString(phone, "IT");

      if (phoneNumber) {
        phone = phoneNumber.formatInternational();
      } else {
        phone = `+IT${phone}`;
      }
    }

    const res = await useAPI(
      "/v1/crm/" + user.id,
      { ...newDataUser, phone },
      { type: "put" }
    );

    if (res?.status == false) {
      snackHandler(res?.message || "Failed edit data lead!", "error");
      return;
    }

    snackHandler("Success edit data lead!");
    context.setDialog(undefined);
    context.getInvalidateQuery();
  };

  const handleDelete = async () => {
    const row = context.dialog?.row ?? context.rowSelected;

    const res = await useAPI(
      "/v1/crm/" + row.id,
      { deleted_at: "now" },
      { type: "put" }
    );

    if (res?.status == false) {
      snackHandler(res?.message || "Failed delete lead!", "error");
      return;
    }

    snackHandler("Success delete lead!");
    context.setDialog(undefined);
    context.getInvalidateQuery();
  };

  return {
    handleDelete,
    handleEditLead,
    handleUpdateLead,
    handleChangeStatus,
    handleMoveToUpselling,
    handleOpenWhatsAppWeb,
    handleMoveToAdmission,
    handleUpdateRowCrmCall,
    updateMeetingDoneOther,
    handleAssignDiscountCode,
    handleAddMeetingCalendar,
    handleUpdateEventMeeting,
    handleGenerateTemplateCrm,
    handleSendMessagesWhatsApp,
    handleSubmitConfirmPayment,
    handleSendTemplatesWithWati,
    handleGenerateReminderLink2,
    handleAlsoAddOnOtherVersionApi,
    handleSendEmailIncorectNumberSubmit,
    handleSendTemplateWhatsAppDiscountCode,
    handleGetReminderLinkAfterRescheduledFallback,
  };
};

// OTHER FUNCTION FOR EXPORT

export const getColor = (value: string, options: Array<string>) => {
  switch (value) {
    case options[0]:
      return "#58ff2f";
    case options[1]:
      return "#FFD700";
    case "other":
      return "#f22d7b";
    default:
      return "grey";
  }
};

export const getDetailsWhatsApp = () => ({
  utm_ads: "WhatsApp",
  utm_source: "WhatsApp",
  utm_medium: "WhatsApp",
  utm_content: "WhatsApp",
  utm_campaign: "WhatsApp",
  details: JSON.stringify(
    [
      "offer",
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_ads",
      "utm_term",
    ].map((e) => ({ label: e, value: "WhatsApp" }))
  ),
});
