import { useEffect, useState } from "react";

interface Props {
  componentHeight?: number | null;
}

export default (props?: Props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(
    props?.componentHeight ?? window.innerHeight
  );
  const [perPage, setPerpage] = useState(0 as number);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(props?.componentHeight ?? window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [props?.componentHeight]);

  let heightFilter = document.getElementById("filter_bar")?.clientHeight || 37;
  let heightTitle = document.getElementById("page-title")?.clientHeight || 25;
  let heightTopMenu = document.getElementById("top_menu")?.clientHeight || 0
  
  heightFilter = heightFilter + 10;
  heightTitle = heightTitle + 20;
  // calculate rows per page base on height of screen
  useEffect(() => {
    const heightMarginTop = 32;
    const heightRow = 52;
    const heightFooterPagination = 52;
    const heightHeaderTable = 60;
    const heightContent =
      height -
      (heightFilter +
        heightTitle +
        heightHeaderTable +
        heightMarginTop +
        heightRow +
        heightFooterPagination + 
        heightTopMenu);

    let tmpPage = Math.ceil(heightContent / heightRow);
    if (tmpPage < 4) tmpPage = 4;
    else if (tmpPage > 100) tmpPage = 100;

    setPerpage(tmpPage);
  }, [width, height, heightFilter, heightTitle, heightTopMenu]);

  let screen = "";

  if (width >= 1440) screen = "xl";
  else if (width >= 1024) screen = "lg";
  else if (width >= 600) screen = "md";
  else screen = "sm";

  const mobileOrizontal = window.matchMedia(
    "(max-width: 1024px) and (orientation: landscape)"
  ).matches;

  return {
    isMobile: screen === "sm" || mobileOrizontal,
    screen,
    width,
    height,
    perPage,
  };
};
