import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "./_Common/Modal/ModalStyles";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

interface ICDProps {
  open: boolean;
  icon?: any;
  title: React.ReactNode | string;
  onClose: (...e: any) => void;
  children: React.ReactNode | string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  onConfirm: (...e: any) => void;
  textButtonAgree: string;
  hideButtonCross?: boolean;
  textButtonDisagree: string;
  excludeOnCloseOutside?: boolean;
}

const ConfirmationDialog = ({
  open,
  icon,
  title,
  onClose,
  children,
  onConfirm,
  textButtonAgree,
  textButtonDisagree,
  maxWidth = "md",
  hideButtonCross = false,
  excludeOnCloseOutside = false,
}: ICDProps) => (
  <EduModal
    open={open}
    onClose={excludeOnCloseOutside ? () => {} : onClose}
    maxWidth={maxWidth}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <EduModalTitle onClose={hideButtonCross ? undefined : onClose}>
      {icon}
      {title}
    </EduModalTitle>

    <EduModalBody style={{ minWidth: "300px" }}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {textButtonDisagree && (
          <Button
            variant="outlined"
            onClick={onClose}
          >
            {textButtonDisagree}
          </Button>
        )}

        <Button
          variant="outlined"
          onClick={onConfirm}
          autoFocus
        >
          {textButtonAgree}
        </Button>
      </DialogActions>
    </EduModalBody>
  </EduModal>
);

ConfirmationDialog.defaultProps = {
  title: "Confirmation",
  textButtonAgree: "Agree",
  textButtonDisagree: "Disagree",
};

export default ConfirmationDialog;
