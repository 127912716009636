import { Box } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import ConfirmButton from "@/components/ConfirmationButton";
import UseShowTemplate from "@/components/UseShowTemplate";

const TemplateEmail = ({
  open,
  onClick,
  onClose,
  template,
  onConfirm,
  isLoading,
  showConfirm,
}) => {
  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={onClose}>Send email</EduModalTitle>

      <EduModalBody>
        <UseShowTemplate>{template}</UseShowTemplate>

        <Box className="p-4">
          <ConfirmButton
            text="SEND"
            show={showConfirm}
            onClick={onClick}
            disabled={isLoading}
            isLoading={isLoading}
            onConfirm={onConfirm}
          />
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default TemplateEmail;
