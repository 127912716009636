import { format, parseISO } from "date-fns";
import { GridColDef } from "@mui/x-data-grid";

import { euro, fUpperWords } from "@/components/useHelpers";
import {
  RenderCellProduct,
  valueGetterProduct,
} from "@/components/User/PopoverProduct";
import { Avatar, Box, Button, Tooltip } from "@mui/material";
import cn from "classnames";
import callIcon from "@/assets/icons/call.svg";
import MyTooltip from "@/components/MyTooltip";
import StatusCell from "./StatusCell";
import { useCrmContext } from "@/pages/Upselling/v2/Crm/state/CrmV3Context";
import { useCrmDialogsContext } from "@/pages/Upselling/v2/Crm/state/CrmDialogsContext";
import ActionCell from "./ActionCell";
import DialpadIcon from "@mui/icons-material/Dialpad";

import {
  CONVERT_STATUS_OPTIONS,
  MEETING_STATUS_OPTIONS,
  STATUS,
} from "../helpers/constants";
import { IDialpadCallModal } from "@/components/Dialpad/interface";
import FlagRender from "./FlagRender";
import { createRef, useState } from "react";

export const getColumns = ({
  handleUpdateRow,
  imgCalendars,
  setAnchorElProduct,
  handleDialpadCall,
}) => {
  const { rowSelectedState } = useCrmContext();

  // Dialog context
  const { dialogNewStatusToBeConvertedRef } = useCrmDialogsContext();

  const columns: GridColDef[] = [
    {
      field: "lang",
      headerName: "",
      align: "center",
      maxWidth: 75,
      renderCell: ({ row }) => (
        <Box className="flex items-center">
          <FlagRender
            flag={
              row?.lang == "de"
                ? row?.phone?.includes("+41")
                  ? "ch"
                  : "de"
                : row?.lang
            }
          />
        </Box>
      ),
    },
    {
      field: "end_date",
      headerName: "End month",
      minWidth: 150,
      flex: 0.5,
      valueGetter: ({ row }) => row?.products?.[0]?.mese_fine || "-",
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row?.fullName || "-",
      renderCell: ({ row }) => {
        const full_name = row.fullName;
        return row.fullName ? (
          full_name.length >= 16 ? (
            <MyTooltip>{full_name}</MyTooltip>
          ) : (
            full_name
          )
        ) : (
          "-"
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "english_level",
      headerName: "English level",
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => valueGetterProduct(row),
      renderCell: ({ row }) => (
        <RenderCellProduct
          {...{
            row,
            setAnchorElProduct,
          }}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 230,
      renderCell: ({ row }) => (
        <StatusCell
          row={row}
          handleUpdateRow={handleUpdateRow}
        />
      ),
    },
    {
      field: "meeting_date",
      headerName: "Meeting date",
      width: 135,
      valueGetter: ({ row }) => {
        let lastMeetingDate = row?.crm_upselling?.meeting_date;

        if (!lastMeetingDate) {
          const meetings = row?.crm_upselling?.meetings?.meet || [];

          const lastMeetingWithPackage = meetings
            .filter((meeting) => Boolean(meeting?.pacchetto))
            .at(-1);

          lastMeetingDate = lastMeetingWithPackage?.start;
        }

        if (!lastMeetingDate) return "-";

        return format(parseISO(lastMeetingDate), "EEE, dd-M HH:mm");
      },
    },
    {
      field: "owned_by",
      headerName: "Owned by",
      width: 100,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row?.crm_upselling?.owned_by || "NA",
      renderCell: ({ row }) => {
        const calendar = row?.crm_upselling?.owned_by;

        if (!calendar) return "NA";

        const name = fUpperWords(calendar);

        return (
          <Tooltip
            arrow
            title={name}
            placement="bottom-start"
          >
            <Avatar
              alt={name}
              src={imgCalendars?.[calendar]?.img}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "ltv",
      headerName: "LTV",
      width: 110,
      valueGetter: ({ row }) => euro(row?.ltv),
    },
    // {
    //   field: "upselling_tracks",
    //   headerName: "Upselling Tracks Response",
    //   width: 150,
    //   valueGetter: ({ row }) => {
    //     const tracks = row?.upselling_tracks || [];
    //     if (tracks.length === 0) return "-";

    //     const latestTrack = tracks.reduce((latest, current) => {
    //       return new Date(current.created_at) > new Date(latest.created_at)
    //         ? current
    //         : latest;
    //     });
    //     // remember to put in constants
    //     const responseMap = {
    //       "Yes, I want to keep improving my english": "Yes",
    //       "No, I don't want to continue": "No",
    //     };

    //     return responseMap[latestTrack.response] || "-";
    //   },
    // },
    {
      field: "upselling_tracks_count",
      headerName: "Upselling Tracks Response",
      width: 110,
      valueGetter: ({ row }) => row?.upselling_tracks_count,
    },
    {
      field: "avg_feedbacks",
      headerName: "Rating",
      width: 70,
      valueGetter: ({ row }) => row?.avg_feedbacks || "-",
    },
    {
      field: "count_feedbacks",
      headerName: "#Feedbacks",
      width: 110,
      valueGetter: ({ row }) => row?.count_feedbacks || "-",
    },
    {
      field: "attendance",
      headerName: "#Attendance/Total",
      width: 130,
      valueGetter: ({ row }) => {
        if (row?.user_events.length < 1) return "-";

        return (
          row?.user_events?.[0]?.attended_events +
          "/" +
          row?.user_events?.[0]?.total_events
        );
      },
    },

    {
      field: "dialpad",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <>
          <Button onClick={() => handleDialpadCall(row)}>
            <DialpadIcon fontSize="small"></DialpadIcon>
          </Button>
        </>
      ),
    },
    {
      field: "button 4",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        [STATUS.ToBeConverted].includes(row?.status) ? (
          <Button
            title={
              row?.info?.offer_rejected
                ? "Offer rejected: " + row?.info?.offer_rejected
                : "Offer rejected"
            }
            onClick={() => dialogNewStatusToBeConvertedRef?.current?.open(row)}
          >
            <img
              src={callIcon}
              className={cn(
                "icon no-margin",
                Boolean(row?.info?.offer_rejected)
                  ? "rotate-[165deg]"
                  : "rotate-[135deg]"
              )}
            />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        const meeting_status_color = MEETING_STATUS_OPTIONS?.find(
          (f: any) => f.status === row?.crm_upselling?.meeting_status
        )?.color;

        const convert_status_color = CONVERT_STATUS_OPTIONS?.find(
          (f: any) => f.status === row?.crm_upselling?.convert_status
        )?.color;

        return (
          <ActionCell
            {...{
              row,
              meeting_status_color,
              convert_status_color,
            }}
          />
        );
      },
    },
  ];

  return columns;
};
