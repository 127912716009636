import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MyTooltip from "@/components/MyTooltip";
import { TableStyling } from "@/dummy";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useScreen from "@/hooks/useScreen";
// import ToolbarNps from "./ToolbarNps";

const FIRST_CAMP_FILTER = {
  normal: "Start month",
  down: "Start month".toLowerCase().replace(" ", "_"),
};

const NpsFeedbacks = ({ dataAPI, isLoading }) => {
  const { isMobile } = useScreen();

  const [avg, setAvg] = useState(Number || null);
  const [rows, setRows] = useState<Array<object>>([]);
  const [startMonths, setStartMonths] = useState<Array<String>>([] || null);
  const [filterMonth, setFilterMonth] = useState({
    [FIRST_CAMP_FILTER.down]: "All",
  });

  const filterData = (data: any) => {
    let newData = [...data];
    if (filterMonth[FIRST_CAMP_FILTER.down] !== "All")
      newData = newData.filter(
        (f) => f?.start_month === filterMonth[FIRST_CAMP_FILTER.down]
      );
    return newData;
  };

  useEffect(() => {
    if (!dataAPI?.length) return;

    dataAPI && setRows(filterData(dataAPI));

    const start_months: Array<string> = [];
    let promotersScore = 0;
    let detractorsScore = 0;

    dataAPI.forEach((el: any) => {
      if (!start_months.includes(el?.start_month)) {
        start_months.push(el?.start_month);
      }

      const currentNpsFeedback = el?.nps_feedback;
      if (filterMonth.start_month == el?.start_month) {
        if (currentNpsFeedback >= 9) {
          promotersScore++;
        } else if (currentNpsFeedback >= 0 && currentNpsFeedback <= 6) {
          detractorsScore++;
        }
      } else if (filterMonth.start_month == "All") {
        if (currentNpsFeedback >= 9) {
          promotersScore++;
        } else if (currentNpsFeedback >= 0 && currentNpsFeedback <= 6) {
          detractorsScore++;
        }
      }
    });
    const percPromoters = parseInt(
      ((promotersScore / (promotersScore + detractorsScore)) * 100).toFixed(2)
    );
    const percDetractors = parseInt(
      ((detractorsScore / (promotersScore + detractorsScore)) * 100).toFixed(2)
    );

    const avg = percPromoters - percDetractors;
    setAvg(avg);

    if (start_months.length == 0) return;

    setStartMonths(start_months);
  }, [dataAPI, filterMonth]);

  // COLUMNS ===================================================================
  const columns: GridColDef[] = [
    {
      field: "nome",
      minWidth: 180,
      flex: 1,
      headerName: "Student",
      valueGetter: ({ row }) => `${row?.first_name} ${row?.last_name}`,
    },
    // {
    //   field: "nome_percorso",
    //   minWidth: 200,
    //   flex: 2,
    //   headerName: "Chosen product",
    //   valueGetter: ({ row }) => [...new Set(row?.percorsi?.map(({ nome_percorso }) => nome_percorso))].filter(Boolean).join(" - "),
    //   renderCell: ({ row }) => {
    //     const products: string = [...new Set(row?.percorsi?.map(({ nome_percorso }) => nome_percorso))].filter(Boolean).join(" - ");

    //     if (!products.includes(" - ") && !products.includes("+")) return products;

    //     return (
    //       <MyTooltip
    //         title={Array.from(new Set(products.split(" - ")))?.map((e, i: number) => (
    //           <Box key={(e || "") + String(i)}>{String(e || "")}</Box>
    //         ))}
    //       >
    //         {products}
    //       </MyTooltip>
    //     );
    //   },
    // },
    {
      field: "start_month",
      minWidth: 140,
      flex: 1,
      headerName: "Start month",
      valueGetter: ({ row }) => (row?.start_month ? row?.start_month : "-"),
    },
    {
      field: "nps_feedback",
      minWidth: 90,
      flex: 0.5,
      headerName: "Score",
      valueGetter: ({ row }) => (row?.nps_feedback ? row?.nps_feedback : "-"),
    },
  ];

  return (
    <DataGrid
      disableColumnMenu
      disableVirtualization
      disableSelectionOnClick
      sx={TableStyling}
      rows={rows}
      style={!isMobile ? { height: "calc(100vh - 14rem)" } : {}}
      columns={columns}
      loading={isLoading}
      // density="comfortable"
      pageSize={20}
      autoHeight={isMobile}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      rowsPerPageOptions={[20]}
      experimentalFeatures={{ newEditingApi: false }}
      // components={{
      //   Toolbar: () => (
      //     <ToolbarNps
      //       avg={avg}
      //       startMonths={startMonths}
      //       filterMonth={filterMonth}
      //       setFilterMonth={setFilterMonth}
      //       FIRST_CAMP_FILTER={FIRST_CAMP_FILTER}
      //     />
      //   ),
      // }}
    />
  );
};

export default NpsFeedbacks;
