import { IData } from "../components/PaymentsOut/FormPaymentOutDialog";
import { queryClient } from "../main";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getPaymentsOut,
  createPaymentOut,
  updatePaymentOut,
  deletePaymentOut,
  downloadPaymentsOut,
  bulkUploadPaymentOut,
} from "./paymentsOutApi";
import dayjs from "dayjs";
import useSessionStorage from "@/hooks/useSessionStorage";
import useAuth from "@/hooks/useAuth";

const usePaymentsOutApi = () => {
  const { snackHandler, userAdmin } = useAuth();

  const listTypes = [
    "Revolut",
    "Paypal",
    "Pagamento Registrato",
    "Pagamento Generico",
    "CREDEM",
  ] as Array<string>;

  const listCategories = [
    "Advertising",
    "Advisory and legal",
    "Andrin",
    "Consulting services",
    "Admission tutor compensation",
    "Domenico",
    "Employees salary",
    "Filippo",
    "Food and travel",
    "Home office and team set-up",
    "Payment fees",
    "Recruiting",
    "Tools and subscriptions",
    "Tutors salary",
    "VAT and taxes",
    "Others",
  ] as Array<string>;

  const keyCache = "payments-out";
  const [getParams, setParams] = useSessionStorage<object>(keyCache);

  const callGetPaymentsOut = (token: string, params) => {
    return useQuery(
      [keyCache, params],
      async () => {
        const rs = await getPaymentsOut(token, params);
        setParams(params);
        return rs;
      },
      {
        enabled: params?.per_page > 0,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
      }
    );
  };

  const callDeletePaymentOut = useMutation(
    (params: {
      id: number;
      token: string;
      alsoFile: string;
      callback: () => void;
    }) => deletePaymentOut(params),
    {
      onSuccess: async () => {
        const params = getParams();
        await queryClient.invalidateQueries([keyCache, params]);

        snackHandler("Payment deleted!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callUpdatePaymentOut = useMutation(
    (params: {
      token: string;
      id: number;
      formData: any;
      callback: () => void;
    }) => updatePaymentOut(params),
    {
      onSuccess: async (updated: { data: IData }) => {
        const params = getParams();
        await queryClient.invalidateQueries([keyCache, params]);
        snackHandler("Payment updated!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callCreatePaymentOut = useMutation(
    (params: { token: string; formData: any; callback: () => void }) =>
      createPaymentOut(params.token, params.formData, params.callback),
    {
      onSuccess: async (data: { data: IData }) => {
        const params = getParams();
        await queryClient.invalidateQueries([keyCache, params]);

        snackHandler("Payment  created!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callBulkUploadPaymentOut = useMutation(
    (params: { formData: any; callback: () => void }) =>
      bulkUploadPaymentOut(userAdmin.token, params.formData, params.callback),
    {
      onSuccess: async () => {
        const params = getParams();
        await queryClient.invalidateQueries([keyCache, params]);
        snackHandler("Imported  successfully!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callDownloadPaymentOut = async (params, filename?) => {
    if (!filename) filename = `Payment Out ${dayjs().format("DD-MM-YYYY")}.csv`;

    const rs = await downloadPaymentsOut(userAdmin.token, params, filename);
    return rs;
  };

  return {
    callGetPaymentsOut,
    callCreatePaymentOut,
    callDeletePaymentOut,
    callUpdatePaymentOut,
    callBulkUploadPaymentOut,
    callDownloadPaymentOut,
    listTypes,
    listCategories,
  };
};

export default usePaymentsOutApi;
