import { ChangeEvent, Dispatch, SetStateAction, useRef } from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { PUBLIC_STORAGE } from ".";
import { INEdit, INKeyTutor, KeysTutor } from "./interfaces";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Avatar,
  Button,
  TextField,
  FormLabel,
  IconButton,
  FormControl,
  Autocomplete,
} from "@mui/material";
import saveIcon from "@/assets/icons/save.svg";

interface INProps {
  edit: INEdit | undefined;
  setEdit: Dispatch<SetStateAction<INEdit | undefined>>;
  handleOnEdit: (...p: any) => void;
}

const Edit = ({ edit, setEdit, handleOnEdit }: INProps) => {
  const fileInputRef: any = useRef(null);

  const firstValidate = Boolean(
    edit?.disabled || !Object.keys(edit?.newData || {}).length
  );

  const onClose = () => {
    setEdit((p: INEdit | undefined) => ({ ...p, open: false }));
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(edit?.open)}
      onClose={onClose}
      maxWidth="md"
    >
      <EduModalTitle onClose={onClose}>Edit</EduModalTitle>

      <EduModalBody className="flex flex-col p-5 pt-7 gap-4">
        <Box className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 gap-4">
          {KeysTutor.map((e: INKeyTutor, i: number) => {
            let component: JSX.Element | null = null;

            if (Boolean(e?.options?.length)) {
              let value: Array<string> | string =
                edit?.newData?.[e.key] || edit?.defaultData?.[e.key] || [];

              if (e.key === "role" && typeof value == "string") {
                value = value.split(",");
              }

              component = (
                <Autocomplete
                  id="tags-standard"
                  value={value}
                  options={e?.options || []}
                  multiple={Boolean(e?.multiple)}
                  onChange={(_: any, value: any) => {
                    setEdit((p: INEdit | undefined) => ({
                      ...p,
                      newData: { ...p?.newData, [e.key]: value },
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={e.label}
                      variant="outlined"
                      required={Boolean(e?.required)}
                      placeholder={(e?.options || [])?.[0] || ""}
                    />
                  )}
                  getOptionLabel={(option) => option || ""}
                />
              );
            } else if (e.key === "profile_pic") {
              component = (
                <FormControl className="w-full h-full flex flex-col justify-between">
                  <FormLabel sx={{ fontWeight: "500", fontSize: "0.8rem" }}>
                    Professional photo (visible to students)
                  </FormLabel>

                  <Box className="w-full flex items-end gap-2">
                    <Button
                      disableElevation
                      variant="contained"
                      component="label"
                      className="!w-[140px] !text-white !p-1"
                    >
                      Choose file
                      <input
                        hidden
                        ref={fileInputRef}
                        type="file"
                        name="profile_pic"
                        accept="image/png,image/jpeg"
                        onChange={(event: any) => {
                          if (
                            event.target.files &&
                            event.target.files.length > 0
                          ) {
                            const selectedFile = event.target.files[0];

                            const reader = new FileReader();

                            reader.onload = function (e: any) {
                              setEdit((p: INEdit | undefined) => {
                                const t = {
                                  ...p,
                                  newData: {
                                    ...p?.newData,
                                    profile_pic: selectedFile,
                                    preview_profile_pic: e.target.result,
                                  },
                                };

                                if (
                                  t?.newData?.[e?.key] ===
                                  edit?.defaultData?.[e?.key]
                                ) {
                                  delete t?.newData?.[e?.key];
                                }

                                return t;
                              });
                            };

                            reader.readAsDataURL(selectedFile);
                          }
                        }}
                      />
                    </Button>

                    <TextField
                      fullWidth
                      value={edit?.newData?.profile_pic?.name || ""}
                      variant="standard"
                      placeholder="No File Selected"
                    />

                    {edit?.newData?.profile_pic && (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          fileInputRef.current.value = "";
                          setEdit((p: INEdit | undefined) => {
                            const t = { ...p };
                            delete t?.newData?.preview_profile_pic;
                            delete t?.newData?.profile_pic;
                            return t;
                          });
                        }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </FormControl>
              );
            } else {
              component = (
                <TextField
                  sx={{ width: "100%" }}
                  key={i}
                  error={Boolean(edit?.errors?.[e.key]?.[0])}
                  label={
                    edit?.errors?.[e.key]?.[0]?.replace(
                      e.key.replaceAll("_", " "),
                      `"${e.label}"`
                    ) || e.label
                  }
                  value={
                    edit?.newData?.[e.key] ?? edit?.defaultData?.[e.key] ?? ""
                  }
                  required={Boolean(e?.required)}
                  placeholder={edit?.defaultData?.[e.key] ?? e.placeholder}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setEdit((p: INEdit | undefined) => {
                      const t = {
                        ...p,
                        newData: {
                          ...p?.newData,
                          [e?.key]: event.target.value,
                        },
                      };

                      if (
                        t?.newData?.[e?.key] === edit?.defaultData?.[e?.key]
                      ) {
                        delete t?.newData?.[e?.key];
                      }

                      return t;
                    })
                  }
                  InputLabelProps={{ sx: { color: "white" } }}
                />
              );
            }

            return <Box key={i}>{component}</Box>;
          })}
        </Box>

        <Box className="w-full flex justify-end items-end gap-2">
          {Boolean(
            edit?.newData?.preview_profile_pic || edit?.defaultData?.profile_pic
          ) && (
            <Avatar
              alt={
                edit?.newData?.first_name || edit?.defaultData?.first_name || ""
              }
              src={
                edit?.newData?.preview_profile_pic ||
                PUBLIC_STORAGE +
                  "/public/storage/" +
                  edit?.defaultData?.profile_pic ||
                ""
              }
              className="mr-auto !w-[10rem] !h-[10rem]"
            >
              {edit?.defaultData?.first_name?.charAt(0)}
            </Avatar>
          )}

          <LoadingButton
            variant="outlined"
            onClick={handleOnEdit}
            loading={Boolean(edit?.isLoading)}
            disabled={firstValidate || Boolean(edit?.isLoading)}
          >
            <img
              src={saveIcon}
              className={`icon ${Boolean(edit?.isLoading) ? "opacity-0" : ""}`}
            />
            Save
          </LoadingButton>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default Edit;
