import Request from "./RequestAdmin";

export const getUserVat = async (token, params) => {
  const { data } = await Request.call(
    {
      url: "/v1/users-vat",
      method: "GET",
      params,
    },
    token
  );

  return data;
};

export const updateUserVatStatus = async (token, id, file) => {
  const formData = new FormData();
  formData.append("invoice_status", "1");

  if (file) {
    formData.append("pdf", file);
  }
  const { data } = await Request.call(
    {
      url: `/v1/user-vat/${id}/update-status`,
      method: "POST",
      data: formData,
    },
    token
  );

  return data;
};

export const softDeleteUserVat = async (token, id) => {
  const { data } = await Request.call(
    {
      url: `/v1/user-vat/${id}/soft-delete`,
      method: "DELETE",
    },
    token
  );

  return data;
};

export const downloadUserVat = async (
  token,
  params,
  fileName = "users-vat.csv"
) => {
  const { data } = await Request.download(
    {
      url: "/v1/users-vat",
      method: "GET",
      params: { ...params, export: true },
    },
    token,
    fileName
  );

  return data;
};
