import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box } from "@mui/material";
import { convert } from "../useHelpers";
import useAuth from '@/hooks/useAuth';
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import useCrmApi from "@/api/useCrmApi";
import ConfirmButton from "@/components/ConfirmationButton";
import getEmailWelcome from "@/components/assets/emails/getEmailWelcome";
import getEmailAdmission from "@/components/assets/emails/getEmailAdmission";

const USER_BASE = import.meta.env.VITE_ROUTE_ACADEMY_USER;
const LANDING_CHECKOUT_BASE = import.meta.env.VITE_LANDING_CHECKOUT;

interface IPropsGetPaymentLink {
  lang: string;
  token: string;
  tutoring: boolean;
  meeting?: string | { meet: Array<any> };
  meetings?: string | { meet: Array<any> };
}

export const getPaymentLink = (user: IPropsGetPaymentLink) => {
  if (!user?.token) return "";

  const sortedMeetings = convert(
    user?.meeting ?? user?.meetings ?? {}
  )?.meet?.sort((a: any, b: any) => b.start.localeCompare(a.start));
  const productTk = sortedMeetings?.find((e: any) => e?.pacchetto)?.pacchetto;
  const orderTk = sortedMeetings?.find((e: any) => e?.orderTk)?.orderTk;

  if (Number(user?.tutoring)) {
    if (user?.lang && user?.lang !== "en") {
      return `${LANDING_CHECKOUT_BASE}/${user.lang}/admission/checkout-page?product_tk=${productTk}&user_tk=${user.token}`;
    }

    return `${LANDING_CHECKOUT_BASE}/admission/checkout-page?product_tk=${productTk}&user_tk=${user.token}`;
  }

  if (user?.lang && user?.lang !== "en") {
    return `${LANDING_CHECKOUT_BASE}/${user.lang
      }/checkout-page?product_tk=${productTk}&user_tk=${user.token}${orderTk ? `&order_tk=${orderTk}` : ""
      }`;
  }

  return `${LANDING_CHECKOUT_BASE}/checkout-page?product_tk=${productTk}&user_tk=${user.token
    }${orderTk ? `&order_tk=${orderTk}` : ""}`;
};

export interface IRef {
  open: (user: any) => void;
}

interface IProps {
  onInvalidateQuery?: () => void;
}

export default forwardRef<IRef, IProps>(({ onInvalidateQuery }, ref) => {
  const { snackHandler } = useAuth();
  const { callSendMailCrm } = useCrmApi();

  const [user, setUser] = useState<any>();
  const [template, setTemplate] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<string | null>(null);

  useImperativeHandle(ref, () => ({
    open: (user) => {
      setUser(user);
    },
  }));

  const handleClose = () => {
    setUser(undefined);
    setOpenConfirm(false);
    setTemplate("");
    setPaymentLink(null);
  };

  const handleConfirm = async () => {
    callSendMailCrm.mutate({
      data: {
        id: user.id,
        mailBody: template,
        ini_type: Boolean(paymentLink) ? user?.iniType : null,
      },
      callback: () => {
        snackHandler("Success mail send!");
        handleClose();
        onInvalidateQuery?.();
      },
    });
  };

  useEffect(() => {
    if (!user) return;

    let link: string = "";
    const lang = user?.lang || "it";
    const isAdmission = Boolean(Number(user.tutoring));

    if (user.status == "Rejected") {
      link = `${USER_BASE}/payment/rejected/${user.token}`;
    } else if (user?.meeting) {
      link = getPaymentLink(user);

      setPaymentLink(link);
    }

    setTemplate(
      isAdmission
        ? getEmailAdmission({ link, name: user?.first_name })
        : getEmailWelcome({ link, lang, name: user?.first_name })
    );
  }, [user]);

  return (
    <EduModal
      fullWidth
      open={Boolean(user)}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        Welcome Email - {user?.first_name} {user?.last_name}
      </EduModalTitle>

      <EduModalBody>
        <Box className="h-[76vh] overflow-y-auto">
          <div dangerouslySetInnerHTML={{ __html: template }} />
        </Box>

        <Box className="m-4 flex gap-4">
          <ConfirmButton
            text="SEND"
            show={openConfirm}
            onClick={() => setOpenConfirm(true)}
            onClose={() => setOpenConfirm(false)}
            disabled={callSendMailCrm.isLoading}
            onConfirm={handleConfirm}
            isLoading={callSendMailCrm.isLoading}
          />
        </Box>
      </EduModalBody>
    </EduModal>
  );
});
