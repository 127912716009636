import { forwardRef, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { HTTP_ROUTE_EDIT } from ".";
import {
  Box,
  Switch,
  Popover,
  Typography,
  FormControlLabel,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import LANGUAGES from "@/components/assets/LANGUAGES";

export interface IData {
  token: string;
  column: string;
  values: Array<string>;
  anchorEl: any;
}

export interface IRef {
  open: (data: IData) => void;
}

interface IProps {
  onSwitchChange: (
    token: string,
    column: string,
    values: Array<string>
  ) => void;
}

const PopupActiveLanguages = forwardRef<IRef, IProps>(
  ({ onSwitchChange }, ref) => {
    const { snackHandler } = useAuth();

    const [data, setData] = useState<IData>();

    useImperativeHandle(ref, () => ({
      open: (data) => {
        const values: any = LANGUAGES.map((l) => ({
          [l.iso]: data?.values.includes(l.iso),
        }));

        data.values = values;

        setData(data);
      },
    }));

    const handleSwitchChange = async (key: string, checked: boolean) => {
      const d: any = { ...data };

      const i = d?.values.findIndex((l: any) => Object.keys(l)[0] == key);

      d.values[i][key] = checked;

      const values = d.values
        .filter((e: any) => Object.values(e)[0])
        .map((e: any) => Object.keys(e)[0]);

      setData(d);

      const res = await useAPI(HTTP_ROUTE_EDIT, {
        token: d?.token,
        [d?.column]: values,
      });

      if (!res?.status) {
        snackHandler("Failed change!", "error");
        return;
      }

      snackHandler("Success change!");
      onSwitchChange(d?.token, d?.column, values);
    };

    return (
      <Popover
        open={Boolean(data)}
        onClose={() => setData(undefined)}
        anchorEl={data?.anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box className="p-4">
          {data?.values?.map((e: any, i: number) => {
            const key = Object.keys(e)[0];

            return (
              <Box
                key={key + i}
                className="w-full flex gap-4 items-center justify-between"
              >
                <Box>{LANGUAGES?.find((l: any) => l.iso == key)?.name}</Box>

                <FormControlLabel
                  className="uppercase w-[6rem]"
                  name={key}
                  control={
                    <Switch
                      color="primary"
                      checked={e[key]}
                      onChange={(e) =>
                        handleSwitchChange(key, e.target.checked)
                      }
                    />
                  }
                  label={
                    <Typography color={e[key] ? "primary" : ""}>
                      {e[key] ? "on" : "off"}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Box>
            );
          })}
        </Box>
      </Popover>
    );
  }
);

export default PopupActiveLanguages;
