import useScreen from "@/hooks/useScreen";
import FiltersBarMobileSize from "./FiltersBarMobileSize";

const FiltersBar = (props: any) => {
  const { isMobile } = useScreen();

  if (!isMobile) return props.children;

  return <FiltersBarMobileSize {...props} />;
};

export default FiltersBar;
