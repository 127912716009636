import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Button,
  Popover,
  Switch,
  Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useAPI from '@/api/useAPI';

const languageMap = {
  en: 'English',
  it: 'Italian',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  pt: 'Portuguese'
};

interface LanguagesCellProps {
  languages: string[];
  adminId: number;
}

const cacheKey = ['callCenter']
const LanguagesCell: React.FC<LanguagesCellProps> = ({ languages, adminId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentLanguages, setCurrentLanguages] = useState(languages);
  const queryClient = useQueryClient();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const allTogglesOff = currentLanguages.length === 0;

  const updateLanguages = useMutation(
    async (updatedLanguages: string[]) => {
      const response = await useAPI(`v1/call-center/update/${adminId}`, { languages: updatedLanguages }, { type: 'put' });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );

  const handleToggleChange = (key: string) => {
    const newLanguages = currentLanguages.includes(key)
      ? currentLanguages.filter((lang) => lang !== key)
      : [...currentLanguages, key];
    setCurrentLanguages(newLanguages);
    updateLanguages.mutate(newLanguages);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        variant="contained"
        className="icon"
        sx={{ boxShadow: 'none', background: "white" }}
      >
        {allTogglesOff ? <VisibilityOffIcon color='secondary' /> : <VisibilityIcon color='primary' />}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 10 }}>
          {Object.entries(languageMap).map(([key, label]) => (
            <Box key={key} display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">{label}</Typography>
              <Switch
                checked={currentLanguages.includes(key)}
                onChange={() => handleToggleChange(key)}
              />
            </Box>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default LanguagesCell;
