import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { Box, Button } from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

import useAPI from "@/api/useAPI";

const EventFeedbackToolbar = (props) => {
  const { isMember } = useAuth();
  const { filterData, setFilters } = props;
  const tempDates = filterData?.dates?.split(",");
  const dates =
    tempDates?.length > 0
      ? [new Date(tempDates[0]), new Date(tempDates[1])]
      : null;

  const handleExport = async () => {
    const startDate = dates ? format(dates[0], "yyyy-MM-dd") : null;
    const endDate = dates ? format(dates[1], "yyyy-MM-dd") : null;
    const response = await useAPI('/v1/export-feedbacks', {
      start_date: startDate,
      end_date: endDate
    }, { type: 'post' });

    const blob = new Blob([response], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `event_feedbacks${startDate}_to_${endDate}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => {
            setFilters({});
          }}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>
        <Dropdown
          active={filterData?.dates ? true : false}
          text="Filter"
          icon={filterIcon}
          onClick={() => { }}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <DateRangePickerField
                title="Date"
                onChange={(date) => {
                  setFilters((prev) => {
                    if (date) {
                      prev.set(
                        "dates",
                        format(date.startDate, "yyyy-MM-dd HH:mm:ss") +
                        "," +
                        format(date.endDate, "yyyy-MM-dd HH:mm:ss")
                      );
                    } else {
                      prev.delete("dates");
                    }

                    return prev;
                  });
                }}
                value={dates}
              />
            </Box>
          }
        />

        {!isMember && (
          <Button
            variant="outlined"
            onClick={() => handleExport()}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        setFilters={setFilters}
        filters={filterData}
        client={true}
      />
    </Box>
  );
};

export default EventFeedbackToolbar;
