import { FC, memo } from "react";
import { Box } from "@mui/material";
import { STATUS } from "@/pages/Upselling/v1/Crm";
import { format, getDay, isEqual, isToday } from "date-fns";
import classNames from "classnames";

interface CalendarProps {
  days: Date[];
  status: string | null;
  isMobile: boolean;
  selectedDay: Date;
  availableDates: string[];
  setSelectedDay: (day: Date) => void;
  isLoadingEvents: boolean;
}

const colStartClasses = [
  "col-start-2",
  "col-start-1",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];

const Calendar: FC<CalendarProps> = ({
  days,
  status,
  isMobile,
  selectedDay,
  availableDates,
  setSelectedDay,
  isLoadingEvents,
}) => {
  return (
    <Box className="grid grid-cols-7 mt-2 gap-3 w-full">
      {days.map((day, dayIndex) => {
        const disabledButtons =
          status != "Da richiamare" && status != STATUS.ToBeCalled
            ? !availableDates?.includes(format(day, "yyyy-M-dd")) ||
              isLoadingEvents
            : day <= new Date() &&
              format(day, "yyyy-M-dd") != format(new Date(), "yyyy-M-dd");

        return (
          <Box
            key={day.toString()}
            className={classNames(
              "flex flex-col items-center",
              dayIndex === 0 && colStartClasses[getDay(day)],
              !isMobile && "py-2"
            )}
          >
            <button
              onClick={() => setSelectedDay(day)}
              disabled={disabledButtons}
              className={classNames(
                "text-gray-400 font-semibold flex items-center justify-center h-8 w-8 rounded-full",
                {
                  "border-2": isToday(day),
                  "hover:bg-slate-400/20":
                    !disabledButtons && !isEqual(day, selectedDay),
                  "bg-[#b388ff] text-white": isEqual(day, selectedDay),
                }
              )}
            >
              <time dateTime={format(day, "yyyy-MM-dd")}>
                {format(day, "d")}
              </time>
            </button>

            <Box
              className={classNames(
                !disabledButtons && `bg-[#b388ff]`,
                "w-2 h-1 mt-[2px] rounded-full"
              )}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(Calendar);
