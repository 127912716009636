import Fix from "./fix";
import Table from "./Table";
import Context from "./Context";
import Dialogs from "./dialogs";
import Popover from "./popover";
import BasePage from "@/components/Page/Base";

const Index = () => {
  return (
    <Context>
      <BasePage title="Crm">
        <Table />
      </BasePage>

      <Fix />
      <Dialogs />
      <Popover />
    </Context>
  );
};

export default Index;
