import { Theme } from "@mui/material";

export interface INStatus {
  Admin: string;
  NoBooked: string;
  MailSent: string;
  ToBeCalled: string;
  UpsellingScheduled: string;
  UpsellingDone: string;
  ToBeConverted: string;
  Reschedule: string;
  InterestedInTheFuture: string;
  StandBy: string;
  OfferRejected: string;
  NoShow: string;
  NotInterested: string;
  Upsold: string;
}

export interface INButtonCup {
  open: boolean;
  template: string;
  showConfirm: boolean;
  isLoadingConfirm: boolean;
}

export const defaultButtonCup: INButtonCup = {
  open: false,
  template: "",
  showConfirm: false,
  isLoadingConfirm: false,
};

export type TypeTemplate =
  | ""
  | "info"
  | "comments"
  | "otherStatus"
  | "email"
  | "changeEnglishLevel"
  | "purchaseHistory"
  |"feedbacks";

export interface INAnchorsEl {
  open?: boolean;
  value?: any;
  element?: any;
  content?: any;
  buttons?: Array<string>;
  template?: TypeTemplate;
}

export const defaultAnchorEl: INAnchorsEl = {
  open: false,
  value: "",
  element: "",
  content: "",
  buttons: [],
  template: "",
};

export interface INDialogsEl {
  firstMeeting: { value: boolean };
  templateEmail: { value: boolean };
  updateMeeting: { value: boolean };
  confirmPayment: { value: boolean };
}

export const defaultDialogsEl: INDialogsEl = {
  firstMeeting: { value: false },
  templateEmail: { value: false },
  updateMeeting: { value: false },
  confirmPayment: { value: false },
};

export interface INUpdateRow {
  toast?: string;
  values?: any;
  otherRows?: any;
  tokenUser?: string;
  activeApi?: boolean;
}

export const defaultFormMeetingDone: IFormMeetingDone = {
  id: null,
  notes: null,
  pacchetto: null,
  english_lvl: null,
  interviewer: null,
  newStartingMonth: null,
};

export interface INConfirmDialog {
  text: string;
  open: boolean;
  title: any;
  onAgree: null | Function;
  onDisagree: null | Function;
}

export const defaultConfirmDialog: INConfirmDialog = {
  text: "",
  open: false,
  title: "",
  onAgree: null,
  onDisagree: null,
};

export interface INCodeValues {
  code: string;
  product?: string;
  expires_at: number;
  newStartingMonth?: string;
}

// TODO: Find better types
export type CRMData = any;
export type CRMDataRow = any;
export type ProductsData = any;

export interface INUserMeeting {
  id: number;
  first_name: string;
  email: string;
  phone: string;
  token: string;
  status: string;
  last_name: string;
  updates: undefined | Array<any>;
  tutoring: boolean;
  meeting?: { meet: Array<any>; summary: string } | undefined;
  meetings?: { meet: Array<any>; summary: string } | undefined;
}

export interface IFormMeetingDone {
  id?: string | null;
  notes?: string | null;
  pacchetto?: string | null;
  motivation?: string | null;
  english_lvl?: string | null;
  interviewer?: string | null;
  payment_link?: string | null;
  certification?: string | null;
  newStartingMonth?: string | null;
}

export interface INButtons {
  NoShow?: string;
  Reschedule?: string;
  ToBeCalled?: string;
}

export interface INProps {
  open: boolean;
  onClose: () => void;
  data: {
    user: INUserMeeting;
    userAdmin: { user: { first_name: string; last_name: string } };
    buttons?: {
      noShow?: { icon: any; name?: string; status: string };
      toBeCall?: { icon: any; name?: string; status: string };
      reschedule?: {
        icon: any;
        name?: string;
        status: string;
        eventMeetingType?: string;
      };
      meetingDone?: {
        icon: any;
        name?: string;
        status: string;
        form: {
          id?: string | null;
          notes?: string | null;
          pacchetto?: string | null;
          motivation?: string | null;
          english_lvl?: string | null;
          interviewer?: string | null;
          payment_link?: string | null;
          certification?: string | null;
          newStartingMonth?: string | null;
        };
        setForm: (key: string, value: string) => void;
      };
    };
    versionStatus?: INStatus;
  };
  functions?: {
    onUpdateRow?: (param: INUpdateRow) => void;
    onMeetingDone?: (...params: any) => void;
    onSendFormUser?: (...params: any) => void;
    onMeetingDoneOther?: (...params: any) => void;
  };
  isUpselling?: boolean;
  dataGetToogle?: any;
}

export interface CrmMeetingEditVideoCallInfoProps {
  data: any;
  products: any;
  functions: any;
  isUpselling: any;
  dataGetToogle: any;
  selectedEvent: any;
  meetingsSorted: any[];
  newStartingMonths: any[];
  keysFormMeetingDone: any[];
  setOpenCalendarEvents: (open: boolean) => void;
  handleSetFormMeetingDone: (e: any) => void;
}

export interface Step2Props {
  data: any;
  setStep: (step: any) => void;
  functions: any;
  isUpselling: boolean;
}

export interface Step3Props {
  data: any;
  setStep: (step: any) => void;
  products: any;
  functions: any;
  otherEvent: boolean;
  isAdmission: boolean;
  selectedEvent: any;
  dataGetToogle: any;
  newStartingMonths: string[];
  keysFormMeetingDone: string[];
  handleSetFormMeetingDone: (event: any) => void;
}

export interface Step1Props {
  meetingsSorted: any[];
  setStep: (step: number) => void;
}

export interface CheckboxProps {
  checked: boolean;
}

export interface EmailTemplateProps {
  dataAPI: any;
  products: any;
  codeValues: INCodeValues;
  setCodeValues: (value: any) => void;
  rowSelected: any;
  newStartingMonths: string[];
  DEFAULT_EXPIRE_CODE: number;
}

export interface InfoTemplateProps {
  popoverInfo?: {
    path?: string;
    english_lvl?: string;
    newStartingMonth?: string;
    used?: number;
    code?: string;
    amount?: string;
    expires_at?: string;
  };
}

export interface Payment {
  id: number;
  token_user: string;
  amount: number;
  paid_for: string;
  product_id: number;
  product: {
    id: number;
    product_tag: string;
  };
}

export interface Feedback {
  id: number;
  id_user: number;
  id_evento: number;
  first_feedback: string;
  rating: number;
  punto: number;
  message: string | null;
  created_at: string;
}
export interface Payments {
  payments: Payment[];
}

export interface CommentsTemplateProps {
  theme: Theme;
  notesMeetings?: Array<{
    interviewer: string;
    start: string;
    notes: string;
  }>;
  comments: string;
  setComments: (comments: string) => void;
  rowSelected?: {
    crm_upselling?: {
      comments?: string;
    };
    first_name?: string;
    last_name?: string;
  };
  setConfirmDialogData: (data: INConfirmDialog) => void;
  onUpdateRow: (params: { toast: string; values: any }) => void;
  onCloseConfirmDialog: () => void;
}
