import { addDays, format, isSameDay, parseISO } from "date-fns";
import dayjs from "dayjs";

interface INBase {
  phone: string;
  nameUser: string;
}

export const baseWhatsApp = ({ phone, nameUser }: INBase) => {
  window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Ciao ${nameUser}.`, "_blank");
};

interface INTimer {
  phone: string;
  nameUser: string;
  meetings: Array<{ start: string }> | undefined;
  nameAdmin: string;
  snackHandler: any;
}

export const timerWhatsApp = ({
  meetings,
  nameUser,
  phone,
  nameAdmin,
  snackHandler,
}: INTimer) => {
  const meet = meetings
    ?.sort((a, b) => a.start.localeCompare(b.start))
    .find((meet) => new Date() <= new Date(meet.start));

  if (!meet) {
    snackHandler("No future events", "error");
    return;
  }

  const date = format(parseISO(meet.start), "yyyy-M-dd");

  let formatDate = "";
  let hour = format(parseISO(meet.start), "H:mm");

  if (isSameDay(parseISO(meet.start), new Date())) {
    formatDate = "oggi";
  } else if (
    new Date() < new Date(date) &&
    isSameDay(parseISO(meet.start), addDays(new Date(), 1))
  ) {
    formatDate = "domani";
  } else {
    hour = `ore ${hour}`;
    formatDate = `il giorno ${format(parseISO(meet.start), "dd")}`;
  }

  const link = `https://api.whatsapp.com/send?phone=${phone}&text=Ciao ${nameUser}, sono ${nameAdmin} di Edusogno! %0AVolevo ricordarti che hai fissato per ${formatDate} alle ${hour} il meeting per valutare il tuo livello di inglese e ricevere l'attestato del corso. %0ADovresti aver ricevuto l'invito per partecipare e lo dovresti vedere nel tuo calendario o direttamente nella casella della tua mail. %0ACi vediamo nella chiamata.`;

  window.open(link, "_blank");
};

interface INHammer {
  phone: string;
  nameUser: string;
  nameAdmin: string;
  expirationDateTime: string;
}

export const hammerWhatsApp = ({
  phone,
  nameUser,
  nameAdmin,
  expirationDateTime,
}: INHammer) => {
  const time = (format: string): string =>
    dayjs(expirationDateTime).format(format);

  // const link = `https://api.whatsapp.com/send?phone=${phone}&text=Ciao ${nameUser}, come stai? %0ASono ${nameAdmin} di Edusogno :) %0ATi abbiamo inviato la mail con il codice sconto per continuare a migliorare l'inglese con noi. %0APer usufruire dello sconto di cui ti parlavo è necessario finalizzare il pagamento entro le ${time(
  //   "HH"
  // )}:00${
  //   dayjs().isSame(dayjs(expirationDateTime), "day")
  //     ? ""
  //     : " del " + time("D") + " " + MONTHS[time("MMMM")]
  // }. %0ASono a tua disposizione per qualsiasi dubbio.`;

  const link = `https://api.whatsapp.com/send?phone=${phone}&text=Ciao ${nameUser}, come stai? %0ASono ${nameAdmin} di Edusogno :) %0ATi abbiamo inviato la mail con il codice sconto per continuare a migliorare l'inglese con noi. %0ATi scrivo solo per ricordarti che per usufruire della promozione è necessario finalizzare il pagamento entro stasera alle 20.00 e poi potrai far partire il corso quando vuoi tu! %0APer qualsiasi info sono a disposizione, fammi sapere!`;

  window.open(link, "_blank");
};
