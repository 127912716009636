import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import {
  Box,
  Badge,
  Avatar,
  Button,
  MenuItem,
  TextField,
  ButtonGroup,
} from "@mui/material";
import nextIcon from "@/assets/icons/next.svg";
import backIcon from "@/assets/icons/back.svg";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import dayOffIcon from "@/assets/icons/day-off.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import requestIcon from "@/assets/icons/request-day-off.svg";
import useDayOffApi from "@/api/useDayOffApi";

const TopBar = ({
  year,
  data,
  setYear,
  filters,
  keyQuery,
  setFilters,
  dayOffModal,
  requestDayOffModal,
}) => {
  const { userAdmin } = useAuth();
  const { callGetListAdminHaveDayOffs } = useDayOffApi();

  const { data: listAdmins } = callGetListAdminHaveDayOffs();

  const numberPending = (data || []).filter((d: any) => d.status === 0).length;

  const handleChange = ({ target: { name, value } }) => {
    setFilters((p: any) => {
      if (value) p.set(name, value);
      else p.delete(name);

      return p;
    });
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        {filters?.viewMode === "admin" && (
          <Button
            variant="outlined"
            onClick={() => {
              setFilters((p: any) => {
                p.delete("admin_id");
                return p;
              });

              queryClient.invalidateQueries(keyQuery);
            }}
          >
            <img
              src={refreshIcon}
              className="icon"
            />
            Refresh
          </Button>
        )}

        <Button
          variant="outlined"
          onClick={() => dayOffModal?.current?.open()}
        >
          <img
            src={dayOffIcon}
            className="icon"
          />
          Add time off
        </Button>

        {filters?.viewMode === "admin" && (
          <>
            <Dropdown
              active={filters?.admin_id ? true : false}
              text="Filter"
              icon={filterIcon}
            >
              <Box className="flex flex-col w-[250px] gap-5 p-5">
                <TextField
                  select
                  fullWidth
                  name="admin_id"
                  label="Admin"
                  value={filters?.admin_id || ""}
                  onChange={handleChange}
                >
                  {(listAdmins || []).map((admin) => (
                    <MenuItem
                      key={admin.id}
                      value={admin.id}
                    >
                      <Box className="grid grid-flow-col place-content-between w-full">
                        <Box>{`${admin.first_name} ${admin.last_name}`}</Box>

                        {admin.avatar ? (
                          <Avatar
                            style={{ height: "1.8rem", width: "1.8rem" }}
                            src={admin.avatar}
                            alt={`${admin.first_name} ${admin.last_name}`}
                          />
                        ) : (
                          <Avatar style={{ height: "1.8rem", width: "1.8rem" }}>
                            {admin.first_name.charAt(0).toUpperCase()}
                          </Avatar>
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Dropdown>

            <Badge
              badgeContent={numberPending}
              color="primary"
            >
              <Button
                className="w-full"
                variant="outlined"
                onClick={() => requestDayOffModal?.current?.open()}
              >
                <img
                  src={requestIcon}
                  className="icon"
                />
                Request
              </Button>
            </Badge>
          </>
        )}

        <Box className="ml-auto flex gap-4 flex-wrap">
          {userAdmin.user.admin === true && (
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
            >
              <Button
                variant={!filters?.viewMode ? "contained" : "outlined"}
                onClick={() => setFilters((p: any) => p.delete("viewMode"))}
              >
                Personal view
              </Button>

              <Button
                variant={
                  filters?.viewMode === "admin" ? "contained" : "outlined"
                }
                onClick={() =>
                  setFilters((p: any) => {
                    p.set("viewMode", "admin");
                    return p;
                  })
                }
              >
                Admin view
              </Button>
            </ButtonGroup>
          )}

          <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
          >
            <Button
              variant="contained"
              onClick={() => setYear(year - 1)}
            >
              <img
                src={backIcon}
                className="icon no-margin"
              />
            </Button>

            <Button
              variant="contained"
              className="font-bold"
            >
              {year}
            </Button>

            <Button
              variant="contained"
              onClick={() => setYear(year + 1)}
            >
              <img
                src={nextIcon}
                className="icon no-margin"
              />
            </Button>
          </ButtonGroup>
        </Box>
      </FiltersBar>
    </Box>
  );
};

export default TopBar;
