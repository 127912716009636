import { useState } from "react";
import EndlessBoard from "@/components/CanvasBoard";

const temp = [
  {
    x: 100,
    y: 100,
    id: 1,
    name: "Trigger 1",
    width: 200,
    height: 100,
  },
  {
    x: 200,
    y: 300,
    id: 2,
    name: "Trigger 2",
    width: 200,
    height: 100,
  },
];

const TriggersCanvas = () => {
  const [triggers, setTriggers] = useState(temp);

  return (
    <EndlessBoard
      triggers={triggers}
      setTriggers={setTriggers}
    />
  );
};

export default TriggersCanvas;
