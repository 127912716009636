import { forwardRef, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import commentIcon from "@/assets/icons/comments.svg";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export interface IRefNote {
  open: (user: any) => void;
}

interface IProps {
  keyQuery: Array<any>;
}

const Note = forwardRef<IRefNote, IProps>(({ keyQuery }, ref) => {
  const theme = useTheme();
  const { snackHandler } = useAuth();

  const [user, setUser] = useState<any>();
  const [content, setContent] = useState<string>("");
  const [addNote, setNewNote] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [commentIndex, setCommentIndex] = useState<number | null>(null);

  useImperativeHandle(ref, () => ({
    open: (user) => {
      setUser(user);
    },
  }));

  const handleSubmit = async () => {
    const res = await useAPI(`/v1/notes/${user?.token}`, {
      content,
      userToken: user?.token,
    });

    if (!res?.status) {
      snackHandler(res?.message || "Failed add commnet!", "error");
      return;
    }

    snackHandler("Success add commnet!");
    queryClient.invalidateQueries(keyQuery);
    setContent("");
    setNewNote(false);

    if (res?.data) {
      setUser((u: any) => {
        u.note = res?.data;
        return u;
      });
    }
  };

  const handleDeleteComment = async () => {
    setOpenConfirm(false);

    const res = await useAPI(`/v1/notes/${user?.token}/${commentIndex}/delete`);

    if (!res?.status) {
      snackHandler(res?.message || "Failed delete commnet!", "error");
      return;
    }

    snackHandler("Success delete commnet!");
    queryClient.invalidateQueries(keyQuery);

    if (res?.data) {
      setUser((u: any) => {
        u.note = res?.data;
        return u;
      });
    }
  };

  return (
    <>
      <EduModal
        fullWidth
        open={Boolean(user)}
        onClose={() => setUser(undefined)}
        maxWidth="sm"
      >
        <EduModalTitle onClose={() => setUser(undefined)}>
          <img src={commentIcon} />
          Comments
        </EduModalTitle>

        <EduModalBody className="p-5">
          <Box className="flex flex-col gap-4">
            {Boolean(user?.note?.length) ? (
              user?.note?.map((e: any, i: number) => (
                <Box key={i}>
                  <Box className="mb-2 flex justify-between items-center">
                    <Typography
                      color="primary"
                      fontSize="10px"
                    >
                      {e?.admin?.name} {e?.admin?.lname} wrote on {e?.created}
                    </Typography>

                    <Button
                      title="Delete Note"
                      variant="outlined"
                      onClick={() => {
                        setCommentIndex(i);
                        setOpenConfirm(true);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        className="icon no-margin"
                      />
                    </Button>
                  </Box>

                  <Box
                    className="flex flex-col border rounded mt-1"
                    sx={{
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    <Typography className="p-2">{e?.content}</Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography
                sx={{ textAlign: "center", color: "#B4B4B4" }}
                fontSize={16}
              >
                No comments here, add the first!
              </Typography>
            )}
          </Box>

          <Box className="flex gap-2 flex-col mt-5">
            {addNote ? (
              <>
                <Box className="flex gap-4 items-center justify-between grow">
                  <Box className="text-lg">New note</Box>
                </Box>

                <TextareaAutosize
                  value={content}
                  minRows={5}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Write here..."
                />

                <Box className="flex justify-end">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleSubmit}
                    disabled={!content}
                  >
                    <img
                      src={saveIcon}
                      className="icon"
                    />
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <Box className="flex justify-end">
                <Button
                  onClick={() => setNewNote(true)}
                  variant="outlined"
                >
                  <img
                    src={addIcon}
                    className="icon"
                  />
                  Add comment
                </Button>
              </Box>
            )}
          </Box>
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleDeleteComment}
      >
        Do you want to delete comment?
      </ConfirmationDialog>
    </>
  );
});

export default Note;
