import { useState } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
interface INProps {
  open: boolean;
  data: Array<any>;
  onSave: (event: object) => void;
  onClose: (...params: any) => void;
}

const EditCalendars = ({ open, data, onSave, onClose }: INProps) => {
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const handleChange = (e: any) => setSelectedAdmin(e.target.value);

  return (
    <EduModal
      {...{ open, onClose }}
      fullWidth
      maxWidth="sm"
    >
      <EduModalTitle onClose={onClose}>
        <img
          src={addIcon}
          className="icon"
        />
        Add calendar
      </EduModalTitle>

      <EduModalBody className="flex flex-col p-4">
        <TextField
          select
          fullWidth
          name="admin"
          label="Admin"
          value={selectedAdmin}
          onChange={handleChange}
        >
          {data
            ?.filter((d) => !d?.calendar)
            .map((e: any) => (
              <MenuItem
                key={e.uuid}
                value={e.uuid}
              >
                {e.first_name} {e.last_name}
              </MenuItem>
            ))}
        </TextField>

        <Box className="flex justify-end">
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
              if (selectedAdmin) {
                onSave(selectedAdmin);
              }
            }}
            className="!mt-4"
          >
            <img
              src={saveIcon}
              className="icon"
            />
            Save
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default EditCalendars;
