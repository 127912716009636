import { MouseEventHandler } from "react";
import { Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface INProps {
  onClose: MouseEventHandler<HTMLButtonElement> | undefined;
  children?: JSX.Element | string | any;
  className?: string;
}

export default ({ onClose, children, className }: INProps) => {
  return (
    <Box className={`flex justify-between items-center pt-6 px-8 pb-4 text-white ${className}`}>
      <Typography variant="h5" className="flex items-center">
        {children}
      </Typography>

      <Button style={{ padding: "0.7rem", minWidth: 0, borderRadius: "50%" }} onClick={onClose}>
        <CloseIcon color="primary" />
      </Button>
    </Box>
  );
};
