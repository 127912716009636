import { createRef, useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { Box, Button, useTheme } from "@mui/material";
import TemplatesWhatsappWeb, {
  IRef as ITemplatesWhatsappWebRef,
} from "./TemplatesWhatsappWeb";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import Dropdown from "@/components/_Common/Dropdown";
import useExportCSV from "@/components/useExportCSV";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import TableEditImageOwnedBy from "./TableEditImageOwnedBy";

const KEY_QUERY_IMAGES_OWNED_BY = ["crm-get-img-calendars"];

const Index = () => {
  const theme = useTheme();
  const { isAdmin, snackHandler } = useAuth();

  const templatesWhatsappWebRef = createRef<ITemplatesWhatsappWebRef>();

  const [startAuthLinkedin, setStartAuthLinkedin] = useState<boolean>(false);
  const [tokenAuthLinkedin, setTokenAuthLinkedin] = useState<boolean>(false);
  const [openTableImageOwnedBy, setOpenTableImageOwnedBy] =
    useState<boolean>(false);

  const { data: imgCalendars, isLoading: isLoadingImgCalendars } = useQuery(
    KEY_QUERY_IMAGES_OWNED_BY,
    () => useAPI("/v1/crm/get-img-calendars")
  );

  const hasOneImageOwnedBy =
    typeof imgCalendars == "object"
      ? Boolean(Object.keys(imgCalendars)?.length)
      : false;

  const handleDownloadUtmVertigo = async () => {
    const res = await useAPI("/v1/crm/download/status-utm_vertigo");
    useExportCSV(res, "Created_at-status-utm_vertivo");
  };

  const handleSetStartAuthLinkedin = async () => {
    setStartAuthLinkedin(false);

    const link = await useAPI(
      "/linkedin/start-set-auth",
      {},
      { pathLiberty: true }
    );

    if (!link) {
      snackHandler("Failed get link!", "error");
      return;
    }

    window.open(link, "_blank");
  };

  const handleSetTokenAuthLinkedin = async () => {
    setTokenAuthLinkedin(false);

    const res = await useAPI(
      "/linkedin/set-token-auth",
      {},
      { pathLiberty: true }
    );

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed get auth Linkedin!", "error");
      return;
    }

    snackHandler("Success set auth Linkedin!");
  };

  useEffect(() => { }, [isLoadingImgCalendars]);

  return (
    <>
      <BasePage
        title="Settings crm"
        className="flex gap-4"
      >
        <Button
          variant="outlined"
          onClick={() => setOpenTableImageOwnedBy(true)}
        >
          Edit images column "owned by"
        </Button>

        <Button
          variant="outlined"
          onClick={handleDownloadUtmVertigo}
        >
          Download utm_vertigo
        </Button>

        <Button
          variant="outlined"
          onClick={() => templatesWhatsappWebRef?.current?.open()}
        >
          Templates whatsapp web
        </Button>

        {Boolean(isAdmin) && (
          <Dropdown text="Auth Linkedin">
            <Box className="flex flex-col gap-3 p-3">
              <Button
                title="Set token for auth into Linkedin as Pixel for leads and events"
                variant="outlined"
                onClick={() => setTokenAuthLinkedin(true)}
              >
                Set token auth Linkedin
              </Button>

              <Button
                title="Set token for auth into Linkedin as Pixel for leads and events"
                variant="outlined"
                onClick={() => setStartAuthLinkedin(true)}
              >
                Set start auth Linkedin
              </Button>
            </Box>
          </Dropdown>
        )}
      </BasePage>

      <TableEditImageOwnedBy
        rows={imgCalendars}
        open={openTableImageOwnedBy && hasOneImageOwnedBy}
        theme={theme}
        onClose={() => setOpenTableImageOwnedBy(false)}
        keyQuery={KEY_QUERY_IMAGES_OWNED_BY}
      />

      <TemplatesWhatsappWeb ref={templatesWhatsappWebRef} />

      <ConfirmationDialog
        open={tokenAuthLinkedin}
        title="Set auth Linkedin"
        onClose={() => setTokenAuthLinkedin(false)}
        onConfirm={handleSetTokenAuthLinkedin}
      >
        Do you want to set auth Linkedin?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={startAuthLinkedin}
        title="Open link for set auth Linkedin"
        onClose={() => setStartAuthLinkedin(false)}
        onConfirm={handleSetStartAuthLinkedin}
      >
        Do you want to open link for start set auth Linkedin?
      </ConfirmationDialog>
    </>
  );
};

export default Index;
