import { Dispatch, SetStateAction } from "react";
import { LoadingButton } from "@mui/lab";
import { INEditPayRate, INKeyTutor, KeysTutor } from "./interfaces";
import { Box, Button, TextField, Autocomplete } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import saveIcon from "@/assets/icons/save.svg";

interface INProps {
  editPayRate: INEditPayRate | undefined;
  setEditPayRate: Dispatch<SetStateAction<INEditPayRate | undefined>>;
  handleOnEditPayRate: (updatedEditPayRate: INEditPayRate | undefined) => void;
}

const EditPayRate = ({
  editPayRate,
  setEditPayRate,
  handleOnEditPayRate,
}: INProps) => {
  const firstValidate = Boolean(
    editPayRate?.disabled || !Object.keys(editPayRate?.newData || {}).length
  );

  const handleClose = () => {
    setEditPayRate((p: INEditPayRate | undefined) => ({ ...p, open: false }));
  };

  const handleConfirmClick = () => {
    setEditPayRate((p: INEditPayRate | undefined) => {
      const newEditPayRate = {
        ...p,
        newData: p?.defaultData,
      };

      handleOnEditPayRate(newEditPayRate);

      return newEditPayRate;
    });
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(editPayRate?.open)}
      onClose={handleClose}
      maxWidth="xs"
    >
      <EduModalTitle onClose={handleClose}>Edit Pay Rate</EduModalTitle>

      <EduModalBody className="flex flex-col p-5 pt-7 gap-4">
        <Box className="grid grid-flow-row-dense ">
          {KeysTutor.map((e: INKeyTutor, i: number) => {
            let component: JSX.Element | null = null;

            if (Boolean(e?.options?.length)) {
              let value: Array<string> | string =
                editPayRate?.newData?.[e.key] ||
                editPayRate?.defaultData?.[e.key] ||
                [];

              if (e.key === "pay_rate") {
                component = (
                  <Autocomplete
                    id="tags-standard"
                    value={value}
                    options={e?.options || []}
                    multiple={Boolean(e?.multiple)}
                    onChange={(_: any, value: any) => {
                      setEditPayRate((p: INEditPayRate | undefined) => ({
                        ...p,
                        newData: { ...p?.newData, [e.key]: value },
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={e.label}
                        variant="outlined"
                        required={Boolean(e?.required)}
                        placeholder={(e?.options || [])?.[0] || ""}
                      />
                    )}
                    getOptionLabel={(option) => option || ""}
                  />
                );
              }
            }

            return <Box key={i}>{component}</Box>;
          })}
        </Box>

        <Box className="w-full flex justify-end items-end gap-2">
          <LoadingButton
            variant="outlined"
            onClick={() => handleOnEditPayRate(editPayRate)}
            loading={Boolean(editPayRate?.isLoading)}
            disabled={firstValidate || Boolean(editPayRate?.isLoading)}
          >
            <img
              src={saveIcon}
              className={`icon ${
                Boolean(editPayRate?.isLoading) ? "opacity-0" : ""
              }`}
            />
            Save
          </LoadingButton>

          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmClick}
            disabled={Boolean(editPayRate?.isLoading)}
          >
            Confirm
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default EditPayRate;
