import { Dispatch, SetStateAction, memo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import commentIcon from "@/assets/icons/comments.svg";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export interface INComments {
  token: string;
  comments: Array<{
    name: string;
    date: string;
    content: string;
  }>;
}

interface IProps {
  open: boolean;
  data: INComments;
  label?: string;
  setData: Dispatch<SetStateAction<INComments>>;
  onClose: () => void;
  onAgree: {
    path: string;
    callback?: (...params: any) => void;
  };
  onDisagree: {
    path: string;
    callback?: (...params: any) => void;
  };
  keyQueryInvalidate?: Array<any>;
}

const Comments = ({
  open,
  data,
  label = "Comments",
  setData,
  onClose,
  onAgree,
  onDisagree,
  keyQueryInvalidate,
}: IProps) => {
  const { palette } = useTheme();
  const { snackHandler } = useAuth();

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [commentIndex, setCommentIndex] = useState<number | null>(null);

  const [addNote, setNewNote] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");

  const handleAgree = async () => {
    const dataNewComment = {
      token: data.token,
      content,
    };

    const res = await useAPI(onAgree.path, dataNewComment);

    if (res?.status == false) {
      snackHandler(res?.message ?? "Error created!", "error");
      return;
    }

    snackHandler("Success created!");
    setData({ ...data, comments: res?.data });
    setContent("");
    setNewNote(false);
    if (keyQueryInvalidate?.length) {
      queryClient.invalidateQueries(keyQueryInvalidate);
    }
    onAgree?.callback?.(res);
  };

  const handleDeleteComment = async () => {
    const paramsApi = {
      token: data.token,
      commentIndex,
    };

    const res = await useAPI(onDisagree.path, paramsApi);

    if (res?.status == false) {
      snackHandler(res?.message ?? "Error delete!", "error");
      return;
    }

    snackHandler("Success delete!");
    setData({ ...data, comments: res?.data });
    setCommentIndex(null);
    setOpenConfirm(false);
    if (keyQueryInvalidate?.length) {
      queryClient.invalidateQueries(keyQueryInvalidate);
    }
    onDisagree?.callback?.(res);
  };

  const handleClose = () => {
    onClose();
    setContent("");
    setNewNote(false);
  };

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <img
            src={commentIcon}
            className="icon"
          />
          {label}
        </EduModalTitle>

        <EduModalBody className="p-4">
          <Box className="flex flex-col gap-4">
            {Array.isArray(data?.comments) && data?.comments?.length > 0 ? (
              data?.comments?.map((e, i) => (
                <Box
                  key={e.date + i}
                  className="border rounded p-3"
                  sx={{ borderColor: palette.primary.main }}
                >
                  <Box className="mb-2 flex justify-between items-center">
                    <Box color={palette.primary.main}>
                      {e.name} wrote on {e.date}
                    </Box>

                    <Button
                      title="Delete Note"
                      variant="text"
                      onClick={() => {
                        setCommentIndex(i);
                        setOpenConfirm(true);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        className="icon no-margin"
                      />
                    </Button>
                  </Box>

                  <Box>{e.content}</Box>
                </Box>
              ))
            ) : (
              <Typography
                sx={{ textAlign: "center", color: "#B4B4B4" }}
                fontSize={16}
              >
                No comments here, add the first!
              </Typography>
            )}
          </Box>

          <Box className="flex gap-2 flex-col mt-5">
            {addNote ? (
              <>
                <Box className="flex gap-4 items-center justify-between grow">
                  <Box className="text-lg">
                    New {label.at(-1) == "s" ? label.slice(0, -1) : label}
                  </Box>
                </Box>

                <TextareaAutosize
                  value={content}
                  minRows={5}
                  maxRows={7}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Write here..."
                />

                <Box className="flex justify-end">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleAgree}
                    disabled={!content}
                  >
                    <img
                      src={saveIcon}
                      className="icon"
                    />
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <Box className="flex justify-end">
                <Button
                  variant="outlined"
                  onClick={() => setNewNote(true)}
                >
                  <img
                    src={addIcon}
                    className="icon"
                  />
                  Add note
                </Button>
              </Box>
            )}
          </Box>
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleDeleteComment}
      >
        {`Do you want to delete comment?`}
      </ConfirmationDialog>
    </>
  );
};

export default memo(Comments);
