import { useState, memo } from "react";
import {
  Chip,
  useTheme,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const AutoCompleteInput = ({
  data,
  label,
  value,
  loading,
  required = true,
  setValue,
  multiple = true,
  fullWidth,
  placeholder = "",
  closeSelect,
  onClickItem = () => {},
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (_, newValue) => setValue(newValue);

  const filterOptions = (options, params) => {
    const filteredOptions = options.filter((option) => {
      // Customize this condition to match your filtering logic based on user input.
      return option.email
        .toLowerCase()
        .includes(params.inputValue.toLowerCase());
    });

    // Return the first 10 options.
    return filteredOptions.slice(0, 10);
  };

  return (
    <Autocomplete
      id={label}
      open={open}
      value={value}
      color="primary"
      onOpen={() => setOpen(true)}
      options={data}
      loading={loading}
      onClose={() => setOpen(false)}
      required={required}
      multiple={multiple}
      onChange={handleChange}
      fullWidth={fullWidth}
      renderOption={(props, option, { selected }) => (
        <MyLi {...{ props, option, selected }} />
      )}
      ChipProps={{ color: "primary", variant: "outlined" }}
      inputValue={inputValue}
      renderInput={(params) => (
        <MyTextField {...{ params, label, placeholder, required, loading }} />
      )}
      filterOptions={filterOptions}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      getOptionLabel={(option) => option.email}
      disableCloseOnSelect={closeSelect}
      isOptionEqualToValue={(option, value) => option?.token === value?.token}
      {...(onClickItem
        ? {
            renderTags: (tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={option.email}
                  sx={{
                    color: theme.palette.primary.main,
                    border: "1px solid " + theme.palette.primary.main,
                    background: "none",
                  }}
                  onClick={(e) => onClickItem(e, option)}
                />
              )),
          }
        : {})}
    />
  );
};

function MyLi({ props, option, selected }) {
  return (
    <li
      {...props}
      key={props.key + props["data-option-index"]}
    >
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option.email}
    </li>
  );
}

function MyTextField({ params, label, placeholder, required, loading }) {
  return (
    <TextField
      {...params}
      label={label + (required ? " *" : "")}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && (
              <CircularProgress
                color="primary"
                size={20}
              />
            )}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
}

export default memo(AutoCompleteInput);
