import { memo, useEffect, useState } from "react";
import { Button, Popover, TextField, useTheme } from "@mui/material";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import dateIcon from "@/assets/icons/date.svg";

interface IDateRangePicker {
  icon?: any;
  value: Array<Date> | null;
  title?: string;
  disabled?: boolean;
  onChange: (data: { startDate: Date; endDate: Date } | null) => void;
  label?: string;
}

const DateRangePickerField = ({
  onChange,
  value,
  disabled,
  icon,
  title,
  label,
}: IDateRangePicker) => {
  const [changed, setChanged] = useState(false as boolean);
  const theme = useTheme();

  const from = new Date();
  from.setHours(0, 0, 0);

  const to = new Date();
  to.setHours(23, 59, 59);

  const [state, setState] = useState([
    { startDate: from, endDate: to, key: "selection" },
  ]);

  useEffect(() => {
    if (value && value.length > 0) {
      setState([
        {
          startDate: new Date(value[0]),
          endDate: new Date(value[1]),
          key: "selection",
        },
      ]);
      setChanged(true);
    } else {
      setState([
        { startDate: new Date(), endDate: new Date(), key: "selection" },
      ]);
    }
  }, [value]);

  const date = state[0];
  const startDate = format(date.startDate, "dd MMM yy");
  const endDate = format(date.endDate, "dd MMM yy");

  const defineds = {
    // Today
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),

    // Last 7 days
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),

    // Last 4 weeks
    startOfLast4Weeks: startOfDay(addWeeks(new Date(), -4)),

    // Last 3 months
    startOfLast3Months: startOfDay(addMonths(new Date(), -3)),

    // Last 12 months
    startOfLast12Months: startOfDay(addMonths(new Date(), -12)),

    // Current week
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),

    // current month
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),

    // Current year
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
  };

  const sideBarOptions = [
    {
      label: "Today",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 4 Weeks",
      range: () => ({
        startDate: defineds.startOfLast4Weeks,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 3 months",
      range: () => ({
        startDate: defineds.startOfLast3Months,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Last 12 months",
      range: () => ({
        startDate: defineds.startOfLast12Months,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Current Week",
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },
    {
      label: "Current Month",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: "Current Year",
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    },
  ];

  const staticRanges = [...createStaticRanges(sideBarOptions)];

  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "daterangepicker-popover" : undefined;

  let displayText = "";

  if (changed && startDate && endDate) {
    displayText =
      startDate === endDate ? startDate : `${startDate} - ${endDate}`;
  }
  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleClick}
        disabled={disabled}
        label={label || title}
        value={displayText}
        InputProps={{
          endAdornment: (
            <Button
              onClick={handleClick}
              sx={{ minWidth: "19px", padding: 0, margin: 0 }}
            >
              {icon ? (
                icon
              ) : (
                <img
                  src={dateIcon}
                  className="icon"
                  style={{ margin: "0px" }}
                />
              )}
            </Button>
          ),
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            overflowX: "auto",
          }}
        >
          <DateRangePicker
            onChange={(item: any) => {
              let startDate: Date = new Date(
                item.selection.startDate.valueOf()
              );
              let endDate: Date = new Date(item.selection.endDate.valueOf());
              const key = item.selection.key;

              startDate.setHours(0, 0, 0, 0);
              endDate.setHours(23, 59, 59, 999);

              setState(() => [{ startDate, endDate, key }]);
              setChanged(true);
            }}
            rangeColors={[theme.palette.primary.main]}
            color={theme.palette.primary.main}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            editableDateInputs={false}
            months={1}
            ranges={state}
            direction="horizontal"
            staticRanges={staticRanges}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              style={{
                paddingLeft: "25px",
                paddingBottom: "10px",
                color: "black",
              }}
              onClick={() => {
                setChanged(false);
                setState([{ startDate: from, endDate: to, key: "selection" }]);
              }}
            >
              Clear
            </button>

            <Button
              size="small"
              variant="outlined"
              style={{ marginRight: "25px", marginBottom: "10px" }}
              onClick={() => {
                if (changed) {
                  onChange(state[0]);
                } else onChange(null);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default memo(DateRangePickerField);
