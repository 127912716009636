import { useEffect, useRef, useState } from "react";
import { Clear } from "@mui/icons-material";
import { TextField, useTheme } from "@mui/material";
import { GridToolbarQuickFilter, useGridApiContext } from "@mui/x-data-grid";
import searchIcon from "@/assets/icons/search.svg";
import useDebounce from "@/hooks/useDebounce";

interface IProps {
  client?: boolean;
  filters?: any;
  setPage?: any;
  className?: string;
  setFilters?: any;
}

const SearchToolbar = ({
  filters,
  className,
  setFilters,
  client = false,
}: IProps) => {
  const theme = useTheme();

  const refSearch = useRef<any>(null);

  const [typed, setTyped] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(filters?.search ?? null);

  const apiRef = useGridApiContext();

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
    setTyped(true);
  };

  const handleClear = () => {
    setSearch(null);
    refSearch.current?.focus?.();
  };

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (!filters?.search) {
      setSearch(null);
    }
  }, [filters?.search]);

  useEffect(() => {
    if (setFilters)
      setFilters(
        (p: any) => {
          if (debouncedSearch) {
            p.set("search", debouncedSearch);

            if (client) {
              apiRef.current.setQuickFilterValues([debouncedSearch]);
            }
          } else {
            p.delete("search");

            if (client) {
              apiRef.current.setQuickFilterValues([]);
            }
          }

          if (typed) {
            p.delete("page");
          }

          setTyped(false);

          return p;
        },
        { replace: true }
      );
  }, [debouncedSearch]);

  if (setFilters) {
    return (
      <TextField
        sx={{ marginLeft: "auto", maxWidth: "220px" }}
        size="small"
        name="search_input"
        value={search || ""}
        variant="outlined"
        onChange={handleSearchChange}
        inputRef={refSearch}
        className={className}
        InputProps={{
          sx: { p: 0, pl: 1.4 },
          startAdornment: (
            <img
              src={searchIcon}
              style={{
                marginRight: 10,
                filter: theme.palette.filter,
              }}
              className="cursor-default"
            />
          ),
          endAdornment: search ? (
            <button
              onClick={handleClear}
              className="px-2 h-full hover:bg-slate-200/10 rounded-sm flex justify-center items-center"
            >
              <Clear />
            </button>
          ) : undefined,
        }}
        placeholder="Search..."
      />
    );
  }

  return (
    <GridToolbarQuickFilter
      sx={{ ml: "auto", py: 0, my: 0, alignSelf: "stretch" }}
      size="small"
      title="Search..."
      variant="outlined"
      quickFilterParser={(searchInput: string) =>
        searchInput
          .split(" ")
          .map((v) => v.trim())
          .filter((v) => v != "")
      }
    />
  );
};

export default SearchToolbar;
