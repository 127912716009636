import { createRef } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DetailsMeets, { IDetailsMeetsRef } from "./DetailsMeets";
import useAPI from "@/api/useAPI";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import useProductApi from "@/api/useProductApi";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const ProductionKpi = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { isAdmission, isMember, userAdmin } = useAuth();

  const detailsMeetsRef = createRef<IDetailsMeetsRef>();

  const products = useProductApi().callGetProducts()?.data?.products || [];

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const KEY_QUERY = [
    "interview-performance",
    "kpis-products",
    filterData,
    isAdmission,
  ];

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () =>
      useAPI("/v1/interview-performance/kpis-products", {
        filters: {
          ...filterData,
          ...(isMember
            ? {
              interviewer:
                userAdmin.user.first_name + " " + userAdmin.user.last_name,
            }
            : {}),
        },
        isAdmission,
      }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const renderHeader = (nameColumn: string) => ({
    renderHeader: ({ field }) => (
      <Box className="MuiDataGrid-columnHeaderTitle ">
        {Boolean(dataAPI?.columns?.[field]) && (
          <span className="number">{dataAPI?.columns?.[field]}</span>
        )}
        {nameColumn}
      </Box>
    ),
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name product",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row?.product_tag,
      renderCell: ({ row }) => <MyTooltip>{row?.product_tag}</MyTooltip>,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.5,
      ...(isMember ? {} : renderHeader("Price")),
      valueGetter: ({ row }) => row?.price + "€" || "",
    },
    {
      field: "proposed",
      headerName: "Proposed",
      minWidth: 100,
      flex: 0.5,
      ...(isMember ? {} : renderHeader("Proposed")),
    },
    {
      field: "sold",
      headerName: "Sold",
      minWidth: 90,
      flex: 0.5,
      ...(isMember ? {} : renderHeader("Sold")),
    },
    {
      field: "revenues",
      headerName: "Revenues",
      minWidth: 100,
      flex: 0.5,
      ...(isMember ? {} : renderHeader("Revenues")),
      valueGetter: ({ row }) => row?.revenues + "€" || "",
    },
    {
      field: "conversion_rate",
      headerName: "Conversion rate",
      minWidth: 100,
      flex: 0.5,
      ...(isMember ? {} : renderHeader("Conversion rate")),
      valueGetter: ({ row }) =>
        parseFloat(row?.conversion_rate.replace("%", "")),
      renderCell: ({ row }) => row?.conversion_rate,
    },
    {
      field: "action",
      headerName: "",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        row?.details ? (
          <Button
            variant="outlined"
            onClick={() =>
              detailsMeetsRef.current?.open(
                row.details,
                row?.name || row?.product_tag
              )
            }
          >
            <KeyboardArrowRightIcon />
          </Button>
        ) : null,
    },
  ];

  return (
    <BasePage title={"KPIS Products" + (isAdmission ? " admission" : "")}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={dataAPI?.rows || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={(row) => row.token}
        components={{ Toolbar, Pagination: CustomPaginationClient }}
        componentsProps={{
          toolbar: {
            isMember,
            products,
            setFilters,
            filters: filterData,
            fileName: "KPIs Products",
            KEY_QUERY: KEY_QUERY,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <DetailsMeets
        ref={detailsMeetsRef}
        theme={theme}
      />
    </BasePage>
  );
};

export default ProductionKpi;
