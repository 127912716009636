import {
  useState,
  createRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { clone, fUpperWords } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { MoreVert } from "@mui/icons-material";
import { queryClient } from "@/main";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Switch,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
} from "@mui/material";
import DialogTemplate, { IRef as IDialogTemplateRef } from "./DialogTemplate";
import useAPI from "@/api/useAPI";
import Toolbar from "./Toolbar";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import deleteIcon from "@/assets/icons/delete.svg";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import BUTTONS_CRM_WHATSAPP from "@/components/assets/BUTTONS_CRM_WHATSAPP";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const QUERY_KEY = ["templates-whatsapp-web"];

interface ITemplate {
  id: number;
  lang: string;
  button: string;
  hidden: boolean;
  template: string;
}

export interface IRef {
  open: () => void;
}

interface IProps { }

const TemplatesWhatsappWeb = forwardRef<IRef, IProps>((_, ref) => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler } = useAuth();

  const dialogTemplateRef = createRef<IDialogTemplateRef>();

  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<GridRowsProp<ITemplate>>([]);
  const [rowIdDelete, setRowIdDelete] = useState<number>();

  const { data: dataAPI, isLoading } = useQuery(
    QUERY_KEY,
    () => useAPI("/v1/crm/templates-whatsapp-web"),
    { enabled: open, keepPreviousData: true, refetchOnWindowFocus: true }
  );

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    const res = await useAPI(
      `/v1/crm/templates-whatsapp-web/${rowIdDelete}/delete`
    );

    if (!res?.status) {
      snackHandler(
        res?.message ?? "Failed delete template " + rowIdDelete,
        "error"
      );
      return;
    }

    setRowIdDelete(undefined);
    setRows((r) => r.filter((r) => r.id != rowIdDelete));
    snackHandler("Success delete");
    queryClient.invalidateQueries(QUERY_KEY);
  };

  const handleHidden = async (id: any, hidden: boolean) => {
    const oldRows = clone(rows);

    setRows((rows) => {
      const indexRow = rows.findIndex((r: any) => r.id == id);
      rows[indexRow].hidden = hidden;
      return rows;
    });

    const res = await useAPI(`/v1/crm/templates-whatsapp-web/${id}/visibility`);

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed update visibility!", "error");
      setRows(oldRows);
      return;
    }

    if (res?.data?.length) {
      setRows(res?.data);
    }
    snackHandler("Success update visibility!");
    queryClient.invalidateQueries(QUERY_KEY);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "lang",
      headerName: "Lang",
      width: 60,
    },
    {
      field: "template",
      headerName: "Template",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.template}</MyTooltip>,
    },
    {
      field: "button",
      headerName: "Button",
      width: 130,
      renderCell: ({ row }) => {
        const button = BUTTONS_CRM_WHATSAPP?.[row?.button];
        if (!button) return "-";
        return (
          <Box
            title={button?.label}
            className="w-full flex gap-3"
          >
            <span>{button?.icon}</span>
            <span className="truncate">{button?.label}</span>
          </Box>
        );
      },
    },
    {
      field: "version",
      headerName: "Version",
      width: 130,
      valueGetter: ({ row }) => fUpperWords(row.version.replace("-", " ")),
      renderCell: ({ row }) => (
        <MyTooltip>{fUpperWords(row.version.replace("-", " "))}</MyTooltip>
      ),
    },
    {
      field: "hidden",
      headerName: "Use",
      width: 90,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <FormControlLabel
          label={row?.hidden ? "NO" : "YES"}
          control={
            <Switch
              name="hidden"
              checked={!row?.hidden}
              disabled={!row?.hidden}
              onChange={(e) => handleHidden(row.id, !e.target.checked)}
            />
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              title="Edit"
              color="primary"
              onClick={() => dialogTemplateRef?.current?.edit(row)}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            {row?.hidden && (
              <MenuItem
                title="Delete"
                color="primary"
                onClick={() => setRowIdDelete(row.id)}
              >
                <ListItemIcon>
                  <img
                    src={deleteIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (!dataAPI?.length) return;
    setRows(dataAPI);
  }, [dataAPI, isLoading]);

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="xl"
      >
        <EduModalTitle onClose={handleClose}>
          <i
            style={{ fontSize: 16 }}
            className="fa-brands fa-whatsapp icon ml-1"
          />
          Templates whatsapp web
        </EduModalTitle>

        <EduModalBody className="p-5">
          <DataGrid
            autoHeight
            disableColumnMenu
            disableVirtualization
            disableSelectionOnClick
            sx={getTableStyling(theme)}
            rows={rows || []}
            columns={columns}
            loading={isLoading}
            pageSize={10}
            hideFooter={rows?.length <= 10}
            components={{
              Toolbar: Toolbar,
              Pagination: CustomPaginationClient,
            }}
            componentsProps={{
              toolbar: {
                QUERY_KEY,
                dialogTemplateRef,
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            rowsPerPageOptions={[perPage]}
            experimentalFeatures={{ newEditingApi: false }}
          />
        </EduModalBody>
      </EduModal>

      <DialogTemplate
        ref={dialogTemplateRef}
        {...{ QUERY_KEY, snackHandler }}
      />

      <ConfirmationDialog
        open={rowIdDelete != undefined}
        onClose={() => setRowIdDelete(undefined)}
        onConfirm={handleConfirmDelete}
      >
        Do you want delete this template {rowIdDelete}
      </ConfirmationDialog>
    </>
  );
});

export default TemplatesWhatsappWeb;
