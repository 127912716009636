import { useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { Box, Button, TextField, useTheme } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";

const KEY_QUERY = ["settings", "flow", "enable-disable-emails-upselling"];

const HTTP_BASE = "/v1/settings/flow";
const HTTP_GET = HTTP_BASE + "/get/enable-disable-emails-upselling";
const HTTP_SET = HTTP_BASE + "/set/enable-disable-emails-upselling";

interface IOpenModals {
  emailsUpselling: boolean;
}

const defaultOpenModals: IOpenModals = {
  emailsUpselling: false,
};

type MODAL = keyof typeof defaultOpenModals;

const UsersSettings = ({ }) => {
  const theme = useTheme();
  const { userAdmin } = useAuth();

  const { data, isLoading } = useQuery(KEY_QUERY, () => useAPI(HTTP_GET));

  const [emails, setEmails] = useState<Array<string>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [lastAdmin, setLastAdmin] = useState<string>();
  const [openModals, setOpenModals] = useState<IOpenModals>(defaultOpenModals);

  const handleEditModals = (modals: Array<MODAL> | MODAL, status = false) => {
    setOpenModals((p: IOpenModals) => {
      const t = { ...p };
      if (typeof modals === "string") {
        t[modals] = status;
      } else {
        modals.forEach((modal: string) => {
          t[modal] = status;
        });
      }
      return t;
    });
  };

  const handleSubmit = async (remove: boolean = true) => {
    setLoading(true);

    const res = await useAPI(HTTP_SET, {
      emails,
      remove,
      admin: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
    });

    setLoading(false);
    setEmails(res?.data?.emails);
    setLastAdmin(res?.data?.admin);
  };

  useEffect(() => {
    if (!data?.data) return;
    setEmails(data?.data?.emails);
    setLastAdmin(data?.data?.admin);
  }, [isLoading]);

  return (
    <BasePage title="Settings users">
      <Button
        variant="outlined"
        onClick={() => handleEditModals("emailsUpselling", true)}
      >
        Edit flux email/s Upselling
      </Button>

      <EduModal
        open={openModals.emailsUpselling}
        onClose={() => handleEditModals("emailsUpselling")}
        fullWidth
        maxWidth="sm"
      >
        <EduModalTitle onClose={() => handleEditModals("emailsUpselling")}>
          Enable/Disable flux send email/s Upselling
        </EduModalTitle>

        <EduModalBody className="p-4 flex flex-col gap-4">
          <Box className="flex justify-between gap-4">
            {Boolean(lastAdmin) && <Box>Last update: {lastAdmin}</Box>}
            {Boolean(loading) && (
              <Box color={theme.palette.primary.main}>PULL</Box>
            )}
          </Box>

          <TextField
            multiline
            fullWidth
            rows={3}
            label="Email/s"
            InputLabelProps={{ shrink: true }}
            placeholder="Write emails with separator comma"
            value={emails ? emails.join(",") : ""}
            className="!mt-2"
            onChange={(e) => {
              const value = e.target.value;
              setEmails(value ? value.split(",") : []);
            }}
          />

          <Box className="flex justify-end gap-4">
            <Button
              color="error"
              variant="outlined"
              onClick={() => handleSubmit()}
              disabled={!emails?.length || loading}
            >
              Disable
            </Button>

            <Button
              variant="outlined"
              onClick={() => handleSubmit(false)}
              disabled={!emails?.length || loading}
            >
              Enable
            </Button>
          </Box>
        </EduModalBody>
      </EduModal>
    </BasePage>
  );
};

export default UsersSettings;
