import { useRef, useState } from "react";
import { Button } from "@mui/material";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import axios from "axios";
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@/assets/icons/download.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ConfirmationDialog from "../ConfirmationDialog";

const BASE_ADMIN = import.meta.env.VITE_BASE_URL;

type Props = {
  token: string;
  rejected: null | Array<string>;
};

const ProofRejection = (props: Props) => {
  const { userAdmin, snackHandler } = useAuth();

  const fileRef = useRef<HTMLInputElement>(null);

  const [show, setShow] = useState(false as boolean);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleFileUpload = async () => {
    const file = fileRef.current?.files || null;
    const formData = new FormData();

    if (!file) return;

    for (let i = 0; i < file.length; i++) {
      formData.append("file[]", file[i]);
    }

    formData.append("token", props.token);

    try {
      const { data } = await axios.post(
        `${BASE_ADMIN}v1/users/leave-reject-proof`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userAdmin.token}`,
          },
        }
      );

      if (data?.status) {
        snackHandler("File uploaded successfully");
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors || [];

      if (errors?.file) {
        snackHandler(errors?.file?.[0], "error");
      } else {
        snackHandler("Something went wrong", "error");
      }
    }

    const keycache: any = queryClient.getQueryData(["crm", "last-params"]);

    const obj = Object.keys(keycache).reduce((object: any, key: any) => {
      if (keycache[key] !== undefined) {
        object[key] = keycache[key];
      }
      return object;
    }, {});

    queryClient.invalidateQueries(["crm", obj]);

    setOpenConfirm(false);
  };

  return (
    <>
      {!props.rejected ? (
        <>
          <input
            multiple
            id="file-input"
            ref={fileRef}
            type="file"
            onInput={() => setOpenConfirm(true)}
            className="none hidden"
          />

          <label htmlFor="file-input">
            <Button
              title="Upload proof rejection/noshow"
              component="span"
            >
              <FileUploadIcon fontSize="small" />
            </Button>
          </label>

          <ConfirmationDialog
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => handleFileUpload()}
          >
            Do you want to submit the file?
          </ConfirmationDialog>
        </>
      ) : (
        <Button
          title="View proof rejection/noshow"
          onClick={() => setShow(true)}
          component="span"
        >
          <PreviewIcon fontSize="small" />
        </Button>
      )}

      {show && (
        <EduModal
          fullWidth
          open={show}
          onClose={() => setShow(false)}
          maxWidth="lg"
        >
          <EduModalTitle onClose={() => setShow(false)}>
            <img
              src={DownloadIcon}
              className="icon no-margin"
            />
            Rejection proof
          </EduModalTitle>

          <EduModalBody>
            {props?.rejected?.map((file: any, index: number) => (
              <iframe
                id="frameContent"
                key={index}
                src={file || ""}
                width="100%"
                height="300"
              />
            ))}
          </EduModalBody>
        </EduModal>
      )}
    </>
  );
};

export default ProofRejection;
