import { useMemo } from "react";
import { QuestionMarkRoundedIcon } from "@/components";
import { Typography, useTheme, Box } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent, // Import TimelineOppositeContent
} from "@mui/lab";
import CallIcon from "@mui/icons-material/Call";
import PauseIcon from "@mui/icons-material/Pause";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import ViewContactIcon from "@/assets/icons/view-contact.svg";

const genIcon = (key: string) => {
  switch (key) {
    case "To be called":
      return <CallIcon color="primary" />;
    case "To be recalled":
      return <CallIcon color="primary" />;
    case "No reply":
      return <PhoneMissedIcon color="primary" />;
    case "Stand by":
      return <PauseIcon color="primary" />;
    default:
      return <QuestionMarkRoundedIcon color="primary" />;
  }
};

const UserUpdatesDialog = ({ open, user, handleClose }) => {
  const theme = useTheme();
  const genIconMemoized = useMemo(() => genIcon, []);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={ViewContactIcon}
          alt="View Contact Icon"
        />
        CONTACT INFORMATION
      </EduModalTitle>

      <EduModalBody>
        {user ? (
          <Box className="md:grid md:grid-cols-2 md:px-10 py-5 w-full">
            <Box className="flex flex-col gap-1">
              <Typography
                color="primary"
                variant="h6"
                component="h2"
              >
                Personal details
              </Typography>

              <Typography>
                Full name: <b>{user?.first_name}</b>
              </Typography>

              <Typography>
                Phone: <b>{user.phone}</b>
              </Typography>

              <Typography>
                Status: <b>{user.status}</b>
              </Typography>

              <Typography>
                Start Month: <b>{user.startMonth}</b>
              </Typography>
            </Box>

            <Box>
              <Typography
                color="primary"
                variant="h6"
                component="h2"
              >
                Last actions
              </Typography>

              <Timeline>
                {user.updates &&
                  user.updates.map((update: any, index: number) => (
                    <TimelineItem
                      sx={{ ".MuiTimelineDot-filled": { m: 0, p: 1 } }}
                      key={index}
                    >
                      <TimelineOppositeContent>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {new Date(update.date).toLocaleString()}
                        </Typography>
                      </TimelineOppositeContent>

                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            border: 2,
                            borderColor: theme.palette.primary.main,
                            backgroundColor: "transparent",
                          }}
                        >
                          {genIconMemoized(update.status)}
                        </TimelineDot>

                        {index < user.updates.length - 1 && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>

                      <TimelineContent>
                        <Typography>{update.status}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
              </Timeline>
            </Box>
          </Box>
        ) : (
          <Typography variant="body1">No user selected</Typography>
        )}
      </EduModalBody>
    </EduModal>
  );
};

export default UserUpdatesDialog;
