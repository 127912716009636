import { forwardRef, useImperativeHandle, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import SingleEvent from "./v2/SingleEvent";

export interface IForwardRef {
  open: (data: any) => void;
}

interface IProps {
  ref: HTMLButtonElement;
  keyQuery: Array<any>;
}

const EventDialog = forwardRef<IForwardRef, IProps>(({ keyQuery }, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvents] = useState<any>();

  const handleClose = () => {
    setOpen(false);
    setSelectedEvents(undefined);
  };

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setSelectedEvents(data);
    },
  }));

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="xl"
    >
      <EduModalTitle onClose={handleClose}>
        {selectedEvent ? (
          <>
            <img src={editIcon} /> Edit Event
          </>
        ) : (
          <>
            <img src={addIcon} /> ADD MANUALLY
          </>
        )}
      </EduModalTitle>

      <EduModalBody className="p-5">
        <SingleEvent
          keyQuery={keyQuery}
          onSubmit={handleClose}
          selectedEvent={selectedEvent}
        />
      </EduModalBody>
    </EduModal>
  );
});

export default EventDialog;
