import {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import useAuth from '@/hooks/useAuth';
import { Box, Button, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import editIcon from "@/assets/icons/edit.svg";
import cancelIcon from "@/assets/icons/cancel.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import viewIcon from "@/assets/icons/view.svg";
import PhoneField from "@/components/_Common/Forms/PhoneField";
import useUserAdminApi from "@/api/useUserAdminApi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import EdusognoPdfViewer from "../EdusognoPdfViewer";
import useAPI from "@/api/useAPI";
import ConfirmationDialog from "../ConfirmationDialog";
dayjs.extend(customParseFormat);

export const ACTIVITY = ["Free", "Sales", "Call center"];

export interface IFormUser {
  id?: number;
  bic?: string;
  first_name?: string;
  iban?: string;
  role?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  admin?: number;
  address?: string;
  password?: string;
  activity?: string;
  calendar_id?: string;
  birth_date?: any;
  contract_file_path?: string;
}

interface IFormUserDialog {
  open: boolean;
  onClose: () => void;
  setOpen: (data: boolean) => void;
  selectedUser?: IFormUser | null;
}

export interface IParams {
  tk?: string;
  postData: IFormUser;
}

const FormUserDialog = ({
  open,
  setOpen,
  onClose,
  selectedUser,
}: IFormUserDialog) => {
  const { userAdmin } = useAuth();
  const {
    callUpdateAdmin,
    callRegisterNewAdmin,
    callUpdateAvatarAdmin,
    callUploadTeamContract,
  } = useUserAdminApi();

  const [avatarFile, setAvatarFile] = useState<any>(null);
  const [contractFile, setContractFile] = useState<any>(null);
  const [userData, setUserData] = useState<IFormUser>({});
  const [openPdfViewer, setOpenPdfViewer] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [isCancelContractDialogOpen, setIsCancelContractDialogOpen] =
    useState(false);

  const { snackHandler } = useAuth();

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    let resp: any;

    if (selectedUser) {
      // update an existing record
      resp = await callUpdateAdmin.mutateAsync({
        tk: userAdmin.token,
        postData: userData,
      });
    } else {
      // create a new record
      const data = await callRegisterNewAdmin.mutateAsync({
        tk: userAdmin.token,
        postData: userData,
      });

      resp = data?.user;
    }

    // set the user id
    const id: any = selectedUser ? selectedUser.id : userData.id;

    // upload avatar
    if (selectedUser?.id || userData?.id) {
      if (avatarFile) {
        const formData = new FormData();
        formData.append("avatarFile", avatarFile[0]);

        await callUpdateAvatarAdmin.mutateAsync({
          tk: userAdmin.token,
          id: id,
          formData,
        });

        setAvatarFile(null);
      }
    }

    // upload the contract document
    if (selectedUser?.id || userData?.id) {
      if (contractFile) {
        const contractFormData = new FormData();
        contractFormData.append("contractFile", contractFile[0]);

        await callUploadTeamContract.mutateAsync({
          tk: userAdmin.token,
          id: id,
          formData: contractFormData,
        });

        setContractFile(null);
      }
    }

    setOpen(false);
  };

  const handleCancelContractDialogOpen = () => {
    setIsCancelContractDialogOpen(true);
  };

  const handleCancelContractDialogClose = () => {
    setIsCancelContractDialogOpen(false);
  };

  const handelCancelTeamContract = async () => {
    if (selectedUser) {
      if (selectedUser?.contract_file_path) {
        const userId = selectedUser?.id;
        // const tk = '6376|nkccRxzyDIMFJLF42PDOaaqNyPm2VCcMJxsbyPGt';
        const cancelResponse = await useAPI(
          `/v1/team/cancel-contract`,
          {
            userId: userId,
          },
          {
            type: "post",
            // headers: {
            //   Authorization: `Bearer ${tk}`
            // }
          }
        );

        if (cancelResponse.status === true) {
          // show a success message
          snackHandler(cancelResponse.message);

          // set contract file state to null
          setContractFile(null);

          // set the contract file to null on userDate
          setUserData((prevState) => ({
            ...prevState,
            contract_file_path: "",
          }));

          selectedUser.contract_file_path = "";
        } else {
          // show an error message
          snackHandler("Contract not cancelled", "error");
        }
      }
    }

    // set dialog open to false
    handleCancelContractDialogClose();

    // snackHandler("Contract not cancelled", "info");
  };

  const onChangeData = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    setUserData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleAvatarFileChange = (event: any) => {
    setAvatarFile(event.target.files);
  };

  /**
   * Handles the onChange event of the input element of type file
   * @param event event of the input element
   */
  const handleContractFileChange = (event: any) => {
    setContractFile(event.target.files);
  };

  /**
   * Show a team member's contract pdf file
   */
  const fetchContractPdf = async () => {
    try {
      if (selectedUser) {
        if (selectedUser?.contract_file_path) {
          setPdfUrl(selectedUser?.contract_file_path);
          setOpenPdfViewer(true);
        } else {
          console.warn("Network response was not OK");
        }
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    if (selectedUser) setUserData(selectedUser);
    else setUserData({});
  }, [selectedUser]);

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={onClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={onClose}>
          {selectedUser ? (
            <>
              <img
                src={editIcon}
                className="icon"
              />
              Edit User
            </>
          ) : (
            <Fragment>
              <img
                src={addIcon}
                className="icon"
              />
              Add User
            </Fragment>
          )}
        </EduModalTitle>

        <EduModalBody className="p-5">
          <Box
            component="form"
            onSubmitCapture={handleSubmit}
          >
            <Box className="grid grid-cols-2 gap-5">
              <TextField
                required
                name="first_name"
                label="Name"
                value={userData?.first_name ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
              />

              <TextField
                required
                name="last_name"
                label="Last Name"
                value={userData?.last_name ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
              />

              <TextField
                required
                name="email"
                type="email"
                label="Email"
                value={userData?.email ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
              />

              <TextField
                name="password"
                type="password"
                label="Password"
                value={userData?.password ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
                helperText={
                  selectedUser ? (
                    <span>
                      Leave empty if you don't want to change password
                    </span>
                  ) : null
                }
              />

              <TextField
                required={false}
                name="calendar_id"
                label="Calendar id"
                value={userData?.calendar_id ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
              />

              <PhoneField
                name="phone"
                label="Phone"
                value={userData?.phone ?? ""}
                onChange={(v: string) =>
                  setUserData((p: any) => ({ ...p, phone: v }))
                }
              />

              <TextField
                select
                required
                fullWidth
                id="productSelect"
                name="role"
                label="Role"
                value={userData?.role ?? ""}
                onChange={({ target: { value } }) =>
                  setUserData((p: any) => ({
                    ...p,
                    role: value,
                    admin: Number(value?.toLowerCase() === "admin"),
                  }))
                }
              >
                {["Member", "Manager", "Admin"].map((p) => (
                  <MenuItem
                    key={p}
                    value={p}
                  >
                    {p}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                required
                fullWidth
                id="productSelect"
                name="activity"
                label="Activity"
                value={userData?.activity ?? ""}
                onChange={(event) =>
                  setUserData((p: any) => ({
                    ...p,
                    activity: event.target.value,
                  }))
                }
              >
                {ACTIVITY.map((p) => (
                  <MenuItem
                    key={p}
                    value={p}
                  >
                    {p}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                name="address"
                label="Address"
                value={userData?.address ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
              />

              <TextField
                name="iban"
                label="IBAN"
                value={userData?.iban ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
              />

              <TextField
                name="bic"
                label="BIC"
                value={userData?.bic ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeData(e)}
              />

              {/* date of birth field */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Birth Date"
                  value={userData?.birth_date ? userData.birth_date : null}
                  onChange={(newDate) =>
                    setUserData((prev) => ({
                      ...prev,
                      birth_date: newDate,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box className="flex justify-between gap-5 mt-5">
              <Box>
                <label htmlFor="avatarFile">
                  <Button
                    variant="outlined"
                    component="div"
                    style={{ minWidth: "132px" }}
                  >
                    {avatarFile ? avatarFile[0].name : <>Upload Photo</>}
                  </Button>
                </label>

                <input
                  id="avatarFile"
                  type="file"
                  name="avatarFile"
                  accept=".png,.jpg,jpeg"
                  onChange={handleAvatarFileChange}
                  className="hidden"
                />

                {avatarFile && (
                  <Button
                    sx={{ marginLeft: "10px" }}
                    variant="outlined"
                    onClick={() => setAvatarFile(null)}
                  >
                    <img
                      src={deleteIcon}
                      className="icon"
                    />
                    Remove
                  </Button>
                )}
              </Box>
            </Box>

            <Box className="flex justify-between gap-5 mt-5">
              {/* contract upload */}
              <Box>
                <label htmlFor="contractFile">
                  <Button
                    variant="outlined"
                    component="div"
                    style={{ minWidth: "132px" }}
                  >
                    {contractFile ? contractFile[0].name : <>Upload Contract</>}
                  </Button>
                </label>

                <input
                  id="contractFile"
                  type="file"
                  name="contractFile"
                  accept=".pdf"
                  onChange={(e: any) => handleContractFileChange(e)}
                  className="hidden"
                />

                {contractFile && (
                  <Button
                    sx={{ marginLeft: "10px" }}
                    variant="outlined"
                    onClick={() => setContractFile(null)}
                  >
                    <img
                      src={deleteIcon}
                      className="icon"
                    />
                    Remove
                  </Button>
                )}

                {selectedUser && selectedUser?.contract_file_path && (
                  <Fragment>
                    <Button
                      sx={{
                        marginLeft: "10px",
                        minHeight: "33px",
                        maxHeight: "33px",
                      }}
                      variant="outlined"
                      onClick={() => fetchContractPdf()}
                      title={"View contract"}
                    >
                      <img
                        src={viewIcon}
                        className="icon"
                      />
                      View
                    </Button>
                    <Button
                      sx={{
                        marginLeft: "10px",
                        minHeight: "33px",
                        maxHeight: "33px",
                        color: "red",
                        border: "1px solid red",
                      }}
                      variant="outlined"
                      onClick={handleCancelContractDialogOpen}
                      title={"Cancel contract"}
                    >
                      <img
                        src={cancelIcon}
                        className="icon"
                        style={{ textAlign: "center", color: "red" }}
                      />
                      Cancel
                    </Button>

                    {/* show a confirmation dialog before deleting */}
                    <ConfirmationDialog
                      open={isCancelContractDialogOpen}
                      title="Confirm Contract Cancellation"
                      onClose={handleCancelContractDialogClose}
                      onConfirm={handelCancelTeamContract}
                      textButtonAgree="Yes"
                      textButtonDisagree="No"
                    >
                      Are you sure you want to cancel the contract? This action
                      cannot be undone.
                    </ConfirmationDialog>
                  </Fragment>
                )}
              </Box>
            </Box>
            <Box className="flex justify-end gap-5 mt-5">
              <Button
                type="button"
                variant="outlined"
                style={{ fontWeight: "600" }}
                onClick={() => onClose()}
              >
                <img
                  src={cancelIcon}
                  className="icon"
                />
                {/* <CloseIcon /> */}
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={{ fontWeight: "600" }}
              >
                <img
                  src={saveIcon}
                  className="icon"
                />
                Save
              </Button>
            </Box>
          </Box>
        </EduModalBody>
      </EduModal>

      {/* show or hide pdf viewer dialog */}
      <EdusognoPdfViewer
        open={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        pdfUrl={pdfUrl}
        dialogTitle="Team Contract"
      />
    </>
  );
};

export default FormUserDialog;
