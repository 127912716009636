import { forwardRef, useImperativeHandle, useState } from "react";
import { Delete } from "@mui/icons-material";
import useAuth from '@/hooks/useAuth';
import { getTableStyling } from "@/providers/Colors";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

export interface IForwardRef {
  open: (tutor: { id: number; fullName: string }) => void;
}

interface IProps { }

const PaymentRequestDialog = forwardRef<IForwardRef, IProps>((props, ref) => {
  const theme = useTheme();
  const { snackHandler } = useAuth();

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [tutor, setTutor] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openConfirmId, setOpenConfirmId] = useState<number>();

  useImperativeHandle(ref, () => ({
    open: async (tutor) => {
      setTutor(tutor);
      setIsLoading(true);
      const res = await useAPI("/v1/general/tutors/tutor-payments", {
        tutorId: tutor?.id,
      });
      setIsLoading(false);
      setRows(res?.data);
    },
  }));

  const handleDelete = async () => {
    setOpenConfirmId(undefined);

    const res = await useAPI(
      `/v1/general/tutors/tutor-payments/delete`,
      { idTutorPayment: openConfirmId, tutorId: tutor?.id },
      { useErrors: true }
    );

    if (!res?.status) {
      snackHandler(res?.message, "error");
      return;
    }

    snackHandler("Success delete!");
    setRows(res?.data);
  };

  const handleClose = () => {
    setRows([]);
    setTutor(undefined);
    setIsLoading(false);
    setOpenConfirmId(undefined);
  };

  const columns: GridColDef[] = [
    {
      field: "period",
      headerName: "Period",
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "created_at",
      headerName: "Submission date",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "",
      width: 60,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Button
          variant="outlined"
          title="Delete"
          onClick={() => setOpenConfirmId(row?.id)}
        >
          <Delete />
        </Button>
      ),
    },
  ];

  return (
    <>
      <EduModal
        fullWidth
        open={Boolean(tutor != undefined)}
        onClose={handleClose}
        maxWidth="md"
      >
        <EduModalTitle onClose={handleClose}>
          Payment request - {tutor?.fullName || ""}
        </EduModalTitle>

        <EduModalBody className="p-5">
          <DataGrid
            autoHeight
            disableColumnMenu
            disableVirtualization
            disableSelectionOnClick
            sx={getTableStyling(theme)}
            rows={rows}
            columns={columns}
            loading={isLoading}
            pageSize={10}
            components={{
              Pagination: CustomPaginationClient,
            }}
            initialState={{
              columns: {
                columnVisibilityModel: { email: false },
              },
            }}
            rowsPerPageOptions={[10]}
            experimentalFeatures={{ newEditingApi: false }}
          />
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={Boolean(openConfirmId != undefined)}
        onClose={() => setOpenConfirmId(undefined)}
        onConfirm={handleDelete}
      >
        Do you want to delete this payment request?
      </ConfirmationDialog>
    </>
  );
});

export default PaymentRequestDialog;
