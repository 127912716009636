import Request from "./RequestAdmin";

export const searchUsers = async (token, params) => {
  const { data } = await Request.call(
    {
      url: "/v1/users/search",
      method: "GET",
      params,
    },
    token
  );

  return data;
};

export const getUser = async (token, userToken) => {
  const { data } = await Request.call(
    {
      url: `/v1/users/${userToken}`,
      method: "GET",
    },
    token
  );
  return data;
};

export const getUserWithDates = async (token, userToken) => {
  const { data } = await Request.call(
    {
      url: `/v1/users/with_date/${userToken}`,
      method: "GET",
    },
    token
  );

  return data;
};

export const updateUser = async ({ token, id, data }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/${id}/update`,
      method: "POST",
      data,
    },
    token
  );

  return result;
};

export const getAuth = async (token) => {
  const { data } = await Request.call(
    {
      url: `/v1/auth`,
      method: "POST",
    },
    token
  );

  // if (data?.user?.routes) data.user.routes = JSON.parse(data.user.routes);

  return data;
};

export const postLogin = async (data) => {
  const { data: result } = await Request.call({
    url: `/v1/login`,
    method: "POST",
    data,
  });

  // if (result?.user?.routes) result.user.routes = JSON.parse(result.user.routes);

  return result;
};

export const postUserListWithSecondPayment = async (token, params = null) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/listWithSecondPayment`,
      method: "POST",
      params,
    },
    token
  );

  return result;
};

export const updateCourseDate = async ({ data }) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/user-product/${data.user_token}/update`,
      method: "POST",
      params: { data },
    },
    data.token
  );

  return result;
};

export const getUsersSuspended = async (token, params = null) => {
  const result = await Request.call(
    {
      url: "/v1/users/user-suspened",
      method: "GET",
      params,
    },
    token
  );

  return result;
};

export const createUserSuspended = async (token, data = null) => {
  const { data: result } = await Request.call(
    {
      url: "/v1/users/user-suspened",
      method: "POST",
      data,
    },
    token
  );

  return result;
};

export const updateUserSuspended = async (token, id, data = null) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/user-suspened/${id}`,
      method: "PUT",
      data,
    },
    token
  );

  return result;
};

export const deleteUserSuspended = async (token, id) => {
  const { data: result } = await Request.call(
    {
      url: `/v1/users/user-suspened/${id}`,
      method: "DELETE",
    },
    token
  );

  return result;
};

export const reactivateUserSuspended = async (token, data = null) => {
  const { data: result } = await Request.call(
    {
      url: "/v1/users/user-suspened/reactive",
      method: "POST",
      data,
    },
    token
  );

  return result;
};

export const downloadUserData = async (
  token,
  params,
  fileName = "export.csv"
) => {
  const { data: res } = await Request.download(
    {
      url: "/v1/users",
      method: "GET",
      params,
    },
    token,
    fileName
  );

  return res;
};

export const downloadUserListWithSecondPayment = async (
  token,
  params = null,
  fileName = "export.csv"
) => {
  const { data: result } = await Request.download(
    {
      url: `/v1/listWithSecondPayment`,
      method: "POST",
      params,
    },
    token,
    fileName
  );

  return result;
};

export const getStartMonths = async (token) => {
  const result = await Request.call(
    {
      url: "/v1/start-months",
      method: "GET",
    },
    token
  );

  return result;
};

export const downloadUsersSuspended = async (
  token,
  params = null,
  fileName = "export.csv"
) => {
  const result = await Request.download(
    {
      url: "/v1/users/user-suspened",
      method: "GET",
      params: { ...params, export: true },
    },
    token,
    fileName
  );

  return result;
};

export const getSurveyData = async (token, userToken) => {
  const { data } = await Request.call(
    {
      url: `/v1/survey/${userToken}`,
      method: "GET",
    },
    token
  );

  return data;
};

export const getAllSurveyData = async (token) => {
  const { data } = await Request.call(
    {
      url: `/v1/survey/all`,
      method: "POST",
    },
    token
  );

  return data;
};

