import useAPI from "@/api/useAPI";
import useUserSuspendedApi from "@/api/useUserSuspendedApi";
import commentIcon from "@/assets/icons/comments.svg";
import copyIcon from "@/assets/icons/copy.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import editIcon from "@/assets/icons/edit.svg";
import reactivateIcon from "@/assets/icons/reactivate.svg";
import CustomPagination from "@/components/_Common/CustomPagination";
import Dropdown from "@/components/_Common/Dropdown";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import MyTooltip from "@/components/MyTooltip";
import BasePage from "@/components/Page/Base";
import useExportCSV from "@/components/useExportCSV";
import useAuth from "@/hooks/useAuth";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";
import useDGS from "@/hooks/useDataGridSort";
import usePagination from "@/hooks/usePagination";
import useScreen from "@/hooks/useScreen";
import { getTableStyling } from "@/providers/Colors";
import { MoreVert } from "@mui/icons-material";
import {
  Badge,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { createRef, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UsersListNotes from "../UsersList/component/UsersListNotes";
import Toolbar from "./components/Toolbar";
import UserSuspenedDialog, {
  IForwardRef as IUserSuspened,
} from "./components/UserSuspenedDialog";

interface IOpenComment {
  note: string | Array<any>;
  token: string;
}

export default () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { userAdmin, copyToClipboard } = useAuth();

  const userSuspenedRef = createRef<IUserSuspened>();

  const [mode, setMode] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");
  const [openComment, setOpenComment] = useState<IOpenComment>();
  const [selectedMode, setSelectedMode] = useState<any[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openConfirmReactivateDialog, setOpenConfirmReactivateDialog] =
    useState<boolean>(false);

  const [filters, setFilters] = useSearchParams();
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ filters, setFilters });

  const {
    QUERY_KEY,
    callGetUsersSuspended,
    callDeleteUserSuspend,
    callReactiveUserSuspend,
  } = useUserSuspendedApi();

  const sorts = filterData?.sorts;
  delete filterData?.sorts;
  delete filterData?.page;
  const { data, isLoading } = callGetUsersSuspended({
    page,
    sorts,
    filter: filterData,
    per_page: perPage,
  });

  const userSuspendedData = data?.data || [];
  const pagination = {
    total: data?.total || 1,
    last_page: data?.last_page || 1,
    current_page: data?.current_page || 1,
  };

  const handleDelete = async () => {
    callDeleteUserSuspend.mutateAsync({ id: deleteId });
    setOpenConfirmDialog(false);
  };

  const handleReactivate = async () => {
    const students = data?.data?.filter((d: any) =>
      selectedMode.includes(d.id)
    );
    await callReactiveUserSuspend.mutateAsync({ users: students });
    setOpenConfirmReactivateDialog(false);
    setSelectedMode([]);
  };

  const handleDownload = async () => {
    const res = await useAPI("/v1/users/user-suspended", {
      export: true,
      filter: filterData,
    });
    useExportCSV(res, "User suspended");
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) =>
        `${row.user?.first_name} ${row.user?.last_name}`,
    },
    {
      field: "start_month",
      headerName: "START MONTH",
      width: 140,
      valueGetter: ({ row }) =>
        useConvertStartMonthEn(row.user?.start_month) || "-",
    },
    {
      field: "english_level",
      headerName: "ENGLISH LEVEL",
      width: 120,
      valueGetter: ({ row }) => `${row.user?.english_level}`,
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) =>
        row?.user?.payments
          ?.map((payment: any) => payment?.product?.product_tag)
          ?.filter((tag: any) => tag !== undefined && tag !== null)
          ?.join(","),
      renderCell: ({ row }) => {
        const products = row?.user?.payments
          ?.map((payment: any) => payment?.product?.product_tag)
          ?.filter((tag: any) => tag !== undefined && tag !== null);

        return (
          <MyTooltip
            title={
              <Box className="flex flex-col gap-2">
                {products?.map((p: string) => (
                  <Box key={p}>{p}</Box>
                ))}
              </Box>
            }
          >
            {products?.join(",")}
          </MyTooltip>
        );
      },
    },
    {
      field: "restart_month",
      headerName: "RESTART MONTH",
      minWidth: 150,
      valueGetter: ({ row }) =>
        useConvertStartMonthEn(row.restart_month) || "-",
    },
    {
      field: "update_start_month",
      headerName: "UPDATED RESTART MONTH",
      width: 200,
      valueGetter: ({ row }) =>
        useConvertStartMonthEn(row.update_start_month) || "-",
    },
    {
      field: "months_to_finish",
      headerName: "MONTHs to finish",
      width: 150,
    },
    {
      field: "availabilities_done",
      headerName: "availabilities done",
      width: 160,
    },
    {
      field: "user_attendance",
      headerName: "Attendance",
      width: 120,
      valueGetter: ({ row }) => row?.user?.attendance ?? 0,
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      valueGetter: ({ row }) => (row?.link_video ? "link video" : ""),
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              title="Copy email"
              onClick={() => copyToClipboard(row.user?.email)}
            >
              <ListItemIcon>
                <img
                  src={copyIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Copy email</ListItemText>
            </MenuItem>

            <MenuItem
              color="primary"
              title="Comments"
              onClick={() =>
                setOpenComment({ token: row.token_user, note: row.user.note })
              }
            >
              <ListItemIcon>
                <img
                  src={commentIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>
                Comments
                <Badge
                  sx={{ mx: 2 }}
                  color="warning"
                  badgeContent={row?.user?.note?.length}
                />
              </ListItemText>
            </MenuItem>

            <MenuItem
              title="Edit"
              onClick={() => {
                userSuspenedRef.current?.open(row);
              }}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              title="Delete"
              onClick={() => {
                setDeleteId(row?.id);
                setOpenConfirmDialog(true);
              }}
            >
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (mode === "reactivate") {
      setFilters({ active: "No" });
    }
  }, [mode]);

  return (
    <BasePage title="Suspended">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={userSuspendedData}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        selectionModel={selectedMode}
        isRowSelectable={(params) => !params.row?.active}
        componentsProps={{
          toolbar: {
            mode,
            setMode,
            setFilters,
            selectedMode,
            handleDownload,
            userSuspenedRef,
            setOpenConfirmReactivateDialog,
            filters: filterData,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: { page, setPage, pagination },
        }}
        onSortModelChange={onSortModelChange}
        checkboxSelection={["reactivate"].includes(mode)}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
        columnVisibilityModel={{
          actions: ["reactivate"].includes(mode) ? false : true,
        }}
        onSelectionModelChange={(ids) => setSelectedMode(ids)}
      />

      <UserSuspenedDialog ref={userSuspenedRef} />

      {Boolean(openComment) && (
        <UsersListNotes
          open={Boolean(openComment)}
          setOpen={setOpenComment}
          cacheKey={QUERY_KEY}
          userAdmin={userAdmin}
          selectedUser={openComment}
        />
      )}

      <ConfirmationDialog
        open={openConfirmDialog}
        title={
          <>
            <img src={deleteIcon} /> DELETE Suspended
          </>
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleDelete}
      >
        Are you sure to remove it?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openConfirmReactivateDialog}
        title={
          <>
            <img src={reactivateIcon} /> REACTIVATE STUDENTS
          </>
        }
        onClose={() => setOpenConfirmReactivateDialog(false)}
        onConfirm={handleReactivate}
      >
        Are you sure to reactivate this students?
      </ConfirmationDialog>
    </BasePage>
  );
};
