import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Fade,
  Slide,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import UsersListNotes from "./Comment";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
  EduButtonFooter,
} from "../_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import useCrmApi from "@/api/useCrmApi";
import DatePickerModal from "./DatePicker";
import useAuth from '@/hooks/useAuth';
import { Snackbar, Alert } from "@mui/material";

import {
  SeeInCrmButton,
  ResolvedButton,
  StandByButton,
  CommentButton,
  BookedCallButton,
} from "./CustomButtons";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  rows: any;
}

const DialpadCallModal: React.FC<ModalProps> = ({ open, onClose, rows }) => {
  const [currentRow, setCurrentRow] = useState<number>(0);
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [fade, setFade] = useState<boolean>(true);
  const [slideIn, setSlideIn] = useState<boolean>(true);
  const [callsComplete, setCallsComplete] = useState<boolean>(false);
  const { userAdmin, copyToClipboard, snackHandler } = useAuth();
  const { callUpdateCrm } = useCrmApi();
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openResolve, setOpenResolve] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [is_low_rating, setIsLowRating] = useState(false);
  const [is_absent, setIsAbsent] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [popoverContent, setPopoverContent] = useState({});

  const getUserColumns = (row) => {
    return (
      <>
        {row.is_low_rating && (
          <Typography variant="body2">
            Low Rating:{" "}
            <Button
              variant="contained"
              onClick={(event) => handleOpenPopover(event, row, "low_rating")}
            >
              View
            </Button>
          </Typography>
        )}
        {row.is_absent && (
          <Typography variant="body2">
            Absent:{" "}
            <Button
              variant="contained"
              onClick={(event) => handleOpenPopover(event, row, "absent")}
            >
              View
            </Button>
          </Typography>
        )}
        {row.is_review && (
          <Typography variant="body2">
            Review:{" "}
            <Button
              variant="contained"
              onClick={(event) => handleOpenPopover(event, row, "review")}
            >
              View
            </Button>
          </Typography>
        )}
      </>
    );
  };

  const getUserRows = (currentRow): GridRowsProp => {
    return [currentRow];
  };

  const handleOpenPopover = (event, row, type) => {
    if (row.is_low_rating && type == "low_rating") {
      setPopoverContent({
        startMonth: row.startMonth,
        reasons: row.reasons,
        rating: row.rating,
        tutor: row.tutor,
        lessonDate: row.lessonDate,
      });
    }
    if (row.is_absent && type == "absent") {
      setPopoverContent({
        startMonth: row.startMonth,
        missedCondition: row.missedCondition,
        course: row.course,
      });
    }
    if (row.is_review && type == "review") {
      setPopoverContent({
        rating: row.rating,
        startMonth: row.startMonth,
        course: row.course,
        attendance: row.attendance,
      });
    }

    setAnchorEl(event.currentTarget);
  };

  const conditionMapping: {
    [key: string]: { info1: string; info2: string; info3: string };
  } = {
    "Never booked": {
      info1: "status",
      info2: "creationDate",
      info3: "utm_source",
    },
    "No show": {
      info1: "status",
      info2: "creationDate",
      info3: "previousMeeting",
    },
    Upselling: {
      info1: "finishDate",
      info2: "course",
      info3: "attendanceRating",
    },
    Absent: {
      info1: "startMonth",
      info2: "course",
      info3: "missedCondition",
    },
    "Low rating": {
      info1: "lessonDate",
      info2: "tutor",
      info3: "reasons",
    },
    Review: {
      info1: "startMonth",
      info2: "course",
      info3: "attendanceRating",
    },
    "Still to pay": {
      info1: "startMonth",
      info2: "courseCost",
      info3: "whatToPay",
    },
    Suspended: {
      info1: "status",
      info2: "restartMonth",
      info3: "-",
    },
    Recruiting: {
      info1: "dateOfSubmission",
      info2: "creationDate",
      info3: "-",
    },
  };

  useEffect(() => {
    const checkAndCallFirstRow = async () => {
      if (open && iframeLoaded && rows.length > 0) {
        const data = await useAPI(
          `v1/user-dev-center/called-today/${rows[0].id}`,
          { table: rows[0].table },
          { type: "post" }
        );
        if (data.called) {
          handleNextRow();
        } else {
          initiateCall(rows[0].phone);
        }
      }
    };

    checkAndCallFirstRow();
  }, [open, rows, iframeLoaded]);

  useEffect(() => {
    if (!open) {
      setCurrentRow(0);
      setIframeLoaded(false);
      setCallsComplete(false);
    }
  }, [open]);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const initiateCall = (phone) => {
    const dialpadFrame = document.querySelector("iframe");

    dialpadFrame?.contentWindow?.postMessage(
      {
        api: "opencti_dialpad",
        version: "1.0",
        method: "initiate_call",
        payload: {
          phone_number: phone,
        },
      },
      "https://dialpad.com"
    );
  };
  const handleNextRow = async () => {
    setOpenComment(false);
    let nextRow = currentRow + 1;
    let found = false;

    const transitionToNextRow = (row: number) => {
      setFade(false);
      setSlideIn(false);
      setTimeout(() => {
        setCurrentRow(row);
        setFade(true);
        setSlideIn(true);
      }, 300);
    };

    while (nextRow < rows.length) {
      const data = await useAPI(
        `v1/user-dev-center/called-today/${rows[nextRow].id}`,
        { table: rows[nextRow].table },
        { type: "post" }
      );
      if (data.called) {
        transitionToNextRow(nextRow);
        nextRow++;
      } else {
        initiateCall(rows[nextRow].phone);
        found = true;
        transitionToNextRow(nextRow);
        break;
      }
    }

    if (!found) {
      setCallsComplete(true);
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  const handleCallClick = () => {
    handleNextRow();
  };

  const handlePopoverOpen = (event) => {
    setOpenResolve(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenResolve(false);
    setAnchorEl(null);
  };

  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  const handleNoReplyClick = () => {
    useAPI(
      `v1/user-dev-center/increment-no-reply/${rows[currentRow].id}`,
      { table: rows[currentRow].table },
      { type: "patch" }
    );
    handleNextRow();
  };

  const handleNotInterestedClick = () => {
    callUpdateCrm.mutate({
      id: parseInt(rows[currentRow].id),
      data: { status: "Disinteressato" },
    });
    handleNextRow();
  };

  const handleNotActiveClick = () => {
    callUpdateCrm.mutate({
      id: parseInt(rows[currentRow].id),
      data: { status: "Numero non valido" },
    });
    handleNextRow();
  };

  const handleScheduleMeetingClick = () => {
    const url = `/admin/crm?search=${encodeURIComponent(
      rows[currentRow].name
    )}&page=1`;
    window.open(url, "_blank");
    handleNextRow();
  };

  const handleCallBackClick = () => {
    setOpenDatePicker(true);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleStandBy = () => {
    useAPI(
      `v1/user-dev-center/stand-by/${rows[currentRow]?.feedback_id}`,
      {},
      { type: "patch" }
    );
    handleNextRow();
  };

  const handleResolve = async () => {
    try {
      if (is_absent) {
        await useAPI(
          `v1/user-dev-center/resolve/${rows[currentRow]?.feedback_id}`,
          { callType: "Absent" },
          { type: "patch" }
        );
      }

      if (is_low_rating) {
        await useAPI(
          `v1/user-dev-center/resolve/${rows[currentRow]?.feedback_id}`,
          { callType: "Low rating" },
          { type: "patch" }
        );
      }

      setSnackbarMessage("Resolve operation completed successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("An error occurred during resolve operation.");
      setSnackbarOpen(true);
    }
  };

  const handleSeeInCrm = (row) => {
    const url = `?search=${encodeURIComponent(row.name)}&page=1`;
    window.open(url, "_blank");
  };

  const handleDateSubmit = () => {
    if (selectedDate) {
      useAPI(
        `v1/user-dev-center/set-call-date/${rows[currentRow].id}`,
        {
          callBackDate: selectedDate.toISOString(),
          table: rows[currentRow].table,
        },
        { type: "patch" }
      );
      setOpenDatePicker(false);
      handleNextRow();
    }
  };

  const getColumns = (row) => {
    const condition = conditionMapping[row?.type];

    if (!condition) return null;

    return (
      <>
        <Typography variant="body2">
          {condition.info1}: {row[condition.info1]}
        </Typography>
        <Typography variant="body2">
          {condition.info2}: {row[condition.info2]}
        </Typography>
        <Typography variant="body2">
          {condition.info3}: {row[condition.info3]}
        </Typography>
      </>
    );
  };

  const getRows = (currentRow): GridRowsProp => {
    const condition = conditionMapping[currentRow?.type];
    if (!condition) return [];

    return [
      {
        id: 1,
        info1: currentRow[condition?.info1],
        info2: currentRow[condition?.info2],
        info3: currentRow[condition?.info3],
      },
    ];
  };

  const renderDynamicButtons = () => {
    const type = rows[currentRow]?.type;
    const absent = rows[currentRow]?.is_absent;
    const low_rating = rows[currentRow]?.is_low_rating;
    const review = rows[currentRow]?.is_review;
    const dynamicButtons: React.ReactNode[] = [];

    if (type === "Never booked" || type === "No show") {
      dynamicButtons.push(
        <Grid
          item
          xs={6}
          sm={4}
          key="schedule-meeting"
        >
          <EduButtonFooter
            fullWidth
            variant="contained"
            onClick={handleScheduleMeetingClick}
            disabled={!iframeLoaded}
          >
            Schedule Meeting
          </EduButtonFooter>
        </Grid>,
        <Grid
          item
          xs={6}
          sm={4}
          key="see-in-crm"
        >
          <SeeInCrmButton onClick={() => handleSeeInCrm(rows[currentRow])} />
        </Grid>,
        <Grid
          item
          xs={6}
          sm={4}
          key="not-active"
        >
          <EduButtonFooter
            fullWidth
            variant="contained"
            onClick={handleNotActiveClick}
            disabled={!iframeLoaded}
          >
            Not Active
          </EduButtonFooter>
        </Grid>
      );
    } else if (absent || low_rating) {
      dynamicButtons.push(
        <Grid
          item
          xs={6}
          sm={4}
          key="resolved"
        >
          <EduButtonFooter
            fullWidth
            variant="contained"
            onClick={handlePopoverOpen}
            disabled={!iframeLoaded}
          >
            Resolved
          </EduButtonFooter>
        </Grid>,
        <Grid
          item
          xs={6}
          sm={4}
          key="comment"
        >
          <EduButtonFooter
            fullWidth
            variant="contained"
            onClick={() => setOpenComment(true)}
            disabled={!iframeLoaded}
          >
            Comment
          </EduButtonFooter>
        </Grid>
      );
    } else if (review) {
      dynamicButtons.push(
        <Grid
          item
          xs={6}
          sm={4}
          key="not-interested"
        >
          <EduButtonFooter
            fullWidth
            variant="contained"
            onClick={handleNotInterestedClick}
            disabled={!iframeLoaded}
          >
            Booked Call
          </EduButtonFooter>
        </Grid>,
        <Grid
          item
          xs={6}
          sm={4}
          key="booked-call"
        >
          <EduButtonFooter
            fullWidth
            variant="contained"
            onClick={() => { }}
            disabled={!iframeLoaded}
          >
            Not Interested
          </EduButtonFooter>
        </Grid>
      );
    }

    return dynamicButtons;
  };

  return (
    <EduModal
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            height: "90%",
            maxHeight: "95vh",
          },
        },
      }}
      maxWidth="xl"
      fullWidth
      open={open}
      onClose={onClose}
    >
      <EduModalTitle onClose={onClose}>Dialpad Call</EduModalTitle>
      <EduModalBody padding={1}>
        {callsComplete ? (
          <Box sx={{ textAlign: "center" }}>
            <CheckCircleOutlineIcon sx={{ fontSize: 100, color: "green" }} />
            <Typography
              variant="h4"
              component="h2"
            >
              Calls Complete
            </Typography>
          </Box>
        ) : (
          <Fade
            in={fade}
            timeout={300}
          >
            <Slide
              direction={slideIn ? "left" : "right"}
              in={slideIn}
              timeout={300}
            >
              <div>
                {rows.length > 0 && (
                  <>
                    <Typography
                      variant="h6"
                      component="h2"
                    >
                      Name: {rows[currentRow]?.name}
                    </Typography>
                    <Typography variant="subtitle1">
                      Email: {rows[currentRow]?.email}
                    </Typography>
                    <Typography variant="subtitle1">
                      Type: {rows[currentRow]?.type}
                    </Typography>

                    <div style={{ marginTop: "20px" }}>
                      {rows[currentRow]?.is_absent ||
                        rows[currentRow]?.is_low_rating ||
                        rows[currentRow]?.is_review
                        ? getUserColumns(rows[currentRow])
                        : getColumns(rows[currentRow])}
                    </div>
                  </>
                )}

                <Box sx={{ mt: 2, mb: 2, height: "300px" }}>
                  <iframe
                    src={`https://dialpad.com/apps/${import.meta.env.VITE_DIALPAD_CTI_CLIENT_ID
                      }`}
                    onLoad={handleIframeLoad}
                    title="Dialpad"
                    allow="microphone; speaker-selection; autoplay; camera; display-capture; hid"
                    sandbox="allow-popups allow-scripts allow-same-origin"
                    height="100%"
                    width="100%"
                  ></iframe>
                </Box>

                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={6}
                    sm={4}
                  >
                    <EduButtonFooter
                      fullWidth
                      variant="contained"
                      onClick={handleCallClick}
                      disabled={
                        iframeLoaded ? currentRow === rows.length - 1 : true
                      }
                    >
                      Next Call
                    </EduButtonFooter>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                  >
                    <EduButtonFooter
                      fullWidth
                      variant="contained"
                      onClick={handleNoReplyClick}
                      disabled={!iframeLoaded}
                    >
                      No Reply
                    </EduButtonFooter>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                  >
                    <EduButtonFooter
                      fullWidth
                      variant="contained"
                      onClick={handleCallBackClick}
                      disabled={!iframeLoaded}
                    >
                      Call Back
                    </EduButtonFooter>
                  </Grid>
                  {renderDynamicButtons()}
                </Grid>
              </div>
            </Slide>
          </Fade>
        )}
      </EduModalBody>

      <DatePickerModal
        open={openDatePicker}
        selectedDate={selectedDate}
        onClose={() => setOpenDatePicker(false)}
        onDateChange={handleDateChange}
        onSubmit={handleDateSubmit}
      />
      <UsersListNotes
        open={openComment}
        userAdmin={userAdmin}
        setOpen={setOpenComment}
        selectedUser={rows[currentRow]}
        cacheKey={{}}
      />
      <Popover
        open={openResolve}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          {rows[currentRow]?.is_absent && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={is_absent}
                  onChange={handleCheckboxChange(setIsAbsent)}
                  name="absent"
                  color="primary"
                />
              }
              label="Absent"
            />
          )}
          {rows[currentRow]?.is_low_rating && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={is_low_rating}
                  onChange={handleCheckboxChange(setIsLowRating)}
                  name="low_rating"
                  color="primary"
                />
              }
              label="Low Rating"
            />
          )}
          <Button
            variant="contained"
            onClick={() => handleResolve()}
          >
            Resolve
          </Button>
        </Box>
      </Popover>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          {Object.keys(popoverContent).map((key) => (
            <Typography key={key}>
              <Typography
                variant="body1"
                component="span"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {`${key}: `}
              </Typography>
              {popoverContent[key]}
            </Typography>
          ))}
        </Box>
      </Popover>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </EduModal>
  );
};

export default DialpadCallModal;
