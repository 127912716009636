import { useMemo } from "react";
import { jP } from "@/components/useHelpers";
import { GridSortModel } from "@mui/x-data-grid";

interface IProps {
  filters: URLSearchParams;
  setFilters: (...params: any) => void;
}

export default ({ filters, setFilters }: IProps) => {
  const sortModel: GridSortModel = useMemo(() => {
    let sorts = filters.get("sorts") as string;

    let model: any;

    if (sorts) {
      model = jP(sorts);
    } else {
      return [];
    }

    model = Object.entries(model)
      .map(([field, sort]) => ({ field, sort }))
      .filter(({ sort }) => ["asc", "desc"].includes(sort as string));

    return model;
  }, [filters]);

  const onSortModelChange = (model: GridSortModel) => {
    const sorts = {};

    model.forEach(({ field, sort }) => {
      sorts[field] = sort;
    });

    setFilters(
      (p: any) => {
        if (JSON.stringify(sorts) === "{}") {
          p.delete("sorts");
        } else {
          p.set("sorts", JSON.stringify(sorts));
        }

        return p;
      },
      { replace: true }
    );
  };

  return {
    sortModel,
    onSortModelChange,
  };
};
