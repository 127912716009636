import React from 'react';
import { Box, Card, LinearProgress, Typography } from '@mui/material';

const calculatePunctualityStats = (data) => {
  const stats = {
    'On time': 0,
    '<3 min delay': 0,
    '3-5 min delay': 0,
    '>5 min delay': 0
  };

  if (data && Array.isArray(data.events)) {
    data.events.forEach(event => {      
      if (!event.tutor_access) {
        stats['>5 min delay']++;
      } else {
        const eventDateTime = new Date(`${event.data}T${event.ora}`).getTime();
        const accessDateTime = new Date(event.tutor_access).getTime();
        const delayInMinutes = Math.floor((accessDateTime - eventDateTime) / (1000 * 60));

        if (delayInMinutes <= 0) {
          stats['On time']++;
        } else if (delayInMinutes < 3) {
          stats['<3 min delay']++;
        } else if (delayInMinutes < 5) {
          stats['3-5 min delay']++;
        } else {
          stats['>5 min delay']++;
        }
      }
    });
  }

  return stats;
};

const Punctuality = ({ data, range }) => {
  const stats = calculatePunctualityStats(data);
  const totalEvents = data && data.events ? data.events.length : 0;

  return (
    <Card>
      <Box className='' p={2} height={294}>
        <div className="text-eduV2-1000 text-sm font-semibold leading-[13.93px] pb-4">Punctuality</div>
        {Object.entries(stats).map(([label, value]) => (
          <Box key={label} py={2} display="flex" justifyContent="space-around" alignItems="center">
            <div  className="text-eduV2-1000 text-xs w-[82px]">{label}</div>
            <Box flex="1" mx={2} ><LinearProgress sx={{ height: '24px', backgroundColor: '#D9DAF3', width: '152px', '& .MuiLinearProgress-bar': { backgroundColor: '#8065C9' }, borderRadius:'3px'}}  variant="determinate" value={(value / totalEvents) * 100} /></Box>
            <div  className="text-eduV2-1000 text-xs font-semibold">{value}</div>
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default Punctuality;
