import { PaletteMode, Theme, ThemeOptions } from "@mui/material";
import {
  red,
  blue,
  green,
  orange,
  pink,
  deepPurple,
  teal,
  cyan
} from "@mui/material/colors";

const getStyleCustomComponents = () => {
  const typography = {
    fontFamily: `Poppins, sans-serif`,
  };

  return {
    typography,
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme: Theme) => `

            body {
                background : ${theme.palette.background.default};
            }

            input[type="date" i]::-webkit-calendar-picker-indicator,
            input[type="datetime-local" i]::-webkit-calendar-picker-indicator {
              color-scheme: ${theme.palette.mode === "dark" ? "dark" : "light"};
            }

            .rdrDayToday .rdrDayNumber span:after {
              background: ${theme.palette.primary.main};
            }

            .select-file-component:hover {
              border: 1px solid ${theme.palette.primary.main};
            }

            .select-file-component.isSet .text-value {
              color: ${theme.palette.primary.main};
            }

            .bg-overlay-block::before {
              position: absolute;
              inset: 0;
              content: "";
              width: "100%";
              height: "100%";
              background-color: ${theme.palette.background.default};
              opacity: 0.5;
              z-index: 10;
              border: 1px solid ${theme.palette.primary.main};
            }

            textarea {
              border: 1px solid ${theme.palette.primary.main};
              border-radius: 8px;
              background: ${theme.palette.mode === "light" ? "white" : "transparent"
          };
              padding : 10px;
            }

            #filter_bar {
              margin-bottom: 10px;
              flex-wrap: wrap;
              display: flex;
              gap: 1rem;
              align-items: center;
            }

        `,
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            backgroundColor:
              theme.palette.mode === "light" ? "white" : "transparent",
            borderRadius: "8px",
            minWidth: "50px",
            textTransform: "none" as const,
            "&.Mui-disabled": {
              color: `${theme.palette.primary}A1`,
              border: `1px solid ${theme.palette.primary}A1`,
            },
            "& .icon": {
              marginRight: 10,
              filter: theme.palette.filter,
              maxHeight: "19px",
            },
            "& .no-margin": {
              margin: 0,
            },
          }),
          outlinedPrimary: ({ theme }: { theme: Theme }) => ({
            border: `1px solid ${theme.palette.primary.main} `,
          }),
          contained: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.primary.main,
            "&.Mui-disabled": {
              backgroundColor: `${theme.palette.primary.main}A1`,
              color: 'white'
            },
            "& .icon": {
              marginRight: 10,
              filter: 'brightness(0) invert(1)',
            },
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            "&:hover:before": {
              borderColor: `${theme.palette.primary.main} !important`,
            },
            ".MuiSvgIcon-root ": {
              fill: `${theme.palette.primary.main} !important`,
            },
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.primary.main}A1 !important`,
            },
            "& fieldset": {
              borderRadius: "8px",
            },
            "& input:-webkit-autofill": {
              boxShadow: "none",
            },
          }),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            color: theme.palette.primary.main,
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.primary.main,
            borderRadius: "8px",
            "& .MuiSvgIcon-root ": {
              fill: `${theme.palette.primary.main}`,
            },
            "& .Mui-disabled": {
              color: `${theme.palette.primary.main}A1`,
              border: `1px solid ${theme.palette.primary.main}A1`,
            },
            backgroundColor:
              theme.palette.mode === "light" ? "white" : "transparent",
            "& .MuiInputLabel-root": {
              color: `${theme.palette.primary.main}`,
            },
            "& fieldset": {
              color: `${theme.palette.primary.main}`,
              borderColor: "rgba(255,255,255,.5)",
              borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline":
            {
              borderColor: theme.palette.primary.main,
            },
            "& .Mui-focused fieldset": {
              borderWidth: "1px",
            },
          }),
        },
      },
      MuiTextField: {
        defaultProps: {
          size: "small" as const,
        },
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            "& label": {
              color: theme.palette.primary.main,
            },
            "& .MuiFormHelperText-root": {
              color: "#ff2b1c",
              marginLeft: 0,
            },
          }),
        },
      },
      MuiSelect: {
        defaultProps: {
          size: "small" as const,
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: "small" as const,
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: ({ theme }: { theme: Theme }) => ({
            borderRadius: "8px",
            border: `1px solid ${theme.palette.primary.main}`,
            marginTop: "5px",
          }),
          root: ({ theme }: { theme: Theme }) => ({
            "&.MuiPopover-paper": {
              boxShadow: "none",
            },
          }),
        },
      },
    },
  };
};

export const getColor = (theme: PaletteMode, color: string): ThemeOptions => {
  const background = {
    default: theme === "light" ? "#F3F3F3" : "#0f1420",
    paper: theme === "light" ? "white" : "#0f1420",
  };

  // $primary-100: #382bf0;
  // $surface-mixed-100: #1a1625;

  switch (color) {
    case "pink":
      return {
        palette: {
          primary: {
            main: theme === "light" ? pink[500] : pink["A100"],
          },
          secondary: {
            main: pink[300],
          },
          leftSideBar: {
            main: theme === "light" ? pink[100] : "#111827",
          },
          leftSideBarSub: {
            main: theme === "light" ? pink[50] : "#252935",
          },

          // $surface-mixed-200: #2f2b3a;
          // $surface-mixed-300: #46424f;
          filter:
            "invert(23%) sepia(68%) saturate(2913%) hue-rotate(321deg) brightness(93%) contrast(102%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };

    case "blue":
      return {
        palette: {
          primary: {
            main: blue[500],
          },
          secondary: {
            main: blue[300],
          },
          leftSideBar: {
            main: theme === "light" ? blue[100] : "#262626",
          },
          leftSideBarSub: {
            main: theme === "light" ? blue[50] : "#474747",
          },
          filter:
            "invert(42%) sepia(93%) saturate(1507%) hue-rotate(185deg) brightness(101%) contrast(91%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };

    case "color-old-edusogno":
      return {
        palette: {
          primary: {
            main: "rgb(16, 185, 129)",
          },
          secondary: {
            main: green[300],
          },
          leftSideBar: {
            main: "#64748b",
          },
          leftSideBarSub: {
            main: "rgb(45, 55, 72)",
          },
          filter:
            "invert(57%) sepia(65%) saturate(456%) hue-rotate(73deg) brightness(92%) contrast(81%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };

    case "green":
      return {
        palette: {
          primary: {
            main: green[500],
          },
          secondary: {
            main: green[300],
          },
          leftSideBar: {
            main: theme === "light" ? green[100] : "#262626",
          },
          leftSideBarSub: {
            main: theme === "light" ? green[50] : "#474747",
          },
          filter:
            "invert(57%) sepia(65%) saturate(456%) hue-rotate(73deg) brightness(92%) contrast(81%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };

    case "red":
      return {
        palette: {
          primary: {
            main: red[500],
          },
          secondary: {
            main: red[300],
          },
          leftSideBar: {
            main: theme === "light" ? red[100] : "#262626",
          },
          leftSideBarSub: {
            main: theme === "light" ? red[50] : "#474747",
          },
          filter:
            "invert(37%) sepia(56%) saturate(4513%) hue-rotate(342deg) brightness(99%) contrast(94%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };

    case "orange":
      return {
        palette: {
          primary: {
            main: orange[500],
          },
          secondary: {
            main: orange[300],
          },
          leftSideBar: {
            main: theme === "light" ? orange[100] : "#262626",
          },
          leftSideBarSub: {
            main: theme === "light" ? orange[50] : "#474747",
          },
          filter:
            "invert(61%) sepia(74%) saturate(2223%) hue-rotate(360deg) brightness(102%) contrast(105%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };

    case "teal":
      return {
        palette: {
          primary: {
            main: teal[500],
          },
          secondary: {
            main: teal[300],
          },
          leftSideBar: {
            main: theme === "light" ? teal[100] : "#35494B",
          },
          leftSideBarSub: {
            main: theme === "light" ? teal[50] : "#252E2F",
          },
          filter:
            "invert(36%) sepia(80%) saturate(784%) hue-rotate(134deg) brightness(102%) contrast(101%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };

      case "cyan":
        return {
          palette: {
            primary: {
              main: cyan[800],
            },
            secondary: {
              main: cyan[300],
            },
            leftSideBar: {
              main: theme === "light" ? cyan[300] : "#262626",
            },
            leftSideBarSub: {
              main: theme === "light" ? cyan[50] : "#474747",
            },
            filter:
              "invert(55%) sepia(96%) saturate(1277%) hue-rotate(143deg) brightness(92%) contrast(100%)",
            background: background,
            mode: theme,
          },
          ...getStyleCustomComponents(),
        };

    default:
      return {
        palette: {
          primary: {
            main: theme === "light" ? deepPurple[500] : deepPurple["A100"],
          },
          secondary: {
            main: deepPurple[300],
          },
          leftSideBar: {
            main: theme === "light" ? deepPurple[100] : "#111827",
          },
          leftSideBarSub: {
            main: theme === "light" ? deepPurple[50] : "#252935",
          },
          filter:
            "invert(25%) sepia(96%) saturate(1376%) hue-rotate(243deg) brightness(85%) contrast(95%)",
          background: background,
          mode: theme,
        },
        ...getStyleCustomComponents(),
      };
  }
};

export const getTableStyling = (theme: Theme) => {
  return {
    "&.MuiDataGrid-root, .MuiDataGrid-footerContainer": {
      border: "none",
      fontSize: "1rem",
    },
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    ".MuiDataGrid-columnHeaders": {
      textTransform: "uppercase",
    },
    ".MuiDataGrid-columnHeaders, .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    border: "none",
    "& .MuiDataGrid-cell:focus": {
      outline: " none",
    },
    "& .MuiDataGrid-cell:focus-visible": {
      outline: " none",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: " none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
      width: '100%'
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: theme.palette.primary.main,
      position: "relative",
      ".number": {
        borderRadius: "3px",
        fontSize: "10px",
        color: theme.palette.primary.main,
        background: theme.palette.leftSideBarSub.main,
        minWidth: "40px",
        position: "absolute",
        left: 0,
        top: "2px",
        lineHeight: "10px",
        textAlign: "center",
        padding: "3px",
      },
    },
    // "& .MuiDataGrid-columnHeaderTitleContainer": {
    //   justifyContent: "center",
    // },
    "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
    "& .MuiDataGrid-root": {
      position: "relative",
    },

    // if not has as parent MuiPaper-root MuiPaper-elevation

    // "& .MuiDataGrid-footerContainer:not(.MuiPaper-root.MuiPaper-elevation)": {
    //   flexDirection: "row-reverse",
    //   position: 'fixed',
    //   bottom: '10px'
    // },

    // "& .MuiDataGrid-footerContainer": {
    //   flexDirection: "row-reverse",
    //   position: 'fixed',
    //   bottom: '10px'
    // },

    "& ::-webkit-scrollbar": {
      width: "0.6em",
      height: "0.6em",
    },
    "& ::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
    },
  };
};

declare module "@mui/material/styles" {
  interface Palette {
    leftSideBar: Palette["primary"];
    leftSideBarSub: Palette["secondary"];
    filter: string;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    leftSideBar?: PaletteOptions["primary"];
    leftSideBarSub?: PaletteOptions["secondary"];
    filter?: string;
  }
}
