import {
  useMemo,
  Dispatch,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from "react";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Box, Grid, Button, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import saveIcon from "@/assets/icons/save.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import usePaymentsOutApi from "../../api/usePaymentsOutApi";

export interface IData {
  id?: number;
  vat?: number;
  data?: string; // Date
  metodo?: string; // Type
  importo?: number; // Amout
  oggetto?: string; // Object
  category?: string;
  destinatario?: string; // Receiver
  refunded_date?: string;
  relevant_month?: string;
}

interface IProps {
  show: boolean;
  data: IData | null;
  setData: (data: IData | null) => void;
  hideDialog: Dispatch<SetStateAction<boolean>>;
}

const FormPaymentOutDialog = ({ show, hideDialog, data, setData }: IProps) => {
  const { userAdmin } = useAuth();

  const {
    listTypes,
    listCategories,
    callCreatePaymentOut,
    callUpdatePaymentOut,
  } = usePaymentsOutApi();

  const [file, setFile] = useState<any>(null);
  const [payment, setPayment] = useState<IData | null>(null);
  const [showUpload, setShowUpload] = useState<boolean>(true);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const handlePaymentChange = async (e: any) =>
    setPayment((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const callBackResultSubmit = () => {
    setIsLoadingData(false);
    hideDialog(false);
    setFile(null);
    setPayment(null);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    setIsLoadingData(true);

    let paymentData: IData | null = { ...payment };

    if (paymentData?.refunded_date === "0000-00-00 00:00:00") {
      delete paymentData.refunded_date;
    }

    const formData = new FormData();

    for (const key in paymentData) {
      if (paymentData[key] !== null && paymentData[key] !== undefined) {
        formData.append(key, paymentData[key] as any);
      }
    }

    if (file) formData.append("file", file[0]);

    if (payment?.id) {
      callUpdatePaymentOut.mutate({
        token: userAdmin.token,
        id: payment.id,
        formData,
        callback: callBackResultSubmit,
      });
    } else {
      callCreatePaymentOut.mutate({
        token: userAdmin.token,
        formData,
        callback: callBackResultSubmit,
      });
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    setFile(file);
  };

  const startMonths = useMemo(() => {
    let start = dayjs();
    const months: Array<{ label: string; value: string }> = [];
    for (let i = 0; i < 10; i++) {
      months.push({
        label: start.format("MMMM YYYY"),
        value: start.format("YYYY-MM-01"),
      });
      start = start.subtract(1, "month");
    }
    return months;
  }, []);

  const handleClose = () => {
    hideDialog(false);
    setFile(null);
    setData(null);
    setPayment(null);
  };

  useEffect(() => {
    setPayment(data);

    setShowUpload(true);
  }, [data]);

  return (
    <EduModal
      fullWidth
      open={show}
      onClose={handleClose}
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={data?.id ? editIcon : addIcon}
          className="icon"
        />{" "}
        {data?.id ? "Edit outgoing payment" : "Add manually"}
      </EduModalTitle>

      <EduModalBody>
        <Box
          onSubmit={submitForm}
          component="form"
          className="flex flex-col m-5 mt-7 gap-5"
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <TextField
                select
                required
                fullWidth
                name="metodo"
                label="Type"
                value={payment?.metodo ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePaymentChange(e)
                }
              >
                {listTypes?.map((item) => {
                  return (
                    <MenuItem
                      key={item}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <TextField
                select
                required
                fullWidth
                name="category"
                label="Category"
                value={payment?.category ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePaymentChange(e)
                }
              >
                {listCategories?.map((item) => {
                  return (
                    <MenuItem
                      key={item}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  openTo="day"
                  views={["day"]}
                  value={payment?.data ? payment?.data.split(" ")[0] : ""}
                  onChange={(newValue) => {
                    setPayment((p) => ({
                      ...p,
                      data: newValue
                        ? dayjs(newValue).format("YYYY-MM-DD")
                        : "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      required
                      fullWidth
                      label="Date"
                    />
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <TextField
                select
                required
                fullWidth
                name="relevant_month"
                label="Relevant month"
                value={payment?.relevant_month ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePaymentChange(e)
                }
              >
                {startMonths?.map((item) => {
                  const arr = item.label.split(" ");
                  return (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                    >
                      <div className="flex justify-between w-full">
                        <div>{arr[0]}</div>
                        <div>{arr[1]}</div>
                      </div>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <TextField
                select
                required
                fullWidth
                name="vat"
                label="VAT"
                value={payment?.vat ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePaymentChange(e)
                }
              >
                <MenuItem value={1}>SI</MenuItem>
                <MenuItem value={0}>NO</MenuItem>
              </TextField>
            </Grid>

            <Grid
              item
              xs={6}
            >
              <TextField
                required
                fullWidth
                name="importo"
                label="Amount"
                type="number"
                value={payment?.importo ?? ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handlePaymentChange(event)
                }
              />
            </Grid>
          </Grid>

          <TextField
            required
            fullWidth
            name="destinatario"
            label="Receiver"
            value={payment?.destinatario ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handlePaymentChange(e)
            }
          />

          <TextField
            required
            fullWidth
            name="oggetto"
            label="Object"
            value={payment?.oggetto ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handlePaymentChange(event)
            }
          />

          {/* {payment?.url_drive &&
            payment?.url_drive !== "null" &&
            showUpload === false && (
              <Box className="py-2 flex flex-1 flex-row">
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.open(
                      payment?.url_drive?.replace("download&confirm", "view")
                    );
                  }}
                  component="span"
                >
                  <FileOpenIcon className="mr-3" /> Invoice
                </Button>

                <Button
                  sx={{ marginLeft: "10px" }}
                  type="button"
                  color="error"
                  onClick={() => setShowUpload(true)}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            )} */}

          <Box className="py-2 flex flex-1 flex-row justify-between">
            {Boolean(showUpload) && (
              <>
                <input
                  id="file"
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                  className="hidden"
                />

                {file ? (
                  <Box>
                    <Button
                      variant="outlined"
                      component="div"
                      className="truncate"
                    >
                      {file[0].name}
                    </Button>

                    <Button
                      sx={{ marginLeft: "10px" }}
                      type="button"
                      color="error"
                      onClick={() => setFile(null)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Box>
                ) : (
                  <label htmlFor="file">
                    <Button
                      variant="outlined"
                      component="span"
                    >
                      <img
                        src={addIcon}
                        className="icon"
                      />{" "}
                      Upload file
                    </Button>
                  </label>
                )}
              </>
            )}

            <LoadingButton
              sx={{ ml: 2 }}
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoadingData}
              className="w-[100px]"
            >
              <img
                src={saveIcon}
                className="icon"
              />{" "}
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default FormPaymentOutDialog;
