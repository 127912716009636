import { Button, Checkbox, TextField } from "@mui/material";
import deleteIcon from "@/assets/icons/delete.svg";
import { IQuestion, ITextQuestion } from "./QuestionInterface";

const SelectQuestion = ({ value, onChange, onDelete }: ITextQuestion) => {
  const handleChange = (newLabel, index) => {
    let newValue: IQuestion = {
      question: value?.question || "",
      elements: value?.elements || [],
    };

    if (
      newValue.elements?.[index]?.label ||
      newValue.elements?.[index]?.label === ""
    ) {
      newValue.elements[index].label = newLabel;
    }
    onChange(newValue);
  };

  return (
    <div className="flex-1">
      <div className="flex gap-4 w-full mb-4">
        <TextField
          label="Question"
          type={"text"}
          placeholder="Write here the question"
          sx={{ flex: 1 }}
          value={value?.question}
          onChange={(e) => {
            let newValue: IQuestion = {
              question: value?.question || "",
              elements: value?.elements || [],
            };
            newValue.question = e.target.value;
            onChange(newValue);
          }}
        />
        <Button
          variant="outlined"
          onClick={onDelete}
        >
          <img
            className="icon no-margin"
            src={deleteIcon}
          />
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-4 w-full">
        {value?.elements?.map((option, index) => {
          return (
            <div className="flex gap-4">
              <TextField
                className="grow"
                key={index + "text"}
                label={`Option ${index + 1}`}
                placeholder={`Option ${index + 1}`}
                value={option?.label}
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange(value, index);
                }}
              />
              <Checkbox
                name="Is it a correct answer?"
                key={index + "-checkbox"}
                checked={option?.trueOption}
                onChange={(e) => {
                  let newValue: IQuestion = {
                    question: value?.question || "",
                    elements: value?.elements || [],
                  };

                  newValue.elements[index].trueOption = e.target.value == "on";
                  onChange(newValue);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectQuestion;
