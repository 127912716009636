interface IProps {
  name: string;
  link: string;
}

export default ({ name, link }: IProps): string => {
  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  style="font-family: arial, 'helvetica neue', helvetica, sans-serif"
>
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title>Risultato selezione</title>
    <link href="https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i" rel="stylesheet" />
    <style type="text/css">
      #outlook a {
        padding: 0;
      }
      .es-button {
        mso-style-priority: 100 !important;
        text-decoration: none !important;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      .es-desk-hidden {
        display: none;
        float: left;
        overflow: hidden;
        width: 0;
        max-height: 0;
        line-height: 0;
        mso-hide: all;
      }
      [data-ogsb] .es-button {
        border-width: 0 !important;
        padding: 10px 20px 10px 20px !important;
      }
      .es-button-border:hover a.es-button,
      .es-button-border:hover button.es-button {
        background: #56d66b !important;
        border-color: #56d66b !important;
      }
      .es-button-border:hover {
        border-color: #42d159 #42d159 #42d159 #42d159 !important;
        background: #56d66b !important;
      }
      td .es-button-border:hover a.es-button-1 {
        background: #d4145a !important;
        border-color: #d4145a !important;
      }
      td .es-button-border-2:hover {
        background: #d4145a !important;
        border-style: solid solid solid solid !important;
        border-color: #42d159 #42d159 #42d159 #42d159 !important;
      }
      @media only screen and (max-width: 600px) {
        p,
        ul li,
        ol li,
        a {
          line-height: 150% !important;
        }
        h1,
        h2,
        h3,
        h1 a,
        h2 a,
        h3 a {
          line-height: 120%;
        }
        h1 {
          font-size: 30px !important;
          text-align: left;
        }
        h2 {
          font-size: 24px !important;
          text-align: left;
        }
        h3 {
          font-size: 20px !important;
          text-align: left;
        }
        .es-header-body h1 a,
        .es-content-body h1 a,
        .es-footer-body h1 a {
          font-size: 30px !important;
          text-align: left;
        }
        .es-header-body h2 a,
        .es-content-body h2 a,
        .es-footer-body h2 a {
          font-size: 24px !important;
          text-align: left;
        }
        .es-header-body h3 a,
        .es-content-body h3 a,
        .es-footer-body h3 a {
          font-size: 20px !important;
          text-align: left;
        }
        .es-menu td a {
          font-size: 14px !important;
        }
        .es-header-body p,
        .es-header-body ul li,
        .es-header-body ol li,
        .es-header-body a {
          font-size: 14px !important;
        }
        .es-content-body p,
        .es-content-body ul li,
        .es-content-body ol li,
        .es-content-body a {
          font-size: 14px !important;
        }
        .es-footer-body p,
        .es-footer-body ul li,
        .es-footer-body ol li,
        .es-footer-body a {
          font-size: 14px !important;
        }
        .es-infoblock p,
        .es-infoblock ul li,
        .es-infoblock ol li,
        .es-infoblock a {
          font-size: 12px !important;
        }
        *[class="gmail-fix"] {
          display: none !important;
        }
        .es-m-txt-c,
        .es-m-txt-c h1,
        .es-m-txt-c h2,
        .es-m-txt-c h3 {
          text-align: center !important;
        }
        .es-m-txt-r,
        .es-m-txt-r h1,
        .es-m-txt-r h2,
        .es-m-txt-r h3 {
          text-align: right !important;
        }
        .es-m-txt-l,
        .es-m-txt-l h1,
        .es-m-txt-l h2,
        .es-m-txt-l h3 {
          text-align: left !important;
        }
        .es-m-txt-r img,
        .es-m-txt-c img,
        .es-m-txt-l img {
          display: inline !important;
        }
        .es-button-border {
          display: inline-block !important;
        }
        a.es-button,
        button.es-button {
          font-size: 18px !important;
          display: inline-block !important;
        }
        .es-adaptive table,
        .es-left,
        .es-right {
          width: 100% !important;
        }
        .es-content table,
        .es-header table,
        .es-footer table,
        .es-content,
        .es-footer,
        .es-header {
          width: 100% !important;
          max-width: 600px !important;
        }
        .es-adapt-td {
          display: block !important;
          width: 100% !important;
        }
        .adapt-img {
          width: 100% !important;
          height: auto !important;
        }
        .es-m-p0 {
          padding: 0px !important;
        }
        .es-m-p0r {
          padding-right: 0px !important;
        }
        .es-m-p0l {
          padding-left: 0px !important;
        }
        .es-m-p0t {
          padding-top: 0px !important;
        }
        .es-m-p0b {
          padding-bottom: 0 !important;
        }
        .es-m-p20b {
          padding-bottom: 20px !important;
        }
        .es-mobile-hidden,
        .es-hidden {
          display: none !important;
        }
        tr.es-desk-hidden,
        td.es-desk-hidden,
        table.es-desk-hidden {
          width: auto !important;
          overflow: visible !important;
          float: none !important;
          max-height: inherit !important;
          line-height: inherit !important;
        }
        tr.es-desk-hidden {
          display: table-row !important;
        }
        table.es-desk-hidden {
          display: table !important;
        }
        td.es-desk-menu-hidden {
          display: table-cell !important;
        }
        .es-menu td {
          width: 1% !important;
        }
        table.es-table-not-adapt,
        .esd-block-html table {
          width: auto !important;
        }
        table.es-social {
          display: inline-block !important;
        }
        table.es-social td {
          display: inline-block !important;
        }
        .es-desk-hidden {
          display: table-row !important;
          width: auto !important;
          overflow: visible !important;
          max-height: inherit !important;
        }
      }
    </style>
  </head>
  <body
    style="
      width: 100%;
      font-family: arial, 'helvetica neue', helvetica, sans-serif;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      padding: 0;
      margin: 0;
    "
  >
    <div class="es-wrapper-color" style="background-color: #f6f6f6">
      <table
        class="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          border-collapse: collapse;
          border-spacing: 0px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-repeat: repeat;
          background-position: center top;
          background-color: #f6f6f6;
        "
      >
        <tr>
          <td valign="top" style="padding: 0; margin: 0">
            <table
              class="es-content"
              cellspacing="0"
              cellpadding="0"
              align="center"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                table-layout: fixed !important;
                width: 100%;
              "
            >
              <tr>
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    class="es-content-body"
                    cellspacing="0"
                    cellpadding="0"
                    bgcolor="#ffffff"
                    align="center"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr>
                      <td align="left" style="padding: 0; margin: 0">
                        <table
                          width="100%"
                          cellspacing="0"
                          cellpadding="0"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                        >
                          <tr>
                            <td valign="top" align="center" style="padding: 0; margin: 0; width: 600px">
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                role="presentation"
                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                              >
                                <tr>
                                  <td align="center" style="padding: 0; margin: 0; font-size: 0px">
                                    <img
                                      class="adapt-img"
                                      src="https://alalbg.stripocdn.email/content/guids/CABINET_4bd7a106f629c162bd27d6f8762827a4/images/group_551.png"
                                      alt
                                      style="display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic"
                                      width="600"
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="left"
                        bgcolor="#ffffff"
                        style="padding: 0; margin: 0; padding-top: 20px; padding-left: 20px; padding-right: 20px; background-color: #ffffff"
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                        >
                          <tr>
                            <td align="center" valign="top" style="padding: 0; margin: 0; width: 560px">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="presentation"
                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                              >
                                <tr>
                                  <td align="center" bgcolor="#ffffff" style="padding: 0; margin: 0">
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        mso-line-height-rule: exactly;
                                        font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif;
                                        line-height: 53px;
                                        color: #8065c9;
                                        font-size: 35px;
                                      "
                                    >
                                      <strong>RISULTATO SELEZIONE</strong>
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="left"
                        bgcolor="#ffffff"
                        style="margin: 0; padding-top: 5px; padding-bottom: 10px; padding-left: 40px; padding-right: 40px; background-color: #ffffff"
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                        >
                          <tr>
                            <td align="center" valign="top" style="padding: 0; margin: 0; width: 520px">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="presentation"
                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                              >
                                <tr>
                                  <td align="center" style="padding: 15px; margin: 0">
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        mso-line-height-rule: exactly;
                                        font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif;
                                        line-height: 21px;
                                        color: #2d224c;
                                        font-size: 14px;
                                      "
                                    >
                                      Ciao ${name},<br />Come promesso ti scriviamo per comunicarti il risultato del colloquio. Dalla chiacchierata
                                      abbiamo capito qualcosa in più su di te e a che punto ti trovi nel tuo percorso.<br /><br />Dato che non abbiamo
                                      un numero infinito di tutor e dunque un numero infinito di posti, abbiamo selezionato i profili che ci sono
                                      sembrati più motivati ed in linea con le nostre proposte di apprendimento.&nbsp;<br /><br /><strong
                                        >Trovi i risultati del colloquio premendo questo&nbsp;bottone.</strong
                                      >
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td align="left" bgcolor="#ffffff" style="padding: 20px; margin: 0; background-color: #ffffff">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                        >
                          <tr>
                            <td align="center" valign="top" style="padding: 0; margin: 0; width: 560px">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="presentation"
                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                              >
                                <tr>
                                  <td align="center" style="padding: 0; margin: 0">
                                    <span
                                      class="msohide es-button-border-2 es-button-border"
                                      style="
                                        border-style: solid;
                                        border-color: #2cb543;
                                        background: #d4145a;
                                        border-width: 0px;
                                        display: inline-block;
                                        border-radius: 7px;
                                        width: auto;
                                        mso-hide: all;
                                      "
                                      ><a
                                        href="${link}"
                                        class="es-button es-button-1"
                                        target="_blank"
                                        style="
                                          mso-style-priority: 100 !important;
                                          text-decoration: none;
                                          -webkit-text-size-adjust: none;
                                          -ms-text-size-adjust: none;
                                          mso-line-height-rule: exactly;
                                          color: #ffffff;
                                          font-size: 18px;
                                          border-style: solid;
                                          border-color: #d4145a;
                                          border-width: 10px 20px 10px 20px;
                                          display: inline-block;
                                          background: #d4145a;
                                          border-radius: 7px;
                                          font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif;
                                          font-weight: normal;
                                          font-style: normal;
                                          line-height: 22px;
                                          width: auto;
                                          text-align: center;
                                        "
                                        >SCOPRI I RISULTATI</a
                                      ></span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td align="left" bgcolor="#ffffff" style="padding: 20px; margin: 0; background-color: #ffffff">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                        >
                          <tr>
                            <td align="center" valign="top" style="padding: 0; margin: 0; width: 560px">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="presentation"
                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                              >
                                <tr>
                                  <td align="center" style="padding: 0; margin: 0">
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        mso-line-height-rule: exactly;
                                        font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif;
                                        line-height: 21px;
                                        color: #2d224c;
                                        font-size: 14px;
                                      "
                                    >
                                      Ad ogni modo, ci ha fatto davvero piacere conoscerti,<br />the Edusogno Team
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="left"
                        bgcolor="#d9daf3"
                        style="padding: 0; margin: 0; padding-top: 20px; padding-left: 20px; padding-right: 20px; background-color: #d9daf3"
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                        >
                          <tr>
                            <td align="center" valign="top" style="padding: 0; margin: 0; width: 560px">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="presentation"
                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                              >
                                <tr>
                                  <td align="center" style="padding: 15px; margin: 0; font-size: 0px; background-color: #d9daf3" bgcolor="#d9daf3">
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      class="es-table-not-adapt es-social"
                                      role="presentation"
                                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px"
                                    >
                                      <tr>
                                        <td align="center" valign="top" style="padding: 0; margin: 0; padding-right: 40px">
                                          <a
                                            target="_blank"
                                            href="https://www.facebook.com/edusogno"
                                            style="
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #2cb543;
                                              font-size: 14px;
                                            "
                                            ><img
                                              src="https://alalbg.stripocdn.email/content/guids/CABINET_4bd7a106f629c162bd27d6f8762827a4/images/1.png"
                                              alt="Fb"
                                              title="Facebook"
                                              width="32"
                                              style="
                                                display: block;
                                                border: 0;
                                                outline: none;
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                              "
                                          /></a>
                                        </td>
                                        <td align="center" valign="top" style="padding: 0; margin: 0; padding-right: 40px">
                                          <a
                                            target="_blank"
                                            href="https://www.instagram.com/edu.sogno/"
                                            style="
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #2cb543;
                                              font-size: 14px;
                                            "
                                            ><img
                                              src="https://alalbg.stripocdn.email/content/guids/CABINET_4bd7a106f629c162bd27d6f8762827a4/images/2.png"
                                              alt="Ig"
                                              title="Instagram"
                                              width="32"
                                              style="
                                                display: block;
                                                border: 0;
                                                outline: none;
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                              "
                                          /></a>
                                        </td>
                                        <td align="center" valign="top" style="padding: 0; margin: 0; padding-right: 40px">
                                          <a
                                            target="_blank"
                                            href="https://www.youtube.com/@edusognotutoring2258"
                                            style="
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #2cb543;
                                              font-size: 14px;
                                            "
                                            ><img
                                              src="https://alalbg.stripocdn.email/content/guids/CABINET_4bd7a106f629c162bd27d6f8762827a4/images/4.png"
                                              alt="Yt"
                                              title="Youtube"
                                              width="32"
                                              style="
                                                display: block;
                                                border: 0;
                                                outline: none;
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                              "
                                          /></a>
                                        </td>
                                        <td align="center" valign="top" style="padding: 0; margin: 0; padding-right: 40px">
                                          <a
                                            target="_blank"
                                            href="https://www.linkedin.com/company/28861904/admin/"
                                            style="
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #2cb543;
                                              font-size: 14px;
                                            "
                                            ><img
                                              src="https://alalbg.stripocdn.email/content/guids/CABINET_4bd7a106f629c162bd27d6f8762827a4/images/3.png"
                                              alt="In"
                                              title="Linkedin"
                                              width="32"
                                              style="
                                                display: block;
                                                border: 0;
                                                outline: none;
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                              "
                                          /></a>
                                        </td>
                                        <td align="center" valign="top" style="padding: 0; margin: 0">
                                          <a
                                            target="_blank"
                                            href="https://www.tiktok.com/@edu.sogno"
                                            style="
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #2cb543;
                                              font-size: 14px;
                                            "
                                            ><img
                                              src="https://alalbg.stripocdn.email/content/guids/CABINET_4bd7a106f629c162bd27d6f8762827a4/images/5.png"
                                              alt="Tt"
                                              title="TikTok"
                                              width="32"
                                              style="
                                                display: block;
                                                border: 0;
                                                outline: none;
                                                text-decoration: none;
                                                -ms-interpolation-mode: bicubic;
                                              "
                                          /></a>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" bgcolor="#d9daf3" style="padding: 10px; margin: 0">
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        mso-line-height-rule: exactly;
                                        font-family: roboto, 'helvetica neue', helvetica, arial, sans-serif;
                                        line-height: 18px;
                                        color: #333333;
                                        font-size: 12px;
                                      "
                                    >
                                      www.edusogno.com - Realizzato con il 🧠 in&nbsp;🇮🇹
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </body>
</html>
  `;
};
