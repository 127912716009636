import React, { useState, useEffect } from "react";
import { Box, LinearProgress, Tab } from "@mui/material";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteExercise, updateUserExercise, getUsersWithExercises } from "@/api";
import AllExercises from "./AllExercises";
import WritingExercises from "./WritingExercises";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// import StudentsExercises from "./StudentsExercises";

const index = () => {
  const [writing, setWriting] = useState<Array<any>>([]);
  const [all, setAll] = useState<Array<any>>([]);
  // const [students, setStudents] = useState([]);

  const { userAdmin } = useAuth();
  const { data, isLoading } = useQuery(["getUsersWithExercises"], () => getUsersWithExercises({ tk: userAdmin.token }));

  const [idUser, setIdUser] = useState("");
  const [tokenEx, setTokenEx] = useState("");
  const [tracks, setTracks] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [tab, setTab] = useState(localStorage.getItem("crm_exercises_tab") || "1");

  const handleChangeTab = (_: any, newTab: string) => {
    localStorage.setItem("crm_exercises_tab", newTab);
    setTab(newTab);
  };

  const updateDeleteExercise = useMutation(deleteExercise, {
    onMutate: (update: any) => {
      let oldData: any = queryClient.getQueryData(["getUsersWithExercises"]);
      let indexUser = oldData["users"].findIndex((el) => el.id == update.token_user);
      let exeFound = oldData["users"][indexUser].exercises_done;
      let index = exeFound.findIndex((el) => el.token_ex == update.token_ex);
      const newData = [...exeFound];
      newData.splice(index, 1);
      oldData["users"][indexUser].exercises_done = newData;
      queryClient.cancelQueries(["getUsersWithExercises"]);
      queryClient.setQueryData(["getUsersWithExercises"], oldData);

      let allExercises: Array<any> = [];
      let allWritings: Array<any> = [];
      let newWritingObj: object = {};

      // let exercises_student = {}
      // let studentsExe = []
      oldData["users"].forEach((element) => {
        let writingExercises: Array<any> = [];
        let essayCorrected: number = 0;
        // let exercisesCorrected = 0

        const exercises = element.exercises_done;
        exercises.forEach((el: any) => {
          allExercises.push({
            id: el.id,
            user_id: el.user_id,
            token_ex: el.token_ex,
            data: el.data,
            test_name: el.test_name,
            type: el.type,
            user_score: el.user_score,
            name_student: element.name_student,
          });
          // if (el.corrected === 1 || el.user_score) exercisesCorrected++

          if (el.type === "Writing") {
            writingExercises.push(el);
            if (el.corrected === 1) essayCorrected++;
          }
        });
        // exercises_student = {
        //     exercises_corrected: exercisesCorrected,
        //     ...element
        // }
        // studentsExe.push(exercises_student)

        if (writingExercises.length == 0) return;

        newWritingObj = {
          writing_exercises: writingExercises,
          total_essay: element.exercises_done.length,
          essay_corrected: essayCorrected,
          ...element,
        };
        allWritings.push(newWritingObj);
      });
      setAll(allExercises);
      setWriting(allWritings);
      // setStudents(studentsExe)
    },
    onSuccess() {
      setTokenEx("");
      setOpenConfirmDialog(false);
    },
  });

  const updateExercise = useMutation(updateUserExercise, {
    onMutate: (update: any) => {
      let oldData: any = queryClient.getQueryData(["getUsersWithExercises"]);
      let indexUser = oldData["users"].findIndex((el) => el.id == update.token_user);
      let exeFound = oldData["users"][indexUser].exercises_done;
      let index = exeFound.findIndex((el) => el.token_ex == update.token_ex);

      oldData["users"][indexUser].exercises_done[index].user_score = update.score;
      oldData["users"][indexUser].exercises_done[index].correction_data = update.correction_data;
      oldData["users"][indexUser].exercises_done[index].corrected = 1;
      queryClient.cancelQueries(["getUsersWithExercises"]);
      queryClient.setQueryData(["getUsersWithExercises"], oldData);

      let allExercises: Array<any> = [];
      let allWritings: Array<any> = [];
      let newWritingObj: object = {};

      // let exercises_student = {}
      // let studentsExe = []
      oldData["users"].forEach((element) => {
        let writingExercises: Array<any> = [];
        let essayCorrected: number = 0;
        // let exercisesCorrected = 0

        const exercises = element.exercises_done;
        exercises.forEach((el: any) => {
          allExercises.push({
            id: el.id,
            user_id: el.user_id,
            token_ex: el.token_ex,
            data: el.data,
            test_name: el.test_name,
            type: el.type,
            user_score: el.user_score,
            name_student: element.name_student,
          });
          // if (el.corrected === 1 || el.user_score) exercisesCorrected++

          if (el.type === "Writing") {
            writingExercises.push(el);
            if (el.corrected === 1) essayCorrected++;
          }
        });
        // exercises_student = {
        //     exercises_corrected: exercisesCorrected,
        //     ...element
        // }
        // studentsExe.push(exercises_student)

        if (writingExercises.length == 0) return;

        newWritingObj = {
          writing_exercises: writingExercises,
          total_essay: element.exercises_done.length,
          essay_corrected: essayCorrected,
          ...element,
        };
        allWritings.push(newWritingObj);
      });
      setAll(allExercises);
      setWriting(allWritings);
      // setStudents(studentsExe)
    },
    onSuccess() {
      setTokenEx("");
      setOpenConfirmDialog(false);
    },
  });

  useEffect(() => {
    if (!data) return;

    setTracks(data["tracks"]);

    let allExercises: Array<any> = [];
    let allWritings: Array<any> = [];
    let newWritingObj: object = {};

    // let studentsExe = []
    // let exercises_student = {}

    data["users"].forEach((element) => {
      let writingExercises: Array<any> = [];
      let essayCorrected: number = 0;
      // let exercisesCorrected = 0

      const exercises = element.exercises_done;
      exercises.forEach((el: any) => {
        allExercises.push({
          id: el.id,
          user_id: el.user_id,
          token_ex: el.token_ex,
          data: el.data,
          test_name: el.test_name,
          type: el.type,
          user_score: el.user_score,
          name_student: element.name_student,
        });
        // if (el.corrected === 1 || el.user_score) exercisesCorrected++

        if (el.type === "Writing") {
          writingExercises.push(el);
          if (el.corrected === 1) essayCorrected++;
        }
      });
      // exercises_student = {
      //     exercises_corrected: exercisesCorrected,
      //     ...element
      // }
      // studentsExe.push(exercises_student)

      if (writingExercises.length == 0) return;

      newWritingObj = {
        writing_exercises: writingExercises,
        total_essay: writingExercises.length,
        essay_corrected: essayCorrected,
        ...element,
      };
      allWritings.push(newWritingObj);
    });
    setWriting(allWritings);
    setAll(allExercises);
    // setStudents(studentsExe)
  }, [data]);

  if (isLoading) return <LinearProgress />;

  return (
    <TabContext value={tab}>
      <Box className="mt-8 mx-5 md:mx-10" style={{ height: "calc(100% - 12.5rem)" }}>
        <Box component="h2" className="text-3xl font-extrabold text-white mb-3">
          CRM Exercises
        </Box>

        <Box className="border-b border-gray-800">
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab sx={{ color: "#fff" }} label="All" value="1" />
            <Tab sx={{ color: "#fff" }} label="Writing" value="2" />
            {/* <Tab sx={{ color: "#fff" }} label="Students" value="3" /> */}
          </TabList>
        </Box>

        <TabPanel style={{ paddingLeft: 0, paddingRight: 0, height: "100%" }} value="1">
          {/* <AllExercises
            idUser={idUser}
            tokenEx={tokenEx}
            id={userAdmin.token}
            setIdUser={setIdUser}
            setTokenEx={setTokenEx}
            updateDeleteExercise={updateDeleteExercise}
            exercises={all}
            openConfirmDialog={openConfirmDialog}
            setOpenConfirmDialog={setOpenConfirmDialog}
          /> */}
        </TabPanel>

        <TabPanel style={{ paddingLeft: 0, paddingRight: 0, height: "100%" }} value="2">
          {/* <WritingExercises
            idUser={idUser}
            tokenEx={tokenEx}
            id={userAdmin.token}
            setIdUser={setIdUser}
            setTokenEx={setTokenEx}
            updateDeleteExercise={updateDeleteExercise}
            updateExercise={updateExercise}
            exercises={writing}
            openConfirmDialog={openConfirmDialog}
            setOpenConfirmDialog={setOpenConfirmDialog}
            tracks={tracks}
          /> */}
        </TabPanel>

        {/* <TabPanel style={{ paddingLeft: 0, paddingRight: 0, height: "100%" }} value="3">
                <StudentsExercises
                idUser={idUser}
                tokenEx={tokenEx}
                id={userAdmin.token}
                setIdUser={setIdUser}
                setTokenEx={setTokenEx}
                updateDeleteExercise={updateDeleteExercise}
                exercises={students}
                openConfirmDialog={openConfirmDialog}
                setOpenConfirmDialog={setOpenConfirmDialog}
                />
            </TabPanel> */}
      </Box>
    </TabContext>
  );
};

export default index;
