import backIcon from "@/assets/icons/back.svg";
import nextIcon from "@/assets/icons/next.svg";
import saveIcon from "@/assets/icons/save.svg";
import { STATUS } from "@/pages/Upselling/v1/Crm";
import { Box, Button } from "@mui/material";
import Checkbox from "./CrmCheckbox";
import { Step2Props } from "../components/crm-upselling.types";
import { useCrmContext } from "../state/CrmV3Context";

const Step2: React.FC<Step2Props> = ({
  data,
  setStep,
  functions,
  isUpselling,
}) => {
  const { meetingComponentState } = useCrmContext();
  const { status, setStatus, eventId, setOpenCalendarEvents } =
    meetingComponentState;
  const handleSave = () => {
    if (
      [
        "Da richiamare",
        "Colloquio fissato",
        STATUS.UpsellingScheduled,
      ].includes(status)
    ) {
      setOpenCalendarEvents(true);
    } else if (
      [STATUS.UpsellingDone, STATUS.StandBy, "Colloquio fatto"].includes(status)
    ) {
      setStep(3);
    } else {
      functions?.onUpdateRow?.({
        toast: "status",
        tokenUser: data.user.token,
        values: { status: isUpselling ? status : "Assente al colloquio" },
      });
    }
  };

  return (
    <Box>
      <ul className="listEvents">
        {(Object.entries(data.buttons) as [string, any][]).map(
          ([key, value], i) => {
            return (
              <li
                key={key + i}
                onClick={() => setStatus(value.status)}
              >
                <Checkbox checked={status === value.status} />

                {value?.icon && (
                  <img
                    src={value.icon}
                    className="icon"
                    alt={value.status}
                  />
                )}
                {value?.name ? value?.name : value.status}
              </li>
            );
          }
        )}
      </ul>

      <Box className="mt-5 text-center">
        {eventId && (
          <>
            <Button
              sx={{ marginRight: "10px" }}
              variant="outlined"
              size="small"
              onClick={() => setStep((p: number) => p - 1)}
            >
              <img
                src={backIcon}
                className="icon"
                alt="Back"
              />
              Back
            </Button>

            {status && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleSave}
              >
                {[
                  STATUS.UpsellingDone,
                  STATUS.StandBy,
                  "Colloquio fatto",
                ].includes(status) ? (
                  <>
                    <img
                      src={nextIcon}
                      className="icon"
                      alt="Next"
                    />
                    Next
                  </>
                ) : (
                  <>
                    <img
                      src={saveIcon}
                      className="icon"
                      alt="Save"
                    />
                    Save
                  </>
                )}
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Step2;
