import { useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { Box, LinearProgress, Tab } from "@mui/material";
import useAPI from "@/api/useAPI";
import StudentsFeedbacks from "@/pages/Feedback/StudentsFeedbacks";
import Title from "@/components/_Common/Page/Title";
import DialogViewNotes from "./Feedback/DialogViewNotes";
import ConfirmationDialog from "@/components/ConfirmationDialog";

const KEY_QUERY = ["multi", "feedbacks"];
const ROUTE_HTTP_BASE = "/v1/feedbacks";
const ROUTE_HTTP_ARCHIVE = ROUTE_HTTP_BASE + "/archive";

export const COLORS_STARS = ["#ff0000", "#ff8000", "#ffa200", "#ffbf00", "#ffdd00"];
export const COLORS_STARS_MEDIA = ["#ff0000", "#ff1200", "#ff2900", "#ff4500", "#ff6400", "#ff7d00", "#ff9700", "#ffb000", "#ffc900", "#ffe000", "#ffff00"];

const TABS = [
  { label: "All", value: "1" },
  { label: "Students", value: "2" },
  { label: "Tutors", value: "3" },
  { label: "NPS", value: "4" },
  { label: "Archived", value: "5" },
];

interface INOpenConfirmArchive {
  open: boolean;
  id: number | null;
}

const Students = () => {
  const { userAdmin } = useAuth();
  const { data: dataAPI, isLoading } = useQuery(KEY_QUERY, () => useAPI(ROUTE_HTTP_BASE));

  const [tab, setTab] = useState<string>(localStorage.getItem("feedbacks_tab") || "1");
  const [data, setData] = useState<any>({});

  const [openConfirmArchive, setOpenConfirmArchive] = useState<INOpenConfirmArchive>({ open: false, id: null });

  const handleChangeTab = (_: any, newTab: string) => {
    localStorage.setItem("feedbacks_tab", newTab);
    setTab(newTab);
  };

  // NOTES ===============================================================
  const [open, setOpen] = useState<boolean>(false);
  const [allNotes, setAllNotes] = useState<Array<any>>([]);
  const [dataDialog, setDataDialog] = useState<any>({});

  const getNotesBackend = async (token: string) => {
    const notesData = await useAPI("/v1/notes_feedbacks/show", { token });
    if (!notesData?.length) return;
    setDataDialog((p) => ({ ...p, data: notesData }));
    setAllNotes((p) => [...p, ...notesData]);
  };

  const getNotes = ({ token, fullNome, numFeedback, person }) => {
    const notesData = allNotes.filter((e) => e?.token_user === token);
    if (!notesData.length) getNotesBackend(token);
    setDataDialog((p: any) => ({
      ...p,
      data: notesData,
      token,
      fullNome,
      numFeedback,
      person,
    }));
    setOpen(true);
  };

  const createNotes = (postData: any) => {
    useAPI("/v1/notes_feedbacks/create", postData);
    setAllNotes((p: any) => {
      const t = [...p];
      t.push(postData);
      return t;
    });
  };
  // NOTES ===============================================================

  const handleOpenPopupArchive = (id: number) => {
    setOpenConfirmArchive({ open: true, id });
  };

  const handleArchive = () => {
    const id = openConfirmArchive.id;
    setData((p: any) => ({
      nps: p.nps.filter((f: any) => f.id !== id),
      archive: p.archive.filter((f: any) => f.id !== id),
      feedbacks: p.feedbacks.filter((f: any) => f.id !== id),
    }));
    useAPI(ROUTE_HTTP_ARCHIVE, { id });
    setOpenConfirmArchive({ open: false, id: null });
  };

  useEffect(() => {
    if (!dataAPI) return;
    setData(dataAPI);
  }, [dataAPI]);

  if (isLoading) return <LinearProgress />;

  return (
    <Box className="mt-8 mx-5 md:mx-10">
      <Title>All Students</Title>

      <StudentsFeedbacks dataAPI={data.feedbacks} getNotes={getNotes} isLoading={isLoading} />

      <DialogViewNotes
        {...{
          open,
          setOpen,
          dataDialog,
          createNotes,
          userAdmin,
          setDataDialog,
          setDataAPI: setData,
        }}
      />

      {openConfirmArchive.open ? (
        <ConfirmationDialog open={openConfirmArchive.open} onClose={() => setOpenConfirmArchive({ open: false, id: null })} onConfirm={handleArchive}>
          Do you want to archive this feedback?
        </ConfirmationDialog>
      ) : null}
    </Box>
  );
};

export default Students;
