interface INSettings {
  getTitle?: string;
  getContent?: boolean;
}

export default async (content: string, settings: INSettings = {}): Promise<boolean | string> => {
  const setting = { getTitle: "", getContent: false, ...settings };
  await navigator.clipboard.writeText(content);
  if (setting.getTitle) return setting.getTitle;
  if (setting.getContent) return content;
  return true;
};
