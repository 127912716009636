import React from 'react';
import { Box, Card } from '@mui/material';
import MoodIcon from '@mui/icons-material/Mood';
import { Height } from '@mui/icons-material';

const PositiveComments = ({ data }) => {
  
  return (
    <Card>
      <Box height={420} p={2}>
        <div className="text-eduV2-1000 text-sm font-semibold leading-[13.93px] pb-4">
          Selected positive comments
        </div>
        {data?.map((feedback, index) => (
          <Box
            key={index}
            margin={1}
            display="flex"
            alignItems="center"
            py={1}
            className="border-[1px] rounded-md border-eduV2-800 p-2"
          >
            <MoodIcon sx={{ fontSize: 24, color: '#8065C9' }} />
            <div className="text-eduV2-1000 text-[10px] italic pl-2 p-1">
              {feedback.first_feedback}
            </div>
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default PositiveComments;
