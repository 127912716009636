import { TableStyling } from "@/dummy";
import { COLORS_STARS_MEDIA } from "../Feedback";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Dialog, Box, Rating, Tooltip, useTheme } from "@mui/material";
import useScreen from "@/hooks/useScreen";
import CountEvents from "./CountEvents";
import HeaderDialog from "@/components/Dialog/HeaderDialog";
import useGetElapsedTime from "@/components/useGetElapsedTime";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import { getTableStyling } from "@/providers/Colors";

const AccessEvents = ({
  accessEventsData,
  setAccessEventsData,
  getAPIAccessEvents,
}) => {
  const theme = useTheme();
  const columns: GridColDef[] = [
    {
      field: "id",
      minWidth: 70,
      flex: 0.3,
      headerName: "ID",
    },
    {
      field: "titolo",
      minWidth: 200,
      flex: 1,
      headerName: "Title",
      valueGetter: ({ row }) => row?.titolo || "-",
      renderCell: ({ row }) => (
        <Tooltip
          placement="bottom-start"
          arrow
          title={<Box className="text-base">{row?.titolo || "-"}</Box>}
        >
          <Box className="truncate">{row?.titolo || "-"}</Box>
        </Tooltip>
      ),
    },
    {
      field: "data",
      minWidth: 110,
      flex: 0.3,
      headerName: "Date",
      valueGetter: ({ row }) => row?.data || "-",
    },
    {
      field: "ora",
      minWidth: 70,
      flex: 0.3,
      headerName: "Time",
      valueGetter: ({ row }) => row?.ora || "-",
    },
    {
      field: "duration",
      minWidth: 110,
      flex: 0.3,
      headerName: "Duration",
      valueGetter: ({ row }) => {
        if (row?.duration != null) {
          return `${row.duration} min`;
        } else {
          return "-";
        }
      },
    },
    {
      field: "tutor_access",
      minWidth: 100,
      flex: 0.5,
      headerName: "On time",
      valueGetter: ({ row }) => {
        if (!row?.tutor_access) return "-";

        const start = new Date(`${row?.data} ${row?.ora}`);
        const end = new Date(row?.tutor_access);

        let value = useGetElapsedTime(start, end, { s: { hide: true } });

        if (value) return value;
        return "On time";
      },
      renderCell: ({ row }) => {
        if (!row?.tutor_access) return "-";

        const start = new Date(`${row?.data} ${row?.ora}`);
        const end = new Date(row?.tutor_access);

        let value = useGetElapsedTime(start, end, { s: { hide: true } });

        if (value)
          return (
            <Box
              style={{ color: value.charAt(0) === "+" ? "#ff5b5b" : "#10b981" }}
            >
              {value}
            </Box>
          );
        return <Box style={{ color: "#10b981" }}>On time</Box>;
      },
    },
    {
      field: "feedbacks",
      minWidth: 180,
      flex: 0.5,
      headerName: "Feedbacks",
      valueGetter: ({ row }) => row?.media_rating || "-",
      renderCell: ({ row }) => {
        const rat = Number(row?.media_rating || 0);
        if (!rat) return "-";
        return (
          <Tooltip
            placement="bottom-start"
            arrow
            title={
              <Box>
                {row?.feedback_user?.map((e: any, i: number) => {
                  const style =
                    i < row?.feedback_user.length - 1
                      ? {
                          borderBottom: "1px solid",
                          paddingTop: i !== 0 ? "0.4rem" : "",
                        }
                      : i !== 0
                      ? { paddingTop: "0.4rem" }
                      : {};

                  return (
                    <Box key={i}>
                      <Box
                        className="text-base flex gap-4 justify-between"
                        style={style}
                      >
                        <span>{`${e?.user?.first_name} ${e?.user?.last_name}`}</span>
                        <span>{e?.rating}</span>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            }
          >
            <Box className="flex items-center gap-2">
              <Box>{rat.toFixed(2)}</Box>
              <Rating
                style={{ color: COLORS_STARS_MEDIA[Math.floor(rat * 2)] }}
                precision={0.1}
                defaultValue={rat}
                readOnly
              />
            </Box>
          </Tooltip>
        );
      },
    },
  ];

  const onClose = () => {
    setAccessEventsData((p: any) => ({ ...p, open: false }));
  };

  return (
    <EduModal
      onClose={onClose}
      open={Boolean(accessEventsData?.open)}
      fullWidth
      maxWidth="xl"
    >
      <EduModalTitle onClose={onClose}>{accessEventsData?.name}</EduModalTitle>
      <EduModalBody className="p-5">
        <CountEvents
          email={accessEventsData?.email || ""}
          token={accessEventsData?.token || ""}
          getEventsAPI={getAPIAccessEvents}
        />

        <Box className="px-8">
          <DataGrid
            sx={getTableStyling(theme)}
            rows={accessEventsData?.rows || []}
            columns={columns}
            // density="comfortable"
            loading={!Boolean(accessEventsData?.rows?.length)}
            pageSize={20}
            autoHeight={true}
            components={{ Pagination: CustomPaginationClient }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            rowsPerPageOptions={[20]}
            experimentalFeatures={{ newEditingApi: false }}
            disableColumnMenu
            disableVirtualization
            disableSelectionOnClick
          />
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default AccessEvents;
