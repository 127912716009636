import AdPerformanceIcon from "@/assets/icons/ad-performance.svg";
import AdIcon from "@/assets/icons/ad.svg";
import { default as AvailabilitiesIcon, default as availabilitiesIcon } from "@/assets/icons/availabilities.svg";
import BookedIcon from "@/assets/icons/booked.svg";
import calendarIcon from "@/assets/icons/calendar.svg";
import CrmExerciseIcon from "@/assets/icons/crm-exercise.svg";
import CRMIcon from "@/assets/icons/crm.svg";
import dayOffIcon from "@/assets/icons/day-off.svg";
import EventStatisticIcon from "@/assets/icons/events-statistic.svg";
import EventsIcon from "@/assets/icons/events.svg";
import ExerciseIcon from "@/assets/icons/exercise.svg";
import FeedbackArchivedIcon from "@/assets/icons/feedback-archived.svg";
import FeedbackEventIcon from "@/assets/icons/feedback-icon.svg";
import FeedbackIcon from "@/assets/icons/feedback.svg";
import GestioneIcon from "@/assets/icons/gestione-iscritti.svg";
import KickOffDatesIcon from "@/assets/icons/kick-off-dates.svg";
import PaymentBalanceIcon from "@/assets/icons/payment-balance.svg";
import PaymentInIcon from "@/assets/icons/payment-incoming.svg";
import PaymentOutIcon from "@/assets/icons/payment-out.svg";
import PaymentTaxIcon from "@/assets/icons/payment-tax.svg";
import PerformanceIcon from "@/assets/icons/performance.svg";
import ProductPerformanceIcon from "@/assets/icons/product-performance.svg";
import ProductIcon from "@/assets/icons/product.svg";
import recruitingSearchIcon from "@/assets/icons/recruiting-search.svg";
import recruitingIcon from "@/assets/icons/recruiting.svg";
import ReferralIcon from "@/assets/icons/referral.svg";
import UserListIcon from "@/assets/icons/user-list.svg";
import WritingDoneIcon from "@/assets/icons/writing-done.svg";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import DialpadIcon from "@mui/icons-material/Dialpad";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import NumbersIcon from "@mui/icons-material/Numbers";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import { FaBlog } from "react-icons/fa";

export const links = [
  {
    name: "CRM",
    path: "/crm",
    icon: <img src={CRMIcon} />,
    group: "Sales",
    typeOf: ["english", "admission"],
    // isBeta: true,
  },
  {
    role: "divisor",
    group: "Sales",
  },
  // {
  //   name: "CRM",
  //   path: "/crm",
  //   icon: <img src={CRMIcon} />,
  //   group: "Sales",
  //   typeOf: ["english", "admission"],
  // },
  {
    name: "Ads",
    path: "/influencers",
    icon: <img src={AdIcon} />,
    group: "Sales",
    typeOf: ["english", "admission"],
  },
  // {
  //   name: "Dev center",
  //   path: "/dev-center",
  //   icon: (
  //     <DialpadIcon
  //       fontSize="small"
  //       color="primary"
  //     />
  //   ),
  //   group: "Sales",
  //   typeOf: ["english", "admission"],
  // },
  {
    name: "Orders",
    path: "/orders",
    icon: (
      <ShoppingCartIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Sales",
    typeOf: ["english", "admission"],
  },
  {
    role: "divisor",
    group: "Sales",
  },
  {
    name: "KPIs Call center",
    path: "/kpis-call-center",
    icon: <img src={AdPerformanceIcon} />,
    group: "Sales",
    typeOf: ["english", "admission"],
  },
  {
    name: "KPIs Leads",
    path: "/kpis-leads",
    icon: <img src={PerformanceIcon} />,
    group: "Sales",
    typeOf: ["english", "admission"],
  },
  {
    name: "KPIs Meetings",
    path: "/kpis-meetings",
    icon: <img src={ProductPerformanceIcon} />,
    group: "Sales",
    typeOf: ["english", "admission"],
  },
  {
    name: "KPIs Products",
    path: "/kpis-products",
    icon: (
      <ProductionQuantityLimitsIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Sales",
    typeOf: ["english", "admission"],
  },
  {
    name: "KPIs No Show",
    path: "/kpis-no-show",
    icon: (
      <VisibilityOffIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Sales",
    typeOf: ["english", "admission"],
  },
  {
    role: "divisor",
    group: "Sales",
  },
  {
    name: "Crm upselling",
    path: "/upselling/v2/crm",
    icon: <img src={CRMIcon} />,
    group: "Sales",
    typeOf: ["english"],
    isBeta: false,
  },
  {
    role: "divisor",
    group: "Sales",
  },
  //OLD CRM UPSELLING
  // {
  //   name: "Crm upselling",
  //   path: "/upselling/crm",
  //   icon: <img src={CRMIcon} />,
  //   group: "Sales",
  //   typeOf: ["english"],
  // },
  {
    name: "KPIs Meetings Upselling",
    path: "/upselling/kpis-meetings",
    icon: <img src={ProductPerformanceIcon} />,
    group: "Sales",
    typeOf: ["english"],
  },
  // Users =============
  {
    name: "All users",
    path: "/users",
    icon: <img src={GestioneIcon} />,
    group: "Users",
    typeOf: ["english", "admission"],
  },
  {
    name: "New users",
    path: "/users-list",
    icon: <img src={UserListIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Onboarding",
    path: "/onboarding",
    icon: (
      <WavingHandIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Dev center User",
    path: "/dev-center-user",
    icon: (
      <DialpadIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Users",
    typeOf: ["english", "admission"],
  },
  {
    name: "Upsolds",
    path: "/upselling/users-upsolds",
    icon: (
      <GroupAddOutlinedIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Suspended",
    path: "/user-suspended",
    icon: <img src={BookedIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Period availabilities",
    path: "/availabilities",
    icon: <img src={AvailabilitiesIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "NPS",
    path: "/nps",
    icon: <img src={FeedbackIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Referrals",
    path: "/referrals",
    icon: <img src={ReferralIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Exam booking",
    path: "/booked-ielts",
    icon: <img src={BookedIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Still to pay",
    path: "/still-to-pay",
    icon: <img src={PaymentInIcon} />,
    group: "Users",
    typeOf: ["english", "admission"],
  },
  {
    name: "User events stats",
    path: "/user-events-stats",
    icon: <img src={EventStatisticIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "KPIs Customer care",
    path: "/kpis-custore-care",
    icon: <img src={PerformanceIcon} />,
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Level change",
    path: "/level-change",
    icon: (
      <PublishedWithChangesIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Users",
    typeOf: ["english"],
  },
  {
    name: "Classi",
    path: "/classi",
    icon: (
      <NumbersIcon
        color="primary"
        fontSize="small"
      />
    ),
    group: "Users",
    typeOf: ["english"],
  },
  // Events =============
  {
    name: "All events",
    path: "/events",
    icon: <img src={EventsIcon} />,
    group: "Events",
    typeOf: ["english"],
  },
  {
    name: "Event statistics",
    path: "/events-statistics",
    icon: <img src={EventStatisticIcon} />,
    group: "Events",
    typeOf: ["english"],
  },
  {
    name: "Events feedbacks",
    path: "/events-feedbacks",
    icon: <img src={FeedbackEventIcon} />,
    group: "Events",
    typeOf: ["english"],
  },
  {
    name: "Events feedbacks archived",
    path: "/events-feedbacks-archived",
    icon: <img src={FeedbackArchivedIcon} />,
    group: "Events",
    typeOf: ["english"],
  },
  {
    name: "Events Report",
    path: "/events-report",
    icon: <img src={EventStatisticIcon} />,
    group: "Events",
    typeOf: ["english"],
  },
  // EXERCISES & MATERIALS =============
  // {
  //   name: "Exercises V1",
  //   path: "/exercises/v1",
  //   icon: <img src={ExerciseIcon} />,
  //   group: "Exercises & Materials",
  //   typeOf: ["english"],
  // },
  // {
  //   name: "Exercises V2",
  //   path: "/exercises/v2",
  //   icon: (
  //     <TextSnippetIcon
  //       fontSize="small"
  //       color="primary"
  //     />
  //   ),
  //   group: "Exercises & Materials",
  //   typeOf: ["english"],
  // },
  {
    name: "Exercises",
    path: "/exercises",
    icon: <img src={ExerciseIcon} />,
    group: "Exercises & Materials",
    typeOf: ["english"],
  },
  {
    role: "divisor",
    group: "Exercises & Materials",
  },
  {
    name: "Exercises done",
    path: "/exercises-done",
    icon: <img src={CrmExerciseIcon} />,
    group: "Exercises & Materials",
    typeOf: ["english"],
  },
  {
    name: "Writings done",
    path: "/writings-done",
    icon: <img src={WritingDoneIcon} />,
    group: "Exercises & Materials",
    typeOf: ["english"],
  },
  {
    name: "All Materials",
    path: "/materials",
    icon: <img src={ExerciseIcon} />,
    group: "Exercises & Materials",
    typeOf: ["english"],
  },
  // ACCOUNTING =====================
  {
    name: "All incoming payments",
    path: "/payments-in",
    icon: <img src={PaymentInIcon} />,
    group: "Accounting",
    typeOf: ["general"],
  },
  {
    name: "All outgoing payments",
    path: "/payments-out",
    icon: <img src={PaymentOutIcon} />,
    group: "Accounting",
    typeOf: ["general"],
  },
  {
    name: "Balances",
    path: "/corrispettivi",
    icon: <img src={PaymentBalanceIcon} />,
    group: "Accounting",
    typeOf: ["general"],
  },
  {
    name: "V.A.T.",
    path: "/users-vat",
    icon: <img src={PaymentTaxIcon} />,
    group: "Accounting",
    typeOf: ["general"],
  },
  // MEMBERS ======================
  {
    name: "Personal Info",
    path: "/personal-info",
    icon: (
      <PersonOutlineIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Members",
    typeOf: ["general"],
  },
  {
    name: "Team access",
    path: "/team",
    icon: (
      <GroupsRoundedIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Members",
    typeOf: ["general"],
  },
  {
    name: "English tutors",
    path: "/tutors",
    icon: (
      <EscalatorWarningIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Members",
    typeOf: ["general"],
  },
  {
    name: "Recruiting forms",
    path: "/recruiting-forms",
    icon: <img src={recruitingIcon} />,
    group: "Members",
    typeOf: ["general"],
  },
  {
    name: "Recruiting",
    path: "/recruiting",
    icon: <img src={recruitingSearchIcon} />,
    group: "Members",
    typeOf: ["general"],
  },
  {
    name: "Day Offs",
    path: "/day-offs",
    icon: <img src={dayOffIcon} />,
    group: "Members",
    typeOf: ["general"],
  },
  // Settings =====================
  {
    name: "Products",
    path: "/products",
    icon: <img src={ProductIcon} />,
    group: "Settings",
    typeOf: ["english", "admission"],
  },
  {
    name: "Calendars",
    path: "/calendars",
    icon: <img src={calendarIcon} />,
    group: "Settings",
    typeOf: ["english", "admission"],
  },
  {
    name: "Call Center",
    path: "/call-center",
    icon: (
      <CallOutlinedIcon
        fontSize="small"
        color="primary"
      />
    ),
    group: "Settings",
    typeOf: ["english", "admission"],
  },
  {
    name: "Kickoff dates",
    path: "/kickoff-dates",
    icon: <img src={KickOffDatesIcon} />,
    group: "Settings",
    typeOf: ["english"],
  },
  {
    name: "Avaibilities to insert",
    path: "/availabilities-to-insert",
    icon: <img src={availabilitiesIcon} />,
    group: "Settings",
    typeOf: ["english"],
  },
  {
    role: "divisor",
    group: "Settings",
  },
  {
    name: "Settings crm",
    path: "/settings/crm",
    icon: <img src={CRMIcon} />,
    group: "Settings",
    typeOf: ["english"],
  },
  {
    name: "Settings users",
    path: "/settings/users",
    icon: <img src={GestioneIcon} />,
    group: "Settings",
    typeOf: ["english"],
  },
  // blogs =====================
  {
    name: "Blog",
    path: "/blog",
    icon: <FaBlog size={24} color="#673ab7" />,
    group: "Settings",
    typeOf: ["english"],
  },
  // POP-UP ==============================
  {
    name: "English - Admission",
    path: "/",
    icon: <img src={CRMIcon} />,
    group: "POP-UP",
    typeOf: ["english", "admission"],
  },
  {
    name: "Problematic users",
    path: "/users-problematic",
    icon: <img src={GestioneIcon} />,
    group: "Users",
    typeOf: ["english", "admission"],
  },
  // =====================================
  // {
  //   name: "Activity logs",
  //   path: "/activity-logs",
  //   icon: <img src={activityLockIcon} />,
  //   group: "Settings",
  //   typeOf: ["english"],
  // },
  // {
  //   name: "CRM",
  //   path: "/tutoring/crm",
  //   icon: <img src={CRMIcon} />,
  //   group: "Admisions",
  // },
  // {
  //   name: "Products",
  //   path: "/tutoring/products",
  //   icon: <img src={ProductIcon} />,
  //   group: "Admisions",
  // },
  // {
  //   name: "Students",
  //   path: "/tutoring/students",
  //   icon: <img src={StudentsIcon} />,
  //   group: "Admisions",
  // },
  // {
  //   name: "Team",
  //   path: "/team",
  //   icon: <GroupsRoundedIcon />,
  //   group: "General",
  // },
  // {
  //   name: "Tutors",
  //   path: "/tutors",
  //   icon: <EscalatorWarningIcon />,
  //   group: "General",
  // }
];
