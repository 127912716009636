import React, { useState } from "react";
import {
  Popover,
  Typography,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const LevelChangeRequestPopOver = ({ reports }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        size="small"
      >
        {reports.length}
      </IconButton>
      {reports.length > 0 && (
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tutor</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Level Recommended</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell>{report.tutor.fullName}</TableCell>
                    <TableCell>{report.event.data}</TableCell>
                    <TableCell>
                      {report.recommended_level}
                      {report.level_change_direction === "increase" && (
                        <ArrowDropUpIcon style={{ color: "green" }} />
                      )}
                      {report.level_change_direction === "decrease" && (
                        <ArrowDropDownIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Popover>
      )}
    </div>
  );
};

export default LevelChangeRequestPopOver;
