import { createContext, useContext, memo, useEffect, useRef } from "react";
import { fireAction } from "./actions";
import { io, Socket } from "socket.io-client";
import { getCookie, rmCookie, setCookie } from "../../components/Cookie";
import axios from "axios";
import useAuth from "@/hooks/useAuth";

const ACTIVE_LOGS_NOTIFICATIONS =
  import.meta.env.VITE_DISABLE_LOGS_NOTIFICATIONS != "0";

const NotificationContext = createContext({ socket: null } as {
  socket: Socket | null;
});

export const useNotification = () => {
  return useContext(NotificationContext);
};

const requestNotificationPermission = () => {
  if ("Notification" in window && Notification.permission !== "granted") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        if (ACTIVE_LOGS_NOTIFICATIONS)
          console.info("Notification permission granted.");
      } else {
        if (ACTIVE_LOGS_NOTIFICATIONS)
          console.info("Notification permission denied.");
      }
    });
  }
};

const NotificationProvider = ({ children }) => {
  const { userAdmin } = useAuth();
  const SOCKET_URL = import.meta.env.VITE_SOCKET_URL as string;
  const socketRef = useRef<Socket | null>(null);
  const hasLoggedIn = useRef(false);
  const heartbeatInterval = useRef<NodeJS.Timeout | null>(null);
  const reconnectAttempts = useRef(0); // Track reconnection attempts
  const isDev = import.meta.env.VITE_MODE === "staging";

  const login = async () => {
    try {
      const { data } = await axios.post(
        `${SOCKET_URL}/login?auth_token=${userAdmin.token}&is_dev=${isDev}`
      );
      if (data?.jwt) {
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + 10);
        setCookie("notification_service", data.jwt, expirationDate);
        establishConnection(data.jwt);
      }
    } catch (error) {
      if (ACTIVE_LOGS_NOTIFICATIONS) console.error("login error:", error);
      rmCookie("notification_service");
    }
  };

  const establishConnection = (token) => {
    if (socketRef.current) return; // Prevent multiple connections

    socketRef.current = io(SOCKET_URL, {
      query: { token },
      transports: ["websocket"],
      reconnection: false, // Prevent automatic reconnection
    });

    socketRef.current.on("connect", () => {
      if (ACTIVE_LOGS_NOTIFICATIONS)
        console.info("Connection established with the notification server");
      reconnectAttempts.current = 0; // Reset attempts on successful connection
      startHeartbeat(); // Start sending pings to keep connection alive
    });

    socketRef.current.on("notification", (data) => {
      fireAction(data?.type, data?.data, data?.message);
    });

    socketRef.current.on("disconnect", (reason) => {
      if (ACTIVE_LOGS_NOTIFICATIONS) console.info("Disconnected:", reason);
      stopHeartbeat(); // Stop heartbeat when disconnected
      reconnect(); // Attempt to reconnect
    });

    socketRef.current.on("error", (error) => {
      if (ACTIVE_LOGS_NOTIFICATIONS) console.error("Socket.IO error:", error);
    });
  };

  const startHeartbeat = () => {
    if (heartbeatInterval.current) return; // Prevent multiple intervals

    heartbeatInterval.current = setInterval(() => {
      if (socketRef.current && socketRef.current.connected) {
        socketRef.current.emit("ping"); // Send a ping to keep connection alive
        if (ACTIVE_LOGS_NOTIFICATIONS)
          console.info("Ping sent to keep connection alive");
      }
    }, 30000); // Send ping every 30 seconds
  };

  const stopHeartbeat = () => {
    if (heartbeatInterval.current) {
      clearInterval(heartbeatInterval.current);
      heartbeatInterval.current = null; // Reset interval
    }
  };

  const reconnect = () => {
    if (reconnectAttempts.current >= 5) {
      // Limit the number of attempts
      if (ACTIVE_LOGS_NOTIFICATIONS)
        console.error("Max reconnect attempts reached");
      return;
    }

    reconnectAttempts.current += 1; // Increment attempts
    const timeout = Math.min(
      1000 * Math.pow(2, reconnectAttempts.current),
      30000
    ); // Exponential backoff

    if (ACTIVE_LOGS_NOTIFICATIONS)
      console.info(`Attempting to reconnect in ${timeout / 1000} seconds...`);
    setTimeout(() => {
      const tokenFromCookie = getCookie("notification_service");
      if (tokenFromCookie) {
        establishConnection(tokenFromCookie);
      } else {
        login(); // Re-login if token is missing
      }
    }, timeout); // Attempt reconnect after timeout
  };

  useEffect(() => {
    requestNotificationPermission();

    if (userAdmin?.token) {
      const tokenFromCookie = getCookie("notification_service");

      if (!tokenFromCookie && !hasLoggedIn.current) {
        hasLoggedIn.current = true;
        login();
      } else if (tokenFromCookie) {
        establishConnection(tokenFromCookie);
      }
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect(); // Clean up on unmount
        socketRef.current = null; // Clear socket reference
      }
      stopHeartbeat(); // Stop the heartbeat on unmount
    };
  }, [userAdmin]);

  return (
    <NotificationContext.Provider value={{ socket: socketRef.current }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default memo(NotificationProvider);
