import client from "@/api/client";
import { PriceCheckIcon } from "@/components";
import { IAddInterestedEventDialog } from "@/components/Crm/AddInterestedEventDialog";
import { clone } from "@/components/useHelpers";
import { queryClient } from "@/main";
import { hammerWhatsApp } from "@/pages/Upselling/v2/Crm/components/WhatsAppTexts";
import {
  BASE_PAYMENT_LINK,
  GENERATE_NUMBER_MONTHS,
  STATUS,
} from "@/pages/Upselling/v2/Crm/helpers/constants";
import { getLastByKey } from "@/pages/Upselling/v2/Crm/helpers/crm-update-rows";
import {
  defaultAnchorEl,
  INConfirmDialog,
} from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import { Box } from "@mui/material";
import { addMonths, format } from "date-fns";
import { Dispatch, SetStateAction } from "react";

const BASE_LANDING = import.meta.env.VITE_LANDING_CHECKOUT;

export function generateLinkCheckout(
  row: any,
  snackHandler: Function,
  copyToClipboard: Function
) {
  let meet = getLastByKey(row, "pacchetto");

  let { pacchetto: product, order_token } = meet

  let link = `${BASE_PAYMENT_LINK}/${product}/${row.token}`;

  if (order_token) {
    link = `${BASE_LANDING}/${row?.lang}/checkout-page/upselling?order_tk=${order_token}`;
  }

  if (!product) {
    snackHandler("Error, the product is missing!", "error");
    return false;
  }

  return copyToClipboard(link);
}

export function sendMessageReminder(
  row: any,
  snackHandler: Function,
  userAdmin: any
) {
  const expires_at = row?.discount_codes?.[0]?.pivot?.expires_at;

  if (!expires_at) {
    snackHandler("The discount code is expired", "error");
    // return;
  }

  hammerWhatsApp({
    phone: row.phone,
    nameUser: row.first_name,
    nameAdmin: userAdmin.user?.first_name,
    expirationDateTime: expires_at,
  });
}

export async function resetDiscountCodeUser(
  token: string,
  snackHandler: Function
) {
  const res = await client.users.resetDiscountCode({
    token,
  });

  if (res?.status) {
    snackHandler(
      `Success reset discount "${res?.discount?.code}" and +1 day from now`
    );
  } else {
    snackHandler(`Failed reset discount code`, "error");
  }
}

export function interestedInTheFuture(
  eventRef: React.RefObject<IAddInterestedEventDialog>,
  row: any
) {
  eventRef.current?.open({
    date: "",
    first_name: row.first_name,
    token: row.token,
    last_name: row.last_name,
  });
}

export const handleConfirmPayment = (
  user: any,
  paymentData: any,
  setConfirmDialogData: Function,
  setLoadingConfirmPayment: Dispatch<SetStateAction<boolean>>,
  rowSelected: any,
  snackHandler: Function,
  handleUpdateRow: Function,
  handleCloseConfirmDialog: Function
) => {
  const data = {
    first_name: user.first_name,
    email: user.email,
    phone: user.phone,
    tokenUser: user.token,
    last_name: user.last_name,
    isUpselling: true,
    newStatus: STATUS.Upsold,
    ...paymentData,
  };

  setConfirmDialogData(
    (p: INConfirmDialog): INConfirmDialog => ({
      ...p,
      open: true,
      title: (
        <Box className="flex items-center gap-1">
          <PriceCheckIcon fontSize="small" />
          <Box>PAID</Box>
        </Box>
      ),
      text: `Do you confirm that ${rowSelected?.first_name} ${rowSelected?.last_name} has paid?`,
      onAgree: async (): Promise<void> => {
        setConfirmDialogData(
          (p: INConfirmDialog): INConfirmDialog => ({ ...p, open: false })
        );

        setLoadingConfirmPayment(true);

        const res = await client.users.confirmPayment(data);

        setLoadingConfirmPayment(false);

        if (!res || !res?.status) {
          snackHandler("Error confirm payment!", "error");
          return;
        }

        snackHandler("Success confirm payment!");
        handleUpdateRow({
          toast: "status",
          values: { status: STATUS.Upsold },
          activeApi: false,
        });
        handleCloseConfirmDialog();
      },
      onDisagree: handleCloseConfirmDialog,
    })
  );
};

export const popoverInfo = (
  rowSelected: any,
  products:
    | {
      products: any[];
    }
    | undefined
) => {
  let lastMeet = rowSelected?.crm_upselling?.meetings?.meet
    ?.filter((meet: any) => Boolean(meet?.pacchetto))
    ?.at(-1);

  let path: string = products?.products?.find(
    (product: any) => product?.token === lastMeet?.pacchetto
  )?.product_tag;

  const dis = rowSelected?.discount_codes?.[0];

  return {
    path,
    code: dis?.code,
    used: parseInt(dis?.pivot?.used),
    amount: "-" + dis?.amount + (dis?.is_percentage ? "%" : "€"),
    expires_at: dis?.pivot?.expires_at,
    english_lvl: lastMeet?.english_lvl,
    newStartingMonth: lastMeet?.newStartingMonth,
  };
};

export const newStartingMonths = () => {
  const futureMonths: Array<string> = [];
  for (let i = 0; i <= GENERATE_NUMBER_MONTHS; i++) {
    const futureMonth = addMonths(new Date(), i);
    const formattedMonth = format(futureMonth, "MMMM yyyy");
    futureMonths.push(
      formattedMonth.charAt(0).toUpperCase() +
      formattedMonth.slice(1).toLowerCase()
    );
  }
  return futureMonths;
};

export const handleSetEnglishLevel = async (
  value: string,
  setAnchorsEl: Function,
  rowSelected: any,
  snackHandler: Function,
  setRows: Function,
  setRowSelected: Function,
  KEY_QUERY: any
) => {
  setAnchorsEl(defaultAnchorEl);

  const res = await client.users.update(rowSelected.token, {
    english_level: value,
  });

  if (res.english_level === value) {
    snackHandler("Success change english level");
  } else {
    snackHandler("Failed change english level", "error");
  }

  try {
    setRows((p: any) => {
      const t = clone(p);
      const i = t.findIndex((r: any) => r.token === rowSelected.token);
      setRowSelected(t[i]);
      t[i].english_level = value;
      return t;
    });
  } catch ($error) {
    queryClient.invalidateQueries(KEY_QUERY);
  }
};
