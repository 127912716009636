import { Dispatch, SetStateAction, useState } from "react";
import { Button } from "@mui/material";

interface IProps {
  setExercise: Dispatch<SetStateAction<any>>;
}

export default ({ setExercise }: IProps) => {
  const [nameFile, setNameFile] = useState<string>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target?.result;
        if (content) {
          try {
            const exercise = JSON.parse(content as string);

            exercise.exercises = {};

            [
              "groupings",
              "matching_option_gap_fill",
              "multiple_choice_true_false",
            ].forEach((key) => {
              exercise.exercises[key] = exercise[key];
              delete exercise[key];
            });

            setExercise(exercise);
          } catch (err) {
            console.error("Errore nel parsing del file JSON:", err);
          }
        }
      };

      reader.onerror = () => {
        console.error("Errore nella lettura del file.");
      };

      reader.readAsText(file);
      setNameFile(file.name);
    }
  };

  return (
    <Button
      disableElevation
      title="Select file JSON"
      variant="contained"
      component="label"
      className="!font-bold !text-base"
    >
      {nameFile ? nameFile : "Choose file JSON"}

      <input
        hidden
        type="file"
        accept=".json"
        onChange={handleFileChange}
      />
    </Button>
  );
};
