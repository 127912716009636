import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Avatar,
  Button,
  styled,
  useTheme,
  Typography,
} from "@mui/material";
import useDayOffApi, { IDayOff } from "@/api/useDayOffApi";
import cn from "classnames";
import deniedIcon from "@/assets/icons/day-off-denied.svg";
import dayOffIcon from "@/assets/icons/day-off-big.svg";
import requestIcon from "@/assets/icons/request-day-off.svg";
import approvedIcon from "@/assets/icons/day-off-approved.svg";

export interface IRequestDayOffModal {
  open: () => void;
}

interface IProps {
  ref: HTMLButtonElement;
  data?: IDayOff[];
}

const RequestDayOffModal = forwardRef<IRequestDayOffModal, IProps>(
  ({ data }, ref) => {
    const theme = useTheme();
    const { snackHandler } = useAuth();
    const { callUpdateStatusDayOff } = useDayOffApi();

    const [open, setOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<number | undefined>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataShow, setDataShow] = useState<IDayOff[]>();
    const [statusCompleted, setStatusCompleted] = useState<number>();

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(Boolean(data));
      },
    }));

    const handleClose = () => {
      setOpen(false);
    };

    const clickApprove = async (row: IDayOff) => {
      setLoading(true);
      const res = await callUpdateStatusDayOff(row.id, "approved");
      setLoading(false);

      if (res?.res == null) {
        snackHandler("Failed create block/s event/s into calendar!", "error");
        return;
      }

      snackHandler("Success!");
    };

    const clickDenied = async (row: IDayOff) => {
      setLoading(true);
      await callUpdateStatusDayOff(row.id, "denied");
      setLoading(false);

      snackHandler("Success!");
    };

    useEffect(() => {
      if (!open) return;

      if (status === 0) {
        setDataShow(data?.filter((p) => Number(p?.status) === 0));
      } else if (status === 1) {
        if (statusCompleted === -1) {
          setDataShow(data?.filter((p) => Number(p?.status) === -1));
        } else if (statusCompleted === 1) {
          setDataShow(data?.filter((p) => Number(p?.status) === 1));
        } else {
          setDataShow(data?.filter((p) => Number(p?.status) !== 0));
        }
      } else {
        setDataShow(data);
      }
    }, [data, open, status, statusCompleted]);

    return (
      <EduModal
        fullWidth
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation": { height: "100vh" },
        }}
        open={open}
        onClose={handleClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <img
            src={requestIcon}
            className="icon"
          />
          REQUEST DAYS OFF
        </EduModalTitle>

        <EduModalBody className="p-5 flex flex-col gap-5 overflow-auto !h-full">
          <Box className="flex flex-col gap-2">
            <Box className="grid grid-cols-3">
              <Button
                sx={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                variant={status === 0 ? "contained" : "outlined"}
                onClick={() => setStatus(0)}
                disabled={status === 0}
              >
                Not completed
              </Button>

              <Button
                sx={{ borderRadius: 0 }}
                variant={status === undefined ? "contained" : "outlined"}
                onClick={() => setStatus(undefined)}
                disabled={status === undefined}
                className="!border-x-0 grow"
              >
                All
              </Button>

              <Button
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                variant={status === 1 ? "contained" : "outlined"}
                onClick={() => setStatus(1)}
                disabled={status === 1}
                className="grow"
              >
                Completed
              </Button>
            </Box>

            {status === 1 && (
              <Box className="grid grid-cols-3">
                <Button
                  sx={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  variant={statusCompleted === -1 ? "contained" : "outlined"}
                  onClick={() => setStatusCompleted(-1)}
                  disabled={statusCompleted === -1}
                >
                  Rejected
                </Button>

                <Button
                  sx={{ borderRadius: 0 }}
                  variant={
                    statusCompleted === undefined ? "contained" : "outlined"
                  }
                  onClick={() => setStatusCompleted(undefined)}
                  disabled={statusCompleted === undefined}
                  className="!border-r-0 grow"
                >
                  Both
                </Button>

                <Button
                  sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  variant={statusCompleted === 1 ? "contained" : "outlined"}
                  onClick={() => setStatusCompleted(1)}
                  disabled={statusCompleted === 1}
                  className="grow"
                >
                  Accepted
                </Button>
              </Box>
            )}
          </Box>

          <Box className="flex flex-col gap-6 md:gap-4 overflow-y-auto pr-4 -mr-4 md:pr-0 md:mr-0">
            {(dataShow || []).map((d) => (
              <Box
                key={d.id}
                className="relative grid grid-cols-1 md:grid-cols-8 gap-2 md:gap-4"
              >
                {d.status != "0" && (
                  <Box
                    sx={{
                      bgcolor:
                        d.status == "1"
                          ? "#219653"
                          : d.status == "-1"
                            ? "#C80505"
                            : "",
                    }}
                    className="absolute top-0 left-0 rounded px-2 text-xs"
                  >
                    {d.status == "1" ? "Accepted" : "Rejected"}
                  </Box>
                )}

                <RequestItem className="col-span-1 md:col-span-7 px-4">
                  <Box className="flex p-3 gap-4 justify-between">
                    <Box className="flex items-center gap-4">
                      {d.admin.avatar ? (
                        <Avatar
                          src={d.admin.avatar}
                          alt={`${d.admin.first_name} ${d.admin.last_name}`}
                          style={{ height: "29px", width: "29px" }}
                        />
                      ) : (
                        <Avatar style={{ height: "29px", width: "29px" }}>
                          {d.admin?.first_name?.charAt(0)?.toUpperCase()}
                        </Avatar>
                      )}
                      {d.admin.first_name} {d.admin.last_name}
                    </Box>

                    <Box className="flex gap-4">
                      <img src={dayOffIcon} />
                      <Box>
                        {format(new Date(d.start_time), "dd MMMM, HH:mm")}
                        <br />
                        {format(new Date(d.end_time), "dd MMMM, HH:mm")}
                      </Box>
                    </Box>
                  </Box>

                  {Boolean(d.reason) && (
                    <Box
                      className="p-5"
                      style={{
                        borderTop: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {d.reason}
                    </Box>
                  )}
                </RequestItem>

                <Box className="flex flex-row-reverse md:flex-col gap-4">
                  <ApproveButton
                    onClick={() => clickApprove(d)}
                    disabled={loading}
                    className={cn(d.status === -1 && "unactive")}
                  >
                    <img src={approvedIcon} />
                  </ApproveButton>

                  <DeniedButton
                    onClick={() => clickDenied(d)}
                    disabled={loading}
                    className={cn(d.status === 1 && "unactive")}
                  >
                    <img src={deniedIcon} />
                  </DeniedButton>
                </Box>
              </Box>
            ))}
          </Box>

          {(data || []).length === 0 && (
            <Typography color="grey">No Request</Typography>
          )}
        </EduModalBody>
      </EduModal>
    );
  }
);

const RequestItem = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "8px",
}));

const DeniedButton = styled(Button)(({ theme }) => ({
  background: "#C80505",
  "&.unactive": {
    opacity: 0.2,
  },
  "&:hover": {
    background: "#C80505",
    opacity: 1,
  },
}));

const ApproveButton = styled(Button)(({ theme }) => ({
  background: "#219653",
  "&.unactive": {
    opacity: 0.2,
  },
  "&:hover": {
    background: "#219653",
    opacity: 1,
  },
}));

export default RequestDayOffModal;
