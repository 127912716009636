import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useAPI from "@/api/useAPI";
import useAuth from "@/hooks/useAuth";

export const useLinks = () => {
  const fetchLinks = async () => {
    const response = await useAPI("/v1/links", {}, { type: "get" });
    return response;
  };

  return useQuery(["links"], fetchLinks);
};

export const useSaveLinks = (setLinksData) => {
  const { snackHandler } = useAuth();

  const queryClient = useQueryClient();

  const saveLinks = async (linksData) => {
    await useAPI("/v1/links", { ...linksData }, { type: "post" });
  };

  return useMutation(saveLinks, {
    onSuccess: () => {
      queryClient.invalidateQueries(["links"]);
      snackHandler("Data saved successfully");
    },
    onSettled: async () => {
      const updatedLinks = await queryClient.fetchQuery(["links"]);
      if (updatedLinks) {
        setLinksData(updatedLinks);
      }
    },
  });
};
