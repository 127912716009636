import { format } from "date-fns";
import { fUpper } from "@/components/useHelpers";
import { Box, MenuItem, TextField } from "@mui/material";
import StatusIcon from "./StatusIcon";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

type Props = {
  filterData: any;
  setFilters: (filters: any) => void;
  handleFilterChange: (event: any) => void;
};

const FilterOrders = ({
  filterData,
  setFilters,
  handleFilterChange,
}: Props) => {
  return (
    <Box className="p-4 flex flex-col gap-4">
      <TextField
        select
        fullWidth
        id="status-select"
        name="status"
        color="primary"
        label="Status"
        value={filterData?.status || ""}
        onChange={handleFilterChange}
      >
        {["initialized", "pending", "completed"].map((v, i) => (
          <MenuItem
            key={v + i}
            value={v}
          >
            <Box className="flex flex-row gap-3">
              <StatusIcon
                status={v}
                className="h-5 w-5"
              />
              <span>{v}</span>
            </Box>
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        fullWidth
        id="status-select"
        name="typeof"
        color="primary"
        label="Typeof"
        value={filterData?.typeof || ""}
        onChange={handleFilterChange}
      >
        {["lead", "tutoring", "upselling"].map((v, i) => (
          <MenuItem
            key={v + i}
            value={v}
          >
            {fUpper(v)}
          </MenuItem>
        ))}
      </TextField>

      <DateRangePickerField
        title="Creation date"
        value={
          filterData?.created_at
            ? filterData?.created_at.split(",").map((c: string) => new Date(c))
            : null
        }
        onChange={(value) => {
          setFilters((p: any) => {
            if (value) {
              p.set(
                "created_at",
                [
                  format(value?.startDate, "yyyy-MM-dd HH:mm:ss"),
                  format(value?.endDate, "yyyy-MM-dd HH:mm:ss"),
                ].join(",")
              );
              p.set("page", "1");
            } else p.delete("created_at");
            return p;
          });
        }}
      />
    </Box>
  );
};

export default FilterOrders;
