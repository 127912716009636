interface INAspect {
  h?: { text?: string; hide?: boolean };
  m?: { text?: string; hide?: boolean };
  s?: { text?: string; hide?: boolean };
}

export default (start: Date, end: Date, aspectParams: INAspect = {}) => {
  const aspect: INAspect = { h: { text: " h ", hide: false }, m: { text: " min ", hide: false }, s: { text: " sec", hide: false }, ...aspectParams };

  const MF = (v: number) => Math.floor(v);

  const startTime = start.getTime();
  const endTime = end.getTime();

  let sign = "+";
  let diff = 0;

  if (startTime >= endTime) {
    sign = "-";
    diff = startTime - endTime;
  } else {
    diff = endTime - startTime;
  }

  const s = MF(diff / 1000);
  const m = MF(s / 60);
  const h = MF(m / 60);

  return (
    (h || m ? sign : "") +
    (!aspect?.h?.hide ? (h > 0 ? h + (aspect?.h?.text || "") : "") : "") +
    (!aspect?.m?.hide ? (m > 0 ? m + (aspect?.m?.text || "") : "") : "") +
    (!aspect?.s?.hide ? (s > 0 ? s + (aspect?.s?.text || "") : "") : "")
  ).trim();
};
