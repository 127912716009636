import useAuth from '@/hooks/useAuth';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import Request from "./RequestAdmin";
import useSessionStorage from "@/hooks/useSessionStorage";

const useRecruitingApi = () => {
  const { userAdmin, snackHandler } = useAuth();
  const queryClient = useQueryClient();

  const keyCache = "recruiting";
  const [getParams, setParams] = useSessionStorage<object>(keyCache);

  const statuses = [
    {
      label: "Partially filled",
      value: "Partially filled",
    },
    {
      label: "Screened out",
      value: "Screened out",
    },
    {
      label: "Schedule screening",
      value: "Schedule screening",
    },
    {
      label: "Screening scheduled",
      value: "Screening scheduled",
    },
    {
      label: "No show",
      value: "No show",
    },
    {
      label: "Not interested",
      value: "Not interested",
    },
    {
      label: "On-hold",
      value: "On-hold",
    },
    {
      label: "Rejected screening",
      value: "Rejected screening",
    },
    {
      label: "Interview with manager scheduled",
      value: "Interview with manager scheduled",
    },
    {
      label: "Rejected interview",
      value: "Rejected interview",
    },
    {
      label: "Employed",
      value: "Employed",
    },
    {
      label: "Offer rejected",
      value: "Offer rejected",
    }
  ];

  // Get All candidates ===============

  const getCandidates = async (params: unknown) => {
    const { data } = await Request.call(
      {
        url: "/v1/recruitings",
        method: "GET",
        params,
      },
      userAdmin.token
    );

    return data;
  };

  const callGetCandidates = (params = {}, enabled = true) => {
    setParams(params);

    return useQuery([keyCache, params], () => getCandidates(params), {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      enabled,
    });
  };

  // Download Candidates ==========

  const downloadCandidates = async (params: unknown, fileName) => {
    const { data } = await Request.download(
      {
        url: "/v1/recruitings",
        method: "GET",
        params,
      },
      userAdmin.token,
      fileName
    );

    return data;
  };

  const callDownloadCandidates = async (params, filename = "") => {
    if (!filename) filename = `Candidates ${dayjs().format("DD-MM-YYYY")}.csv`;

    const rs = await downloadCandidates({ ...params, export: true }, filename);
    return rs;
  };

  // Update Candidate ==============

  const postPutUpdateCandidate = async (id: number, data: unknown) => {
    const result = await Request.call(
      {
        url: `/v1/recruitings/${id}`,
        method: "PUT",
        data,
      },
      userAdmin.token
    );

    return result?.data;
  };

  const callPutUpdateCandidate = useMutation(
    (params: { id: number; data: unknown }) =>
      postPutUpdateCandidate(params.id, params.data),
    {
      onSuccess: async () => {
        const params = getParams();
        await queryClient.invalidateQueries([keyCache, params]);

        snackHandler("Candidate updated!");
      },
    }
  );

  // Delete Candidate
  const deleteCandidate = async (id: number) => {
    const result = await Request.call(
      {
        url: `/v1/recruitings/${id}`,
        method: "DELETE",
      },
      userAdmin.token
    );

    return result?.data;
  };

  const callDeleteCandidate = useMutation(
    (params: { id: number }) => deleteCandidate(params.id),
    {
      onSuccess: async () => {
        const params = getParams();
        await queryClient.invalidateQueries([keyCache, params]);

        snackHandler("Candidate deleted!");
      },
    }
  );

  return {
    callGetCandidates,
    callPutUpdateCandidate,
    callDeleteCandidate,
    callDownloadCandidates,
    statuses,
  };
};

export default useRecruitingApi;
