import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import useAPI from '@/api/useAPI';
import { useQueryClient } from '@tanstack/react-query';
import { EduModal, EduModalBody, EduModalTitle } from '@/components/_Common/Modal/ModalStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import saveIcon from "@/assets/icons/save.svg";

const EditMaxChangeEvent = ({ open, handleClose, cacheKey, userToken, maxValue }) => {
  const [maxChange, setMaxChange] = useState('');
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMaxChange(maxValue);
  }, [maxValue]);
  
  const handleSubmit = async () => {
    const maxChangeNumber = parseInt(maxChange, 10);

    if (isNaN(maxChangeNumber) || maxChangeNumber <= 0) {
      setError(true);
      return;
    }

    useAPI(`v1/${userToken}/edit-max-change-event`,{max_value:maxChange}, { type: 'patch' });
    queryClient.invalidateQueries(cacheKey);
    handleClose();
  };

  const handleChange = (event) => {
    setMaxChange(event.target.value);
    setError(false);
  };

  return (
    <EduModal open={open} onClose={handleClose}>
      <EduModalTitle>Edit Max Change</EduModalTitle>
      <EduModalBody  p={2}>
        <TextField
          autoFocus
          margin="dense"
          id="maxChange"
          label="Max Change"
          type="number"
          fullWidth
          value={maxChange}
          onChange={handleChange}
          error={error}
          helperText={error ? "Please enter a positive integer" : ""}
        />
        <Box className="flex flex-row-reverse">
              <LoadingButton
                type="submit"
                variant="outlined"
                loading={isLoading}
                onClick={handleSubmit}
              >
                <img
                  src={saveIcon}
                  className="icon"
                />
                Save
              </LoadingButton>
            </Box>
      </EduModalBody>

       
    </EduModal>
  );
};

EditMaxChangeEvent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  cacheKey: PropTypes.any.isRequired,
  userToken: PropTypes.string,
  maxValue: PropTypes.number,
};

export default EditMaxChangeEvent;
