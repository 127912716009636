export {}

String.prototype.strCapitalization = function () {
    if (this.includes("'"))
      return this.split("'")
        .map((w) => capitalize(w))
        .join("'");

    if (this.includes(" "))
      return this.split(" ")
        .map((w) => capitalize(w).trim())
        .join(" ");

    return capitalize(this as string);
  };


const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const AreSomeKeysEmpty = <T extends object>(object: T, ...keys: (keyof T)[]): boolean => {
  return keys.some((key) => key in object && object[key] !== undefined && object[key] !== null && object[key] !== "");
}

export const AreAllKeysEmpty = <T extends object>(object: T, ...keys: (keyof T)[]): boolean => {
  return keys.every((key) => key in object && object[key] !== undefined && object[key] !== null && object[key] !== "");
}

export const debounce = (func: any, wait: number, immediate: boolean) => {
  let timeout: any;
  return function executedFunction(...args: any[]) {
    // @ts-ignore
    const context = this;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };


    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}


export const uuid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(2)
      .toUpperCase();
  };

  return `${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}`;
}
