import { useMemo } from "react";
import { euro } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { deepPurple } from "@mui/material/colors";
import { ENGLISH_LEVEL } from "@/pages/Users/UserSingle";
import { Info, Check, Close } from "@mui/icons-material";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import Dropdown from "@/components/_Common/Dropdown";
import useUserApi from "@/api/useUserApi";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import useExportCSV from "@/components/useExportCSV";
import downloadIcon from "@/assets/icons/download.svg";
import useProductApi from "@/api/useProductApi";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

export const exportToCsv = (
  header: Array<string>,
  rows: any,
  range: Array<string> | string
) => {
  const data = rows.map((row: any) => [
    typeof range === "string" ? range : range.join("/"),
    `${row?.first_name} ${row?.last_name}`,
    row.start_month ?? "-",
    row.english_level ?? "-",
    row.nome_percorso ?? "-",
    ...row?.array?.Stringa?.map((d: object) => d[Object.keys(d)[0]]),
  ]);

  const csvData = [
    [...header, ...rows[0]?.array?.Stringa?.map((d) => Object.keys(d)[0])],
    ...data,
  ]
    .map((e) => e.join(";"))
    .join("\n");

  useExportCSV(csvData, `Availabilities ${dayjs().format("DD-MM-YYYY")}.csv`);
};

export default ({
  filters,
  setFilters,
  handleOnOpenInfoFlow,
  handleExportAvailabilities,
  handleExportBadAvailabilities,
  handleOnOpenShowStructureAutomation,
}) => {
  const { isMember, snackHandler } = useAuth();
  const { data: products } = useProductApi().callGetProducts();
  const { data: startMonths } = useUserApi().callGetStartMonths();

  const handleDownloadAvailabilitiesFromFlow = async () => {
    const res = await useAPI(
      "/v1/availabilities/download-missing-availabilities"
    );

    if (!res?.status) {
      snackHandler(res?.message || "Error Download", "error");
      return;
    }

    useExportCSV(res.data, "Missing-availability-of-users.csv");
    snackHandler("Success download");
  };

  const handleChange = ({ target: { name, value } }: any) => {
    setFilters((p: any) => {
      if (value) p.set(name, value);
      else p.delete(name);
      p.set("page", "1");
      return p;
    });
  };

  const englishMenu = useMemo(
    () =>
      ENGLISH_LEVEL.map((e: string, i: number) => (
        <MenuItem
          key={i}
          value={e}
        >
          {e}
        </MenuItem>
      )),
    []
  );

  const startMonthMenu = useMemo(
    () =>
      startMonths?.data?.map((e: any) => (
        <MenuItem
          key={e.month}
          value={e.month}
        >
          {e.month_en}
        </MenuItem>
      )),
    [startMonths]
  );

  const productMenu = useMemo(
    () =>
      products?.products?.map((e: any) => (
        <MenuItem
          key={e.token}
          value={e.token}
        >
          <Box className="w-full flex justify-between gap-4">
            <span>{e.product_tag}</span>
            <span>{euro(e.price / 100)}</span>
          </Box>
        </MenuItem>
      )),
    [products?.products]
  );

  return (
    <Box
      id="filter_bar"
      className="flex gap-5 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Dropdown
          active={Boolean(
            Object.keys(filters).filter(
              (key) => !["search", "sorts", "page"].includes(key)
            ).length
          )}
          text="Filter"
          icon={filterIcon}
        >
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            <TextField
              select
              fullWidth
              name="english_level"
              label="English level"
              value={filters?.english_level || ""}
              onChange={handleChange}
            >
              {englishMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="start_month"
              label="Start month"
              value={filters?.start_month || ""}
              onChange={handleChange}
            >
              {startMonthMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="product"
              label="Current product"
              value={filters?.product || ""}
              onChange={handleChange}
            >
              {productMenu}
            </TextField>
          </Box>
        </Dropdown>

        {!isMember && (
          <Dropdown
            text={
              <Box className="flex items-center gap-1">
                <img
                  src={downloadIcon}
                  className="icon"
                />
                <span>Download</span>
              </Box>
            }
          >
            <Box className="flex flex-col gap-4 p-4">
              <Button
                variant="outlined"
                onClick={handleExportAvailabilities}
              >
                <Check
                  sx={{ color: deepPurple["300"] }}
                  className="mr-2"
                />
                Availabilities
              </Button>

              <Button
                variant="outlined"
                onClick={handleExportBadAvailabilities}
              >
                <Close
                  sx={{ color: deepPurple["300"] }}
                  className="mr-2"
                />
                Bad availabilities
              </Button>
            </Box>
          </Dropdown>
        )}

        {!isMember && (
          <Dropdown
            text={
              <>
                <Info
                  sx={{ color: deepPurple["300"] }}
                  className="mr-2"
                />
                Info flow
              </>
            }
          >
            <Box className="flex flex-col gap-4 p-4">
              <Button
                variant="outlined"
                onClick={() => handleOnOpenInfoFlow()}
              >
                Show actions automations
              </Button>

              <Button
                variant="outlined"
                onClick={() => handleOnOpenShowStructureAutomation()}
              >
                Show structure automations
              </Button>

              <Button
                variant="outlined"
                onClick={handleDownloadAvailabilitiesFromFlow}
              >
                Download rest users
              </Button>
            </Box>
          </Dropdown>
        )}
      </FiltersBar>

      <SearchToolbar
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};
