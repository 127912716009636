import { FC } from "react";
import { clone } from "@/components/useHelpers";
import {
  defDefinition,
  defKeysExercises,
  defKeysExercisesArray,
  ISecondaryKeysExercises,
  defSecondaryKeysExercises,
} from "@/pages/Exercises/ExercisesV2/Create/interfacesAndDefaults";
import { ExerciseQuestionsPropsType } from "@/pages/Exercises/ExercisesV3/exercisesV3.types";
import { Box, Checkbox, FormControlLabel, useTheme } from "@mui/material";
import AddNewExerciseQuestionsAccordion from "./AddNewExerciseQuestionsAccordion";
import Definitions from "@/pages/Exercises/ExercisesV2/Create/Definitions";

const getFullKeyExercise = (v: string): any =>
  defSecondaryKeysExercises?.find((k) => k?.includes(v));

const isOneFromTypes = (v: string) =>
  ["multiple_choice", "true_false", "matching_option", "gap_fill"].includes(v);

const AddNewExerciseQuestions: FC<ExerciseQuestionsPropsType> = ({
  exercise,
  setExercise,
}) => {
  const theme = useTheme();

  const sx = {
    color: theme.palette.primary.main,
    bgcolor: theme.palette.mode == "dark" ? "transparent" : "white",
    borderColor: theme.palette.primary.main,
    "& .MuiFormControlLabel-label": {
      lineHeight: "1rem",
    },
  };

  return (
    <Box className="flex flex-col gap-6">
      <Box className="grid grid-cols-6 gap-6">
        {defKeysExercisesArray.map((key) => {
          const keyExercise = getFullKeyExercise(key);

          if (!keyExercise) {
            return null;
          }

          const isWithType = isOneFromTypes(key);

          const hasType =
            isWithType &&
            exercise?.exercises?.[keyExercise]?.find(
              ({ type }: any) => type == key
            );

          const isChecked = Boolean(
            exercise?.exercises?.[keyExercise]?.length &&
              ((isWithType && hasType) ||
                (!isWithType && exercise?.exercises?.[keyExercise]?.length))
          );

          return (
            <FormControlLabel
              sx={sx}
              key={key}
              label={key?.replaceAll("_", " ")?.toUpperCase()}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={({ target: { checked } }) => {
                    setExercise((prev) => {
                      let ex: any = clone(prev.exercises ?? {});

                      if (checked) {
                        ex[keyExercise] = [
                          ...(ex[keyExercise] ?? []),
                          {
                            ...clone(defKeysExercises[keyExercise]),
                            type: key,
                          },
                        ];
                      } else {
                        if (
                          [
                            "multiple_choice",
                            "true_false",
                            "matching_option",
                            "gap_fill",
                          ].includes(key)
                        ) {
                          ex[keyExercise] = ex[keyExercise]?.filter(
                            ({ type }) => type != key
                          );

                          if (!ex?.[keyExercise]?.length) {
                            delete ex[keyExercise];
                          }
                        } else if (["groupings"].includes(key)) {
                          delete ex[keyExercise];
                        }
                      }

                      return { ...prev, exercises: ex };
                    });
                  }}
                />
              }
              className="w-full rounded-[0.6rem] border h-[2.7rem] !mx-0"
            />
          );
        })}

        <FormControlLabel
          sx={sx}
          key="definitions"
          label="DEFINITIONS"
          control={
            <Checkbox
              checked={Boolean(exercise?.definitions?.length)}
              onChange={({ target: { checked } }) => {
                setExercise((prev) => ({
                  ...prev,
                  definitions: checked ? [clone(defDefinition)] : [],
                }));
              }}
            />
          }
          className="w-full rounded-[0.6rem] border h-[2.7rem] !mx-0"
        />
      </Box>

      {defKeysExercisesArray.map((key) => {
        const keyExercise = getFullKeyExercise(key);

        if (!keyExercise) {
          return null;
        }

        const isWithType = isOneFromTypes(key);

        let questions = exercise?.exercises?.[keyExercise]?.map(
          (e: any, i: number) => ({ ...e, idx_: i })
        );

        return Boolean(
          (isWithType && questions?.find((q: any) => q?.type == key)) ||
            (!isWithType && questions?.length)
        ) ? (
          <AddNewExerciseQuestionsAccordion
            key={key}
            type={key}
            exercise={exercise}
            questions={
              ((isWithType
                ? questions?.filter((q: any) => q?.type == key)
                : questions) ?? []) as Array<any>
            }
            keyVersion={keyExercise}
            setExercise={setExercise}
          />
        ) : null;
      })}

      {Boolean(exercise?.definitions?.length) && (
        <Definitions
          exercise={exercise}
          setExercise={setExercise}
        />
      )}
    </Box>
  );
};

export default AddNewExerciseQuestions;
