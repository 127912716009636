import { useState, useEffect, useCallback, createRef } from "react";
import useAuth from '@/hooks/useAuth';
import { getColumns } from "./column";
import { useNavigate } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { defaultSortModel } from "@/pages/DevelopmentCenter/components/CallCenterTable";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Button,
  Dialog,
  useTheme,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import callIcon from "@/assets/icons/call.svg";
import useCrmApi from "@/api/useCrmApi";
import useScreen from "@/hooks/useScreen";
import DialpadIcon from "@mui/icons-material/Dialpad";
import SearchFilter from "./SearchFilter";
import UsersListNotes from "@/components/Dialpad/Comment";
import DialpadMassCall from "@/components/Dialpad/DialpadMassCall";
import CustomPagination from "@/components/_Common/CustomPagination";
import DialpadCallModal from "@/components/Dialpad/DialpadSingleCallV2/DialpadSingleCall";
import UserUpdatesDialog from "./UserUpdatesDialog";
import CallBackDateDialog from "./CallBackDateDialog";
import { IDialpadCallModal } from "@/components/Dialpad/interface";

const cacheKey = ["dev-center-users"];

const CallCenterTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { perPage } = useScreen();
  const { callUpdateCrm } = useCrmApi();
  const { userAdmin, snackHandler } = useAuth();

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<any[]>([]);
  const [menuRow, setMenuRow] = useState<null | number>(null);
  const [filters, setFilters] = useState({ callType: "", lang: "" });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isStandBy, setIsStandBy] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [searchTerm, setSearchTerm] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const [pagination, setPagination] = useState({ total: 0 });
  const [callStarted, setCallStarted] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [commentUser, setcommentUser] = useState<any>({});
  const [updatesUser, setUpdatesUser] = useState<any>({});
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [selectedRows, setSelectRows] = useState<Array<any>>([]);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [selectionModel, setSelectionModel] = useState<Array<any>>([]);
  const [openUserUpdates, setOpenUserUpdates] = useState(false);
  const [massCallStarted, setMassCallStarted] = useState(false);
  const [openNoReplyDialog, setOpenNoReplyDialog] = useState(false);
  const [targetCaller, setTargetCaller] = useState({});

  const dialpadCallRef = createRef<IDialpadCallModal>();

  const fetchTableData = async ({ queryKey }) => {
    const [
      _key,
      { sorts, search, archived, standby, page, perPage, callType, lang },
    ] = queryKey;

    const sortsObject = sorts.reduce((acc: any, { field, sort }) => {
      acc[field] = sort;
      return acc;
    }, {});

    const sortsString = JSON.stringify(sortsObject);

    const params = {
      page,
      search,
      lang: lang,
      uuid: userAdmin.user.uuid,
      sorts: sortsString,
      stand_by: standby,
      per_page: perPage,
      call_type: callType,
      is_archived: archived,
    };

    return useAPI("v1/user-dev-center/users", params, { type: "post" });
  };

  const { data, isLoading, error } = useQuery(
    [
      cacheKey,
      {
        page,
        perPage,
        sorts: sortModel,
        search: searchTerm,
        standby: isStandBy,
        archived: isArchived,
        ...filters,
      },
    ],
    fetchTableData,
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const handleDialpadCall = (row: any) => {
    setTargetCaller(row);
    if (dialpadCallRef.current) {
      dialpadCallRef.current.openModal();
    }
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuRow(id);
  };

  const handleOpenUserUpdates = (row: any) => {
    setUpdatesUser(row);
    setOpenUserUpdates(true);
  };

  const handleCloseUserUpdates = () => {
    setUpdatesUser(null);
    setOpenUserUpdates(false);
  };

  const handleCommentClick = (row: any) => {
    setOpenComment(true);
    setcommentUser(row);
  };

  const invalidateAllQueries = () => {
    queryClient.invalidateQueries([
      cacheKey,
      {
        page,
        perPage,
        sorts: sortModel,
        search: searchTerm,
        standby: isStandBy,
        archived: isArchived,
        ...filters,
      },
    ]);
  };

  const handleStatusChange = (row: any, newStatus: any) => {
    useAPI(
      `v1/user-dev-center/status/${row.id}`,
      { status: newStatus },
      { type: "patch" }
    );
    invalidateAllQueries();
  };

  const handleStandBy = (row: any) => {
    useAPI(
      `v1/user-dev-center/stand-by/${row.feedback_id}`,
      { callType: row.type },
      { type: "patch" }
    );
    invalidateAllQueries();
  };

  const handleResolve = (row: any, type: any) => {
    if (type == "Absent") {
      useAPI(
        `v1/user-dev-center/resolve/${row.id}`,
        { callType: type },
        { type: "patch" }
      );
    } else {
      useAPI(
        `v1/user-dev-center/resolve/${row.feedback_id}`,
        { callType: type },
        { type: "patch" }
      );
    }

    invalidateAllQueries();
  };

  const handleRowSelectionChange = (newSelection: Array<any>) => {
    setSelectionModel(newSelection);
    setSelectRows(
      newSelection.map((uuid) => rows.find((row) => row.uuid === uuid))
    );
  };

  const handleMassCall = () => {
    setMassCallStarted(true);
  };

  const handleCallEnded = () => {
    setCallStarted(false);
    setMassCallStarted(false);
    queryClient.invalidateQueries([
      cacheKey,
      {
        page,
        perPage,
        sorts: sortModel,
        search: searchTerm,
        standby: isStandBy,
        archived: isArchived,
        ...filters,
      },
    ]);
  };

  const handleDateDialogClose = () => {
    setOpenDateDialog(false);
  };

  const handleDateConfirm = (date: Date | null) => { };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRow(null);
  };

  const handleNoReplyDialogClose = () => {
    setOpenNoReplyDialog(false);
  };

  const handleNoReplyConfirm = (action: "increase" | "replied") => {
    if (action === "replied") {
      const temp = {
        ...selectedRow,
        noReplyCount: 0,
      };
      setSelectedRow(temp);
    }
    setOpenNoReplyDialog(false);
  };

  const debounce = (func: any, wait: any) => {
    let timeout: any = null;
    return (...args: any) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleSearch = useCallback(
    debounce((term: any) => {
      setSearchTerm(term);
      setPage(1);
    }, 300),
    []
  );

  const handleSortModelChange = (model: any) => {
    setSortModel(model);
    setPage(1);
  };

  const handlePageChange = (_: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handlePowerDialer = async () => {
    try {
      const response = await useAPI(
        "v1/user-dev-center/users",
        {
          page,
          lang: filters.lang,
          uuid: userAdmin.user.uuid,
          sorts: JSON.stringify(
            sortModel.reduce((acc, { field, sort }) => {
              acc[field] = sort;
              return acc;
            }, {})
          ),
          search: searchTerm,
          per_page: 50,
          stand_by: isStandBy,
          call_type: filters.callType,
          is_archived: isArchived,
        },
        { type: "post" }
      );

      if (response && response.data) {
        setSelectRows(response.data);
        setMassCallStarted(true);
      }
    } catch (error) {
      snackHandler("Error fetching power dialer data", "error");
    }
  };

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    setPage(1);
  };

  useEffect(() => {
    if (data && data.data) {
      setRows(data.data);
      setPagination({ total: data.total });
    }
  }, [data]);

  useEffect(() => {
    invalidateAllQueries();
  }, [
    page,
    filters,
    perPage,
    sortModel,
    isStandBy,
    searchTerm,
    isArchived,
    queryClient,
  ]);

  return (
    <BasePage title="Development Center Users">
      <Box className="flex gap-4 flex-wrap justify-between !items-stretch">
        <Box className="flex !items-stretch">
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "1rem",
            }}
            variant="outlined"
            onClick={handleMassCall}
            disabled={selectedRows.length === 0}
          >
            <img
              src={callIcon}
              style={{
                filter: theme.palette.filter,
                marginRight: 10,
              }}
              className="icon"
            />
            Call Selected Users
          </Button>

          <Button
            style={{ display: "flex", alignItems: "center" }}
            variant="outlined"
            onClick={handlePowerDialer}
          >
            <DialpadIcon
              fontSize="small"
              className="icon"
            />
            Power Dialer
          </Button>
        </Box>

        <SearchFilter
          onSearch={handleSearch}
          //   onToggle={handleToggle}
          applyFilters={applyFilters}
        //   onStandByToggle={handleStandByToggle}
        />
      </Box>

      <DialpadMassCall
        rows={selectedRows}
        open={massCallStarted}
        onClose={handleCallEnded}
      />

      <DataGrid
        autoHeight
        pagination
        disableColumnMenu
        checkboxSelection
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={getColumns(
          handleMenuClick,
          handleMenuClose,
          menuRow,
          anchorEl,
          navigate,
          handleDialpadCall,
          callUpdateCrm,
          handleCommentClick,
          handleStandBy,
          handleResolve,
          handleStatusChange,
          handleOpenUserUpdates
        )}
        loading={isLoading}
        getRowId={(row) => row.uuid}
        sortModel={sortModel}
        components={{
          Pagination: () => (
            <CustomPagination
              hideTotal
              page={page - 1}
              count={pagination.total || 0}
              rowsPerPage={perPage}
              onPageChange={handlePageChange}
            />
          ),
        }}
        selectionModel={selectionModel}
        onSortModelChange={handleSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        onSelectionModelChange={(newSelection) =>
          handleRowSelectionChange(newSelection)
        }
      />

      <CallBackDateDialog
        open={openDateDialog}
        onClose={handleDateDialogClose}
        onConfirm={handleDateConfirm}
      />

      <UserUpdatesDialog
        user={updatesUser}
        open={openUserUpdates}
        handleClose={handleCloseUserUpdates}
      />

      <UsersListNotes
        open={openComment}
        setOpen={setOpenComment}
        cacheKey={[]}
        userAdmin={userAdmin}
        selectedUser={commentUser}
      />

      <DialpadCallModal
        ref={dialpadCallRef}
        row={targetCaller}
      />

      <Dialog
        open={openNoReplyDialog}
        onClose={handleNoReplyDialogClose}
      >
        <DialogTitle>Choose Action</DialogTitle>

        <DialogContent>
          <Box className="text-center font-400">{selectedRow.noReplyCount}</Box>

          <Button onClick={() => handleNoReplyConfirm("increase")}>
            Increase
          </Button>

          <Button onClick={() => handleNoReplyConfirm("replied")}>
            Replied
          </Button>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleNoReplyDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </BasePage>
  );
};

export default CallCenterTable;
