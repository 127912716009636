import { forwardRef, useImperativeHandle, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";

export interface IRef {
  open: (photo: string, title: string) => void;
}

interface IProps {}

const ShowPhoto = forwardRef<IRef, IProps>((_, ref) => {
  const [photo, setPhoto] = useState<string>();
  const [title, setTitle] = useState<string>();

  useImperativeHandle(ref, () => ({
    open: (photo, title) => {
      setPhoto(photo);
      setTitle(title);
    },
  }));

  return (
    <EduModal
      fullWidth
      open={Boolean(photo)}
      onClose={() => setPhoto(undefined)}
      maxWidth="sm"
    >
      <EduModalTitle onClose={() => setPhoto(undefined)}>{title}</EduModalTitle>

      <EduModalBody>
        <img
          src={photo}
          alt={title}
          className="m-auto"
        />
      </EduModalBody>
    </EduModal>
  );
});

export default ShowPhoto;
