import { createRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { format, parseISO } from "date-fns";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import RecruitingFormDialog, {
  IForwardRef as IRecruitingFormDialog,
} from "./components/RecruitingFormDialog";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./components/Toolbar";
import BasePage from "@/components/Page/Base";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import deleteIcon from "@/assets/icons/delete.svg";
import invoiceIcon from "@/assets/icons/invoice.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import usePagination from "@/hooks/usePagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import useRecruitingFormApi from "@/api/useRecruitingFormApi";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import addIcon from "@/assets/icons/add.svg";
import useAuth from '@/hooks/useAuth';

const RECRUITING_BASE = import.meta.env.VITE_ROUTE_RECRUITING;

const RecruitingForms = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const [filters, setFilters] = useSearchParams({});
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ filters, setFilters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const { callGetForms, callDeleteForm } = useRecruitingFormApi();
  const { data, isLoading } = callGetForms(filterData);

  const recruitingFormRef = createRef<IRecruitingFormDialog>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { copyToClipboard } = useAuth();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 110,
      renderCell: ({ row }) => (row?.status === 1 ? "Enabled" : "Disabled"),
    },
    {
      field: "created_at",
      headerName: "Created at",
      minWidth: 200,
      renderCell: ({ row }) =>
        format(parseISO(row.created_at), "EEE, dd-M HH:mm"),
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVertIcon />}>
          <MenuList>
            <MenuItem
              title="Copy link"
              color="primary"
              onClick={() => {
                copyToClipboard(`${RECRUITING_BASE}${row.uuid}`);
              }}
            >
              <ListItemIcon>
                <img
                  src={invoiceIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Copy link</ListItemText>
            </MenuItem>

            <MenuItem
              title="Create a form from here"
              color="primary"
              onClick={() => {
                row.name = "";
                row.id = "";
                row.uuid = "";
                row.created_at = "";
                row.updated_at = "";

                recruitingFormRef.current?.open(row);
              }}
            >
              <ListItemIcon>
                <img
                  src={addIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Create a form from here</ListItemText>
            </MenuItem>

            <MenuItem
              title="Edit"
              color="primary"
              onClick={() => {
                recruitingFormRef.current?.open(row);
              }}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              title="Delete"
              color="primary"
              onClick={() => {
                setDeleteId(row.id);
                setOpenConfirmDialog(true);
              }}
            >
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  className="icon "
                />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  return (
    <BasePage title="Recruiting Form">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={data || []}
        loading={isLoading}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        componentsProps={{
          toolbar: {
            filters: filterData,
            setFilters,
            recruitingFormRef,
          },
        }}
        components={{
          Toolbar,
          Pagination: CustomPaginationClient,
        }}
        page={parseInt(page.toString()) - 1}
        onPageChange={(newPage) => {
          setPage(newPage + 1);
        }}
      />

      <RecruitingFormDialog ref={recruitingFormRef} />

      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        title={
          <>
            <img src={deleteIcon} /> DELETE FORM
          </>
        }
        onConfirm={() => {
          if (!deleteId) return;
          callDeleteForm.mutate({
            id: deleteId,
          });
          setOpenConfirmDialog(false);
        }}
      >
        Are you sure to remove it?
      </ConfirmationDialog>
    </BasePage>
  );
};

export default RecruitingForms;
