import { Box, Button } from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import useAPI from "@/api/useAPI";
import useExportCSV from "../useExportCSV";
import downloadIcon from "@/assets/icons/download.svg";
import dayjs from "dayjs";
import { useState } from "react";
import DownloadingSpinner from "./DownloadingSpinner";

const Toolbar = ({ toogleFormUserDialog, setFilters, filters }) => {

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Calls an api end point to download a csv version of the
   * team records
   */
  const handleDownloadTeamsToCSV = async () => {
    setIsLoading(true);

    const downloadResponse = await useAPI("/v1/team/download-csv");

    if (downloadResponse) {
      useExportCSV(downloadResponse, `teams-download-${dayjs(Date.now()).format("YYYY-MMM-DD")}.csv`);
      setIsLoading(false);
    }
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-2 !items-stretch"
    >
      <Button
        variant="outlined"
        onClick={() => toogleFormUserDialog(null)}
      >
        <img
          src={addIcon}
          className="icon"
        />
        Add
      </Button>

      <Button
        variant="outlined"
        onClick={() => handleDownloadTeamsToCSV()}
        disabled={isLoading}
      >
        <img
          src={downloadIcon}
          className="icon"
        />
         { isLoading ? 'Downloading...' : 'Download'}
      </Button>

      {/* show loading spinner */}
      { isLoading && <DownloadingSpinner />}

      <SearchToolbar
        setFilters={setFilters}
        filters={filters}
        client={true}
      />
    </Box>
  );
};

export default Toolbar;
