import React, { useState } from "react";
import { Popover, Box } from "@mui/material";

interface URLPopoverProps {
  url: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const URLPopover: React.FC<URLPopoverProps> = ({ url, anchorEl, onClose }) => {
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ width: "400px", height: "300px" }}>
        <iframe
          src={url}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            transformOrigin: "0 0",
          }}
          title="URL Preview"
        />
      </Box>
    </Popover>
  );
};

export default URLPopover;
