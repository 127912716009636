import { format } from "date-fns";
import { TableStyling } from "@/dummy";
import { fUpper, isObject } from "@/components/useHelpers";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Rating, Tooltip, useTheme } from "@mui/material";
import { COLORS_STARS } from "..";
import StarIcon from "@mui/icons-material/Star";
import MyTooltip from "@/components/MyTooltip";
import CircleIcon from "@mui/icons-material/Circle";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import { getTableStyling } from "@/providers/Colors";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const DialogStudents = ({ open, setOpen, dataDialog }) => {
  const theme = useTheme();

  // COLUMNS ==============================================================================
  const columns: GridColDef[] = [
    {
      field: "created_at",
      hide: true,
      hideable: false,
    },
    {
      field: "titolo",
      headerName: "Lesson",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row?.event?.titolo || "-",
      renderCell: ({ row }) => <MyTooltip>{row?.event?.titolo}</MyTooltip>,
    },
    {
      field: "data",
      headerName: "Date",
      width: 120,
      valueGetter: ({ row }) => (row?.event?.data ? row?.event?.data : "-"),
    },
    {
      field: "name_tutor",
      headerName: "Tutor",
      minWidth: 150,
      flex: 0.5,
      valueGetter: ({ row }) =>
        row?.event?.tutors?.first_name && row?.event?.tutors?.last_name
          ? `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`
          : "-",
      renderCell: ({ row }) => (
        <MyTooltip>
          {row?.event?.tutors?.first_name && row?.event?.tutors?.last_name
            ? `${row?.event?.tutors?.first_name} ${row?.event?.tutors?.last_name}`
            : "-"}
        </MyTooltip>
      ),
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 180,
      valueGetter: ({ row }) => "rating =r" + (row?.punto / 2 || 0),
      renderCell: ({ row }) => {
        const rat = Number(row?.punto / 2);
        return (
          <Box className="flex items-center gap-2">
            <Box>{rat.toFixed(2)}</Box>
            <Rating
              style={{ color: "#faaf00" }}
              defaultValue={rat}
              precision={0.1}
              readOnly
            />
          </Box>
        );
        return rat
          ? Array.from({ length: rat }, (_, i) => (
              <StarIcon
                key={i}
                style={{ color: COLORS_STARS.at(rat - 1) }}
              />
            ))
          : "-";
      },
    },
    {
      field: "first_feedback",
      headerName: "Comment",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => (
        <MyTooltip>{fUpper(row?.first_feedback || "")}</MyTooltip>
      ),
    },
    {
      field: "improvements",
      headerName: "Improvements",
      width: 150,
      valueGetter: ({ row }) => {
        let improv = JSON.parse(row?.improvements || "[]");
        if (typeof improv === "string") improv = JSON.parse(improv);
        const improvType = Array.isArray(improv)
          ? improv
          : isObject(improv)
          ? Object.keys(improv)
          : [];
        return (
          "improvements =i" +
          (improvType?.length && row?.last_feedback
            ? improvType?.length + 1
            : improvType?.length
            ? improvType?.length
            : row?.last_feedback
            ? 1
            : "-")
        );
      },
      renderCell: ({ row }) => {
        let improv = JSON.parse(row?.improvements || "[]");
        if (typeof improv === "string") improv = JSON.parse(improv);
        const improvType = Array.isArray(improv)
          ? improv
          : isObject(improv)
          ? Object.keys(improv)
          : [];

        return improvType.length ? (
          <Tooltip
            placement="bottom-start"
            arrow
            title={
              <Box>
                {improvType.map((e: string, i: number) => {
                  const v = Array.isArray(improv)
                    ? e
                    : isObject(improv)
                    ? improv
                    : "";

                  const isString = typeof v === "string";

                  const style =
                    i < improvType.length - 1
                      ? {
                          borderBottom: "1px solid",
                          paddingTop: i !== 0 ? "0.4rem" : "",
                        }
                      : i !== 0
                      ? { paddingTop: "0.4rem" }
                      : {};

                  return (
                    <Box key={isString ? v : e}>
                      <Box
                        className="text-base"
                        style={style}
                      >
                        <CircleIcon
                          style={{ fontSize: "0.5rem", marginRight: "0.2rem" }}
                        />{" "}
                        {isString ? fUpper(v) : fUpper(e)}
                        {!isString && (
                          <Box className="pl-4 w-full">
                            {v[e]?.map((r: string) => (
                              <Box
                                key={r}
                                sx={{ width: "100%", wordWrap: "break-word" }}
                              >
                                - {fUpper(r)}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>

                      {row?.last_feedback && (
                        <Box
                          className="text-base border-t"
                          style={style}
                        >
                          <CircleIcon
                            style={{
                              fontSize: "0.5rem",
                              marginRight: "0.2rem",
                            }}
                          />
                          Old version
                          <Box className="pl-4 w-full">
                            <Box sx={{ width: "100%", wordWrap: "break-word" }}>
                              - {fUpper(row?.last_feedback || "")}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            }
          >
            <Box
              className="truncate"
              style={{ width: "100%" }}
            >
              {improvType?.length && row?.last_feedback
                ? improvType?.length + 1
                : improvType?.length
                ? improvType?.length
                : row?.last_feedback
                ? 1
                : "-"}
            </Box>
          </Tooltip>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <EduModal
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={() => setOpen(false)}
    >
      <EduModalTitle>{dataDialog?.fullNome}</EduModalTitle>
      <EduModalBody className="p-5">
        <DataGrid
          disableColumnMenu
          disableVirtualization
          disableSelectionOnClick
          sx={getTableStyling(theme)}
          rows={dataDialog?.data}
          columns={columns}
          // density="comfortable"
          pageSize={20}
          autoHeight={true}
          initialState={{
            sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowsPerPageOptions={[20]}
          experimentalFeatures={{ newEditingApi: false }}
          columnVisibilityModel={{ created_at: false }}
          components={{
            Toolbar: () => (
              <Box className="flex">
                <SearchToolbar />
              </Box>
            ),
            Pagination: CustomPaginationClient,
          }}
        />
      </EduModalBody>
    </EduModal>
  );
};

export default DialogStudents;
