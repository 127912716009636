import { Button, TextField } from "@mui/material";
import deleteIcon from "@/assets/icons/delete.svg";
import { IQuestion, ITextQuestion } from "./QuestionInterface";

const TextQuestion = ({ value, onChange, onDelete }: ITextQuestion) => {
  // let defaultValue = {
  //   question: "",
  //   elements: [
  //     {
  //       type: type,
  //       label: "",
  //       value: "",
  //     },
  //   ],
  // };

  return (
    <div className="flex gap-4 flex-1">
      <div className="flex flex-col flex-1 gap-4">
        <TextField
          type={"text"}
          label="Question"
          placeholder="Write here the question"
          sx={{ flex: 1 }}
          value={value?.question}
          onChange={(e) => {
            let newValue: IQuestion = {
              question: value?.question || "",
              elements: value?.elements || [],
            };
            newValue.question = e.target.value;
            onChange(newValue);
          }}
        />
        <TextField
          type={"text"}
          label="Correct answer"
          placeholder="Write here the correct answer"
          sx={{ flex: 1 }}
          value={value?.elements[0].correctAnswer}
          onChange={(e) => {
            let newValue: IQuestion = {
              question: value?.question || "",
              elements: value?.elements || [],
            };
            newValue.elements[0].correctAnswer = e.target.value;
            onChange(newValue);
          }}
        />
      </div>
      <Button
        variant="outlined"
        onClick={onDelete}
        className="self-start"
      >
        <img
          className="icon no-margin"
          src={deleteIcon}
        />
      </Button>
    </div>
  );
};

export default TextQuestion;
