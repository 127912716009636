import { Box, Button } from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const Toolbar = ({ setOpenDialog, ...rest }) => {
  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <Button
        variant="outlined"
        onClick={() => setOpenDialog(true)}
      >
        <img
          src={addIcon}
          className="icon"
        />{" "}
        Add calendar
      </Button>

      <SearchToolbar
        {...rest}
        client={true}
      />
    </Box>
  );
};

export default Toolbar;
