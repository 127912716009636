import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { TableStyling } from "@/dummy";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import Filter from "./Filter";
import useAPI from "@/api/useAPI";
import useScreen from "@/hooks/useScreen";
import ToolbarSummary from "./ToolbarSummary";

const KEY_QUERY = ["summary"];

const HTTP_BASE = "/v1/summary";

const Summary = () => {
  const { isMobile, screen } = useScreen();

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [range, setRange] = useState<Array<Date>>([]);
  const [splitBy, setSplitBy] = useState<string>("week");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [valueFilter, setValueFilter] = useState<Array<string>>([]);
  const [optionsFilter, setOptionsFilter] = useState<Array<string>>([]);
  const [columnsHeaders, setColumnsHeaders] = useState<Array<string>>([]);

  const { data, isLoading } = useQuery([...KEY_QUERY, "week/s"], () => useAPI(HTTP_BASE, { splitBy: "week" }));

  const getAPI = async (date: any, splitBy2: string) => {
    if (!splitBy2) splitBy2 = splitBy;

    let KEY_QUERY_DATES = [...KEY_QUERY, splitBy2, ...valueFilter];

    let startDate: string = "";
    let endDate: string = "";

    if (Boolean(date?.startDate)) {
      startDate = format(date?.startDate, "yyyy-MM-dd HH:mm:ss");
      endDate = format(date?.endDate, "yyyy-MM-dd HH:mm:ss");
    } else if (Boolean(range?.length)) {
      startDate = format(range[0], "yyyy-MM-dd HH:mm:ss");
      endDate = format(range[1], "yyyy-MM-dd HH:mm:ss");
    }

    KEY_QUERY_DATES = [...KEY_QUERY_DATES, startDate, endDate];

    let res: any = queryClient.getQueryData(KEY_QUERY_DATES);

    if (!res) {
      setDisabled(true);
      res = await useAPI(HTTP_BASE, { startDate, endDate, splitBy: splitBy2, utmSource: valueFilter });
      queryClient.setQueryData(KEY_QUERY_DATES, res);
    }

    setRows(res?.rows || []);
    setColumnsHeaders(res?.columns || []);
    setOpenFilter(false);
    setDisabled(false);
  };

  const handleSetSplitBy = async (e: any) => {
    await getAPI(range, e.target.value);
    setSplitBy(e.target.value);
  };

  const handleGetApiByFilter = async (e: any) => getAPI(range, e.target.value);

  const handleChangeValue = (_: any, newValue: Array<string>) => setValueFilter(newValue);
  const handleOpenFilter = () => setOpenFilter(true);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "day/week",
        minWidth: 220,
        flex: 1,
        headerName: splitBy.charAt(0).toUpperCase() + splitBy.slice(1),
        valueGetter: ({ row }) => {
          const dates = row["day/week"].split("~");
          return dates[0].trim() === dates[1].trim() ? dates[0] : row["day/week"];
        },
      },
      {
        field: "total_leads",
        minWidth: 170,
        flex: 1,
        headerName: "Total leads",
        renderHeader: ({ field }) => `Total leads = ${columnsHeaders?.[field] || 0}`,
      },
      {
        field: "meeting_scheduled",
        minWidth: 220,
        flex: 1,
        headerName: "Meeting scheduled",
        renderHeader: ({ field }) => `Meeting scheduled = ${columnsHeaders?.[field] || 0}`,
      },
      {
        field: "meeting_done",
        minWidth: 180,
        flex: 1,
        headerName: "Meeting done",
        renderHeader: ({ field }) => `Meeting done = ${columnsHeaders?.[field] || 0}`,
      },
      {
        field: "sales",
        minWidth: 120,
        flex: 1,
        headerName: "Sales",
        renderHeader: ({ field }) => `Sales = ${columnsHeaders?.[field] || 0}`,
      },
      {
        field: "booking_rate",
        minWidth: 200,
        flex: 1,
        headerName: "Booking rate",
        renderHeader: ({ field }) => `Booking rate = ${columnsHeaders?.[field] || 0}`,
      },
      {
        field: "convertion_rate",
        minWidth: 210,
        flex: 1,
        headerName: "Convertion rate",
        renderHeader: ({ field }) => `Convertion rate = ${columnsHeaders?.[field] || 0}`,
      },
      {
        field: "showup_rate",
        minWidth: 190,
        flex: 1,
        headerName: "Showup rate",
        renderHeader: ({ field }) => `Showup rate = ${columnsHeaders?.[field] || 0}`,
      },
    ],
    [screen, columnsHeaders, splitBy]
  );

  useEffect(() => {
    if (isLoading) return;
    setRows(data?.rows || []);
    setColumnsHeaders(data?.columns || []);
    setValueFilter(data?.utmSource || []);
    setOptionsFilter(data?.utmSource || []);
  }, [isLoading]);

  return (
    <>
      <DataGrid
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={TableStyling}
        rows={rows}
        style={!isMobile ? { height: `calc(100vh - 14rem)` } : {}}
        columns={columns}
        loading={isLoading}
        // density="comfortable"
        pageSize={100}
        autoHeight={isMobile}
        components={{
          Toolbar: () => (
            <ToolbarSummary
              {...{ range, setRange, getAPI, disabled, splitBy }}
              onSetSplitBy={handleSetSplitBy}
              fileName="Summary Performance"
              onOpenFilter={handleOpenFilter}
            />
          ),
        }}
        componentsProps={{ toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 500 } } }}
        rowsPerPageOptions={[100]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <Filter
        open={openFilter}
        value={valueFilter}
        getAPI={handleGetApiByFilter}
        options={optionsFilter}
        onClose={() => setOpenFilter(false)}
        disabled={disabled}
        onChangeValue={handleChangeValue}
      />
    </>
  );
};

export default Summary;
