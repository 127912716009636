import { IExercise } from "../ExercisesV2/Create/interfacesAndDefaults";
import { PotExercise } from "./exercisesV3.types";

export const levelMappingDummy = [
  "Beginner",
  "Basic",
  "Intermediate",
  "Advanced",
];

export const NAME_SELECT = [
  "IELTS 0",
  "IELTS 0 - TEST 0",
  "IELTS 0 - TEST 0 - PART 0",
  "GRAMMAR TEST 0",
];

export const transformExercisePayload = (exercise: PotExercise | undefined) => {
  if (exercise) {
    const {
      id,
      lang,
      name,
      text,
      type,
      category,
      duration,
      img_file,
      groupings,
      audio_file,
      definitions,
      hasDeadLine,
      is_precourse,
      english_level,
      matching_option_gap_fill,
      multiple_choice_true_false,
    } = exercise;

    const newExercise: IExercise = {
      name,
      text,
      type,
      img_file,
      category,
      audio_file,
      definitions,
      english_level,
      id: id.toString(),
      lang: typeof lang == "string" ? JSON.parse(lang) : lang,
      duration: Number(duration),
      hasDeadLine: !!hasDeadLine,
      exercises: {
        groupings,
        matching_option_gap_fill,
        multiple_choice_true_false,
      },
      is_precourse: !!is_precourse,
    };

    return newExercise;
  }

  return undefined;
};
