import { Autocomplete, Box, Button, Checkbox, CircularProgress, Dialog, TextField } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HeaderDialog from "@/components/Dialog/HeaderDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default ({ open, onClose, value, options, onChangeValue, getAPI, disabled }) => (
  <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
    <HeaderDialog className="bg-edu-800" onClose={onClose}>
      Set filter
    </HeaderDialog>

    <Box className="bg-edu-800 px-6 pb-6">
      <Autocomplete
        multiple
        disableCloseOnSelect
        sx={{ flexGrow: 1 }}
        value={value}
        color="primary"
        options={options}
        onChange={onChangeValue}
        ChipProps={{ color: "primary", variant: "outlined" }}
        renderInput={(params) => (
          <TextField {...params} label="Filter" InputLabelProps={{ shrink: true }} sx={{ "& .MuiInputLabel-root": { color: "#fff" } }} />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} sx={{ color: "rgb(16, 185, 129)" }} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
      />

      <Button sx={{ mt: 2, width: "100%" }} onClick={getAPI} variant="outlined" disabled={disabled}>
        {disabled ? <CircularProgress size={26} /> : "Search"}
      </Button>
    </Box>
  </Dialog>
);
