import { useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { CustomToolbar } from "./Toolbar";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Popover, useTheme } from "@mui/material";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import MyTooltip from "@/components/MyTooltip";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

interface IEmailsData {
  emails: Array<string>;
  element: any;
}

const KpiNoShow = () => {
  const theme = useTheme();
  const { copyToClipboard, isMember, userAdmin } = useAuth();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const [ownedBy, setOwnedBy] = useState<Array<string>>([]);
  const [emailsData, setEmailsData] = useState<IEmailsData>();
  const [columnsHeaders, setColumnsHeaders] = useState({});

  const KEY_QUERY = ["interview-performance", "kpis-no-show", filterData];

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () =>
      useAPI("/v1/interview-performance/kpis-no-show", {
        filters: {
          ...filterData,
          ...(isMember
            ? {
              admin:
                userAdmin.user.first_name + " " + userAdmin.user.last_name,
            }
            : {}),
        },
      }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const renderHeader = (nameColumn: string) => ({
    renderHeader: ({ field }) => (
      <Box className="MuiDataGrid-columnHeaderTitle ">
        {Boolean(columnsHeaders?.[field]) && (
          <span className="number">{columnsHeaders?.[field]}</span>
        )}
        {nameColumn}
      </Box>
    ),
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) =>
        row?.name ? <MyTooltip>{row?.name}</MyTooltip> : "",
    },
    {
      field: "no_show_assigned",
      headerName: "No show assigned",
      minWidth: 120,
      flex: 1,
      ...(isMember ? {} : renderHeader("No show assigned")),
      renderCell: ({ row }) =>
        row?.no_show_assigned && (
          <button
            onClick={(e) =>
              setEmailsData({
                element: e.currentTarget,
                emails: row?.no_show_assigned_emails,
              })
            }
          >
            {row?.no_show_assigned}
          </button>
        ),
    },
    {
      field: "meeting_booked",
      headerName: "Meeting booked",
      minWidth: 120,
      flex: 1,
      ...(isMember ? {} : renderHeader("Meeting booked")),
      renderCell: ({ row }) =>
        row?.meeting_booked && (
          <button
            onClick={(e) =>
              setEmailsData({
                element: e.currentTarget,
                emails: row?.meeting_booked_emails,
              })
            }
          >
            {row?.meeting_booked}
          </button>
        ),
    },
  ];

  useEffect(() => {
    if (!dataAPI?.rows) return;
    setOwnedBy(dataAPI.owned_by);
    setColumnsHeaders(dataAPI.columns);
  }, [dataAPI, isLoading]);

  return (
    <BasePage title="Kpis no show">
      <DataGrid
        autoHeight
        hideFooter
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={dataAPI?.rows || []}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row?.name}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPaginationClient,
        }}
        componentsProps={{
          toolbar: {
            ownedBy,
            isMember,
            KEY_QUERY,
            setFilters,
            title: "Action update",
            filters: filterData,
            hideLang: true,
            fileName: "KPI No Show",
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <Popover
        open={Boolean(emailsData?.element)}
        onClose={() => setEmailsData(undefined)}
        anchorEl={emailsData?.element}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box className="p-3">
          {emailsData?.emails?.map((email: string, i: number) => (
            <button
              key={email + i}
              className="flex hover:opacity-60"
              onClick={() => copyToClipboard(email)}
            >
              <span className="w-12 text-left">{i + 1}</span>
              {email}
            </button>
          ))}
        </Box>
      </Popover>
    </BasePage>
  );
};

export default KpiNoShow;
