import useAuth from '@/hooks/useAuth';
import { Box, Button } from "@mui/material";
import downloadIcon from "@/assets/icons/download.svg";
import useUserVatApi from "@/api/useUserVatApi";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const Toolbar = (props) => {
  const { isMember } = useAuth();
  const { callDownloadUsersVat } = useUserVatApi();

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      {!isMember && (
        <Button
          variant="outlined"
          onClick={() => callDownloadUsersVat()}
        >
          <img
            src={downloadIcon}
            className="icon"
          />
          Download
        </Button>
      )}

      <SearchToolbar {...props} />
    </Box>
  );
};

export default Toolbar;
