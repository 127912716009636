import { useEffect, useState } from "react";
import { Info } from "@mui/icons-material";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { deepPurple } from "@mui/material/colors";
import { queryClient } from "@/main";
import { Box, Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import EditIcon from "@mui/icons-material/Edit";
import Dropdown from "@/components/_Common/Dropdown";
import refreshIcon from "@/assets/icons/refresh.svg";
import useExportCSV from "@/components/useExportCSV";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import ConfirmationDialog from "@/components/ConfirmationDialog";

interface IProps {
  KEY_QUERY: Array<any>;
  handleOnOpenInfoFlow: () => void;
  handleOnOpenShowStructureAutomation: () => void;
}

interface INDate {
  date: string;
  error: boolean;
}

const REGEX_VALID_DATE = /^\d{4}-\d{2}-\d{2}$/;

const UserSettingDateRangeAvailabilities = ({
  KEY_QUERY,
  handleOnOpenInfoFlow,
  handleOnOpenShowStructureAutomation,
}: IProps) => {
  const KEY_QUERY_RANGE = ["availabilities"];
  const HTTP_ROUTE_BASE = "/v1/availabilities";
  const HTTP_ROUTE_SET_DATES = HTTP_ROUTE_BASE + "/set_dates";

  const { isMember, snackHandler } = useAuth();

  const { data: dataAPI, isLoading } = useQuery(KEY_QUERY_RANGE, () =>
    useAPI(HTTP_ROUTE_BASE)
  );

  const { data: availabilitiesSettings, isLoading: isLoadingAvailaSettings } =
    useQuery(["settings-availabilities"], () =>
      useAPI("/v1/settings/flow/get/availabilities-data")
    );

  const [range, setRange] = useState<Array<Date> | null>([]);
  const [settings, setSettings] = useState<any>({ deadline: 6 });

  const start = format(range?.[0] || new Date(), "yyyy-MM-dd");
  const end = format(range?.[1] || new Date(), "yyyy-MM-dd");

  const [startDate, setStartDate] = useState<INDate>({
    date: start,
    error: false,
  });

  const [endDate, setEndDate] = useState<INDate>({ date: end, error: false });

  const [openConfirmSetDates, setOpenConfirmSetDates] =
    useState<boolean>(false);
  const [openConfirmSetDeadline, setOpenConfirmSetDeadline] =
    useState<boolean>(false);

  const handleSetDeadline = async () => {
    setOpenConfirmSetDeadline(false);

    const res = await useAPI(
      "/v1/settings/flow/set/availabilities-data",
      settings
    );

    if (!res?.status) {
      snackHandler("Failed save deadline", "error");
      return;
    }

    setSettings(res.data);
    snackHandler("Success save deadline");
  };

  const handleValidateDate = () => {
    setStartDate((p) => ({ ...p, error: false }));
    setEndDate((p) => ({ ...p, error: false }));

    const errors: Array<boolean> = [];

    if (!REGEX_VALID_DATE.test(startDate.date)) {
      setStartDate((p) => ({ ...p, error: true }));
      errors[0] = true;
    }

    if (!REGEX_VALID_DATE.test(endDate.date)) {
      setEndDate((p) => ({ ...p, error: true }));
      errors[0] = true;
    }

    if (errors?.[0] || errors?.[1]) return;

    if (startDate.date !== start || endDate.date !== end) {
      setRange([new Date(startDate.date), new Date(endDate.date)]);
      setOpenConfirmSetDates(true);
    }
  };

  const formatDate = (v: Array<Date> | null, i: number) =>
    v?.length
      ? format(new Date(v[i]), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd");

  const handleConfirmSetRange = async () => {
    setOpenConfirmSetDates(false);
    await useAPI(HTTP_ROUTE_SET_DATES, {
      dates: [formatDate(range, 0), formatDate(range, 1)],
    });
    snackHandler("Success edit!");
  };

  const handleDownloadAvailabilitiesFromFlow = async () => {
    const res = await useAPI(
      "/v1/availabilities/download-missing-availabilities"
    );

    if (!res?.status) {
      snackHandler(res?.message || "Error Download", "error");
      return;
    }

    useExportCSV(res.data, "Missing-availability-of-users.csv");
    snackHandler("Success download");
  };

  useEffect(() => {
    if (!dataAPI) return;
    setRange([
      new Date(dataAPI.range_date[0]),
      new Date(dataAPI.range_date[1]),
    ]);

    setStartDate({
      date: format(new Date(dataAPI.range_date[0]), "yyyy-MM-dd"),
      error: false,
    });

    setEndDate({
      date: format(new Date(dataAPI.range_date[1]), "yyyy-MM-dd"),
      error: false,
    });
  }, [dataAPI]);

  useEffect(() => {
    if (!availabilitiesSettings?.data?.deadline) return;
    setSettings(availabilitiesSettings?.data);
  }, [isLoadingAvailaSettings]);

  return (
    <>
      <Box
        id="filter_bar"
        className="flex gap-4 flex-wrap !items-stretch"
      >
        <Button
          variant="outlined"
          onClick={() => queryClient.invalidateQueries(KEY_QUERY)}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Dropdown
          text={
            dayjs(range?.[0]).format("YYYY-MM-DD") +
            "/" +
            dayjs(range?.[1]).format("YYYY-MM-DD")
          }
        >
          <Box className="flex flex-col gap-4 p-4">
            <TextField
              fullWidth
              label="Start"
              error={startDate.error}
              value={startDate.date ?? ""}
              onChange={(e) =>
                setStartDate((p: INDate) => ({
                  ...p,
                  date: e.target.value,
                }))
              }
              placeholder={startDate.date ?? ""}
            />

            <TextField
              fullWidth
              label="End"
              error={endDate.error}
              value={endDate.date ?? ""}
              onChange={(e) =>
                setEndDate((p: INDate) => ({ ...p, date: e.target.value }))
              }
              placeholder={endDate.date ?? ""}
            />

            <Button
              variant="outlined"
              onClick={handleValidateDate}
              className="w-full flex gap-2"
            >
              <span>EDIT</span>
              <EditIcon fontSize="small" />
            </Button>
          </Box>
        </Dropdown>

        <Dropdown text="Set deadline">
          <Box className="flex flex-col gap-4 p-4">
            <TextField
              type="number"
              label="Deadline"
              value={
                String(settings?.deadline) ||
                String(availabilitiesSettings?.deadline) ||
                ""
              }
              onChange={(e) =>
                setSettings((p: any) => ({
                  ...p,
                  deadline: Number(e.target.value),
                }))
              }
              placeholder="The deadline is calculate in to days"
            />

            <Button
              variant="outlined"
              onClick={() => setOpenConfirmSetDeadline(true)}
              className="w-full flex gap-2"
            >
              <span>EDIT</span>
              <EditIcon fontSize="small" />
            </Button>
          </Box>
        </Dropdown>

        {!isMember && (
          <Dropdown
            text={
              <>
                <Info
                  sx={{ color: deepPurple["300"] }}
                  className="mr-2"
                />
                Info flow
              </>
            }
          >
            <Box className="flex flex-col gap-4 p-4">
              <Button
                variant="outlined"
                onClick={handleOnOpenInfoFlow}
              >
                Show actions automations
              </Button>

              <Button
                variant="outlined"
                onClick={handleOnOpenShowStructureAutomation}
              >
                Show structure automations
              </Button>

              <Button
                variant="outlined"
                onClick={handleDownloadAvailabilitiesFromFlow}
              >
                Download rest users
              </Button>
            </Box>
          </Dropdown>
        )}

        <SearchToolbar />
      </Box>

      <ConfirmationDialog
        open={openConfirmSetDates}
        onClose={() => setOpenConfirmSetDates(false)}
        onConfirm={handleConfirmSetRange}
      >
        Do you want to edit range dates?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openConfirmSetDeadline}
        onClose={() => setOpenConfirmSetDeadline(false)}
        onConfirm={handleSetDeadline}
      >
        Do you want to edit deadline with <b>{settings?.deadline} days</b>{" "}
        before start month?
      </ConfirmationDialog>
    </>
  );
};

export default UserSettingDateRangeAvailabilities;
