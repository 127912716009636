import { Box } from "@mui/material";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import SelectMonthFilter from "./SelectMonthFilter";
import filterIcon from "@/assets/icons/filter.svg";
import Dropdown from "@/components/_Common/Dropdown";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

const ToolbarStudents = ({
  rangeDate,
  setRangeDate,
  filterMonth,
  setFilterMonth,
  FIRST_CAMP_FILTER,
}) => {
  return (
    <Box className="flex gap-4" id="filter_bar">
      <Dropdown
        text="Filter"
        icon={filterIcon}
        onClick={() => {}}
        content={
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            <DateRangePickerField
              title="Creation date"
              onChange={(date) =>
                date
                  ? setRangeDate([date?.startDate, date?.endDate])
                  : setRangeDate([])
              }
              value={rangeDate}
            />

            <SelectMonthFilter
              status={filterMonth[FIRST_CAMP_FILTER.down]}
              label={FIRST_CAMP_FILTER.normal}
              name={FIRST_CAMP_FILTER.down}
              setFilterMonth={setFilterMonth}
            />
          </Box>
        }
      />

      <SearchToolbar />
    </Box>
  );
};

export default ToolbarStudents;
