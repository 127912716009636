import { useMemo } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { ENGLISH_LEVEL } from "../Users/UserSingle";
import { ONBOARDING_VALUES_NEW_USERS } from "../UsersList/component/IndexUsersList";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

export default ({
  filters,
  keyQuery,
  setFilters,
  productsData,
  onDownloadSCV,
  customPaginationRef,
}) => {
  const { isMember } = useAuth();

  const handleChange = ({ target: { name, value } }) => {
    setFilters(
      (p: any) => {
        if (value) p.set(name, value);
        else p.delete(name);
        return p;
      },
      { replace: true }
    );
    customPaginationRef?.current?.resetPage();
  };

  const englishMenu = useMemo(
    () =>
      ENGLISH_LEVEL.map((el: string, i: number) => (
        <MenuItem
          key={i}
          value={el}
        >
          {el}
        </MenuItem>
      )),
    []
  );

  const productMenu = useMemo(
    () =>
      productsData
        ?.filter((p: { id: number }) => p.id)
        .map((e: { id: number; product_tag: string }) => (
          <MenuItem
            key={e.id}
            value={e.id}
          >
            {e.product_tag}
          </MenuItem>
        )),
    [JSON.stringify(productsData)]
  );

  const languageMenu = useMemo(
    () =>
      [{ name: "- Remove -", iso: "" }, ...LANGUAGES].map(
        (item: { iso: string; name: string }, i: number) => (
          <MenuItem
            key={i}
            value={item.iso}
          >
            {item.name}
          </MenuItem>
        )
      ),
    []
  );

  const ONBOARDING_VALUES_NEW_USERS_MEMO = useMemo(
    () =>
      ONBOARDING_VALUES_NEW_USERS?.map((o, i) => (
        <MenuItem
          key={o + i}
          value={o}
        >
          {o}
        </MenuItem>
      )),

    []
  );

  const filtersApplied = useMemo(
    () =>
      Object.keys(filters).filter(
        (key) => !["search", "sorts", "page"].includes(key)
      ).length,
    [filters]
  );

  const handleDelete = (key: string) => {
    setFilters(
      (p: any) => {
        p.delete(key);
        return p;
      },
      { replace: true }
    );
  };

  return (
    <Box
      id="filter_bar"
      className="flex !items-stretch gap-4"
    >
      <Button
        variant="outlined"
        onClick={() => {
          setFilters({});
          queryClient.invalidateQueries(keyQuery);
        }}
      >
        <img
          src={refreshIcon}
          className="icon"
        />
        Refresh
      </Button>

      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={Boolean(filtersApplied)}
        content={
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            <TextField
              select
              fullWidth
              name="english_level"
              label="English level"
              value={filters?.english_level || ""}
              onChange={handleChange}
            >
              <MenuItem value="">- Remove -</MenuItem>
              {englishMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="product"
              label="Product"
              value={filters?.product || ""}
              onChange={(e: any) => {
                handleChange(e);
                handleDelete("path");
              }}
            >
              <MenuItem value="">- Remove -</MenuItem>
              {productMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="lang"
              color="primary"
              label="Language"
              value={filters?.lang || ""}
              onChange={handleChange}
            >
              {languageMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="status_onboarding"
              color="primary"
              label="Onboarding"
              value={filters?.status_onboarding || ""}
              onChange={handleChange}
            >
              {ONBOARDING_VALUES_NEW_USERS_MEMO}
            </TextField>
          </Box>
        }
      />

      {!isMember && (
        <Button
          variant="outlined"
          onClick={() => onDownloadSCV()}
        >
          <img
            src={downloadIcon}
            className="icon"
          />
          Download
        </Button>
      )}

      <SearchToolbar
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};
