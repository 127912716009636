import { styled } from "@mui/system";

export const SideBarComponent = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  "& .MainMenu": {
    width: "55px",
    height: "100%",
    overflow: "auto",
    color: "red",
    background: theme.palette.leftSideBar.main,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "20px",
    transition: "width 0.5s",
  },
  "& .MainMenu .logo": {
    width: "100%",
    marginTop: "26px",
    marginBottom: "22px",
    display: "flex",
    justifyContent: "center",
  },
  "& .MainMenu .MenuItem": {
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    background: "#FFF",
    marginBottom: "10px",
    width: "40px",
    height: "40px",
    position: "relative",
    opacity: 0.5,
  },
  "& .MainMenu .MenuItem.active": {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "center",
    background: "#FFF",
    boxShadow: `0px 4px 5px 0px ${theme.palette.primary.main}`,
    marginBottom: "10px",
    width: "40px",
    height: "40px",
    opacity: 1,
  },
  "& .MainMenu .UserSetting": {
    marginTop: "auto",
    "& img": {
      filter:
        theme.palette.mode === "dark" ? "brightness(0) invert(1)" : "none",
    },
  },
  "& .SubMenu": {
    width: "195px",
    height: "100%",
    overflow: "auto",
    background: theme.palette.leftSideBarSub.main,
    transition: "0.3s ease-in",
  },
  "& .SubMenu.closed": {
    width: "44px",
  },
}));

export const UserSettingComponent = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginBottom: "15px",
  marginTop: "15px",
}));

export const UserSettingMenuComponent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  border: `1px solid ${theme.palette.primary.main}`,
  padding: 10,
  background: theme.palette.background.paper,
  "& .title": {
    color: theme.palette.primary.main,
    marginBottom: 5,
    fontWeight: 600,
  },
  "& ul li": {
    display: "flex",
    marginTop: 5,
    "& .checkbox": {
      width: 40,
      marginRight: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        filter: theme.palette.filter,
        width: 15,
        height: 15,
      },
    },
    "& .name": {
      width: 200,
    },
    "& .inuse": {
      color: theme.palette.primary.main,
      background: theme.palette.leftSideBarSub.main,
      padding: 4,
      borderRadius: "8px",
      fontSize: 8,
    },
  },
}));

export const UserProfileComponent = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export const SubMenuComponent = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "6px",
  overflowX: "hidden",
  "& .title": {
    color: theme.palette.primary.main,
    fontSize: "1.25rem",
    fontWeight: 600,
    marginTop: "20px",
    marginBottom: "15px",
    textTransform: "uppercase",
  },
  "& .menuContainer": {
    marginTop: "29px",
  },
  "& .subMenuItem": {
    width: "100%",
    marginTop: "5px",
    textAlign: "left",
    display: "flex",
    padding: "5px",
    borderRadius: "8px",
    alignItems: "center",
    textTransform: "uppercase",
  },
  "& .subMenuItem.active": {
    color: theme.palette.primary.main,
    background: "white",
  },
  "& .subMenuItem div": {
    marginRight: "15px",
  },
  "& .subMenuItem img": {
    width: "19px",
    filter: theme.palette.filter,
  },
  "& .subMenuItem:hover": {
    background: theme.palette.background.paper,
  },
  "& .subMenuItem span": {
    width: "150px",
  },
}));

export const UserProfileMenuComponent = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "8px",
  padding: 10,
  background: theme.palette.background.paper,
  marginLeft: "20px",
  filter: `drop-shadow(4px 4px 9px ${theme.palette.primary.main})`,
  marginBottom: 10,
  "& ul li": {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    cursor: "pointer",
  },
}));

export const UserSettingLeftSide = styled("div")(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.main}`,
  padding: 5,
  "& .menu": {
    "& ul li ": {
      cursor: "pointer",
      padding: 5,
      display: "flex",
      alignItems: "center",
      "& img": {
        marginRight: 10,
        filter:
          theme.palette.mode === "light" ? "none" : "brightness(0) invert(1)",
      },
    },
    "& ul li + li": {
      marginTop: 5,
    },
    "& ul li.active": {
      background: theme.palette.primary.main,
      color: "white",
      "& img": {
        filter: "brightness(0) invert(1)",
      },
    },
  },
}));

export const UserSettingRighttSide = styled("div")(({ theme }) => ({
  padding: 5,
  width: "100%",
}));
