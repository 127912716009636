import { useState, forwardRef, ChangeEvent, useImperativeHandle } from "react";
import { clone } from "../useHelpers";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import editIcon from "@/assets/icons/edit.svg";
import saveIcon from "@/assets/icons/save.svg";
import useAPIOld from "@/api/useAPI-old";

export interface IRef {
  open: (row: any) => void;
}

interface IProps {
  onInvalidateQuery?: () => void;
}

export default forwardRef<IRef, IProps>(({ onInvalidateQuery }, ref) => {
  const { snackHandler } = useAuth();

  const [row, setRow] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: (row) => {
      setRow(clone(row));
    },
  }));

  const handleChange = ({ target: { name, value } }) => {
    setRow((p: any) => ({ ...(p ?? {}), [name]: value }));
  };

  const handleClose = () => {
    setRow(undefined);
  };

  const handleSubmitEdit = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const res = await useAPIOld("/v1/users/edit/invoices", {
      id: row?.id,
      data: row?.data,
      type: row?.type,
      amount: row?.amount,
    });

    if (!res?.status) {
      snackHandler("Error edit invoice", "error");
      setIsLoading(false);
      return;
    }

    setRow(undefined);
    snackHandler("Success edit invoice");
    onInvalidateQuery?.();
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(row)}
      onClose={handleClose}
      maxWidth="xs"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={editIcon}
          className="icon"
        />
        Edit
      </EduModalTitle>

      <EduModalBody
        onSubmit={handleSubmitEdit}
        component="form"
        className="flex flex-col gap-5 p-4 pt-6"
      >
        <TextField
          required
          fullWidth
          name="data"
          type="date"
          label="Date"
          value={
            dayjs(row?.data).format("YYYY-MM-DD") ??
            dayjs().format("YYYY-MM-DD")
          }
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          select
          required
          fullWidth
          id="type-select"
          name="type"
          label="Type"
          color="primary"
          value={row?.type || ""}
          onChange={handleChange}
        >
          {["Stripe", "Alma", "Klarna", "Pagolight", "Bonifico"].map(
            (t: string) => (
              <MenuItem
                key={t}
                value={t}
              >
                {t}
              </MenuItem>
            )
          )}
        </TextField>

        <TextField
          required
          fullWidth
          id="amount"
          name="amount"
          type="number"
          label="Amount"
          value={row?.amount ?? ""}
          onChange={handleChange}
        />

        <Box className="flex flex-row-reverse">
          <LoadingButton
            type="submit"
            color="primary"
            variant="outlined"
            loading={isLoading}
            startIcon={<img src={saveIcon} />}
          >
            Save
          </LoadingButton>
        </Box>
      </EduModalBody>
    </EduModal>
  );
});
