import { API_ENDPOINTS } from "@/api/client/endpoints";
import { mapPaginatorData } from "@/utils/data-mappers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  EventUsers,
  EventTutors,
  EventQueryParam,
  EventsPaginator,
  MaterialExerciseResponse,
  EventStatisticsPaginator,
  EventStatisticsQueryParam,
} from "@/interfaces";
import client from "@/api/client";

export const useEvents = (options: Partial<EventQueryParam>) => {
  const { data, error, isLoading } = useQuery<EventsPaginator, Error>(
    [API_ENDPOINTS.V2.EVENTS, options],
    ({ queryKey, pageParam, signal }) =>
      client.events.all(Object.assign({}, queryKey[1], pageParam), { signal }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  return {
    error,
    events: data ?? { data: [] },
    loading: isLoading,
    keyQuery: [API_ENDPOINTS.V2.EVENTS, options],
  };
};

export const generateLink = async (
  eventId: number,
  role: "tutor" | "student"
) => {
  try {
    const response = await client.events.generateMeetingLink(eventId, role);
    return response;
  } catch (error) {
    console.error("Failed to generate meeting link:", error);
    throw error;
  }
};

export const useEventsStatistics = (
  options: Partial<EventStatisticsQueryParam>
) => {
  const { data, error, isLoading } = useQuery<EventStatisticsPaginator, Error>(
    [API_ENDPOINTS.V2.EVENT_STATISTICS, options],
    ({ queryKey, pageParam }) =>
      client.events.info(Object.assign({}, queryKey[1], pageParam)),
    { keepPreviousData: true }
  );

  return {
    events: data?.data ?? [],
    paginatorInfo: mapPaginatorData(data),
    error,
    loading: isLoading,
  };
};

export function useEventTutors() {
  const { data, isLoading, error } = useQuery<EventTutors[], Error>(
    [API_ENDPOINTS.V2.EVENT_TUTORS],
    client.events.tutors
  );

  return {
    tutors: data ?? [],
    isLoading,
    error,
  };
}

export function useEventUsers() {
  const { data, isLoading, error } = useQuery<EventUsers[], Error>(
    [API_ENDPOINTS.V2.EVENT_USERS],
    client.events.users
  );

  return {
    users: data ?? [],
    isLoading,
    error,
  };
}

export const useDeleteEventMutation = (
  reset: () => void,
  query: EventQueryParam
) => {
  const queryClient = useQueryClient();

  return useMutation(({ id }: { id: number }) => client.events.remove(id), {
    onError: (error) => {
      console.error(error);
    },
    // Always refetch after error or success:
    onSettled: () => {
      reset();
      queryClient.invalidateQueries([API_ENDPOINTS.V2.EVENTS, query]);
    },
  });
};

export const useTechnicalProblemEventMutation = (
  reset: () => void,
  query: EventQueryParam
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, value }: { id: number; value: number }) =>
      client.events.technicalProblem(id, value),
    {
      onError: (error) => {
        console.error(error);
      },
      // Always refetch after error or success:
      onSettled: () => {
        reset();
        queryClient.invalidateQueries([API_ENDPOINTS.V2.EVENTS, query]);
      },
    }
  );
};

export function useListMaterialExercises() {
  const { data, isLoading, error } = useQuery<MaterialExerciseResponse, Error>(
    [API_ENDPOINTS.V1.LIST_MATERIAL_EXERCISES],
    client.events.materialExercises
  );

  return {
    materialExercises: data,
    isLoading,
    error,
  };
}
