import { Box, Button } from "@mui/material";
import DiscountIcon from "@mui/icons-material/Discount";
import SearchToolbar from "../Toolbars/SearchToolbar";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const ToolbarProdotti = ({ setShowDialog, setShowDialogDiscount, ...rest }) => {
  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <Button
        variant="outlined"
        onClick={() => setShowDialog((p: boolean) => !p)}
      >
        <AddRoundedIcon className="icon" /> Add new product
      </Button>

      <Button
        variant="outlined"
        onClick={() => setShowDialogDiscount((prev) => !prev)}
      >
        <DiscountIcon className="icon" /> Discount
      </Button>

      <SearchToolbar
        {...rest}
        client={true}
      />
    </Box>
  );
};

export default ToolbarProdotti;
