import { Box, Button, TextField } from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import searchIcon from "@/assets/icons/search.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import FilterOrders from "./FilterOrders";
import { useRef } from "react";
import CheckoutOptions from "./CheckoutOptions";

const Toolbar = ({ theme, filterData, setFilters, handleFilterChange }) => {
  const checkoutOptionsRef = useRef<{ open: () => void; close: () => void }>(
    null
  );

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 flex-wrap !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => checkoutOptionsRef.current?.open()}
        >
          Manage Checkout Options
        </Button>
        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={
            Object.keys(filterData).filter(
              (key) => !["search", "sorts", "page"].includes(key)
            ).length
              ? true
              : false
          }
          content={
            <FilterOrders
              filterData={filterData}
              setFilters={setFilters}
              handleFilterChange={handleFilterChange}
            />
          }
        />
      </FiltersBar>

      <TextField
        sx={{ marginLeft: "auto", maxWidth: "200px" }}
        name="search"
        size="small"
        value={filterData?.search || ""}
        variant="outlined"
        onChange={handleFilterChange}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              style={{ marginRight: 10, filter: theme.palette.filter }}
            />
          ),
        }}
        placeholder="Search..."
        //disable suggestions
        autoComplete="off"
      />
      <CheckoutOptions ref={checkoutOptionsRef} />
    </Box>
  );
};

export default Toolbar;
