import client from '@/api/client';
import { Bounce, toast } from 'react-toastify';

export const actions = [
    {
        type: "NEW_USER_NO_ACTIONS",
        action: async (event: any) => {
            
            try {
                const user = await client.crm.getUser(event.user_token);

                if(user && user?.status === "Nuovo"){
                    return {onclick: () => window.location.href = `/crm?token=${event.user_token}`}
                } else {
                    return false
                }
            } catch (error) {
                return false
            }
        },
    },
    {
        type : "RELOAD_PAGE",
        action: async (event: any) => {
            toast(event?.message || "", {
                position: "bottom-right",
                autoClose: false,
                hideProgressBar: false,
                closeButton: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return false
        }
    }
]