import { Dispatch, SetStateAction } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MenuItem, TextField, Button, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";

interface ICDProps {
  open: boolean;
  date: Date | null;
  hide: boolean | null;
  setHide: Dispatch<SetStateAction<any>>;
  setDate: Dispatch<SetStateAction<any>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  startMonth: string | null;
  paymentDate: Date | null;
  startsMonth: Array<string> | null;
  selectedMonth: Array<string> | null;
  setStartMonth: Dispatch<SetStateAction<any>>;
  setPaymentDate: Dispatch<SetStateAction<any>>;
}

const Create = ({
  date,
  hide,
  open,
  setHide,
  setDate,
  setOpen,
  onConfirm,
  startMonth,
  startsMonth,
  paymentDate,
  selectedMonth,
  setStartMonth,
  setPaymentDate,
}: ICDProps) => {
  const handleClose = () => {
    setOpen(false);
    setStartMonth(null);
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={addIcon}
          className="icon"
        />
        Create a Kick off date
      </EduModalTitle>

      <EduModalBody className="bg-edu-800 p-5 pt-6 flex flex-col gap-5">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["day"]}
            value={date || new Date()}
            openTo="day"
            onChange={(e) => setDate(e)}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Kick off date"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["day"]}
            value={paymentDate || new Date()}
            openTo="day"
            onChange={(e) => setPaymentDate(e)}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Payment date"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </LocalizationProvider>

        <TextField
          select
          required
          id="month"
          name="start_month"
          label="Start Month"
          value={startMonth || ""}
          onChange={(e) => setStartMonth(e.target.value)}
        >
          {startsMonth?.map((month, index: any) =>
            selectedMonth?.indexOf(month) != index ? (
              <MenuItem
                key={index}
                value={month}
              >
                {useConvertStartMonthEn(month)}
              </MenuItem>
            ) : null
          )}
        </TextField>

        <TextField
          select
          required
          id="status"
          name="hide"
          label="Select Visibility"
          value={!hide ? "ON" : "OFF"}
          onChange={(e) =>
            setHide(() => (e.target.value == "OFF" ? true : false))
          }
        >
          {["ON", "OFF"].map((v: string, i: number) => (
            <MenuItem
              key={i}
              value={v}
            >
              {v}
            </MenuItem>
          ))}
        </TextField>

        <Box className="flex justify-end">
          <Button
            variant="outlined"
            onClick={onConfirm}
            disabled={Boolean(!startMonth)}
          >
            <img
              src={saveIcon}
              className="icon"
            />
            Save
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default Create;
