import { useEffect, useMemo } from "react";
import useProductApi from "@/api/useProductApi";
import useAuth from '@/hooks/useAuth';
import { defaultFormMeetingDone } from "../components/crm-upselling.types";

export const useMeetingLogic = (data, eventId, formMeetingState, open) => {
  const { callGetProducts } = useProductApi();
  const { data: products } = callGetProducts();
  const { userAdmin } = useAuth();
  const { formMeetingDone, setFormMeetingDone } = formMeetingState;

  const meetingsSorted = useMemo(
    () =>
      data.user?.meetings?.meet?.sort((a, b) =>
        a.start.localeCompare(b.start)
      ) || [],
    [data.user?.meetings?.meet]
  );

  const selectedEvent = useMemo(
    () => meetingsSorted.find((e) => e.id === eventId) || {},
    [meetingsSorted, eventId]
  );

  const handleSetFormMeetingDone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFormMeetingDone((prev) => ({
      ...prev,
      [name]: value,
      interviewer:
        data.buttons?.meetingDone?.form?.interviewer ||
        `${userAdmin?.user?.first_name} ${userAdmin?.user?.last_name}`,
    }));
  };

  useEffect(() => {
    const meetings = data.user?.meetings?.meet;
    if (Array.isArray(meetings) && meetings.length) {
      const firstUpcomingMeeting = meetings.find(
        (meet) => new Date() < new Date(meet.start)
      );
      const meetingToSet =
        firstUpcomingMeeting || meetings[meetings.length - 1];

      setFormMeetingDone((prev) => ({ ...prev, id: meetingToSet.id }));
    }
  }, [data.user?.meetings?.meet]);

  useEffect(() => {
    const selectedMeet = meetingsSorted.find((e) => e.id === eventId);
    if (selectedMeet) {
      setFormMeetingDone((prev) => ({ ...prev, ...selectedMeet }));
    }
  }, [eventId, meetingsSorted]);

  useEffect(() => {
    return () => setFormMeetingDone(defaultFormMeetingDone);
  }, [open]);

  const keysFormMeetingDone = useMemo(
    () => Object.keys(formMeetingDone || {}),
    [formMeetingDone]
  );

  return {
    products,
    meetingsSorted,
    selectedEvent,
    handleSetFormMeetingDone,
    keysFormMeetingDone,
  };
};
