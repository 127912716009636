import { Box } from "@mui/material";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useEffect, useState } from "react";
import useAPI from "@/api/useAPI";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";

const HTTP_BASE = "/v1/general/tutors/count-access-events";

const CountEvents = ({
  email,
  token,
  getEventsAPI,
}: {
  email: string;
  token: string;
  getEventsAPI: any;
}) => {
  const KEY_QUERY = ["count-access-events", email];

  const { data, isLoading } = useQuery(KEY_QUERY, () =>
    useAPI(HTTP_BASE, { email, token })
  );

  const [value, setValue] = useState<string>();
  const [range, setRange] = useState<Array<Date> | null>(null);
  const [disabled, setDisabled] = useState<boolean>();

  const getAPI = async (date: any) => {
    setDisabled(true);
    if (!date?.startDate) {
      setValue(queryClient.getQueryData(KEY_QUERY));
      setDisabled(false);
      return;
    }

    const startDate = format(date?.startDate, "yyyy-MM-dd HH:mm:ss");
    const endDate = format(date?.endDate, "yyyy-MM-dd HH:mm:ss");

    const KEY_QUERY_DATES = [...KEY_QUERY, startDate, endDate];

    let res: any = queryClient.getQueryData(KEY_QUERY_DATES);

    if (!res) {
      res = await useAPI(HTTP_BASE, { email, token, startDate, endDate });
      queryClient.setQueryData(KEY_QUERY_DATES, res);
    }

    setValue(res);
    setDisabled(false);
  };

  useEffect(() => {
    if (!isLoading) setValue(data);
  }, [isLoading]);

  return (
    <Box className="flex gap-4 items-center justify-between px-8">
      <CustomDateRangePicker
        icon={disabled ? <RefreshIcon /> : undefined}
        value={range}
        disabled={disabled}
        onChange={(date) => {
          if (date) setRange([date.startDate, date.endDate]);
          else setRange(null);

          getAPI(date);
          getEventsAPI({ ...date, email, token });
        }}
      />

      {value ? <Box className="text-2xl px-4 text-primary">{value}</Box> : null}
    </Box>
  );
};

export default CountEvents;
