export const ROLES = [
  "IELTS",
  "Speaking",
  "One to one",
  "Test account",
  "Grammar & Basic",
  "Writing & Mock test",
];

export const STATUS = [
  "New",
  "Registered",
  "Onboarding",
  "Teacher training",
  "Final session",
  "Active",
  "Inactive",
];

export const PAY_RATE = [
  "Fixed SA",
  "Hourly SA",
  "Old",
  "Fixed IT",
  "Hourly IT",
  "Fixed ES",
  "Hourly ES",
];

export interface INKeyTutor {
  key: string;
  label: string;
  options?: Array<string>;
  multiple?: boolean;
  required?: boolean;
  placeholder: string;
  document_deadline?:string;
}

export const KeysTutor: INKeyTutor[] = [
  {
    key: "first_name",
    label: "First name",
    required: true,
    placeholder: "James",
  },
  {
    key: "last_name",
    label: "Family name",
    required: true,
    placeholder: "Webb",
  },
  {
    key: "email",
    label: "Email address",
    required: true,
    placeholder: "james.webb@email.com",
  },
  { key: "password", label: "Password", placeholder: "*********" },
  {
    key: "birth_date",
    label: "Date of birth",
    required: true,
    placeholder: "YYYY-MM-DD",
  },
  {
    key: "luogo_nascita",
    label: "Birth place",
    placeholder: "London",
  },
  {
    key: "phone",
    label: "Whatsapp phone number",
    required: true,
    placeholder: "+39",
  },
  {
    key: "cf_user",
    label: "Tax code / Social security number",
    placeholder: "JMSWBB92E13DKFP",
  },
  {
    key: "paese_residenza",
    label: "Country",
    placeholder: "Italy",
  },
  {
    key: "municipality",
    label: "Town / Municipality",
    placeholder: "London",
  },
  {
    key: "address",
    label: "Address",
    placeholder: "Backer Street 10",
  },
  {
    key: "zipcode",
    label: "Zip code",
    placeholder: "E1W",
  },
  {
    key: "status",
    label: "Status",
    options: STATUS,
    placeholder: "New",
  },
  {
    key: "pay_rate",
    label: "Pay rate",
    options: PAY_RATE,
    placeholder: "Fixed SA",
  },
  {
    key: "role",
    label: "Role",
    options: ROLES,
    multiple: true,
    required: true,
    placeholder: "Essay, Speaking, Italiano",
  },
  {
    key: "profile_pic",
    label: "Profile pic",
    placeholder: "",
  },
  
];

export const KeysTutorCreate: INKeyTutor[] = [
  {
    key: "first_name",
    label: "First name",
    placeholder: "James",
    required: true,
  },
  {
    key: "last_name",
    label: "Family name",
    placeholder: "Webb",
    required: true,
  },
  {
    key: "role",
    label: "Role",
    placeholder: "",
    required: false,
  },
  {
    key: "refered_by",
    label: "Refered by",
    placeholder: "",
    required: false,
  },
  {
    key: "document_deadline",
    label: "Document Deadline",
    placeholder: "YYYY-MM-DD",
    required: true,
  },
];

export interface INTutor {
  role?: Array<string> | string;
  email?: string;
  token?: string;
  phone?: string;
  cf_user?: string;
  zipcode?: string;
  address?: string;
  password?: string;
  last_name?: string;
  first_name?: string;
  birth_date?: string;
  refered_by?: string;
  profile_pic?: any;
  municipality?: string;
  luogo_nascita?: string;
  contract_docs?: string;
  paese_residenza?: string;
  preview_profile_pic?: string;
}

export interface INEdit {
  open?: boolean;
  errors?: INTutor | undefined;
  newData?: INTutor | undefined;
  disabled?: boolean;
  isLoading?: boolean;
  openConfirm?: boolean;
  defaultData?: INTutor | undefined;
}

export interface INEditPayRate {
  open?: boolean;
  errors?: INTutor | undefined;
  newData?: INTutor | undefined;
  disabled?: boolean;
  isLoading?: boolean;
  openConfirm?: boolean;
  defaultData?: INTutor | undefined;
}

export interface INDestroy {
  token?: string;
  openConfirm?: boolean;
}

export interface INDataFeedback {
  user?: { first_name?: string; last_name?: string };
  event?: { titolo?: string; data?: string | Date; tutor_token?: string };
  rating?: string | number;
  created_at?: string | Date;
  improvements?: string;
  first_feedback?: string;
}

export interface INFeedback {
  name: string;
  open: boolean;
  data: Array<INDataFeedback>;
}

export interface INAccessEvents {
  data?: any;
  name?: string;
  open?: boolean;
  email?: string;
  token?: string;
}

export interface INDataAvailabilities {
  name: string;
  token: string;
  keyQuery: Array<any>;
  availabilities: Array<any>;
}
