import {
  Done,
  Handshake,
  TimerRounded,
  ContactSupport,
  HardwareRounded,
  // EmojiEventsRounded,
} from "@mui/icons-material";

const index = {
  // chose_payment: {
  //   icon: (
  //     <EmojiEventsRounded
  //       color="primary"
  //       fontSize="small"
  //     />
  //   ),
  //   label: "Chose payment",
  //   value: "chose_payment",
  // },
  reminder_4: {
    icon: (
      <Handshake
        color="primary"
        fontSize="small"
      />
    ),
    label: "Handshake",
    value: "reminder_4",
    valueDB: "reminder4",
  },
  // reminder_2: {
  //   icon: (
  //     <TimerRounded
  //       color="primary"
  //       fontSize="small"
  //     />
  //   ),
  //   label: "Reminder 2",
  //   value: "reminder_2",
  // },
  reminder_1: {
    icon: (
      <TimerRounded
        color="primary"
        fontSize="small"
      />
    ),
    label: "Timer",
    value: "reminder_1",
    valueDB: "reminder1",
  },
  "reminder_3.1": {
    icon: (
      <ContactSupport
        color="primary"
        fontSize="small"
      />
    ),
    label: "Question",
    value: "reminder_3.1",
    valueDB: "reminder3.1",
  },
  reminder_3: {
    icon: (
      <HardwareRounded
        color="primary"
        fontSize="small"
      />
    ),
    label: "Hammer",
    value: "reminder_3",
    valueDB: "reminder3",
  },
  reminder_after_rescheduled: {
    icon: (
      <Done
        color="primary"
        fontSize="small"
      />
    ),
    label: "Reminder after rescheduled",
    value: "reminder_after_rescheduled",
    valueDB: "reminder_after_rescheduled",
  },
};

export default index;

export type IButtonCrmWhatsapp = keyof typeof index;
