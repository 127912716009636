import { useMemo, useState, createRef } from "react";
import { euro } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import InvoiceUserDialog, {
  IForwardRef as InvoiceUserDialogRef,
} from "@/pages/Users/components/InvoiceUserDialog";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import copyIcon from "@/assets/icons/copy.svg";
import mailIcon from "@/assets/icons/mail.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import clockIcon from "@/assets/icons/clock.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import invoiceIcon from "@/assets/icons/invoice.svg";
import commentsIcon from "@/assets/icons/comments.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import usePagination from "@/hooks/usePagination";
import UsersListNotes from "../UsersList/component/UsersListNotes";
import PaymentsTooltip from "./PaymentsTooltip";
import CustomPagination from "@/components/_Common/CustomPagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import FlagRender from "@/components/FlagRender";

export const HTTP_BASE = "/v1/still-to-pay";
const HTTP_SEND_EMAIL = HTTP_BASE + "/send-email";

interface IConfirmDialogData {
  open: boolean;
  title: JSX.Element | string | number;
  content: JSX.Element | string | number;
  onAgree: (...args: any) => void;
  onDisagree: (...agrs: any) => void;
}

const defaultConfirmDialogData: IConfirmDialogData = {
  open: false,
  title: "",
  content: "",
  onAgree: () => { },
  onDisagree: () => { },
};

const StillToPay = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { userAdmin, snackHandler, isAdmission, copyToClipboard } = useAuth();
  const [filtersHttp, setFiltersHttp] = useSearchParams({});

  const InvoiceUserDialogRef = createRef<InvoiceUserDialogRef>();

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<any>();
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [confirmDialogData, setConfirmDialogData] =
    useState<IConfirmDialogData>(defaultConfirmDialogData);
  const [anchorElPaidAmount, setAnchorElPaidAmount] =
    useState<HTMLElement | null>(null);

  const { page, setPage } = usePagination({
    filters: filtersHttp,
    setFilters: setFiltersHttp,
  });

  const { sortModel, onSortModelChange } = useDGS({
    filters: filtersHttp,
    setFilters: setFiltersHttp,
  });

  const getProductID = (paymentsReminder) => {
    if (!paymentsReminder) return null;

    for (let i = paymentsReminder.length - 1; i >= 0; i--) {
      if (!paymentsReminder[i].product_name.includes("individual")) {
        return paymentsReminder[i].product_id;
      }
    }

    return paymentsReminder.length > 0
      ? paymentsReminder[paymentsReminder.length - 1].product_id
      : null;
  };

  const filters = Object.fromEntries(filtersHttp.entries());
  const sorts = filters?.sorts;
  delete filters?.page;
  delete filters?.sorts;

  const KEY_QUERY = [
    "still to pay",
    page,
    sorts,
    perPage,
    filters,
    isAdmission,
  ];

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () =>
      useAPI(HTTP_BASE + (isAdmission ? "/admission" : ""), {
        page,
        sorts,
        filters,
        perPage,
        isAdmission,
      }),
    {
      enabled: perPage > 0,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );

  const handleSendEmail = (row: any) => {
    setConfirmDialogData({
      open: true,
      title: (
        <>
          <img
            className="icon"
            src={mailIcon}
          />
          Send email
        </>
      ),
      content: `Do you want send email to ${row.full_name} for ${row?.payments_reminder?.at(-1)?.next_payment
        }° installment of the ${euro(
          row?.payments_reminder?.at(-1)?.next_amount
        )}?`,
      onAgree: async () => {
        setConfirmDialogData((p: any) => ({ ...p, open: false }));

        const res = await useAPI(HTTP_SEND_EMAIL, { token: row.token });

        if (res?.status) {
          snackHandler("Success send email!");
        } else {
          snackHandler("Error send email!", "error");
        }

        setConfirmDialogData(defaultConfirmDialogData);
      },
      onDisagree: () => setConfirmDialogData(defaultConfirmDialogData),
    });
  };

  const handleDelete = async () => {
    const res = await useAPI(
      HTTP_BASE + "/delete",
      { token_user: rowSelected?.token },
      { type: "delete" }
    );

    if (!res?.status) {
      snackHandler(res?.message || "Failed delete!", "error");
      return;
    }

    snackHandler("Success delete!");
    queryClient.invalidateQueries(KEY_QUERY);
  };

  const columns: GridColDef[] = useMemo(
    (): GridColDef[] => [
      {
        field: "lang",
        headerName: "",
        align: "center",
        maxWidth: 75,
        renderCell: ({ row }) => (
          <Box className="flex items-center">
            {Boolean(row?.tutoring && !isAdmission) && <span>A</span>}
            <FlagRender
              flag={
                row?.lang == "de"
                  ? row?.phone?.includes("+41")
                    ? "ch"
                    : "de"
                  : row?.lang
              }
            />
          </Box>
        ),
      },
      {
        field: "full_name",
        headerName: "Name",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) => row?.full_name || "-",
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        flex: 1,
        valueGetter: ({ row }) => row?.email || "-",
      },
      ...(isAdmission
        ? []
        : [
          {
            field: "start_month",
            headerName: "Start month",
            minWidth: 160,
            flex: 0.5,
            valueGetter: ({ row }) => row?.start_month || "-",
          },
        ]),
      {
        field: "product",
        headerName: "Product",
        minWidth: 220,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => {
          const product_id = getProductID(row?.payments_reminder);

          const product = row?.products_selected?.find(
            (p) => p.id === product_id
          )?.course_info?.course_name;

          return product || "-";
        },
        renderCell: ({ row }) => {
          const product_id = getProductID(row?.payments_reminder);

          const product = row?.products_selected?.find(
            (p) => p.id === product_id
          );

          return product ? (
            <MyTooltip>{product.course_info.course_name}</MyTooltip>
          ) : (
            "-"
          );
        },
      },
      {
        field: "paid_amount",
        headerName: "Paid Amount",
        minWidth: 140,
        flex: 0.5,
        sortable: false,
        valueGetter: ({ row }) => {
          const amount = row?.payments_reminder?.at(-1)?.current_amount;
          return amount ? euro(amount) : "-";
        },
        renderCell: ({ row }) => {
          const amount = row?.payments_reminder?.at(-1)?.current_amount;
          if (!amount) return "-";

          return (
            <Box className="flex items-center gap-2 w-full">
              <Box className="min-w-[5.5rem]">{euro(amount)}</Box>
              <Button
                className="!min-w-0 !bg-transparent"
                onClick={(e) => {
                  setRowSelected(row);
                  setAnchorElPaidAmount(e.currentTarget);
                }}
              >
                <img
                  className="icon no-margin"
                  src={clockIcon}
                  alt="Clock Icon"
                />
              </Button>
            </Box>
          );
        },
      },
      {
        field: "rest_to_pay",
        headerName: "Rest to Pay",
        minWidth: 110,
        flex: 0.5,
        sortable: false,
        valueGetter: ({ row }) => {
          const amount = row?.payments_reminder?.at(-1)?.rest_amount;
          return amount ? euro(amount) : "-";
        },
      },
      {
        field: "total_to_pay",
        headerName: "Total to Pay",
        minWidth: 120,
        flex: 0.5,
        sortable: false,
        valueGetter: ({ row }) => {
          const amount = row?.payments_reminder?.at(-1)?.final_price;
          return amount ? euro(amount) : "-";
        },
      },
      {
        field: "attendance",
        headerName: "#Attendance",
        minWidth: 130,
        flex: 0.5,
        sortable: false,
      },
      {
        field: "comment",
        headerName: "Comment",
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const latestComment =
            row?.note?.length > 0
              ? row.note[row.note.length - 1]
              : "No comments";

          return (
            <Box
              color="primary"
              style={{ cursor: "pointer" }}
              display="flex"
              onClick={() => {
                setOpenComment(true);
                setRowSelected(row);
              }}
              alignItems="center"
              className="w-full truncate"
            >
              {latestComment?.content ? (
                <MyTooltip>{latestComment.content}</MyTooltip>
              ) : (
                <ListItemIcon>
                  <img
                    src={commentsIcon}
                    alt="comments icon"
                    className="icon"
                  />
                </ListItemIcon>
              )}
            </Box>
          );
        },
      },
      {
        field: "action",
        headerName: "",
        width: 70,
        sortable: false,
        editable: false,
        hideable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) => (
          <Dropdown
            text={<MoreVertIcon />}
            content={
              <MenuList>
                <MenuItem
                  color="primary"
                  title="Copy email"
                  onClick={() => copyToClipboard(row.email)}
                >
                  <ListItemIcon>
                    <img
                      src={copyIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>Copy email</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Invoices"
                  onClick={() => InvoiceUserDialogRef.current?.open(row.token)}
                >
                  <ListItemIcon>
                    <img
                      src={invoiceIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>Invoices</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Send email"
                  onClick={() => handleSendEmail(row)}
                >
                  <ListItemIcon>
                    <img
                      className="icon no-margin"
                      src={mailIcon}
                    />
                  </ListItemIcon>
                  <ListItemText>Send mail</ListItemText>
                </MenuItem>

                <MenuItem
                  title="Delete"
                  color="primary"
                  onClick={() => {
                    setRowSelected(row);
                    setOpenDelete(true);
                  }}
                >
                  <ListItemIcon>
                    <img
                      className="icon no-margin"
                      src={deleteIcon}
                    />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </MenuList>
            }
          />
        ),
      },
    ],
    [dataAPI?.rows?.data]
  );

  return (
    <BasePage title={`Still to pay${isAdmission ? " admission" : ""}`}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={dataAPI?.rows?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={--page}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_: any, newPage: number) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            theme,
            filters,
            setPage,
            KEY_QUERY,
            filtersHttp,
            snackHandler,
            setFiltersHttp,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: { page, setPage, pagination: dataAPI?.rows },
        }}
      />

      <PaymentsTooltip
        theme={theme}
        onClose={() => setAnchorElPaidAmount(null)}
        product={rowSelected?.payments_reminder?.at(-1)?.product_id}
        anchorEl={anchorElPaidAmount}
        payments={rowSelected?.payments || []}
      />

      <ConfirmationDialog
        open={confirmDialogData.open}
        title={confirmDialogData.title}
        onConfirm={confirmDialogData.onAgree}
        onClose={confirmDialogData.onDisagree}
      >
        {confirmDialogData.content}
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={handleDelete}
      >
        Do you want delete {rowSelected?.full_name}?
      </ConfirmationDialog>

      <InvoiceUserDialog ref={InvoiceUserDialogRef} />

      <UsersListNotes
        open={openComment}
        setOpen={setOpenComment}
        cacheKey={KEY_QUERY}
        userAdmin={userAdmin}
        selectedUser={rowSelected}
      />
    </BasePage>
  );
};

export default StillToPay;
