export const useCalendarEvents = (
  data,
  status,
  functions,
  setOpenCalendarEvents
) => {
  const handleUpdateRow = (meet) => {
    const values: any = { status };
    const isScheduleMeeting = status === data.buttons?.reschedule?.status;

    if (isScheduleMeeting) {
      const formatMeet = {
        id: meet?.id,
        start: meet?.start,
        end: meet?.end,
        calendar: meet?.calendar,
        link: meet?.meet,
      };

      values.meetings = data.user.meetings
        ? {
            ...data.user.meetings,
            meet: [...data.user.meetings.meet, formatMeet],
          }
        : { meet: [formatMeet], summary: meet?.summary };
    }

    functions?.onUpdateRow?.({
      values,
      toast: "status",
      tokenUser: data.user.token,
      activeApi: !isScheduleMeeting,
    });
    setOpenCalendarEvents(false);
  };

  return { handleUpdateRow };
};
