import { useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { Box, LinearProgress, Tab, useTheme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useAPI from "@/api/useAPI";
import Archive from "./Archive";
import AllFeedbacks from "./AllFeedbacks";
import NpsFeedbacks from "./NpsFeedbacks";
import TutorsFeedbacks from "./TutorsFeedbacks";
import DialogViewNotes from "./DialogViewNotes";
import StudentsFeedbacks from "./StudentsFeedbacks";
import ConfirmationDialog from "@/components/ConfirmationDialog";

const KEY_QUERY = ["multi", "feedbacks"];
const ROUTE_HTTP_BASE = "/v1/feedbacks";
const ROUTE_HTTP_ARCHIVE = ROUTE_HTTP_BASE + "/archive";

export const COLORS_STARS = ["#ff0000", "#ff8000", "#ffa200", "#ffbf00", "#ffdd00"];
export const COLORS_STARS_MEDIA = ["#ff0000", "#ff1200", "#ff2900", "#ff4500", "#ff6400", "#ff7d00", "#ff9700", "#ffb000", "#ffc900", "#ffe000", "#ffff00"];

const TABS = [
  { label: "All", value: "1" },
  { label: "Students", value: "2" },
  { label: "Tutors", value: "3" },
  { label: "NPS", value: "4" },
  { label: "Archived", value: "5" },
];

interface INOpenConfirmArchive {
  open: boolean;
  id: number | null;
}

const Feedbacks = () => {
  const theme = useTheme();
  const { userAdmin } = useAuth();
  const { data: dataAPI, isLoading } = useQuery(KEY_QUERY, () => useAPI(ROUTE_HTTP_BASE));

  const [tab, setTab] = useState<string>(localStorage.getItem("feedbacks_tab") || "1");
  const [data, setData] = useState<any>({});

  const [openConfirmArchive, setOpenConfirmArchive] = useState<INOpenConfirmArchive>({ open: false, id: null });

  const handleChangeTab = (_: any, newTab: string) => {
    localStorage.setItem("feedbacks_tab", newTab);
    setTab(newTab);
  };

  // NOTES ===============================================================
  const [open, setOpen] = useState<boolean>(false);
  const [allNotes, setAllNotes] = useState<Array<any>>([]);
  const [dataDialog, setDataDialog] = useState<any>({});

  const getNotesBackend = async (token: string) => {
    const notesData = await useAPI("/v1/notes_feedbacks/show", { token });
    if (!notesData?.length) return;
    setDataDialog((p) => ({ ...p, data: notesData }));
    setAllNotes((p) => [...p, ...notesData]);
  };

  const getNotes = ({ token, fullNome, numFeedback, person }) => {
    const notesData = allNotes.filter((e) => e?.token_user === token);
    if (!notesData.length) getNotesBackend(token);
    setDataDialog((p: any) => ({ ...p, data: notesData, token, fullNome, numFeedback, person }));
    setOpen(true);
  };

  const createNotes = (postData: any) => {
    useAPI("/v1/notes_feedbacks/create", postData);
    setAllNotes((p: any) => {
      const t = [...p];
      t.push(postData);
      return t;
    });
  };
  // NOTES ===============================================================

  const handleOpenPopupArchive = (id: number) => {
    setOpenConfirmArchive({ open: true, id });
  };

  const handleArchive = () => {
    const id = openConfirmArchive.id;
    setData((p: any) => ({
      nps: p.nps.filter((f: any) => f.id !== id),
      archive: p.archive.filter((f: any) => f.id !== id),
      feedbacks: p.feedbacks.filter((f: any) => f.id !== id),
    }));
    useAPI(ROUTE_HTTP_ARCHIVE, { id });
    setOpenConfirmArchive({ open: false, id: null });
  };

  useEffect(() => {
    if (!dataAPI) return;
    setData(dataAPI);
  }, [dataAPI]);

  if (isLoading) return <LinearProgress />;

  return (
    <>
      <TabContext value={tab}>
        <Box className="mt-8 mx-5 md:mx-10" style={{ height: "calc(100% - 10rem)" }}>
          <Box component="h2" className="text-3xl font-extrabold text-white mb-3">
            Feedbacks
          </Box>

          <Box className="border-b border-gray-800">
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              {TABS.map(({ label, value }) => (
                <Tab key={label + value} sx={{ color: "#fff" }} label={label} value={value} />
              ))}
            </TabList>
          </Box>

          <TabPanel style={{ height: "calc(100% - 10rem)" }} sx={{ px: 0 }} value="1">
            <AllFeedbacks dataAPI={data.feedbacks} isLoading={isLoading} />
          </TabPanel>

          <TabPanel style={{ height: "calc(100% - 10rem)" }} sx={{ px: 0 }} value="2">
            <StudentsFeedbacks dataAPI={data.feedbacks} getNotes={getNotes} isLoading={isLoading} />
          </TabPanel>

          <TabPanel style={{ height: "calc(100% - 10rem)" }} sx={{ px: 0 }} value="3">
            <TutorsFeedbacks dataAPI={data.feedbacks} getNotes={getNotes} isLoading={isLoading} />
          </TabPanel>

          <TabPanel style={{ height: "calc(100% - 10rem)" }} sx={{ px: 0 }} value="4">
            <NpsFeedbacks dataAPI={data.nps} isLoading={isLoading} />
          </TabPanel>

          <TabPanel style={{ height: "calc(100% - 10rem)" }} sx={{ px: 0 }} value="5">
            {/* <Archive
              dataAPI={data.archive} isLoading={isLoading} onArchive={handleOpenPopupArchive}
            /> */}
          </TabPanel>

          <DialogViewNotes {...{ open, setOpen, dataDialog, createNotes, userAdmin, setDataDialog, setDataAPI: setData }} />
        </Box>
      </TabContext>

      {openConfirmArchive.open ? (
        <ConfirmationDialog open={openConfirmArchive.open} onClose={() => setOpenConfirmArchive({ open: false, id: null })} onConfirm={handleArchive}>
          Do you want to <span style={{ color: theme.palette.primary.main }}>archive</span> this feedback?
        </ConfirmationDialog>
      ) : null}
    </>
  );
};

export default Feedbacks;
