import useAPI from "@/api/useAPI";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAuth from "@/hooks/useAuth";
import { queryClient } from "@/main";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

export interface IRef {
  open: (user: any, cacheKey: Array<any>) => void;
}

interface IProps {}

const ToogleActiveDiploma = forwardRef<IRef, IProps>((_, ref) => {
  const { snackHandler } = useAuth();

  const [user, setUser] = useState<any>();
  const [cacheKey, setCacheKey] = useState<Array<any>>();
  const [loadingButton, setLoadingButton] = useState<number>();

  useImperativeHandle(ref, () => ({
    open: (user, cacheKey) => {
      setUser(user);
    },
  }));

  const handleClose = () => {
    setUser(undefined);
    setCacheKey(undefined);
    setLoadingButton(undefined);
  };

  const handleToogleDiploma = async (productId: number) => {
    setLoadingButton(productId);

    const res = await useAPI(
      "v1/users/product/diploma_unlocked",
      {
        productId,
        userId: user?.id,
      },
      { type: "put" }
    );

    setLoadingButton(undefined);

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed active/disable diploma!", "error");
      return;
    }

    snackHandler(res?.message ?? "Success active/disable diploma!");
    queryClient.invalidateQueries(cacheKey);
    handleClose();
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(user)}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>Toogle unlock diploma</EduModalTitle>

      <EduModalBody className="p-4 flex flex-col gap-4">
        {user?.products?.map(
          (
            product: {
              id: number;
              pivot: { diploma_unlocked: number };
              product_tag: string;
            },
            i: number
          ) => (
            <Box
              key={product?.product_tag + i}
              className="w-full flex gap-4 justify-between items-center"
            >
              <span>{product?.product_tag}</span>
              <Button
                variant="outlined"
                onClick={() => handleToogleDiploma(product?.id)}
                disabled={loadingButton == product?.id}
              >
                {Number(product?.pivot?.diploma_unlocked) ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </Button>
            </Box>
          )
        )}
      </EduModalBody>
    </EduModal>
  );
});

export default ToogleActiveDiploma;
