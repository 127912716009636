import { FormEvent, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { EduModal, EduModalBody, EduModalTitle } from "@/components/_Common/Modal/ModalStyles";
import { Autocomplete, Box, Button, MenuItem, TextField, useTheme } from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { fUpper } from "../useHelpers";
import useAuth from '@/hooks/useAuth';

interface INFormOther {
  label: string;
  value: string;
}
export interface INForm {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_ads?: string;
}

interface INQuery {
  ads: boolean;
  associated_link: string;
  query: INForm;
  influencer: string;
  tracking_name: string;
  other: Array<INFormOther>;
}

const LANDING_URL = "https://edusogno.com/acceleratore-di-inglese/ads/";
const LANDING_URL_ADMISSION = "https://edusogno.com/edusogno-admissions/ads/";
const FORM_URL = "https://edusogno.com/form/edusogno-inglese";

const defaultInQuery: INQuery = {
  ads: false,
  associated_link: FORM_URL,
  query: {},
  influencer: "",
  tracking_name: "",
  other: [],
};

// const LANDING_URLS = ["https://", "https://edusogno.com/", "https://edusogno.com/acceleratore-di-inglese/", "https://edusogno.com/acceleratore-di-inglese/ads/"];

// const FORM_URL = ["https://", "https://edusogno.com/", "https://edusogno.com/form/", "https://edusogno.com/form/edusogno-inglese"];

const CreateLinkDialog = ({ open, onClose, onCreateLink, params }) => {
  const theme = useTheme();
  const { isAdmission } = useAuth();

  const [form, setForm] = useState<INQuery>(defaultInQuery);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await onCreateLink(form);
    setIsLoading(false);
    setForm(defaultInQuery);
  };

  const otherCampAction = (action: string, label: string = "", value: string = "", index: number = 0) => {
    action = action.toLowerCase();

    setForm((p: INQuery) => {
      const t = { ...p };

      if (action === "add") {
        t.other[index][label] = value;
      } else if (action === "remove") {
        t.other.pop();
      }

      return t;
    });
  };

  return (
    <EduModal fullWidth open={open} onClose={onClose}>
      <EduModalTitle onClose={onClose}>
        <img src={addIcon} className="icon" /> Create a new link
      </EduModalTitle>

      <EduModalBody>
        <Box className="flex flex-col p-8 gap-4" onSubmit={handleSubmit} component="form">
          <Box className="flex flex-col gap-4">
            <TextField
              select
              name="ads"
              label="Type"
              value={Number(form.ads)}
              onChange={(e) =>
                setForm((p: INQuery) => ({ ...p, ads: Boolean(e.target.value), associated_link: Boolean(e.target.value) ? (isAdmission ? LANDING_URL_ADMISSION : LANDING_URL) : FORM_URL }))
              }
            >
              {(Object.entries({ form: false, landing: true }) as [string, boolean][]).map(([key, value], i: number) => (
                <MenuItem key={key + i} value={Number(value)}>
                  {fUpper(key)}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              name="tracking_name"
              color="primary"
              label="Tracking name"
              value={form.tracking_name ?? ""}
              placeholder="edusogno-ig"
              onChange={(e) => setForm((p: INQuery) => ({ ...p, tracking_name: e.target.value }))}
            />

            {form.ads && (
              <TextField
                name="influencer"
                color="primary"
                label="Influencer"
                value={form.influencer ?? ""}
                placeholder="Name..."
                onChange={(e) => setForm((p: INQuery) => ({ ...p, influencer: e.target.value }))}
              />
            )}

            {/* <Autocomplete
              options={form.ads ? LANDING_URLS : FORM_URL}
              onInputChange={(e, newVal) => setForm((p: INQuery) => ({ ...p, associated_link: newVal }))}
              color="primary"
              value={form?.associated_link}
              inputValue={form?.associated_link}
              renderInput={(params) => <TextField required name="base_link" label="Base link" {...params} />}
            /> */}

            {params &&
              (Object.entries(params || {}) as [string, string[]][]).map(([key, value], i: number) => (
                <Autocomplete
                  key={key + i}
                  freeSolo={true}
                  options={value as Array<string>}
                  onInputChange={(e, newVal) => setForm((p: INQuery) => ({ ...p, query: { ...p.query, [key]: newVal } }))}
                  color="primary"
                  value={form?.query?.[key]}
                  inputValue={form?.query?.[key]}
                  renderInput={(params) => <TextField required name={key} label={fUpper(key)} {...params} />}
                />
              ))}
          </Box>

          {/* {form?.other?.map((e: any, i: number) => (
            <Box
              key={i}
              className="flex flex-col gap-4 px-2 py-3 rounded border"
              sx={{ borderColor: theme.palette.primary.main, backgroundColor: theme.palette.background.paper }}
            >
              <TextField
                required
                color="primary"
                name="label"
                label="Name"
                value={e?.label ?? ""}
                onChange={(e) => otherCampAction("add", "label", e.target.value, i)}
              />
              <TextField
                required
                color="primary"
                name="value"
                label="Value"
                value={e?.value ?? ""}
                onChange={(e) => otherCampAction("add", "value", e.target.value, i)}
              />
            </Box>
          ))} */}

          {/* <Box className="flex gap-2">
            <Button
              variant="outlined"
              type="button"
              className=" w-full grid-rows-2"
              color="primary"
              onClick={() => setForm((p: INQuery) => ({ ...p, other: [...p.other, { label: "", value: "" }] }))}
            >
              Add
            </Button>

            {Boolean(form.other.length) && (
              <Button variant="outlined" type="button" className=" max-w-fit" color="error" onClick={() => otherCampAction("remove")}>
                <RemoveCircleOutlineIcon />
              </Button>
            )}
          </Box> */}

          <Box className="flex justify-end">
            <LoadingButton variant="outlined" type="submit" disabled={isLoading} loading={isLoading} color="primary">
              <img src={saveIcon} className="icon" />
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default CreateLinkDialog;
