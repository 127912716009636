import { createRef, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  MenuItem,
  MenuList,
  useTheme,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FormPaymentOutDialog, {
  IData,
} from "@/components/PaymentsOut/FormPaymentOutDialog";
import ImportPaymentOutDialog, {
  IForwardRef,
} from "@/components/PaymentsOut/ImportPaymentOutDialog";
import ViewInvoicePaymentOutDialog, {
  IViewInvoiceForwardRef,
} from "@/components/PaymentsOut/ViewInvoicePaymentOutDialog";
import dayjs from "dayjs";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import EditIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import RefundIcon from "@/assets/icons/refund.svg";
import DeleteIcon from "@/assets/icons/delete.svg";
import PaymentIcon from "@/assets/icons/payment.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import usePagination from "@/hooks/usePagination";
import CustomPagination from "@/components/_Common/CustomPagination";
import usePaymentsOutApi from "@/api/usePaymentsOutApi";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import InvoicePaymentOutDialog from "@/components/PaymentsOut/InvoicePaymentOutDialog";
import CustomToolbarPaymentsOut from "@/components/PaymentsOut/CustomToolbarPaymentsOut";

const PaymentsOut = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { userAdmin } = useAuth();

  const importDialog = createRef<IForwardRef>();
  const viewInvoiceDialog = createRef<IViewInvoiceForwardRef>();

  const [show, setShow] = useState(false as boolean);
  const [data, setData] = useState(null as IData | null);
  const [showDialog, setShowDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<"file" | "confirm">();
  const [paymentData, setPaymentData] = useState<IData | null>(null);
  const [deleteAlsoFile, setDeleteAlsoFile] = useState<
    "delete-file" | "not-delete-file"
  >("not-delete-file");

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  const { callGetPaymentsOut, callDeletePaymentOut } = usePaymentsOutApi();

  const sorts = filterData?.sorts;
  delete filterData?.sorts;
  delete filterData?.page;

  const { data: paymentsOut, isLoading } = callGetPaymentsOut(userAdmin.token, {
    page,
    sorts,
    filter: filterData,
    per_page: perPage,
  });

  const paymentsOutData = paymentsOut?.data || [];
  const pagination = {
    total: paymentsOut?.total ? paymentsOut?.total : 1,
    last_page: paymentsOut?.last_page ? paymentsOut?.last_page : 1,
    current_page: paymentsOut?.current_page ? paymentsOut?.current_page : 1,
  };

  const handleCloseConfirms = () => {
    setOpenConfirm(undefined);
    setDeleteAlsoFile("not-delete-file");
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      width: 60,
    },
    {
      field: "data",
      headerName: "Date",
      minWidth: 100,
      valueGetter: ({ row }) => {
        let formated_date = "";

        try {
          formated_date = dayjs(row.data).format("DD-MM-YYYY");
        } catch (error) {
          formated_date = "Invalid Date check the refund date";
          console.error({ error });
        }

        return formated_date;
      },
    },
    {
      field: "relevant_month",
      headerName: "Relevant month",
      width: 170,
      valueGetter: ({ row }) => {
        if (!row.relevant_month) return "-";

        let formated_date = "";

        try {
          formated_date = dayjs(row.relevant_month).format("MMMM YYYY");
        } catch (error) {
          formated_date = "Invalid Date check the refund date";
          console.error({ error });
        }

        return formated_date;
      },
    },
    {
      field: "destinatario",
      headerName: "Receiver",
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row.destinatario}</MyTooltip>,
    },
    {
      field: "importo",
      headerName: "Amount",
      width: 100,
      renderCell: ({ row }) => (
        <Typography color="primary">&euro;{row.importo}</Typography>
      ),
    },
    {
      field: "net_amount",
      headerName: "Net amount",
      width: 110,
      renderCell: ({ row }) => (
        <Typography color="primary">&euro;{row?.net_amount}</Typography>
      ),
    },
    {
      field: "oggetto",
      headerName: "Object",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row.oggetto}</MyTooltip>,
    },
    {
      field: "metodo",
      headerName: "Type",
      width: 100,
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 150,
      flex: 0.3,
      renderCell: ({ row }) => <MyTooltip>{row?.category}</MyTooltip>,
    },
    {
      field: "url_drive",
      headerName: "",
      width: 70,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <EduBadge
          badgeContent={!row.url_drive || row.url_drive == "null" ? "" : null}
          color="error"
          showZero={false}
        >
          <Dropdown text={<MoreVertIcon />}>
            <MenuList>
              {row.url_drive && row.url_drive !== "null" ? (
                <MenuItem
                  title="View Invoice"
                  onClick={() => {
                    const downLink = row.url_drive;
                    const url = new URL(downLink);
                    const getParams = new URLSearchParams(url.search);
                    const id = getParams.get("id");
                    const viewLink = `https://drive.google.com/file/d/${id}/preview`;
                    viewInvoiceDialog?.current?.open(viewLink);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={PaymentIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>View Invoice</ListItemText>
                </MenuItem>
              ) : null}

              {!row.refunded_date && (
                <MenuItem
                  onClick={() => {
                    setPaymentData(row);
                    setShowDialog(true);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={RefundIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Refund</ListItemText>
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  setData(row);
                  setShow(true);
                }}
              >
                <ListItemIcon>
                  <img
                    src={EditIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setData(row);
                  setOpenConfirm("file");
                }}
              >
                <ListItemIcon>
                  <img
                    src={DeleteIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </MenuList>
          </Dropdown>
        </EduBadge>
      ),
    },
  ];

  return (
    <BasePage
      title={
        userAdmin.user.admin
          ? "All outgoing payments"
          : "All outgoing payments in the last 2 weeks"
      }
    >
      <DataGrid
        autoHeight
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={paymentsOutData}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar: CustomToolbarPaymentsOut,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        componentsProps={{
          toolbar: {
            setPage: setPage,
            filters: filterData,
            setFilters: setFilters,
            onClickAdd: () => (setData(null), setShow(true)),
            onClickImport: () => importDialog.current?.open(),
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: { page, setPage, pagination },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <ImportPaymentOutDialog ref={importDialog} />

      <ViewInvoicePaymentOutDialog ref={viewInvoiceDialog} />

      <FormPaymentOutDialog
        show={show}
        data={data}
        setData={setData}
        hideDialog={() => setShow(false)}
      />

      <InvoicePaymentOutDialog
        show={showDialog}
        data={paymentData}
        setData={setPaymentData}
        hideDialog={() => (setShowDialog(false), setData(null))}
      />

      <ConfirmationDialog
        open={openConfirm == "confirm"}
        onClose={handleCloseConfirms}
        onConfirm={() => {
          setOpenConfirm(undefined);

          if (data?.id)
            callDeletePaymentOut.mutate({
              id: data.id,
              token: userAdmin.token,
              alsoFile: deleteAlsoFile,
              callback: handleCloseConfirms,
            });
        }}
      >
        Do you want to delete this payment out{" "}
        <b>{deleteAlsoFile == "delete-file" ? "with" : "without"}</b> file from
        drive?
      </ConfirmationDialog>

      <ConfirmationDialog
        hideButtonCross
        excludeOnCloseOutside
        open={openConfirm == "file"}
        onClose={() => {
          setDeleteAlsoFile("not-delete-file");
          setOpenConfirm("confirm");
        }}
        onConfirm={() => {
          setDeleteAlsoFile("delete-file");
          setOpenConfirm("confirm");
        }}
      >
        Do you also want to delete the file from the drive?
      </ConfirmationDialog>
    </BasePage>
  );
};

export default PaymentsOut;
