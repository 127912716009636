import { useState } from "react";
import { convert } from "../useHelpers";
import { CopyAll } from "@mui/icons-material";
import useAuth from '@/hooks/useAuth';
import { IFormUser } from "./FormUserDialog";
import { getTableStyling } from "@/providers/Colors";
import { getCookie, setCookie } from "../Cookie";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import {
  Box,
  Avatar,
  MenuItem,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import Toolbar from "./Toolbar";
import viewIcon from "@/assets/icons/view.svg";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import MyTooltip from "../MyTooltip";
import useScreen from "@/hooks/useScreen";
import deleteIcon from "@/assets/icons/delete.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import impersonateIcon from "@/assets/icons/impersonate.svg";
import MenuToggleRoutes from "./MenuToggleRoutes";
import EdusognoPdfViewer from "../EdusognoPdfViewer";
import AltRouteRoundedIcon from "@mui/icons-material/AltRouteRounded";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

interface IProps {
  data: Array<any>;
  page: string | number;
  setPage: (value: any) => void;
  filters: { [x: string]: any };
  sortModel: GridSortModel;
  setFilters: (v: any) => any;
  onSortModelChange: (model: GridSortModel) => void;
  toogleFormUserDialog: (user?: IFormUser | null) => void;
  toogleConfirmDeleteDialog: (user?: IFormUser | null) => void;
}

const TeamsTable = ({
  data,
  page,
  setPage,
  filters,
  sortModel,
  setFilters,
  onSortModelChange,
  toogleFormUserDialog,
  toogleConfirmDeleteDialog,
}: IProps) => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler, copyToClipboard } = useAuth();
  const [openPdfViewer, setOpenPdfViewer] = useState<boolean>(false);

  const handleImpersonate = async (uuid: string) => {
    const res = await useAPI("/v1/impersonate/admin", { uuid });

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed authorized!", "error");
      return;
    }

    const currentAdmin = getCookie("user");

    if (!currentAdmin) {
      snackHandler("The current authorized not found!");
      return;
    }

    setCookie("user", res.data);
    setCookie("user-old", currentAdmin);
    window.location.reload();
  };

  const columns: GridColDef[] = [
    {
      field: "avatar",
      headerName: "",
      width: 60,
      renderCell: ({ row }) => (
        <Avatar
          src={row?.avatar}
          alt={row?.first_name?.toUpperCase().charAt(0)}
          sx={{ width: 40, height: 40 }}
        >
          {!row?.avatar &&
            `${row?.first_name?.toUpperCase().charAt(0)}${row?.last_name
              ?.toUpperCase()
              .charAt(0)}`}
        </Avatar>
      ),
    },
    {
      field: "name",
      headerName: "Full Name",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <MyTooltip>
          {row?.first_name} {row?.last_name}
        </MyTooltip>
      ),
      valueGetter: ({ row }) => row?.first_name + " " + row?.last_name,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) =>
        row?.email ? (
          <MyTooltip title={row?.email}>
            <button
              onClick={() => copyToClipboard(row?.email)}
              className="w-full text-left truncate"
            >
              {row?.email}
            </button>
          </MyTooltip>
        ) : (
          "-"
        ),
      valueGetter: ({ row }) => row?.email ?? "-",
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 140,
      flex: 0.7,
      renderCell: ({ row }) =>
        row?.phone ? <MyTooltip>{row?.phone}</MyTooltip> : "-",
      valueGetter: ({ row }) => row?.phone ?? "-",
    },
    {
      field: "birth_date",
      headerName: "Birth Date",
      width: 120,
      valueGetter: ({ row }) => row?.birth_date ?? "-",
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
      valueGetter: ({ row }) => row?.role ?? "-",
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
      renderCell: ({ row }) =>
        row?.address ? <MyTooltip>{row?.address}</MyTooltip> : "-",
      valueGetter: ({ row }) => row?.address ?? "-",
    },
    {
      field: "iban",
      headerName: "IBAN",
      width: 200,
      renderCell: ({ row }) =>
        row?.iban ? <MyTooltip>{row?.iban}</MyTooltip> : "-",
      valueGetter: ({ row }) => row?.iban ?? "-",
    },
    {
      field: "bic",
      headerName: "BIC",
      width: 150,
      valueGetter: ({ row }) => row?.bic ?? "-",
    },
    {
      field: "uuid",
      headerName: "",
      width: 70,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVertIcon />}>
          <MenuItem onClick={() => copyToClipboard(row?.email)}>
            <ListItemIcon>
              <CopyAll fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy email</ListItemText>
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <AltRouteRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <MenuToggleRoutes
                userId={row?.id}
                routes={convert(row.routes || [])}
              />
            </ListItemText>
          </MenuItem>

          {row?.contract_file_path && (
            <Box>
              <MenuItem onClick={() => setOpenPdfViewer(true)}>
                <ListItemIcon>
                  <img
                    src={viewIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>View contract</ListItemText>
              </MenuItem>
              {/* show or hide pdf viewer dialog */}
              <EdusognoPdfViewer
                open={openPdfViewer}
                onClose={() => setOpenPdfViewer(false)}
                pdfUrl={row?.contract_file_path}
                dialogTitle="Viewing Team Contract"
              />
            </Box>
          )}

          <MenuItem onClick={() => handleImpersonate(row.uuid)}>
            <ListItemIcon>
              <img
                src={impersonateIcon}
                className="icon no-margin"
              />
            </ListItemIcon>
            <ListItemText>Impersonate</ListItemText>
          </MenuItem>

          <MenuItem onClick={() => toogleFormUserDialog(row)}>
            <ListItemIcon>
              <img
                src={editIcon}
                className="icon no-margin"
              />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>

          <MenuItem onClick={() => toogleConfirmDeleteDialog(row)}>
            <ListItemIcon>
              <img
                src={deleteIcon}
                className="icon no-margin"
              />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Dropdown>
      ),
    },
  ];

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      disableVirtualization
      disableSelectionOnClick
      sx={getTableStyling(theme)}
      rows={data}
      page={parseInt(page.toString()) - 1}
      columns={columns}
      pageSize={perPage}
      sortModel={sortModel}
      components={{ Toolbar, Pagination: CustomPaginationClient }}
      onPageChange={(newPage) => setPage(newPage + 1)}
      componentsProps={{
        toolbar: { filters, setFilters, toogleFormUserDialog },
      }}
      onSortModelChange={onSortModelChange}
      rowsPerPageOptions={[perPage]}
      experimentalFeatures={{ newEditingApi: false }}
    />
  );
};

export default TeamsTable;
