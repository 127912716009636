import { TextField } from "@mui/material";

const CustomInput = ({
  name,
  label,
  value,
  shrink = true,
  required = false,
  onChange,
}) => (
  <TextField
    fullWidth
    name={name}
    color="primary"
    label={label}
    value={value}
    variant="outlined"
    required={required}
    onChange={(e) => onChange(e.target.value)}
    InputLabelProps={{ shrink }}
  />
);

export default CustomInput;
