import { format } from "date-fns";
import { Button } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import downloadIcon from "@/assets/icons/download.svg";
type Variant = "outlined" | "contained" | "text";
type Color =
  | "error"
  | "info"
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "warning";

interface INUseDownloadTable {
  sx?: any;
  Icon?: JSX.Element;
  color?: Color;
  title?: string;
  fileName: string;
  variant?: Variant;
  disabled?: boolean;
  delimiter?: string;
  className?: string;
  IconColor?: string;
  removeDate?: boolean;
}

export default ({
  sx,
  Icon,
  color,
  title,
  fileName,
  variant,
  disabled,
  delimiter,
  className,
  IconColor,
  removeDate,
}: INUseDownloadTable) => {
  const apiRef = useGridApiContext();

  const handleExport = () => {
    //  get all data as array

    let csv = [
      [
        "ID",
        "Titolo",
        "Data",
        "Ora",
        "Tutor",
        "Type",
        "Status",
        "Attended",
        "In %20 meet",
        "Attended %20 email",
        "Absent %20 email",
        "In %20 meet %20 email",
      ],
    ];

    const data = [...apiRef.current.getRowModels().entries()].map(
      ([id, row]) => {
        let rowCsv: any = [];

        rowCsv.push(row.id);
        rowCsv.push(row?.titolo);
        rowCsv.push(row?.data);
        rowCsv.push(row?.ora);
        rowCsv.push(row?.tutors?.first_name);
        rowCsv.push(row?.type);
        rowCsv.push(row?.status || "");

        const attendedEmails = row?.user_events?.map(
          (user: any) => user?.email
        );
        const inTheEventEmails = row?.user_events
          ?.filter((user: any) => user.is_logged_in == 1)
          .map((user: any) => user?.email);

        rowCsv.push(attendedEmails?.length);
        rowCsv.push(inTheEventEmails?.length);
        rowCsv.push(attendedEmails?.join(","));

        const absentEmails = row?.user_events
          ?.filter((user: any) => user.is_logged_in == 0)
          .map((user: any) => user?.email);
        rowCsv.push(absentEmails?.join(","));
        rowCsv.push(inTheEventEmails?.join(","));

        csv.push(rowCsv);

        return row;
      }
    );

    //  convert to csv string

    let csvString = "";
    csv.forEach(function (rowArray) {
      let row = rowArray.join(delimiter || ";");
      csvString += row + "\r\n";
    });

    //  download csv file

    let a = document.createElement("a");
    a.href = "data:attachment/csv," + csvString;
    a.target = "_Blank";
    a.download =
      fileName +
      (removeDate ? "" : " " + format(new Date(), "dd-MM-yyyy")) +
      ".csv";
    document.body.appendChild(a);
    a.click();

    //  remove element

    a.remove();
  };

  return (
    <Button
      title={title || "Download"}
      className={className}
      color={color}
      disabled={disabled}
      variant={variant || "outlined"}
      onClick={handleExport}
    >
      {Icon || (
        <img
          src={downloadIcon}
          className="icon"
        />
      )}{" "}
      Download
    </Button>
  );
};
