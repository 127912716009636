import useAuth from '@/hooks/useAuth';
import { useSearchParams } from "react-router-dom";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import useUserApi from "@/api/useUserApi";
import usePagination from "@/hooks/usePagination";
import IndexUsersList from "./component/IndexUsersList";

const UsersList = () => {
  const { perPage } = useScreen();
  const { userAdmin } = useAuth();

  const [filters, setFilters] = useSearchParams({});
  const filterData = Object.fromEntries(filters.entries());

  const { page, setPage } = usePagination({ setFilters, filters });

  const { data: dataAPI, isLoading } =
    useUserApi().callPostUserListWithSecondPayment({
      page,
      ...filterData,
      per_page: perPage,
    });

  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  const cacheKey = { page, ...filterData, per_page: perPage };

  const pagination = {
    total: Boolean(dataAPI?.users) ? dataAPI?.users?.total : 1,
    last_page: Boolean(dataAPI?.users) ? dataAPI.users?.users?.last_page : 1,
    current_page: Boolean(dataAPI?.users) ? dataAPI?.users?.current_page : 1,
  };

  return (
    <BasePage title="New users">
      <IndexUsersList
        {...{
          page,
          users: dataAPI?.users?.data || [],
          filters: filterData,
          setPage,
          perPage,
          cacheKey,
          isLoading,
          userAdmin,
          sortModel,
          pagination,
          setFilters,
          onSortModelChange,
        }}
      />
    </BasePage>
  );
};

export default UsersList;
