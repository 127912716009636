import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextQuestion from "./TextQuestion";
import SelectQuestion from "./SelectQuestion";
import { IQuestion } from "./QuestionInterface";

const Question = ({
  value,
  onChange,
  onDelete,
}: {
  value: IQuestion;
  onChange: (newValue: IQuestion) => void;
  onDelete: () => void;
}) => {
  const types = [
    {
      label: "Text question",
      value: "text",
      component: (
        <TextQuestion
          value={value}
          onChange={onChange}
          onDelete={onDelete}
        />
      ),
    },
    {
      label: "Phone question",
      value: "tel",
      component: (
        <TextQuestion
          value={value}
          onChange={onChange}
          onDelete={onDelete}
        />
      ),
    },

    {
      label: "Date question",
      value: "date",
      component: (
        <TextQuestion
          value={value}
          onChange={onChange}
          onDelete={onDelete}
        />
      ),
    },
    {
      label: "Multiple choice question",
      value: "check",
      component: (
        <SelectQuestion
          value={value}
          onChange={onChange}
          onDelete={onDelete}
        />
      ),
    },
    {
      label: "Single choice question",
      value: "radio",
      component: (
        <SelectQuestion
          value={value}
          onChange={onChange}
          onDelete={onDelete}
        />
      ),
    },
    {
      label: "Number question",
      value: "number",
      component: (
        <TextQuestion
          value={value}
          onChange={onChange}
          onDelete={onDelete}
        />
      ),
    },
    {
      label: "Upload file",
      value: "file",
      component: (
        <TextQuestion
          value={value}
          onChange={onChange}
          onDelete={onDelete}
        />
      ),
    },
  ];

  const type = value?.elements?.[0]?.type || "text";
  const currentType = types.find((t) => t.value === type);

  const handleChange = (e) => {
    const newType = e.target.value;

    let defaultContent: IQuestion;
    if (
      true === ["text", "number", "file", "date", "email"].includes(newType)
    ) {
      defaultContent = {
        question: "",
        elements: [
          {
            type: newType,
            label: "",
            value: "",
            correctAnswer: "",
          },
        ],
      };
    } else {
      defaultContent = {
        question: "",
        elements: [
          {
            type: newType,
            label: "",
            value: "",
            trueOption: false,
          },
          {
            type: newType,
            label: "",
            value: "",
            trueOption: false,
          },
          {
            type: newType,
            label: "",
            value: "",
            trueOption: false,
          },
          {
            type: newType,
            label: "",
            value: "",
            trueOption: false,
          },
        ],
      };
    }

    onChange(defaultContent);
  };
  return (
    <div className="flex gap-4">
      <FormControl sx={{ width: "200px" }}>
        <InputLabel id="select_type">Question type</InputLabel>
        <Select
          labelId="select_type"
          value={type}
          label="Question type"
          onChange={handleChange}
        >
          {types.map((item, index) => (
            <MenuItem
              key={index}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {currentType?.component}
    </div>
  );
};

export default Question;
