import { clone } from "@/components/useHelpers";
import client from '@/api/client';
export const updateRowAPI = async (
  values: any,
  token: string,
  userAdmin: any
) => {
  await client.crm_upselling.update({
    values,
    token_user: token,
    admin: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
  });
};

export const updateRowData = (
  rows: any[],
  token: string,
  values: any,
  tokenUser: string
) => {
  const rows2 = clone(rows);
  const i = rows2.findIndex((row: any) => row.token === token);

  if (!rows2[i]?.crm_upselling) {
    rows2[i].crm_upselling = { token_user: tokenUser, ...values };
  } else {
    rows2[i].crm_upselling = { ...rows2[i].crm_upselling, ...values };
  }

  return rows2;
};

export const toastMessages: {
  [key: string]: string | ((status: string) => string);
} = {
  comments: "New comments saved!",
  status: (status: string) => `New status "${status}"`,
  change: "Change response!",
};

export const getLastByKey = (row: any, key: string): any =>
  row?.crm_upselling?.meetings?.meet
    ?.filter((m: any) => Boolean(m?.[key]))
    ?.at(-1);
