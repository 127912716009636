import { Dispatch, SetStateAction } from "react";
import { Dialog, Box, Typography, TextField, Button } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";

interface INProps {
  open: boolean;
  onSave: () => void;
  onClose: () => void;
  data: { value?: string };
  setData: Dispatch<SetStateAction<string | any>>;
}

const EditCalendarID = ({ open, onClose, data, setData, onSave }: INProps) => {
  return (
    <EduModal {...{ open, onClose }} fullWidth maxWidth="sm">
      <EduModalTitle onClose={onClose}>Set calendar ID</EduModalTitle>
      <EduModalBody>
        <Box className="p-4 flex flex-col gap-4">
          <TextField
            className="block w-full"
            label="Calendar ID"
            defaultValue={data?.value || ""}
            onChange={({ target: { value } }) => setData(value)}
            placeholder="save with empty field to set to NULL"
          />
          <Button className="!py-2" onClick={onSave}>
            Save
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default EditCalendarID;
