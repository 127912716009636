export default (
  data: string,
  nameFile: string,
  type: string = "text/csv;charset=utf-8;"
) => {
  const link = document.createElement("a");
  link.setAttribute("href", URL.createObjectURL(new Blob([data], { type })));
  link.setAttribute("download", nameFile);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
