import { FC, FormEvent, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { clone } from "@/components/useHelpers";
import { LoadingButton } from "@mui/lab";
import { useExerciseContext } from "@/pages/Exercises/ExercisesV3/state/ExerciseContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IExercise,
  defExercise,
} from "@/pages/Exercises/ExercisesV2/Create/interfacesAndDefaults";
import {
  PotExercise,
  AddNewExerciseContentPropsType,
} from "@/pages/Exercises/ExercisesV3/exercisesV3.types";
import { transformExercisePayload } from "@/pages/Exercises/ExercisesV3/exerciseV3.utils";
import client from "@/api/client";
import useAuth from "@/hooks/useAuth";
import saveIcon from "@/assets/icons/save.svg";
import AddNewExerciseForm from "@/pages/Exercises/ExercisesV3/components/new-exercise(pot)/AddNewExerciseForm";
import AddNewExerciseQuestions from "@/pages/Exercises/ExercisesV3/components/new-exercise(pot)/AddNewExerciseQuestion";
import AddNewExerciseFromFileJson from "./AddNewExerciseFromFileJson";

export const AddNewExercise: FC<
  Pick<AddNewExerciseContentPropsType, "watchImgRef" | "playerRef"> & {
    isEditing: boolean;
    exercisePayload?: PotExercise;
  }
> = ({ isEditing, watchImgRef, playerRef, exercisePayload }) => {
  const queryClient = useQueryClient();
  const { snackHandler } = useAuth();
  const {
    queryKey,
    addNewExerciseOpenState: { setAddNewExerciseOpen },
  } = useExerciseContext();

  const [exercise, setExercise] = useState<IExercise>(
    transformExercisePayload(exercisePayload) || clone(defExercise)
  );

  const mutation = useMutation(
    (newExercise: IExercise) => {
      const isEdit = !!exercisePayload;
      const formData = new FormData();

      if (newExercise?.audio_file) {
        formData.append("audio_file", newExercise.audio_file);
      }
      if (newExercise?.img_file) {
        formData.append("img_file", newExercise.img_file);
      }

      for (const key in newExercise) {
        if (key !== "audio_file" && key !== "img_file") {
          const value = newExercise[key];
          const formDataValue =
            typeof value === "boolean" ? value.toString() : value;
          formData.append(
            key,
            typeof formDataValue === "string"
              ? formDataValue
              : JSON.stringify(formDataValue)
          );
        }
      }
      if (isEdit) {
        return client.exercises.editNewExercise(formData);
      } else {
        return client.exercises.addNewExercise(formData);
      }
    },
    {
      onSuccess: (res) => {
        snackHandler(res?.message, res?.status ? "success" : "error");
        if (res?.status) {
          queryClient.invalidateQueries(queryKey);
          setAddNewExerciseOpen(false);
        }
      },
      onError: (error: any) => {
        snackHandler(error.message, "error");
      },
    }
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!exercise) {
      snackHandler("Exercise data is not properly initialized", "error");
      return;
    }

    mutation.mutate(exercise);
  };

  const handleSetExercise = (name: string, value: string | boolean | File) => {
    if (value === undefined) {
      setExercise((p) => {
        delete p[name];
        return p;
      });

      return;
    }

    setExercise((p) => ({ ...p, [name]: value }));
  };

  return (
    <Box
      onSubmit={handleSubmit}
      component="form"
      className="h-full flex flex-col gap-4 mt-1"
    >
      {!isEditing && (
        <Box className="flex justify-end">
          <AddNewExerciseFromFileJson setExercise={setExercise} />
        </Box>
      )}

      <AddNewExerciseForm
        exercise={exercise}
        playerRef={playerRef}
        watchImgRef={watchImgRef}
        handleSetExercise={handleSetExercise}
      />

      <AddNewExerciseQuestions
        exercise={exercise}
        setExercise={setExercise}
      />

      <Box className="flex justify-end mt-auto">
        <LoadingButton
          type="submit"
          color="primary"
          variant="outlined"
          loading={mutation.isLoading}
          disabled={mutation.isLoading}
        >
          <img
            alt="Save"
            src={saveIcon}
            className="icon"
          />
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};
