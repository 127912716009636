import useAuth from '@/hooks/useAuth';
import { Box, Button } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import dayjs from "dayjs";
import viewIcon from "@/assets/icons/view.svg";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "../Toolbars/SearchToolbar";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

const CustomToolbarBalance = (props: {
  filters: any;
  setFilters: Function;
  showColumns;
  setShowColumns;
}) => {
  const { isMember } = useAuth();
  const { filters, setFilters } = props;
  const apiRef = useGridApiContext();

  const handleExport = async () => {
    let data: string[] = [];

    let csvHeaders: string[] = [];

    if (props.showColumns) {
      csvHeaders = [
        "Date",
        "Stripe",
        "Klarna",
        "Pagolight",
        "Alma",
        "Bank Transfer",
        "Incomes",
        "E-invoices",
        "Outcomes",
        "Total",
      ];
    } else {
      csvHeaders = ["Date", "Incomes", "E-invoices", "Outcomes", "Total"];
    }

    data.push(csvHeaders.join(";"));

    apiRef.current.getRowModels().forEach((row) => {
      if (props.showColumns) {
        const exportData: string[] = [
          row.dataI ?? "",
          row.stripe
            ? "€ " +
            Number(row.stripe).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.klarna
            ? "€ " +
            Number(row.klarna).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.pagolight
            ? "€ " +
            Number(row.pagolight).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",

          row.alma
            ? "€ " +
            Number(row.alma).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.bonifico
            ? "€ " +
            Number(row.bonifico).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.incomes
            ? "€ " +
            Number(row.incomes).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.e_invoices
            ? "€ " +
            Number(row.e_invoices).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.outcomes
            ? "€ " +
            Number(row.outcomes).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.total
            ? "€ " +
            Number(row.total).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
        ];

        data.push(exportData.join(";"));
      } else {
        const exportData: string[] = [
          row.dataI ?? "",
          row.incomes
            ? "€ " +
            Number(row.incomes).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.incomes
            ? "€ " +
            Number(row.incomes).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.e_invoices
            ? "€ " +
            Number(row.e_invoices).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
          row.total
            ? "€ " +
            Number(row.total).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
            })
            : "€ 0,00",
        ];

        data.push(exportData.join(";"));
      }
    });

    const csvString = "\uFEFF" + data.join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("hidden", "");

    a.setAttribute("href", url);

    a.setAttribute(
      "download",
      "Balance " + dayjs().format("DD-MM-YYYY") + ".csv"
    );

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);

    a.remove();

    return;
  };

  const tempDates = filters?.dates?.split(",");
  const dates =
    tempDates?.length > 0
      ? [new Date(tempDates[0]), new Date(tempDates[1])]
      : null;

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />{" "}
          Refresh
        </Button>

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={filters?.dates ? true : false}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <DateRangePickerField
                title="Creation date"
                onChange={(date) => {
                  if (date) {
                    // props.setRange([date.startDate, date.endDate]);
                    setFilters((prev) => {
                      prev.set(
                        "dates",
                        dayjs(date.startDate).format("YYYY-MM-DD HH:mm:ss") +
                        "," +
                        dayjs(date.endDate).format("YYYY-MM-DD HH:mm:ss")
                      );
                      return prev;
                    });
                  } else {
                    // props.setRange(null);
                    setFilters((prev) => {
                      prev.delete("dates");
                      return prev;
                    });
                  }
                }}
                value={dates}
              />
            </Box>
          }
        />

        <Button
          variant="outlined"
          onClick={() => props.setShowColumns(!props.showColumns)}
        >
          <img
            src={viewIcon}
            className="icon"
          />
          {props.showColumns ? "Hide columns" : "Show columns"}
        </Button>

        {!isMember && (
          <Button
            variant="outlined"
            onClick={() => handleExport()}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        client={true}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};

export default CustomToolbarBalance;
