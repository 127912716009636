import { Box, Popover } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";

interface IOther {
  data?: Array<any>;
  element?: HTMLElement | null;
}

interface IProps {
  theme: any;
  others: IOther;
  setOthers: Dispatch<SetStateAction<IOther>>;
}

const PopoverCallCenter = ({ theme, others, setOthers }: IProps) => {
  return (
    <Popover
      open={Boolean(others?.element)}
      onClose={() => setOthers({})}
      anchorEl={others?.element}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Box className="p-4 flex flex-col gap-2 overflow-x-auto">
        <Box
          key={"header"}
          sx={{ color: theme.palette.primary.main }}
          className="flex gap-4 mb-1"
        >
          <Box sx={{ minWidth: "4rem" }}>ID</Box>
          <Box sx={{ minWidth: "11rem" }}>Name</Box>
          <Box sx={{ minWidth: "11rem" }}>Owned by</Box>
          <Box sx={{ minWidth: "11rem" }}>Created at</Box>
        </Box>

        {others?.data?.map((value: any, i: number) => (
          <Box
            key={value.name + i}
            className="flex gap-4"
          >
            <Box sx={{ minWidth: "4rem" }}>{value.id}</Box>
            <Box sx={{ minWidth: "11rem" }}>{value.name}</Box>
            <Box sx={{ minWidth: "11rem" }}>{value.owned_by}</Box>
            <Box sx={{ minWidth: "11rem" }}>
              {dayjs(value.created_at).format("YYYY-MM-DD")}
            </Box>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default PopoverCallCenter;
