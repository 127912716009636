import { format } from "date-fns";
import { queryClient } from "@/main";
import { useGridApiContext } from "@mui/x-data-grid";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import SelectMultipleCheckbox, {
  IItemObject,
} from "@/components/SelectMultipleCheckbox";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import UseDownloadTable from "@/components/UseDownloadTable";
import SelectOwnedByFilter from "@/components/Crm/SelectOwnedByFilter";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";

interface INProps {
  title?: string;
  filters: any;
  fileName: string;
  isMember?: boolean;
  hideLang?: boolean;
  KEY_QUERY: Array<string>;
  setFilters: Function;
  meetingCalendar: Array<string>;
  hideUtmSource?: boolean;
  itemsUtmSource?: Array<string>;
}

export const CustomToolbar = ({
  title,
  filters,
  fileName,
  isMember,
  hideLang = false,
  KEY_QUERY,
  setFilters,
  meetingCalendar,
  hideUtmSource = true,
  itemsUtmSource,
}: INProps) => {
  const apiRef = useGridApiContext();

  const handleFilterChange = (event: {
    target: { name: string; value: string };
  }) => {
    setFilters(
      (p: any) => {
        if (event.target.value) {
          p.set(event.target.name, event.target.value);
        } else {
          p.delete(event.target.name);
        }
        return p;
      },
      { replace: true }
    );
  };

  const date = filters?.dates?.split(",");
  const dates = filters?.dates ? [new Date(date[0]), new Date(date[1])] : null;

  return (
    <Box
      id="filter_bar"
      className="flex !items-stretch gap-4"
    >
      <Button
        variant="outlined"
        onClick={() => {
          setFilters({});
          apiRef.current.setQuickFilterValues([""]);
          queryClient.invalidateQueries(KEY_QUERY);
        }}
      >
        <img
          src={refreshIcon}
          className="icon"
        />
        Refresh
      </Button>

      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={filters?.admin || filters?.dates}
        content={
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            {!hideLang && (
              <TextField
                select
                fullWidth
                name="lang"
                label="Language"
                value={filters?.lang || ""}
                onChange={handleFilterChange}
              >
                {LANGUAGES.map((lang) => (
                  <MenuItem
                    key={lang.iso}
                    value={lang.iso}
                  >
                    {lang.name}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {!hideUtmSource && (
              <SelectMultipleCheckbox
                label="Utm source"
                value={filters?.utmSource?.split(",") ?? []}
                items={
                  [
                    { label: "Blank", value: "null" },
                    ...(itemsUtmSource as Array<string>),
                  ] as Array<string | IItemObject>
                }
                onChange={(_, value) =>
                  handleFilterChange({
                    target: { name: "utmSource", value: value.join(",") },
                  })
                }
              />
            )}

            {!isMember && (
              <SelectOwnedByFilter
                title="Admin"
                items={meetingCalendar || []}
                ownedBy={filters?.admin || ""}
                onChange={(e) =>
                  handleFilterChange({
                    target: { name: "admin", value: e.target.value },
                  })
                }
              />
            )}

            <DateRangePickerField
              value={dates}
              title={title ? title : "Creation date"}
              onChange={(date) => {
                if (date) {
                  const temp =
                    format(date.startDate, "yyyy-MM-dd HH:mm:ss") +
                    "," +
                    format(date.endDate, "yyyy-MM-dd HH:mm:ss");

                  handleFilterChange({
                    target: { name: "dates", value: temp },
                  });
                }
              }}
            />
          </Box>
        }
      />

      {!isMember && <UseDownloadTable fileName={fileName} />}

      <SearchToolbar />
    </Box>
  );
};
