import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getUser,
  getAuth,
  postLogin,
  updateUser,
  searchUsers,
  getSurveyData,
  getStartMonths,
  getUserWithDates,
  downloadUserData,
  updateCourseDate,
  postUserListWithSecondPayment,
  downloadUserListWithSecondPayment,
} from "./usersApi";
import dayjs from "dayjs";

export interface IUser {
  id?: number;
  zipcode?: string;
  first_name?: string;
  phone?: string;
  city?: string;
  email?: string;
  token?: string;
  last_name?: string;
  payments?: Array<any>;
  // percorsi?: Array<any>;
  products?: Array<any>;
  address?: string;
  start_month?: string;
}

const useUserApi = () => {
  const { snackHandler, userAdmin, isAdmission } = useAuth();

  const keyCache = "users";
  const keyCacheUserList = "users-list";

  const callSearchUsers = (keyword: string) => {
    return useQuery(
      [`search-${keyCache}-${keyword}`, isAdmission],
      async () => {
        const { data }: { data: Array<IUser> } = await searchUsers(
          userAdmin.token,
          { keyword, isAdmission }
        );
        return data;
      }
    );
  };

  const callGetUser = (token: string | null) => {
    if (token) queryClient.setQueryData([keyCache, "last-params"], () => token);

    return useQuery(
      [keyCache, token],
      async () => {
        if (!token) return null;

        const data: Array<IUser> = await getUser(userAdmin.token, token);
        if (data.length > 0) return data[0];

        return null;
      },
      { keepPreviousData: true, refetchOnWindowFocus: true }
    );
  };

  const callGetUserWithDates = (token: string | null) => {
    queryClient.setQueryData([keyCache, "last-params"], () => token);

    return useQuery(
      [keyCache, token, "dates"],
      async () => {
        if (!token) return null;
        const data = await getUserWithDates(userAdmin.token, token);
        return data;
      },
      { refetchOnWindowFocus: true }
    );
  };

  const callUpdateUser = useMutation(
    (params: { id: string; data: any; callback?: (resp: any) => void }) =>
      updateUser({ ...params, token: userAdmin.token }),
    {
      onSuccess: async (value, { callback }) => {
        const id = queryClient.getQueryData([keyCache, "last-params"]);

        await queryClient.invalidateQueries([keyCache, id, "dates"]);
        await queryClient.invalidateQueries([keyCacheUserList]);
        snackHandler("User updated!");
        if (callback) callback(value);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callGetAuth = (params: any) => {
    const { token, ...rest } = params;
    return useQuery(
      ["userAdmin"],
      async () => {
        const data = await getAuth(token);
        return data;
      },
      {
        ...rest,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        enabled: Boolean(token),
      }
    );
  };

  const callLogin = useMutation((params) => postLogin(params), {
    onSuccess: async (data: any) => {
      queryClient.setQueryData(["userAdmin"], data.data);
    },
  });

  const callPostUserListWithSecondPayment = (params: any) => {
    return useQuery(
      [keyCacheUserList, params],
      async () => {
        const data = await postUserListWithSecondPayment(
          userAdmin.token,
          params
        );
        return data;
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        enabled: params.per_page > 0,
      }
    );
  };

  const callUpdateCourseDate = useMutation(
    (params: { data: any; callback?: (resp: any) => void }) =>
      updateCourseDate({ ...params }),
    {
      onSuccess: async () => {
        const id = queryClient.getQueryData([keyCache, "last-params"]);

        await queryClient.invalidateQueries([keyCache, id, "dates"]);
        await queryClient.invalidateQueries([keyCacheUserList]);

        snackHandler("User updated!");
      },
      onError: (error) => {
        snackHandler("Select a valid date", "error");
        console.error(error);
      },
    }
  );

  const callDownloadUser = async (params: object, filename: string) => {
    if (!filename) filename = `Users ${dayjs().format("DD-MM-YYYY")}.csv`;

    const rs = await downloadUserData(
      userAdmin.token,
      { ...params, export: true },
      filename
    );
    return rs;
  };

  const callDownloadNewUsers = async (params: object, filename: string) => {
    if (!filename) filename = `Users ${dayjs().format("DD-MM-YYYY")}.csv`;

    const rs = await downloadUserListWithSecondPayment(
      userAdmin.token,
      { ...params, export: true },
      filename
    );
    return rs;
  };

  const callGetStartMonths = () => {
    return useQuery(
      [keyCache, "start_months"],
      async () => {
        const { data } = await getStartMonths(userAdmin.token);
        return data;
      },
      { keepPreviousData: true, refetchOnWindowFocus: true }
    );
  };

  const callGetSurveyData = (userToken: string | null) => {
    return useQuery(
      ["survey", userToken],
      async () => {
        if (!userToken) return null;

        const data = await getSurveyData(userAdmin.token, userToken);
        return data;
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        onSuccess: (data) => { },
        onError: (error) => {
          console.error("Error fetching survey data:", error);
          snackHandler("Failed to fetch survey data", "error");
        },
      }
    );
  };

  return {
    keyCache,
    callLogin,
    callGetUser,
    callGetAuth,
    callUpdateUser,
    callSearchUsers,
    callDownloadUser,
    callGetSurveyData,
    callGetStartMonths,
    callGetUserWithDates,
    callUpdateCourseDate,
    callDownloadNewUsers,
    callPostUserListWithSecondPayment,
  };
};

export default useUserApi;
