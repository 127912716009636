import client from "@/api/client";
import { clone, filterObject } from "@/components/useHelpers";
import { queryClient } from "@/main";
import { defaultConfirmDialog } from "@/pages/Upselling/v1/Crm/interfacesAndDefaults";
import {
  defaultDialogsEl,
  defaultFormMeetingDone,
} from "@/pages/Upselling/v2/Crm/components/crm-upselling.types";
import { UpdateMeetingOtherResult } from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import { useCrmDialogsContext } from "@/pages/Upselling/v2/Crm/state/CrmDialogsContext";
import { useCrmContext } from "@/pages/Upselling/v2/Crm/state/CrmV3Context";
import useAuth from '@/hooks/useAuth';
import { GridRowsProp } from "@mui/x-data-grid";
import dayjs from "dayjs";

export const handleMeetingUpdate = async (
  selectedEvent: any,
  eventId: number | string | null,
  newStatus: string,
  rows: GridRowsProp,
  setRows: (rows: GridRowsProp) => void,
  useSetUpdate: boolean = true,
  isOtherMeeting: boolean = false,
  dependencyHooks: [
    Partial<ReturnType<typeof useAuth>>,
    Partial<ReturnType<typeof useCrmContext>>,
    Partial<ReturnType<typeof useCrmDialogsContext>>
  ]
): Promise<any> => {
  const [authState, crmContext, dialogState] = dependencyHooks;
  const { userAdmin, snackHandler } = authState;
  const { rowSelectedState, formMeetingState, KEY_QUERY } = crmContext;
  const { confirmDialogState, dialogsElState } = dialogState;
  // const { userAdmin, snackHandler } = useAuth();
  // const { rowSelectedState, formMeetingState } = useCrmContext();
  // const { confirmDialogState, dialogsElState } = useCrmDialogsContext();
  confirmDialogState?.setConfirmDialogData(defaultConfirmDialog);
  dialogsElState?.setDialogsEl(defaultDialogsEl);

  const newMeet = isOtherMeeting
    ? {
      meet: [
        {
          ...filterObject(formMeetingState?.formMeetingDone || {}, [null]),
          id: "other-meet-" + (Math.random() * 100000).toFixed(0),
          start: dayjs().startOf("minutes").format("YYYY-MM-DD HH:mm:ss"),
          end: dayjs()
            .startOf("minutes")
            .add(20, "minutes")
            .format("YYYY-MM-DD HH:mm:ss"),
          link: null,
          calendar: `${userAdmin?.user.first_name} ${userAdmin?.user.last_name}`,
        },
      ],
      summary:
        (rowSelectedState?.rowSelected?.fullName
          ? rowSelectedState.rowSelected?.fullName + " e "
          : "") + "Edusogno",
    }
    : {
      ...selectedEvent,
      ...filterObject(formMeetingState?.formMeetingDone || {}, [null]),
    };

  let res: UpdateMeetingOtherResult;

  if (isOtherMeeting) {
    res = await client.crm_upselling.updateMeetingOther({
      admin: `${userAdmin?.user.first_name} ${userAdmin?.user.last_name}`,
      status: newStatus,
      meetings: newMeet,
      token_user: rowSelectedState?.rowSelected.token,
    });
  } else {
    res = await client.crm_upselling.updateMeeting({
      token_user: rowSelectedState?.rowSelected.token,
      data: newMeet,
      admin: `${userAdmin?.user.first_name} ${userAdmin?.user.last_name}`,
      status: newStatus,
      useSetUpdate,
    });
  }

  if (!res || !res?.status) {
    snackHandler?.(res?.message ?? `Error update meet!`, "error");
    return;
  }

  const rows2 = clone(rows);
  const i = rows2.findIndex(
    (row) => row.token === rowSelectedState?.rowSelected.token
  );

  if (isOtherMeeting) {
    rows2[i].meetings = newMeet;
  } else {
    const indexMeet = rows2[i].crm_upselling.meetings.meet.findIndex(
      (f: any) => f.id === newMeet.id
    );
    const meets = rows2[i].crm_upselling.meetings.meet[indexMeet];
    rows2[i].crm_upselling.meetings.meet[indexMeet] = { ...meets, ...newMeet };
  }

  rows2[i].crm_upselling.status = newStatus;

  setRows(rows2);
  queryClient.invalidateQueries([
    "upselling",
    "crm",
    rowSelectedState?.rowSelected.token,
  ]);
  queryClient.invalidateQueries(KEY_QUERY);
  snackHandler?.(`Update meet and new status "${newStatus}"`);
  formMeetingState?.setFormMeetingDone(defaultFormMeetingDone);

  return rows2[i];
};
