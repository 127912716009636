import React, { useEffect, useState } from 'react';
import { Box, Card } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, CategoryScale, DoughnutController } from 'chart.js';

Chart.register(DoughnutController, ArcElement, CategoryScale);

const Legend = ({labels, colors}) => (
    <div>
        {labels.map((label, index) => (
            <div className='flex items-center pb-1' key={index}>
                <span style={{backgroundColor: colors[index], display: 'inline-block', width: '15px', height: '15px', borderRadius: '50%', marginRight: '0.5rem'}}></span>
                <span className='text-[10px] text-eduV2-1000 max-w-[120px]'>{label}</span>
            </div>
        ))}
    </div>
)

const NegativeComments = ({ data }) => {
    const [chartData, setChartData] = useState<{
        labels: string[];
        datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[];
    } | null>(null);
        
    useEffect(() => {
        const reasonsCount = {};
        data.events.forEach(event => {
            event.feedbackUsers.forEach(feedback => {
              if (feedback.improvements) {
                const improvements = JSON.parse(feedback.improvements);
                if (improvements.hasOwnProperty('Tutor')) {
                  improvements['Tutor'].forEach(reason => {
                    reasonsCount[reason] = reasonsCount[reason] ? reasonsCount[reason] + 1 : 1;
                  });
                }
              }
            });
          });          

        const sortedReasons = Object.entries(reasonsCount).sort((a, b) => (b[1] as number) - (a[1] as number));
        const top5Reasons = sortedReasons.slice(0, 5);

        const labels = top5Reasons.map(([reason,]) => reason);
        const values = top5Reasons.map(([,count]) => count as number);


        const colors = ['#7B4DE1', '#9078F3', '#9F9DFF', '#B9BCFF', '#D9DAF3'];
        setChartData({
            labels: labels,
            datasets: [{
                data: values,
                backgroundColor: colors,
                hoverBackgroundColor: colors
            }]
        });
    }, [data]);


    if (!chartData) {
        return null;  
    }

    const options = {
      cutout: '75%', 
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    return (
        <Card >
            <Box height={210} p={2}>
                <div className="text-eduV2-1000 text-sm font-semibold leading-[13.93px] pb-4">
                    Reasons negative comments 
                </div>
                <Box className='flex justify-around items-center'>
                    <div style={{maxWidth: '140px', maxHeight: '140px'}}>
                        <Doughnut data={chartData} options={options}/>
                    </div>
                    <Legend labels={chartData.labels} colors={chartData.datasets[0].backgroundColor} />
                </Box>
            </Box>
        </Card>
    );
};

export default NegativeComments;
