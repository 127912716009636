import { FormControl, Select, MenuItem } from "@mui/material";
import { STATUS } from "../helpers/constants";

const StatusCell = ({ row, handleUpdateRow }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    handleUpdateRow({
      toast: "status",
      values: { status: value },
      tokenUser: row.token,
    });
  };

  const currentStatus = row?.crm_upselling?.status ?? "New";

  return (
    <FormControl
      className="w-full"
      size="small"
    >
      <Select
        name="status"
        value={currentStatus}
        onChange={handleChange}
      >
        {Object.values(STATUS).map((status, index) => (
          <MenuItem
            key={`${status}-${index}`}
            value={status}
          >
            {status}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusCell;
