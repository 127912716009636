import { forwardRef, useImperativeHandle, useState } from "react";
import { Summarize } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import MyTooltip from "@/components/MyTooltip";
import CustomPagination from "@/components/_Common/CustomPagination";

export interface IRefClassreport {
  open: (user: any) => void;
}

interface IProps {}

const Classreport = forwardRef<IRefClassreport, IProps>((_, ref) => {
  const theme = useTheme();

  const [user, setUser] = useState<any>();

  useImperativeHandle(ref, () => ({
    open: (user) => setUser(user),
  }));

  const columns: GridColDef[] = [
    {
      field: "created_at",
      headerName: "Created",
      width: 100,
      valueGetter: ({ row }) =>
        dayjs(row?.created_at).format("DD/MM HH:mm") || "",
    },
    {
      field: "tutor",
      headerName: "Tutor",
      minWidth: 180,
      flex: 0.5,
      valueGetter: ({ row }) => row?.tutor?.fullName || "",
      renderCell: ({ row }) => <MyTooltip>{row?.tutor?.fullName}</MyTooltip>,
    },
    {
      field: "event",
      headerName: "Event",
      minWidth: 180,
      flex: 0.5,
      valueGetter: ({ row }) => row?.event?.titolo || "",
      renderCell: ({ row }) => <MyTooltip>{row?.event?.titolo}</MyTooltip>,
    },
    {
      field: "reason",
      headerName: "Reason",
      minWidth: 180,
      flex: 0.5,
      renderCell: ({ row }) =>
        Boolean(row?.reason) ? <MyTooltip>{row?.reason}</MyTooltip> : "",
    },
    {
      field: "excels_in",
      headerName: "Excels in",
      minWidth: 180,
      flex: 0.5,
      renderCell: ({ row }) => {
        const excels_in = row?.excels_in
          ? typeof row?.excels_in == "string"
            ? JSON.parse(row?.excels_in)
            : row?.excels_in
          : null;

        return Boolean(excels_in) ? (
          <MyTooltip
            title={
              <Box>
                {excels_in?.map((e: string, i: number) => (
                  <Box key={e + i}>{e}</Box>
                ))}
              </Box>
            }
          >
            {excels_in?.join(", ")}
          </MyTooltip>
        ) : (
          ""
        );
      },
    },
    {
      field: "improvement",
      headerName: "Improvement",
      minWidth: 180,
      flex: 0.5,
      renderCell: ({ row }) => {
        const improvement = row?.improvement
          ? typeof row?.improvement == "string"
            ? JSON.parse(row?.improvement)
            : row?.improvement
          : null;

        return Boolean(improvement) ? (
          <MyTooltip
            title={
              <Box>
                {improvement?.map((e: string, i: number) => (
                  <Box key={e + i}>{e}</Box>
                ))}
              </Box>
            }
          >
            {improvement?.join(", ")}
          </MyTooltip>
        ) : (
          ""
        );
      },
    },
    {
      field: "suggestions",
      headerName: "Suggestions",
      minWidth: 180,
      flex: 0.5,
      renderCell: ({ row }) =>
        Boolean(row?.suggestions) ? (
          <MyTooltip>{row?.suggestions}</MyTooltip>
        ) : (
          ""
        ),
    },
    {
      field: "recommended_level",
      headerName: "Recommended level",
      minWidth: 180,
      flex: 0.5,
      renderCell: ({ row }) =>
        Boolean(row?.recommended_level) ? (
          <MyTooltip>{row?.recommended_level}</MyTooltip>
        ) : (
          ""
        ),
    },
  ];

  return (
    <EduModal
      fullWidth
      open={Boolean(user)}
      onClose={() => setUser(undefined)}
      maxWidth="xl"
    >
      <EduModalTitle onClose={() => setUser(undefined)}>
        <Summarize className="pr-2" />
        Classreport - {user?.fullName}
      </EduModalTitle>

      <EduModalBody className="p-5 pt-7">
        <DataGrid
          autoHeight
          disableColumnMenu
          disableVirtualization
          disableSelectionOnClick
          hideFooterSelectedRowCount
          sx={getTableStyling(theme)}
          rows={user?.tutors_reports || []}
          columns={columns}
          pageSize={10}
          components={{ Pagination: CustomPagination }}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: false }}
          hideFooter={Boolean(user?.tutors_reports?.length < 10)}
        />
      </EduModalBody>
    </EduModal>
  );
});

export default Classreport;
