import { useState } from "react";
import useAuth from '@/hooks/useAuth';
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Badge, Box, Button, useTheme } from "@mui/material";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import MyTooltip from "@/components/MyTooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const getIeltsScore = (score: number) => {
  const ielts = {
    "39-40": 9,
    "37-38": 8.5,
    "35-36": 8,
    "32-34": 7.5,
    "30-31": 7,
    "26-29": 6.5,
    "23-25": 6,
    "18-22": 5.5,
    "16-17": 5,
    "13-15": 4.5,
    "11-12": 4,
    "0-10": 0,
  };

  for (const [range, value] of Object.entries(ielts)) {
    const rangeValues = range.split("-");

    if (score >= Number(rangeValues[0]) && score <= Number(rangeValues[1])) {
      return value;
    }
  }
};

const index = ({ user, loading }) => {
  const theme = useTheme();
  const { snackHandler } = useAuth();

  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [exerciseDoneForDelete, setExerciseDoneForDelete] = useState<any>();
  const [exerciseNOTDoneForDelete, setExerciseNOTDoneForDelete] =
    useState<any>();

  const handleDeleteExerciseNOTDone = async () => {
    setOpenConfirmDelete(false);

    const res = await useAPI(
      `/v1/users/exercise/delete`,
      exerciseNOTDoneForDelete
    );

    if (!res?.status) {
      snackHandler("Failed delete!", "error");
      return;
    }

    snackHandler("Success delete");
    setExerciseNOTDoneForDelete(undefined);

    user.exercises_with_done = user.exercises_with_done.filter(
      (e: any) =>
        e?.id_exercise_not_done != exerciseNOTDoneForDelete?.id_exercise
    );
  };

  const handleDeleteExerciseDone = async () => {
    setOpenConfirmDelete(false);

    const res = await useAPI(
      `/v1/users/${exerciseDoneForDelete.user_id}/destroy/${exerciseDoneForDelete.token_ex}`,
      exerciseDoneForDelete
    );

    if (!res?.status) {
      snackHandler("Failed delete!", "error");
      return;
    }

    snackHandler("Success delete");
    setExerciseDoneForDelete(undefined);

    user.exercises_with_done = user.exercises_with_done.filter(
      (e: any) => e?.token_ex != exerciseDoneForDelete?.token_ex
    );
  };

  const columns: GridColDef[] = [
    {
      field: "test_name",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row.test_name}</MyTooltip>,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "data",
      headerName: "Completion date",
      minWidth: 150,
      flex: 0.5,
      renderCell: ({ row }) => {
        if (!row.data) return "-";

        const date = dayjs(row.data).startOf("day");
        const toDoBy = dayjs(row.timer);
        const dateF = date.format("YYYY-MM-DD");

        if (toDoBy <= date) {
          return (
            <Box className="flex items-center">
              <Badge
                variant="dot"
                className="pr-2"
                componentsProps={{
                  badge: {
                    style: {
                      backgroundColor: toDoBy < date ? "#ff5b5b" : "#faaf00",
                    },
                  },
                }}
              >
                {dateF}
              </Badge>
            </Box>
          );
        }

        return (
          <Box className="flex items-center">
            <Badge
              variant="dot"
              className="pr-2"
              componentsProps={{
                badge: {
                  style: {
                    backgroundColor: "#10b981",
                  },
                },
              }}
            >
              {dateF}
            </Badge>
          </Box>
        );
      },
    },
    {
      field: "user_score",
      headerName: "Score",
      minWidth: 80,
      flex: 0.25,
      renderCell: ({ row }) => {
        if (!row.user_score) return "-";
        if (row.type === "Writing" || row.type === "Speaking") return row.user_score;
        return getIeltsScore(Number(row.user_score.split("/")[0]));
      },
    },
    {
      field: "timer",
      headerName: "To do by",
      minWidth: 130,
      flex: 0.5,
      renderCell: ({ row }) => row.timer,
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Button
          variant="outlined"
          onClick={() => {
            if (
              typeof row?.id_exercise_not_done == "number" &&
              row?.id_exercise_not_done > -1 &&
              row?.id_event != undefined
            ) {
              setExerciseNOTDoneForDelete({
                user_token: user?.token,
                id_event: row?.id_event,
                id_exercise: row?.id_exercise_not_done,
              });
            } else {
              setExerciseDoneForDelete({
                user_id: user?.id,
                token_ex: row?.token_ex,
              });
            }
            setOpenConfirmDelete(true);
          }}
        >
          <DeleteIcon color="primary" />
        </Button>
      ),
    },
  ];

  return (
    <Box style={{ maxHeight: "60vh", overflowY: "auto" }}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={user?.exercises_with_done || []}
        loading={loading}
        columns={columns}
        pageSize={8}
        components={{
          Toolbar: () => (
            <Box className="px-3">
              Expired exercises: {user?.countExpiresExercise}
            </Box>
          ),
          Pagination: CustomPaginationClient,
        }}
        getRowId={(row) => row.idk}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        rowsPerPageOptions={[8]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <ConfirmationDialog
        open={Boolean(
          openConfirmDelete &&
          (exerciseDoneForDelete || exerciseNOTDoneForDelete)
        )}
        onClose={() => {
          setOpenConfirmDelete(false);
          setExerciseDoneForDelete(undefined);
          setExerciseNOTDoneForDelete(undefined);
        }}
        onConfirm={async () => {
          if (exerciseDoneForDelete) {
            handleDeleteExerciseDone();
          }

          if (exerciseNOTDoneForDelete) {
            handleDeleteExerciseNOTDone();
          }
        }}
      >
        Do you want to delete this exercise?
      </ConfirmationDialog>
    </Box>
  );
};

export default index;
