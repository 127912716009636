import { useMutation, useQuery } from "@tanstack/react-query";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "../main";
import {
  updateProduct,
  createProduct,
  deleteProduct,
  getProducts,
} from "./productsApi";

// Generated by https://quicktype.io

export interface ICreateError {
  message: string;
  errors: object;
}


const useProductApi = () => {
  const { snackHandler, userAdmin, isAdmission } = useAuth();

  const keyCache = [`${isAdmission ? "admission-" : ""}products`];

  const callGetProducts = (callback?: (data: any) => void, all = false) => {
    return useQuery(
      keyCache,
      async () => {
        const rs = await getProducts(userAdmin.token, {
          tutoring: Number(isAdmission),
          all: all
        });
        if (callback) callback(rs);
        return rs;
      },
      { keepPreviousData: true, refetchOnWindowFocus: true }
    );
  };

  const callDeleteProduct = useMutation(
    (params: { token: string; prod_tk: string; callback: () => void }) =>
      deleteProduct(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(keyCache);
        snackHandler("Product deleted!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callUpdateProduct = useMutation(
    (params: { token: string; formData: any; callback: () => void, prod_tk: string }) =>
      updateProduct(params.token, params.formData, params.callback, params.prod_tk),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(keyCache);
        snackHandler("Product updated!");
      },
      onError: (error) => {
        console.error(error);
        snackHandler("Some issue occured", "error");
      },
    }
  );

  const callHideProduct = useMutation(
    (params: { token: string; callback: () => void, prod_tk: string }) => {
      const formData = new FormData();
      formData.append("hidden", "true");
      return updateProduct(params.token, formData, params.callback, params.prod_tk)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(keyCache);
        snackHandler("Product hidden!");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const callCreateProduct = useMutation(
    (params: { token: string; formData: any; callback: () => void }) =>
      createProduct(params.token, params.formData, params.callback),
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(keyCache);
        snackHandler("Product created!");
      },
      onError: (error) => {
        snackHandler("Some error occurred", "error");
      },
    }
  );

  const invalidateProducts = () => {
    queryClient.invalidateQueries(keyCache);
  };

  return {
    callGetProducts,
    callCreateProduct,
    callUpdateProduct,
    callDeleteProduct,
    callHideProduct,
    invalidateProducts,
  };
};

export default useProductApi;
