import BasePage from "@/components/Page/Base";
import ListEvents from "./component/ListEvents";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";

export const TO_SELECT_CHANGE_EVENT = "Speaking";
export const TIME_DURATION_EVENT = [8, 21];
export const TYPE = [
  "IELTS",
  "Grammar",
  "Speaking",
  "Mock test",
  "Correction",
  "One to One",
];
export const VALUE_SELECT_LEVEL = ["Basic", "Intermediate", "Advance"];
export const SUBTYPE = [...VALUE_SELECT_LEVEL, ...ENGLISH_LEVELS];

const Events = () => (
  <BasePage title="All Events">
    <ListEvents />
  </BasePage>
);

export default Events;
