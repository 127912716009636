import { format } from "date-fns";
import { INUserCrmCall } from "@/pages/Crm/CrmCall";
import { INUserMeeting } from "@/components/Crm/MeetingComponent";

export function formatUserCrmCall(rowSelected: any): INUserCrmCall {
  return {
    first_name: rowSelected.first_name,
    email: rowSelected.email,
    phone: rowSelected.phone,
    token: rowSelected.token,
    status: rowSelected.crm_upselling?.status || "New",
    answers: undefined,
    last_name: rowSelected.last_name,
    updates: rowSelected.crm_upselling?.updates || undefined,
    details: rowSelected.crm_upselling?.details || undefined,
    meetings: rowSelected.crm_upselling?.meetings,
    created_at:
      rowSelected.crm_upselling?.created_at ||
      format(new Date(), "dd/MM/yyyy HH:mm:ss"),
    lang: rowSelected.lang,
    tutoring: rowSelected.tutoring,
  };
}

export function formatUserCrmMeeting(rowSelected: any): INUserMeeting {
  return {
    id: rowSelected.id,
    first_name: rowSelected.first_name,
    phone: rowSelected.phone,
    email: rowSelected.email,
    token: rowSelected.token,
    status: rowSelected.crm_upselling?.status || "New",
    last_name: rowSelected.last_name,
    updates: rowSelected.crm_upselling?.updates || undefined,
    tutoring: rowSelected.tutoring,
    meeting: rowSelected.crm_upselling?.meetings || undefined,
    meetings: rowSelected.crm_upselling?.meetings || undefined,
    lang: rowSelected.lang,
  };
}
