import BaseAxios from "axios";
const BASE_URL = import.meta.env.VITE_USER_BASE_URL; // api/user/
import { queryClient } from "@/main";

class Request {
  axios;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
  }

  async call(config, token = null) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...config.headers,
    };

    if (token) headers["Authorization"] = `Bearer ${token}`;

    delete config.headers;

    const result = this.axios({
      baseURL: BASE_URL,
      headers,
      ...config,
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // queryClient.invalidateQueries(["userAdmin"]);
          throw "Unauthorized";
        }
        return { error };
      });

    return result;
  }

  async download(config, token = null, fileName) {
    let headers = {
      "Content-Type": "application/json",
      responseType: "blob",
      ...config.headers,
    };

    if (token) headers["Authorization"] = `Bearer ${token}`;

    delete config.headers;

    const response = await this.axios.request({
      baseURL: BASE_URL,
      headers,
      ...config,
    });

    let blob = new Blob([response.data], {
      type: response.headers["Content-type"],
    });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    return true;
  }
}

export default new Request();
