import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from '@/hooks/useAuth';
import {
  getListDayOffs,
  postUpdateStatusDayOffs,
  postCreateDayOff,
  getListAdminHaveDayOffs,
} from "@/api/dayOffApi";

export interface IAdmin {
  first_name: string;
  last_name: string;
  id: number;
  uuid: string;
  avatar: string;
}

export interface IDayOff {
  admin: IAdmin;
  admin_id: number;
  created_at: string;
  updated_at: string;
  id: number;
  start_time: string;
  end_time: string;
  status: number | string;
  reason: string;
  date?: string;
  month?: string;
}

const useDayOffApi = () => {
  const { userAdmin } = useAuth();
  const queryClient = useQueryClient();

  const keyCache = "day-offs";

  const callGetListDayOffs = (params = {}) => {
    queryClient.setQueryData([keyCache, "last-params"], () => params);
    return useQuery(
      [keyCache, params],
      async () => {
        const rs: IDayOff[] = await getListDayOffs(userAdmin.token, params);
        return rs;
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
      }
    );
  };

  const callUpdateStatusDayOff = async (
    id: number,
    status: "approved" | "denied"
  ) => {
    try {
      const response = await postUpdateStatusDayOffs(
        userAdmin.token,
        id,
        status
      );
      const params = queryClient.getQueryData([keyCache, "last-params"]);
      queryClient.invalidateQueries([keyCache, params]);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const callCreateDayOff = async (data) => {
    try {
      const response = await postCreateDayOff(userAdmin.token, data);
      const params = queryClient.getQueryData([keyCache, "last-params"]);
      queryClient.invalidateQueries([keyCache, params]);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const callGetListAdminHaveDayOffs = () => {
    return useQuery(
      [keyCache, "list-admin"],
      async () => {
        const rs: IAdmin[] = await getListAdminHaveDayOffs(userAdmin.token);
        return rs;
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
      }
    );
  };

  return {
    keyQuery: [keyCache, "last-params"],
    callGetListAdminHaveDayOffs,
    callGetListDayOffs,
    callUpdateStatusDayOff,
    callCreateDayOff,
  };
};

export default useDayOffApi;
