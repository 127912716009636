import { useState } from "react";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import SelectStatusFilter from "@/components/Crm/SelectStatusFilter";
import SelectOwnedByFilter from "@/components/Crm/SelectOwnedByFilter";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";
import { useCrmContext } from "../state/CrmV3Context";
import client from "@/api/client";

interface IProps {
  status: Array<string>;
  filters: any;
  setPage: any;
  owned_by: Array<string>;
  endMonth: Array<string>;
  KEY_QUERY: Array<any>;
  setFilters: any;
  snackHandler?: any;
  newStartingMonth: Array<string> | undefined;
}

export interface INFilters {
  search?: string;
  status?: string;
  origins?: string;
  owned_by?: string;
  endMonth?: string;
  rangeDates?: string;
  newStartingMonth?: string;
  upsellingResponse?: string;
}

const Toolbar = ({
  status,
  filters,
  setPage,
  owned_by,
  endMonth,
  setFilters,
}: IProps): JSX.Element => {
  const { isMember, snackHandler } = useAuth();
  const { KEY_QUERY } = useCrmContext();
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false);

  const LANGUAGE_OPTIONS: { [key: string]: string } = {
    it: "Italian",
    es: "Spanish",
    pt: "Portuguese",
    fr: "French",
    de: "German",
    en: "English",
  };

  const handleDownload = async () => {
    setDownloadDisabled(true);

    const res = await client.crm_upselling.list({ filters, download: true });

    setDownloadDisabled(false);

    if (!res) {
      snackHandler("Error download!", "error");
      return;
    }

    let dates = "";

    if (filters?.rangeDates?.length) {
      filters?.rangeDates.forEach((date: string) => {
        dates += `${format(new Date(date), "dd-MM-yyyy")} `;
      });

      dates = dates.trim();

      const partsDates = dates.split(" ");

      if (partsDates[0] === partsDates[1]) {
        dates = partsDates[0];
      } else {
        dates = dates.replace(" ", " - ");
      }
    }

    const blob = new Blob([res as Blob]);
    const link = document.createElement("a");
    const fileName = `CRM Upselling ${dates ? dates : format(new Date(), "dd-MM-yyyy")
      }.csv`;

    // Remove any links at the end of "nome_percorso" before setting the download attribute
    const cleanedFileName = fileName.replace(/\s*<a\b[^>]*>(.*?)<\/a>\s*$/, "");

    link.href = window.URL.createObjectURL(blob);
    link.download = cleanedFileName;
    link.click();

    snackHandler("Success download!");
  };

  const handleOutput = (name: string, value: string | null) => {
    setFilters(
      (p: any) => {
        if (value === null) {
          p.delete(name);
        } else {
          p.set(name, value);
        }

        return p;
      },
      { replace: true }
    );

    setPage(1);
  };

  const rangeDates = filters?.rangeDates
    ? filters?.rangeDates.split(",")
    : null;

  return (
    <>
      <Box
        id="filter_bar"
        className="flex gap-4 flex-wrap !items-stretch"
      >
        <FiltersBar>
          <Button
            title="Reset/Sync"
            variant="outlined"
            onClick={() => {
              setFilters({});
              queryClient.invalidateQueries(KEY_QUERY);
            }}
          >
            <img
              src={refreshIcon}
              className="icon"
            />
            Refresh
          </Button>

          <Dropdown
            active={
              !!Object.keys(filters).filter(
                (f) => !["page", "sorts", "search"].includes(f)
              ).length
            }
            text="Filter"
            icon={filterIcon}
          >
            <Box className="bg-edu-800 flex flex-col w-[250px] gap-5 p-5">
              <SelectStatusFilter
                // useRemove
                items={status}
                status={filters?.status || ""}
                onChange={(e: any) => handleOutput("status", e.target.value)}
              />

              <TextField
                select
                fullWidth
                name="status"
                label="End month"
                value={filters?.endMonth || ""}
                onChange={(e: any) => handleOutput("endMonth", e.target.value)}
              >
                {/* <MenuItem value="">- Remove -</MenuItem> */}

                {endMonth?.map((month: string, i: number) => (
                  <MenuItem
                    key={month + i}
                    value={month}
                  >
                    {month}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                fullWidth
                name="languages"
                label="Language"
                value={filters?.languages || ""}
                onChange={(e: any) => handleOutput("lang", e.target.value)}
              >
                <MenuItem value="">- Select -</MenuItem>
                {Object.entries(LANGUAGE_OPTIONS).map(([code, name]) => (
                  <MenuItem
                    key={code}
                    value={code}
                  >
                    {name}
                  </MenuItem>
                ))}
              </TextField>

              <SelectOwnedByFilter
                // useRemove
                items={owned_by}
                ownedBy={filters?.owned_by || ""}
                onChange={(e: any) => handleOutput("owned_by", e.target.value)}
              />

              <DateRangePickerField
                title="Meeting date"
                value={
                  rangeDates
                    ? [new Date(rangeDates[0]), new Date(rangeDates[1])]
                    : null
                }
                onChange={(date) => {
                  if (date) {
                    let d = [
                      format(date.startDate, "yyyy-MM-dd HH:mm:ss"),
                      format(date.endDate, "yyyy-MM-dd HH:mm:ss"),
                    ].join(",");

                    handleOutput("rangeDates", d);
                  } else {
                    handleOutput("rangeDates", null);
                  }
                }}
              />

              <TextField
                select
                fullWidth
                name="upsellingResponse"
                label="Upselling Response"
                value={filters?.upsellingResponse || ""}
                onChange={(e: any) =>
                  handleOutput("upsellingResponse", e.target.value)
                }
              >
                <MenuItem value="">- Select -</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </Box>
          </Dropdown>

          {!isMember && (
            <Button
              title="Download"
              color="primary"
              variant="outlined"
              onClick={handleDownload}
              disabled={downloadDisabled}
            >
              <img
                src={downloadIcon}
                className="icon"
              />
              Download
            </Button>
          )}
        </FiltersBar>

        <SearchToolbar
          setFilters={setFilters}
          filters={filters}
        />
      </Box>
    </>
  );
};

export default Toolbar;
