import addIcon from "@/assets/icons/add.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import { Box, Button } from "@mui/material";

const Toolbar = ({ setOpenDialogTollbar, ...rest }) => {
  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <Button
        variant="outlined"
        onClick={() => setOpenDialogTollbar(true)}
      >
        <img
          src={addIcon}
          className="icon"
        />{" "}
        Add new article
      </Button>

      <SearchToolbar
        {...rest}
        client={false}
      />
    </Box>
  );
};

export default Toolbar;
