import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Box,
  Typography,
  Switch,
  Button,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAPI from "@/api/useAPI";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import saveIcon from "@/assets/icons/save.svg";

interface AddCallCenterDialogProps {
  open: boolean;
  onClose: () => void;
}

const languageMap = {
  en: "English",
  it: "Italian",
  es: "Spanish",
  fr: "French",
  de: "German",
  pt: "Portuguese",
};

const typeMap = {
  "Never booked": "Never booked",
  "No show": "No show",
  Upselling: "Upselling",
  Absent: "Absent",
  "Low rating": "Low rating",
  Review: "Review",
  "Still to pay": "Still to pay",
  Suspended: "Suspended",
  Recruiting: "Recruiting",
};

const cacheKey = ["callCenter"];

const AddCallCenterDialog: React.FC<AddCallCenterDialogProps> = ({
  open,
  onClose,
}) => {
  const [newCallCenter, setNewCallCenter] = useState({
    token: "",
    languages: [] as string[],
    types: [] as string[],
  });
  const [admins, setAdmins] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const fetchAdmins = async () => {
    setLoading(true);
    const response = await useAPI("v1/call-center/admins", {}, { type: "get" });
    setAdmins(response);
    setLoading(false);
  };

  const createCallCenter = useMutation(
    async (newCallCenter: {
      token: string;
      languages: string[];
      types: string[];
    }) => {
      const response = await useAPI("v1/call-center/create", newCallCenter, {
        type: "post",
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createCallCenter.mutate(newCallCenter);
    onClose();
    setNewCallCenter({ token: "", languages: [], types: [] });
  };

  const handleOpen = () => {
    fetchAdmins();
  };

  useEffect(() => {
    if (open) {
      handleOpen();
    }
  }, [open]);

  return (
    <EduModal
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <EduModalTitle>Add Call Center</EduModalTitle>
      <EduModalBody>
        <form
          className="m-4"
          onSubmit={handleSubmit}
        >
          <TextField
            select
            label="Token"
            value={newCallCenter.token}
            onChange={(e) =>
              setNewCallCenter({ ...newCallCenter, token: e.target.value })
            }
            fullWidth
            margin="normal"
            required
            disabled={loading}
          >
            {admins.map((admin) => (
              <MenuItem
                key={admin.uuid}
                value={admin.uuid}
              >
                {`${admin.first_name} ${admin.last_name}`}
              </MenuItem>
            ))}
          </TextField>
          <Box>
            <Typography variant="h6">Languages</Typography>
            {Object.entries(languageMap).map(([key, label]) => (
              <Box
                key={key}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">{label}</Typography>
                <Switch
                  checked={newCallCenter.languages.includes(key)}
                  onChange={() => {
                    const newLanguages = newCallCenter.languages.includes(key)
                      ? newCallCenter.languages.filter((lang) => lang !== key)
                      : [...newCallCenter.languages, key];
                    setNewCallCenter({
                      ...newCallCenter,
                      languages: newLanguages,
                    });
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box>
            <Typography variant="h6">Types</Typography>
            {Object.entries(typeMap).map(([key, label]) => (
              <Box
                key={key}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">{label}</Typography>
                <Switch
                  checked={newCallCenter.types.includes(label)}
                  onChange={() => {
                    const newTypes = newCallCenter.types.includes(label)
                      ? newCallCenter.types.filter((type) => type !== label)
                      : [...newCallCenter.types, label];
                    setNewCallCenter({ ...newCallCenter, types: newTypes });
                  }}
                />
              </Box>
            ))}
          </Box>
          <DialogActions>
            <Button
              variant="outlined"
              className="!mt-4"
              type="submit"
            >
              <img
                src={saveIcon}
                className="icon"
              />
              Save
            </Button>
          </DialogActions>
        </form>
      </EduModalBody>
    </EduModal>
  );
};

export default AddCallCenterDialog;
