import { forwardRef, useImperativeHandle, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import {
  EditAttributes,
  MenuBook,
  ThumbsUpDown,
  Visibility,
  Workspaces,
} from "@mui/icons-material";

export interface IVersionExercisesRef {
  open: (props: any, version: string, title?: string) => void;
}

interface IProps {
  watchOptionsRef?: any;
}

const VersionExercises = forwardRef<IVersionExercisesRef, IProps>(
  ({ watchOptionsRef }, ref) => {
    const theme = useTheme();
    const { perPage } = useScreen();

    const [data, setData] = useState<Array<any>>([]);
    const [title, setTitle] = useState<string>("");
    const [version, setVersion] = useState<string>("");

    useImperativeHandle(ref, () => ({
      open: (data, version, title) => {
        setData(data);
        setVersion(version);
        if (title) setTitle(title);
      },
    }));

    const handleClose = () => {
      setData([]);
    };

    const columns: GridColDef[] = [
      ...(version == "multiple_choice_true_false"
        ? [
            {
              field: "course_exercises_id",
              headerName: "ID Exercise",
              width: 100,
            },
            {
              field: "question",
              headerName: "Question",
              minWidth: 150,
              flex: 1,
              renderCell: ({ row }) => <MyTooltip>{row?.question}</MyTooltip>,
            },
            {
              field: "type",
              headerName: "Type",
              minWidth: 150,
              flex: 1,
            },
            {
              field: "actions",
              headerName: "",
              width: 65,
              hideable: false,
              sortable: false,
              editable: false,
              filterable: false,
              disableExport: true,
              renderCell: ({ row }) => (
                <Button
                  title="Watch options"
                  variant="outlined"
                  onClick={() =>
                    watchOptionsRef?.current?.open(row?.options, row?.question)
                  }
                >
                  <Visibility />
                </Button>
              ),
            },
          ]
        : []),
      ...(version == "matching_option_gap_fill"
        ? [
            {
              field: "course_exercises_id",
              headerName: "ID Exercise",
              width: 100,
            },
            {
              field: "statement",
              headerName: "Statement",
              minWidth: 200,
              flex: 1,
              renderCell: ({ row }) => <MyTooltip>{row?.statement}</MyTooltip>,
            },
            {
              field: "type",
              headerName: "Type",
              minWidth: 150,
              flex: 0.5,
            },
            {
              field: "correct_answer",
              headerName: "Correct answer",
              minWidth: 200,
              flex: 1,
              renderCell: ({ row }) => (
                <MyTooltip
                  title={row?.correct_answer
                    .split(",")
                    .map((word: string, i: number) => (
                      <Box key={word + i}>{word}</Box>
                    ))}
                >
                  {row?.correct_answer.replaceAll(",", ", ")}
                </MyTooltip>
              ),
            },
          ]
        : []),
      ...(version == "groupings"
        ? [
            {
              field: "course_exercises_id",
              headerName: "ID Exercise",
              width: 100,
            },
            {
              field: "word",
              headerName: "Word",
              minWidth: 150,
              flex: 1,
            },
            {
              field: "category",
              headerName: "Category",
              minWidth: 150,
              flex: 1,
            },
          ]
        : []),
      ...(version == "dictionary"
        ? [
            {
              field: "id",
              headerName: "ID",
              width: 50,
            },
            {
              field: "value",
              headerName: "Value",
              minWidth: 150,
              flex: 0.5,
              renderCell: ({ row }) => <MyTooltip>{row?.value}</MyTooltip>,
            },
            {
              field: "definition",
              headerName: "Definition",
              minWidth: 200,
              flex: 1,
              renderCell: ({ row }) => <MyTooltip>{row?.definition}</MyTooltip>,
            },
          ]
        : []),
    ];

    return (
      <EduModal
        fullWidth
        open={Boolean(data?.length)}
        maxWidth="xl"
      >
        <EduModalTitle onClose={handleClose}>
          {version == "multiple_choice_true_false" && (
            <ThumbsUpDown fontSize="small" />
          )}
          {version == "matching_option_gap_fill" && (
            <EditAttributes fontSize="small" />
          )}
          {version == "groupings" && <Workspaces fontSize="small" />}
          {version == "dictionary" && <MenuBook fontSize="small" />}
          <span style={{ marginLeft: "0.8rem" }}>{title}</span>
        </EduModalTitle>

        <EduModalBody className="p-4">
          <DataGrid
            disableColumnMenu
            disableSelectionOnClick
            sx={getTableStyling(theme)}
            rows={data || []}
            columns={columns}
            pageSize={perPage}
            getRowId={(row) => row.id}
            autoHeight={true}
            components={{
              Toolbar: () => (
                <Box className="w-full flex">
                  <SearchToolbar />
                </Box>
              ),
              Pagination: CustomPaginationClient,
            }}
            rowsPerPageOptions={[perPage]}
            experimentalFeatures={{ newEditingApi: false }}
          />
        </EduModalBody>
      </EduModal>
    );
  }
);

export default VersionExercises;
