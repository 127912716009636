import Request from "./RequestAdmin";

export const getEventsPrsencesStats = async (token, params, signal) => {
  const { data } = await Request.call({
    url: `/v1/statistics/events`,
    method: "POST",
    data: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

  return data;
};
