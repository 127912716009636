import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { Box, Button } from "@mui/material";
import { TableStyling } from "@/dummy";
import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import ToolbarADS from "./ToolbarADS";
import RefreshIcon from "@mui/icons-material/Refresh";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const KEY_QUERY = ["ads-performance"];
const KEY_QUERY_RESET = ["ads-performance-reset"];

const HTTP_BASE = "/v1/overview";
const HTTP_NEW_ROWS = HTTP_BASE + "/new-rows";

const formatNumber = (v: number) => new Intl.NumberFormat("it-IT", { style: "decimal" }).format(v);
const formatCurrencies = (v: number) => new Intl.NumberFormat("it-IT", { style: "currency", currency: "EUR" }).format(v);

const transitionRotate = (action: boolean) => ({ transition: "all .2s linear", transform: action ? "rotate(180deg)" : "rotate(0deg)" });

const ADS = () => {
  const { isMobile, screen } = useScreen();

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [range, setRange] = useState<Array<Date>>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedUsersID, setSelectedUsersID] = useState<Array<any>>([]);
  const [resetDataDisabled, setResetDataDisabled] = useState<boolean>(false);

  const { data, isLoading } = useQuery(KEY_QUERY, () => useAPI(HTTP_BASE));

  const visibleColumns = useMemo(
    () => ({
      utm_ads: isLoading ? false : Boolean(data.find((f: any) => Boolean(f?.utm_ads))?.utm_ads),
      utm_content: isLoading ? false : Boolean(data.find((f: any) => Boolean(f?.utm_content))?.utm_content),
    }),
    [rows]
  );

  const getAPI = async (date: any) => {
    if (!date?.startDate) {
      setRows(queryClient.getQueryData(KEY_QUERY) || []);
      return;
    }

    const startDate = dayjs(date?.startDate).format("YYYY-MM-DD HH:mm:ss");
    const endDate = dayjs(date?.endDate).format("YYYY-MM-DD HH:mm:ss");

    const KEY_QUERY_DATES = [...KEY_QUERY, startDate, endDate];

    let res: any = queryClient.getQueryData(KEY_QUERY_DATES);

    if (!res) {
      setDisabled(true);
      res = await useAPI(HTTP_BASE, { from: startDate, to: endDate });
      queryClient.setQueryData(KEY_QUERY_DATES, res);
    }

    setRows(res);
    setDisabled(false);
  };

  const handleSelectRows = (ids: Iterable<unknown> | null | undefined) => {
    if (!rows) return;
    const selectedIDs = new Set(ids);
    const selectedRowIds = rows?.filter((row: any) => selectedIDs.has(row.id) || (row?.parentId ? selectedIDs.has(row?.parentId) : false)).map((row: any) => row.id);
    setSelectedUsersID([...selectedRowIds]);
  };

  const handleResetData = async () => {
    setResetDataDisabled(true);
    const dataReset = await useAPI(HTTP_BASE);
    setRange([]);
    setRows(dataReset);
    queryClient.setQueryData(KEY_QUERY, dataReset);
    setResetDataDisabled(false);
  };

  const handleGetNewDataAds = async (params: any) => {
    const setData = (data: any) => {
      setRows(data);
      queryClient.setQueryData(KEY_QUERY, data);
    };

    if (range.length) {
      params.from = dayjs(range[0]).format("YYYY-MM-DD HH:mm:ss");
      params.to = dayjs(range[1]).format("YYYY-MM-DD HH:mm:ss");
    }

    let clone = [...rows];

    const indexRow: number = clone.findIndex((e: any) => e.id === params.id);

    clone[indexRow][params.isOpen] = true;

    if (clone[indexRow]?.[params.column + "NewRows"]?.length) {
      clone.splice(indexRow + 1, 0, ...clone[indexRow][params.column + "NewRows"]);
      setData(clone);
      return;
    }

    clone[indexRow][params.isLoading] = true;

    setRows(clone);

    clone = [...clone];

    const res = await useAPI(HTTP_NEW_ROWS, params);

    if (!res || !res.length) {
      clone[indexRow]["newRowSearchError"] = true;
    }

    clone[indexRow][params.column + "NewRows"] = [...res];
    clone[indexRow][params.isLoading] = false;

    clone.splice(indexRow + 1, 0, ...res);

    setData(clone);
  };

  const handleRemoveNewDataAds = (row: any, newRows: Array<any>, isOpen: string) => {
    const clone = [...rows];

    let indexRow: number;

    newRows.forEach((i: any) => {
      indexRow = clone.findIndex((e: any) => e.id == i.id);
      clone.splice(indexRow, 1);
    });

    indexRow = clone.findIndex((e: any) => e.id === row.id);
    clone[indexRow][isOpen] = false;

    setRows(clone);
    queryClient.setQueryData(KEY_QUERY, clone);
  };

  const columns = useMemo(() => {
    let total_leads: number = 0,
      duplicate_leads: number = 0,
      unique_leads: number = 0,
      bad_leads: number = 0,
      limbo: number = 0,
      processing: number = 0,
      sales: number = 0,
      not_converted: number = 0,
      meetings_scheduled: number = 0,
      meetings_scheduled_manual: number = 0,
      meetings_done: number = 0,
      expected_revenues: number = 0,
      revenues: number = 0;

    rows?.forEach((item: any) => {
      total_leads += Number(item.total_leads);
      duplicate_leads += Number(item.duplicate_leads);
      unique_leads += Number(item.unique_leads);
      bad_leads += Number(item.bad_leads);
      limbo += Number(item.limbo);
      processing += Number(item.processing);
      sales += Number(item.sales);
      not_converted += Number(item.not_converted);
      meetings_scheduled += Number(item.meetings_scheduled);
      meetings_scheduled_manual += Number(item.meetings_scheduled_manual);
      meetings_done += parseInt(item.meetings_done);
      expected_revenues += Number(item.expected_revenues);
      revenues += Number(item.revenues);
    });

    return [
      {
        field: "utm_source",
        headerName: "Source",
        minWidth: 100,
        renderCell: ({ row }) =>
          row?.newRowCampaign || row?.newRowContent ? (
            <></>
          ) : !row?.utm_source || row?.utm_source === "NA" || row?.utm_source.length <= 6 ? (
            <Box className="truncate w-full">{row?.utm_source}</Box>
          ) : (
            <MyTooltip>{row?.utm_source}</MyTooltip>
          ),
      },
      {
        field: "utm_medium",
        headerName: "Reach",
        minWidth: 120,
        renderCell: ({ row }) =>
          row?.newRowCampaign || row?.newRowContent ? (
            <></>
          ) : !row?.utm_medium || row?.utm_medium === "NA" || row?.utm_medium?.length <= 8 ? (
            <Box className="truncate w-full">{row?.utm_medium}</Box>
          ) : (
            <MyTooltip>{row?.utm_medium}</MyTooltip>
          ),
      },
      {
        field: "utm_campaign",
        headerName: "Campaign",
        minWidth: 250,
        renderCell: ({ row }) => {
          if (row?.newRowCampaign || row?.newRowContent) return <></>;

          return (
            <Box className="w-full flex items-center justify-between gap-2">
              {!row?.utm_campaign || row?.utm_campaign === "NA" || row?.utm_campaign?.length <= 18 ? (
                <Box className="truncate w-full">{row?.utm_campaign}</Box>
              ) : (
                <MyTooltip>{row?.utm_campaign}</MyTooltip>
              )}

              {row?.utm_campaign && row?.utm_campaign !== "NA" ? (
                <Button
                  sx={{ p: "0.1rem 0.2rem", minWidth: 0 }}
                  variant="text"
                  disabled={row?.campaignIsLoading || row?.newRowSearchError}
                  onClick={() => {
                    if (row?.campaignIsOpen) {
                      handleRemoveNewDataAds(row, row?.campaignNewRows, "campaignIsOpen");
                    } else {
                      handleGetNewDataAds({
                        id: row.id,
                        utm_source: row.utm_source,
                        utm_medium: row.utm_medium,
                        utm_campaign: row.utm_campaign,
                        column: "campaign",
                        isOpen: "campaignIsOpen",
                        isLoading: "campaignIsLoading",
                      });
                    }
                  }}
                >
                  {row?.campaignIsLoading ? (
                    <RefreshIcon fontSize="small" />
                  ) : row?.newRowSearchError ? (
                    <SdCardAlertIcon fontSize="small" />
                  ) : (
                    <ArrowDropDownIcon sx={transitionRotate(Boolean(row?.campaignIsOpen))} />
                  )}
                </Button>
              ) : null}
            </Box>
          );
        },
      },
      {
        field: "utm_content",
        headerName: "Adset",
        minWidth: 250,
        flex: 1,
        renderCell: ({ row }) => {
          if (row?.newRowContent) return <></>;

          return (
            <Box className="w-full flex items-center justify-between gap-2">
              {!row?.utm_content || row?.utm_content === "NA" || row?.utm_content?.length <= 18 ? <Box className="truncate w-full">{row?.utm_content}</Box> : <MyTooltip>{row?.utm_content}</MyTooltip>}

              {row?.utm_content && row?.utm_campaign !== "NA" && row?.utm_content !== "NA" && !row?.campaignIsOpen ? (
                <Button
                  sx={{ p: "0.1rem 0.2rem", minWidth: 0 }}
                  variant="text"
                  disabled={row?.contentIsLoading}
                  onClick={() => {
                    if (row?.contentIsOpen) {
                      handleRemoveNewDataAds(row, row?.contentNewRows, "contentIsOpen");
                    } else {
                      handleGetNewDataAds({
                        id: row.id,
                        parentId: row.parentId,
                        utm_source: row.utm_source,
                        utm_medium: row.utm_medium,
                        utm_campaign: row.utm_campaign,
                        utm_content: row.utm_content,
                        column: "content",
                        isOpen: "contentIsOpen",
                        isLoading: "contentIsLoading",
                      });
                    }
                  }}
                >
                  {row?.contentIsLoading ? <RefreshIcon fontSize="small" /> : <ArrowDropDownIcon sx={transitionRotate(Boolean(row?.contentIsOpen))} />}
                </Button>
              ) : null}
            </Box>
          );
        },
      },
      {
        field: "utm_ads",
        headerName: "Ads",
        minWidth: 250,
        renderCell: ({ row }) => (!row?.utm_ads || row?.utm_ads === "NA" || row?.utm_ads?.length <= 28 ? <Box className="truncate w-full">{row?.utm_ads}</Box> : <MyTooltip>{row?.utm_ads}</MyTooltip>),
      },
      {
        field: "total_leads",
        headerName: `Total leads${total_leads ? " = " + formatNumber(total_leads) : ""}`,
        minWidth: 180,
        valueGetter: ({ row }) => formatNumber(row?.total_leads),
      },
      {
        field: "duplicate_leads",
        headerName: `Duplicate leads${duplicate_leads ? " = " + formatNumber(duplicate_leads) : ""}`,
        minWidth: 200,
        valueGetter: ({ row }) => formatNumber(row?.duplicate_leads),
      },
      {
        field: "unique_leads",
        headerName: `Unique leads${unique_leads ? " = " + formatNumber(unique_leads) : ""}`,
        minWidth: 200,
        valueGetter: ({ row }) => formatNumber(row?.unique_leads),
      },
      {
        field: "percent_unique_total",
        headerName: `Unique/Total${unique_leads && total_leads ? " = " + ((unique_leads / total_leads) * 100).toFixed(2) + "%" : ""}`,
        minWidth: 200,
        valueGetter: ({ row }) => (row?.percent_unique_total ? formatNumber(row?.percent_unique_total) : 0) + "%",
      },
      {
        field: "bad_leads",
        headerName: `Bad lead${bad_leads ? " = " + formatNumber(bad_leads) : ""}`,
        minWidth: 160,
        valueGetter: ({ row }) => formatNumber(row?.bad_leads),
      },
      {
        field: "limbo",
        headerName: `Limbo${limbo ? " = " + formatNumber(limbo) : ""}`,
        minWidth: 140,
        valueGetter: ({ row }) => formatNumber(row?.limbo),
      },
      {
        field: "processing",
        headerName: `Processing${processing ? " = " + formatNumber(processing) : ""}`,
        minWidth: 180,
        valueGetter: ({ row }) => formatNumber(row?.processing),
      },
      {
        field: "sales",
        headerName: `Sales${sales ? " = " + formatNumber(sales) : ""}`,
        minWidth: 140,
        valueGetter: ({ row }) => formatNumber(row?.sales),
      },
      {
        field: "not_converted",
        headerName: `Not converted${not_converted ? " = " + formatNumber(not_converted) : ""}`,
        minWidth: 190,
        valueGetter: ({ row }) => formatNumber(row?.not_converted),
      },
      {
        field: "percent_not_interested_total",
        headerName: `Not interested/Total${not_converted && total_leads ? " = " + ((not_converted / total_leads) * 100).toFixed(2) + "%" : ""}`,
        minWidth: 240,
        valueGetter: ({ row }) => (row?.percent_not_interested_total ? formatNumber(row?.percent_not_interested_total) : 0) + "%",
      },
      {
        field: "meetings_scheduled",
        headerName: `Meetings scheduled${meetings_scheduled ? " = " + formatNumber(meetings_scheduled) : ""}`,
        minWidth: 250,
        valueGetter: ({ row }) => formatNumber(row?.meetings_scheduled),
      },
      {
        field: "meetings_scheduled_manual",
        headerName: `Meetings Manually${meetings_scheduled_manual ? " = " + formatNumber(meetings_scheduled_manual) : ""}`,
        minWidth: 230,
        valueGetter: ({ row }) => formatNumber(row?.meetings_scheduled_manual),
      },
      {
        field: "meetings_done",
        headerName: `Meetings done${meetings_done ? " = " + formatNumber(meetings_done) : ""}`,
        minWidth: 200,
        valueGetter: ({ row }) => formatNumber(row?.meetings_done),
      },
      {
        field: "expected_revenues",
        headerName: `Expected revenues${expected_revenues ? " = " + formatCurrencies(expected_revenues) : ""}`,
        minWidth: 300,
        valueGetter: ({ row }) => formatCurrencies(row?.expected_revenues),
      },
      {
        field: "revenues",
        headerName: `Revenues${revenues ? " = " + formatCurrencies(revenues) : ""}`,
        minWidth: 230,
        valueGetter: ({ row }) => formatCurrencies(row?.revenues),
      },
      {
        field: "interview_rate",
        headerName: `Interview rate${meetings_done && unique_leads ? " = " + ((meetings_done / unique_leads) * 100).toFixed(2) + "%" : ""}`,
        minWidth: 210,
        valueGetter: ({ row }) => (row?.interview_rate ? formatNumber(row?.interview_rate) : 0) + "%",
      },
      {
        field: "interview_conversion",
        headerName: `Interview conversion${sales && meetings_done ? " = " + ((sales / meetings_done) * 100).toFixed(2) + "%" : ""}`,
        minWidth: 260,
        valueGetter: ({ row }) => (row?.interview_conversion ? formatNumber(row?.interview_conversion) : 0) + "%",
      },
      {
        field: "acquisition_rate",
        headerName: `Acquisition rate${sales && unique_leads ? " = " + ((sales / unique_leads) * 100).toFixed(2) + "%" : ""}`,
        minWidth: 220,
        valueGetter: ({ row }) => (row?.acquisition_rate ? formatNumber(row?.acquisition_rate) : 0) + "%",
      },
    ];
  }, [screen, rows]);

  useEffect(() => {
    if (isLoading) return;
    setRows(data);
    queryClient.setQueryData(KEY_QUERY_RESET, JSON.parse(JSON.stringify(data)));
  }, [isLoading]);

  return (
    <>
      <DataGrid
        checkboxSelection
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={TableStyling}
        rows={rows}
        style={!isMobile ? { height: `calc(100vh - 14rem)` } : {}}
        columns={columns}
        loading={isLoading}
        autoHeight={isMobile}
        hideFooter={rows.length < 100}
        selectionModel={selectedUsersID}
        componentsProps={{ toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 500 } } }}
        rowsPerPageOptions={[25, 50, 75, 100]}
        experimentalFeatures={{ newEditingApi: false }}
        onSelectionModelChange={(ids) => handleSelectRows(ids)}
        columnVisibilityModel={{
          utm_ads: visibleColumns.utm_ads,
          utm_content: visibleColumns.utm_content,
        }}
        components={{
          Toolbar: () => <ToolbarADS {...{ range, setRange, getAPI, disabled, onResetdata: handleResetData, resetDataDisabled }} fileName="Ads Performance" />,
        }}
      />
    </>
  );
};

export default ADS;
