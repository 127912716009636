import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { TableStyling } from "@/dummy";
import { CustomToolbar } from "../Kpis/Toolbar";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import useScreen from "@/hooks/useScreen";
import LinearProgress from "@mui/material/LinearProgress";

const KEY_QUERY = ["prodotti_performance"];

const HTTP_ROUTE_BASE = "/v1/prodotti";

const ProductsPerformance = () => {
  const { isMobile } = useScreen();

  const { data, isLoading } = useQuery(KEY_QUERY, () => useAPI(HTTP_ROUTE_BASE));

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [range, setRange] = useState<Array<Date>>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [columnsHeaders, setColumnsHeaders] = useState<Object>({});

  const getAPI = async (date: any) => {
    if (!date?.startDate) {
      const value: any = queryClient.getQueryData(KEY_QUERY);
      setColumnsHeaders(value?.columns);
      setRows(value?.rows);
      return;
    }

    const startDate = format(date?.startDate, "yyyy-MM-dd HH:mm:ss");
    const endDate = format(date?.endDate, "yyyy-MM-dd HH:mm:ss");

    const KEY_QUERY_DATE = [...KEY_QUERY, startDate, endDate];

    let res: any = queryClient.getQueryData(KEY_QUERY_DATE);

    if (!res) {
      setDisabled(true);
      res = await useAPI(HTTP_ROUTE_BASE, { startDate, endDate });
      queryClient.setQueryData(KEY_QUERY_DATE, res);
    }

    setColumnsHeaders(res?.columns);
    setRows(res?.rows);
    setDisabled(false);
  };

  const columns: GridColDef[] = [
    {
      field: "product",
      headerName: "Product",
      minWidth: 400,
      flex: 1,
    },
    {
      field: "proposed",
      headerName: "Proposed",
      minWidth: 160,
      renderHeader: ({ field }) => `Proposed${columnsHeaders?.[field] ? " = " + columnsHeaders?.[field] : ""}`,
    },
    {
      field: "sale",
      headerName: "Sale",
      minWidth: 120,
      renderHeader: ({ field }) => `Sale${columnsHeaders?.[field] ? " = " + columnsHeaders?.[field] : ""}`,
    },
    {
      field: "conversion",
      headerName: "Conversion",
      minWidth: 200,
      renderHeader: ({ field }) => `Conversion${columnsHeaders?.[field] ? " = " + columnsHeaders?.[field] : ""}`,
    },
  ];

  useEffect(() => {
    if (isLoading) return;
    setRows(data.rows);
    setColumnsHeaders(data.columns);
  }, [isLoading]);

  if (isLoading) return <LinearProgress />;

  return (
    <Box className="mt-8 mx-5 md:mx-10" style={{ height: "calc(100% - 10rem)" }}>
      <Box component="h2" className="text-3xl font-extrabold text-white mb-3">
        Products performance
      </Box>

      <DataGrid
        sx={TableStyling}
        rows={rows}
        style={!isMobile ? { height: "calc(100vh - 10rem)", fontSize: "1rem" } : { paddingBottom: "1rem" }}
        columns={columns}
        loading={isLoading}
        // density="comfortable"
        autoHeight={isMobile}
        // components={{ Toolbar: () => CustomToolbar(range, setRange, getAPI, disabled, "Products Performance") }}
        componentsProps={{ toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 500 } } }}
        experimentalFeatures={{ newEditingApi: false }}
        hideFooter
        disableVirtualization
        disableSelectionOnClick
      />
    </Box>
  );
};

export default ProductsPerformance;
