import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import { Box, Button, MenuItem, TextField, Snackbar } from "@mui/material";
import Logo from "@/assets/logoBlack.svg";
import jsPDF from "jspdf";
import useAPI from "@/api/useAPI";
import TutorCard from "./Report/TutorCard";
import domtoimage from "dom-to-image";
import Punctuality from "./Report/Punctuality";
import AverageRating from "./Report/AverageRating";
import PositiveComments from "./Report/PositiveComments";
import NegativeComments from "./Report/NegativeComments";
import LessonsEvaluation from "./Report/LessonsEvaluation";
import BoxPlotComparison from "./Report/BoxPlotComparison";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import CustomDateRangePicker from "@/components/CustomDateRangePicker";

const HTTP_ROUTE_BASE = "/v1/general/tutors/report";

const labels = ["VOCABULARY", "SPEAKING", "FEEDBACK", "INTERACTION"];

const PerformanceReport = ({ open, onClose, tutorToken }) => {
  const [range, setRange] = useState(null as Array<Date> | null);
  const [values, setValues] = useState(Array(4).fill(1));
  const [showToast, setShowToast] = useState(false);
  const [showPdfDiv, setShowPdfDiv] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const KEY_QUERY = ["report-tutors", tutorToken, range];

  const previousRange = range && [
    new Date(range[0].getTime() - (range[1].getTime() - range[0].getTime())),
    new Date(range[1].getTime() - (range[1].getTime() - range[0].getTime())),
  ];

  const { data, isLoading } = useQuery(
    [KEY_QUERY, range],
    () =>
      useAPI(`${HTTP_ROUTE_BASE}/${tutorToken}`, {
        currentStartDate: range && range[0],
        currentEndDate: range && range[1],
        previousStartDate: previousRange && previousRange[0],
        previousEndDate: previousRange && previousRange[1],
      }),
    {
      enabled: open && range !== null,
    }
  );

  const formatDate = (date) => {
    const year = date.getYear().toString().slice(-2);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${day}.${month}.${year}`;
  };

  let dateRangeDisplay = "";

  if (range) {
    dateRangeDisplay = `${formatDate(range[0])} - ${formatDate(range[1])}`;
  }

  const handleChange = (index: number) => (event: any) => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);
  };

  const printToPDF = () => {
    setTimeout(() => {
      const node = document.getElementById("pdfdiv");

      domtoimage
        .toBlob(node, { quality: 1 })
        .then((blob: any) => {
          const img = new Image();
          img.src = URL.createObjectURL(blob);
          img.onload = () => {
            const pdf = new jsPDF("landscape", "mm", [img.width, img.height]);
            pdf.addImage(img, "PNG", 0, 0, img.width, img.height);
            pdf.save("document.pdf");
            setShowPdfDiv(false);
          };
        })
        .catch((error: any) => {
          console.error("Errore durante la creazione del PDF: ", error);
          setShowPdfDiv(false);
        });
    }, 0);
  };

  const handleClose = () => {
    setRange(null);
    setFilteredData([]);
    setValues(Array(4).fill(1));
    setShowToast(false);
    setShowPdfDiv(false);
    onClose();
  };

  useEffect(() => {
    if (data && data.currentPeriod && data.currentPeriod.length > 0) {
      setFilteredData(data.currentPeriod);
      setShowPdfDiv(true);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && data) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [isLoading, data]);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="xl"
    >
      <EduModalTitle onClose={handleClose}>Tutor Performance</EduModalTitle>

      <EduModalBody
        className="p-5"
        sx={{ minHeight: "720px", minWidth: "1440px" }}
      >
        <Box className="flex items-center gap-4 mb-8 px-8">
          <Button
            color={"primary"}
            variant="outlined"
            onClick={printToPDF}
          >
            <SimCardDownloadIcon color={"primary"} />
          </Button>

          <CustomDateRangePicker
            value={range}
            onChange={(date) =>
              setRange(date ? [date.startDate, date.endDate] : null)
            }
          />

          <Box sx={{ display: "flex" }}>
            {values.map((value, index) => (
              <TextField
                key={index}
                select
                label={labels[index]}
                value={value}
                onChange={handleChange(index)}
                color="primary"
                variant="outlined"
                style={{ width: "100px", marginLeft: "10px" }}
              >
                {[1, 2, 3, 4, 5].map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            ))}
          </Box>

          <Snackbar
            open={showToast}
            autoHideDuration={5000}
            onClose={() => setShowToast(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ".MuiAlert-root": {
                height: "40px",
                padding: "0px 16px",
                marginRight: "12px",
              },
              ".MuiSnackbar-root": {
                right: "40px",
              },
            }}
            message="Dati pronti, inserisci i valori manualmente e scarica il PDF"
          />
        </Box>

        {showPdfDiv && (
          <Box
            id="pdfdiv"
            sx={{ width: "1280", height: "720px" }}
          >
            <div className="p-6 bg-gradient-to-r from-[#D9DAF3] from-45% to-[#fff]">
              <div className="pb-3 flex justify-between items-center text-eduV2-1000">
                <img
                  className="w-16"
                  src={Logo}
                  alt=""
                />

                <h3 className="font-semibold text-xl">
                  TUTOR PERFORMANCE REPORT
                </h3>

                <h3>
                  <span className="font-semibold text-xl">Period</span>{" "}
                  {dateRangeDisplay}
                </h3>
              </div>

              <div className="grid grid-cols-12 grid-rows-12 gap-3">
                <div className="border-2 rounded-md border-eduV2-800 bg-eduV2-white50 col-start-1 col-span-3 row-start-1 row-span-1">
                  {filteredData && filteredData.length > 0 && (
                    <TutorCard data={filteredData?.[0]} />
                  )}
                </div>

                <div className="border-2 rounded-md border-eduV2-800 bg-eduV2-white50 col-start-4 col-span-6 row-start-1 row-span-6">
                  {/* {filteredData && filteredData.length > 0 && <GaussComparison data={filteredData?.[0]} />} */}
                  {filteredData && filteredData.length > 0 && (
                    <BoxPlotComparison filteredData={filteredData?.[0]} />
                  )}
                </div>

                <div className="border-2 rounded-md border-eduV2-800 bg-eduV2-white50 col-start-10 col-span-3 row-start-1 row-span-6">
                  {filteredData && filteredData.length > 0 && (
                    <PositiveComments data={filteredData?.[0].last_feedbacks} />
                  )}
                </div>

                <div className="border-2 rounded-md border-eduV2-800 bg-eduV2-white50 col-start-1 col-span-3 row-start-2 row-span-1">
                  {filteredData && filteredData.length > 0 && (
                    <AverageRating
                      data={data}
                      formatDate={formatDate}
                      range={range}
                    />
                  )}
                </div>

                <div className="border-2 rounded-md border-eduV2-800 bg-eduV2-white50 col-start-1 col-span-3 row-start-3 row-span-6">
                  {filteredData && filteredData.length > 0 && (
                    <Punctuality
                      data={filteredData?.[0]}
                      range={range}
                    />
                  )}
                </div>

                <div className="border-2 rounded-md border-eduV2-800 bg-eduV2-white50 col-start-4 col-span-6 row-span-2">
                  {filteredData && filteredData.length > 0 && (
                    <LessonsEvaluation data={values} />
                  )}
                </div>

                <div className="border-2 rounded-md border-eduV2-800 bg-eduV2-white50 col-start-10 col-span-3 row-span-2">
                  {filteredData && filteredData.length > 0 && (
                    <NegativeComments data={filteredData[0]} />
                  )}
                </div>
              </div>
            </div>
          </Box>
        )}
      </EduModalBody>
    </EduModal>
  );
};

export default PerformanceReport;
