import { forwardRef, useImperativeHandle, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, useTheme } from "@mui/material";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import InfoIcon from "@mui/icons-material/Info";
import MyTooltip from "@/components/MyTooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export interface IRef {
  open: () => void;
}

interface IProps {}

const InfoFlowAvailabilities = forwardRef<IRef, IProps>((_, ref) => {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const { data, isLoading } = useQuery(
    ["info-flow-availabilities"],
    () => useAPI("/v1/settings/flow/get/availabilities-history"),
    { refetchOnWindowFocus: true }
  );

  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: "success",
      headerName: "",
      width: 30,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Box className="w-full flex justify-center">
          {row.success === null ? (
            <MoreHorizIcon color="disabled" />
          ) : row.success === true ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </Box>
      ),
    },
    {
      field: "active_date",
      headerName: "Active date",
      width: 140,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      valueGetter: ({ row }) => dayjs(row.active_date).format("YY-MM-DD HH:mm"),
    },
    {
      field: "action",
      headerName: "Action",
      width: 75,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
    },
    {
      field: "name_action",
      headerName: "Name action",
      minWidth: 180,
      flex: 1,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => <MyTooltip>{row.name_action}</MyTooltip>,
    },
    {
      field: "users_prepare_count",
      headerName: "Users prepare",
      width: 130,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
    },
    {
      field: "users_send_count",
      headerName: "Users send",
      width: 120,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
    },
    {
      field: "deadline",
      headerName: "Deadline",
      width: 110,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
    },
    {
      field: "start_month",
      headerName: "Start month",
      width: 130,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
    },
    {
      field: "start_month_date",
      headerName: "Start month date",
      width: 160,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
    },
  ];

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="xl"
    >
      <EduModalTitle
        onClose={handleClose}
        className="flex gap-3"
      >
        <InfoIcon />
        Info flow Availabilities
      </EduModalTitle>

      <EduModalBody className="p-5">
        <DataGrid
          autoHeight
          disableColumnMenu
          disableColumnFilter
          disableVirtualization
          disableSelectionOnClick
          sx={getTableStyling(theme)}
          rows={data}
          columns={columns}
          loading={isLoading}
          pageSize={10}
          hideFooter={data?.length <= 10}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: false }}
        />
      </EduModalBody>
    </EduModal>
  );
});

export default InfoFlowAvailabilities;
