import { useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useMutation } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useEventUsers } from "@/api/events";
import { API_ENDPOINTS } from "@/api/client/endpoints";
import { useListMaterialExercises } from "@/api/events";
import {
  EventItem,
  MaterialExercise,
  bulkLoadMaterialExercise,
} from "@/interfaces";
import {
  Box,
  Chip,
  Checkbox,
  TextField,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import client from "@/api/client";
import saveIcon from "@/assets/icons/save.svg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AutoCompleteInput from "../custom/AutoCompleteInput";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExercisesMaterialsBox = ({ events, query, paramsFromOpen }) => {
  const { snackHandler } = useAuth();

  const { users } = useEventUsers();
  const { materialExercises } = useListMaterialExercises();

  const [isString, setIsString] = useState<boolean>(false);
  const [students, setStudents] = useState<Array<any> | string>([]);
  const [exercises, setExercises] = useState<MaterialExercise[]>([]);
  const [materialsUsers, setMaterialsUsers] = useState<Array<any>>([]);
  const [materialsTutors, setMaterialsTutors] = useState<Array<any>>([]);
  const [studentsExercises, setStudentsExercises] = useState<Array<any>>([]);

  const { mutate: bulkUpload, isLoading } = useMutation(
    client.events.bulkEditMaterialsExercisesStudents,
    {
      onSuccess: (data) => {
        snackHandler(data.message);
        queryClient.invalidateQueries([API_ENDPOINTS.V2.EVENTS, query]);
      },
      onError: (er: Error) => {
        snackHandler("An error occurred. Please try again", "error");
      },
    }
  );

  const formatDataForSubmit = () => {
    const payload: bulkLoadMaterialExercise[] = events.map(
      (event: EventItem) => ({
        id: event.id,
        isRemove: paramsFromOpen.isRemove,
        students:
          typeof students == "string"
            ? students.split(",").map((e: string) => e.trim())
            : students.map((e: any) => e.email),
        materials: [...new Set([...materialsTutors, ...materialsUsers])],
        exercises,
        studentsExercises: studentsExercises.map((e: any) => e.token),
      })
    );

    bulkUpload({ events: payload });
  };

  const forUsersTutors = useMemo(
    () => ({
      users: materialExercises?.materials?.filter(
        (m: any) => m?.for == "User" || m?.for == "Both" || !m?.for
      ),
      tutors: materialExercises?.materials?.filter(
        (m: any) => m?.for == "Tutor" || m?.for == "Both"
      ),
    }),
    [materialExercises?.materials]
  );

  return (
    <Box className="flex gap-5 flex-col">
      {!paramsFromOpen?.isRemove && (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="checkboxes-tags-demo"
          color="primary"
          style={{ width: "100%" }}
          options={materialExercises ? materialExercises.exercises : []}
          getOptionLabel={(option: {
            id: number;
            name: string;
            type: string;
            lang: string[];
            token: string;
            lesson: string;
            category: string[];
          }) => `${option.name} | ${option.category} | ${option.lang}`}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              key={option.id}
            >
              <Chip label={option.type} />
              <Checkbox
                icon={icon}
                style={{ marginRight: 8 }}
                checked={selected}
                checkedIcon={checkedIcon}
              />
              {`${option.name} | ${option.category} | ${option.lang}`}
            </li>
          )}
          onChange={(_, value) => setExercises(value.map((e: any) => e.token))}
          ChipProps={{ color: "primary", variant: "outlined" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Exercises"
              placeholder="exercise"
            />
          )}
          isOptionEqualToValue={(option, value) => option.token === value.token}
        />
      )}

      <AutoCompleteInput
        fullWidth
        data={users}
        value={studentsExercises}
        label="Exercises from students"
        multiple={true}
        required={false}
        setValue={setStudentsExercises}
        closeSelect={true}
        placeholder="student"
        optionsLabel={true}
      />

      <Autocomplete
        multiple
        disableCloseOnSelect
        id="checkboxes-tags-demo"
        color="primary"
        style={{ width: "100%" }}
        options={forUsersTutors?.tutors ?? []}
        getOptionLabel={(option: {
          id: number;
          for: string | null;
          name: string;
          type: string;
          token: string;
          material_id: number;
          lang: string[];
          language: string[];
          category: string[];
          lesson: string;
        }) =>
          `${option.name} | ${option.lesson} | ${option.category} | ${option.language}`
        }
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            key={option.id}
          >
            <Chip label={option.type} />
            <Checkbox
              icon={icon}
              style={{ marginRight: 8 }}
              checked={selected}
              checkedIcon={checkedIcon}
            />
            {`${option.name} | ${option.lesson} | ${option.category} | ${option.language}`}
          </li>
        )}
        onChange={(_, value) => setMaterialsTutors(value.map((m: any) => m.id))}
        ChipProps={{ color: "primary", variant: "outlined" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Materials tutors"
            placeholder="material"
          />
        )}
        isOptionEqualToValue={(option, value) => option.token === value.token}
      />

      <Autocomplete
        multiple
        disableCloseOnSelect
        id="checkboxes-tags-demo"
        color="primary"
        style={{ width: "100%" }}
        options={forUsersTutors?.users ?? []}
        getOptionLabel={(option: {
          id: number;
          for: string | null;
          name: string;
          type: string;
          token: string;
          lesson: string;
          language: string[];
          category: string[];
          material_id: number;
        }) =>
          `${option.name} | ${option.lesson} | ${option.category} | ${option.language}`
        }
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            key={option.id}
          >
            <Chip label={option.type} />
            <Checkbox
              icon={icon}
              style={{ marginRight: 8 }}
              checked={selected}
              checkedIcon={checkedIcon}
            />
            {`${option.name} | ${option.lesson} | ${option.category} | ${option.language}`}
          </li>
        )}
        onChange={(_, value) => setMaterialsUsers(value.map((m: any) => m.id))}
        ChipProps={{ color: "primary", variant: "outlined" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Materials users"
            placeholder="material"
          />
        )}
        isOptionEqualToValue={(option, value) => option.token === value.token}
      />

      <Box>
        <FormControlLabel
          sx={{ width: "100%" }}
          label="Write emails with commas between them"
          control={
            <Checkbox
              onChange={(e: any) => {
                if (!e.target.checked) {
                  setStudents([]);
                }
                setIsString(e.target.checked);
              }}
            />
          }
        />

        {isString ? (
          <TextField
            fullWidth
            label="Students"
            value={
              (typeof students == "string"
                ? students
                : students.map((e: any) => e.email).join(",")) || ""
            }
            onChange={(e: any) => setStudents(e.target.value)}
            placeholder="example@edu.com,example@gma.it,etc..."
          />
        ) : (
          <AutoCompleteInput
            fullWidth
            data={users}
            label="Students"
            value={
              typeof students == "string"
                ? users
                  .filter((user: any) =>
                    students.split(",").includes(user.email)
                  )
                  .map((user: any) => ({
                    token: user.token,
                    email: user.email,
                  }))
                : students
            }
            multiple={true}
            required={false}
            setValue={setStudents}
            closeSelect={true}
            placeholder="student"
            optionsLabel={true}
          />
        )}
      </Box>

      <Box className="flex justify-end">
        <LoadingButton
          color="primary"
          variant="outlined"
          loading={isLoading}
          onClick={formatDataForSubmit}
        >
          <img
            src={saveIcon}
            className="icon"
          />
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ExercisesMaterialsBox;
