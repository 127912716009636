import { MouseEvent, useState } from "react";
import { IDayOff } from "@/api/useDayOffApi";
import { groupBy } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  useTheme,
} from "@mui/material";
import {
  parse,
  format,
  getDay,
  isToday,
  endOfWeek,
  endOfMonth,
  isSameMonth,
  startOfWeek,
  startOfToday,
  eachDayOfInterval,
} from "date-fns";
import dayOffIcon from "@/assets/icons/day-off.svg";
import MyTooltip from "@/components/MyTooltip";

const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
const colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];

const MonthCalendar = ({ currentDate, dayOffs, viewMode = "personal" }) => {
  const theme = useTheme();
  const { typeOf } = useAuth();

  const [people, setPeople] = useState<IDayOff[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const today = currentDate || startOfToday();
  const currMonth = format(today, "MMM-yyyy");
  let firstDayOfMonth = parse(currMonth, "MMM-yyyy", new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  });

  const handlePopoverOpen = (
    event: MouseEvent<HTMLElement>,
    admin: IDayOff[]
  ) => {
    // if (viewMode === "personal") return;

    if (admin && admin.length > 0) {
      setAnchorEl(event.currentTarget);
      setPeople(admin);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPeople([]);
  };

  return (
    <div
      className="flex p-4 rounded-xl"
      style={{
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.mode === "light" ? "white" : "",
      }}
    >
      <div className="w-full">
        <div className="mb-5">
          <p className="text-2xl">{format(firstDayOfMonth, "MMMM")}</p>
        </div>

        <div className="grid grid-cols-7 gap-6 sm:gap-4 place-items-center">
          {days.map((day, idx) => (
            <div
              key={idx}
              className="font-semibold uppercase"
            >
              {day}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-7 gap-6 sm:gap-x-4 sm:gap-y-1 mt-4 place-items-center">
          {daysInMonth.map((day, idx) => {
            let classes = "";

            if (isToday(day)) {
              classes = `bg-gray-500`;
            }

            if (!isSameMonth(day, today)) {
              classes = "text-gray-400";
            }

            const dayOffsFilter = groupBy(dayOffs, (day: IDayOff) => day?.date);
            let admin = dayOffsFilter.get(format(day, "yyyy-MM-dd"));

            if (admin) {
              if (viewMode === "personal") {
                const status = admin[0].status;

                if (status === 0) {
                  classes = "bg-yellow-500 text-black";
                } else if (status === 1) {
                  classes = "bg-green-500 text-white";
                } else {
                  classes = "bg-red-500 text-white";
                }
              } else {
                if (admin.filter(({ status }) => status === 1).length)
                  classes =
                    typeOf === "english"
                      ? `bg-purple-500 text-white`
                      : `bg-cyan-500 text-white`;
              }
            }

            return (
              <div
                key={idx}
                className={colStartClasses[getDay(day)]}
                onMouseEnter={(e) => handlePopoverOpen(e, admin)}
                onMouseLeave={handlePopoverClose}
              >
                <p
                  className={`cursor-pointer flex items-center justify-center h-8 w-8 rounded ${classes}`}
                >
                  {format(day, "d")}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {Boolean(anchorEl) && (
        <Popover
          disableRestoreFocus
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={Boolean(anchorEl)}
          onClose={handlePopoverClose}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <MenuList>
            <MenuItem>
              <ListItemIcon>
                <img
                  src={dayOffIcon}
                  style={{ filter: theme.palette.filter }}
                />
              </ListItemIcon>
              <ListItemText>People Off</ListItemText>
            </MenuItem>

            {(people ?? []).map(({ admin }) => (
              <MenuItem>
                <ListItemIcon>
                  {admin.avatar ? (
                    <Avatar
                      style={{ height: "1.8rem", width: "1.8rem" }}
                      src={admin.avatar}
                      alt={`${admin.first_name} ${admin.last_name}`}
                    />
                  ) : (
                    <Avatar style={{ height: "1.8rem", width: "1.8rem" }}>
                      {admin.first_name.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                </ListItemIcon>
                <ListItemText>{`${admin.first_name} ${admin.last_name}`}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      )}
    </div>
  );
};

export default MonthCalendar;
