import IT from "@/assets/img/IT.png";
import GB from "@/assets/img/GB.png";
import CH from "@/assets/img/CH.png";
import AT from "@/assets/img/AT.png";

const classImg = "w-[1.25rem] py-[0.3rem]";

export default {
  smile: {
    code: "[icon:smile]",
    value: "☺",
    show: <>&#128522;</>,
  },
  smirk: {
    code: "[icon:smirk]",
    value: "😅",
  },
  "eyes stars": {
    code: "[icon:eyes_stars]",
    value: "🤩",
  },
  celebration: {
    code: "[icon:celebration]",
    value: "🥳",
  },
  confetti: {
    code: "[icon:confetti]",
    value: "🎉",
  },
  greeting: {
    code: "[icon:greeting]",
    value: "👋🏻",
  },
  like: {
    code: "[icon:like]",
    value: "👍🏻",
  },
  watch: {
    code: "[icon:watch]",
    value: "⏱",
  },
  hourglass: {
    code: "[icon:hourglass]",
    value: "⏳",
  },
  "magnifying glass": {
    code: "[icon:magnifying_glass]",
    value: "🔍",
  },
  sheet: {
    code: "[icon:sheet]",
    value: "📄",
  },
  note: {
    code: "[icon:note]",
    value: "🗒️",
  },
  calendar: {
    code: "[icon:calendar]",
    value: "📅",
  },
  clip: {
    code: "[icon:clip]",
    value: "📎",
  },
  light: {
    code: "[icon:light]",
    value: "💡",
  },
  "check box": {
    code: "[icon:check_box]",
    value: "✅",
  },
  "red cross": {
    code: "[icon:red_cross]",
    value: "❌",
  },
  IT: {
    code: "[icon:IT]",
    value: "🇮🇹",
    show: (
      <img
        src={IT}
        alt="🇮🇹"
        className={classImg}
      />
    ),
  },
  GB: {
    code: "[icon:GB]",
    value: "🇬🇧",
    show: (
      <img
        src={GB}
        alt="🇬🇧"
        className={classImg}
      />
    ),
  },
  CH: {
    code: "[icon:CH]",
    value: "🇨🇭",
    show: (
      <img
        src={CH}
        alt="🇨🇭"
        className={classImg}
      />
    ),
  },
  AT: {
    code: "[icon:AT]",
    value: "🇦🇹",
    show: (
      <img
        src={AT}
        alt="🇦🇹"
        className={classImg}
      />
    ),
  },
};
