import { Box, Card } from "@mui/material";
import React from "react";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StarHalfRoundedIcon from '@mui/icons-material/StarHalfRounded';

const AverageRating = ({ data, range, formatDate }) => {
    if (!data || !data.currentPeriod || !data.previousPeriod || data.currentPeriod.length === 0 || data.previousPeriod.length === 0) {
        return null;
    }
    const currentPeriod = data.currentPeriod[0];
    const previousPeriod = data.previousPeriod[0];

    const roundedRating = (currentPeriod.averageRating ?? 0).toFixed(2);
    const fullStars = Math.floor(currentPeriod.averageRating);
    const remainingRating = currentPeriod.averageRating - fullStars;
    const halfStars = remainingRating >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    //Calcolo Stellette
    const renderStars = (count, IconComponent) => {
        const stars: React.ReactElement[] = [];
        for (let i = 0; i < count; i++) {
            stars.push(<IconComponent key={i} className="text-eduV2-800" sx={{ fontSize: 38 }}/>);
        }
        return stars;
    };
    // Calcolo periodo precedente
    const calculatePreviousRange = ([start, end]) => {
        const diff = end - start;
        return [new Date(start.getTime() - diff), start];
    };

    let previousDateRangeDisplay = "";

    if (range) {
        const previousRange = calculatePreviousRange(range);
        previousDateRangeDisplay = `${formatDate(previousRange[0])} - ${formatDate(previousRange[1])}`;
    }
    //Calcolo percentuale tra i due periodi
    const calculateRatingChange = (currentPeriod, previousPeriod) => {
        const currentAverageRating = currentPeriod.averageRating;
        const previousAverageRating = previousPeriod.averageRating;

        if (Math.abs(previousAverageRating) < 0.00001) {
            return 0;
        }

        return ((currentAverageRating - previousAverageRating) / previousAverageRating) * 100;
    };

    let ratingChange = calculateRatingChange(currentPeriod, previousPeriod);

    if (isNaN(ratingChange)) {
        ratingChange = 0;
    }
    
    return (
        <Card>
            <Box height={130} p={2}>
                <div className="text-eduV2-1000 text-sm font-semibold leading-[13.93px]">Average rating</div>
                <Box display="flex" alignItems="center" py={1}>
                    {renderStars(fullStars, StarRoundedIcon)}
                    {renderStars(halfStars, StarHalfRoundedIcon)}
                    {renderStars(emptyStars, StarBorderRoundedIcon)}
                    <div className="text-eduV2-800 text-2xl font-semibold px-2">{roundedRating}</div>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box className="w-[135px] h-8">
                        <div className="h-4 text-[10px] leading-[9.95px]">Previous period</div>
                        <div className="h-4 text-eduV2-1000 text-sm leading-[13.93px]"> {previousDateRangeDisplay}</div>
                    </Box>
                    <Box display="flex" alignItems="center" px={1} mx={2} sx={{ backgroundColor: ratingChange >= 0 ? '#E0F0E4' : '#f0e0e0' }}>
                        {ratingChange >= 0 ? (
                            <ArrowDropUpIcon sx={{ color: '#377E36' }} />
                        ) : (
                            <ArrowDropDownIcon sx={{ color: '#7e3636' }} />
                        )}
                        <Box sx={{fontSize: 20, color: ratingChange >= 0 ? '#377E36' : '#7e3636' }}>{ratingChange.toFixed(2)}%</Box>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default AverageRating;
