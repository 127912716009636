import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Theme,
} from "@mui/material";
import { ExpandMore, MessageRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import { useCrmContext } from "../../state/CrmV3Context";
import { useCrmDialogsContext } from "../../state/CrmDialogsContext";
import { CommentsTemplateProps } from "../crm-upselling.types";

const MeetingNote: React.FC<{ note: any; theme: Theme }> = ({
  note,
  theme,
}) => (
  <Box
    sx={{ borderColor: theme.palette.primary.main }}
    className="border rounded p-3"
  >
    <Box
      sx={{ borderColor: theme.palette.primary.main }}
      className="flex justify-between gap-4 border-b mb-2"
    >
      <Box>{note.interviewer}</Box>
      <Box>{dayjs(note.start).format("DD MMM YYYY HH:mm")}</Box>
    </Box>
    <Box>{note.notes}</Box>
  </Box>
);

const getAccordionTitle = (length: number | undefined) =>
  length && length < 2 ? "Note from meeting" : "Notes from meetings";

const CommentsTemplate: React.FC<CommentsTemplateProps> = ({
  theme,
  notesMeetings,
  rowSelected,
  onUpdateRow,
}) => {
  const {
    commentsState: { comments, setComments },
  } = useCrmContext();

  const { confirmDialogState } = useCrmDialogsContext();
  const crmComments = rowSelected?.crm_upselling?.comments;
  const userFullName = `${rowSelected?.first_name} ${rowSelected?.last_name}`;

  const handleSaveClick = () => {
    confirmDialogState.setConfirmDialogData({
      open: true,
      title: (
        <Box className="flex items-center gap-1">
          <MessageRounded fontSize="small" />
          <Box>ADD NOTE</Box>
        </Box>
      ),
      text: `Do you want to confirm to add this note for ${userFullName}?`,
      onAgree: () => {
        onUpdateRow({ toast: "comments", values: { comments } });
        confirmDialogState.setConfirmDialogData({
          open: false,
          title: "",
          text: "",
          onAgree: null,
          onDisagree: null,
        });
      },
      onDisagree: () => {
        confirmDialogState.setConfirmDialogData({
          open: false,
          title: "",
          text: "",
          onAgree: null,
          onDisagree: null,
        });
      },
    });
  };

  return (
    <Box
      sx={{ bgcolor: theme.palette.background.paper }}
      className="flex flex-col gap-3 p-3"
    >
      {Boolean(notesMeetings?.length) && (
        <Accordion
          sx={{ borderRadius: "0.5rem !important" }}
          className="!bg-none !m-0"
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            {getAccordionTitle(notesMeetings?.length)}
          </AccordionSummary>

          <AccordionDetails className="flex flex-col gap-3 !-m-2">
            {notesMeetings?.map((note, i) => (
              <MeetingNote
                key={i}
                note={note}
                theme={theme}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      )}

      <TextField
        multiline
        sx={{ minWidth: "28rem" }}
        rows={10}
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        placeholder="Write a comment"
      />

      <Button
        variant="outlined"
        disabled={crmComments === comments || !comments}
        onClick={handleSaveClick}
      >
        SAVE
      </Button>
    </Box>
  );
};

export default CommentsTemplate;
