import { useState } from "react";
import useAuth from '@/hooks/useAuth';
import { OpenInNew } from "@mui/icons-material";
import { getTableStyling } from "@/providers/Colors";
import { Button, useTheme } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import WritingDialog from "./components/WritingDialog";
import ExercisesToolbar from "./components/ExercisesToolbar";
import CustomPagination from "@/components/_Common/CustomPagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";

const WritingExercises = ({
  id,
  page,
  idUser,
  tokenEx,
  setPage,
  filters,
  setIdUser,
  exercises,
  isLoading,
  sortModel,
  setTokenEx,
  setFilters,
  updateExercise,
  openConfirmDialog,
  onSortModelChange,
  updateDeleteExercise,
  setOpenConfirmDialog,
}) => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { copyToClipboard } = useAuth();

  const [selectDialog, setSelectDialog] = useState(false);

  const pagination = {
    total: exercises?.total ? exercises?.total : 1,
    last_page: exercises?.last_page ? exercises?.last_page : 1,
    current_page: exercises?.current_page ? exercises?.current_page : 1,
  };

  const student = exercises?.data?.find((el: any) => el.id === idUser);

  const columns: GridColDef[] = [
    {
      field: "name_student",
      headerName: "Student name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row?.name_student}</MyTooltip>,
    },
    {
      field: "email",
      headerName: "Student email",
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) =>
        row?.email ? (
          <MyTooltip title={row?.email}>
            <button
              onClick={() => copyToClipboard(row?.email)}
              className="w-full text-left truncate"
            >
              {row?.email}
            </button>
          </MyTooltip>
        ) : (
          "-"
        ),
      valueGetter: ({ row }) => row?.email ?? "-",
    },
    {
      field: "start_month",
      headerName: "Start month",
      minWidth: 140,
      flex: 0.8,
      valueGetter: ({ row }) => row?.start_month || "-",
    },
    {
      field: "writing_done",
      headerName: "Writings done",
      minWidth: 130,
      flex: 0.7,
      valueGetter: ({ row }) => row?.writing_done || "-",
    },
    {
      field: "writing_corrected",
      headerName: "Writings corrected",
      minWidth: 172,
      flex: 0.7,
      valueGetter: ({ row }) => row?.writing_corrected || "-",
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Button
          onClick={() => {
            setSelectDialog((p) => !p);
            setIdUser(row?.id);
          }}
        >
          <OpenInNew color="primary" />
        </Button>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={exercises?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar: ExercisesToolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        componentsProps={{
          toolbar: { filters, setPage, setFilters },
          pagination: { page, setPage, pagination },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={() =>
          updateDeleteExercise.mutate({
            tk: id,
            token_user: idUser,
            token_ex: tokenEx,
          })
        }
      >
        Do you want to delete?
      </ConfirmationDialog>

      {Boolean(student) && (
        <WritingDialog
          id={id}
          open={selectDialog}
          title={"Student exercises - " + student?.name_student}
          idUser={idUser}
          tokenEx={tokenEx}
          student={student}
          setOpen={setSelectDialog}
          content="Open the exercise to be corrected"
          setIdUser={setIdUser}
          setTokenEx={setTokenEx}
          updateExercise={updateExercise}
          setOpenConfirmDialog={setOpenConfirmDialog}
        />
      )}
    </>
  );
};

export default WritingExercises;
