import classNames from "classnames";
import { QRCodeCanvas } from "qrcode.react";

interface Props {
    dataText: string;
    disableHover?: boolean;
}
const QrCodeGenerator = ({ dataText, disableHover = false }: Props) => {

    const qrcode = (
        <QRCodeCanvas
            id="qrCode"
            value={dataText}
            size={125}
            bgColor={"#ffff"}
            fgColor={'rgb(17, 24, 39)'}
            level={"H"}
        />
    );

    const handleClose = () => { }

    return (
        <div className={
            classNames(
                "qrcode__container p-4 bg-white rounded-xl w-min h-min",
                disableHover ? "" : "hover:scale-125"
            )
        }>
            <div>{qrcode}</div>
        </div>

    );
};

export default QrCodeGenerator;