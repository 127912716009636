import { useState, useMemo } from "react";
import { useTheme } from "@mui/material";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  days,
  generateRows,
  CustomToolbar,
  generateDates,
  generateColumns,
  generateCalendar,
} from "./HelpersUserAttendance";
import Popper from "./Popper";

interface IAttendance {
  user: any;
  isLoading: boolean;
}

const Attendance = ({ user, isLoading }: IAttendance) => {
  const theme = useTheme();

  const [index, setIndex] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [rowSelected, setRowSelected] = useState<any>();

  const dates = generateDates(6);
  const calendars = useMemo(
    () => generateCalendar(dates[index]),
    [dates, index]
  );

  const handlePopper = (e: any, row: any) => {
    setRowSelected(row);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const columns: GridColDef[] = generateColumns({
    days,
    onPopper: handlePopper,
  });

  const rows: GridRowsProp = useMemo(
    () => generateRows({ calendars, events: user?.events }),
    [calendars, user?.user_events]
  );

  const typesCount = useMemo(() => {
    let types = {};

    rows.forEach((row: any, i) => {
      Object.entries(row.typesCount).forEach(([key, value]) => {
        if (!types?.[key]) {
          types[key] = 0;
        }

        types[key] += value;
      });
    });
    return types;
  }, [rows]);

  const handleIndexMore = () => {
    if (index >= 0 && index < dates.length - 1) {
      setIndex(index + 1);
    }
  };

  const handleIndexLess = () => {
    if (index > 0 && index <= dates.length - 1) {
      setIndex(index - 1);
    }
  };

  return (
    <>
      <DataGrid
        autoHeight
        hideFooter
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        density="comfortable"
        pageSize={20}
        components={{
          Toolbar: () => (
            <CustomToolbar
              date={dates[index]}
              typesCount={typesCount}
              onLessIndex={handleIndexLess}
              onMoreIndex={handleIndexMore}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        rowsPerPageOptions={[20]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      {Boolean(anchorEl) && (
        <Popper
          data={rowSelected}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
};

export default Attendance;
