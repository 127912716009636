import { PaymentOption, usePaymentOptions } from "@/api/orders"; // Correct import
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAuth from '@/hooks/useAuth';
import PaymentIcon from "@mui/icons-material/Payment";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import EnabledPaymentsIcon from "./EnabledPaymentsIcon";

interface PaymentCategories {
  prepayment: PaymentOption[];
  total_payment: PaymentOption[];
}

interface CountryPaymentOptions {
  [countryCode: string]: PaymentCategories;
}

const CheckoutOptions = forwardRef((_, ref) => {
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("it");
  const [tabIndex, setTabIndex] = useState(0); // 0 for Prepayment, 1 for Total Payment
  const { isAdmin } = useAuth();

  const [selectedPayments, setSelectedPayments] = useState<{
    [countryCode: string]: {
      prepayment: { [displayName: string]: boolean };
      total_payment: { [displayName: string]: boolean };
    };
  }>({});

  const { paymentOptions, availablePaymentTypes, setPaymentOptions } =
    usePaymentOptions();

  // List of countries
  const countries = [
    { code: "it", name: "Italy" },
    { code: "pt", name: "Portugal" },
    { code: "es", name: "Spain" },
    { code: "en", name: "England" },
    { code: "fr", name: "France" },
    { code: "de", name: "Germany" },
  ];

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  useEffect(() => {
    if (paymentOptions) {
      const currentCountryPayments = paymentOptions[selectedCountry] || {
        prepayment: [],
        total_payment: [],
      };

      const mapOptionsToCheckboxState = (options: PaymentOption[]) => {
        return options.reduce((acc, option) => {
          acc[
            option.display_name + (option?.installments == null ? " (x1)" : "")
          ] = true;
          return acc;
        }, {} as { [displayName: string]: boolean });
      };

      setSelectedPayments((prev) => ({
        ...prev,
        [selectedCountry]: {
          prepayment: mapOptionsToCheckboxState(
            currentCountryPayments.prepayment
          ),
          total_payment: mapOptionsToCheckboxState(
            currentCountryPayments.total_payment
          ),
        },
      }));
    }
  }, [selectedCountry, paymentOptions]);

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setSelectedCountry(event.target.value as string);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handlePaymentSelect = (
    category: "prepayment" | "total_payment",
    displayName: string
  ) => {
    setSelectedPayments((prev) => {
      const newSelectedPayments = { ...prev };
      const currentCountry = newSelectedPayments[selectedCountry] || {
        prepayment: {},
        total_payment: {},
      };

      const currentCategory = currentCountry[category] || {};
      const newCategory = {
        ...currentCategory,
        [displayName]: !currentCategory[displayName],
      };

      newSelectedPayments[selectedCountry] = {
        ...currentCountry,
        [category]: newCategory,
      };

      return newSelectedPayments;
    });
  };

  const mergeOptionsWithSelections = (
    category: "prepayment" | "total_payment"
  ) => {
    const selectedCategory =
      selectedPayments[selectedCountry]?.[category] || {};
    return availablePaymentTypes?.map((option) => ({
      ...option,
      checked: !!selectedCategory[option.display_name],
    }));
  };

  const handleSubmit = () => {
    const payload: CountryPaymentOptions = Object.keys(selectedPayments).reduce(
      (acc, countryCode) => {
        const selectedPrepayment = availablePaymentTypes?.filter(
          (option) =>
            selectedPayments[countryCode]?.prepayment[option.display_name]
        );
        const selectedTotalPayment = availablePaymentTypes?.filter(
          (option) =>
            selectedPayments[countryCode]?.total_payment[option.display_name]
        );

        if (selectedPrepayment.length > 0 || selectedTotalPayment.length > 0) {
          acc[countryCode] = {
            prepayment: selectedPrepayment,
            total_payment: selectedTotalPayment,
          };
        } else {
          const currentCountryPayments = paymentOptions[countryCode] || {
            prepayment: [],
            total_payment: [],
          };
          acc[countryCode] = {
            prepayment: currentCountryPayments.prepayment,
            total_payment: currentCountryPayments.total_payment,
          };
        }

        return acc;
      },
      {} as CountryPaymentOptions
    );

    if (setPaymentOptions) {
      setPaymentOptions(payload);
    }
  };

  return (
    <EduModal
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
    >
      <EduModalTitle onClose={() => setOpen(false)}>
        Manage Checkout Options
      </EduModalTitle>
      <EduModalBody className="p-5 grid gap-5">
        <FormControl
          fullWidth
          variant="outlined"
          margin="normal"
        >
          <InputLabel>Select Country</InputLabel>
          <Select
            value={selectedCountry}
            onChange={handleCountryChange}
            label="Select Country"
          >
            {countries.map((country) => (
              <MenuItem
                key={country.code}
                value={country.code}
              >
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
        >
          <Tab label="Prepayment" />
          <Tab label="Total Payment" />
        </Tabs>

        <Typography
          variant="body1"
          component="p"
          sx={{ mt: 2 }}
        >
          <PaymentIcon /> {tabIndex === 0 ? "Prepayment" : "Total Payment"}{" "}
          Options:
        </Typography>

        <FormGroup>
          {mergeOptionsWithSelections(
            tabIndex === 0 ? "prepayment" : "total_payment"
          )?.map((option) => (
            <FormControlLabel
              key={option.display_name}
              control={
                <Checkbox
                  disabled={!isAdmin}
                  checked={option.checked}
                  onChange={() =>
                    handlePaymentSelect(
                      tabIndex === 0 ? "prepayment" : "total_payment",
                      option.display_name
                    )
                  }
                />
              }
              label={
                <EnabledPaymentsIcon providers={[option]}>
                  {" "}
                  {option.display_name}{" "}
                </EnabledPaymentsIcon>
              }
            />
          ))}
        </FormGroup>

        <Grid
          container
          justifyContent="flex-end"
        >
          <LoadingButton
            color="primary"
            variant="contained"
            loading={false}
            onClick={handleSubmit}
            className="w-full"
            disabled={!isAdmin}
          >
            Save
          </LoadingButton>
        </Grid>
      </EduModalBody>
    </EduModal>
  );
});

export default CheckoutOptions;
