import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { clone } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { HTTP_ROUTE_CREATE, KEY_QUERY } from ".";
import { Box, Button, TextField, Autocomplete } from "@mui/material";
import { INKeyTutor, INTutor, KeysTutorCreate, ROLES } from "./interfaces";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import ConfirmDialog from "@/pages/Exercises/ExercisesV1/components/ConfirmDialog";

export interface INCreate {
  errors?: INTutor | undefined;
  newData?: INTutor | undefined;
  disabled?: boolean;
  openConfirm?: boolean;
}

interface INProps {
  rows: GridRowsProp;
  setRows: Dispatch<SetStateAction<readonly GridValidRowModel[]>>;
  openCreate: boolean;
  setOpenCreate: Dispatch<SetStateAction<boolean>>;
}

const Create = ({ rows, setRows, openCreate, setOpenCreate }: INProps) => {
  const { snackHandler } = useAuth();
  const [create, setCreate] = useState<INCreate>();

  const [tutorOptions, setTutorOptions] = useState<readonly any[]>([]);

  const handleOnCreate = async (): Promise<any> => {
    setCreate((p: INCreate | undefined) => ({
      ...p,
      disabled: true,
      errors: undefined,
    }));

    const newData = clone(create?.newData as any);

    newData.role = newData.role.join(",");

    const res = await useAPI(HTTP_ROUTE_CREATE, newData);

    // ERRORS
    if (res?.status == false) {
      setCreate((p: INCreate | undefined) => ({
        ...p,
        errors: res?.response,
        disabled: false,
      }));
      return;
    }

    // SUCCESS
    snackHandler("Success creation!");
    setOpenCreate(false);
    setCreate(undefined);
    queryClient.setQueryData(KEY_QUERY, res?.response);
    setRows(res.response.original.tutors);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setCreate({});
  };

  const firstValidate = Boolean(
    create?.disabled ||
    !create?.newData?.first_name ||
    !create?.newData?.last_name ||
    !Boolean(create?.newData?.role?.length)
  );

  useEffect(() => {
    if (!rows) return;

    const tutorsMapped = rows?.map((el) => ({
      value: el.token,
      label: el.first_name + " " + el.last_name,
    }));

    setTutorOptions(tutorsMapped);
  }, [rows]);

  return (
    <EduModal
      fullWidth
      open={openCreate}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>Creation</EduModalTitle>

      <EduModalBody className="flex flex-col gap-4 p-5 pt-7">
        {KeysTutorCreate.map((e: INKeyTutor, i: number) => (
          <Box key={i}>
            {e.key === "role" ? (
              <Autocomplete
                multiple
                id="tags-standard"
                options={ROLES}
                value={(create?.newData?.[e.key] as any[]) ?? []}
                onChange={(_: any, value: any) => {
                  setCreate((p: INCreate | undefined) => ({
                    ...p,
                    newData: { ...p?.newData, [e.key]: value },
                  }));
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Types"
                    variant="outlined"
                    placeholder="type"
                  />
                )}
              />
            ) : e.key === "refered_by" ? (
              <Autocomplete
                id="select-standard"
                options={tutorOptions}
                value={create?.newData?.[e.key] as string}
                onChange={(_: any, value: any) => {
                  setCreate((p: INCreate | undefined) => ({
                    ...p,
                    newData: { ...p?.newData, [e.key]: value.value },
                  }));
                }}
                getOptionLabel={(option) => option.label ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Refered"
                    variant="outlined"
                    placeholder="Jon Doe"
                  />
                )}
              />
            ) : e.key === "document_deadline" ? (
              <TextField
                fullWidth
                type="date"
                required={e.required}
                error={Boolean(create?.errors?.[e.key]?.[0])}
                label={e.label}
                placeholder={e.placeholder}
                value={create?.newData?.[e.key] ?? ""}
                sx={{ width: "100%" }}
                InputLabelProps={{ shrink: true }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setCreate((p: INCreate | undefined) => ({
                    ...p,
                    newData: {
                      ...p?.newData,
                      [e.key]: event.target.value,
                    },
                  }))
                }
              />
            ) : (
              <TextField
                fullWidth
                key={i}
                required={e.required}
                error={Boolean(create?.errors?.[e.key]?.[0])}
                label={
                  create?.errors?.[e.key]?.[0]?.replace(
                    e.key.replaceAll("_", " "),
                    `"${e.label}"`
                  ) || e.label
                }
                placeholder={e.placeholder}
                value={create?.newData?.[e.key] ?? ""}
                sx={{ width: "100%" }}
                InputLabelProps={{ sx: { color: "white" } }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setCreate((p: INCreate | undefined) => ({
                    ...p,
                    newData: { ...p?.newData, [e.key]: event.target.value },
                  }))
                }
              />
            )}
          </Box>
        ))}

        <Box className="flex justify-end">
          <Button
            disabled={firstValidate}
            variant="outlined"
            onClick={() =>
              setCreate((p: INCreate | undefined) => ({
                ...p,
                openConfirm: true,
              }))
            }
            className="!pl-3"
          >
            <img
              src={addIcon}
              className="icon"
            />
            Create
          </Button>
        </Box>
      </EduModalBody>

      <ConfirmDialog
        open={Boolean(create?.openConfirm)}
        onClose={() =>
          setCreate((p: INCreate | undefined) => ({ ...p, openConfirm: false }))
        }
        clickYes={handleOnCreate}
      >
        Do you want to create new Tutor?
      </ConfirmDialog>
    </EduModal>
  );
};

export default Create;
