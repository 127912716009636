import { Dispatch, SetStateAction, useState } from "react";
import { convert } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { getTableStyling } from "@/providers/Colors";
import { MoreVert, OpenInNew } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  MenuItem,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useAPI from "@/api/useAPI";
import editIcon from "@/assets/icons/edit.svg";
import copyIcon from "@/assets/icons/copy.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import EditDialog from "./EditDialog";
import deleteIcon from "@/assets/icons/delete.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

interface ICDProps {
  id: string;
  open: boolean;
  title: string;
  idUser: string;
  student: any;
  content: string;
  tokenEx: string;
  setOpen: (open: boolean) => void;
  setIdUser: (id: number | null) => void;
  setTokenEx: (token_ex: string) => void;
  updateExercise: any;
  textButtonAgree: string;
  textButtonDisagree: string;
  setOpenConfirmDialog: Dispatch<SetStateAction<boolean>>;
}

function getIeltsScore(score: number) {
  let ielts = {
    "39-40": 9,
    "37-38": 8.5,
    "35-36": 8,
    "32-34": 7.5,
    "30-31": 7,
    "26-29": 6.5,
    "23-25": 6,
    "18-22": 5.5,
    "16-17": 5,
    "13-15": 4.5,
    "11-12": 4,
    "0-10": 0,
  };

  for (let [range, value] of Object.entries(ielts)) {
    let rangeValues = range.split("-");

    if (
      score >= parseInt(rangeValues[0]) &&
      score <= parseInt(rangeValues[1])
    ) {
      return value;
    }
  }
}

const WritingDialog = ({
  id,
  open,
  title,
  idUser,
  setOpen,
  content,
  student,
  tokenEx,
  setIdUser,
  setTokenEx,
  updateExercise,
  setOpenConfirmDialog,
}: ICDProps) => {
  const theme = useTheme();
  const { perPage: rowsPerPage } = useScreen();
  const { snackHandler, copyToClipboard, isAdmin } = useAuth();

  const perPage = rowsPerPage - 2;

  const [userScore, setUserScore] = useState<null | number>(null);
  const [editDialog, setEditDialog] = useState(false);
  const [rowSelected, setRowSelected] = useState<any>();

  const [correctionData, setCorrectionData] = useState<null | string>(null);
  const [loadingButtonSaveEdit, setLoadingButtonSaveEdit] =
    useState<boolean>(false);

  const handleClose = () => {
    setTokenEx("");
    setIdUser(null);
    setOpen(false);
    setEditDialog(false);
  };

  const columns: GridColDef[] = [
    ...(isAdmin
      ? [
        {
          field: "id",
          width: 80,
        },
      ]
      : []),
    {
      field: "test_name",
      headerName: "Test name",
      minWidth: 160,
      flex: 1,
      renderCell: ({ row }) => (
        <MyTooltip title={row?.test_name}>
          <button
            onClick={() => copyToClipboard(row?.test_name)}
            className="w-full text-left truncate"
          >
            {row?.test_name}
          </button>
        </MyTooltip>
      ),
    },
    {
      field: "data",
      headerName: "Completion date",
      width: 180,
      valueGetter: ({ row }) => row?.data || "-",
    },
    {
      field: "correction_data",
      headerName: "Correction date",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => row?.correction_data || "-",
    },
    {
      field: "user_score",
      headerName: "Score",
      minWidth: 70,
      flex: 0.5,
      valueGetter: ({ row }) => {
        if (!row.user_score) return "-";
        const score = row.user_score.match(/[-]{0,1}[\d]*[.,]{0,1}[\d]+/g);

        if (row.type !== "Writing") {
          const res = row.user_score.split("/")[0];
          return getIeltsScore(Number(res));
        }

        return score;
      },
    },
    {
      field: "corrected",
      headerName: "Status",
      minWidth: 100,
      flex: 0.5,
      renderCell: ({ row }) =>
        row?.corrected
          ? row?.corrected == 1
            ? "Corrected"
            : row?.corrected
          : "-",
    },
    {
      field: "action",
      headerName: "",
      width: 70,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Dropdown text={<MoreVert />}>
          <MenuItem
            onClick={() => {
              const currentTrack = convert(
                row?.exercise?.files
              )?.media?.replace("public/", "");

              if (!currentTrack) {
                snackHandler("Track exercise not found!", "error");
                return;
              }

              window.open(
                `https://api.edusogno.com/public/storage/${currentTrack}`
              );
            }}
          >
            <ListItemIcon>
              <OpenInNew fontSize="small" />
            </ListItemIcon>
            <ListItemText>Open track exercise</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              const file_id = convert(row?.user_submit)?.file_id;

              if (!file_id) {
                snackHandler("Google docs document not found!", "error");
                return;
              }

              window.open(
                `https://docs.google.com/document/d/${file_id}/edit`,
                "_blank"
              );
            }}
          >
            <ListItemIcon>
              <OpenInNew fontSize="small" />
            </ListItemIcon>
            <ListItemText>Open google docs document</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={async () => {
              const report = await useAPI("v1/writings-done/copy-report", {
                user_id: row.user_id,
                token_ex: row.token_ex,
              });

              if (!report?.report) {
                snackHandler("Feedback not found!", "error");
                return;
              }

              copyToClipboard(report?.report);
            }}
          >
            <ListItemIcon>
              <img
                src={copyIcon}
                className="icon no-margin"
              />
            </ListItemIcon>
            <ListItemText>Copy Feedback</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setTokenEx(row?.token_ex);
              setRowSelected(row);
              setEditDialog(true);
            }}
          >
            <ListItemIcon>
              <img
                src={editIcon}
                className="icon no-margin"
              />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setTokenEx(row?.token_ex);
              setOpenConfirmDialog(true);
            }}
          >
            <ListItemIcon>
              <img
                src={deleteIcon}
                className="icon no-margin"
              />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Dropdown>
      ),
    },
  ];

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <EduModalTitle onClose={handleClose}>{title}</EduModalTitle>

      <EduModalBody className="p-4">
        <DataGrid
          autoHeight
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          sx={getTableStyling(theme)}
          rows={student?.exercises_done ?? []}
          columns={columns}
          pageSize={perPage}
          components={{
            Toolbar: () => (
              <Box className="flex items-center gap-4">
                <Box className="text-base">{content}</Box>
                <SearchToolbar />
              </Box>
            ),
            Pagination: CustomPaginationClient,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowsPerPageOptions={[perPage]}
          experimentalFeatures={{ newEditingApi: false }}
        />

        <EditDialog
          open={editDialog}
          title={`Edit - ${student?.name_student} - ${rowSelected?.test_name}`}
          setOpen={setEditDialog}
          content="Correct exercise"
          userScore={userScore}
          onConfirm={() =>
            updateExercise.mutate({
              tk: id,
              score: userScore,
              token_ex: tokenEx,
              token_user: idUser,
              correction_data: correctionData,
              params: {
                ...student.exercises_done.filter(
                  (e: any) => e.token_ex === tokenEx
                )[0],
                user_score: userScore,
              },
              callback: (close: boolean = true) => {
                if (close) {
                  setUserScore(null);
                  setEditDialog(false);
                }

                setLoadingButtonSaveEdit(false);
              },
            })
          }
          setUserScore={setUserScore}
          setCorrectionData={setCorrectionData}
          loadingButtonSaveEdit={loadingButtonSaveEdit}
          setLoadingButtonSaveEdit={setLoadingButtonSaveEdit}
        />
      </EduModalBody>
    </EduModal>
  );
};

WritingDialog.defaultProps = {
  title: "Student's Exercises",
  textButtonAgree: "Save",
  textButtonDisagree: "Close",
};

export default WritingDialog;
