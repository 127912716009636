import { forwardRef, useImperativeHandle, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
} from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import HistoryIcon from "@mui/icons-material/History";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TimelineIcon from "@mui/icons-material/Timeline";
import { LevelChangeHistoryModalRef } from "./interfaces";

const LevelChangeHistoryModal = forwardRef<LevelChangeHistoryModalRef>(
  (_, ref) => {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState<any>({});

    useImperativeHandle(ref, () => ({
      openModal: (row) => {
        setOpen(true);
        setUser(row);
      },
      closeModal: () => setOpen(false),
    }));

    const handleClose = () => setOpen(false);

    const sortedChanges = user?.level_change?.changes
      ?.slice()
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const totalChanges = sortedChanges?.length || 0;
    const increases = sortedChanges?.filter(
      (change) => change.level > change.previous_level
    ).length;
    const decreases = sortedChanges?.filter(
      (change) => change.level < change.previous_level
    ).length;

    return (
      <EduModal
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <TimelineIcon />
          History for - {user?.fullName || ""}
        </EduModalTitle>
        <EduModalBody
          className="p-5 pt-7 flex flex-col gap-4"
          maxHeight={"50vh"}
        >
          {/* Summary */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Summary</Typography>
            <Box>
              <Badge
                badgeContent={increases}
                color="success"
              >
                <ArrowUpwardIcon sx={{ color: "green", ml: 1 }} />
              </Badge>
              <Badge
                badgeContent={decreases}
                color="error"
              >
                <ArrowDownwardIcon sx={{ color: "red", ml: 1 }} />
              </Badge>
              <Typography variant="body2">
                Total Changes: {totalChanges}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />
          <List>
            {sortedChanges?.map((change, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <HistoryIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={`Admin: ${change.admin}`}
                  secondary={
                    <>
                      <Typography variant="body2">
                        Date: {new Date(change.date).toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        Level: {change.previous_level}
                        {change.previous_level !== change.level && (
                          <>
                            <Tooltip
                              title={
                                change.level > change.previous_level
                                  ? "Level Increased"
                                  : "Level Decreased"
                              }
                            >
                              {change.level > change.previous_level ? (
                                <ArrowUpwardIcon
                                  sx={{ color: "green", ml: 1 }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  sx={{ color: "red", ml: 1 }}
                                />
                              )}
                            </Tooltip>
                          </>
                        )}{" "}
                        → {change.level}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </EduModalBody>
      </EduModal>
    );
  }
);

export default LevelChangeHistoryModal;
