import { getLastByKey } from "@/pages/Upselling/v2/Crm/helpers/crm-update-rows";
import {
  INButtonCup,
  INDialogsEl,
} from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import {
  STATUS,
  BASE_PAYMENT_LINK,
} from "@/pages/Upselling/v2/Crm/helpers/constants";
import client from "@/api/client";
import emailUpsellingBtnCup from "@/components/assets/crm-upselling/emailUpsellingBtnCup";

const BASE_LANDING = import.meta.env.VITE_LANDING_CHECKOUT;

export const updateMeeting = async (
  handleMeetingDone: Function,
  meet: any,
  codeValues: any
): Promise<string | undefined> => {
  if ("newStartingMonth" in codeValues) {
    meet = {
      ...meet,
      ...(codeValues?.newStartingMonth
        ? { newStartingMonth: codeValues.newStartingMonth }
        : {}),
      ...(codeValues?.product ? { pacchetto: codeValues.product } : {}),
    };

    const updatedMeet = await handleMeetingDone(
      meet,
      "",
      STATUS.ToBeConverted,
      false
    );
    return getLastByKey(updatedMeet, "pacchetto")?.pacchetto;
  }
  return meet?.pacchetto;
};

export const sendEmail = async (
  codeValues: any,
  rowSelected: any,
  product: string,
  dataAPI: any,
  snackHandler: Function,
): Promise<any> => {

  let meet = getLastByKey(
    rowSelected,
    "pacchetto"
  );

  let {order_token} = meet

  let link = `${BASE_PAYMENT_LINK}/${product}/${rowSelected.token}`;

  if(order_token) {
    link = `${BASE_LANDING}/${rowSelected?.lang}/checkout-page/upselling?order_tk=${order_token}`;
  }

  const code = dataAPI.codes.find((c: any) => c.id === codeValues.code)?.code || null;

  const res = await client.crm_upselling.sendTemplateMail({
    ...(code ? {code} : {}),
    expires_at: codeValues?.expires_at,
    userToken: rowSelected.token,
    productTk: product,
    order_token,
    template: emailUpsellingBtnCup({
      ...codeValues,
      ...(code ? {code} : {}),
      link,
      nameUser: rowSelected.first_name,
      lang: rowSelected?.lang,
    }),
  });

  if (res?.success == false || res?.errors) {
    snackHandler("Error sending email!", "error");
    return null;
  }

  snackHandler(
    `Send email to ${rowSelected.first_name} ${rowSelected.last_name}`
  );
  return res;
};

export const handleGenerateEmail = async (
  e: any,
  rowSelected: any,
  codeValues: any,
  setButtonCup: Function,
  snackHandler: Function,
  dataAPI: any,
  setDialogsEl: Function,
) => {
  e.preventDefault();

  let meet = getLastByKey(
    rowSelected,
    "pacchetto"
  );

  let {pacchetto: product, order_token} = meet

  let link = `${BASE_PAYMENT_LINK}/${product}/${rowSelected.token}`;

  if(order_token) {
    link = `${BASE_LANDING}/${rowSelected?.lang}/checkout-page/upselling?order_tk=${order_token}`;
  }

  if ("product" in codeValues) {
    product = codeValues?.product;
  }

  if (!product) {
    snackHandler("Error, the product is missing!", "error");
    return;
  }

  setButtonCup((p: INButtonCup) => ({
    ...p,
    template: emailUpsellingBtnCup({
      ...codeValues,
      code: dataAPI.codes.find((c: any) => c.id === codeValues?.code)?.code,
      link,
      nameUser: rowSelected.first_name,
      lang: rowSelected?.lang,
    }),
  }));

  setDialogsEl((p: INDialogsEl) => ({
    ...p,
    templateEmail: { value: true },
  }));
};
