import { useEffect, useState } from "react";
import { fUpper } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { listOrders } from "@/api/orders";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { Email, MoreVert } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { OrdersData, UserOrders, UserTypeof } from "@/interfaces";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import dayjs from "dayjs";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import copyIcon from "@/assets/icons/copy.svg";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import classNames from "classnames";
import StatusIcon from "./StatusIcon";
import useDebounce from "@/hooks/useDebounce";
import CustomPagination from "@/components/_Common/CustomPagination";
import EnabledPaymentsIcon from "./EnabledPaymentsIcon";
import FlagRender from "@/components/FlagRender";

interface OrdersTableProps {
  handleOrderSelected: (order: OrdersData, user: UserOrders) => void;
}

const USER_CHECKOUT_BASE = import.meta.env.VITE_CHECKOUT_BASE;
const LANDING_CHECKOUT_BASE = import.meta.env.VITE_LANDING_CHECKOUT;

const OrdersTable = ({ handleOrderSelected }: OrdersTableProps) => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler, copyToClipboard } = useAuth();

  const [pagination, setPagination] = useState<any>({});

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const debouncedSearch = useDebounce(filterData?.search, 500);

  const page = filterData.page ? parseInt(filterData.page) : 1;

  let dataFilters = { ...filterData };
  delete dataFilters?.search;
  delete dataFilters?.sorts;
  delete dataFilters?.page;

  const { data, isLoading } = listOrders({
    page,
    search: debouncedSearch,
    filters: dataFilters,
    per_page: perPage,
  });

  const paging = {
    total: data ? data?.total : 1,
    last_page: data ? data.last_page : 1,
    current_page: data ? data.current_page : 1,
  };

  const setPage = (value: any) => {
    setFilters((p: any) => {
      p.set("page", value);
      return p;
    });
  };

  const handleFilterChange = ({ target: { name, value } }) => {
    setFilters((p) => {
      if (value) {
        p.set(name, value);
      } else {
        p.delete(name);
      }
      p.set("page", "1");
      return p;
    });
  };

  const copy = (link: string) => {
    snackHandler(`Copied: \n\n ${link}`);
    navigator.clipboard.writeText(link);
  };

  // const copyPaymentLink = (order: any) => {
  //   if (!order) return;

  //   let link = "";

  //   const isTutoring =
  //     order?.user?.is_tutoring || order?.typeof === UserTypeof.Tutoring;

  //   if (order?.typeof !== UserTypeof.Lead || isTutoring) {
  //     // link = `${USER_CHECKOUT_BASE}order/${order.token}/`;
  //     link = `${LANDING_CHECKOUT_BASE}/checkout-page?product_tk=${order.product_tk}&user_tk=${order.user_tk}&order_tk=${order.token}`;
  //   } else {
  //     link = `${LANDING_CHECKOUT_BASE}/checkout-page?product_tk=${order.product_tk}&user_tk=${order.user_tk}&order_tk=${order.token}`;
  //   }

  //   snackHandler(`Copied: \n\n ${link}`);

  //   navigator.clipboard.writeText(link);
  // };

  const copyLinkCheckout = (order: any) => {
    if (!order) return;

    copy(USER_CHECKOUT_BASE + "order/" + order.token);
  };

  const copyLinkLandingCheckout = (order: any) => {
    if (!order) return;

    let link = "";

    const isTutoring =
      order?.user?.is_tutoring || order?.typeof === UserTypeof.Tutoring;

    const BASE = LANDING_CHECKOUT_BASE;

    const params = `/checkout-page?product_tk=${order.product_tk}&user_tk=${order.user_tk}&order_tk=${order.token}`;

    if (order?.typeof !== UserTypeof.Lead || isTutoring) {
      link = BASE + "/admission" + params;
    } else {
      link = BASE + params;
    }

    copy(link);
  };

  const columns: GridColDef[] = [
    {
      field: "lang",
      headerName: "",
      align: "center",
      maxWidth: 75,
      renderCell: ({ row }) => (
        <Box className="flex items-center">
          {/* {Boolean(row?.tutoring && !isAdmission) && <span>A</span>} */}
          <FlagRender
            flag={
              row?.user?.lang == "de"
                ? row?.phone?.includes("+41")
                  ? "ch"
                  : row?.phone?.includes("+43")
                    ? "at"
                    : "de"
                : row?.user?.lang
            }
          />
        </Box>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 130,
      valueGetter: ({ row }: GridRenderCellParams<OrdersData>) =>
        format(parseISO(row.created_at), "EEE, dd-M HH:mm"),
      renderCell: ({ row }: GridRenderCellParams<OrdersData>) => (
        <MyTooltip title={dayjs(row.created_at).format("YYYY-MM-DD HH:mm")}>
          {format(parseISO(row.created_at), "EEE, dd-M HH:mm")}
        </MyTooltip>
      ),
    },
    {
      field: "left_time",
      headerName: "Left Time",
      width: 130,
      renderCell: ({ row }: GridRenderCellParams<OrdersData>) => {
        const expired =
          new Date(row.left_time).getTime() < new Date().getTime();

        return (
          <Box
            title={(expired ? "Expired" : "Active") + ": " + row.left_time}
            className={classNames("text-base font-semibold", {
              "text-red-600": expired,
              "text-green-600": !expired,
            })}
          >
            {format(parseISO(row.left_time), "EEE, dd-M HH:mm")}
          </Box>
        );
      },
    },
    {
      field: "token",
      headerName: "Order Token",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams<OrdersData>) => row?.token,
      renderCell: ({ row }: GridRenderCellParams<OrdersData>) => (
        <MyTooltip>
          <button
            onClick={() => copyToClipboard(row?.token)}
            className="block text-left truncate"
          >
            {row?.token}
          </button>
        </MyTooltip>
      ),
    },
    {
      field: "first_name",
      headerName: "Full Name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }: GridRenderCellParams<OrdersData>) =>
        row?.user?.first_name && row?.user?.last_name
          ? `${row?.user?.first_name} ${row?.user?.last_name}`
          : "-",
      renderCell: ({ row }: GridRenderCellParams<OrdersData>) =>
        row?.user?.first_name && row?.user?.last_name ? (
          <MyTooltip>
            {`${row?.user?.first_name} ${row?.user?.last_name}`}
          </MyTooltip>
        ) : (
          "-"
        ),
    },
    {
      field: "typeof",
      width: 90,
      valueGetter: ({ row }: GridRenderCellParams<OrdersData>) =>
        fUpper(row.typeof) || "-",
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      headerAlign: "center",
      width: 75,
      renderCell: ({ row }: GridRenderCellParams<OrdersData>) => (
        <StatusIcon
          status={row?.status}
          className="h-5"
        />
      ),
    },
    {
      field: "enabled_payments",
      headerName: "Enabled Payments",
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      headerAlign: "center",
      width: 160,
      renderCell: ({ row }: GridRenderCellParams<OrdersData>) =>
        row?.enabled_payments ? (
          <EnabledPaymentsIcon
            providers={row.enabled_payments}
            className="h-5 w-5"
            showBadge={true}
          />
        ) : (
          <></>
        ),
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }: GridRenderCellParams<OrdersData>) => (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            <MenuItem
              key="copy_email_user"
              onClick={() => copyToClipboard(row?.user?.email ?? row?.email)}
            >
              <ListItemIcon>
                <Email fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy email user</ListItemText>
            </MenuItem>

            {row?.status === "initialized" && [
              <MenuItem
                key="copy_link_checkout"
                onClick={() => copyLinkCheckout(row)}
              >
                <ListItemIcon>
                  <img
                    src={copyIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Copy link checkout</ListItemText>
              </MenuItem>,

              <MenuItem
                key="copy_link_landing_checkout"
                onClick={() => copyLinkLandingCheckout(row)}
                sx={{ display: row?.typeof !== UserTypeof.Lead ? "none" : "flex" }}
              >
                <ListItemIcon>
                  <img
                    src={copyIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Copy link landing checkout</ListItemText>
              </MenuItem>,

              <MenuItem
                key="edit"
                onClick={() => handleOrderSelected(row, row?.user)}
              >
                <ListItemIcon>
                  <img
                    src={editIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>,
            ]}
          </MenuList>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (
      (paging.last_page == 1 && isLoading) ||
      paging.last_page == pagination.last_page
    ) {
      return;
    }

    if (paging?.total == 0) return;

    setPagination(paging);
  }, [paging]);

  return (
    <BasePage title="ORDERS">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={data?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: { theme, filterData, setFilters, handleFilterChange },
          pagination: { page, setPage, pagination },
        }}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />
    </BasePage>
  );
};

export default OrdersTable;
