import dayjs from "dayjs";

export default (
  data: string,
  nameFile: string = `data-${dayjs().format("DD-MM-YYYY")}.csv`,
  type: string = "text/csv;charset=utf-8;"
) => {
  const link = document.createElement("a");
  link.setAttribute("href", URL.createObjectURL(new Blob([data], { type })));
  link.setAttribute(
    "download",
    nameFile.includes(".csv") ? nameFile : nameFile + ".csv"
  );
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
