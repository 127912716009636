import client from "@/api/client";
import processErrorMessages from "@/api/client/processErrorMessages";
import saveIcon from "@/assets/icons/save.svg";
import { uuid } from "@/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useMemo, useState } from "react";

const PriceBox = ({ productPrices, setProductPrices, product_id, countries, snackHandler, invalidateProducts, hidden, expanded, handleAccordionChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const formId = useMemo(() => uuid(), []);

  const createOrUpdatePrice = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    try {
      const { item, message } = await client.product.prices.create_or_update(product_id, formData, { headers: { "Content-Type": "multipart/form-data" } });

      if (item) {
        setProductPrices(item);
        snackHandler(message, "success");
      }

    } catch (error) {
      processErrorMessages(error);
    } finally {
      setIsLoading(false);
      invalidateProducts();
    }
  };

  const deletePrice = async (priceId) => {
    setIsLoading(true);

    try {
      const { message } = await client.product.prices.delete(priceId);

      if (message) {
        snackHandler(message, "success");
      }

    } catch (error) {
      processErrorMessages(error);
    } finally {
      setIsLoading(false);
      invalidateProducts();
    }
  }

  const restorePrice = async (priceId) => {
    setIsLoading(true);

    try {
      const { message } = await client.product.prices.restore(priceId);

      if (message) {
        snackHandler(message, "success");
      }

    } catch (error) {
      processErrorMessages(error);
    } finally {
      setIsLoading(false);
      invalidateProducts();
    }
  }

  const handleProductPricesChange = async (newValue) => {
    const prices = productPrices.filter((price) => !price.deleted_at);

    //if new value is deleted
    if (newValue.length < prices.length) {
      const deletedPrices = prices.filter((price) => !newValue.map((country) => country.key.toLowerCase()).includes(price.country));

      if (deletedPrices.length) {
        for (const deletedPrice of deletedPrices) {
          if (deletedPrice?.id) {
            await deletePrice(deletedPrice.id);
            setProductPrices(prev => prev.map((price) => price.id === deletedPrice.id ? { ...price, deleted_at: new Date().toISOString() } : price));
          } else {
            setProductPrices(prev => prev.filter((price) => price.country !== deletedPrice.country));
          }
        }
      }

    } else if (newValue.length > prices.length) {
      const newPrice = newValue.find((country) => !prices.map((price) => price.country).includes(country.key.toLowerCase()));
      const deletedPrice = productPrices.find((price) => price.deleted_at && price.country === newPrice.key.toLowerCase());

      if (deletedPrice?.id) {
        await restorePrice(deletedPrice.id);
        setProductPrices(productPrices.map((price) => price.id === deletedPrice.id ? { ...price, deleted_at: null } : price));
      } else if (newPrice) {
        setProductPrices([...productPrices, { country: newPrice.key.toLowerCase(), currency: newPrice.currency, price: "" }]);
      }
    }
  }

  if (hidden) return null;

  return (
    <div>
      <Accordion
        component="form"
        id={formId}
        onSubmit={createOrUpdatePrice}
        expanded={expanded === "acc-prices"}
        onChange={handleAccordionChange("acc-prices")}
      >
        <AccordionSummary
          id={"panel1-header " + formId}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
        >
          Prices
        </AccordionSummary>

        <AccordionDetails className="flex flex-col gap-2">
          <Autocomplete
            multiple
            options={countries ?? []}
            getOptionLabel={(option) => option?.value}
            value={productPrices
              .filter((price) => !price?.deleted_at)
              .map((price) => countries?.find((country) => country.key.toLowerCase() === price.country))
              .filter(Boolean)
              //filterout deleted prices
            }
            onChange={(_, newValue) => handleProductPricesChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Countries"
                placeholder="Countries"
                variant="outlined"
                fullWidth
              />
            )}
            isOptionEqualToValue={(option, value) => option.key.toLowerCase() === value.key.toLowerCase()}
          />
          <Box className="grid grid-cols-5 md:auto-cols-auto gap-4 items-center mt-4">
            {
              productPrices?.map((price, i) => {

                return !price?.deleted_at && (
                  <Box className="flex flex-col gap-4 max-w-[200px]"
                    key={formId + i + price?.country}
                  >
                    <span>
                      {countries?.find((c) => c.key.toLowerCase() === price.country)?.value}
                    </span>
                    <input type="text" hidden name={`prices[${i}][country]`} value={price?.country} readOnly />
                    <input type="text" hidden name={`prices[${i}][product_id]`} value={product_id} readOnly />
                    <input type="text" hidden name={`prices[${i}][currency]`} value={price?.currency} readOnly />
                    <TextField
                      required
                      name={`prices[${i}][price]`}
                      label="Price"
                      placeholder="amount"
                      defaultValue={price.price ?? ""}
                      InputLabelProps={{ shrink: true }}
                      className="col-span-1"
                      //add endAdornment
                      InputProps={{
                        endAdornment: (
                          <>
                            {price.currency}
                          </>
                        )
                      }}
                      inputProps={{ pattern: "^-?[0-9]*\.?[0-9]+$" }}

                    />
                  </Box>
                )
              })
            }
          </Box>



          <Box className="flex gap-2 justify-end !py-2">
            <LoadingButton
              form={formId}
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoading}
              className="flex justify-center"
              title="Save"
            >
              <img
                src={saveIcon}
                className={`icon !m-0 ${isLoading ? "opacity-0" : ""}`}
              />
            </LoadingButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PriceBox;
