import { useRef, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, Radio, RadioGroup, FormControlLabel, TextField } from "@mui/material";
import useAPI from "@/api/useAPI";
import saveIcon from "@/assets/icons/save.svg";

const FOR = ["User", "Tutor"];

const MATERIAL_TYPE = [
  "Grammar",
  "Speaking",
  "Ielts Writing",
  "Ielts Listening",
  "Ielts Reading",
  "Ielts Speaking",
];

const DEFAULT_DATA = {
  for: FOR[0],
  name: "",
  file: "",
  link: "",
  material_type: MATERIAL_TYPE[0],
};

interface Data {
  for: string;
  name: string;
  file: string | Blob;
  link: string;
  material_type: string;
}

interface IProps {
  className?: string;
}

const MaterialBox = ({ className = "" }: IProps) => {
  const { snackHandler } = useAuth();

  const fileRef = useRef<HTMLInputElement>();

  const [data, setData] = useState<Data>(DEFAULT_DATA);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>("link");

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    if (file.size > 220000000) {
      snackHandler("File size is bigger than 220mb", "error");

      if (fileRef.current) {
        fileRef.current.value = "";
      }
      return;
    }

    setData((prev) => ({ ...prev, file }));
  };

  const loadMaterial = async (e: any) => {
    e.preventDefault();

    const formData = new FormData();

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }

    setIsLoading(true);
    const res = await useAPI("/v1/material/create", formData, {
      headers: { "Content-Type": "multipart/mixed" },
    });
    setIsLoading(false);

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed creating material", "error");
      return;
    }

    if (fileRef.current) {
      fileRef.current.value = "";
    }

    queryClient.setQueriesData(
      ["events", "listAllMaterialsAndEvents"],
      (old: any) => ({
        ...old,
        materials: [...old.materials, res?.data],
      })
    );

    snackHandler("Success created material");
    setData(DEFAULT_DATA);
  };

  return (
    <Box onSubmit={loadMaterial} component="form" className={className}>
      <TextField
        required
        fullWidth
        name="name"
        label="Name material"
        value={data.name}
        variant="outlined"
        onChange={(event) => setData({ ...data, name: event.target.value })}
        placeholder="Name material"
      />

      <RadioGroup
        row
        name="inputType"
        value={inputType}
        onChange={(e) => setInputType(e.target.value)}
      >
        <FormControlLabel value="link" control={<Radio />} label="Link" />
        <FormControlLabel value="file" control={<Radio />} label="File" />
      </RadioGroup>

      {inputType === "link" ? (
        <TextField
          required
          fullWidth
          name="link"
          label="Material Link"
          value={data.link}
          variant="outlined"
          onChange={(event) => setData({ ...data, link: event.target.value })}
          placeholder="Material Link"
        />
      ) : (
        <TextField
          required
          type="file"
          variant="outlined"
          inputRef={fileRef}
          onChange={(e) => handleFileChange(e)}
          InputProps={{ inputProps: { accept: "application/pdf, video/mp4" } }}
          placeholder="file"
        />
      )}

      <TextField
        select
        required
        fullWidth
        name="material_type"
        label="Type"
        value={data?.material_type || ""}
        variant="outlined"
        onChange={(e) => setData({ ...data, material_type: e.target.value })}
        placeholder="Type"
      >
        {MATERIAL_TYPE.map((e, i) => (
          <MenuItem key={e + i} value={e}>
            {e}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        required
        fullWidth
        name="for"
        label="For"
        value={data?.for ?? ""}
        variant="outlined"
        onChange={(e) => setData({ ...data, for: e.target.value })}
      >
        {FOR.map((f, i) => (
          <MenuItem key={f + i} value={f}>
            {f}
          </MenuItem>
        ))}
      </TextField>

      <Box className="flex justify-end">
        <LoadingButton type="submit" variant="outlined" loading={isLoading}>
          <img src={saveIcon} className="icon" />
          CREATE
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default MaterialBox;
