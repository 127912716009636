import { useState } from "react";
import { MenuProps } from ".";
import { Box, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import dayjs from "dayjs";
import ConfirmButton from "../ConfirmationButton";

interface IProps {
  theme: any;
  newEvent: any;
  calendars: Array<any>;
  selectedDay: Date;
  createEvent: (user: string, dateTime: string) => void;
}

const ToBeCalled = ({
  theme,
  newEvent,
  calendars,
  selectedDay,
  createEvent,
}: IProps) => {
  const [time, setTime] = useState<string>("08:00");
  const [click, setClick] = useState<boolean>(false);

  return (
    <Box className="pt-1 pb-4 md:pt-6 pl-4 grow flex flex-col gap-4">
      <Box className="text-sm font-bold leading-20 text-white">
        Schedule for {dayjs(selectedDay).format("MMMM DD, YYYY")}
      </Box>

      <Box>
        {calendars.map((user: string, i: number) => (
          <Box
            sx={{ color: theme.palette.primary.main }}
            key={user + i}
            className="pr-4 flex flex-col min-w-[250px]"
          >
            <Box key={i}>
              <Box className="text-sm font-bold leading-20 text-edu-green mt-5">
                {user.toUpperCase()}
              </Box>

              <Box className="my-5 flex flex-col">
                <FormControl className="w-full !mb-3">
                  <InputLabel id="demo-simple-select-label">Time</InputLabel>

                  <Select
                    id="demo-simple-select"
                    name="certification"
                    label="Time"
                    value={time}
                    labelId="demo-simple-select-label"
                    onChange={(event) => {
                      setTime(event.target.value);
                      setClick(false);
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ style: { color: "white" } }}
                  >
                    {[...Array(14).keys()].map((hour: number, n: number) => {
                      hour = hour + 8;
                      const value = `${hour > 9 ? hour : "0" + hour}:00`;

                      return (
                        <MenuItem
                          key={i + n}
                          value={value}
                        >
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <ConfirmButton
                  show={click}
                  text="CREATE"
                  onClick={() => setClick(true)}
                  disabled={false}
                  isLoading={newEvent.isLoading}
                  onConfirm={() =>
                    createEvent(
                      user,
                      dayjs(selectedDay).format("YYYY-MM-DD ") + time
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ToBeCalled;
