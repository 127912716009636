// @ts-nocheck
import { useState, useEffect, createRef } from "react";
import { Box, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const MiniPlayer = ({ audioURL = "" }) => {
  const audioRef = createRef<HTMLAudioElement>();

  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(0);

  const handlePlay = () => {
    setPlaying(true);
    audioRef.current?.play();
  };

  const handlePause = () => {
    setPlaying(false);
    audioRef.current?.pause();
  };

  useEffect(() => {
    if (audioRef) {
      handlePause();
      audioRef.current.load();
    }
  }, [audioURL]);

  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      {audioURL ? (
        <>
          <audio ref={audioRef} className="hidden" controls>
            <source type="audio/mpeg" src={audioURL} />
          </audio>
          <Button onClick={playing ? handlePause : handlePlay}>
            {playing ? <PauseCircleFilledIcon color="primary" /> : <PlayCircleFilledWhiteIcon color="primary" />}
          </Button>
        </>
      ) : (
        <Button onClick={() => setLoading(1)}>
          {loading === 0 ? <DownloadIcon color="primary" /> : ""}
          {loading === 1 ? <RotateRightIcon color="primary" /> : ""}
          {loading === 2 ? <ErrorOutlineIcon color="primary" /> : ""}
        </Button>
      )}
    </Box>
  );
};

export default MiniPlayer;
