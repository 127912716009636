import { useState, useEffect } from "react";
import useAuth from '@/hooks/useAuth';
import { Box, MenuItem, useTheme, TextField } from "@mui/material";
import useAPI from "@/api/useAPI";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import searchIcon from "@/assets/icons/search.svg";

const allowedCallTypes = [
  { label: "None", value: "" },
  { label: "Low rating", value: "Low rating" },
];

const languageOptions = [
  { label: "None", value: "" },
  { label: "Italian", value: "it" },
  { label: "Spanish", value: "es" },
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "Portuguese", value: "pt" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "To be called", value: "To be called" },
  { label: "To be recalled", value: "To be recalled" },
  { label: "No reply", value: "No reply" },
  { label: "Stand by", value: "Stand by" },
];

const SearchFilter = ({ onSearch, applyFilters }) => {
  const theme = useTheme();
  const { userAdmin } = useAuth();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLang, setSelectedLang] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [callTypeOptions, setCallTypeOptions] = useState(allowedCallTypes);
  const [selectedCallType, setSelectedCallType] = useState("");

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleCallTypeChange = (event: any) => {
    const newCallType = event.target.value;
    setSelectedCallType(newCallType);
    applyFilters({ callType: newCallType, lang: selectedLang });
  };

  const handleLangChange = (event: any) => {
    const newLang = event.target.value;
    setSelectedLang(newLang);
    applyFilters({ callType: selectedCallType, lang: newLang });
  };

  const handleStatusChange = (event: any) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    applyFilters({ callType: selectedCallType, status: newStatus });
  };

  useEffect(() => {
    const fetchCallTypes = async () => {
      try {
        const response = await useAPI(
          `v1/call-center/call-types/${userAdmin.user.uuid}`,
          {},
          { type: "get" }
        );

        const { types } = response;

        const formattedTypes = types
          .filter((type: string) =>
            allowedCallTypes.some(
              (allowedType) =>
                allowedType.value.toLowerCase() === type.toLowerCase()
            )
          )
          .map((type: string) => ({
            label: allowedCallTypes.find(
              (allowedType) =>
                allowedType.value.toLowerCase() === type.toLowerCase()
            )?.label,
            value: type,
          }));

        setCallTypeOptions([{ label: "None", value: "" }, ...formattedTypes]);
      } catch (error) { }
    };

    fetchCallTypes();
  }, [userAdmin.user.uuid]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, onSearch]);

  return (
    <Box className="flex gap-4 flex-wrap !items-stretch">
      <Dropdown
        text="Filter"
        icon={filterIcon}
        active={Boolean(selectedCallType || selectedLang)}
      >
        <Box className="flex flex-col w-[250px] p-2">
          <TextField
            select
            fullWidth
            label="Call Type"
            value={selectedCallType}
            onChange={handleCallTypeChange}
          >
            {callTypeOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Language"
            value={selectedLang}
            onChange={handleLangChange}
          >
            {languageOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Status"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            {statusOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Dropdown>

      <TextField
        size="small"
        value={searchTerm}
        variant="outlined"
        onChange={handleInputChange}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              style={{ marginRight: 10, filter: theme.palette.filter }}
            />
          ),
        }}
        placeholder="Search..."
        autoComplete="off"
      />
    </Box>
  );
};

export default SearchFilter;
