import { memo, useMemo } from "react";
import useAuth from '@/hooks/useAuth';
import { ENGLISH_LEVEL } from "@/pages/Users/UserSingle";
import { ONBOARDING_VALUES_NEW_USERS } from "./IndexUsersList";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import callIcon from "@/assets/icons/call.svg";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import useUserApi from "@/api/useUserApi";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const ToolbarUsersList = ({
  filters,
  setPage,
  handleMassCall,
  selectedRows,
  // pathsData,
  setFilters,
  productsData,
}) => {
  const { isMember } = useAuth();
  const { callDownloadNewUsers, callGetStartMonths } = useUserApi();
  const { data: startMonths } = callGetStartMonths();

  const handleChange = ({ target: { name, value } }) => {
    setFilters(
      (p: any) => {
        if (value) p.set(name, value);
        else p.delete(name);
        return p;
      },
      { replace: true }
    );
    setPage(1);
  };

  const englishMenu = useMemo(
    () =>
      ENGLISH_LEVEL.map((el: string, i: number) => (
        <MenuItem
          key={i}
          value={el}
        >
          {el}
        </MenuItem>
      )),
    []
  );

  const startMonthMenu = useMemo(
    () =>
      startMonths?.data?.map((e: any) => (
        <MenuItem
          key={e.month}
          value={e.month}
        >
          {e.month_en}
        </MenuItem>
      )),
    [startMonths]
  );

  const productMenu = useMemo(
    () =>
      productsData
        ?.filter((p: { id: number }) => p.id)
        .map((e: { id: number; name: string }) => (
          <MenuItem
            key={e.id}
            value={e.id}
          >
            {e.name}
          </MenuItem>
        )),
    [JSON.stringify(productsData)]
  );

  // const pathMenu = useMemo(
  //   () =>
  //     pathsData
  //       ?.filter((p: { id: number }) => p.id)
  //       .map((e: { id: number; name: string }) => (
  //         <MenuItem
  //           key={e.id}
  //           value={e.id}
  //         >
  //           {e.name}
  //         </MenuItem>
  //       )),
  //   [JSON.stringify(pathsData)]
  // );

  const languageMenu = useMemo(
    () =>
      [{ name: "- Remove -", iso: "" }, ...LANGUAGES].map(
        (item: { iso: string; name: string }, i: number) => (
          <MenuItem
            key={i}
            value={item.iso}
          >
            {item.name}
          </MenuItem>
        )
      ),
    []
  );

  const filtersApplied = useMemo(
    () =>
      Object.keys(filters).filter(
        (key) => !["search", "sorts", "page"].includes(key)
      ).length,
    [filters]
  );

  const handleDelete = (key: string) => {
    setFilters(
      (p: any) => {
        p.delete(key);
        return p;
      },
      { replace: true }
    );
  };

  const ONBOARDING_VALUES_NEW_USERS_MEMO = useMemo(
    () =>
      ONBOARDING_VALUES_NEW_USERS?.map((o, i) => (
        <MenuItem
          key={o + i}
          value={o}
        >
          {o}
        </MenuItem>
      )),

    []
  );

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Button
          disabled={selectedRows.length === 0}
          variant="outlined"
          onClick={handleMassCall}
          style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}
        >
          <img
            src={callIcon}
            className="icon"
          />
          Call Selected Users
        </Button>

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(filtersApplied)}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <TextField
                select
                fullWidth
                name="english_level"
                label="English level"
                value={filters?.english_level || ""}
                onChange={handleChange}
              >
                {englishMenu}
              </TextField>

              <TextField
                select
                fullWidth
                name="start_month"
                label="Start month"
                value={filters?.start_month || ""}
                onChange={handleChange}
              >
                {startMonthMenu}
              </TextField>

              <TextField
                select
                fullWidth
                name="product"
                label="Product"
                value={filters?.product || ""}
                onChange={(e: any) => {
                  handleChange(e);
                  handleDelete("path");
                }}
              >
                {productMenu}
              </TextField>

              {/* <TextField
                select
                fullWidth
                name="path"
                label="Path"
                value={filters?.path || ""}
                onChange={(e: any) => {
                  handleChange(e);
                  handleDelete("product");
                }}
              >
                {pathMenu}
              </TextField> */}

              <TextField
                select
                fullWidth
                name="lang"
                color="primary"
                label="Language"
                value={filters?.lang || ""}
                onChange={handleChange}
              >
                {languageMenu}
              </TextField>

              <TextField
                select
                fullWidth
                name="onboarding"
                color="primary"
                label="Onboarding"
                value={filters?.onboarding || ""}
                onChange={handleChange}
              >
                {ONBOARDING_VALUES_NEW_USERS_MEMO}
              </TextField>
            </Box>
          }
        />

        {!isMember && (
          <Button
            variant="outlined"
            onClick={() => callDownloadNewUsers(filters, "New Users.csv")}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};

export default memo(ToolbarUsersList);
