import { createRef } from "react";
import useAuth from '@/hooks/useAuth';
import { STATUS } from "../helpers/constants";
import { useCrmContext } from "../state/CrmV3Context";
import { useCrmDialogsContext } from "../state/CrmDialogsContext";
import { INAnchorsEl, INDialogsEl } from "./crm-upselling.types";
import { baseWhatsApp, timerWhatsApp } from "./WhatsAppTexts";
import {
  Box,
  Badge,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  sendMessageReminder,
  generateLinkCheckout,
  interestedInTheFuture,
  resetDiscountCodeUser,
} from "@/pages/Upselling/v2/Crm/helpers/side-effects";
import {
  InfoIcon,
  ForwardIcon,
  PriceCheckIcon,
  TimerRoundedIcon,
  MessageRoundedIcon,
  RefreshRoundedIcon,
  HardwareRoundedIcon,
  EmojiEventsRoundedIcon,
} from "@/components";
import StarIcon from "@mui/icons-material/Star";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InvoiceUserDialog, {
  IForwardRef as InvoiceUserDialogRef,
} from "@/pages/Users/components/InvoiceUserDialog";
import useGenerateTemplateCrm, {
  convertRowCrm,
} from "@/components/useGenerateTemplateCrm";
import copyIcon from "@/assets/icons/copy.svg";
import mailIcon from "@/assets/icons/mail.svg";
import callIcon from "@/assets/icons/call.svg";
import viewIcon from "@/assets/icons/view.svg";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import videoIcon from "@/assets/icons/video.svg";
import eventIcon from "@/assets/icons/event.svg";
import invoiceIcon from "@/assets/icons/invoice.svg";
import presenceIcon from "@/assets/icons/presence.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";

const ActionCell = ({ row, meeting_status_color, convert_status_color }) => {
  const { snackHandler, copyToClipboard, userAdmin } = useAuth();
  const { commentsState, rowSelectedState } = useCrmContext();

  // Dialog context
  const {
    crmDialogState,
    PresenceUserDialogRef,
    dialogsElState,
    anchorsElState,
    eventRef,
  } = useCrmDialogsContext();

  const handleSetDialogEl = (typeAnchor: keyof INDialogsEl, row: any): void => {
    rowSelectedState.setRowSelected(row);
    dialogsElState.setDialogsEl((prevState) => ({
      ...prevState,
      [typeAnchor]: { value: true },
    }));
  };

  const handleSetAnchorEl = (row: any, params: INAnchorsEl): void => {
    rowSelectedState.setRowSelected(row);
    anchorsElState.setAnchorsEl((p: INAnchorsEl) => ({
      ...p,
      open: true,
      ...params,
    }));
  };

  const InvoiceUserDialogRef = createRef<InvoiceUserDialogRef>();

  return (
    <>
      <EduBadge
        color="primary"
        variant="dot"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={Number(Boolean(row?.crm_upselling?.comments))}
      >
        <Dropdown text={<MoreVertIcon />}>
          <MenuList>
            <MenuItem
              key="Copy email"
              title="Copy email"
              color="primary"
              onClick={() => copyToClipboard(row.email)}
            >
              <ListItemIcon>
                <img
                  src={mailIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Copy email</ListItemText>
            </MenuItem>

            <MenuItem
              key="Feedbacks"
              title="Feedbacks"
              color="primary"
              onClick={(e) => {
                rowSelectedState.setRowSelected(row);
                handleSetAnchorEl(row, {
                  element: e.currentTarget,
                  template: "feedbacks",
                });
              }}
            >
              <ListItemIcon>
                <StarIcon className="icon no-margin" />{" "}
              </ListItemIcon>
              <ListItemText>Feedbacks</ListItemText>
            </MenuItem>

            <MenuItem
              key="Purchase history"
              title="Purchase History"
              color="primary"
              onClick={(e) => {
                rowSelectedState.setRowSelected(row);
                handleSetAnchorEl(row, {
                  element: e.currentTarget,
                  template: "purchaseHistory",
                });
              }}
            >
              <ListItemIcon>
                <ShoppingCartIcon className="icon no-margin" />{" "}
              </ListItemIcon>
              <ListItemText>Purchase History</ListItemText>
            </MenuItem>

            <MenuItem
              key="WhatsApp"
              title="Go to WhatsApp"
              onClick={() =>
                baseWhatsApp({
                  phone: row.phone,
                  nameUser: row.first_name,
                })
              }
            >
              <ListItemIcon>
                <i
                  style={{ fontSize: 16 }}
                  className="fa-brands fa-whatsapp"
                />
              </ListItemIcon>
              <ListItemText>WhatsApp</ListItemText>
            </MenuItem>

            {[
              STATUS.Admin,
              STATUS.MailSent,
              STATUS.ToBeCalled,
              STATUS.Reschedule,
              STATUS.NoShow,
              STATUS.NotInterested,
            ].includes(row?.crm_upselling?.status || "New") && [
                <MenuItem
                  key="Schedule meet"
                  title="Schedule meet"
                  onClick={() => handleSetDialogEl("firstMeeting", row)}
                >
                  <ListItemIcon>
                    <img
                      src={callIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Schedule meet</ListItemText>
                </MenuItem>,
              ]}

            {[STATUS.UpsellingScheduled].includes(
              row?.crm_upselling?.status
            ) && [
                <MenuItem
                  key="Edit meet"
                  title="Edit meet"
                  onClick={() => handleSetDialogEl("updateMeeting", row)}
                >
                  <ListItemIcon>
                    <img
                      src={videoIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Edit meet</ListItemText>
                </MenuItem>,

                <MenuItem
                  key="Send message on WhatsApp"
                  title="Send message on WhatsApp"
                  onClick={() =>
                    useGenerateTemplateCrm(
                      "reminder_1",
                      convertRowCrm({
                        ...row,
                        version: "crm-upselling",
                      }),
                      userAdmin,
                      snackHandler,
                      undefined,
                      () =>
                        timerWhatsApp({
                          meetings: row?.crm_upselling?.meetings?.meet,
                          phone: row.phone,
                          nameUser: row.first_name,
                          nameAdmin: userAdmin.user.first_name,
                          snackHandler,
                        })
                    )
                  }
                >
                  <ListItemIcon>
                    <TimerRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Send message on WhatsApp</ListItemText>
                </MenuItem>,

                <MenuItem
                  key="Set response user"
                  title="Set response user"
                  style={{ color: meeting_status_color || "grey" }}
                  onClick={(e: any) =>
                    handleSetAnchorEl(row, {
                      element: e.currentTarget,
                      buttons: ["present", "no reply"],
                      template: "otherStatus",
                      value: "meeting_status",
                    })
                  }
                >
                  <ListItemIcon>
                    <Box
                      className="rounded-full transitions-colors duration-200"
                      sx={{
                        p: "0.55rem",
                        boxShadow: meeting_status_color
                          ? `0 0 5px ${meeting_status_color}`
                          : "",
                        bgcolor: meeting_status_color || "grey",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>Set response user</ListItemText>
                </MenuItem>,
              ]}

            {[STATUS.UpsellingDone, STATUS.StandBy].includes(
              row?.crm_upselling?.status
            ) && (
                <div>
                  <MenuItem
                    title="Send template on Email"
                    onClick={() =>
                      generateLinkCheckout(row, snackHandler, copyToClipboard)
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={copyIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy link for checkout</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Send template on Email"
                    onClick={(e) => {
                      rowSelectedState.setRowSelected(row);
                      handleSetAnchorEl(row, {
                        element: e.currentTarget,
                        template: "email",
                      });
                    }}
                  >
                    <ListItemIcon>
                      <EmojiEventsRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Send template on Email</ListItemText>
                  </MenuItem>
                </div>
              )}

            {[STATUS.Upsold, STATUS.ToBeConverted].includes(
              row?.crm_upselling?.status
            ) && (
                <MenuItem
                  title="Info"
                  onClick={(e) => {
                    return handleSetAnchorEl(row, {
                      element: e.currentTarget,
                      template: "info",
                    });
                  }}
                >
                  <ListItemIcon>
                    <InfoIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Info</ListItemText>
                </MenuItem>
              )}

            {[STATUS.ToBeConverted].includes(row?.crm_upselling?.status) && [
              <MenuItem
                key="Send message on WhatsApp"
                title="Send message on WhatsApp"
                onClick={() =>
                  useGenerateTemplateCrm(
                    "reminder_3",
                    convertRowCrm({
                      ...row,
                      version: "crm-upselling",
                    }),
                    userAdmin,
                    snackHandler,
                    undefined,
                    () => sendMessageReminder(row, snackHandler, userAdmin)
                  )
                }
              >
                <ListItemIcon>
                  <HardwareRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Send message on WhatsApp</ListItemText>
              </MenuItem>,

              <MenuItem
                key="Set response user"
                title="Set response user"
                className="relative flex items-center"
                onClick={(e: any) =>
                  handleSetAnchorEl(row, {
                    element: e.currentTarget,
                    template: "otherStatus",
                    buttons: ["soon buying", "no reply"],
                    value: "convert_status",
                  })
                }
              >
                <ListItemIcon className="flex items-center">
                  <ForwardIcon
                    fontSize="small"
                    sx={{
                      transition: "color .2s linear",
                      position: "absolute",
                      filter: convert_status_color ? "blur(2px)" : "",
                      color: convert_status_color || "grey",
                    }}
                  />

                  <ForwardIcon
                    fontSize="small"
                    sx={{
                      transition: "color .2s linear",
                      position: "absolute",
                      color: convert_status_color || "grey",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Set response user</ListItemText>
              </MenuItem>,

              <MenuItem
                key="Reset discount code"
                title="Confirm payment"
                onClick={() => resetDiscountCodeUser(row.token, snackHandler)}
              >
                <ListItemIcon>
                  <RefreshRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Reset discount code</ListItemText>
              </MenuItem>,

              <MenuItem
                key="Confirm payment"
                title="Confirm payment"
                onClick={() => handleSetDialogEl("confirmPayment", row)}
              >
                <ListItemIcon>
                  <PriceCheckIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Confirm payment</ListItemText>
              </MenuItem>,
            ]}

            {[STATUS.Reschedule].includes(row?.crm_upselling?.status) && (
              <MenuItem
                title="Copy link for the platform user for rescheduled"
                onClick={() =>
                  copyToClipboard(
                    `https://edusogno.com/form/reschedule/edusogno-inglese/${row.token}`
                  )
                }
              >
                <ListItemIcon>
                  <img
                    src={eventIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Copy link rescheduled</ListItemText>
              </MenuItem>
            )}

            {[STATUS.NotInterested].includes(row?.crm_upselling?.status) && (
              <MenuItem
                title="Set response user"
                onClick={(e: any) =>
                  handleSetAnchorEl(row, {
                    element: e.currentTarget,
                    buttons: ENGLISH_LEVELS,
                    template: "changeEnglishLevel",
                  })
                }
              >
                <ListItemIcon>{row.english_level}</ListItemIcon>
                <ListItemText>Change english level</ListItemText>
              </MenuItem>
            )}

            {[STATUS.ToBeConverted, STATUS.InterestedInTheFuture].includes(
              row?.status
            ) && (
                <MenuItem onClick={() => interestedInTheFuture(eventRef, row)}>
                  <ListItemIcon>
                    <img
                      src={callIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Interested in the future</ListItemText>
                </MenuItem>
              )}

            <MenuItem
              title="Save your comments"
              onClick={(e) => {
                rowSelectedState.setRowSelected(row);
                commentsState.setComments(row?.crm_upselling?.comments);
                handleSetAnchorEl(row, {
                  element: e.currentTarget,
                  template: "comments",
                });
              }}
            >
              <ListItemIcon>
                <MessageRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Comments
                <Badge
                  sx={{ mx: 2 }}
                  variant={row?.crm_upselling?.comments ? "dot" : "standard"}
                  color="primary"
                />
              </ListItemText>
            </MenuItem>

            <MenuItem
              color="primary"
              title="Invoices"
              onClick={() => InvoiceUserDialogRef.current?.open(row.token)}
            >
              <ListItemIcon>
                <img
                  src={invoiceIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Invoices</ListItemText>
            </MenuItem>
            <MenuItem
              color="primary"
              title="Presence"
              onClick={() => PresenceUserDialogRef?.current?.open(row.token)}
            >
              <ListItemIcon>
                <img
                  src={presenceIcon}
                  className="icon"
                />
              </ListItemIcon>
              <ListItemText>Presence</ListItemText>
            </MenuItem>

            <MenuItem
              title="Info"
              color="primary"
              onClick={() =>
                crmDialogState.setViewCrmDialog({ open: true, data: row })
              }
            >
              <ListItemIcon>
                <img
                  src={viewIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      </EduBadge>
      <InvoiceUserDialog ref={InvoiceUserDialogRef} />
    </>
  );
};

export default ActionCell;
