import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import { queryClient } from "@/main";
import { ContentCopy } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { EVENT_TYPES, EventItem } from "@/interfaces";
import { clone, generateCasualCharacters } from "@/components/useHelpers";
import {
  DataGrid,
  GridColDef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import {
  useEvents,
  useDeleteEventMutation,
  useTechnicalProblemEventMutation,
  generateLink
} from "@/api/events";
import {
  Box,
  Popover,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EventDialog, { IForwardRef } from "./EventDialog";
import MaterialDialog, { IRef as IRefMaterialDialog } from "./MaterialDialog";
import ImportBulkUserDialog, {
  IForwardRef as ImportEventsRef,
} from "./ImportBulkUserDialog";
import EditExerciseMaterialDialog, {
  IForwardRef as EditMaterialRef,
} from "./EditExerciseMaterialDialog";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import useAuth from "@/hooks/useAuth";
import Toolbar from "../custom/Toolbar";
import copyIcon from "@/assets/icons/copy.svg";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import videoIcon from "@/assets/icons/video.svg";
import DeleteIcon from "@/assets/icons/delete.svg";
import CachedIcon from "@mui/icons-material/Cached";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchEvents from "./SearchEvents";
import useClipboard from "@/utils/hooks/use-clipboard";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useWeekOffset from "./useWeekOffset";
import DeleteIconMui from "@mui/icons-material/Delete";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import EventDelayedStatus from "./EventDelayedStatus";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const MODES = ["edit_exercise_material", "edit_class_students"];

const VITE_ROUTE_ACADEMY_USER = import.meta.env.VITE_ROUTE_ACADEMY_USER;
const VITE_ROUTE_ACADEMY_TUTOR = import.meta.env.VITE_ROUTE_ACADEMY_TUTOR;

export const splitCount = (v: string, s: string = ","): number =>
  v?.split(s)?.length || 0;

interface INDeleteEvent {
  id: number | string | undefined;
  openConfirm: boolean;
}

interface INTechnicalProblemEvent {
  id: number | string | undefined;
  value: number | undefined;
  openConfirm: boolean;
}

const today = new Date();

const ListEvents = () => {
  const theme = useTheme();
  const { isMobile, screen, perPage } = useScreen();
  const { copyToClipboard, isCopiedToClipboard } = useClipboard();
  const { isAdmin, snackHandler, copyToClipboard: copy } = useAuth();
  const { weekStart, weekEnd, handleNextWeek, handlePrevWeek } =
    useWeekOffset();

  const customPaginationRef = useRef();

  const refEvent = createRef<IForwardRef>();
  const refMaterialDialog = createRef<IRefMaterialDialog>();
  const refImportBulkEvent = createRef<ImportEventsRef>();
  const refEditEventAssociations = createRef<EditMaterialRef>();

  const [mode, setMode] = useState<any>("");
  const [selectedMode, setSelectMode] = useState<any[]>([]);
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [deletedEvent, setDeletedEvent] = useState<INDeleteEvent>();
  const [selectedEvents, setSelectedEvents] = useState<EventItem[]>([]);
  const [tutorAccessData, setTutorAccessData] = useState<{
    eventId: number;
    tutorToken: string;
  }>();
  const [tutorsAvailable, setTutorsAvailable] = useState<{
    tutors: Array<any>;
    element: any;
    eventId?: number;
    sendToTutor?: string;
  }>();
  const [dataSearchEvents, setDataSearchEvents] = useState<any>();
  const [isMoreSelectable, setIsMoreSelectable] = useState<boolean>(true);
  const [technicalProblemEvent, setTechnicalProblemEvent] =
    useState<INTechnicalProblemEvent>();
  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());

  const newFilterForSearch = clone(filterData);
  delete newFilterForSearch?.search;
  const EVENTS_KEY = {
    weekStart: format(weekStart, "yyyy-MM-dd"),
    weekEnd: format(weekEnd, "yyyy-MM-dd"),
    filters: filterData,
  };

  const { events, keyQuery, loading: isLoadingEvents } = useEvents(EVENTS_KEY);

  const listTutors = useMemo(() => {
    if (!events) return [];
    const tutors = new Map();
    events?.data?.forEach((e: any) => {
      if (e?.tutors) tutors.set(e.tutors.token, e.tutors?.fullName);
    });
    return [...tutors].map(([key, value]) => ({ key, value }));
  }, [JSON.stringify(events)]);

  useEffect(() => {
    if (!isCopiedToClipboard) return;
    snackHandler("Link copied to clipboard!");
  }, [isCopiedToClipboard]);

  const { mutate: removeEvent } = useDeleteEventMutation(() => {
    setDeletedEvent({ id: undefined, openConfirm: false });
    snackHandler("Event deleted!");
  }, EVENTS_KEY);

  const { mutate: callTechnicalProblemEvent } =
    useTechnicalProblemEventMutation(() => {
      setTechnicalProblemEvent({
        id: undefined,
        openConfirm: false,
        value: undefined,
      });
    }, EVENTS_KEY);

  const handleSelect = (ids: Iterable<unknown> | null | undefined) => {
    if (!events) return;
    const selectedIDs = new Set(ids);
    const selectedRowData = events?.data?.filter((row: any) =>
      selectedIDs.has(row.id)
    );
    setSelectedEvents(selectedRowData);
  };

  const handleDeleteEvent = async () => {
    if (!deletedEvent?.id) return;
    removeEvent({
      id: deletedEvent.id as number,
    });
  };

  const handleTechnicalProblemEvent = async () => {
    if (!technicalProblemEvent?.id) return;
    callTechnicalProblemEvent({
      id: technicalProblemEvent.id as number,
      value: technicalProblemEvent.value as number,
    });
  };

  const handleGetVideoRecording = async (row: EventItem) => {
    if (row?.link_video) {
      window.open(row.link_video, "_blank");
      return;
    }

    row.isLoadingVideoRecording = true;

    const res = await useAPI(
      "/v1/event-recording/" + row.id,
      {},
      { useErrors: true }
    );

    if (Boolean("errors" in res)) {
      row.errorVideoRecording = true;
      row.isLoadingVideoRecording = false;

      snackHandler("No recording found!", "error");

      return;
    }

    window.open(res.link, "_blank");

    row.isLoadingVideoRecording = false;
    row.link_video = res.link;
  };

  const handleSetTutorAccess = async () => {
    const res = await useAPI(
      `/v1/general/tutors/event/${tutorAccessData?.eventId}/access/${tutorAccessData?.tutorToken}`,
      {},
      { useErrors: true }
    );

    if (!res?.status) {
      snackHandler(
        res?.data?.message || res?.message || "Failed set tutor access event",
        "error"
      );
      return;
    }

    snackHandler("Success set tutor access event");
    setTutorAccessData(undefined);
    queryClient.invalidateQueries(keyQuery);
  };

  const handleGetDisponibilityNextDateTime = async (e: any, event: any) => {
    const res = await useAPI(
      "/v2/events/get-availability-next-date-time/" + event?.id,
      {},
      { type: "get" }
    );

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed search next events!", "error");
      return;
    }

    setTutorsAvailable((p: any) => ({ ...p, tutors: res?.data }));
  };

  const filteredEvents = useMemo(() => {
    if (!filterData?.search) {
      return events?.data ?? [];
    }

    return (events?.data ?? []).filter((event) => {
      const searchLower = filterData.search.toLowerCase();

      const tLI = ($v: string): boolean =>
        $v?.toLowerCase()?.includes(searchLower);

      return (
        tLI(event?.data + " " + event?.ora) ||
        tLI(event?.titolo) ||
        tLI(event?.tutors?.fullName) ||
        tLI(event?.type) ||
        tLI(event?.english_lvl) ||
        event?.user_events.some(
          (e) => tLI(e?.email) || tLI(e?.first_name) || tLI(e?.last_name)
        )
      );
    });
  }, [events, events?.data, JSON.stringify(filterData)]);

  const studentsColumnData = useMemo(() => {
    const studColumnData = [0, 0];

    filteredEvents.forEach((event) => {
      studColumnData[0] += event.user_events?.reduce(
        (acc, ue) => acc + Number(Boolean(ue.access_date || ue.is_logged_in)),
        0
      );
      studColumnData[1] += event.user_events?.length || 0;
    });

    return studColumnData;
  }, [filteredEvents]);

  const columns: GridColDef<EventItem>[] = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 70 },
      {
        field: "lang",
        width: 60,
      },
      {
        field: "titolo",
        headerName: "Title",
        minWidth: 100,
        flex: 1,
        renderCell: ({ row }) => {
          const is_less20Min_notTokenOrNotAccess_hasUsers =
            Boolean(
              dayjs(`${row.data} ${row.ora}`).add(20, "m").isBefore(dayjs())
            ) &&
            (!Boolean(row?.tutor_token) || !Boolean(row?.tutor_access)) &&
            Boolean(row?.user_events?.length);

          const isSlotAvailable =
            Boolean(dayjs(`${row.data} ${row.ora}`).isAfter(dayjs())) &&
            Boolean(row?.slot_available);

          const isTecnicalProblem = Boolean(row?.technical_problem);

          const isAutomaticallyDeleted =
            row?.status_calc == "Automatically deleted";

          return (
            <Box className="flex gap-2 items-center truncate">
              {row?.duplicate_event && row.duplicate_event > 1 ? (
                <MyTooltip
                  className="!w-6"
                  title="This event is duplicate by tutor"
                >
                  <ContentCopy fontSize="small" />
                </MyTooltip>
              ) : isSlotAvailable ? (
                <button
                  title="is slot available"
                  onClick={(e) => {
                    setTutorsAvailable((p: any) => ({
                      ...p,
                      eventId: row.id,
                      element: e.currentTarget,
                    }));
                    handleGetDisponibilityNextDateTime(e, row);
                  }}
                >
                  <CachedIcon fontSize="small" />
                </button>
              ) : is_less20Min_notTokenOrNotAccess_hasUsers ? (
                <MyTooltip
                  className="!w-6"
                  title="Is less from now after 20 min from start and not have tutor or not tutor access and has users"
                >
                  <WarningOutlinedIcon
                    sx={{ color: "red" }}
                    fontSize="small"
                  />
                </MyTooltip>
              ) : isAutomaticallyDeleted ? (
                <MyTooltip
                  className="!w-6"
                  title="Is Automatically deleted"
                >
                  <DeleteIconMui fontSize="small" />
                </MyTooltip>
              ) : isTecnicalProblem ? (
                <MyTooltip
                  className="!w-6"
                  title="Is Tecnical problem"
                >
                  <WarningOutlinedIcon
                    sx={{ color: "yellow" }}
                    fontSize="small"
                  />
                </MyTooltip>
              ) : null}

              {Boolean(row?.titolo) ? (
                <MyTooltip>{row?.titolo}</MyTooltip>
              ) : (
                "---"
              )}
            </Box>
          );
        },
      },
      {
        field: "data",
        headerName: "Date",
        width: 110,
      },
      {
        field: "ora",
        headerName: "Time",
        width: 65,
      },
      {
        field: "status",
        headerName: "Status",
        width: 110,
        renderCell: ({ row }) => (
          <EventDelayedStatus
            eventStatus={row?.eventDelayedStatus}
            eventTime={row?.eventDelayedTime}
          />
        ),
      },
      {
        field: "tutors",
        headerName: "Tutor",
        minWidth: 120,
        flex: 0.5,
        valueGetter: ({ row }) => row?.tutors?.fullName,
        renderCell: ({ row }) => <MyTooltip>{row?.tutors?.fullName}</MyTooltip>,
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 100,
        flex: 0.5,
        valueGetter: ({ row }) => row?.type || "-",
      },
      {
        field: "english_lvl",
        headerName: "Subtype",
        minWidth: 90,
        flex: 0.25,
        valueGetter: ({ row }) => row?.english_lvl || "-",
      },
      {
        field: "tutor",
        headerName: "Tutor",
        minWidth: 100,
        flex: 0.25,
        valueGetter: ({ row }) =>
          Number(Boolean(row?.tutor_access)) +
          "/" +
          Number(Boolean(row?.tutor_token)),
      },
      {
        field: "partecipants",
        headerName: "STUDENTS",
        minWidth: 100,
        flex: 0.25,
        renderHeader: () => (
          <MyTooltip
            title={
              <span className="number">
                {studentsColumnData[0]}/{studentsColumnData[1]}
              </span>
            }
          >
            <Box className="MuiDataGrid-columnHeaderTitle ">
              <span className="number">
                {Math.round(studentsColumnData[0] / filteredEvents.length || 0)}
                /
                {Math.round(studentsColumnData[1] / filteredEvents.length || 0)}
              </span>
              STUDENTS
            </Box>
          </MyTooltip>
        ),
        valueGetter: ({ row }) => {
          const participants = row?.user_events?.length || 0;

          const presences = row?.user_events?.filter(
            (userEvent) =>
              Boolean(userEvent?.access_date) ||
              Boolean(userEvent?.is_logged_in)
          )?.length;

          const date = new Date(`${row?.data} ${row?.ora_fine}`);

          return `${
            today > date || presences ? presences : "?"
          }/${participants}`;
        },
      },
      {
        field: "status_calc",
        headerName: "Status calc",
        width: 180,
        renderCell: ({ row }) => row?.status_calc || "---",
      },
      ...(Boolean(MODES.includes(mode))
        ? [
            {
              ...GRID_CHECKBOX_SELECTION_COL_DEF,
              width: 100,
            },
          ]
        : []),
      {
        field: "actions",
        headerName: "",
        width: 80,
        hideable: false,
        sortable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        valueGetter: ({ row }) => (row?.link_video ? "link video" : ""),
        renderCell: ({ row }) => (
          <Dropdown
            text={<MoreVertIcon />}
            content={
              <MenuList>
                {row?.link_meet && (
                  <MenuItem
                    title="Copy link meet"
                    onClick={(e) => {
                      copyToClipboard(row.link_meet);

                      if (!row?.tutor_access) {
                        setTutorAccessData({
                          tutorToken: row?.tutor_token,
                          eventId: row?.id,
                        });
                      }
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={copyIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy link google meet</ListItemText>
                  </MenuItem>
                )}
                {row?.link_meet && (
                  <MenuItem
                    title="Copy link meet"
                    onClick={async () => {
                      try {
                        // Generate meeting link for the student
                        const response = await generateLink(row.id, "student");
                
                        // Copy the generated link to the clipboard
                        if (response?.meeting_url) {
                          copyToClipboard(response.meeting_url);
                          snackHandler("Student meeting link copied to clipboard!");
                        } else {
                          snackHandler(response?.message || "Failed to generate the student meeting link.", "error");
                        }
                      } catch (error) {
                        console.error("Error generating student meeting link:", error);
                        snackHandler("Error generating the student meeting link.", "error");
                      }
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={copyIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy event link (Student)</ListItemText>
                  </MenuItem>
                )}

                {row?.link_meet && (
                  <MenuItem
                    title="Copy link meet for tutor"
                    onClick={async () => {
                      try {
                        const response = await generateLink(row.id, "tutor");
                        if (response?.meeting_url) {
                          copyToClipboard(response.meeting_url);
                          snackHandler("Tutor meeting link copied to clipboard!");
                        } else {
                          snackHandler(response?.message || "Failed to generate the tutor meeting link.", "error");
                        }
                      } catch (error) {
                        console.error("Error generating tutor meeting link:", error);
                        snackHandler("Error generating the tutor meeting link.", "error");
                      }
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={copyIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy event link (Tutor)</ListItemText>
                  </MenuItem>
                )}

                <MenuItem
                  title="Edit"
                  onClick={() => refEvent.current?.open(row)}
                >
                  <ListItemIcon>
                    <img
                      src={editIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>

                {today > new Date(`${row.data} ${row.ora_fine}`) ? (
                  <MenuItem
                    title="Show video recording"
                    disabled={
                      row?.isLoadingVideoRecording || row?.errorVideoRecording
                    }
                    onClick={() => handleGetVideoRecording(row)}
                  >
                    <ListItemIcon>
                      {row?.isLoadingVideoRecording === true ? (
                        <RefreshIcon />
                      ) : row?.errorVideoRecording === true ? (
                        <SdCardAlertIcon />
                      ) : (
                        <img
                          src={videoIcon}
                          className="icon no-margin"
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText>Show video recording</ListItemText>
                  </MenuItem>
                ) : null}

                {row?.type === EVENT_TYPES.SPEAKING &&
                row?.user_events?.length ? (
                  <MenuItem
                    title="Move student from this event"
                    onClick={() => {
                      setDataSearchEvents({ ...row });
                      setOpenTemplate(true);
                      setIsMoreSelectable(false);
                    }}
                  >
                    <ListItemIcon>
                      <ChangeCircleIcon />
                    </ListItemIcon>
                    <ListItemText>Move student from this event</ListItemText>
                  </MenuItem>
                ) : null}

                {row?.type === EVENT_TYPES.SPEAKING &&
                row?.user_events?.length ? (
                  <MenuItem
                    title="Create link for change event"
                    onClick={() => {
                      setDataSearchEvents({ ...row });
                      setOpenTemplate(true);
                      setIsMoreSelectable(true);
                    }}
                  >
                    <ListItemIcon>
                      <FindReplaceIcon />
                    </ListItemIcon>
                    <ListItemText>Create link for change event</ListItemText>
                  </MenuItem>
                ) : null}

                <MenuItem
                  title="Technical problem"
                  onClick={() =>
                    setTechnicalProblemEvent({
                      id: row?.id,
                      openConfirm: true,
                      value: row?.technical_problem ? 0 : 1,
                    })
                  }
                >
                  <ListItemIcon>
                    <WarningOutlinedIcon
                      fontSize="small"
                      sx={{
                        color: Boolean(row?.technical_problem)
                          ? "yellow"
                          : "inherit",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {row?.technical_problem === 1
                      ? "Remove technical problem"
                      : "Technical problem"}
                  </ListItemText>
                </MenuItem>

                {Boolean(
                  isAdmin ||
                    dayjs(`${row.data} ${row.ora}`)
                      .subtract(1, "h")
                      .isAfter(dayjs()) ||
                    ((!row?.tutor_token || !row?.tutor_access) &&
                      !row?.user_events?.length)
                ) && (
                  <MenuItem
                    title="Delete this event"
                    onClick={() =>
                      setDeletedEvent({ id: row?.id, openConfirm: true })
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={DeleteIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                )}
              </MenuList>
            }
          />
        ),
      },
    ],
    [screen, refEvent, events, mode]
  );

  return (
    <Box className={!isMobile ? "flex flex-row" : ""}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={filteredEvents}
        columns={columns}
        loading={isLoadingEvents}
        pageSize={perPage}
        components={{
          Toolbar: Toolbar,
          Pagination: CustomPaginationClient,
        }}
        selectionModel={selectedMode}
        checkboxSelection={MODES.includes(mode)}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
        columnVisibilityModel={{
          actions: !MODES.includes(mode),
        }}
        onSelectionModelChange={(ids) => {
          handleSelect(ids);
          setSelectMode(ids);
        }}
        componentsProps={{
          toolbar: {
            mode,
            screen,
            setMode,
            weekEnd,
            refEvent,
            isMobile,
            weekStart,
            setFilters,
            listTutors,
            EVENTS_KEY,
            handleNextWeek,
            handlePrevWeek,
            selectedEvents,
            refMaterialDialog,
            refImportBulkEvent,
            customPaginationRef,
            refEditEventAssociations,
            filters: filterData,
            onClearSelect: () => {
              setSelectedEvents([]);
              setSelectMode([]);
            },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: {
            customPaginationRef,
          },
        }}
      />

      {Boolean(openTemplate) && (
        <SearchEvents
          open={Boolean(openTemplate)}
          event={dataSearchEvents}
          onClose={() => setOpenTemplate(false)}
          keyQuery={keyQuery}
          isMoreSelectable={isMoreSelectable}
        />
      )}

      <ConfirmationDialog
        title={
          <>
            <img
              src={DeleteIcon}
              className="icon"
            />
            DELETE EVENT - {deletedEvent?.id}
          </>
        }
        open={Boolean(deletedEvent?.openConfirm)}
        onClose={() => setDeletedEvent({ id: undefined, openConfirm: false })}
        onConfirm={handleDeleteEvent}
      >
        Are you sure to remove event <b>{deletedEvent?.id}</b>?
      </ConfirmationDialog>

      <ConfirmationDialog
        title={
          <>
            <WarningOutlinedIcon
              fontSize="small"
              className="mr-2"
            />
            Technical problem
          </>
        }
        open={Boolean(technicalProblemEvent?.openConfirm)}
        onClose={() =>
          setTechnicalProblemEvent({
            id: undefined,
            openConfirm: false,
            value: undefined,
          })
        }
        onConfirm={handleTechnicalProblemEvent}
      >
        Are you sure to do this?
      </ConfirmationDialog>

      <EventDialog
        ref={refEvent}
        keyQuery={keyQuery}
      />

      <MaterialDialog ref={refMaterialDialog} />

      <ImportBulkUserDialog
        ref={refImportBulkEvent}
        query={EVENTS_KEY}
      />

      <EditExerciseMaterialDialog
        ref={refEditEventAssociations}
        query={EVENTS_KEY}
        onClearSelect={() => {
          setSelectedEvents([]);
          setSelectMode([]);
        }}
        selectedEvents={selectedEvents}
      />

      <ConfirmationDialog
        open={Boolean(tutorAccessData)}
        title={
          <>
            <img
              src={editIcon}
              className="icon"
            />
            Set tutor access now
          </>
        }
        onClose={() => setTutorAccessData(undefined)}
        onConfirm={handleSetTutorAccess}
      >
        Are you sure to set tutor access from start event?
      </ConfirmationDialog>

      <Popover
        open={
          Boolean(tutorsAvailable?.element) &&
          Boolean(tutorsAvailable?.tutors?.length)
        }
        onClose={() => setTutorsAvailable(undefined)}
        anchorEl={tutorsAvailable?.element}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box className="p-3">
          {tutorsAvailable?.tutors?.map((tutor: any, i: number) => (
            <button
              key={tutor?.token + i}
              onClick={() => copy(tutor?.email)}
              className="w-full flex hover:opacity-60 hover:bg-slate-200/5"
            >
              <span className="w-8 text-left">{i + 1}</span>
              {tutor?.name}
            </button>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default ListEvents;
