import { Box, TextField, Button, MenuItem } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";

interface IProps {
  value: string | number;
  type?: "" | "text" | "select" | "number";
  error?: boolean;
  label?: string;
  minRows?: number;
  maxRows?: number;
  setValue: (v: string) => void;
  children?: any;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  multiline?: boolean;
  placeholder?: string;
  name?: string;
  helperText?: any
}

const InputTextSelect = ({
  setValue = () => {},
  type = "",
  label = "",
  value = "",
  error = false,
  minRows = 1,
  maxRows = 1,
  children = "",
  disabled = false,
  required = false,
  className = "",
  multiline = false,
  placeholder = "",
  name = "",
  helperText
}: IProps) => {
  const typeForType: string = type === "number" ? "number" : "text";

  return (
    <Box className={`relative ${className}`}>
      {type == "" && (
        <>
          <Box
            sx={{
              width: "calc(100% - 2.3rem)",
              zIndex: 100,
              position: "absolute",
              "& .css-rdeiy7-MuiInputBase-root-MuiInput-root:before, & .css-rdeiy7-MuiInputBase-root-MuiInput-root:after":
                { display: "none" },
            }}
            className="w-full flex items-stretch h-full mr-9 gap-1"
          >
            <Box className="w-full grow flex items-center pl-4">
              <TextField
                fullWidth
                type={typeForType}
                variant="standard"
                onChange={({ target: { value: v } }) => setValue(v)}
                className="w-full"
                InputProps={{
                  sx: {
                    pt: "5px",
                    "&:before": { border: "none !important" },
                    "&:after": { border: "none !important" },
                  },
                  value: value,
                }}
                placeholder={placeholder}
                InputLabelProps={{ shrink: true, style: { border: "none" } }}
                name={name}
                helperText={helperText}
              />
            </Box>

            <Button
              sx={{
                width: "10px",
                height: "10px",
                minWidth: 0,
                marginTop: "12px",
              }}
              onClick={() => setValue("")}
            >
              <BackspaceIcon fontSize="small" />
            </Button>
          </Box>

          <TextField
            select
            fullWidth
            sx={{ backgroundColor: "none", p: 0 }}
            error={error}
            label={label}
            value={value}
            color="primary"
            variant="outlined"
            disabled={disabled}
            required={required}
            onChange={({ target: { value: v } }) => setValue(v)}
            InputProps={{ sx: { color: "transparent" } }}
            SelectProps={{ displayEmpty: true, defaultValue: "" }}
            InputLabelProps={{ shrink: true }}
          >
            {children}
          </TextField>
        </>
      )}

      {type == "text" && (
        <Box className="w-full">
          <TextField
            fullWidth
            type={typeForType}
            error={error}
            label={label}
            color="primary"
            minRows={minRows}
            maxRows={maxRows}
            variant="outlined"
            disabled={disabled}
            required={required}
            onChange={({ target: { value: v } }) => setValue(v)}
            className="w-full"
            multiline={multiline}
            InputProps={{
              value: value,
              sx: { pr: "3rem" },
              inputProps: {
                maxLength: 1600,
              },
            }}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true, style: { border: "none" } }}
            helperText={helperText}
          />

          <Button
            sx={{
              top: 1,
              right: 1,
              minWidth: 0,
              width: "3rem",
              height: "95%",
              position: "absolute",
            }}
            onClick={() => setValue("")}
          >
            <BackspaceIcon fontSize="small" />
          </Button>
        </Box>
      )}

      {type == "select" && (
        <TextField
          select
          fullWidth
          error={error}
          label={label}
          color="primary"
          value={value || "SELECT"}
          variant="outlined"
          disabled={disabled}
          required={required}
          onChange={({ target: { value: v } }) => setValue(v)}
          SelectProps={{ displayEmpty: true, defaultValue: "" }}
          InputLabelProps={{ shrink: true }}
          helperText={helperText}
        >
          <MenuItem
            selected
            value="SELECT"
          >
            <Box className="w-full flex items-cente justify-between gap-4">
              SELECT
            </Box>
          </MenuItem>

          {children}
        </TextField>
      )}
    </Box>
  );
};

export default InputTextSelect;
