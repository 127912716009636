import UseDownloadTable from "@/components/UseDownloadTable";
import { Box, Button } from "@mui/material";
import { INEmail } from ".";
import AddLinkIcon from "@mui/icons-material/AddLink";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmailIcon from "@mui/icons-material/Email";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const Toolbar = ({
  filters,
  setFilters,
  selectedUsersID,
  setDataEmail,
  templateEmail,
  handleGetTemplate,
}) => {
  return (
    <Box className="flex gap-4">
      <UseDownloadTable
        color="primary"
        fileName="Booked Ielts"
      />

      {selectedUsersID?.length ? (
        <Button
          variant="outlined"
          onClick={() => setDataEmail((p: INEmail) => ({ ...p, open: true }))}
        >
          {<AddLinkIcon />}
        </Button>
      ) : null}

      {selectedUsersID?.length ? (
        <Button
          disabled={templateEmail?.loadingGet}
          variant="outlined"
          onClick={handleGetTemplate}
        >
          {templateEmail?.loadingGet ? <RefreshIcon /> : <EmailIcon />}
        </Button>
      ) : null}

      <SearchToolbar
        filters={filters}
        setFilters={setFilters}
        client={true}
      />
    </Box>
  );
};

export default Toolbar;
