import ActivityLogs from "@/pages/ActivityLogs";
import AdsPage from "@/pages/AdsPage";
import BookedIelts from "@/pages/BookedIelts";
import Calendar from "@/pages/Calendar";
import CallCenter from "@/pages/CallCenter/Index";
import Changelog from "@/pages/Changelog";
import Classi from "@/pages/Classi";
import CrmV2 from "@/pages/Crm/V2";
import CrmExercises from "@/pages/CrmExercises";
import DayOffs from "@/pages/DayOffs";
import Info from "@/pages/Events/component/Info";
import Events from "@/pages/Events/Events";
import EventsReport from "@/pages/EventsReport/EventsReport";
import ExercisesV3 from "@/pages/Exercises/ExercisesV3";
import Feedback from "@/pages/Feedback";
import Invoices from "@/pages/Invoices";
import KickoffDates from "@/pages/KickoffDates";
import SalesKpi from "@/pages/Kpis/KpisLeads";
import Interviews from "@/pages/Kpis/KpisMeetings";
import KpiNoShow from "@/pages/Kpis/KpisNoShow";
import LevelChange from "@/pages/LevelChange";
import Materials from "@/pages/Materials/Index";
import NotFound from "@/pages/NotFound";
import Nps from "@/pages/Nps";
import Onboarding from "@/pages/Onboarding";
import Orders from "@/pages/Orders";
import PaymentsIn from "@/pages/PaymentsIn";
import PaymentsOut from "@/pages/PaymentsOut";
import PdfInvoice from "@/pages/PdfInvoice";
import PersonalInfo from "@/pages/PersonalInfo";
import Prodotti from "@/pages/Prodotti";
import Recruiting from "@/pages/Recruiting";
import Referrals from "@/pages/Referrals";
import SettingsCrm from "@/pages/Settings/Crm";
import StillToPay from "@/pages/StillToPay";
import Students from "@/pages/Students";
import Team from "@/pages/Team";
import Test from "@/pages/Test";
import Tutors from "@/pages/Tutors";
import UsersUpsolds from "@/pages/Upselling/v1/UsersUpsolds";
import UsersList from "@/pages/UsersList/UsersList";
import UsersVat from "@/pages/UsersVat";
import WritingDone from "@/pages/WritingDone";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, CssBaseline, Drawer, useTheme } from "@mui/material/";
import { useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import Sidebar from "./Sidebar/Sidebar";

import CrmUpsellingV1 from "@/pages/Upselling/v1/Crm";
import CrmUpsellingV2 from "@/pages/Upselling/v2/Crm/CrmUpsellingTable";

import WhiteLogoIcon from "@/assets/logo.svg";
import BlackLogoIcon from "@/assets/logoBlack.svg";
import useAuth from "@/hooks/useAuth";
import AdsPerformance from "@/pages/AdsPerformance";
import Availabilities from "@/pages/Availabilities";
import AvailabilitiesToInsert from "@/pages/Availabilities/AvailabilitiesToInsert";
import BlogPost from "@/pages/BlogPost";
import Corrispettivi from "@/pages/Corrispettivi";
import CustomerCareKpi from "@/pages/CustomerCareKpi";
import DevelopmentCenterUser from "@/pages/DevCenterUsers/Index";
import DevelopmentCenter from "@/pages/DevelopmentCenter/Index";
import EventFeedback from "@/pages/EventFeedback";
import ExercisesDone from "@/pages/ExercisesDone";
import ArchivedFeedbacks from "@/pages/Feedback/Archive";
import CallCenterKPI from "@/pages/Kpis/KpisCallCenter";
import ProductionKpi from "@/pages/Kpis/KpisProducts";
import TriggersCanvas from "@/pages/notifications";
import ProblematicUsers from "@/pages/ProblematicUsers";
import ProductsPerformance from "@/pages/ProductsPerformance";
import RecruitingForms from "@/pages/RecruitingForms";
import SettingsUsers from "@/pages/Settings/Users";
import TutoringStudents from "@/pages/Tutoring/Students";
import TutorsAnalytics from "@/pages/Tutors/Analytics";
import UserEventsStats from "@/pages/UserEventsStats";
import AllUsersAdmission from "@/pages/Users/AllUsersAdmission";
import GestioneIscritti from "@/pages/Users/GestioneIscritti";
import UserSuspended from "@/pages/UserSuspended";

const BASE_ROUTE = import.meta.env.VITE_ROUTES_BASE;

interface IProps {
  window?: () => Window;
}

const ProtectedRoutes = ({ window }: IProps) => {
  const theme = useTheme();
  const pathname = useLocation().pathname;
  const { userAdmin, statusSideBar, links, isAdmission, typeOf } = useAuth();

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [drawerWidth, setDrawerWidth] = useState<number>(250);

  const routesUser = userAdmin?.user?.routes;

  const currentRoute = routesUser?.find(
    (e: any) =>
      e.active === true &&
      (!e?.limitByTypeOf?.length || e?.limitByTypeOf?.includes(typeOf))
  );

  const dynIndex: any = links.filter(
    (e: any) => e.name == currentRoute?.name
  )?.[0];

  const routesActive: Array<any> = useMemo(
    () =>
      links?.filter((link: any) => {
        const routeUser: any = routesUser?.find((r) => r?.name == link?.name);

        return (
          userAdmin?.user?.admin ||
          (routeUser &&
            routeUser?.active &&
            (!routeUser?.limitByTypeOf?.length ||
              (routeUser?.limitByTypeOf?.length &&
                routeUser?.limitByTypeOf?.includes(typeOf))) &&
            (link?.typeOf?.includes(typeOf) ||
              link?.typeOf?.includes("general")))
        );
      }),
    [links, routesUser]
  );

  const isActivePage = routesActive?.some(
    (l) =>
      pathname == "/test" ||
      pathname == "/canvas" ||
      l.path == pathname ||
      (pathname == "/" && l.path == "/crm")
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  useEffect(() => {
    if (statusSideBar === "open") {
      setDrawerWidth(250);
    } else {
      setTimeout(() => {
        setDrawerWidth(105);
      }, 300);
    }
  }, [statusSideBar]);

  const drawer = (
    <Box className="h-full">
      <Sidebar />
    </Box>
  );

  return !currentRoute ? null : (
    <Box className="flex bg-edu-900 h-screen">
      <CssBaseline />

      <Box
        id="sidebar"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        component="nav"
        aria-label="mailbox folders"
      >
        <SimpleBar>
          <Drawer
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                border: "none",
                boxSizing: "border-box",
              },
            }}
            open={mobileOpen}
            variant="temporary"
            onClose={handleDrawerToggle}
            container={container}
            ModalProps={{ keepMounted: true }}
          >
            {drawer}
          </Drawer>
        </SimpleBar>

        <SimpleBar>
          <Drawer
            open
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                border: "none",
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
          >
            {drawer}
          </Drawer>
        </SimpleBar>
      </Box>

      <Box className="flex flex-col flex-1 flex-grow">
        <Box
          id="top_menu"
          sx={{
            display: { sm: "none" },
            background: theme.palette.leftSideBarSub.main,
          }}
          className="flex justify-between p-4"
        >
          <Box>
            <img
              src={
                theme.palette.mode === "light" ? BlackLogoIcon : WhiteLogoIcon
              }
              style={{ height: "40px" }}
            />
          </Box>

          <Button
            variant="outlined"
            onClick={() => handleDrawerToggle()}
          >
            <MenuIcon />
          </Button>
        </Box>

        <Routes>
          {isActivePage || userAdmin?.user?.admin ? (
            <Route>
              <Route
                index
                element={
                  !userAdmin.user.admin && dynIndex?.path != BASE_ROUTE ? (
                    <Navigate
                      to={dynIndex?.path}
                      replace
                    />
                  ) : (
                    <CrmV2 />
                  )
                }
              />

              <Route path="exercises">
                {/* <Route
                  path="v1"
                  element={<ExercisesV1 />}
                />

                <Route
                  path="v2"
                  element={<ExercisesV2 />}
                /> */}

                <Route
                  index
                  element={<ExercisesV3 />}
                />
              </Route>

              <Route
                path="materials"
                element={<Materials />}
              />

              <Route
                path="ads-performance"
                element={<AdsPerformance />}
              />

              <Route path="crm">
                {/* <Route
                  index
                  element={<CRM />}
                /> */}

                <Route
                  // path="v2"
                  index
                  element={<CrmV2 />}
                />

                {/* <Route
                  path={"/crm/:id"}
                  element={<CrmCall />}
                />

                <Route
                  path={"/crm/:id"}
                  element={<UserSingle />}
                /> */}
              </Route>

              <Route path="tutoring">
                {/* <Route
                  path="crm"
                  element={<CRM />}
                /> */}

                <Route
                  path="students"
                  element={<TutoringStudents />}
                />

                <Route
                  path="products"
                  element={<Prodotti />}
                />
              </Route>

              <Route path="upselling">
                <Route
                  path="crm"
                  element={<CrmUpsellingV1 />}
                />

                <Route
                  path="v2/crm"
                  element={<CrmUpsellingV2 />}
                />

                <Route
                  path="kpis-meetings"
                  element={<Interviews version="upselling" />}
                />

                <Route
                  path="users-upsolds"
                  element={<UsersUpsolds />}
                />
              </Route>

              <Route path={"users"}>
                <Route
                  index
                  element={
                    isAdmission ? <AllUsersAdmission /> : <GestioneIscritti />
                  }
                />

                {/* <Route
                  path={":id"}
                  element={<UserSingle />}
                /> */}

                <Route
                  path={":id/invoice/:invoiceId"}
                  element={<PdfInvoice />}
                />
              </Route>

              <Route
                path="kickoff-dates"
                element={<KickoffDates />}
              />

              <Route
                path="availabilities-to-insert"
                element={<AvailabilitiesToInsert />}
              />

              <Route
                path="activity-logs"
                element={<ActivityLogs />}
              />

              <Route
                path="invoices"
                element={<Invoices />}
              />

              <Route
                path="payments-in"
                element={<PaymentsIn />}
              />

              <Route
                path="payments-out"
                element={<PaymentsOut />}
              />

              <Route
                path="corrispettivi"
                element={<Corrispettivi />}
              />

              <Route
                path="still-to-pay"
                element={<StillToPay />}
              />

              <Route
                path="products"
                element={<Prodotti />}
              />

              <Route
                path="classi"
                element={<Classi />}
              />

              <Route
                path="events"
                element={<Events />}
              />

              <Route
                path="events-statistics"
                element={<Info />}
              />

              <Route
                path="events-feedbacks"
                element={<EventFeedback />}
              />

              <Route
                path="events-feedbacks-archived"
                element={<ArchivedFeedbacks />}
              />

              <Route
                path="events-report"
                element={<EventsReport />}
              />

              {/* <Route
                path="calendars"
                element={<Calendars />}
              /> */}

              <Route
                path="changelog"
                element={<Changelog />}
              />

              <Route
                path="influencers"
                element={<AdsPage />}
              />

              <Route
                path="users-list"
                element={<UsersList />}
              />

              <Route
                path="onboarding"
                element={<Onboarding />}
              />

              {/* <Route
                path="performance"
                element={<Performance />}
              /> */}

              <Route
                path="kpis-call-center"
                element={<CallCenterKPI />}
              />

              <Route
                path="dev-center"
                element={<DevelopmentCenter />}
              />

              <Route
                path="dev-center-user"
                element={<DevelopmentCenterUser />}
              />

              <Route
                path="kpis-leads"
                element={<SalesKpi />}
              />

              <Route
                path="kpis-meetings"
                element={<Interviews />}
              />

              <Route
                path="kpis-products"
                element={<ProductionKpi />}
              />

              <Route
                path="kpis-no-show"
                element={<KpiNoShow />}
              />

              <Route
                path="products-performance"
                element={<ProductsPerformance />}
              />

              <Route
                path="feedback"
                element={<Feedback />}
              />

              <Route
                path="nps"
                element={<Nps />}
              />

              <Route
                path="students"
                element={<Students />}
              />

              <Route
                path="crm-exercises"
                element={<CrmExercises />}
              />

              <Route
                path="exercises-done"
                element={<ExercisesDone />}
              />

              <Route
                path="writings-done"
                element={<WritingDone />}
              />

              <Route
                path="availabilities"
                element={<Availabilities />}
              />

              <Route
                path="team"
                element={<Team />}
              />

              <Route
                path="calendars"
                element={<Calendar />}
              />

              <Route
                path="blog"
                element={<BlogPost />}
              />

              <Route
                path="call-center"
                element={<CallCenter />}
              />

              <Route
                path="Tutors"
                element={<Tutors />}
              />

              <Route
                path="tutors-analytics"
                element={<TutorsAnalytics />}
              />

              <Route
                path="users-vat"
                element={<UsersVat />}
              />

              <Route
                path="booked-ielts"
                element={<BookedIelts />}
              />

              <Route
                path="referrals"
                element={<Referrals />}
              />

              <Route
                path="user-suspended"
                element={<UserSuspended />}
              />

              <Route
                path="personal-info"
                element={<PersonalInfo />}
              />

              <Route path="settings">
                <Route
                  path="users"
                  element={<SettingsUsers />}
                />

                <Route
                  path="crm"
                  element={<SettingsCrm />}
                />
              </Route>

              <Route
                path="day-offs"
                element={<DayOffs />}
              />

              <Route
                path="user-events-stats"
                element={<UserEventsStats />}
              />

              <Route
                path="users-problematic"
                element={<ProblematicUsers />}
              />

              <Route
                path="kpis-custore-care"
                element={<CustomerCareKpi />}
              />

              <Route
                path="level-change"
                element={<LevelChange />}
              />

              <Route
                path="recruiting"
                element={<Recruiting />}
              />

              <Route
                path="recruiting-forms"
                element={<RecruitingForms />}
              />

              <Route
                path="test"
                element={<Test />}
              />

              <Route
                path="canvas"
                element={<TriggersCanvas />}
              />

              <Route
                path="orders"
                element={<Orders />}
              />

              {/* <Route
                path="influencers"
                element={<Ads />}
              />

              <Route
                path="availabilities"
                element={<Availabilities />}
              /> */}
            </Route>
          ) : (
            <Route
              path=":route"
              element={<NotFound message="PAGE NOT AUTHORIZED" />}
            />
          )}

          <Route
            path=":route"
            element={<NotFound />}
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default ProtectedRoutes;
