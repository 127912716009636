import { Box, TextField } from "@mui/material";

interface IProps {
  id: string;
  value: string | { name: string };
  accept: string;
  children: any;
  setValue: (v: any) => void;
  title?: string;
}

const SelectFile = ({
  id = "",
  value,
  accept = "",
  children = "",
  setValue,
  title
}: IProps) => {
  const _value = typeof value === "string" ? value : value?.name ?? "";

  return (
    <label
      style={{ border: "1px solid #B388FF", borderRadius: "0.7rem" }}
      htmlFor={`select-file-${id}`}
      className={`block select-file-component ${value ? "isSet" : ""}`}
      title={title}
    >
      <Box className="text-label text-[#B388FF] text-sm pl-[0.35rem]">
        {children}
      </Box>
      <Box className="truncate text-value w-full">{_value}</Box>
      <TextField
        id={`select-file-${id}`}
        sx={{ display: "none" }}
        type="file"
        title="mp3"
        inputProps={{
          accept,
          onChange: ({ target: { files } }: any) => setValue(files[0]),
        }}
      />
    </label>
  );
};

export default SelectFile;
