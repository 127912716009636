import { createRef, useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  Box,
  Button,
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PopupActiveLanguages, {
  IRef as IRefPopupActiveLanguages,
} from "./PopupActiveLanguages";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import EditIcon from "@mui/icons-material/Edit";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import RemoveIcon from "@/assets/icons/delete.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditCalendars from "./EditCalendars";
import usePagination from "@/hooks/usePagination";
import EditCalendarID from "./EditCalendarID";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

enum COLUMNS {
  ENGLISH = "english",
  SELECTED = "selected",
  ADMISION = "admission",
  UPSELLING = "upselling",
  CALENDAR_ID = "calendar_id",
}

const KEY_QUERY = ["general-calendar"];

const HTTP_ROUTE_BASE = "/v1/general/calendar";
const HTTP_ROUTE_CREATE = HTTP_ROUTE_BASE + "/create";
export const HTTP_ROUTE_EDIT = HTTP_ROUTE_BASE + "/edit";
const HTTP_ROUTE_EDIT_CALENDAR_ID = HTTP_ROUTE_EDIT + "/calendar_id";

export interface INEditDataCalendarID {
  token: string;
  value: string;
}

const Calendar = () => {
  const theme = useTheme();
  const { snackHandler, isAdmission } = useAuth();
  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () => useAPI(HTTP_ROUTE_BASE),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );
  const { perPage } = useScreen();

  const refPopupActiveLanguages = createRef<IRefPopupActiveLanguages>();

  const [data, setData] = useState<Array<any>>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [deleteToken, setDeleteToken] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openEditCalendarID, setOpenEditCalendarID] = useState<boolean>(false);
  const [dataEditCalendarID, setDataEditCalendarID] =
    useState<INEditDataCalendarID>({ token: "", value: "" });

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const handleSave = async (token) => {
    setOpenDialog(false);
    await useAPI(HTTP_ROUTE_CREATE, {
      data: [
        {
          selected: 1,
          token: token,
        },
      ],
    });
    queryClient.invalidateQueries(KEY_QUERY);
    snackHandler("Calendar updated!");
  };

  const handleDelete = async () => {
    if (!deleteToken) return false;

    await useAPI(HTTP_ROUTE_CREATE, {
      data: [{ selected: 0, token: deleteToken }],
    });
    queryClient.invalidateQueries(KEY_QUERY);
    setDeleteToken(null);
    return true;
  };

  const handleSaveEditCalendarID = async () => {
    setOpenEditCalendarID(false);

    const { token, value } = dataEditCalendarID;
    await useAPI(HTTP_ROUTE_EDIT_CALENDAR_ID, {
      token,
      [COLUMNS.CALENDAR_ID]: value,
    });

    const getData = queryClient.getQueryData(KEY_QUERY);

    if (!Array.isArray(getData)) return;

    const index = getData.findIndex(
      (e) => e?.uuid === dataEditCalendarID?.token
    );
    const newValue = { ...(getData[index] || {}) };
    newValue.calendar_id = dataEditCalendarID?.value || "";
    getData[index] = newValue;
    queryClient.setQueryData(KEY_QUERY, getData);

    setData(getData);
    setRows(getData.filter((e) => Boolean(e?.calendar?.selected)));
    snackHandler("Edit Calendar ID!");
  };

  const handleSwitchChange = async (
    token: string,
    column: string,
    values: Array<string>
  ) => {
    setRows((r) => {
      const t = [...r];
      const index = t.findIndex((e) => e.uuid == token);
      t[index].calendar[column] = values;
      return t;
    });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      minWidth: 180,
      flex: 1,
      headerName: "Name",
      valueGetter: ({ row }) => `${row?.first_name} ${row?.last_name}`,
    },
    {
      field: "email",
      minWidth: 220,
      flex: 1,
      headerName: "Email",
      valueGetter: ({ row }) => row?.email || "-",
    },
    ...(isAdmission
      ? [COLUMNS.ADMISION]
      : [COLUMNS.ENGLISH, COLUMNS.UPSELLING]
    ).map((e) => ({
      field: e,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      minWidth: 100,
      flex: 0.5,
      headerName: e.split("_").join(" "),
      valueGetter: ({ row }) => row?.calendar?.[e]?.join(","),
      renderCell: ({ row }) => (
        <MyTooltip
          title={
            <Box className="flex gap-2">
              {row?.calendar?.[e]?.map((e: string, i: number) => (
                <Box key={e + i}>{e}</Box>
              ))}
            </Box>
          }
        >
          <Button
            onClick={(event) => {
              refPopupActiveLanguages?.current?.open({
                token: row?.uuid,
                column: e,
                values: row?.calendar?.[e],
                anchorEl: event.currentTarget,
              });
            }}
          >
            {row?.calendar?.[e]?.length ? (
              <Visibility />
            ) : (
              <VisibilityOff
                color="secondary"
                className="opacity-40"
              />
            )}
          </Button>
        </MyTooltip>
      ),
    })),
    {
      field: "actions",
      headerName: "",
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      width: 70,
      renderCell: ({ row }) => (
        <Dropdown
          text={<MoreVertIcon />}
          content={
            <MenuList>
              <MenuItem
                color="primary"
                title="Set calendar id"
                onClick={() => {
                  setDataEditCalendarID((p) => ({
                    ...p,
                    token: row?.uuid,
                    value: row?.calendar_id || "",
                  }));
                  setOpenEditCalendarID(true);
                }}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText>Set calendar id</ListItemText>
              </MenuItem>

              <MenuItem
                title="Delete calendar"
                onClick={() => {
                  setDeleteToken(row?.uuid);
                  setOpenConfirmDialog(true);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </MenuList>
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (!dataAPI) return;
    setData(dataAPI);
    setRows(dataAPI.filter((e: any) => Boolean(e?.calendar?.selected)));
  }, [dataAPI]);

  return (
    <BasePage title={"Calendars" + (isAdmission ? " admission" : "")}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        page={parseInt(page.toString()) - 1}
        columns={columns}
        loading={isLoading}
        rows={
          rows?.filter((e) => {
            if (!isAdmission) return true;
            return e?.calendar?.admission;
          }) || []
        }
        pageSize={perPage}
        sortModel={sortModel}
        components={{ Toolbar: Toolbar, Pagination: CustomPaginationClient }}
        onPageChange={(newPage) => setPage(newPage + 1)}
        componentsProps={{
          toolbar: { filters: filterData, setFilters, setOpenDialog },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <EditCalendars
        data={data}
        open={openDialog}
        onSave={(token) => handleSave(token)}
        onClose={() => setOpenDialog(false)}
      />

      <EditCalendarID
        open={openEditCalendarID}
        data={dataEditCalendarID}
        onSave={handleSaveEditCalendarID}
        onClose={() => setOpenEditCalendarID(false)}
        setData={(e) => setDataEditCalendarID((p) => ({ ...p, value: e }))}
      />

      <ConfirmationDialog
        open={openConfirmDialog}
        title={
          <>
            <img src={RemoveIcon} /> Delete Calendar
          </>
        }
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          handleDelete();
          setOpenConfirmDialog(false);
        }}
      >
        Are you sure to remove it?
      </ConfirmationDialog>

      <PopupActiveLanguages
        ref={refPopupActiveLanguages}
        onSwitchChange={handleSwitchChange}
      />
    </BasePage>
  );
};

export default Calendar;
