import { forwardRef, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useContextCrm } from "@/pages/Crm/V2/Context";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import useCrmApi from "@/api/useCrmApi";

export interface IAddInterestedEventDialog {
  open: (data: any) => void;
}

interface IProps {
  ref: HTMLButtonElement;
  isUpselling?: boolean;
  dialogNewStatusToBeConvertedRef?: any;
}

const AddInterestedEventDialog = forwardRef<IAddInterestedEventDialog, IProps>(
  ({ isUpselling, dialogNewStatusToBeConvertedRef }, ref) => {
    const { userAdmin } = useAuth();
    const { callAddEventInterested } = useCrmApi();

    const context = useContextCrm();

    const [show, setShow] = useState(false as boolean);
    const [formData, setData] = useState({
      date: "",
      first_name: "",
      last_name: "",
      token: "",
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      open: (value: any) => {
        setData(value);
        setShow(true);
      },
    }));

    const onClose = () => {
      setShow(false);
      setIsLoading(false);
    };

    const handleUpdateFormState = (name: string, value: string) => {
      setData((p) => ({
        ...p,
        [name]: value,
      }));
    };

    const submitForm = async (e: any) => {
      e.preventDefault();

      setIsLoading(true);

      callAddEventInterested.mutate(
        {
          data: {
            summary:
              "!! Contact " + formData?.first_name + " " + formData?.last_name,
            start: dayjs(formData.date).format("YYYY-MM-DD"),
            user_token: formData.token,
            calendar_id: userAdmin.user.uuid,
            ...(isUpselling ? { isUpselling } : {}),
          },
        },
        {
          onSuccess: () => {
            setShow(false);
            setIsLoading(false);
            dialogNewStatusToBeConvertedRef?.current?.open?.(undefined);
            context?.getInvalidateQuery?.();
          },
        }
      );
    };

    return (
      <EduModal
        fullWidth
        open={show}
        onClose={onClose}
      >
        <EduModalTitle onClose={onClose}>
          When do you want to contact "
          {formData?.first_name + " " + formData?.last_name}" in the future?
        </EduModalTitle>

        <EduModalBody className="p-5">
          <Box
            width="100%"
            onSubmit={submitForm}
            component="form"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disablePast
                views={["day"]}
                value={formData?.date || null}
                openTo="day"
                onChange={(newValue) =>
                  newValue && handleUpdateFormState("date", newValue)
                }
                inputFormat="yyyy-MM-dd"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="Date"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>

            <Box className="flex justify-end mt-5">
              <Button
                type="submit"
                title="Submit"
                variant="outlined"
                disabled={isLoading || !formData?.date}
              >
                <img
                  src={addIcon}
                  className="icon"
                />
                Submit
              </Button>
            </Box>
          </Box>
        </EduModalBody>
      </EduModal>
    );
  }
);

export default AddInterestedEventDialog;
