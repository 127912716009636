import { FC } from "react";
import { Add, ExpandMore } from "@mui/icons-material";
import { defKeysExercises } from "@/pages/Exercises/ExercisesV2/Create/interfacesAndDefaults";
import { clone, fUpperWords } from "@/components/useHelpers";
import { ExerciseQuestionsAccordionPropsType } from "@/pages/Exercises/ExercisesV3/exercisesV3.types";
import { Box, Button, Accordion, AccordionSummary } from "@mui/material";
import Groupings from "@/pages/Exercises/ExercisesV2/Create/Groupings";
import MatchingOptionGapFill from "@/pages/Exercises/ExercisesV2/Create/MatchingOptionGapFill";
import MultipleChoiceTrueFalse from "@/pages/Exercises/ExercisesV2/Create/MultipleChoiceTrueFalse";

const AddNewExerciseQuestionsAccordion: FC<
  ExerciseQuestionsAccordionPropsType
> = ({ type, keyVersion, questions, exercise, setExercise }) => {
  const questionComponents = {
    multiple_choice_true_false: MultipleChoiceTrueFalse,
    matching_option_gap_fill: MatchingOptionGapFill,
    groupings: Groupings,
  };

  const QuestionComponent = questionComponents[keyVersion];

  if (!QuestionComponent) return null;

  return (
    <Accordion className="!rounded !m-0">
      <AccordionSummary expandIcon={<ExpandMore />}>
        {fUpperWords((type ?? keyVersion).replaceAll("_", " "))}
        {questions?.length ? " - " + questions?.length : ""}
      </AccordionSummary>

      <Box className="p-4 pt-0">
        {questions.map((question, index) => (
          <QuestionComponent
            key={question.idx_ + " " + index}
            type={type}
            index={index}
            question={question}
            exercise={exercise}
            keyVersion={keyVersion}
            setExercise={setExercise}
            indexQuestion={question.idx_}
          />
        ))}

        <Button
          variant="outlined"
          onClick={() => {
            setExercise((prev) => ({
              ...prev,
              exercises: {
                ...prev.exercises,
                [keyVersion]: [
                  ...prev.exercises[keyVersion],
                  ...(type
                    ? [{ ...clone(defKeysExercises[keyVersion]), type }]
                    : clone(defKeysExercises[keyVersion])),
                ],
              },
            }));
          }}
          className="w-full flex gap-2 !mt-2 !py-1"
        >
          <Add />{" "}
          <span>
            NEW {fUpperWords((type ?? keyVersion).replaceAll("_", " "))}
          </span>
        </Button>
      </Box>
    </Accordion>
  );
};

export default AddNewExerciseQuestionsAccordion;
