import it from '@/assets/icons/it.svg';
import de from '@/assets/icons/de.svg';
import ch from '@/assets/icons/ch.svg';
import fr from '@/assets/icons/fr.svg';
import es from '@/assets/icons/es.svg';
import pt from '@/assets/icons/pt.svg';


export default {
    it,
    de,
    fr,
    es,
    pt,
    ch
}