import { Box, Button } from "@mui/material";

const OtherStatusTemplate = ({ anchorsEl, onUpdateRow, rowSelected }) => {
  return (
    <Box className="bg-edu-800 flex flex-col gap-2 p-3">
      {anchorsEl?.buttons?.map((e: any) => (
        <Button
          key={e}
          variant="outlined"
          onClick={() =>
            onUpdateRow({
              toast: "change",
              values: { [anchorsEl.value]: e },
            })
          }
          disabled={rowSelected?.crm_upselling?.[anchorsEl.value] === e}
        >
          {e}
        </Button>
      ))}
    </Box>
  );
};

export default OtherStatusTemplate;
