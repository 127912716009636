import { Box, Button } from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const ToolbarMaterials = ({
    filters,
    setFilters,
    setOpenCreate,
    setOpenAssignment,
}) => {
    return (
        <Box
            id="filter_bar"
            className="flex gap-2 !items-stretch"
        >
            <FiltersBar>
                <Button
                    variant="outlined"
                    onClick={() => setOpenCreate(true)}
                >
                    <img
                        src={addIcon}
                        className="icon"
                    />{" "}
                    Add
                </Button>

                <Button
                    variant="outlined"
                    onClick={() => setOpenAssignment(true)}
                >
                    <img
                        src={addIcon}
                        className="icon"
                    />
                    Assignment materials
                </Button>
            </FiltersBar>

            <SearchToolbar
                setFilters={setFilters}
                filters={filters}
                client={true}
            />
        </Box>
    );
};

export default ToolbarMaterials;
