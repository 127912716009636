import { Button, Box } from "@mui/material";
import addIcon from "@/assets/icons/add.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

const Toolbar = ({
  filters,
  setPage,
  onClickAdd,
  setFilters,
  refetchAvailableLandings,
  linksModalRef,
}) => {
  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={onClickAdd}
        >
          <img
            src={addIcon}
            className="icon"
          />
          Create a new link
        </Button>

        {/* <Button
          variant="outlined"
          onClick={refetchAvailableLandings}
        >
          Refetch available landings
        </Button> */}
        <Button
          variant="outlined"
          onClick={() => linksModalRef.current.open()}
        >
          Manage Landing links
        </Button>
      </FiltersBar>

      <SearchToolbar
        setPage={setPage}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};

export default Toolbar;
