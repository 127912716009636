import {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  ChangeEvent,
  useCallback,
  useImperativeHandle,
} from "react";
import { CSVLink } from "react-csv";
import useAuth from '@/hooks/useAuth';
import { AxiosError } from "axios";
import { queryClient } from "@/main";
import { LoadingButton } from "@mui/lab";
import { API_ENDPOINTS } from "@/api/client/endpoints";
import { EventQueryParam } from "@/interfaces";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Button, Grid, TextField, useTheme } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import Papa from "papaparse";
import dayjs from "dayjs";
import client from "@/api/client";
import useAPI from "@/api/useAPI";
import AddIcon from "@/assets/icons/add.svg";
import SaveIcon from "@/assets/icons/save.svg";
import classNames from "classnames";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@/assets/icons/download.svg";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ConfirmSendEmailAndNotifications from "./ConfirmSendEmailAndNotifications";

dayjs.extend(isSameOrBefore);

const csvData = [
  [
    "titolo",
    "lang",
    "data",
    "ora",
    "ora_fine",
    "id_utente",
    "descrizione_argomento",
    "tutor",
    "nome_visualizzato",
    "livello",
    "tipo",
    "english_level",
  ],
  [
    "POT.T1.A0.1",
    "it",
    dayjs().add(2, "hours").format("DD/MM/YYYY"),
    dayjs().add(2, "hours").format("HH:00:00"),
    dayjs().add(3, "hours").format("HH:00:00"),
    "user_example@gmail.com",
    "Communication",
    "user_example@gmail.com",
    "Speaking Class",
    "Basic - A0",
    "Speaking",
    "A0",
  ],
];

const VALID_TYPE_FOR_CREATE_BULK = [
  "IELTS",
  "Grammar",
  "Speaking",
  "Correction",
  "One to One",
];

interface IProps {
  ref: HTMLButtonElement;
  query: EventQueryParam;
}

export interface IForwardRef {
  open: () => void;
}

const ImportBulkUserDialog = forwardRef<IForwardRef, IProps>(
  ({ query }, ref) => {
    const theme = useTheme();
    const { snackHandler } = useAuth();

    const [file, setFile] = useState<any>(null);
    const [show, setShow] = useState<boolean>(false);
    const [values, setValues] = useState<Array<any>>([]);
    const [errorData, setErrorData] = useState<Array<any>>([]);
    const [openSaveBulk, setOpenSaveBulk] = useState<boolean>(false);
    const [openConfirmSend, setOpenConfirmSend] = useState<boolean>(false);

    const { data: dataCreatedAt } = useQuery(["created_at-events-last"], () =>
      useAPI("/v2/events/add-events/get-add-events-created-at")
    );

    const { mutate: createBulk, isLoading } = useMutation(
      client.events.createBulk,
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["created_at-events-last"]);
          setOpenConfirmSend(true);
          setFile(null);
          setValues([]);
          snackHandler(data.message);
        },
        onError: (error: AxiosError) => {
          const data: any = error?.response?.data;
          const message = data?.message || "Something went wrong. Please retry";
          snackHandler(message, "error");
          if (data?.data?.length) {
            setErrorData(data?.data);
          }
        },
        onSettled: () => {
          queryClient.invalidateQueries([API_ENDPOINTS.V2.EVENTS, query]);
        },
      }
    );

    const sortDataInCSV = useCallback((data: Array<any>) => {
      return data.sort(function (a: { data: string }, b: { data: string }) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const from = new Date(b.data);
        const to = new Date(a.data);
        return from.getTime() - to.getTime();
      });
    }, []);

    useImperativeHandle(ref, () => ({
      open: () => {
        setShow(true);
        setErrorData([]);
      },
    }));

    const submitForm = () => {
      setOpenSaveBulk(false);
      if (file && file[0]) {
        createBulk(file[0]);
        setErrorData([]);
      }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      setFile(e.target.files);
    };

    useEffect(() => {
      if (file && file?.length) {
        Papa.parse(file[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results: any) {
            const rs = sortDataInCSV(results.data);
            setValues(rs);
          },
        });

        setErrorData([]);
      }
    }, [file]);

    useEffect(() => {
      if (errorData.length == 0) return;
      const error = document.getElementById("error_data_bulk_events");
      if (!error) return;
      error.scrollIntoView({ behavior: "smooth" });
    }, [errorData]);

    const handleClose = () => {
      setShow(false);
      setFile(null);
      setValues([]);
    };

    const searchInvalidates: {
      startEnd: Array<any>;
      typeEvent: Array<any>;
      tutorsDateTimeCount: Array<Array<any>>;
    } = useMemo(() => {
      const startEnd: Array<any> = [];
      const typeEvent: Array<any> = [];
      const tutorsDateTimeCount: any = {};

      values.forEach((e, id) => {
        // CONTROL DUPLICATE EVENTS
        const valueTDTC = e.tutor + "-%-" + e.data + "-%-" + e.ora;

        if (!tutorsDateTimeCount?.[valueTDTC]) {
          tutorsDateTimeCount[valueTDTC] = [];
        }

        if (tutorsDateTimeCount[valueTDTC]) {
          tutorsDateTimeCount[valueTDTC].push({ id, ...e });
        }

        // CONTROL VALUE TYPE EVENT
        if (!VALID_TYPE_FOR_CREATE_BULK?.includes(e.tipo)) {
          typeEvent.push({ id, ...e });
        }

        // CONTROL DATE START -> END
        const data = e.data.includes("/")
          ? e.data.split("/").reverse().join("-")
          : e.data;
        if (new Date(data + " " + e.ora) >= new Date(data + " " + e.ora_fine)) {
          startEnd.push({ id, ...e });
        }
      });

      for (const tdtc in tutorsDateTimeCount) {
        const el: Array<number> = tutorsDateTimeCount[tdtc];

        if (el.length <= 1) {
          delete tutorsDateTimeCount[tdtc];
        }
      }

      return {
        startEnd,
        typeEvent,
        tutorsDateTimeCount: Object.values(tutorsDateTimeCount),
      };
    }, [values]);

    const hasValues = Boolean(values?.length);
    const hasInvalidates = Boolean(
      searchInvalidates?.startEnd?.length ||
      searchInvalidates?.typeEvent?.length ||
      searchInvalidates?.tutorsDateTimeCount?.length
    );

    return (
      <>
        <EduModal
          fullWidth
          open={show}
          onClose={handleClose}
          maxWidth={Boolean(values?.length) ? "xl" : "sm"}
        >
          <EduModalTitle onClose={handleClose}>
            <Box className="flex w-full items-center">
              <img
                src={AddIcon}
                className="icon"
              />
              ADD IN BULK
            </Box>
          </EduModalTitle>

          <EduModalBody>
            <Box className="h-full flex flex-col m-4 gap-5">
              {Boolean(values?.length) && (
                <Box sx={{ color: theme.palette.primary.main }}>
                  Validate name columns and correct format:{" "}
                  {csvData[0].map((n) => `'${n}'`).join(", ")}
                </Box>
              )}

              {hasValues && (
                <Box className="py-2 overflow-y-auto max-h-[60vh] bg-slate-500/10">
                  <Grid container>
                    {values.map((item, index) => (
                      <Box
                        key={index}
                        className="p-4 w-full flex flex-col"
                        style={{
                          ...(index + 1 < values.length
                            ? { borderBottom: "1px solid gray" }
                            : {}),
                        }}
                      >
                        <Box>
                          <b>ID: </b> {index + 1}
                        </Box>
                        <Box>
                          <b>Lang:</b> {item.lang}
                        </Box>
                        <Box>
                          <b>Title:</b> {item.titolo}
                        </Box>
                        <Box>
                          <b>Topic: </b> {item.descrizione_argomento}
                        </Box>
                        <Box>
                          <b>Date: </b> {item.data} {item.ora} - {item.ora_fine}
                        </Box>
                        <Box>
                          <b>Tutor: </b> {item.tutor}
                        </Box>
                        <Box>
                          <b>Type: </b> {item.tipo}
                        </Box>
                        <Box>
                          <b>SubType: </b> {item.livello}
                        </Box>
                        <Box>
                          <b>Level: </b> {item.english_level}
                        </Box>
                        <Box>
                          <b>Students: </b> {item?.id_utente}
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              )}

              {/* INVALIDATES */}
              {hasInvalidates && (
                <Box className="flex flex-col gap-2">
                  {Boolean(searchInvalidates?.tutorsDateTimeCount?.length) && (
                    <Box>
                      <Box className="flex gap-1 items-center">
                        <WarningAmberIcon
                          color="error"
                          fontSize="small"
                        />
                        <span>Warning, double booking:</span>
                      </Box>

                      {searchInvalidates?.tutorsDateTimeCount?.map(
                        (eventsDuplicats, i) => (
                          <Box
                            key={i}
                            className="ml-4"
                          >
                            {eventsDuplicats.map((event, indexEvent) => (
                              <span>
                                Class ID <b>{event.id + 1}</b>{" "}
                                {++indexEvent < eventsDuplicats.length
                                  ? " & "
                                  : ""}
                              </span>
                            ))}{" "}
                            on <b>{eventsDuplicats[0].data}</b> at{" "}
                            <b>{eventsDuplicats[0].ora.slice(0, 5)}</b>
                          </Box>
                        )
                      )}
                    </Box>
                  )}

                  {Boolean(searchInvalidates?.typeEvent?.length) && (
                    <Box>
                      <Box className="flex gap-1 items-center">
                        <WarningAmberIcon
                          color="error"
                          fontSize="small"
                        />
                        <span>Warning, TYPE from event is not good:</span>
                      </Box>

                      <Box className="flex gap-1 items-center">
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          color="primary"
                        />
                        <span>
                          Example, correct types:{" "}
                          {VALID_TYPE_FOR_CREATE_BULK?.map((t) => (
                            <>
                              <b>
                                <u>{t}</u>
                              </b>
                              ,{" "}
                            </>
                          ))}
                        </span>
                      </Box>

                      {searchInvalidates?.typeEvent?.map((event: any, i) => (
                        <Box
                          key={i}
                          className="ml-4"
                        >
                          Class ID <b>{event.id + 1}</b> : not valid Type:{" "}
                          <b>{event.tipo}</b>
                        </Box>
                      ))}
                    </Box>
                  )}

                  {Boolean(searchInvalidates?.startEnd?.length) && (
                    <Box>
                      <Box className="flex gap-1 items-center">
                        <WarningAmberIcon
                          color="error"
                          fontSize="small"
                        />
                        <span>Warning, dates start - end not valid:</span>
                      </Box>

                      {searchInvalidates?.startEnd?.map((e: any, i) => (
                        <Box
                          key={i}
                          className="ml-4"
                        >
                          Class ID <b>{e.id + 1}</b> : {e.data} {e.ora} -{" "}
                          {e.ora_fine}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              )}

              <Box className="flex flex-1 items-stretch ">
                {!hasValues && (
                  <input
                    id="file"
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                )}

                <Box
                  className={classNames(
                    "flex justify-between w-full gap-5",
                    file ? "items-center" : "items-stretch"
                  )}
                >
                  <Box className="flex items-center grow">
                    <div
                      onDrop={(e) => {
                        e.preventDefault();

                        const files = e.dataTransfer.files;

                        if (files && files.length > 0) {
                          setFile(files);
                        }
                      }}
                      onDrag={(e) => e.preventDefault()}
                      className={classNames({ "h-[120px] w-full": !file })}
                      onDragOver={(e) => e.preventDefault()}
                      onDragLeave={(e) => e.preventDefault()}
                    >
                      <label htmlFor="file">
                        <Button
                          title="Upload CSV file"
                          variant="outlined"
                          component="div"
                          className={`h-full w-full ${classNames({
                            "!border-dashed": !file,
                          })}`}
                        >
                          {file ? (
                            <span
                              className="max-w-[150px] truncate"
                              title={file[0].name}
                            >
                              {file[0].name}
                            </span>
                          ) : (
                            <>
                              <img
                                src={AddIcon}
                                className="icon"
                              />
                              Select or drop file
                            </>
                          )}
                        </Button>
                      </label>
                    </div>

                    {hasValues && (
                      <Button
                        sx={{ marginLeft: "10px" }}
                        title="Remove content from this file CSV"
                        variant="outlined"
                        onClick={() => {
                          setValues([]);
                          setFile(null);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                  </Box>

                  <Button
                    title="Get template CSV"
                    color="primary"
                    variant="outlined"
                  >
                    <img
                      src={DownloadIcon}
                      className="icon"
                    />
                    <CSVLink
                      data={csvData}
                      filename="import-template.csv"
                      separator=","
                    >
                      Template
                    </CSVLink>
                  </Button>

                  {!hasValues &&
                    dataCreatedAt &&
                    dayjs()
                      .subtract(1, "day")
                      .isSameOrBefore(dayjs(dataCreatedAt), "day") && (
                      <Button
                        title={`Send emails and notifications to students about the
                        events you added ${dayjs().isSame(dayjs(dataCreatedAt), "day")
                            ? "today"
                            : `on ${dayjs(dataCreatedAt).format("DD MMMM")}`
                          }`}
                        color="primary"
                        variant="outlined"
                        onClick={() => setOpenConfirmSend(true)}
                        className="flex gap-3"
                      >
                        <CallSplitIcon sx={{ fontSize: "1.1rem" }} />
                        Warn them
                      </Button>
                    )}

                  {hasValues && (
                    <Box className="py-2 flex flex-row-reverse">
                      <LoadingButton
                        color="primary"
                        variant="outlined"
                        loading={isLoading}
                        onClick={() => setOpenSaveBulk(true)}
                        disabled={hasInvalidates}
                      >
                        <img
                          src={SaveIcon}
                          className="icon"
                        />
                        Submit
                      </LoadingButton>
                    </Box>
                  )}
                </Box>
              </Box>

              {errorData.length > 0 && (
                <TextField
                  multiline
                  id="error_data_bulk_events"
                  rows={4}
                  label="Error"
                  error={errorData.length > 0}
                  value={JSON.stringify(errorData?.join("  "))}
                />
              )}
            </Box>
          </EduModalBody>
        </EduModal>

        <ConfirmationDialog
          open={openSaveBulk}
          title={
            <>
              <img
                src={SaveIcon}
                className="icon"
              />
              Submit
            </>
          }
          onClose={() => setOpenSaveBulk(false)}
          onConfirm={submitForm}
        >
          Do you want save this events?
        </ConfirmationDialog>

        <ConfirmSendEmailAndNotifications
          setShow={setShow}
          dataCreatedAt={dataCreatedAt}
          openConfirmSend={openConfirmSend}
          setOpenConfirmSend={setOpenConfirmSend}
        />
      </>
    );
  }
);

export default ImportBulkUserDialog;
