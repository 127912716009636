import { forwardRef, useImperativeHandle, useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import copyIcon from "@/assets/icons/copy.svg";
import Dropdown from "@/components/_Common/Dropdown";
import MyTooltip from "@/components/MyTooltip";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

export interface IRef {
  open: (rows: Array<any>, title: string) => void;
}

interface IProps {
  theme: any;
  copyToClipboard: (data: string) => void;
}

const DialogUsers = forwardRef<IRef, IProps>(
  ({ theme, copyToClipboard }, ref) => {
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [titleDialog, setTitleDialog] = useState<string>("");

    useImperativeHandle(ref, () => ({
      open: (rows, title) => {
        setRows(rows);
        setTitleDialog(title);
      },
    }));

    const handleClose = () => {
      setRows([]);
      setTitleDialog("");
    };

    const columns: GridColDef[] = [
      {
        field: "fullName",
        headerName: "Full name",
        minWidth: 180,
        flex: 1,
        renderCell: ({ row }) => <MyTooltip>{row?.fullName}</MyTooltip>,
      },
      {
        field: "lang",
        headerName: "Lang",
        width: 60,
      },
      {
        field: "birth_date",
        headerName: "Date of birth",
        width: 120,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 180,
        flex: 1,
        renderCell: ({ row }) => <MyTooltip>{row?.email}</MyTooltip>,
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 140,
        flex: 0.5,
      },
      {
        field: "english_level",
        headerName: "English level",
        width: 120,
      },
      {
        field: "start_month",
        headerName: "Start month",
        width: 120,
      },
      {
        field: "product_tag",
        headerName: "Product",
        minWidth: 180,
        flex: 1,
        renderCell: ({ row }) =>
          row?.product_tag ? <MyTooltip>{row?.product_tag}</MyTooltip> : "-",
      },
      {
        field: "ltv",
        headerName: "LTV",
        width: 90,
      },
      {
        field: "created_at_availabilities",
        headerName: "Availabilities",
        minWidth: 150,
        flex: 0.5,
        headerAlign: "center",
        renderCell: ({ row }) => (
          <Box className="w-full text-center">
            {row?.created_at_availabilities || <Cancel color="error" />}
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "",
        width: 70,
        sortable: false,
        editable: false,
        hideable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) => (
          <Dropdown text={<MoreVert />}>
            <MenuList>
              <MenuItem
                color="primary"
                title="Copy email"
                onClick={() => copyToClipboard(row?.email)}
              >
                <ListItemIcon>
                  <img
                    src={copyIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Copy email</ListItemText>
              </MenuItem>

              <MenuItem
                color="primary"
                title="Copy phone"
                onClick={() => copyToClipboard(row?.phone)}
              >
                <ListItemIcon>
                  <img
                    src={copyIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Copy phone</ListItemText>
              </MenuItem>
            </MenuList>
          </Dropdown>
        ),
      },
    ];

    return (
      <EduModal
        fullWidth
        open={Boolean(rows?.length)}
        onClose={handleClose}
        maxWidth="xl"
      >
        <EduModalTitle onClose={handleClose}>{titleDialog}</EduModalTitle>

        <EduModalBody className="p-5">
          <DataGrid
            autoHeight
            disableColumnMenu
            disableVirtualization
            disableSelectionOnClick
            hideFooterSelectedRowCount
            sx={getTableStyling(theme)}
            rows={rows}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row.email}
            hideFooter={Boolean(rows?.length < 11)}
            components={{
              Toolbar: () => (
                <Box className="flex">
                  <SearchToolbar />
                </Box>
              ),
              Pagination: CustomPaginationClient,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            rowsPerPageOptions={[10]}
            experimentalFeatures={{ newEditingApi: false }}
          />
        </EduModalBody>
      </EduModal>
    );
  }
);

export default DialogUsers;
