import { memo, useMemo } from "react";
import { ENGLISH_LEVEL } from "@/pages/Users/UserSingle";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import useProductApi from "@/api/useProductApi";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import UseDownloadTable from "@/components/UseDownloadTable";
import { useGridApiContext } from "@mui/x-data-grid";
import refreshIcon from "@/assets/icons/refresh.svg";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";
import FiltersBar from "@/components/_Common/FiltersBar";

const ToolbarNps = ({ filters, setFilters, startMonths }) => {
  const { data: products } = useProductApi().callGetProducts();

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    setFilters((prev) => {
      if (value) prev.set(name, value);
      else prev.delete(name);
      return prev;
    });
  };

  // const productsData = useMemo(() => {
  //   const tempProducts =
  //     products?.products?.map((product: any) => ({
  //       id: product?.percorsi?.id,
  //       name: product?.percorsi?.nome_percorso,
  //     })) || [];

  //   return [
  //     ...new Map(tempProducts.map((item: any) => [item["id"], item])).values(),
  //   ];
  // }, [products]);

  const apiRef = useGridApiContext();

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => {
            setFilters({});
            // apiRef.current.setQuickFilterValues([""]);
            // setSearch("");
          }}
        >
          <img
            src={refreshIcon}
            className="icon"
          />{" "}
          Refresh
        </Button>

        <Dropdown
          active={
            Object.keys(filters).filter(
              (f) => !["sorts", "page", "search"].includes(f)
            ).length
              ? true
              : false
          }
          text="Filter"
          icon={filterIcon}
          onClick={() => {}}
          content={
            <Box className="flex flex-col w-[250px] gap-5 p-5">
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  English level
                </InputLabel>

                <Select
                  name="english_level"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label=" english level"
                  value={filters?.english_level || ""}
                  onChange={handleChange}
                >
                  {ENGLISH_LEVEL.map((e: any, i: number) => (
                    <MenuItem
                      key={e + i}
                      value={e}
                    >
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="demo-simple-select-label2">
                  Start month
                </InputLabel>

                <Select
                  name="start_month"
                  labelId="demo-simple-select-label2"
                  id="demo-simple-select2"
                  label="start month"
                  value={filters?.start_month || ""}
                  onChange={handleChange}
                >
                  {startMonths?.map((e: any) => (
                    <MenuItem
                      key={e}
                      value={e}
                    >
                      {useConvertStartMonthEn(e)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <FormControl>
                <InputLabel id="demo-simple-select-label3">Path</InputLabel>
                <Select
                  name="path"
                  labelId="demo-simple-select-label3"
                  id="demo-simple-select3"
                  label="Path"
                  value={filters?.path || ""}
                  onChange={handleChange}
                >
                  {productsData
                    ?.filter((p: any) => p.id)
                    .map((e: any) => (
                      <MenuItem
                        key={e.id}
                        value={e.name}
                      >
                        {e.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </Box>
          }
        />

        <UseDownloadTable fileName={"NPS"} />
      </FiltersBar>

      <SearchToolbar
        setFilters={setFilters}
        filters={filters}
        client={true}
      />
    </Box>
  );
};

export default memo(ToolbarNps);
