import { countries } from "countries-list";
import {
  MeetingLinkResponse,
  Order,
  OrdersResponseList,
} from "./../../interfaces/index";
import {
  bulkLoadMaterialExerciseInput,
  CreateEventInput,
  CreateEventResponse,
  DefaultResponse,
  EditClassStudentsInput,
  EventQueryParam,
  EventsPaginator,
  EventStatisticsPaginator,
  EventStatisticsQueryParam,
  EventTutors,
  EventUsers,
  MaterialExerciseResponse,
} from "@/interfaces";
import { AxiosRequestConfig } from "axios";
import { API_ENDPOINTS } from "./endpoints";
import { HttpClient } from "./http-client";
import {
  CrmUpsellingList,
  CrmUpsellingUser,
  SendTemplateMailResult,
  UpdateMeetingOtherResult,
  UpdateMeetingResult,
} from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import {
  UserUploads,
  UserUpsoldsList,
} from "@/pages/Upselling/v2/UsersUpsolds/interface";

class Client {
  events = {
    all: (
      params?: Partial<EventQueryParam>,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<EventsPaginator>(
        API_ENDPOINTS.V2.EVENTS,
        params,
        options
      ),
    info: (params?: Partial<EventStatisticsQueryParam>) =>
      HttpClient.post<EventStatisticsPaginator>(
        API_ENDPOINTS.V2.EVENT_STATISTICS,
        params
      ),
    tutors: () =>
      HttpClient.get<EventTutors[]>(API_ENDPOINTS.V2.EVENT_TUTORS, {}),
    users: () => HttpClient.get<EventUsers[]>(API_ENDPOINTS.V2.EVENT_USERS, {}),
    remove: (id: number) =>
      HttpClient.delete<DefaultResponse>(
        API_ENDPOINTS.V2.DESTROY_EVENT(id),
        {}
      ),
    technicalProblem: (id: number, value) =>
      HttpClient.post<DefaultResponse>(API_ENDPOINTS.V1.TECHNICAL_PROBLEM(id), {
        value,
      }),
    materialExercises: () =>
      HttpClient.post<MaterialExerciseResponse>(
        API_ENDPOINTS.V1.LIST_MATERIAL_EXERCISES,
        {}
      ),
    create: (input: CreateEventInput) =>
      HttpClient.post<CreateEventResponse>(
        API_ENDPOINTS.V2.EVENT_CREATE,
        input
      ),
    createBulk: (file: File) => {
      const formData = new FormData();
      formData.append("myFile", file);
      return HttpClient.post<DefaultResponse>(
        API_ENDPOINTS.V2.EVENT_CREATE_BULK,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    editClassStudents: (input: EditClassStudentsInput) =>
      HttpClient.post<DefaultResponse>(
        API_ENDPOINTS.V2.EVENT_EDIT_CLASS_STUDENTS,
        input
      ),
    bulkEditMaterialsExercisesStudents: (
      input: bulkLoadMaterialExerciseInput
    ) => {
      return HttpClient.post<DefaultResponse>(
        API_ENDPOINTS.V2.BULK_EDIT_MATERIALS_EXERCISES_STUDENTS,
        input
      );
    },
    update: ({ id, input }: { id: number; input: CreateEventInput }) =>
      HttpClient.post<DefaultResponse>(
        API_ENDPOINTS.V2.EVENT_UPDATE(id),
        input
      ),
    generateMeetingLink: (eventId: number, role: "tutor" | "student") =>
      HttpClient.post<MeetingLinkResponse>(API_ENDPOINTS.V2.EVENTMEETING, {
        eventId,
        role,
      }),
  };

  //orders
  orders = {
    get: (order_tk: string) =>
      HttpClient.post<Order>(API_ENDPOINTS.V3.ORDER(order_tk), {}),
    create: (data: any) => HttpClient.post(API_ENDPOINTS.V3.ORDER_CREATE, data),
    list: (params?: any) =>
      HttpClient.post<OrdersResponseList>(API_ENDPOINTS.V3.ORDER_LIST, params),
    update: (token: string, data) =>
      HttpClient.post(API_ENDPOINTS.V3.ORDER_UPDATE(token), data),
    getPaymentOptions: (params?: any) =>
      HttpClient.get<any>(API_ENDPOINTS.V2.PAYMENT_OPTIONS, params),
    setPaymentOptions: (params?: any) =>
      HttpClient.post<any>(API_ENDPOINTS.V2.PAYMENT_OPTIONS, params),
  };

  //calendar
  calendar = {
    list: (params?: any, options?: AxiosRequestConfig<any> | undefined) =>
      HttpClient.post<Array<CalendarList>>(
        API_ENDPOINTS.V2.CALENDAR_LIST,
        params,
        options
      ),
    createOrUpdate: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post(
        API_ENDPOINTS.V2.CALENDAR_CREATE_OR_UPDATE,
        params,
        options
      ),
  };

  //USERS
  users = {
    confirmPayment: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<{ status: string }>(
        API_ENDPOINTS.V1.USERS.CONFIRM_PAYMENT,
        params,
        options
      ),
    update: (token: string, input: any) =>
      HttpClient.post<CrmUpsellingUser>(
        API_ENDPOINTS.V1.USERS.UPDATE(token),
        input
      ),
    resetDiscountCode: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<any>(
        API_ENDPOINTS.V1.USERS.RESET_DISCOUNT_CODE,
        params,
        options
      ),
    getReviews: (params?: any, options?: AxiosRequestConfig<any> | undefined) =>
      HttpClient.post<any>(API_ENDPOINTS.V1.USERS.GET_REVIEWS, params, options),
  };

  //CRM
  crm = {
    getCalendarImages: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<UpdateMeetingResult>(
        API_ENDPOINTS.V1.CRM.GET_IMG_CALENDARS,
        params,
        options
      ),
    getUser: (token: string) =>
      HttpClient.post<any>(API_ENDPOINTS.V1.GET_CRM_USER(token), {}),
  };

  //CRM UPSELLING
  crm_upselling = {
    list: (params?: any, options?: AxiosRequestConfig<any>) =>
      HttpClient.post<CrmUpsellingList | Blob>(
        API_ENDPOINTS.V1.UPSELLING.LIST,
        params,
        options
      ),

    listUpsolds: (params?: any, options?: AxiosRequestConfig<any>) =>
      HttpClient.post<UserUpsoldsList>(
        API_ENDPOINTS.V1.UPSELLING.UPSOLDS_LIST,
        params,
        options
      ),

    update: (params?: any, options?: AxiosRequestConfig<any> | undefined) =>
      HttpClient.post(API_ENDPOINTS.V1.UPSELLING.UPDATE, params, options),

    updateMeeting: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<UpdateMeetingResult>(
        API_ENDPOINTS.V1.UPSELLING.UPDATE_MEETING,
        params,
        options
      ),

    updateMeetingOther: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<UpdateMeetingOtherResult>(
        API_ENDPOINTS.V1.UPSELLING.UPDATE_MEETING_OTHER,
        params,
        options
      ),

    sendTemplateMail: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<SendTemplateMailResult>(
        API_ENDPOINTS.V1.UPSELLING.TEMPLATE_EMAIL_SEND,
        params,
        options
      ),
  };

  exercises = {
    addOldExercise: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<any>(
        API_ENDPOINTS.V1.EXERCISE.ADD_EXERCISE_OLD,
        params,
        options
      ),

    addNewExercise: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<any>(
        API_ENDPOINTS.V1.EXERCISE.ADD_EXERCISE_NEW,
        params,
        options
      ),

    editNewExercise: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined //exercise id is part of the params
    ) =>
      HttpClient.post<any>(
        API_ENDPOINTS.V1.EXERCISE.EDIT_EXERCISE_NEW,
        params,
        options
      ),

    editOldExercise: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined //exercise id is part of the params
    ) =>
      HttpClient.post<any>(
        API_ENDPOINTS.V1.EXERCISE.EDIT_EXERCISE_OLD,
        params,
        options
      ),

    getExercises: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<any>(API_ENDPOINTS.V1.EXERCISE.GET_EXERCISES, {
        params,
        ...options,
      }),

    deleteNewExercise: (id: any, options: any) =>
      HttpClient.delete<any>(
        API_ENDPOINTS.V1.EXERCISE.DELETE_EXERCISE_NEW(id),
        options
      ),

    deleteOldExercise: (
      params?: any,
      options?: AxiosRequestConfig<any> | undefined //exercise id is passed of the params
    ) =>
      HttpClient.delete<any>(API_ENDPOINTS.V1.EXERCISE.DELETE_EXERCISE_OLD, {
        data: params,
        ...options,
      }),
  };

  countries_list = (params?: { columns?: string[]; key_value?: object }) =>
    HttpClient.get<any>(API_ENDPOINTS.V1.COUNTRY_LIST, params);

  blog = {
    list: (params: any) =>
      HttpClient.get<any>(API_ENDPOINTS.V1.BLOG.LIST, params),
    create: (params?: any, options?: AxiosRequestConfig<any> | undefined) =>
      HttpClient.post<any>(API_ENDPOINTS.V1.BLOG.CREATE, params, options),
    update: (
      id: number,
      params?: any,
      options?: AxiosRequestConfig<any> | undefined
    ) =>
      HttpClient.post<any>(API_ENDPOINTS.V1.BLOG.UPDATE(id), params, options),
    delete: (id: number) =>
      HttpClient.delete<any>(API_ENDPOINTS.V1.BLOG.DELETE(id)),
  };

  product = {
    prices: {
      // create
      create_or_update: (productId: number, params: any, options?: any) =>
        HttpClient.post<any>(
          API_ENDPOINTS.V1.PRODUCT.PRICES.CREATE_OR_UPDATE(productId),
          params,
          options
        ),
      // delete
      delete: (priceId: number) =>
        HttpClient.delete<any>(API_ENDPOINTS.V1.PRODUCT.PRICES.DELETE(priceId)),
      // restore
      restore: (priceId: number) =>
        HttpClient.post<any>(
          API_ENDPOINTS.V1.PRODUCT.PRICES.RESTORE(priceId),
          {}
        ),
    },
    localeData: {
      // create
      create_or_update: (productId: number, params: any, options?: any) =>
        HttpClient.post<any>(
          API_ENDPOINTS.V1.PRODUCT.LOCALE_DATA.CREATE_OR_UPDATE(productId),
          params,
          options
        ),
      // delete
      delete: (priceId: number) =>
        HttpClient.delete<any>(
          API_ENDPOINTS.V1.PRODUCT.LOCALE_DATA.DELETE(priceId)
        ),
      // restore
      restore: (priceId: number) =>
        HttpClient.post<any>(
          API_ENDPOINTS.V1.PRODUCT.LOCALE_DATA.RESTORE(priceId),
          {}
        ),
    },
    listByLanguage: (lang: string, isAdmission: string | boolean) =>
      HttpClient.get<any>(API_ENDPOINTS.V1.PRODUCT.LIST_BY_LANGUAGE(lang), {
        tutoring: isAdmission,
      }),
  };
}

const RequestClient = new Client();
export default RequestClient;
