import { forwardRef, useImperativeHandle, useState } from "react";
import { Image } from "@mui/icons-material";
import { PUBLIC_STORAGE } from "@/pages/Tutors";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";

export interface IWatchImgRef {
  open: (fileName: string, title: string) => void;
}

interface IProps {}

const WatchImg = forwardRef<IWatchImgRef, IProps>((props, ref) => {
  const [title, setTitle] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  useImperativeHandle(ref, () => ({
    open: (fileName, title) => {
      setTitle(title);
      setFileName(fileName);
    },
  }));

  const handleClose = () => {
    setFileName("");
  };

  return (
    <EduModal
      open={Boolean(fileName)}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <EduModalTitle onClose={handleClose}>
        <Image sx={{ mr: 1.7 }} /> {title}
      </EduModalTitle>

      <EduModalBody className="flex justify-center items-center">
        <img
          className="w-full h-auto"
          src={PUBLIC_STORAGE + "/public/storage/" + fileName}
          alt="Img from exercise"
        />
      </EduModalBody>
    </EduModal>
  );
});

export default WatchImg;
