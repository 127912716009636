import { useQuery } from "@tanstack/react-query";
import { Box, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import Title from "@/components/_Common/Page/Title";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import usePagination from "@/hooks/usePagination";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const ActivityLogs = () => {
  const theme = useTheme();

  const KEY_QUERY = ["index", "activity-logs"];

  const { data, isLoading } = useQuery(KEY_QUERY, () =>
    useAPI("/v1/users/activity-logs", {}, { type: "get" })
  );

  const [filters, setFilters] = useSearchParams();
  const { page, setPage } = usePagination({ setFilters, filters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  // COLUMNS ===================================================================
  const columns: GridColDef[] = [
    {
      field: "user",
      minWidth: 90,

      headerName: "Admin",
      valueGetter: ({ row }) =>
        `${row?.user?.last_name} ${row?.user?.first_name}`,
    },
    {
      field: "action",
      minWidth: 90,
      headerName: "Action",
    },
    {
      field: "new_changes",
      minWidth: 90,
      flex: 1,
      headerName: "Changes",
      renderCell: ({ row }) => {
        const objects = JSON.parse(row.new_changes);
        let changes: { label: string; value: string }[] = [];
        for (const key in objects) {
          if (key !== "updates") {
            changes.push({ label: key, value: objects[key] });
          }
        }

        return (
          <div className="mb-3">
            {changes.map((c, index) => {
              return (
                <div key={index}>
                  <b>{c.label}:</b> {c.value}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "created_at",
      minWidth: 150,
      valueGetter: ({ row }) =>
        row?.created_at
          ? dayjs(row?.created_at).format("DD/MM/YYYY HH:mm")
          : "-",
      headerName: "Created",
    },
  ];

  return (
    <Box className="mt-8 mx-5 md:mx-10">
      <Title>Activity Logs</Title>

      <DataGrid
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        getRowHeight={() => "auto"}
        rows={data || []}
        columns={columns}
        loading={isLoading}
        pageSize={20}
        autoHeight={true}
        components={{
          Pagination: CustomPaginationClient,
        }}
        rowsPerPageOptions={[20]}
        experimentalFeatures={{ newEditingApi: false }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        page={parseInt(page.toString()) - 1}
        onPageChange={(newPage) => {
          setPage(newPage + 1);
        }}
      />
    </Box>
  );
};

export default ActivityLogs;
