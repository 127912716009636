import videoIcon from "@/assets/icons/video.svg";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import React, { memo, useMemo } from "react";
import { GENERATE_NUMBER_MONTHS } from "../helpers/constants";
import CalendarEventsDrawer from "../components/CalendarEventsDrawer";
import ContactMeetingForm from "../components/ContactMeetingForm";
import { INProps } from "../components/crm-upselling.types";
import { useCalendarEvents } from "../hooks/useCalendarEvents";
import { useMeetingLogic } from "../hooks/useCrmMeeting";
import { useCrmContext } from "../state/CrmV3Context";
import CrmMeetingEditVideoCallInfo from "./CrmMeetingEditVideoCall";

const CrmMeetingComponent: React.FC<INProps> = memo(
  ({ open, data, onClose, functions, isUpselling, dataGetToogle }) => {
    const { formMeetingState, meetingComponentState } = useCrmContext();
    const {
      status,
      eventId,
      formUser,
      setFormUser,
      openCalendarEvents,
      setOpenCalendarEvents,
    } = meetingComponentState;

    const {
      products,
      meetingsSorted,
      selectedEvent,
      handleSetFormMeetingDone,
      keysFormMeetingDone,
    } = useMeetingLogic(data, eventId, formMeetingState, open);

    const { handleUpdateRow } = useCalendarEvents(
      data,
      status,
      functions,
      setOpenCalendarEvents
    );

    const newStartingMonths =
      data?.buttons?.meetingDone?.form.newStartingMonth !== undefined
        ? useMemo(() => {
            const futureMonths: Array<string> = [];
            for (let i = 0; i <= GENERATE_NUMBER_MONTHS; i++) {
              const futureMonth = dayjs().add(i, "M");
              const formattedMonth = dayjs(futureMonth).format("MMMM YYYY");
              futureMonths.push(
                formattedMonth.charAt(0).toUpperCase() +
                  formattedMonth.slice(1).toLowerCase()
              );
            }
            return futureMonths;
          }, [])
        : null;

    return (
      <EduModal
        fullWidth
        open={open}
        onClose={onClose}
        maxWidth="md"
      >
        <EduModalTitle onClose={onClose}>
          <img
            src={videoIcon}
            alt="Video icon"
          />
          MODIFY VIDEO CALL INFORMATION - {data?.user?.first_name}{" "}
          {data?.user?.last_name}
        </EduModalTitle>

        <EduModalBody>
          <Box className="p-5 md:p-10 grid md:grid-cols-2 gap-10">
            <ContactMeetingForm
              data={data}
              formUser={formUser}
              functions={functions}
              setFormUser={setFormUser}
            />

            <CrmMeetingEditVideoCallInfo
              data={data}
              products={products}
              functions={functions}
              isUpselling={isUpselling}
              dataGetToogle={dataGetToogle}
              selectedEvent={selectedEvent}
              meetingsSorted={meetingsSorted}
              newStartingMonths={newStartingMonths || []}
              keysFormMeetingDone={keysFormMeetingDone}
              setOpenCalendarEvents={setOpenCalendarEvents}
              handleSetFormMeetingDone={handleSetFormMeetingDone}
            />
          </Box>

          <CalendarEventsDrawer
            data={data}
            open={openCalendarEvents}
            status={status}
            onClose={() => setOpenCalendarEvents(false)}
            onUpdateRow={handleUpdateRow}
            isUpselling={isUpselling ? isUpselling : (isUpselling = false)}
          />
        </EduModalBody>
      </EduModal>
    );
  }
);

export default CrmMeetingComponent;
