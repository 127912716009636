import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect, useRef } from "react";

export default ({ content }) => {
  const [editor] = useLexicalComposerContext();
  const isContentSet = useRef(false);

  useEffect(() => {
    if (editor && content && !isContentSet.current) {
      try {
        const parsedEditorState = editor.parseEditorState(content);
        editor.setEditorState(parsedEditorState);
        isContentSet.current = true; // Ensure this only runs once
      } catch (error) {
        console.error("Error parsing serialized content:", error);
      }
    }
  }, [editor, content]);

  return null;
};
