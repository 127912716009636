export default {
  January: "Gennaio",
  February: "Febbraio",
  March: "Marzo",
  April: "Aprile",
  May: "Maggio",
  June: "Giugno",
  July: "Luglio",
  August: "Agosto",
  September: "Settembre",
  October: "Ottobre",
  November: "Novembre",
  December: "Dicembre",
};
