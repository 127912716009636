import { createRef, useCallback, useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { ENGLISH_LEVEL } from "./UserSingle";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { arrayFlat, clone, euro } from "@/components/useHelpers";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import {
  MenuItem,
  MenuList,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  PopoverProduct,
  IAnchorElProduct,
  RenderCellProduct,
  valueGetterProduct,
  defaultAnchorElProduct,
  PopoverSetProductFromPayment,
} from "@/components/User/PopoverProduct";
import CreateUser, { Products } from "./components/CreateUser";
import InvoiceUserDialog, {
  IForwardRef as InvoiceUserDialogRef,
} from "./components/InvoiceUserDialog";
import CreateInvoicePaymentInDialog, {
  IRef as IRefCreateInvoicePaymentInDialog,
} from "@/components/PaymentsIn/CreateInvoicePaymentInDialog";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import editIcon from "@/assets/icons/edit.svg";
import mailIcon from "@/assets/icons/mail.svg";
import chatIcon from "@/assets/icons/chat.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import useUserApi from "@/api/useUserApi";
import invoiceIcon from "@/assets/icons/invoice.svg";
import UsersToolbar from "./components/UsersToolbar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useProductApi from "@/api/useProductApi";
import usePagination from "@/hooks/usePagination";
import PaymentInIcon from "@/assets/icons/payment-incoming.svg";
import CustomPagination from "@/components/_Common/CustomPagination";

const HTTP_BASE = "/v1/tutoring";

const GestioneIscritti = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { copyToClipboard, isAdmission } = useAuth();

  const { data: products } = useProductApi().callGetProducts();

  const [isMode, setIsMode] = useState<boolean>(false);
  const [filters, setFilters] = useSearchParams({});
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<{} | null>(null);
  const [selectedModes, setSelectedModes] = useState<Array<any>>([]);
  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );
  const [
    anchorElPopoverSetProductFromPayment,
    setAnchorElPopoverSetProductFromPayment,
  ] = useState<IAnchorElProduct>(defaultAnchorElProduct);

  const invoiceDialogRef = createRef<IRefCreateInvoicePaymentInDialog>();
  const InvoiceUserDialogRef = createRef<InvoiceUserDialogRef>();

  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ filters, setFilters });

  delete filterData?.page;
  const cacheKey = [
    "usersGestioneIscrittiAdmission",
    page,
    perPage,
    filterData,
  ];

  const { data: usersAPI, isLoading } = useQuery(
    cacheKey,
    () => useAPI(HTTP_BASE, { page, per_page: perPage, ...filterData }),
    { keepPreviousData: true, refetchOnWindowFocus: true, enabled: perPage > 0 }
  );

  const pagination = {
    total: usersAPI?.total || 1,
    last_page: usersAPI?.last_page || 1,
    current_page: usersAPI?.current_page || 1,
  };

  const handleOpenDialog = (data = null) => {
    setOpenDialog(true);
    setUserSelected(data);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUserSelected(null);
  };

  const selectedMode = useMemo(() => arrayFlat(selectedModes), [selectedModes]);

  const productsData = useMemo(() => {
    const tempProducts =
      products?.products?.map((product: any) => ({
        id: product?.id,
        name: product?.course_info?.course_name || product?.product_tag,
      })) || [];

    return [
      ...new Map(tempProducts.map((item: any) => [item["id"], item])).values(),
    ] as Products[];
  }, [products]);

  const handleRemoveModeSelected = useCallback(() => {
    setIsMode(false);
    setSelectedModes([]);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Name",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) => row?.fullName || "-",
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) => row?.email || "-",
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 150,
        flex: 0.5,
        valueGetter: ({ row }) => row?.phone || "-",
      },
      {
        field: "product",
        headerName: "Product",
        minWidth: 220,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => valueGetterProduct(row),
        renderCell: ({ row }) => (
          <RenderCellProduct
            {...{
              row,
              setAnchorElProduct,
              setAnchorElPopoverSetProductFromPayment,
            }}
          />
        ),
      },
      {
        field: "ltv",
        headerName: "LTV",
        minWidth: 110,
        flex: 0.5,
        valueGetter: ({ row }) => euro(row?.payments?.[0]?.ltv),
      },
      {
        field: "actions",
        headerName: "",
        minWidth: 100,
        sortable: false,
        hideable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) => {
          return (
            <Dropdown
              text={<MoreVertIcon />}
              onClick={() => { }}
              content={
                <MenuList>
                  <MenuItem
                    title="Copy email"
                    color="primary"
                    onClick={() => copyToClipboard(row?.email)}
                  >
                    <ListItemIcon>
                      <img
                        src={mailIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy email</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Send WhatsApp message"
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=${row?.phone}&text=`,
                        "_blank"
                      )
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={chatIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>WhatsApp</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Add payment"
                    color="primary"
                    onClick={() =>
                      invoiceDialogRef?.current?.open({
                        user: row,
                        products: products.products,
                      })
                    }
                  >
                    <ListItemIcon>
                      <img
                        width="16px"
                        src={PaymentInIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Add payment</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Invoices"
                    color="primary"
                    onClick={() =>
                      InvoiceUserDialogRef.current?.open(row.token)
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={invoiceIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Invoices</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Edit user"
                    color="primary"
                    onClick={() => handleOpenDialog(row)}
                  >
                    <ListItemIcon>
                      <img
                        src={editIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Edit user</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          );
        },
      },
    ],
    [InvoiceUserDialogRef]
  );

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFilters(
      (prev) => {
        if (value) {
          prev.set(name, value);
        } else prev.delete(name);
        return prev;
      },
      { replace: true }
    );
    setPage(1);
  };

  const handleFilterSort = useCallback((model: GridSortModel) => {
    const sorts = {};

    model.forEach(({ field, sort }) => {
      sorts[field] = sort;
      setPage(1);
    });

    setFilters(
      (prev) => {
        prev.set("sorts", JSON.stringify(sorts));
        return prev;
      },
      { replace: true }
    );
  }, []);

  const englishMenu = useMemo(
    () =>
      ENGLISH_LEVEL.map((e: string, i: number) => (
        <MenuItem
          key={i}
          value={e}
        >
          {e}
        </MenuItem>
      )),
    []
  );

  const { callGetStartMonths } = useUserApi();
  const { data: startMonths } = callGetStartMonths();

  const startMonthMenu = useMemo(
    () =>
      startMonths?.data?.map((e: any) => (
        <MenuItem
          key={e.month}
          value={e.month}
        >
          {e.month_en}
        </MenuItem>
      )),
    [startMonths]
  );

  const productMenu = useMemo(
    () =>
      productsData
        ?.filter((p) => p.id)
        .map((e: any) => (
          <MenuItem
            key={e.id}
            value={e.id}
          >
            {e.name}
          </MenuItem>
        )),
    [productsData]
  );

  const sortsModel: GridSortModel = useMemo(() => {
    const sorts = filters.get("sorts") as string;

    let model: any;

    if (sorts) {
      model = JSON.parse(sorts);
    } else {
      return [];
    }

    model = Object.entries(model)
      .map(([field, sort]) => ({ field, sort }))
      .filter(({ sort }) => ["asc", "desc"].includes(sort as string));

    return model;
  }, [filters]);

  return (
    <BasePage title={"All Users" + (isAdmission ? " admission" : "")}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        hideFooterSelectedRowCount
        sx={getTableStyling(theme)}
        rows={usersAPI?.data || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortsModel}
        sortingMode="server"
        selectionModel={selectedModes[parseInt(page.toString()) - 1] || []}
        isRowSelectable={({ row }) => !Boolean(row?.crm_upselling)}
        checkboxSelection={isMode}
        onSortModelChange={handleFilterSort}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        columnVisibilityModel={{ id_utente: false, actions: !isMode }}
        initialState={{
          columns: {
            columnVisibilityModel: { phone: false, email: false },
          },
        }}
        onSelectionModelChange={(ids) => {
          setSelectedModes((p: Array<Array<string | number>>) => {
            const t = clone(p);
            t[parseInt(page.toString()) - 1] = ids;
            return t;
          });
        }}
        components={{
          Toolbar: UsersToolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
              rowSelected={selectedMode?.length}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            isMode,
            filters,
            setPage,
            setIsMode,
            setFilters,
            englishMenu,
            productMenu,
            handleChange,
            selectedMode,
            startMonthMenu,
            handleOpenDialog,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            onRemoveModeSelected: handleRemoveModeSelected,
          },
          pagination: { page, setPage, pagination },
        }}
      />

      <CreateUser
        open={openDialog}
        user={userSelected}
        cacheKey={cacheKey}
        products={productsData}
        handleOpen={handleOpenDialog}
        handleClose={handleCloseDialog}
      />

      <InvoiceUserDialog ref={InvoiceUserDialogRef} />

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}

      {Boolean(anchorElPopoverSetProductFromPayment?.element) && (
        <PopoverSetProductFromPayment
          anchorElProduct={anchorElPopoverSetProductFromPayment}
          setAnchorElProduct={setAnchorElPopoverSetProductFromPayment}
        />
      )}

      <CreateInvoicePaymentInDialog ref={invoiceDialogRef} />
    </BasePage>
  );
};

export default GestioneIscritti;
