import { BrowserRouter, Route, Routes } from "react-router-dom";

const BASE_ROUTE = import.meta.env.VITE_ROUTES_BASE;

import { Box, useTheme } from "@mui/material/";

import Login from "@/pages/Login";

const BasicRoutes = () => {
  const theme = useTheme();
  return (
    <Box
      className=" h-screen "
      sx={{ background: theme.palette.leftSideBar.main }}
    >
      <Box
        className="flex flex-col justify-center items-center"
        sx={{ flexGrow: 1, height: "calc(100% - 64px)" }}
      >
        <Routes>
          <Route path={BASE_ROUTE}>
            <Route index element={<Login />} />
            <Route path="*" element={<Login />} />
          </Route>
        </Routes>
      </Box>
    </Box>
  );
};

export default BasicRoutes;
