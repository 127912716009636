import { useState } from "react";
import { team } from "@/api";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { LinearProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import FormUserDialog, { IFormUser } from "@/components/Team/FormUserDialog";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import TeamsTable from "@/components/Team/TeamsTable";
import usePagination from "@/hooks/usePagination";
import useUserAdminApi from "@/api/useUserAdminApi";
import ConfirmationDialog from "@/components/ConfirmationDialog";

const Team = () => {
  const { userAdmin } = useAuth();

  const { callDeleteAdmin } = useUserAdminApi();

  const [open, setOpen] = useState(false as boolean);
  const [openConfirm, setOpenConfirm] = useState(false as boolean);
  const [selectedUser, setSelectedUser] = useState(null as IFormUser | null);

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ filters, setFilters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const { data: teamUsers, isLoading } = useQuery(["team"], () =>
    team(userAdmin.token)
  );

  const toogleFormUserDialog = (user: IFormUser | null = null) => {
    setSelectedUser(user);
    setOpen((prev) => !prev);
  };

  const toogleConfirmDeleteDialog = (user: IFormUser | null = null) => {
    setSelectedUser(user);
    setOpenConfirm((prev) => !prev);
  };

  if (isLoading) {
    return (
      <BasePage title="Team">
        <LinearProgress />
      </BasePage>
    );
  }

  return (
    <BasePage title="Team">
      <TeamsTable
        page={page}
        data={teamUsers || []}
        setPage={setPage}
        filters={filterData}
        sortModel={sortModel}
        setFilters={setFilters}
        onSortModelChange={onSortModelChange}
        toogleFormUserDialog={toogleFormUserDialog}
        toogleConfirmDeleteDialog={toogleConfirmDeleteDialog}
      />

      <FormUserDialog
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        selectedUser={selectedUser}
      />

      <ConfirmationDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          if (selectedUser) {
            callDeleteAdmin.mutate({
              tk: userAdmin.token,
              postData: selectedUser,
            });
          }
          setOpenConfirm(false);
        }}
      >
        Do you want to delete this user?
      </ConfirmationDialog>
    </BasePage>
  );
};

export default Team;
