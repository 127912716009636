import { memo, useMemo, Dispatch, useState, SetStateAction } from "react";
import useAuth from '@/hooks/useAuth';
import { HTTP_BASE } from ".";
import { queryClient } from "@/main";
import {
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import useAPI from "@/api/useAPI";
import MONTHS from "@/components/assets/MONTHS";
import Dropdown from "@/components/_Common/Dropdown";
import LANGUAGES from "@/components/assets/LANGUAGES";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import useExportCSV from "@/components/useExportCSV";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";

interface IProps {
  theme: any;
  filters: any;
  setPage: Dispatch<SetStateAction<number>>;
  KEY_QUERY: Array<any>;
  filtersHttp: any;
  setFiltersHttp: any;
}

const Toolbar = ({ filters, setPage, setFiltersHttp, KEY_QUERY }: IProps) => {
  const { isAdmission } = useAuth();
  const [disableDownload, setDisableDownload] = useState<boolean>(false);

  const startMonths = useMemo(() => {
    let start = dayjs().startOf("month").subtract(6, "month");
    const months: Array<string> = [];
    for (let i = 0; i < 12; i++) {
      months.push(start.format("MMMM YYYY"));
      start = start.add(1, "month");
    }
    return months;
  }, []);

  const handleSetFiltersHttp = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setPage(1);
    setFiltersHttp({ ...filters, [name]: value });
  };

  const handleDownload = async () => {
    setDisableDownload(true);
    const res = await useAPI(HTTP_BASE, { filters, export: true });
    setDisableDownload(false);
    useExportCSV(res, `Still to pay ${dayjs().format("YYYY-MM-DD")}.csv`);
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-2 !items-stretch"
    >
      <FiltersBar>
        <Button
          title="Reset/Sync"
          variant="outlined"
          onClick={() => {
            setFiltersHttp({});
            queryClient.invalidateQueries(KEY_QUERY);
          }}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        <Button
          title="Download"
          variant="outlined"
          color="primary"
          disabled={disableDownload}
          onClick={handleDownload}
        >
          <img
            src={downloadIcon}
            className="icon"
          />
          Download
        </Button>

        <Dropdown
          active={
            Object.keys(filters).filter(
              (f) => !["page", "sorts", "search"].includes(f)
            ).length
              ? true
              : false
          }
          text="Filter"
          icon={filterIcon}
          content={
            <Box className="bg-edu-800 flex flex-col w-[250px] gap-5 p-5">
              {!isAdmission && (
                <FormControl className="w-full">
                  <InputLabel id="status-filter-select-label-start_month">
                    Start month
                  </InputLabel>

                  <Select
                    id="status-filter-select-start_month"
                    name="start_month"
                    color="primary"
                    label="Start month"
                    value={filters?.start_month || ""}
                    labelId="status-filter-select-label"
                    onChange={handleSetFiltersHttp}
                    inputProps={{ style: { color: "white" } }}
                  >
                    {startMonths.map((month: string, i: number) => {
                      const v = month.split(" ");
                      return (
                        <MenuItem
                          key={month + i}
                          value={`${MONTHS[v[0]] || v[0]} ${v[1]}`}
                        >
                          <Box className="w-full flex justify-between gap-4">
                            <span>{v[0]}</span>
                            <span>{v[1]}</span>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}

              <FormControl className="w-full">
                <InputLabel id="status-filter-select-label-invoices">
                  Invoices
                </InputLabel>

                <Select
                  id="status-filter-select-invoices"
                  name="invoice"
                  color="primary"
                  label="Invoices"
                  value={filters?.invoice || ""}
                  labelId="status-filter-select-label"
                  onChange={handleSetFiltersHttp}
                  inputProps={{ style: { color: "white" } }}
                >
                  {["1", "2"].map((invoice: string) => (
                    <MenuItem
                      key={invoice}
                      value={invoice}
                    >
                      Count {invoice}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                select
                fullWidth
                name="lang"
                label="Language"
                value={filters?.lang ?? ""}
                onChange={handleSetFiltersHttp}
              >
                {LANGUAGES.map((l, i) => (
                  <MenuItem
                    key={l.iso + i}
                    value={l.iso}
                  >
                    {l.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          }
        />
      </FiltersBar>

      <SearchToolbar
        setFilters={setFiltersHttp}
        filters={filters}
      />
    </Box>
  );
};

export default memo(Toolbar);
