import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

type Props = {
    type: 'daily' | 'weekly',
    data: any,
    isLoading: boolean,
    isFetching: boolean,
}


const colors = {
    "IELTS": {
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
    },
    "Grammar": {
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
    },
    "Speaking": {
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
    },
}

const EventsPresencesChart = (props: Props) => {
    const { type, data, isLoading, isFetching } = props
    const [dataset, setDataset] = React.useState<any>([]);
    const theme = useTheme()
    const [isResizing, setIsResizing] = React.useState(false)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Events Presences ' + type,
            },
        },
        aspectRatio: 1.5,
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                max: 100,
            },
        },
    };

    React.useEffect(() => {
        if (!data) return

        const { data: { [type]: { labels, datasets } } } = data

        let statsData: any = {
            labels: [],
            datasets: [],
        };

        labels.forEach((label: any) => {

            if (label.includes('~')) {

                const [start, end] = label.split('~')

                const [startYear, startMonth, startDay] = start.split('-')
                const [endYear, endMonth, endDay] = end.split('-')

                label = `${startDay}/${startMonth}~${endDay}/${endMonth}`
            } else {
                const [startYear, startMonth, startDay] = label.split('-')

                label = `${startDay}/${startMonth}`
            }

            statsData = {
                ...statsData,
                labels: [
                    ...statsData.labels,
                    label
                ]
            }
        })

        for (const key in datasets) {
            const dataset = datasets[key];

            statsData = {
                ...statsData,
                datasets: [
                    ...statsData.datasets,
                    {
                        label: key,
                        data: dataset,
                        fill: false,
                        backgroundColor: colors[key].backgroundColor,
                        borderColor: colors[key].borderColor,
                    }
                ]
            }
        }

        setDataset(statsData)

    }, [data])

    React.useEffect(() => {

        function onResize() {
            setIsResizing(true)

            setTimeout(() => {
                setIsResizing(false)
            }, 1000);
        }

        window.addEventListener('resize', onResize)

        //on resize end



        return () => {
            window.removeEventListener('resize', onResize)

        }

    }, [])


    return (
        <div style={{
            maxWidth: "900px",
        }}
        >

            {
                <div className='grid grid-flow-col grid-cols-1'>

                    {
                        !isLoading && dataset?.datasets?.length > 0 && !isFetching && !isResizing ? (
                            <Line data={dataset} options={options} style={{
                                width: "100%"
                            }} />
                        ) : (
                            <div className="shadow rounded-md p-4 w-full h-[350px] mx-auto animate-pulse"
                                style={{
                                    backgroundColor: theme.palette.leftSideBarSub.main,
                                }}
                            > </div>
                        )
                    }
                </div>
            }
        </div>
    )
}


export default EventsPresencesChart