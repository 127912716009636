import { createRef } from "react";
import { OrdersData, UserOrders } from "@/interfaces";
import EditOrder, { IRef as IRefEditOrder } from "./components/EditOrder";
import OrdersTable from "./components/OrdersTable";

const Orders = () => {
  const refEditOrder = createRef<IRefEditOrder>();

  return (
    <>
      <OrdersTable
        handleOrderSelected={(order: OrdersData, user: UserOrders) =>
          refEditOrder.current?.open([order], user)
        }
      />

      <EditOrder ref={refEditOrder} />
    </>
  );
};

export default Orders;
