import client from '@/api/client';
import addIcon from '@/assets/icons/add.svg';
import editIcon from '@/assets/icons/edit.svg';
import saveIcon from "@/assets/icons/save.svg";
import TextEditor from '@/components/TextEditor';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Drawer, TextField, useTheme } from '@mui/material';
import { FormEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';

export interface IForwardRef {
    open: (user: any) => void;
}

type ExcludeChildren<T> = Omit<T, 'children'>;

interface IProps {
    snackMessage(message, type?): void
    reloadCache(value): void
    setOpenDialog(value): void
    setOpenDialog2(value): void
    dialogStatus: boolean
}

const TAGS = ["Success Stories", "IELTS", "Inglese"]

const BlogPostDialog = forwardRef<IForwardRef, ExcludeChildren<IProps>>(({ snackMessage, reloadCache, dialogStatus, setOpenDialog, setOpenDialog2 }, ref) => {
    const [content, setContent] = useState<any>();
    const [Submitting, setSubmitting] = useState<boolean>(false);
    const theme = useTheme();

    const saveWorkFlow = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitting(true);

        const article = { ...content };

        if (!article.body) {
            return snackMessage("No Blog content Found", "error")
        }

        delete article.model_data;
        delete article.author_id;
        delete article.published_at;
        delete article.updated_at;
        delete article.created_at;

        const formData = new FormData();

        Object.keys(article).forEach(key => {
            if (key === 'tags') {
                article.tags.forEach((tag, index) => {
                    formData.append(`tags[${index}]`, tag);
                });
            } else {
                formData.append(key, article[key]);
            }
        });

        try {
            if (article.id) {
                const response = await client.blog.update(article.id, formData, { headers: { "Content-Type": "multipart/form-data" } })

                if (response) {
                    setOpenDialog(false);
                    setOpenDialog2(false);
                    snackMessage("Article Updated Successfully")
                    reloadCache(true)
                }
            } else {
                const response = await client.blog.create(formData, { headers: { "Content-Type": "multipart/form-data" } })

                if (response) {
                    setOpenDialog(false);
                    setOpenDialog2(false);
                    snackMessage("Article Created Successfully")
                    reloadCache(true)
                }
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error creating content:', error);
            setSubmitting(false);
        }
    };

    const [selectedImage, setSelectedImage] = useState<any>();

    // Handle image selection
    const handleImageChange = (event) => {
        const file: any = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file)); // Create a preview URL for the image
            setContent({
                ...content,
                upload_img: event.target.files[0]
            })
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
        setContent(prev => {
            delete prev.upload_img
            return prev
        })
    };

    const handleBlogContentChange = (updatedContent) => {
        setContent({
            ...content,
            body: updatedContent
        }); // Update the blog content state
    };

    const handleMultiChange = (evt) => {
        setContent(
            {
                ...content, title: evt.target.value,
                //replace all non alphanumeric characters with "" execpt for spaces
                slug: evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, '-').toLowerCase()
            }
        )
    }

    useImperativeHandle(ref, () => ({
        open: (blog: any) => {
            setContent(blog);
            setOpenDialog(true);
        },
    }));

    return (
        <Drawer
            sx={{ zIndex: 1300 }}
            open={dialogStatus}
            anchor="right"
            onClose={() => { setOpenDialog(false); setOpenDialog2(false) }}
        >
            <Box onSubmit={saveWorkFlow} component="form"
                className='w-[50vw] min-w-[600px] p-5 overflow-y-auto'
            >
                <Box className="flex items-center justify-center gap-2 p-2 mb-10 rounded-3xl"
                    sx={{
                        // borderBottom: `1px solid ${theme.palette.divider}`,
                        backgroundColor: "white",
                        width: 'fit-content',
                    }}
                >
                    <img src={content?.id ? editIcon : addIcon} className='white' />
                    <span
                        className='text-black'
                    >
                        {content?.id ? `Edit article` : "Add new article"}
                    </span>
                </Box>
                <div className="flex flex-col gap-5  h-full min-h-fit">
                    {/* <InputLabel id="title">Title</InputLabel> */}
                    <TextField
                        required
                        fullWidth
                        name="title"
                        label="Title"
                        value={content?.title}
                        variant="outlined"
                        onChange={(event) => handleMultiChange(event)}
                        placeholder="Title"
                    />
                    <TextField
                        required
                        fullWidth
                        name="slug"
                        label="Slug"
                        value={content?.slug || ""}
                        variant="outlined"
                        onChange={(event) => setContent({ ...content, slug: event.target.value })}
                        placeholder="Slug"
                    />

                    <TextField
                        required
                        fullWidth
                        name="description"
                        label="Description"
                        value={content?.description}
                        variant="outlined"
                        onChange={(event) => setContent({ ...content, description: event.target.value })}
                        placeholder="Description"
                    />

                    <Autocomplete
                        multiple
                        options={TAGS} // Options are plain strings
                        getOptionLabel={(option) => option} // Since options are strings, return the string itself
                        value={content?.tags || []} // Bind selected tags
                        onChange={(event, newValue) => {
                            setContent({ ...content, tags: newValue }); // Update the tags
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Tag"
                                placeholder="Tags"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option === value} // Simple string comparison
                    />
                    <div className="d-flex mt-3 mb-3">
                        {/* <label htmlFor="imageInput"> Select Image</label> */}

                        <input
                            type="file"
                            id="imageInput"
                            name="image"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: 'none' }} // Hide the default input field
                        />

                        {/* Display image preview if an image is selected */}
                        {selectedImage ? (
                            <div className="image-preview">
                                <div className="relative w-fit">
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                        className="uploaded-image"
                                        style={{ height: '120px' }}
                                    />
                                    <button
                                        type="button"
                                        onClick={removeImage}
                                        className="remove-image-btn absolute top-0 right-0 bg-red-600 text-white rounded-lg"
                                    >
                                        <IoCloseCircleSharp size={17} />
                                    </button>
                                </div>
                            </div>
                        ) : content?.featured_image ? (
                            <div className="image-preview" style={{ background: "#0b0b0b36" }}>
                                <div className="relative w-fit">
                                    <img
                                        src={content.featured_image}
                                        alt="Featured"
                                        className="uploaded-image"
                                        style={{ height: '120px' }}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setContent({ ...content, featured_image: null })}
                                        className="remove-image-btn absolute top-0 right-0 bg-red-600 text-white rounded-lg"
                                    >
                                        <IoCloseCircleSharp size={17} />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <Box
                                component="label"
                                htmlFor="imageInput"
                                className="rounded-xl outline-none p-4 my-2 border relative"
                                sx={{
                                    borderColor: theme.palette.primary.light,
                                    color: theme.palette.primary.light,
                                }}
                            >
                                Click to upload Image
                            </Box>
                        )}

                    </div>

                    <TextEditor
                        content={content?.body}
                        setContent={handleBlogContentChange}
                    />

                    <div className="flex justify-center mt-12">
                        <LoadingButton
                            type="submit"
                            disabled={Submitting}
                            loading={Submitting}
                            color="primary"
                            variant="outlined"
                        >
                            <img src={saveIcon} className="icon" />
                            {content?.id ? `Update article` : "Create article"}
                        </LoadingButton>
                    </div>
                </div>
            </Box>
        </Drawer>
    );
});

export default BlogPostDialog;
