import { euro } from "@/components/useHelpers";
import { Box, Popover } from "@mui/material";
import dayjs from "dayjs";
import CheckRoundedRegular from "@/components/icons/checkRoundedRegular";

interface IProps {
  theme: any;
  onClose: () => void;
  product: number | string;
  payments: Array<any>;
  anchorEl: HTMLElement | null;
}

const PaymentsTooltip = ({
  theme,
  onClose,
  product,
  payments,
  anchorEl,
}: IProps) => {
  const lastPaymentsForProduct =
    payments?.filter((payment: any) => payment?.product_id === product) || [];

  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box className="flex flex-col gap-2 p-2">
        {lastPaymentsForProduct?.map((payment: any, i: number) => (
          <Box
            key={i}
            className={
              "flex flex-nowrap justify-between items-center gap-2 px-3 py-2 rounded " +
              (theme.palette.mode === "light" ? " bg-violet-100" : "")
            }
          >
            <Box className="px-4 grow">
              {dayjs(payment.data).format("MMMM")}
            </Box>

            <Box className="font-bold flex gap-1">
              {euro(payment?.amount || 0)}
            </Box>

            <CheckRoundedRegular
              className="pl-1"
              color={theme.palette.primary.main}
            />
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default PaymentsTooltip;
