import React from "react";
import { Drawer } from "@mui/material";
import CalendarEvents from "@/components/CalendarEvents/";

interface CalendarEventsDrawerProps {
  open: boolean;
  onClose: () => void;
  data: any;
  status: string;
  isUpselling: boolean;
  onUpdateRow: (meet: any) => void;
}

const CalendarEventsDrawer: React.FC<CalendarEventsDrawerProps> = ({
  open,
  onClose,
  data,
  status,
  isUpselling,
  onUpdateRow,
}) => (
  <Drawer
    sx={{ zIndex: 1300 }}
    open={open}
    anchor="right"
    onClose={onClose}
  >
    {data.user ? (
      <CalendarEvents
        user={data.user}
        status={status}
        isUpselling={Boolean(isUpselling)}
        onUpdateRow={onUpdateRow}
      />
    ) : (
      "No user selected"
    )}
  </Drawer>
);

export default CalendarEventsDrawer;
