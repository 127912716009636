import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Grid } from "@mui/material";
import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { PlaylistAddIcon } from "@/components";
import SaveIcon from "@/assets/icons/save.svg";
import AddIcon from "@/assets/icons/add.svg";
import DownloadIcon from "@/assets/icons/download.svg";

interface IProps {
  ref: HTMLButtonElement;
}

export interface IForwardRef {
  open: () => void;
}

import usePaymentsOutApi from "@/api/usePaymentsOutApi";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";

const ImportPaymentOutDialog = forwardRef<IForwardRef, IProps>(
  (_props, ref) => {
    const [show, setShow] = useState(false as boolean);

    const { callBulkUploadPaymentOut } = usePaymentsOutApi();
    const [file, setFile] = useState(null as any);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const csvData = [
      ["Date", "Receiver", "Amout", "Object", "Type", "Category"],
      [
        "2023-03-09",
        "Meta Platforms Ireland",
        50.99,
        "Tutoring - Uni Apps",
        "Paypal",
        "Others",
      ],
    ];

    const [values, setValues] = useState([] as Array<any>);

    useEffect(() => {
      if (file && file.length > 0) {
        Papa.parse(file[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results: any) {
            let data = results.data.filter((item: any, i) => item.Date != "");

            data = data.map((item: any, i) => {
              return {
                ...item,
                Amout: parseFloat(item.Amout.replace(",", ".")),
              };
            });

            setValues(data);
          },
        });
      }
    }, [file]);

    useImperativeHandle(ref, () => ({
      open: () => {
        setShow(true);
      },
    }));

    const submitForm = async (e: any) => {
      e.preventDefault();

      if (file) {
        setIsLoadingData(true);
        const formData = new FormData();
        formData.append("file", file[0]);
        callBulkUploadPaymentOut.mutate({
          formData,
          callback: () => {
            setIsLoadingData(false);
            setShow(false);
            setFile(null);
            setValues([]);
          },
        });
      }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files;
      setFile(file);
    };

    return (
      <EduModal
        fullWidth
        open={show}
        onClose={() => {
          setShow(false);
          setFile(null);
          setValues([]);
        }}
      >
        <EduModalTitle
          onClose={() => {
            setShow(false);
            setFile(null);
            setValues([]);
          }}
        >
          <div className="flex justify-between w-full items-center">
            <div>
              <PlaylistAddIcon className="icon" /> ADD IN BULK
            </div>
          </div>
        </EduModalTitle>
        <EduModalBody>
          <Box
            component="form"
            onSubmit={submitForm}
            className=" flex flex-col m-5 gap-5"
          >
            {values.length > 0 && (
              <Box className="py-2">
                <Grid container>
                  {values.map((item) => {
                    return (
                      <Grid
                        container
                        style={{
                          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        <Grid item xs={12}>
                          <b>Receiver:</b> {item.Receiver}
                        </Grid>
                        <Grid item xs={12}>
                          <b>Amout: </b>
                          {new Intl.NumberFormat("it-IT", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.Amout)}{" "}
                        </Grid>
                        <Grid item xs={12}>
                          <b>Date: </b>
                          {item.Date}
                        </Grid>
                        <Grid item xs={12}>
                          <b>Category: </b>
                          {item.Category}
                        </Grid>
                        <Grid item xs={12}>
                          <b>Type: </b>
                          {item.Type}{" "}
                        </Grid>
                        <Grid item xs={12}>
                          <b>Object: </b>
                          {item.Object}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}

            <Box className="flex flex-1 items-stretch ">
              {values.length === 0 && (
                <input
                  type="file"
                  className="hidden"
                  id="file"
                  name="file"
                  accept=".csv"
                  onChange={handleFileChange}
                />
              )}

              <Box className="w-full flex flex-row justify-between">
                <div>
                  <label htmlFor="file">
                    <Button component={"div"} variant={"outlined"}>
                      {file ? (
                        file[0].name
                      ) : (
                        <>
                          <img src={AddIcon} className="icon" />
                          Upload CSV file
                        </>
                      )}
                    </Button>
                  </label>

                  {values.length > 0 && (
                    <Button
                      onClick={() => {
                        setValues([]);
                        setFile(null);
                      }}
                      component={"div"}
                      variant="text"
                      sx={{ marginLeft: "10px" }}
                      color="error"
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </div>
                <Button color="primary" variant={"outlined"}>
                  <img src={DownloadIcon} className="icon" />
                  <CSVLink
                    data={csvData}
                    filename={"import-template.csv"}
                    separator=";"
                  >
                    Download template
                  </CSVLink>
                </Button>
              </Box>
            </Box>

            {values.length > 0 && (
              <Box className="py-2 flex flex-row-reverse">
                <LoadingButton
                  loading={isLoadingData}
                  // loadingPosition="end"
                  color="primary"
                  variant="outlined"
                  type={"submit"}
                >
                  <img src={SaveIcon} className="icon" />{" "}
                  {values.length === 0 ? "Upload" : "Submit"}
                </LoadingButton>
              </Box>
            )}
          </Box>
        </EduModalBody>
      </EduModal>
    );
  }
);

export default ImportPaymentOutDialog;
