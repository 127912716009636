import { forwardRef, useImperativeHandle, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { Box, Button } from "@mui/material";
import { Delete, Image, RemoveRedEye } from "@mui/icons-material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import ConfirmationDialog from "@/components/ConfirmationDialog";

const BASE_API = import.meta.env.VITE_BASE_URL_SERVER;

interface IImages {
  id: number;
  user_id: number;
  booking_pic: string;
}

interface IProps {
  cacheKey?: Array<any>;
}

export interface IRef {
  open: (images: Array<IImages>) => void;
}

const CheckBookingIELTS = forwardRef<IRef, IProps>(({ cacheKey }, ref) => {
  const { snackHandler } = useAuth();

  const [index, setIndex] = useState<number>(0);
  const [images, setImages] = useState<Array<IImages>>([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: (images) => {
      setImages(images);
    },
  }));

  const handleClose = () => {
    setIndex(0);
    setImages([]);
  };

  const handleDeleteImage = async () => {
    setOpenConfirmDelete(false);

    const res = await useAPI("/v1/delete/check_user_booking_ielts", {
      user_id: images?.[index]?.user_id,
      check_id: images?.[index]?.id,
    });

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed delete image!", "error");
      return;
    }

    setIndex(0);
    setImages(res.images);
    snackHandler("Success delete image!");
    queryClient.invalidateQueries(cacheKey);
  };

  return (
    <>
      <EduModal
        fullWidth
        open={Boolean(images?.length)}
        onClose={handleClose}
        maxWidth="md"
      >
        <EduModalTitle onClose={handleClose}>
          <Image className="mr-3" /> Check user booking IELTS
        </EduModalTitle>

        <EduModalBody className="flex flex-col items-center p-5 gap-5">
          <Box className="flex gap-5 justify-center items-center">
            <Button
              variant="outlined"
              onClick={() =>
                window.open(
                  BASE_API + "public/storage/" + images?.[index]?.booking_pic,
                  "_blank"
                )
              }
            >
              <RemoveRedEye />
            </Button>

            <Button
              variant="outlined"
              onClick={() =>
                setIndex((p) => (p - 1 < 0 ? images?.length - 1 : --p))
              }
            >
              Prev
            </Button>

            <Box>
              {index + 1}/{images?.length}
            </Box>

            <Button
              variant="outlined"
              onClick={() =>
                setIndex((p) => (p + 1 > images?.length - 1 ? 0 : ++p))
              }
            >
              Next
            </Button>

            <Button
              variant="outlined"
              onClick={() => setOpenConfirmDelete(true)}
              className="ml-6"
            >
              <Delete />
            </Button>
          </Box>

          <Box className="h-full overflow-y-auto">
            <img
              alt={images?.[index]?.booking_pic?.split("/")?.[1]}
              src={BASE_API + "public/storage/" + images?.[index]?.booking_pic}
              className="object-contain max-h-[700px]"
            />
          </Box>
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={handleDeleteImage}
      >
        Do you want to delete this image?
      </ConfirmationDialog>
    </>
  );
});

export default CheckBookingIELTS;
