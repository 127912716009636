import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const LessonsEvaluation = ( {data} ) => {
  const [evaluationData, setEvaluationData] = useState(data);

  useEffect(() => {
    setEvaluationData(data);
  }, [data]);

  const renderProgressBar = (value) => {
  const progress = Math.round((value / 5) * 100);
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={100}
        size={84}
        thickness={3}
        sx={{
          position: 'absolute',
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'butt',
            stroke: '#D9DAF3',
          },
        }}
      />
      <CircularProgress
        variant="determinate"
        value={progress}
        size={84}
        thickness={3}
        sx={{
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'butt',
            stroke: '#8065C9',
          },
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="background.paper"
          color="text.primary"
          borderRadius="50%"
          width={40}
          height={40}
        >
          {value}
        </Box>
      </Box>
    </Box>
  );
};


  const handleInputChange = (field, value) => {
    const updatedEvaluationData = {
      ...evaluationData,
      [field]: value,
    };
    setEvaluationData(updatedEvaluationData);
  };

  return (
    <Card>
      <Box height={210} p={2}>
        <div className="text-eduV2-1000 text-sm font-semibold leading-[13.93px] pb-2">Lessons evaluation on a scale from 1 to 5</div>
        {evaluationData ? (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box py={1} textAlign="center">
                <div className="text-eduV2-800 font-semibold text-xs border-2 rounded-md border-eduV2-800 mb-4 p-1">VOCABULARY</div>
                {renderProgressBar(evaluationData[0])}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box py={1} textAlign="center">
                <div className="text-eduV2-800 font-semibold text-xs border-2 rounded-md border-eduV2-800 mb-4 p-1">SPEAKING</div>
                {renderProgressBar(evaluationData[1])}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box py={1} textAlign="center">
                <div className="text-eduV2-800 font-semibold text-xs border-2 rounded-md border-eduV2-800 mb-4 p-1">FEEDBACK</div>
                {renderProgressBar(evaluationData[2])}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box py={1} textAlign="center">
                <div className="text-eduV2-800 font-semibold text-xs border-2 rounded-md border-eduV2-800 mb-4 p-1">INTERACTION</div>
                {renderProgressBar(evaluationData[3])}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                label="Vocabulary"
                onChange={(e) => handleInputChange('vocabulary', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Speaking"
                onChange={(e) => handleInputChange('speaking', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Feedback"
                onChange={(e) => handleInputChange('feedback', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Interaction"
                onChange={(e) => handleInputChange('interaction', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
};

export default LessonsEvaluation;
