import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";
import BasePage from "@/components/Page/Base";
import useAuth from "@/hooks/useAuth";
import useDGS from "@/hooks/useDataGridSort";
import useMobileScreen from "@/hooks/useMobileScreen";
import usePagination from "@/hooks/usePagination";
import useScreen from "@/hooks/useScreen";
import { getTableStyling } from "@/providers/Colors";
import { LinearProgress, Typography, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridComparatorFn } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getCorrispettivi } from "../api";
import CustomToolbarBalance from "../components/Balance/CustomToolbarBalance";

const Corrispettivi = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { userAdmin } = useAuth();

  const [showColumns, setShowColumns] = useState(false);
  const [filters, setFilters] = useSearchParams();
  const { page, setPage } = usePagination({ setFilters, filters });
  const filterData = Object.fromEntries(filters.entries());

  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const { screen } = useMobileScreen();

  const { data: corrispettivi, isLoading } = useQuery(["corrispettivi"], () =>
    getCorrispettivi(userAdmin.token)
  );

  if (isLoading) {
    return <LinearProgress />;
  }

  const rows = corrispettivi?.filter((p: any) => {
    if (!filterData?.dates) return true;

    const range = filterData?.dates.split(",");
    const startDate = range[0];
    const endDate = range[1];

    if (!p.dataI) return false;
    var [day, month, year] = p.dataI.split("-");
    const value = new Date(`${year}-${month}-${day}  00:00:00`);

    return value >= new Date(startDate) && value <= new Date(endDate);
  });

  let stripe = 0;
  let klarna = 0;
  let bonifico = 0;
  let incomes = 0;
  let outcomes = 0;
  let total = 0;
  let einvoices = 0;
  let pagolight = 0;
  let alma = 0;

  rows?.forEach((item) => {
    if (item?.stripe) stripe += parseFloat(item?.stripe);
    if (item?.klarna) klarna += Number(item?.klarna);
    if (item?.bonifico) bonifico += Number(item?.bonifico);
    if (item?.incomes) incomes += Number(item?.incomes);
    if (item?.outcomes) outcomes += Number(item?.outcomes);
    if (item?.total) total += Number(item?.total);
    if (item?.e_invoices) {
      einvoices += Number(item?.e_invoices);
    }
    if (item?.pagolight) pagolight += Number(item?.pagolight);
    if (item?.alma) alma += Number(item?.alma);
  });

  const formatNumberDecimal = (number) =>
    new Intl.NumberFormat("it-IT", { style: "decimal" }).format(number);

  const formatNumber = (number: number) => {
    if (number < 1000) {
      return formatNumberDecimal(number.toFixed(1));
    }

    return formatNumberDecimal((number / 1000).toFixed(1)) + "k";
  };

  const dayInMonthComparator: GridComparatorFn<string> = (v1, v2) => {
    const day1 = v1.split("-");
    const day2 = v2.split("-");

    return (
      new Date(day1[2] + "-" + day1[1] + "-" + day1[0]).getTime() -
      new Date(day2[2] + "-" + day2[1] + "-" + day2[0]).getTime()
    );
  };

  const columns: GridColDef[] = [
    {
      field: "dataI",
      headerName: "Date",
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 150,
      editable: false,
      sortComparator: dayInMonthComparator,
      // sortable: false,
    },
    {
      field: "stripe",
      hide: showColumns === false,
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(stripe)}</span>
          Stripe
        </div>
      ),
      // flex: 1,
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.stripe ? formatNumberDecimal(row.stripe) : 0}
        </Typography>
      ),
    },
    {
      field: "klarna",
      hide: showColumns === false,
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(klarna)}</span>
          Klarna
        </div>
      ),
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.klarna ? formatNumberDecimal(row.klarna) : 0}
        </Typography>
      ),
    },
    {
      field: "pagolight",
      hide: showColumns === false,
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(pagolight)}</span>
          Pagolight
        </div>
      ),
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.pagolight ? formatNumberDecimal(row.pagolight) : 0}
        </Typography>
      ),
    },
    {
      field: "alma",
      hide: showColumns === false,
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(alma)}</span>
          Alma
        </div>
      ),
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.alma ? formatNumberDecimal(row.alma) : 0}
        </Typography>
      ),
    },
    {
      field: "bonifico",
      hide: showColumns === false,
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(bonifico)}</span>
          Bank transfer
        </div>
      ),
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.bonifico ? formatNumberDecimal(row.bonifico) : 0}
        </Typography>
      ),
    },
    {
      field: "incomes",
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(incomes)}</span>
          Incomes
        </div>
      ),
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.incomes ? formatNumberDecimal(row.incomes) : 0}
        </Typography>
      ),
    },
    {
      field: "e_invoices",
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(einvoices)}</span>
          E-INVOICES
        </div>
      ),
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.e_invoices ? formatNumberDecimal(row.e_invoices) : 0}
        </Typography>
      ),
    },
    {
      field: "outcomes",
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(outcomes)}</span>
          Outgoing
        </div>
      ),
      // headerName: `Outgoing = ${formatNumber(outcomes)}`,
      // flex: 1,
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;
          {row.outcomes ? formatNumberDecimal(row.outcomes) : 0}
        </Typography>
      ),
    },
    {
      field: "total",
      renderHeader: () => (
        <div className="MuiDataGrid-columnHeaderTitle ">
          <span className="number">{formatNumber(total)}</span>
          Total
        </div>
      ),
      // headerName: `Total = ${formatNumber(total)}`,
      // flex: 1,
      [screen === "large" ? "flex" : "minWidth"]: screen === "large" ? 1 : 200,
      editable: false,
      // sortable: false,
      renderCell: ({ row }) => (
        <Typography color="primary">
          &euro;{row.total ? formatNumberDecimal(row.total) : 0}
        </Typography>
      ),
    },
  ];

  return (
    <BasePage
      title={userAdmin.user.admin ? "Balances" : "Balances in the last 2 weeks"}
    >
      <DataGrid
        autoHeight
        disableColumnMenu
        disableColumnSelector
        disableColumnFilter
        disableDensitySelector
        sx={getTableStyling(theme)}
        components={{
          Toolbar: CustomToolbarBalance,
          Pagination: CustomPaginationClient,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            filters: filterData,
            setFilters: setFilters,
            showColumns,
            setShowColumns,
          },
        }}
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[perPage]}
        pageSize={perPage}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: false }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        page={parseInt(page.toString()) - 1}
        onPageChange={(newPage) => setPage(newPage + 1)}
      />
    </BasePage>
  );
};

export default Corrispettivi;
