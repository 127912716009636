import { useState } from "react";
import useAuth from '@/hooks/useAuth';
import { setCookie } from "@/components/Cookie";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, useTheme } from "@mui/material";
import logo from "@/assets/logo.svg";
import logoBlack from "@/assets/logoBlack.svg";
import LoginIcon from "@/assets/icons/login.svg";
import useUserApi from "@/api/useUserApi";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

const Login = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { callLogin } = useUserApi();
  const { snackHandler, setUserAdmin } = useAuth();

  const [visible, setVisible] = useState(false as boolean);
  const [formData, setFormData] = useState({} as any);

  const handleChange = ({ target: { name, value } }: any) => {
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    callLogin.mutate(formData, {
      onSuccess: (data: any) => {
        setUserAdmin(data);
        setCookie("user", data.token);
      },
      onError: () => {
        snackHandler("Wrong email or password!", "error");
      },
    });
  };

  return (
    <Box
      sx={{
        maxWidth: "500px",
        background: theme.palette.leftSideBarSub.main,
      }}
      onSubmit={handleSubmit}
      component="form"
      className="sm:w-full m-3 md:w-1/3 p-10 rounded-lg flex flex-col gap-5 shadow-2xl"
    >
      <img
        src={mode === "light" ? logoBlack : logo}
        className="w-1/2 md:w-1/3 self-center mb-5"
      />

      <TextField
        required
        id="email-required"
        name="email"
        type="email"
        label="Email"
        value={formData?.email ? formData?.email : ""}
        onChange={handleChange}
        className="w-full"
      />

      <TextField
        required
        id="password-required"
        name="password"
        type={visible ? "text" : "password"}
        label="Password"
        value={formData?.password ? formData?.password : ""}
        onChange={handleChange}
        className="w-full"
        InputProps={{
          endAdornment: (
            <Button onClick={() => setVisible((prev) => !prev)}>
              {visible ? (
                <VisibilityOffRoundedIcon />
              ) : (
                <VisibilityRoundedIcon />
              )}
            </Button>
          ),
        }}
      />

      <LoadingButton
        type="submit"
        color="primary"
        variant="outlined"
        loading={callLogin.isLoading}
        className="!py-[0.4rem] !mt-[0.1rem]"
      >
        <img
          src={LoginIcon}
          className="icon"
        />
        Login
      </LoadingButton>
    </Box>
  );
};

export default Login;
