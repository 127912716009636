import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { assignmentMaterials } from "@/api";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import ConfirmDialog from "@/pages/Exercises/ExercisesV1/components/ConfirmDialog";
import AutoCompleteMaterials from "./components/AutoCompleteMaterials";

const MaterialsAssignment = ({
  userAdmin,
  allMaterials,
  openAssignment,
  setOpenAssignment,
}) => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState<string[]>([]);
  const [materials, setMaterials] = useState<Array<{ id: number }>>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleConfirm = async () => {
    // check a student email exists
    if (students?.length === 0) {
      setError("You have to select student/s first!");
      return;
    }

    // check if a material is chosen
    if (materials?.length == 0) {
      setError("You have to select material/s first!");
      return;
    }

    setLoading(true);

    const materialsIds = materials.map((el) => el?.id).join(",");

    const params = {
      data: date,
      id_utente: students,
      materials_id: materialsIds,
    };

    const res = await assignmentMaterials({ tk: userAdmin?.token, params });

    if (res?.status === 200) {
      setStudents([]);
      setMaterials([]);
      setError(null);
      setLoading(false);
      setOpenAssignment(false);
    } else {
      setError(res?.error);
      setLoading(false);
    }
  };

  const handleStudentEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setInputValue(value);
    // Split the value by comma and remove any extra spaces
    const emails = value
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email);
    setStudents(emails);
  };

  return (
    <EduModal
      fullWidth
      open={openAssignment}
      maxWidth="sm"
    >
      <EduModalTitle
        onClose={() => {
          setStudents([]);
          setMaterials([]);
          setError(null);
          setOpenAssignment(false);
        }}
      >
        Materials assignment
      </EduModalTitle>

      <EduModalBody className="h-full w-full p-4 pb-10">
        {Boolean(error) && (
          <Typography
            variant="h6"
            className="text-center w-full text-[#ff0000] pb-4"
          >
            {error}
          </Typography>
        )}

        <FormControl className="w-full flex flex-col items-center gap-8 pt-10 px-4">
          <Box className="flex flex-col w-full max-w-lg gap-4">
            {/* //DATE */}
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={date}
                  views={["day"]}
                  openTo="day"
                  onChange={(newValue) => setDate(newValue)}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Date"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            {/* // STUDENTS */}
            <Grid item>
              <TextField
                label="Students"
                value={inputValue ?? []}
                style={{ width: "100%" }}
                variant="outlined"
                onChange={handleStudentEmailChange}
              />
            </Grid>

            {/* // EXERCISES */}
            <Grid item>
              <AutoCompleteMaterials
                multiple
                closeSelect
                optionsLabel
                data={allMaterials}
                label="Materials"
                value={materials ?? []}
                setValue={setMaterials}
              />
            </Grid>
          </Box>

          <Button
            variant="outlined"
            onClick={() => setOpenConfirm(true)}
            disabled={
              students?.length == 0 || materials?.length == 0 || loading == true
            }
            className="block"
          >
            {loading ? (
              <CircularProgress
                size={26}
                color="primary"
              />
            ) : (
              "Assign"
            )}
          </Button>
        </FormControl>

        <ConfirmDialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          clickYes={handleConfirm}
        >
          Do you want to assign this material(s)?
        </ConfirmDialog>
      </EduModalBody>
    </EduModal>
  );
};

export default MaterialsAssignment;
