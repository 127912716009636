import { AxiosError } from "axios";
import { Bounce, toast } from "react-toastify";

interface ErrorMessages {
  [field: string]: string[];
}

const Message = ({ errors }: { errors: ErrorMessages }) => {
  return (
    <div>
      <p><strong>The given data was invalid:</strong></p>
      <ul style={{ margin: 0, paddingLeft: 20 }}>
        {Object.entries(errors).map(([field, messages]) => (
          <li key={field}>
            <strong>{field}:</strong> {messages.join(", ")}
          </li>
        ))}
      </ul>
    </div>
  );
}

const processErrorMessages = (error) => {
  // Extract errors from the response

  if (!(error instanceof AxiosError)) {
    defaultResponse();
    return;
  }

  const { response } = error;

  if (!response?.data?.errors) {
    defaultResponse();
    return;
  }

  const { errors } = response.data;

  if (errors && typeof errors === "object") {
    // Show the error messages in a toast
    toast(<Message errors={errors} />,
      {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      }
    );
  } else {
    // Default message if no errors exist
    defaultResponse();
  }
};

const defaultResponse = () =>
  toast.error("An unexpected error occurred.", {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeButton: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

export default processErrorMessages;
